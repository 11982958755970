import { CardIssuanceServices } from '+services/card-issuance-services';
import { AllTableResponsesType, CurrencyType, TableKeyType } from '+types';
import { filteredOutObjectProperty, queryParams } from '+utils';

import { getIssuingPermissions } from '../utils';

interface IUseFetchIssuanceTables {
  currency?: CurrencyType;
  tab: TableKeyType;
  limit: string;
  page: string;
  queryValue: any;
}

export default function useFetchIssuanceTables({ currency, tab, limit, page, queryValue }: IUseFetchIssuanceTables) {
  const sortingParams = {
    ...filteredOutObjectProperty(queryValue, [
      queryParams.currency,
      queryParams.page,
      queryParams.limit,
      queryParams.tab,
      queryParams.subtab,
      queryParams.sorterType
    ])
  };

  const servicesMapper = {
    issuing_balance_history: {
      service: CardIssuanceServices.useFetchIssuingBalanceHistory,
      params: {
        currency,
        limit,
        page,
        ...sortingParams
      }
    },
    funding_deposits: {
      service: CardIssuanceServices.useFetchFundingDeposits,
      params: {
        limit,
        page,
        ...sortingParams
      }
    },
    customer_cards: {
      service: CardIssuanceServices.useFetchIssuedCards,
      params: {
        cardCategory: 'customer',
        limit,
        page,
        ...sortingParams
      }
    },
    reserved_cards: {
      service: CardIssuanceServices.useFetchIssuedCards,
      params: {
        cardCategory: 'reserved',
        limit,
        page,
        ...sortingParams
      }
    },
    card_transactions: {
      service: CardIssuanceServices.useFetchAllIssuanceTransactions,
      params: {
        limit,
        page,
        ...sortingParams
      }
    },
    chargebacks: {
      service: CardIssuanceServices.useFetchAllIssuedCardChargebacks,
      params: {
        page,
        limit,
        ...sortingParams
      }
    }
  };

  const {
    data: tableData,
    isFetching,
    refetch
  } = servicesMapper[tab].service({
    enabled: switchPermission(tab),
    params: servicesMapper[tab].params,
    refetchOnCloseFeedbackError: true
  });

  return {
    tableData: tableData ?? ({} as AllTableResponsesType),
    isFetching,
    refetch
  };
}

const switchPermission = (tab: TableKeyType) => {
  const { canViewCardList, canViewChargebacks, canViewWalletHistory, canViewCardTrxns } = getIssuingPermissions();

  switch (tab) {
    case 'issuing_balance_history':
      return canViewWalletHistory;
    case 'customer_cards':
      return canViewCardList;
    case 'reserved_cards':
      return canViewCardList;
    case 'card_transactions':
      return canViewCardTrxns;
    case 'chargebacks':
      return canViewChargebacks;
    default:
      return true;
  }
};
