/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { createApiClient } from '+services/api-setup';
import { IPaging, IResponse } from '+types/api-services-types';
import { logError } from '+utils';

import useFeedbackHandler from './feedbackHandler';

type IResult = {
  /** The API response status. */
  status: boolean;
  /** The API response message. */
  message: string;
  /** The API response paging. */
  paging: IPaging;
};

export interface IOptions<TData, T> extends Omit<UseQueryOptions<AxiosResponse<IResponse<TData>>>, 'queryKey' | 'select'> {
  /** The parameter needed by the endpoint. */
  params?: T;
  /** The flag to indicate the endpoint needs a token. It is optional. */
  useAuth?: boolean;
  /** The flag to show successful message banner. It is optional. */
  showSuccessMessage?: boolean;
  /** The flag to show error message banner. It is optional. */
  showErrorMessage?: boolean;
  /** The success message to be shown. It is optional. */
  successMessage?: string;
  /** The error message to be shown. It is optional. */
  errorMessage?: string;
  /** The success message banner placement, it defaults to `viewport`. It is optional. */
  successMessageBannerLevel?: boolean;
  /** The error message banner placement, it defaults to `viewport`. It is optional. */
  errorMessageBannerLevel?: boolean;
  /** The custom base URL for the API. It is optional. */
  customBaseURL?: string;
  /** The callback called with the data returned from a successful response. It is optional. */
  onSuccess?: (data: TData) => void;
  /** The function to select the data from the response. It is optional. */
  select?: (data: unknown) => TData;
  /** The callback called with the error returned from a failed response. It is optional. */
  onError?: (error: Error) => void;
  /** The flag to indicate the environment header. It is optional. */
  useEnvironmentHandler?: boolean | string;
  /** The flag to indicate if the refetching should happen when the error feedback banner is close. It is optional. */
  refetchOnCloseFeedbackError?: boolean;
  /** The flag to indicate if the feedback timeout should be handled. It is optional. */
  hasFeedbackTimeout?: boolean;
  /** The request configuration for the API. It is optional. */
  requestConfig?: Omit<AxiosRequestConfig, 'params'>;
  /** The flag to indicate if the default axios response should be shown. It is optional. */
  showReturnDefaultResponse?: boolean;
  /** The query key for the request. It is optional. */
  queryKey?: QueryKey;
}

/**
 * This callback type is called `onSuccess` and is displayed as a global symbol
 * @callback onSuccess
 * @param: {object} data
 */

/**
 * @description Custom hook for making GET request.
 * @param  {string} url - API path without the baseURL.
 * @param  {object} schema - The schema to validate the response.
 * @param  {object} options - The options for the request.
 * @param  {object | undefined} options.params - The parameter needed by the endpoint.
 * @param  {boolean | undefined} options.useAuth - The flag to indicate the endpoint needs a token. It is optional.
 * @param  {boolean | undefined} options.showSuccessMessage - The flag to indicate if the success message should be shown. It is optional.
 * @param  {boolean | undefined} options.showErrorMessage - The flag to indicate if the error message should be shown. It is optional.
 * @param  {string | undefined} options.successMessage - The success message to be shown. It is optional.
 * @param  {string | undefined} options.errorMessage - The error message to be shown. It is optional.
 * @param  {Function | undefined} options.onSuccess - The callback function to be executed when the request is successful. It is optional.
 * @param  {Function | undefined} options.onError - The callback function to be executed when the request is unsuccessful. It is optional.
 * @param  {string | undefined} options.successMessageBannerLevel - The success message banner placement, it will default to `viewport`. It is optional.
 * @param  {string | undefined} options.errorMessageBannerLevel - The error message banner placement, it will default to `viewport`. It is optional.
 * @param  {string | undefined} options.customBaseURL - The custom base URL for the API. It is optional.
 * @param  {onSuccess | undefined} options.onSuccess - The callback handles side-effects with data returned from a successful response. It is optional.
 * @returns {{
 *   data: T;
 *   status: number;
 *   message: string;
 *   paging: unknown;
 * }}
 * @example const result = useFetch(url, schema, { params, useAuth, showSuccessMessage, showErrorMessage, successMessage, errorMessage, onSuccess, onError });
 * the result of the request: result.data;
 * the status of the request: result.status;
 * the message of the request: result.message;
 * the paging of the request: result.paging;
 * the result also exposes all the properties of the useQuery hook.
 */

export const useFetch = <TData, T = Record<string, unknown>>(
  url: string,
  {
    params,
    useAuth,
    showSuccessMessage = false,
    showErrorMessage = true,
    customBaseURL,
    successMessage,
    errorMessage,
    successMessageBannerLevel,
    errorMessageBannerLevel,
    onSuccess,
    onError,
    useEnvironmentHandler,
    refetchOnCloseFeedbackError,
    hasFeedbackTimeout,
    requestConfig,
    showReturnDefaultResponse,
    ...options
  }: IOptions<TData, T> = {}
) => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const { client, setBaseURL } = createApiClient(useAuth, useEnvironmentHandler);
  if (customBaseURL) setBaseURL(customBaseURL);
  const data = useQuery({
    queryKey: [...url.split('/'), url, params, useAuth] as QueryKey,
    queryFn: () => client.get<TData>(url, { params, ...requestConfig }) as Promise<AxiosResponse<IResponse<TData>>>,
    ...options
  });

  const formattedMeta = <U, TResult extends keyof IResult>(
    responseData: AxiosResponse<IResponse<U>> | undefined,
    accessor: TResult
  ): IResult[TResult] | undefined => {
    if (!responseData) return;
    if (accessor === 'paging')
      return (responseData?.data?.data?.paging as IResult[TResult]) || (responseData?.data?.paging as IResult[TResult]);

    return responseData?.data?.[accessor as keyof Omit<IResponse<U>, 'data'>] as IResult[TResult];
  };

  const formattedData = (data.data as { data: TData })?.data ?? data?.data;

  useEffect(() => {
    if ((data.isSuccess || data.isError) && hasFeedbackTimeout) {
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
    if (data.isSuccess && showSuccessMessage) {
      feedbackInit({
        type: 'success',
        message: (successMessage ?? data.data?.data?.message) as string,
        componentLevel: successMessageBannerLevel
      });
    }

    if (data.isSuccess && onSuccess) onSuccess(showReturnDefaultResponse ? data?.data : (formattedData as TData));

    if (data.isError) {
      const error = data?.error as AxiosError<{ message: string }>;
      logError(error?.response?.data?.message);
      if (onError) onError(data.error);
      if (showErrorMessage) {
        feedbackInit({
          type: 'danger',
          message: error?.response?.data?.message ?? errorMessage,
          componentLevel: errorMessageBannerLevel,
          ...(refetchOnCloseFeedbackError && {
            action: {
              action: () => data.refetch(),
              name: 'Try again'
            }
          })
        });
      }
    }
  }, [data.isError, data.isFetching]);

  return {
    ...data,
    data: formattedData as TData,
    meta: {
      status: formattedMeta(data?.data as AxiosResponse<IResponse<TData>>, 'status') as boolean,
      message: formattedMeta(data?.data as AxiosResponse<IResponse<TData>>, 'message') as string,
      paging: formattedMeta(data?.data as AxiosResponse<IResponse<TData>>, 'paging') as IPaging
    }
  };
};
