import { StateCreator } from 'zustand';

type CurrencySlice = {
  availableCurrencies: string[];
  defaultCurrency: string;
};

const createCurrencySlice: StateCreator<CurrencySlice> = () => ({
  availableCurrencies: [],
  defaultCurrency: ''
});

export default createCurrencySlice;
