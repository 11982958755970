import { useQueryClient } from '@tanstack/react-query';

import { AuthServices } from '+services/auth-services';
import { logout } from '+utils';

export default function useLogout() {
  const queryClient = useQueryClient();
  const { mutate } = AuthServices.useSignout({});

  const handleLogout = (destination: null | string = null) => {
    mutate({});
    logout(destination, queryClient);
  };

  return { logout: handleLogout };
}
