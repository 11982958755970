import React from 'react';

import { CurrencyType } from './common';

export interface Params {
  id: string;
}

export type PermissionsT = Record<string, string>;

export type BulkPayoutStatusT = 'draft' | 'processing' | 'cancelled' | 'completed' | 'failed' | 'pending' | 'complete';

type BulkLimit = {
  max_allowed_bulk_payouts: number;
  min_allowed_bulk_payouts: number;
};

type PayoutLimit = {
  max?: number;
};

type PayoutLimits = {
  bank_account?: Record<string, PayoutLimit>;
  bulk_bank_account?: Record<string, PayoutLimit>;
  mobile_money?: Record<string, PayoutLimit>;
  bulk_mobile_money?: Record<string, PayoutLimit>;
};

export type BulkpayoutType = {
  available_currency: string[];
  id: number;
  name: string;
  sorterType: null;
  bulk_payout_limits: BulkLimit;
  payout_limits: PayoutLimits;
  default_merchant: object;
};

export const defaultPage = {
  page: 1,
  limit: 10,
  sorterType: null || '',
  sortingParams: {}
};

export type SwitchIconType = {
  complete: string;
  pending: string;
  failed: string;
  draft: string;
  cancelled: string;
  processing: string;
};

export type bulkPayoutContentT = {
  narration: string | null;
  amount: string | null;
  bank_slug: string | null;
  account_number: string | null;
  account_name: string;
  bank_name: string;
  mmo_slug: string | null;
};

export interface IBulkPayoutModalProps {
  close: () => void;
  type: 'onError' | 'onSuccess';
  currency: CurrencyType;
}

export type RadioProps = {
  checked: boolean;
  onChange: () => void;
  label: React.ReactNode;
  disabled: boolean;
};

export type BankAccountT = {
  account_name: string;
  account_number: string;
  bank_slug?: string;
  bank_code?: string;
  mobile_number?: string;
  operator?: string;
  bank_name?: string;
};

export type MobileMoneyT = {
  mobile_number: string;
  mmo_slug?: string;
  operator?: string;
  account_name: string;
  operator_slug?: string;
};

export type BankT = {
  code: string;
  country: string;
  name: string;
  nibss_bank_code: string;
  slug: string;
};

type BulkPayoutErrorT = {
  title: string;
  messages: Array<string>;
};

type CustomerT = {
  email?: string;
  name?: string;
};

export interface BulkDataT {
  amount: number;
  narration: string;
  bank_code: string;
  account_number: string;
  account_name: string;
  customer_name: string;
  customer_email: string;
  errors: Array<BulkPayoutErrorT>;
  reference: string;
  bank_account: BankAccountT;
  currency: string;
  customer: CustomerT;
  mobile_money: MobileMoneyT;
}

export interface BulkDraftDataT {
  reference: string;
  data: Array<BulkDataT>;
  newUpdate: boolean;
}
export interface IBulkPayoutInitTableProps {
  isFetching: boolean;
  refetch: () => void;
  data: Array<BulkDataT>;
  permissions: PermissionsT;
  reference: string;
  status?: BulkPayoutStatusT;
  payoutValidationStatus?: boolean;
  payoutType?: string;
  currency?: string;
}

export interface PagingT {
  total_items: number;
  page_size: number;
  count?: number;
  current?: number;
}

export interface PaginationT {
  page: number;
  limit: number;
  sorterType?: string;
  sortingParams?: {
    bulkPayoutReference?: string;
    entriesType: string;
    reference: string;
  };
}

export interface IBulkPayoutEditModalProps {
  confirmModal: boolean;
  closeModal: (value: boolean) => void;
  actionBtn: (e: Partial<BulkDataT>) => void;
  editData: BulkDataT;
  currency: string;
}

export interface IBulkPayoutConfirmModalProps {
  confirmModal: boolean;
  close: () => void;
  secondButtonAction: () => void;
  heading: string;
  completedHeading: string;
  completedDescription: string;
  secondButtonColor?: string;
  secondButtonText: string;
  description: string;
  firstButtonText?: string;
  completedImage?: React.ReactNode;
}

export interface IBulkPayoutTableDataProps {
  reference: string;
  status: string;
  transaction_date: string;
  customer: {
    name: string;
  };
  amount: number;
  currency: string;
}

export interface IBulkPayoutTableProps<T extends IBulkPayoutTableDataProps> {
  isFetching: boolean;
  paging: PagingT;
  setPagination: (value: typeof defaultPage) => void;
  refetch: () => void;
  pagination: typeof defaultPage;
  data: Array<T>;
  setExportModalVisible: (value?: boolean) => void;
  permissions: PermissionsT;
  status: 'pending' | 'processing' | 'complete';
}

export interface IBulkPayoutSummary {
  total_amount: number;
  current_balance: number;
  type: string;
  total_count: number;
  status: string;
  reference: string;
  description: string;
  payout_count: string | number;
  failed_transactions: string | number;
  pending_transactions: string | number;
  total_bulk_amount: string | number;
  successful_transactions: string | number;
  date_completed: string | number;
  validation_completed: boolean;
  amount?: string | number;
  amount_charged?: string | number;
  invalidPayoutsCount?: string | number;
  validPayoutsCount?: string | number;
}

export interface IBulkPayoutSummaryProps {
  dateCompleted: string;
  summaryData: Partial<IBulkPayoutSummary>;
  activeCurrency: string;
  status: BulkPayoutStatusT;
  payCount: number;
  successful: number;
  failed: number;
  isFetching: boolean;
  validationComplete?: boolean;
  draftTotalEntries?: string | number;
}

export interface IBulkPayoutHeaderProps {
  summaryData: Partial<IBulkPayoutSummary>;
  status: string;
  description: string;
  data: Array<object>;
  setExportModalVisible?: (e: boolean) => void;
  permissions: Permissions;
  discardAction?: () => void;
  startAction?: () => void;
  validationCompleted?: boolean;
  enableStartBtn?: boolean;
  enableDiscardBtn: boolean;
  type: string;
  currency: string;
}

export interface IBulkPayoutStartModalProps {
  closeModal: (value: boolean) => void;
  actionBtn: () => void;
  bulkPayoutSummary: Partial<IBulkPayoutSummary>;
  currency: string;
  isOpen: boolean;
  payoutCount?: number | string;
  twoFactorType: 'otp' | 'totp';
  totpIdentifier: string;
  reference: string;
}

export interface IBulkPayoutFilterProps {
  openExportModal: () => void;
  handleFilterQuery: (params) => void;
  totalCount: number;
  status: string;
  activeCurrency: string;
  userAccess: PermissionsT;
  previousFilter?: boolean;
  setPreviousFilter?: (value: boolean) => void;
  dropdownOptions: Array<Record<'value' | 'label', string | number>>;
  disableFilter?: boolean;
}

export interface IStatusRowProps {
  isComplete?: boolean;
  count: string;
  message: string;
  imgSrc?: string;
}
