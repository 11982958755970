import useStore from '+store';

const PermissionKeys = {
  VERIFICATION_ACCESS: 'identity_verification_list',
  VERIFICATION_DETAILS_ACCESS: 'identity_verification',
  BILLING_ACCESS: 'identity_billing_list',
  BILLING_DETAILS_ACCESS: 'identity_billing_details',
};

const PermissionType = {
  VIEW: 'view',
  MANAGE: 'manage',
  EXPORT: 'export',
  NO_ACCESS: 'no_access'
};



export default function useGetIdentityPermissions() {
  const permissions: { [key: string]: string } = useStore.getState()?.permissions;

  const hasPermission = (permissionKey: string, types: string[]): boolean => {
    return types.includes(permissions[permissionKey]);
  };
  
  const canViewBillingHistory = hasPermission(PermissionKeys.BILLING_ACCESS, [PermissionType.VIEW, PermissionType.MANAGE, PermissionType.EXPORT]);
  
  const canViewBillingDetails = hasPermission(PermissionKeys.BILLING_DETAILS_ACCESS, [PermissionType.VIEW, PermissionType.MANAGE]);
  
  const canViewVerificationHistory = hasPermission(PermissionKeys.VERIFICATION_ACCESS, [PermissionType.VIEW, PermissionType.MANAGE, PermissionType.EXPORT]);
  
  const canViewVerificationDetails = hasPermission(PermissionKeys.VERIFICATION_DETAILS_ACCESS, [PermissionType.VIEW, PermissionType.MANAGE]);

  const canViewVerificationSensitiveData = hasPermission(PermissionKeys.VERIFICATION_DETAILS_ACCESS, [PermissionType.MANAGE]);

  const canExportVerificatinData = hasPermission(PermissionKeys.VERIFICATION_ACCESS, [PermissionType.EXPORT]);

  const canExportBillingData = hasPermission(PermissionKeys.BILLING_ACCESS, [PermissionType.EXPORT]);

  return {
    canViewBillingHistory,
    canViewBillingDetails,
    canViewVerificationHistory,
    canViewVerificationDetails,
    canViewVerificationSensitiveData,
    canExportVerificatinData,
    canExportBillingData
  };
}
