import React from 'react';
import * as Flags from 'country-flag-icons/react/3x2';

import { ArrowsLeftRight } from '+containers/Shared/Icons';
import { ICurrencyConversionFormProps } from '+types';
import { formatWithCommas } from '+utils';

import Accordion from './Accordion';
import BasicSelect from './BasicSelect';
import DisplayCountdown from './DisplayCountdown';
import Icon from './Icons';

import './index.scss';

import { ArrowsLeftRightHorizontal } from '+containers/Shared/Icons/ArrowsLeftRightHorizontal';

const CurrencyConversionForm: React.FC<ICurrencyConversionFormProps> = props => {
  const {
    formType,
    payloadCurrency,
    currencyArray,
    handleSelectChange,
    handleSwitchCurrency,
    handleProcessConversion,
    availableBalance,
    errorMessage,
    checkLimitAndValidate,
    formatAmount,
    rate,
    toAmount,
    isLoading,
    amount,
    handleInputChange,
    updateCurrency,
    amountInputRef,
    currentCount,
    initiateConversion,
    sourceCurrency,
    destinationCurrency,
    isProcessConversionLoading
  } = props;
  const [open, setOpen] = React.useState(false);

  const currencies = {
    sourceCurrency,
    destinationCurrency
  };

  const getFlag = (country: string) => (Flags as unknown as Record<string, string>)?.[country?.substring(0, 2)?.toUpperCase()];
  const FromFlag = getFlag(payloadCurrency.from);
  const ToFlag = getFlag(payloadCurrency.to);
  const countryList = (type: 'sourceCurrency' | 'destinationCurrency') =>
    currencies[type].map((currency: string) => {
      const Flag = getFlag(currency);
      return {
        label: (
          <span className="flex mr-2 py-1">
            <Flag
              style={{
                width: '20px'
              }}
            />{' '}
            {currency}
          </span>
        ),
        value: currency
      };
    });

  switch (formType) {
    case 'main':
      return (
        <div className="conversions-wrapper">
          <div className="conversion-forms">
            <div className="conversion-forms inner">
              <div className="form-group conversions-form">
                <label htmlFor="mobileNo" className="withdraw-label">
                  <span className="dark">When you convert</span>
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="select-cur">
                      <select
                        className="form-control"
                        aria-label="source currency"
                        value={payloadCurrency.from}
                        onChange={e => handleSelectChange(e, 'currencyFrom')}
                      >
                        {sourceCurrency.map((currency: string) => (
                          <option key={currency} value={currency}>
                            {currency}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <input
                    className="form-control"
                    name="amount"
                    inputMode="numeric"
                    type="text"
                    maxLength={11}
                    value={formatWithCommas(amount)}
                    onChange={handleInputChange}
                    placeholder="0"
                    ref={amountInputRef}
                  />
                </div>
                <div className="text-amount-info" style={{ right: 0 }}>
                  {errorMessage && <small className="text-muted" dangerouslySetInnerHTML={{ __html: errorMessage }} />}
                </div>
                <div className="conversion-summary-item">
                  <div className="conversion-summary-item__mobile">
                    <span>Balance:</span>
                    <span className="available-balance">
                      {formatAmount(availableBalance)} {payloadCurrency.from}
                    </span>
                  </div>
                </div>
              </div>
              <div onClick={handleSwitchCurrency} className="handle-switch-currency btn-margin">
                <span>
                  <ArrowsLeftRight className="btn-arrows-left-right" />
                </span>
                <span>
                  <ArrowsLeftRightHorizontal className="btn-arrows-up-down" />
                </span>
              </div>
              <div className="form-group conversions-form currency-to">
                <label htmlFor="mobileNo" className="withdraw-label">
                  <span className="dark">You’ll Receive</span>
                </label>
                <div className="input-group currency-to-amount">
                  <div className="input-group-prepend">
                    <div className="select-cur">
                      <select
                        className="form-control"
                        aria-label="destination currency"
                        value={payloadCurrency.to}
                        onChange={e => handleSelectChange(e, 'currencyTo')}
                      >
                        {destinationCurrency.map((currency: string) => (
                          <option key={currency} value={currency}>
                            {currency}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <input
                    className="form-control currency-to-input"
                    type="number"
                    name="amount"
                    value={''}
                    onChange={() => {}}
                    placeholder={checkLimitAndValidate ? `${formatAmount(toAmount || 0)}` : ''}
                  />
                </div>
              </div>
            </div>
            <button className="btn btn-primary convert-btn" disabled={!checkLimitAndValidate} onClick={handleProcessConversion}>
              {isProcessConversionLoading ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              ) : (
                <>
                  <span>Convert</span>
                  <i className="os-icon os-icon-arrow-right6" />
                </>
              )}
            </button>
          </div>

          <div className="conversion-sumarry">
            <div className="conversion-summary-item">
              <div className="conversion-summary-item__desktop">
                <span>Balance:</span>
                <span className="available-balance">
                  {formatAmount(availableBalance)} {payloadCurrency.from}
                </span>
              </div>
            </div>
            <div className="conversion-summary-item exchange_rate_wrapper">
              <ul>
                <li>
                  <div style={{ display: 'flex' }}>
                    {isLoading === true ? (
                      <span className="spinner-border spinner-border-sm ml-2" style={{ opacity: '0.4' }} role="status" aria-hidden="true" />
                    ) : (
                      checkLimitAndValidate && (
                        <>
                          <span>Rate:</span>
                          <span className="swaps-amount">
                            {`${'USD'} ${formatAmount(1)} `} -{' '}
                            {`${currencyArray[0] === 'USD' ? 'NGN' : currencyArray[0]} ${formatAmount(rate || 0)} `}
                          </span>
                          {<DisplayCountdown currentCount={currentCount} />}
                        </>
                      )
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    case 'modal':
      return (
        <>
          <div className="form-group convert-form">
            <div className="conversion-label">
              <label htmlFor="conversion-label" className="withdraw-label">
                <span className="dark">Pay</span>
              </label>
              <label htmlFor="balance" className="withdraw-label balance">
                <span className="">Balance:</span>
                <span className="dark">
                  {formatAmount(availableBalance)} {payloadCurrency.from}
                </span>
              </label>
            </div>
            <div className="input-group currency-input-group">
              <div className="input-group-prepend">
                <div className="select-curr">
                  <BasicSelect
                    options={countryList('sourceCurrency')}
                    setValue={e => updateCurrency?.('currencyFrom', e)}
                    key={payloadCurrency.from}
                    placeholder={
                      <span style={{ display: 'flex', gap: '0.3rem', alignItems: 'center' }}>
                        <FromFlag
                          style={{
                            width: '20px'
                          }}
                        />{' '}
                        {payloadCurrency.from}
                      </span>
                    }
                    aria-label="source currency"
                    className="form-control currency-to-select"
                  />
                </div>
              </div>
              <input
                className="form-control conversion-form-input"
                name="amount"
                inputMode="numeric"
                type="text"
                maxLength={11}
                value={formatWithCommas(amount)}
                onChange={handleInputChange}
                placeholder="0"
              />
            </div>
            <div className="text-amount-info">
              {errorMessage && <small className="text-muted" dangerouslySetInnerHTML={{ __html: errorMessage }} />}
            </div>
          </div>
          <button type="button" onClick={handleSwitchCurrency} className="handle-switch-currency">
            <ArrowsLeftRight className="btn-arrows" />
          </button>
          <div className="form-group convert-form btn-margin">
            <label htmlFor="conversion-label" className="withdraw-label">
              <span className="dark">To Receive</span>
            </label>
            <div className={`input-group currency-input-group dropdown-wrapper ${open ? 'dropdown-clicked' : ''}`}>
              <div className="input-group-prepend">
                <div className="select-curr">
                  <BasicSelect
                    options={countryList('destinationCurrency')}
                    key={payloadCurrency.to}
                    aria-label="destination currency"
                    onOpenChange={setOpen}
                    setValue={e => updateCurrency?.('currencyTo', e)}
                    placeholder={
                      payloadCurrency.to ? (
                        <span style={{ display: 'flex', gap: '0.3rem', alignItems: 'center' }}>
                          <ToFlag
                            style={{
                              width: '20px'
                            }}
                          />{' '}
                          {payloadCurrency.to}
                        </span>
                      ) : (
                        ''
                      )
                    }
                    className="form-control currency-to-select"
                  />
                </div>
              </div>
              <input
                className="form-control currency-to-input conversion-form-input"
                name="amount"
                type="number"
                maxLength={11}
                value={''}
                placeholder={checkLimitAndValidate ? `${formatAmount(toAmount || 0)}` : ''}
              />
            </div>
          </div>
          <div className="conversion-sumarry">
            <div className="conversion-summary-item exchange-rate">
              <div>
                {initiateConversion?.isLoading === true ? (
                  <span className="spinner-border spinner-border-sm ml-2" style={{ opacity: '0.4' }} role="status" aria-hidden="true" />
                ) : (
                  checkLimitAndValidate && (
                    <Accordion
                      className="conversion-summary-rate w-100 mb-4"
                      title={
                        <div className="conversion-summary-rate-header">
                          <p className="label text-capitalize mr-2 mb-0 mt-1">Summary</p>
                          <p className="mr-2 mb-0 mt-1">
                            Show more{' '}
                            <Icon className="arrow-down show-more-arrow" name="caretDown" width={12} strokeWidth={1.5} stroke="#2376F3" />
                          </p>
                        </div>
                      }
                      defaultOpen={false}
                      showToggle={false}
                      content={
                        <>
                          <div className="top-up-row my-2 conversion-summary-rate-content">
                            <p className="value fs-1">Conversion rate</p>
                            <p className="value fs-1">
                              <span className="swaps-amount">
                                {`${formatAmount(rate || 0)} ${currencyArray[0] === 'USD' ? 'NGN' : currencyArray[0]} `} ={' '}
                                {`${formatAmount(1)} ${'USD'}`}
                              </span>
                            </p>
                          </div>
                          <div className="top-up-row my-2 conversion-summary-rate-content">
                            <p className="value fs-1">
                              Amount to pay <span>(Plus Fees)</span>
                            </p>
                            <p className="value fs-1">
                              <span className="swaps-amount">{`${payloadCurrency.from} ${formatWithCommas(amount || '0')}`}</span>
                            </p>
                          </div>
                          <div className="top-up-row my-2 conversion-summary-rate-content">
                            <p className="value fs-1">You will receive</p>
                            <p className="value fs-1">
                              <span className="swaps-amount">{`${payloadCurrency.to} ${formatAmount(toAmount || 0)} `}</span>
                            </p>
                          </div>
                        </>
                      }
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </>
      );
    default:
      return null;
  }
};

export default CurrencyConversionForm;
