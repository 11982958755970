import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { IssuanceSlice } from '+types';

import createAuthSlice from './setAuth';
import createCurrencySlice from './setCurrency';
import createEmailConfigurationSlice from './setEmailConfigurationModal';
import createIssuanceSlice from './setIssuanceData';
import createMerchantSlice from './setMerchantData';
import createShared from './setShared';
import createTipsAndGuideSlice from './setTipsAndGuide';
import createUserAccessSlice from './setUsersPermission';
import storage from './storage';

type AuthSliceT = ReturnType<typeof createAuthSlice>;
type EmailConfigurationSliceT = ReturnType<typeof createEmailConfigurationSlice>;
type MerchantSliceT = ReturnType<typeof createMerchantSlice>;
type UserAccessSliceT = ReturnType<typeof createUserAccessSlice>;
type TipsAndGuideSliceT = ReturnType<typeof createTipsAndGuideSlice>;
type CurrencySliceT = ReturnType<typeof createCurrencySlice>;
type SharedSliceT = ReturnType<typeof createShared>;

type StoreStateT = AuthSliceT &
  EmailConfigurationSliceT &
  MerchantSliceT &
  UserAccessSliceT &
  TipsAndGuideSliceT &
  IssuanceSlice &
  CurrencySliceT &
  SharedSliceT;

const useStore = create<StoreStateT>()(
  devtools(
    persist(
      (set, get, api) => {
        return {
          ...createAuthSlice(set, get, api),
          ...createEmailConfigurationSlice(set, get, api),
          ...createMerchantSlice(set, get, api),
          ...createUserAccessSlice(set, get, api),
          ...createIssuanceSlice(set, get, api),
          ...createShared(set, get, api),
          ...createCurrencySlice(set, get, api)
        };
      },
      {
        name: 'kora-app-data',
        storage: createJSONStorage(() => storage)
      }
    ),
    { enabled: process.env.NODE_ENV !== 'production' }
  )
);

export default useStore;
