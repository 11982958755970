import { Link } from 'react-router-dom';

import Tooltip from '+containers/Dashboard/Shared/Tooltip';
import { switchCard } from '+containers/Dashboard/TransactionDetails/data';
import { SummaryItemType } from '+containers/Dashboard/TransactionDetailsNew/types';
import Copyable from '+dashboard/Shared/Copyable';
import { CurrencyType, ICardTransactionDetails } from '+types';
import {
  capitalize,
  capitalizeRemovedash,
  formatAmount,
  getDate,
  getDateAndTime,
  getTime,
  getTwoDigitDate,
  history,
  switchCurrency,
  switchStatus
} from '+utils';

import { statusPropsMap } from './statusMap';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

export function generateSummaryFrom(data: ICardTransactionDetails): Array<SummaryItemType> {
  return [
    {
      label: (
        <>
          Net Amount
          <Tooltip
            type="net_amount"
            image={InfoIcon}
            message={
              <em>
                Net Amount <br /> This is the amount less fee
              </em>
            }
          />
        </>
      ),
      value: `${formatAmount(data?.amount ?? 0)} ${data?.currency}`
    },
    {
      label: (
        <>
          Fee
          <Tooltip
            type="net_amount"
            image={InfoIcon}
            message={
              <em>
                Fees <br /> Total charges incurred while processesing this transaction.
              </em>
            }
          />
        </>
      ),
      value: `${formatAmount(data?.fee ?? 0)} ${data?.currency}`
    },
    { label: 'Date/Time', value: getDateAndTime(data?.date) },
    {
      label: 'Cardholder',
      value: `${data?.card ? data?.card?.card_holder?.first_name : 'Not Available'} ${
        data?.card ? data?.card?.card_holder?.last_name : 'Not Available'
      }`
    },
    {
      label: 'Transaction ID',
      value: (
        <>
          <Copyable text={data?.reference.toUpperCase?.()} textModifier={(text: string) => `${text.substring(0, 15)}...`} /> <br />
          {data?.card && data?.card.reference ? (
            <button
              type="button"
              className="btn"
              data-redirectto="original-transaction"
              onClick={() => {
                const cardType = data?.card.reserved ? 'reserved_cards' : 'customer_cards';
                history.push(`/dashboard/issuing/${cardType}/${data?.card.reference}`);
              }}
            >
              <span>See Virtual Card</span>
              <i className="os-icon os-icon-arrow-up-right" />
            </button>
          ) : null}
        </>
      )
    }
  ];
}

export function generateMoreDetailsFrom(data: ICardTransactionDetails): Array<SummaryItemType> {
  const requiresAuth = ['requires_auth', 'pending_auth', 'require_auth'].includes(data?.status);

  return [
    {
      label: 'Status',
      value: (
        <>
          <span className={`status-pill smaller ${switchStatus(data?.status)}`} />{' '}
          {requiresAuth ? 'Pending (Requires Authorization)' : (capitalize(data?.status) ?? 'Not Available')}
        </>
      )
    },
    { label: 'Currency Charged', value: data?.currency ? switchCurrency[data?.currency] : 'Not Available' },
    { label: 'Transaction Type', value: data?.type ? <strong>{capitalizeRemovedash(data?.type)}</strong> : 'Not Available' },
    {
      label: 'Amount',
      value: data?.amount ? formatAmount(data?.amount) : 'Not Available',
      hidden: !['card_creation', 'card_funding'].includes(data?.type)
    },
    {
      label: 'Amount Withdrawn',
      value: data?.amount ? `${formatAmount(data?.amount)} ${data?.currency}` : 'Not Available',
      hidden: data?.type !== 'card_withdrawal'
    },
    {
      label: 'Amount Settled',
      value: data?.amount ? formatAmount(data?.amount) : 'Not Available',
      hidden: data?.type !== 'card_transaction'
    },
    {
      label: 'Fee',
      value: data?.fee ?? 'Not Available',
      hidden: !['card_transaction', 'card_creation', 'card_funding'].includes(data?.type)
    },
    {
      label: (
        <>
          Cross-Currency Status
          <Tooltip
            type="net_amount"
            image={InfoIcon}
            message="This defines whether a transaction is performed in the issued virtual card currency or not"
          />
        </>
      ),
      value: data?.cross_currency ? 'Yes' : 'No',
      hidden: data?.type !== 'card_withdrawal'
    },
    {
      label: 'Purchasing Vendor',
      value: data?.card_acceptor_name || 'Not Available',
      hidden: ['card_creation', 'card_funding', 'card_withdrawal'].includes(data?.type)
    },
    { label: 'Date, Time', value: data?.date ? getDateAndTime(data?.date) : 'Not Available' },
    { label: 'Description', value: <span className="font-italic">{data?.description ?? 'Not Available'}</span> }
  ];
}

export function generateHolderInfoFrom(data: ICardTransactionDetails): Array<SummaryItemType> {
  return [
    {
      label: "Cardholder's Name",
      value: `${data?.card ? data?.card?.card_holder.first_name : 'Not Available'} ${
        data?.card ? data?.card?.card_holder.last_name : 'Not Available'
      }`
    },
    {
      label: 'PAN',
      value: (
        <>
          <img
            src={switchCard[data?.card ? data?.card.brand : 'mastercard']}
            className="card-logo"
            alt={data?.card ? data?.card.brand : ''}
          />
          {data?.card && data?.card.last_four ? (
            <Copyable text={`**** ${data?.card.last_four}`} spanClassName="copyable-bold" />
          ) : (
            'Not Available'
          )}
        </>
      )
    },
    {
      label: 'Card ID',
      value: data?.card && data?.card.reference ? <Copyable text={data?.card.reference} spanClassName="copyable-blue" /> : 'Not Available'
    },
    {
      label: 'Expiry Date',
      value: data?.card && data?.card.expiration_date ? getTwoDigitDate(data?.card.expiration_date) : 'Not Available'
    }
  ];
}

type Status = keyof typeof statusPropsMap;

export const generateSummaryGenerators = () => ({
  refunds: (item: any, currency: CurrencyType) => [
    { label: 'Refund ID', value: <Copyable text={item.reference} spanClassName="copyable-blue" /> },
    { label: 'Refund Amount', value: `${item.amount} ${currency}` },
    {
      label: 'Refund Status',
      value: <span style={{ color: statusPropsMap[item.status as Status].color ?? '' }}>{statusPropsMap[item.status as Status].name}</span>
    },
    {
      label: 'Date Created',
      value: `${getDate(item.created_at)} | ${getTime(item.created_at)}`,
      hidden: !item.created_at
    },
    {
      label: 'Date Completed',
      value: item.date_completed ? `${getDate(item.date_completed)} | ${getTime(item.date_completed)}` : '-- | --'
    },
    {
      label: 'Reason for Refund',
      value: (item?.reversal_reason || item.description) ?? 'Not Available'
    }
  ],
  chargebacks: (item: any, currency: CurrencyType) => [
    { label: 'Chargeback ID', value: <Copyable text={item?.reference} spanClassName="copyable-blue" /> },
    { label: 'Chargeback Amount', value: `${item?.amount} ${currency}` },
    { label: 'Escalated Amount', value: `${item?.amount} ${currency}` },
    { label: 'Accepted Amount', value: `${item?.accepted_amount} ${currency}` },
    {
      label: 'Chargeback Status',
      value: (
        <span style={{ color: statusPropsMap[item?.status as Status]?.color ?? '' }}>{statusPropsMap[item?.status as Status]?.name}</span>
      )
    },
    {
      label: 'Date Escalated',
      value: item?.escalation_date ? `${getDate(item?.escalation_date)} | ${getTime(item?.escalation_date)}` : '-- | --'
    },
    {
      label: 'Date Completed',
      value: item?.actual_resolution_date
        ? `${getDate(item?.actual_resolution_date)} | ${getTime(item?.actual_resolution_date)}`
        : '-- | --'
    }
  ]
});

export const generateChildrenGenerators = () => ({
  chargebacks: (item: any) => (
    <Link className="btn btn-primary" to={`/dashboard/issuing/issued-card-chargebacks/${item?.reference}`}>
      View Details
    </Link>
  )
});
