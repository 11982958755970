/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';

import useFocusTrap from '+containers/Dashboard/Shared/hooks/useFocusTrap';

import FeedbackHandler from '../Dashboard/Shared/FeedbackHandler';

import completed from '+assets/img/dashboard/completed-tick.svg';

interface IModalProps {
  close: () => void;
  visible?: boolean;
  hideCloseButton?: boolean;
  themeColor?: string;
  heading?: string;
  description?: string | React.ReactNode;
  content?: Record<string, any>;
  showButtons?: boolean;
  firstButtonText?: string;
  secondButtonText?: string;
  closeAction?: () => void;
  secondButtonColor?: string;
  firstButtonAction?: () => void;
  secondButtonAction?: () => void;
  firstButtonDisable?: boolean;
  secondButtonDisable?: boolean;
  completedHeading?: string;
  completedDescription?: string | React.ReactNode;
  completedImage?: React.ReactNode;
  showImage?: boolean;
  completedAction?: () => void;
  completedActionText?: string;
  size?: string;
  maxHeight?: string;
  secondButtonActionIsTerminal?: boolean;
  modalStage?: string;
  hideCompletedAction?: boolean;
  headerClassName?: string;
  hideFirstButton?: boolean;
}

const Modal = ({
  close,
  visible = true,
  hideCloseButton = false,
  themeColor = undefined,
  heading = '',
  description = '',
  content = undefined,
  showButtons = true,
  firstButtonText = 'Cancel',
  secondButtonText = 'Continue',
  closeAction = undefined,
  secondButtonColor = undefined,
  firstButtonAction = undefined,
  secondButtonAction = undefined,
  firstButtonDisable = false,
  secondButtonDisable = false,
  completedHeading = 'Success!',
  completedDescription = null,
  completedImage = null,
  showImage = true,
  completedAction = undefined,
  completedActionText = 'Dismiss',
  size = 'md',
  maxHeight = 'md',
  secondButtonActionIsTerminal = true,
  modalStage,
  hideCompletedAction,
  headerClassName
}: IModalProps) => {
  const [modalState, setModalState] = useState({ loading: false, stage: modalStage || 'init' });
  const modalContentRef = useRef(null);
  useFocusTrap(modalContentRef, visible, close);

  let actionColor;
  if (secondButtonColor) {
    actionColor = secondButtonColor;
  } else {
    actionColor = themeColor || '#2376F3';
  }

  const Header = () => (
    <div className="px-4 py-3 pb-3 border-bottom">
      <h4 id="modal-title" className={`onboarding-title ${headerClassName || ''}`} style={{ color: themeColor || '#292b2c' }}>
        {heading}
      </h4>
      <div className="onboarding-text">{description}</div>
    </div>
  );

  const ModalFooter = () => (
    <div className="px-4 pb-3" style={{ background: '#F9FBFD' }}>
      <div className="modal-footer">
        <button
          className="btn border-0"
          data-dismiss="modal"
          type="button"
          disabled={firstButtonDisable}
          onClick={() => {
            if (!firstButtonAction) {
              setModalState({ ...modalState, stage: 'init' });
              return close();
            }
            return firstButtonAction();
          }}
        >
          {firstButtonText}
        </button>
        <button
          type="button"
          className="btn"
          disabled={secondButtonDisable}
          style={{
            color: 'white',
            backgroundColor: actionColor,
            outline: actionColor
          }}
          onClick={async () => {
            try {
              setModalState({ ...modalState, loading: true });
              secondButtonAction && (await secondButtonAction());
              setModalState({ ...modalState, loading: false });
              if (secondButtonActionIsTerminal) setModalState({ ...modalState, stage: 'complete', loading: false });
            } catch (error) {
              setModalState({ ...modalState, loading: false });
            }
          }}
        >
          {modalState.loading ? (
            <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
          ) : (
            <span>{secondButtonText}</span>
          )}
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    if (modalStage) setModalState({ ...modalState, stage: modalStage });
  }, [modalStage]);

  return (
    visible && (
      <div
        aria-hidden="true"
        className={`onboarding-modal modal ${visible ? 'show' : ''} fade`}
        aria-labelledby="modal-title"
        role="dialog"
        tabIndex={-1}
        style={{ display: `${visible ? 'block' : 'none'}` }}
      >
        <div
          className={`modal-dialog modal-${size} modal-dialog-scrollable modal-centered ${
            modalState.stage === 'complete' ? 'modal-sm' : ''
          }`}
          role="document"
        >
          <div className="modal-content" style={{ maxHeight }} ref={modalContentRef}>
            {!hideCloseButton && (
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setModalState({ ...modalState, stage: 'init' });
                  if (closeAction) closeAction();
                  close();
                }}
              >
                <span className="os-icon os-icon-close" />
              </button>
            )}

            {modalState.stage === 'init' && (
              <>
                <Header />
                <FeedbackHandler component />
                {content && (
                  <div className="modal-body border-bottom">
                    <form onSubmit={e => e.preventDefault()}>
                      <div className="form-center px-2 py-1">{content}</div>
                    </form>
                  </div>
                )}
                {showButtons && <ModalFooter />}
              </>
            )}
            {modalState.stage === 'complete' && (
              <div className="modal-body p-4 onboarding-content text-center">
                <section className="modal-complete py-2">
                  {showImage && <img src={(completedImage as string) || completed} alt="complete" />}
                  <h4 className="mt-2 mb-3">{completedHeading}</h4>
                  <p>{completedDescription}</p>
                  {!hideCompletedAction && (
                    <button type="button" className="btn btn-link" onClick={() => (completedAction ? completedAction() : close())}>
                      {completedActionText}
                    </button>
                  )}
                </section>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
