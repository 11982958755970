import { StateCreator } from 'zustand';

import { SharedStoreSlice } from '+types';

const createShared: StateCreator<SharedStoreSlice> = set => ({
  currencies: [],
  merchantKYC: {},
  identityServiceAccess: {
    has_access: false,
    has_declined_request: false,
    has_pending_request: false,
    active: false
  },
  banks: [],
  savedLocation: '',
  setCurrencies: value => set(state => ({ ...state, currencies: value })),
  setMerchantKYC: value => set(state => ({ ...state, merchantKYC: value })),
  setBanks: value => set(state => ({ ...state, banks: value })),
  setSavedLocation: value => set(state => ({ ...state, savedLocation: value }))
});

export default createShared;
