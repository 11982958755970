import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { formatAmount, switchTrxnMessage } from '+utils';

import useConversionDetails from '../hooks/useConversionDetails';

const ConversionDetails = () => {
  const { data, isLoading, recipientInfo, moreTransactionDetails } = useConversionDetails();

  return (
    <div>
      <TransactionDetails loaderCount={6} isLoading={isLoading}>
        <TransactionDetails.Header
          heading={formatAmount(data?.converted_amount as number)}
          currency={data?.destination_currency}
          status={String(switchTrxnMessage[data?.status as keyof typeof switchTrxnMessage]?.name || switchTrxnMessage?.processing?.name)}
          statusBg={String(switchTrxnMessage[data?.status as keyof typeof switchTrxnMessage]?.backgroundColor)}
          statusColor={String(switchTrxnMessage[data?.status as keyof typeof switchTrxnMessage]?.color)}
          summaries={recipientInfo}
        />
        <TransactionDetails.Section heading="Details" summaries={moreTransactionDetails} />
      </TransactionDetails>
    </div>
  );
};

export default ConversionDetails;
