import React from 'react';

import { capitalize, capitalizeFirstText, getDate, getTime, lowercaseRemovedash } from '+utils/formats';

import { statusIcons, StatusIconsKeyType } from '../data/identity-data';
import useGetIdentityPermissions from '../hooks/useGetIdentityPermissions';
import { VerificationDataType } from '../types/types';

function handleEnterClick(e: React.KeyboardEvent, onClick = () => {}) {
  if (e.key === 'Enter') {
    e.preventDefault();
    onClick();
  }
}

export default function renderEventsHistory(data: VerificationDataType[], onClick: (ref?: string) => void) {
  const { canViewVerificationDetails } = useGetIdentityPermissions();
  return data?.map(tx => {
    return (
      <div
        key={`transaction_${tx.reference}`}
        className="div-table --row identity-events-row"
        role="button"
        tabIndex={0}
        onClick={() => canViewVerificationDetails && onClick(tx.reference)}
        onKeyUp={e => handleEnterClick(e, onClick)}
        ref={el => {}}
      >
        <div className="--column">
          <span className="body-row-header">Reference:</span>
          <span style={{ color: '#007bff', fontWeight: '500' }} className="text-uppercase">
            {tx.reference}
          </span>
        </div>
        <div className="--txn-status --column" style={{ display: 'flex' }}>
          <span className="body-row-header">Individual / Business&apos;:</span>
          <span className={`${!tx.full_name && 'grey-text italic'}`}>{tx.full_name || 'Not found'}</span>
        </div>

        <div className="--column">
          <span className="body-row-header">ID Status:</span>
          <span className={`${tx.status === 'failed' || (tx.status === 'not_found' && 'grey-text em')}`}>
            <span className={`status-indicator ${tx.status}`} />
            {capitalizeFirstText(lowercaseRemovedash(tx.status)) || 'N/A'}
          </span>
        </div>
        <div className="--column">
          <span className="body-row-header">Event Result:</span>
          <span className={`${tx.result === 'failed' || (!tx.result && 'grey-text em')}`}>
            {' '}
            <i className={`${statusIcons[tx?.result?.toLowerCase() as StatusIconsKeyType]} status-icon --${tx?.result?.toLowerCase()}`} />
            {capitalize(tx.result) || 'Undefined'}
          </span>
        </div>
        <div className="--column">
          <span className="body-row-header">ID Type:</span>
          <span className="">{tx.identity_type_label}</span>
        </div>
        <div className="--column">
          <span className="body-row-header">Region:</span>
          <span className="text-uppercase">{tx.country}</span>
        </div>
        <div className="--column">
          <span className="body-row-header">Date Created:</span>
          <span>
            <span>{getDate(tx.date_created)}</span> <span className="grey-text">{getTime(tx.date_created)}</span>
          </span>
        </div>
      </div>
    );
  });
}
