import RadioButton from '+containers/Dashboard/Shared/RadioButton';
import { capitalize } from '+utils';

// eslint-disable-next-line no-unused-vars
const UpdateIntervalModal = ({ value, onChange }: { value: string; onChange: (text: string) => void }) => {
  const intervalOptions = ['weekly', 'monthly'];

  return (
    <div>
      <p>
        Select how often you&apos;d like Kora to send product updates and click &apos;Subscribe&apos; to start receiving product reports
        from your Kora account.
      </p>
      <div>
        {intervalOptions.map(intervalOption => (
          <RadioButton
            key={intervalOption}
            label={`Send ${capitalize(intervalOption)}`}
            checked={value === intervalOption}
            onChange={() => onChange(intervalOption)}
            className="my-3 reasons_for_failure"
          />
        ))}
      </div>
    </div>
  );
};

export default UpdateIntervalModal;
