import Table from '+containers/Dashboard/Shared/Table';
import { useSearchQuery } from '+hooks';
import { VirtualBankServices } from '+services/virtual-bank-services';
import { capitalize, filteredOutObjectProperty, getDateAndTime, history, switchStatus } from '+utils';

import VirtualAccountsFilter from '../Shared/VirtualAccountsFilter';

export default function AccountNumbers({ reference, name }: { reference: string; name: string }) {
  const searchQuery = useSearchQuery();
  const paginationPage = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, ['page', 'tab', 'limit', 'kycinfoTab', 'eventTab', 'accountHolderTab'])
  };
  const { data, meta, isFetching } = VirtualBankServices.useGetVirtualBankAccounts({
    params: { page: paginationPage, limit, sortingParams: { account_holder_reference: reference, ...sortingParams } },
    errorMessage: 'There has been an issue while fetching your virtual bank accounts',
    refetchOnCloseFeedbackError: true
  });
  const accounts = data?.data?.data || [];
  const paging = meta?.paging;

  const AccountNumber = () => {
    return accounts?.map(each => (
      <div
        className="div-table --fvba-account-numbers --row"
        key={each?.korapay_reference}
        style={{ background: each?.status === 'suspended' ? '#FFFAF0' : '' }}
      >
        <div>
          <span className="body-row-header">Status:</span>
          <span className={`status-pill smaller ${switchStatus(each?.status)}`} />
          <span>{capitalize(each?.status)}</span>
        </div>
        <div
          onClick={() => history.push(`/dashboard/virtual-accounts/details/${each?.korapay_reference}`)}
          onKeyDown={() => history.push(`/dashboard/virtual-accounts/details/${each?.korapay_reference}`)}
          role="button"
          tabIndex={0}
        >
          <span className="body-row-header">Account Number:</span>
          <span style={{ color: '#A9AFBC' }}>
            <span style={{ fontWeight: 600, color: '#414F5F' }}>{each?.account_number}</span>{' '}
          </span>
        </div>
        <div>
          <span className="body-row-header">Bank:</span>
          <span>{each?.bank_name || 'Not Available'}</span>
        </div>
        <div>
          <span className="body-row-header">Currency</span>
          <span>{each?.currency}</span>
        </div>
        <div>
          <span className="body-row-header">Tier:</span>
          <span>Tier {each?.tier}</span>
        </div>
        <div>
          <span className="body-row-header">Date Created:</span>
          <span>
            <span>{`${getDateAndTime(each.created_at)}`}</span>
          </span>
        </div>
      </div>
    ));
  };
  return (
    <div className="content-i">
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div
              className="os-tabs-controls os-tabs-complex settlement-tabs"
              style={{ alignItems: 'center', borderBottom: '1px solid #dee2e6', marginRight: '0px' }}
            />
            <VirtualAccountsFilter
              type="account_number"
              Tab={`Account Number${paging?.total_items > 1 ? 's' : ''}`}
              totalCount={paging?.total_items}
              filterText={`assigned to ${name}`}
            />
            <Table
              tableClassName="--fvba-account-numbers"
              headings={[
                {
                  value: 'Status'
                },
                {
                  value: 'Account Number'
                },
                {
                  value: 'Bank'
                },
                {
                  value: 'Currency'
                },
                {
                  value: 'Tier'
                },
                {
                  value: 'Date Created'
                }
              ]}
              hasPagination
              loading={isFetching}
              current={paging?.current}
              totalItems={paging?.total_items}
              limitAction={limit => searchQuery.setQuery({ limit })}
              pageSize={paging?.page_size}
              actionFn={p => searchQuery.setQuery({ page: p })}
              annotation="accounts"
              EmptyStateHeading="No virtual bank accounts yet"
              EmptyStateMessage="You currently have not created any virtual bank accounts"
              tableWrapperClassName="vba-container sub"
            >
              {AccountNumber()}
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
