import { StateCreator } from 'zustand';

import { MerchantSlice } from '+types';

const createMerchantSlice: StateCreator<MerchantSlice, [], [], MerchantSlice> = () => ({
  profile: {},
  defaultMerchant: {},
  merchantEnv: '',
  sessionActive: false,
  issuanceAccessRequested: false,
  is2FactorEnabled: false,
  canGoLive: false,
  productAccess: {
    card_issuance: { USD: false },
    identity: { USD: false }
  },
  bulkPayoutDraftData: [
    {
      reference: '',
      data: [],
      newUpdate: false
    }
  ],
  tierLevel: null,
  productTierLimit: 0
});

export default createMerchantSlice;
