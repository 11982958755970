const AccountSuspensionNotification = ({
  onRequestAccess,
  isRequestingAccess,
  cardCategory
}: {
  isRequestingAccess: boolean;
  onRequestAccess: () => Promise<void>;
  cardCategory: string | undefined;
}) => {
  return (
    <>
      {cardCategory === 'reserved' ? (
        <div className="fade-in p-3 my-3 text-center account-suspension-notif">
          <span>Your access to Reserved Virtual Cards has been revoked. To continue creating and managing your RVCs,</span>{' '}
          <button className="ml-1 p-1 btn btn-link" type="button" onClick={() => onRequestAccess()}>
            {isRequestingAccess ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            ) : (
              <span>Request Reactivation</span>
            )}
          </button>
        </div>
      ) : (
        <div className="fade-in p-3 my-3 text-center account-suspension-notif">
          <span>Your access to Customer Virtual Cards has been revoked. To continue creating and managing your customer cards,</span>{' '}
          <button className="ml-1 p-1 btn btn-link" type="button" onClick={() => onRequestAccess()}>
            {isRequestingAccess ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
            ) : (
              <span>Request Access Here</span>
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default AccountSuspensionNotification;
