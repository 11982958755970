import { useEffect, useRef, useState } from 'react';

import useFeedbackHandler from '+hooks/feedbackHandler';
import { TransactionServices } from '+services/transaction-services';
import { backwardAmountInput, cleanInput, formatAmount, history, logBreadCrumb, twoFAInputValidation } from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import Modal from '../../Shared/Modal';
import WithdrawWarning from './WithdrawWarning';

interface IWalletPayoutsModalProps {
  close: () => void;
  currency: string;
  balance?: number;
  refetchBalance: () => void;
  initiateTransfer: Record<string, any>;
  processTransfer: Record<string, any>;
  resendOTP: Record<string, any>;
  paymentRef: string;
  identifier: string;
  minPayoutLimit: number;
  maxPayoutLimit: number;
  get2FAType?: () => void;
  type?: string;
}

const WalletPayoutsModal = ({
  close,
  currency,
  balance = 0,
  refetchBalance,
  initiateTransfer,
  processTransfer,
  resendOTP,
  paymentRef,
  identifier,
  minPayoutLimit,
  maxPayoutLimit,
  get2FAType,
  type: modalType
}: IWalletPayoutsModalProps) => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();

  const timerInterval = useRef(null);
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(false);
  const [codeSent, setCodeSent] = useState(true);
  const [time, setTime] = useState(0);
  const [twoFactorType, setTwoFactorType] = useState('otp');
  const [withdrawalDetails, setWithdrawalDetails] = useState({
    amount: null,
    description: null,
    email: null,
    pin: '',
    name: ''
  });

  useEffect(() => {
    if (codeSent && type === 'otpStage') {
      if (timerInterval.current) {
        clearInterval(timerInterval.current);
      }

      timerInterval.current = setInterval(() => {
        if (time <= 0) {
          clearInterval(timerInterval.current);
          setCodeSent(false);
        } else {
          setTime(time - 1);
        }
      }, 1000);
    }
    return () => clearInterval(timerInterval.current);
  }, [time]);

  useEffect(() => {
    if (parseFloat(withdrawalDetails.amount) > maxPayoutLimit) {
      feedbackInit({
        message: `Your maximum payout limit is ${formatAmount(maxPayoutLimit)} ${currency} per transaction.`,
        type: 'error',
        componentLevel: true
      });
    } else {
      closeFeedback();
    }
  }, [withdrawalDetails.amount]);

  useEffect(() => {
    setTwoFactorType(modalType);
  }, [modalType]);

  useEffect(() => {
    get2FAType && get2FAType(twoFactorType);
    setWithdrawalDetails(details => ({ ...details, pin: '' }));
  }, [twoFactorType]);

  const transferData = {
    two_factor_type: twoFactorType,
    reference: '',
    destination: {
      type: 'wallet',
      amount: withdrawalDetails.amount,
      currency,
      narration: withdrawalDetails.description || 'Withdrawal from Merchant Balance',
      customer: {
        email: withdrawalDetails.email
      }
    }
  };

  const setDisabled = () => {
    switch (type) {
      case 'otpStage':
        if (twoFAInputValidation(twoFactorType, withdrawalDetails.pin)) return false;
        return true;
      default:
        // eslint-disable-next-line no-case-declarations
        const number = parseFloat(withdrawalDetails.amount);
        if (number >= minPayoutLimit && number <= maxPayoutLimit) {
          if (number <= parseFloat(balance) && withdrawalDetails.name) return false;
          return true;
        }
        return true;
    }
  };

  const confirmBeneficiary = TransactionServices.useConfirmWalletBeneficiary({
    onSuccess: data => {
      setLoading(false);
      setWithdrawalDetails(details => ({ ...details, name: data.data.name }));
    },
    hasFeedbackTimeout: true,
    bannerLevel: true,
    showSuccessMessage: false
  });

  const defaultWithdrawContent = () => {
    return (
      <>
        <div className="form-group">
          <label htmlFor="amount" className="withdraw-label">
            <span className="dark">Amount to send</span>
          </label>

          <input
            id="amount"
            type="number"
            className="form-control"
            name="amount"
            value={withdrawalDetails.amount || ''}
            placeholder="eg 1,000.00"
            maxLength="11"
            onChange={e => {
              const formattedAmount = backwardAmountInput(e.target.value);
              setWithdrawalDetails(details => ({ ...details, amount: formattedAmount }));
            }}
          />

          <label htmlFor="amount" className="withdraw-label mt-2 small">
            <span>
              Minimum amount:{' '}
              <span className={`dark ${parseFloat(withdrawalDetails.amount) < minPayoutLimit && 'red'}`} id="min">
                {currency} {formatAmount(minPayoutLimit)}
              </span>
            </span>
            <span>
              Available balance:{' '}
              <span className={`dark ${parseFloat(withdrawalDetails.amount) > balance && 'red'}`}>
                {currency} {formatAmount(balance)}
              </span>
            </span>
          </label>
        </div>

        <div className="form-group">
          <label htmlFor="description" className="withdraw-label">
            <span className="dark">
              Description <span style={{ opacity: 0.7 }}>(optional)</span>
            </span>
          </label>
          <input
            id="description"
            value={withdrawalDetails.description || ''}
            className="form-control"
            name="description"
            placeholder="Enter a description"
            onChange={e => {
              const formattedInput = cleanInput(e.target.value);
              setWithdrawalDetails(details => ({ ...details, description: formattedInput }));
            }}
          />
        </div>

        <div className="form-group merchant-email">
          <label htmlFor="email" className="withdraw-label">
            <span className="dark">Merchant email</span>
          </label>
          <div className="verify-wallet-email">
            <input
              id="email"
              value={withdrawalDetails.email || ''}
              className="form-control"
              name="email"
              placeholder="treasury@korapay.com"
              onChange={e => {
                const formattedInput = cleanInput(e.target.value);
                setWithdrawalDetails(details => ({ ...details, email: formattedInput }));
              }}
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setLoading(true);
                confirmBeneficiary.mutate({ email: withdrawalDetails.email, currency });
                logBreadCrumb({ event: breadCrumbEvents.balances.verifyButtonClicked });
              }}
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
              ) : (
                'Verify'
              )}
            </button>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="merchant-name" className="withdraw-label">
            <span className="dark">Merchant name</span>
          </label>
          <input id="merchant-name" value={withdrawalDetails.name || ''} name="name" readOnly className=" form-control read-only" />
        </div>

        <WithdrawWarning />
      </>
    );
  };

  const otpContent = () => {
    let inputLabel = 'One Time PIN';
    let inputPlaceholder = '';
    if (twoFactorType === 'totp') {
      inputLabel = 'Authentication Code';
      inputPlaceholder = 'Enter authentication code';
    } else if (twoFactorType === 'totp_recovery_code') {
      inputLabel = 'Recovery Code';
      inputPlaceholder = 'Enter recovery code';
    }
    return (
      <div className="bankForm-container">
        <div className="element-box saved-bank-container mb-2 p-2">
          <div>
            {twoFactorType === 'totp_recovery_code' ? (
              <p>Can't use your authenticator app? Enter one of your previously saved recovery codes to validate this transaction.</p>
            ) : (
              <p style={{ color: '#919295', fontWeight: 400, fontSize: '12px' }}>
                <strong>
                  You are about to send {currency} {formatAmount(withdrawalDetails.amount)} to {withdrawalDetails.name}
                </strong>{' '}
                You will not be charged a fee for this transaction.
              </p>
            )}
          </div>
        </div>

        <label htmlFor="amount">{inputLabel}</label>
        <input
          type="number"
          name="pin"
          maxLength="7"
          value={withdrawalDetails.pin || ''}
          placeholder={inputPlaceholder}
          autoComplete="one-time-code"
          onChange={e => {
            const formattedInput = cleanInput(e.target.value);
            setWithdrawalDetails(details => ({ ...details, pin: formattedInput }));
          }}
        />
        {twoFactorType === 'totp' && (
          <div className="recovery-code">
            Cant access authenticator app?{' '}
            <button type="button" onClick={() => setTwoFactorType('totp_recovery_code')} className="recovery-code-btn">
              Confirm using recovery codes
            </button>
          </div>
        )}
        {twoFactorType === 'otp' && (
          <label htmlFor="amount" className="withdraw-label mt-2 small">
            <span>
              <span className="dark">If you didn’t recieve a code? </span>
              {!codeSent ? (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={async () => {
                    setCodeSent(true);
                    setTime(30);
                    await resendOTP.mutateAsync({
                      identifier
                    });
                  }}
                >
                  Resend code.
                </button>
              ) : (
                <span style={{ marginLeft: '7px' }}>Resend code in {time} secs.</span>
              )}
            </span>
          </label>
        )}
      </div>
    );
  };

  const getOtpStageDescription = () => {
    switch (twoFactorType) {
      case 'totp':
        return (
          <>
            To proceed, enter the authorization code from your <b>authenticator app</b> in the space provided below to confirm this
            transaction.
          </>
        );
      case 'otp':
        return 'To proceed, enter the OTP (one-time PIN) that was sent to your email';
      default:
        return '';
    }
  };

  const getOtpStageFirstBtn = () => {
    if (twoFactorType === 'totp_recovery_code') {
      return async () => {
        setTwoFactorType('totp');
      };
    }
    return undefined;
  };

  const switchWithdrawModal = kind => {
    let content;
    switch (kind) {
      case 'otpStage':
        content = {
          heading: `Confirm ${twoFactorType === 'totp_recovery_code' ? 'using recovery code' : 'transaction'}`,
          description: getOtpStageDescription(),
          content: otpContent(),
          firstButtonText: twoFactorType === 'totp_recovery_code' ? 'Back' : undefined,
          secondButtonText: 'Confirm',
          firstButtonAction: getOtpStageFirstBtn(),
          secondButtonAction: async () => {
            const verifyData = {
              payment_reference: paymentRef,
              auth_data: {
                two_factor_type: twoFactorType,
                identifier,
                code: withdrawalDetails.pin
              }
            };
            await processTransfer.mutateAsync(verifyData);
          },
          completedHeading: 'Withdrawal Created',
          completedDescription: 'Your payment has been initiated.',
          completedActionText: 'View payment details',
          completedAction: () => history.push(`/dashboard/payouts/${paymentRef}`)
        };
        break;
      default:
        content = {
          heading: `Send money to Korapay wallet.`,
          description: 'Please enter the details of the transaction to proceed.',
          content: defaultWithdrawContent(),
          secondButtonText: 'Next',
          secondButtonAction: async () => {
            logBreadCrumb({ event: breadCrumbEvents.balances.attemptWithdrawButton('Next', 'wallet') });
            await confirmBeneficiary.mutateAsync({ email: withdrawalDetails.email, currency });
            await initiateTransfer.mutateAsync(transferData);
            closeFeedback();
            setType('otpStage');
            setTime(30);
          }
        };
        break;
    }

    return {
      size: 'md',
      close: () => {
        setType('init');
        setWithdrawalDetails({});
        setLoading(false);
        close();
      },
      secondButtonDisable: setDisabled(),
      secondButtonActionIsTerminal: kind === 'otpStage',
      ...content
    };
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Modal {...switchWithdrawModal(type)} />;
};

export default WalletPayoutsModal;
