import { Link } from 'react-router-dom';

import { breadCrumbEvents } from '+/utils/bugsnag-events';
import Copyable from '+containers/Dashboard/Shared/Copyable';
import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import { CardBrandType, CurrencyType, DataType, PayInMappingTypes, StatusType } from '+types';
import {
  capitalize,
  capitalizeRemovedash,
  cardStatus,
  daysfromToday,
  formatAmount,
  getDate,
  getDateAndTime,
  getTime,
  logBreadCrumb,
  switchBank,
  switchCard,
  switchCurrency,
  switchDisputeStatus,
  switchReason,
  switchStatus
} from '+utils';

import { ChargebackType, RefundType, ReversalType } from './payInDetailsType';

import cardLogo from '+assets/img/dashboard/card-logo.svg';
import infoIcon from '+assets/img/dashboard/information-button.svg';
import mobileIconSVG from '+assets/img/dashboard/mobile.svg';
import overpaymentIcon from '+assets/img/dashboard/overpayment.svg';
import underpaymentIcon from '+assets/img/dashboard/underpayment.svg';
import walletIcon from '+assets/img/dashboard/wallet.svg';

export const payInMethodMapping: Record<PayInMappingTypes, string> = {
  bank_transfer: 'Bank Transfer',
  wallet: 'Wallet Transfer',
  card: 'Card Payment',
  pay_with_bank: 'Pay with Bank (Direct debit)',
  mobile_money: 'Mobile Money'
};

export const payInHeaderSummary = (trx?: DataType) => {
  return [
    ...((trx?.status as unknown as StatusType) !== 'pre_authorized'
      ? [
          {
            label: 'Amount Expected',
            value: `${formatAmount(trx?.amount_charged)} ${trx?.currency}`
          }
        ]
      : []),
    {
      label: (
        <>
          Fee
          <ToolTip type="fee" image={infoIcon} message={<em>Total charges incurred while processing this transaction</em>} />
        </>
      ),
      value: [!!trx?.fee, !!trx?.vat, !!trx?.currency].includes(false)
        ? 'N/A'
        : `${formatAmount(parseFloat(trx?.fee ?? '') + parseFloat(trx?.vat ?? ''))} ${trx?.currency}`
    },
    { label: 'Date Completed', value: trx?.completed_at ? `${getDate(trx.completed_at)}, ${getTime(trx.completed_at)}` : 'Not Available' },
    ...(trx?.payment?.customer.name ? [{ label: 'Customer Name', value: capitalize(trx.payment.customer.name) }] : []),
    {
      label: 'Transaction ID',
      value: <Copyable text={trx?.payment.reference ?? ''} textModifier={text => text?.toUpperCase()} />
    }
  ];
};

export const payInMoreTrxDetailBanner = (trx?: DataType) => {
  return (
    trx?.meta?.payment_event_action?.event && (
      <div data-testid="payin-more-trx-details-banner" className="overpayment-underpayment-info">
        <img
          src={trx?.meta?.payment_event_action?.event === 'overpayment' ? overpaymentIcon : underpaymentIcon}
          alt={trx?.meta?.payment_event_action?.event}
        />
        <span>
          There is an <strong>{trx?.meta?.payment_event_action?.event}</strong> on this transaction.
        </span>
      </div>
    )
  );
};

const renderStatus = (status: string) => {
  const isCardStatus = Object.keys(cardStatus).includes(status);

  if (isCardStatus) {
    return (
      <>
        {cardStatus[status as keyof typeof cardStatus]}
        <span
          style={{
            background: '#6474FF21',
            color: '#6474FF',
            marginLeft: 5,
            padding: '2px 4px',
            borderRadius: 8,
            fontSize: 12
          }}
        >
          {status.includes('auth') ? 'AUTH' : 'CAPTURE'}
        </span>
      </>
    );
  }
  return (status && capitalizeRemovedash(status)) || 'Not Available';
};

const authExpiryText = (expiryDate: string) => {
  const diff = daysfromToday(expiryDate, true);

  if (diff.includes('in')) return `${diff.split(' ').slice(1).join(' ').split(',').join(' ')} remaining`;
  if (diff.includes('ago')) return 'Expired';
  return diff;
};

export const payInMoreTrxDetailsSection = (trx?: DataType) => {
  const targetRef = trx?.meta?.void?.target_transaction_reference;
  const createdDate = trx?.created_at ?? trx?.transaction_date;

  const payinConversionRate = () => {
    const conversions = trx?.payment?.conversion;
    if (!conversions) return;

    const { settlement_currency: settlementCurrency, rate } = conversions;
    const mergeCurrency = settlementCurrency + trx?.currency;
    const formatRate = formatAmount(rate);

    if (mergeCurrency === 'NGNUSD') return `1 USD → ${formatRate} NGN`;
    if (mergeCurrency === 'USDNGN') return `${formatRate} NGN → 1 USD`;
  };

  return [
    {
      label: 'Status',
      value: (
        <>
          <span
            className={`status-pill smaller more-details-opt ${
              (trx?.status as StatusType) === 'requires_auth' ? 'yellow' : switchStatus(trx?.status ?? '')
            }`}
          />
          {renderStatus(trx?.status ?? '')}
        </>
      )
    },

    ...(targetRef
      ? [
          {
            label: 'Target',
            value: (
              <Link to={`/dashboard/pay-ins/${targetRef}`} className="target-ref">
                {targetRef} <i className="os-icon os-icon-arrow-up-right" />
              </Link>
            )
          }
        ]
      : []),

    ...(['expired', 'failed', 'flagged'].includes(trx?.status ?? '')
      ? [{ label: 'Reason for ' + switchReason(trx?.status ?? ''), value: trx?.message ?? 'Not Available' }]
      : []),

    ...(trx?.amount_collected ? [{ label: 'Amount Paid', value: `${formatAmount(trx?.amount_collected)} ${trx?.currency}` }] : []),

    ...(trx?.payment_source_type === 'bank_transfer'
      ? [{ label: 'Amount Expected', value: `${formatAmount(trx?.amount_charged)} ${trx?.currency}` }]
      : []),

    ...(trx?.payment?.conversion
      ? [
          {
            label: 'Exchange Rate',
            value: payinConversionRate()
          },
          { label: 'Settlement Currency', value: trx?.payment?.conversion?.settlement_currency },
          {
            label: 'Settlement Amount',
            value: trx?.payment?.conversion?.settlement_amount
              ? `${formatAmount(trx?.payment?.conversion?.settlement_amount)} ${trx?.payment?.conversion?.settlement_currency}`
              : 'N/A'
          }
        ]
      : []),

    { label: 'Currency Charged', value: switchCurrency[trx?.currency as CurrencyType] },

    ...(['USD', 'GBP'].includes(trx?.currency ?? '')
      ? [
          { label: 'Transaction Region', value: trx?.destination?.details?.payment_region || 'Not Available' },
          { label: 'Payment Type', value: trx?.destination?.details?.payment_method_type || 'Not Available' },
          { label: 'SWIFT Code', value: trx?.destination?.details?.payment_method_value || 'Not Available' }
        ]
      : []),

    { label: 'Fee Bearer', value: `${trx?.merchant_bears_cost ? 'Merchant' : 'Customer'}` },

    ...(trx?.meta?.stan
      ? [
          {
            label: (
              <>
                STAN <ToolTip image={infoIcon} type="stan" message="System Trace Audit Number" />
              </>
            ),
            value: <Copyable text={trx.meta.stan} />
          }
        ]
      : []),

    ...(trx?.meta?.receipt
      ? [
          {
            label: (
              <>
                RNN <ToolTip image={infoIcon} type="rnn" message="Reference Retrieval Number" />
              </>
            ),
            value: <Copyable text={trx.meta.receipt} />
          }
        ]
      : []),

    ...(trx?.meta?.gateway_code ? [{ label: 'Approval Code', value: <Copyable text={trx.meta.gateway_code} /> }] : []),

    { label: 'Date Created', value: createdDate ? `${getDate(createdDate)}, ${getTime(createdDate)}` : 'Not Available' },

    { label: 'Date Completed', value: trx?.completed_at ? `${getDate(trx.completed_at)}, ${getTime(trx.completed_at)}` : 'Not Available' },

    { label: 'Internal Reference', value: <Copyable text={trx?.reference ?? ''} /> },

    ...(trx?.auth_data?.pre_auth_expiry
      ? [
          {
            label: 'Authorization Expiry',
            value: (
              <>
                {getDateAndTime(trx.auth_data?.pre_auth_expiry)}{' '}
                <i style={{ color: '#A9AFBC', fontStyle: 'italic' }}>({authExpiryText(trx.auth_data?.pre_auth_expiry)})</i>
              </>
            )
          }
        ]
      : []),

    {
      label: 'Description',
      value:
        trx?.channel === 'web' && trx?.source?.type === 'bank_transfer'
          ? 'Balance Top Up'
          : (trx?.narration ?? trx?.description ?? 'No description provided')
    }
  ];
};

export const accNumberGeneratedForPaymentSection = (trx: DataType) => [
  {
    label: 'Bank',
    value: (
      <>
        <img id="bank-icon" src={switchBank(trx.source?.virtual_bank_account?.bank_name)} alt="bank icon" style={{ marginRight: 7 }} />
        {capitalize(trx.source?.virtual_bank_account?.bank_name || 'No Bank Information Available')}
      </>
    )
  },

  {
    label: 'Account Number',
    value: trx.source?.virtual_bank_account?.account_number || 'Not Available'
  },

  {
    label: 'Account Name',
    value: capitalize(trx.source?.virtual_bank_account?.account_name || 'Not Available')
  },

  {
    label: 'Expired At',
    value: trx.source.virtual_bank_account.expiry
      ? `${getDate(trx.source.virtual_bank_account.expiry)}, ${getTime(trx.source?.virtual_bank_account.expiry)}`
      : 'Not Available'
  }
];

const formatPAN = (value: string): string => {
  const separated = value.match(/.{1,4}/g) || [];
  return separated.join(' ');
};

export const payInRecipientInformationSection = (trx: DataType) => [
  { label: 'Payment Method', value: payInMethodMapping[trx?.source?.type as PayInMappingTypes] ?? 'Not Available' },

  ...(['bank_transfer', 'pay_with_bank'].includes(trx?.source?.type)
    ? [
        {
          label: 'Bank',
          value: (
            <>
              <img id="bank-icon" src={switchBank(trx?.source.details?.bank_slug)} alt="bank icon" style={{ marginRight: 8 }} />
              {capitalize(trx?.source.details?.bank_name || 'No Bank Information Available')}
            </>
          )
        }
      ]
    : []),

  ...(trx?.source?.type === 'wallet'
    ? [
        {
          label: (
            <>
              <img id="wallet-icon" src={walletIcon} alt="wallet icon" style={{ marginRight: 7 }} /> Korapay wallet
            </>
          ),
          value: trx?.payment?.customer?.email ?? 'Not provided'
        }
      ]
    : []),

  ...(trx?.source?.type === 'mobile_money'
    ? [
        {
          label: (
            <>
              <img id="phone-icon" src={mobileIconSVG} alt="phone icon" style={{ height: 23, width: 13, marginRight: 7 }} />
              {trx?.source?.details?.network_provider || 'Operator Information Unavailable'}
            </>
          ),
          value: trx?.source?.details?.mobile_number || 'Not Available'
        }
      ]
    : []),

  ...(trx?.source?.type === 'card'
    ? [
        trx?.source?.details?.masked_pan
          ? {
              label: (
                <>
                  <img
                    src={switchCard[trx?.source?.details?.card_type as CardBrandType]}
                    className="card-logo"
                    alt="card-logo"
                    style={{ width: '1.5rem', marginRight: '8px' }}
                  />
                  {capitalize(trx?.source?.details?.card_type)}
                </>
              ),
              value: trx?.meta?.card_details?.first_six
                ? formatPAN(`${trx?.meta?.card_details?.first_six}${trx?.source.details.masked_pan?.slice(6)}`)
                : formatPAN(trx?.source.details.masked_pan)
            }
          : {
              label: <img src={cardLogo} className="card-logo" alt="card-logo" />,
              value: 'No card information available'
            }
      ]
    : []),

  ...(['bank_transfer', 'pay_with_bank', 'wallet'].includes(trx?.source?.type)
    ? [
        {
          label: 'Account Number',
          value: trx.source?.details?.account_number || 'Not Available'
        }
      ]
    : []),

  ...(trx?.source?.type === 'bank_transfer'
    ? [
        {
          label: 'Account Name',
          value: capitalize(trx.source?.details?.account_name || 'Not Available')
        }
      ]
    : []),

  ...(['card', 'wallet', 'pay_with_bank', 'mobile_money'].includes(trx?.source?.type)
    ? [
        {
          label: 'Customer Name',
          value: capitalize(trx?.payment?.customer?.name || 'Not Available')
        }
      ]
    : []),

  { label: 'Email', value: trx?.payment?.customer?.email || 'Not provided' }
];

export const payInReceiptFn = (trx: DataType) => {
  return {
    id: trx.reference,
    txType: 'pay-ins' as const,
    amount: formatAmount(trx.amount_paid),
    currency: trx.currency,
    customerInfo: `Receipt For ${trx.source?.type === 'bank_transfer' ? trx?.source?.details?.account_name : trx?.payment?.customer?.name}`,
    summaryInfo: 'Payment has been received. View additional details below.',
    isInternalTransaction: true,
    summaryList: [
      {
        label: 'Amount Paid',
        value: `${formatAmount((trx?.status as StatusType) === 'success' ? trx?.amount_collected || trx?.amount_charged : trx?.amount_collected)} ${trx.currency}`
      },
      {
        label: 'Fees',
        value: `${formatAmount(parseFloat(trx?.fee || '0') + parseFloat(trx?.vat || '0'))} ${trx.currency}`
      },
      {
        label: 'Net Amount',
        value: formatAmount(trx.amount_paid)
      },
      {
        label: 'Channel',
        value: payInMethodMapping[trx.source?.type as PayInMappingTypes] || 'Not Available'
      },

      ...(trx.trace_id
        ? [
            {
              label: 'Trace ID',
              value: trx.trace_id
            }
          ]
        : []),
      {
        label: 'Date Completed',
        value: trx.completed_at ? `${getDate(trx.completed_at)}, ${getTime(trx.completed_at)}` : 'Not Available'
      },

      {
        label: 'Description',
        value: trx.narration || trx.description || 'No description provided'
      }
    ]
  };
};

export const viewPayInReceiptButton = ({ onClick }: { onClick: () => void }) => {
  return {
    children: 'View Receipt',
    onClick: () => {
      onClick();
      logBreadCrumb({
        event: breadCrumbEvents.receipt.viewReceiptButton
      });
    },
    disabled: false,
    hidden: false,
    variant: 'grey'
  } as const;
};

export const openRefundModalButton = ({ onClick, trx }: { onClick: () => void; trx: DataType }) => {
  return {
    children: (
      <>
        <i className="os-icon os-icon-corner-up-left" /> {trx?.can_reverse_payment ? 'Refund...' : 'Refund Requested...'}
      </>
    ),
    onClick: () => {
      onClick();
      logBreadCrumb({
        event: breadCrumbEvents.disputes.createRefundButton
      });
    },
    disabled: !trx?.can_reverse_payment,
    hidden: false,
    variant: 'secondary'
  } as const;
};

export const payInDisputesFn = (trx: DataType) => {
  const refunds = trx.payment_reversals?.filter(({ type }: { type: string }) => type === 'refund');
  const reversals = trx.payment_reversals?.filter(({ type }: { type: string }) => type === 'underpayment' || type === 'overpayment');
  const chargebacks = trx.payment_reversals?.filter(({ type }: { type: string }) => type === 'chargeback');

  return {
    refunds: refunds?.map((refund: RefundType) => {
      return {
        amount: refund.amount,
        completedAt: refund.completed_at,
        dateCreated: refund.created_at,
        reference: refund.reference,
        status: refund.status,
        destination: refund.destination,
        type: refund.type,
        reversal_reason: refund.reversal_reason
      };
    }),

    reversals: reversals?.map(({ type, destination, status, reference, amount }: ReversalType) => ({
      reference,
      destination: destination,
      status,
      type,
      amount
    })),

    chargebacks: chargebacks?.map((arg: ChargebackType) => ({
      reference: arg.reference,
      amount: arg.amount,
      status: arg.status,
      currency: trx.currency,
      type: arg.type,
      destination: arg.destination === 'disbursement_wallet' ? `Balance` : 'Customer'
    }))
  };
};

export const payInDisputeSummaryGeneratorsFn = () => ({
  refunds: (item: ReturnType<typeof payInDisputesFn>['refunds']) => {
    const formatStatus = ['failed', 'processing', 'pending', 'manual'].includes(item.status.toLowerCase())
      ? 'processing'
      : item.status.toLowerCase();

    const statusObj = switchDisputeStatus(formatStatus);

    return [
      { label: 'Refund ID', value: <Copyable text={item.reference} spanClassName="copyable-blue" /> },

      { label: 'Channel', value: capitalize(item.type || 'Not Available') },

      { label: 'Refund Destination', value: capitalize(item.destination || 'Not Available') },

      { label: 'Refund Status', value: <span style={{ color: statusObj.color || '' }}>{statusObj.name || 'Not Available'}</span> },

      { label: 'Date Created', value: item.dateCreated ? `${getDate(item.dateCreated)}, ${getTime(item.dateCreated)}` : 'Not Available' },

      { label: 'Reason for Refund', value: capitalize(item.reversal_reason || 'Not Available') }
    ];
  },

  reversals: (item: ReversalType) => {
    const statusObj = switchDisputeStatus(item.status);

    return [
      { label: 'Reason for Reversal', value: capitalize(item.type || 'Not Available') },

      { label: 'Reversal Destination', value: capitalize(item.destination || 'Not Available') },

      {
        label: 'Reversal Status',
        value: item.status ? <span style={{ color: statusObj.color || '' }}>{statusObj.name}</span> : 'Not Available'
      },

      { label: 'Reference', value: <Copyable text={item.reference} spanClassName="copyable-blue" /> }
    ];
  },

  chargebacks: (item: ReturnType<typeof payInDisputesFn>['chargebacks']) => {
    const statusObj = switchDisputeStatus(item.status);

    return [
      { label: 'Reference', value: <Copyable text={item?.reference} spanClassName="copyable-blue" /> },

      { label: 'Chargeback Destination', value: capitalize(item?.destination) },

      { label: 'Chargeback Status', value: <span style={{ color: statusObj.color }}>{statusObj.name}</span> },

      { label: 'Reason for Chargeback', value: capitalize(item?.type) }
    ];
  }
});

export const payinDisputeChildrenGenerators = () => {
  const detailsBtnFn = (to: string) => (
    <Link to={to} style={{ fontWeight: 600 }}>
      <button className="btn btn-primary" type="button">
        View Details
      </button>
    </Link>
  );

  return {
    refunds: (item: RefundType) => detailsBtnFn(`/dashboard/disputes/refunds/${item?.reference}`),
    chargebacks: (item: ChargebackType) => detailsBtnFn(`/dashboard/disputes/chargebacks/${item?.reference}`)
  };
};
