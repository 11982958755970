import dayjs from 'dayjs';

import DatePicker from '+containers/Shared/DatePicker';
import { IKYCReservedVC } from '+types';

const KYCReservedVC = ({ formik, representatives = [] }: IKYCReservedVC) => {
  const { values, touched, errors, handleBlur, setFieldValue } = formik;
  const today = dayjs();
  const eighteenYearsAgo = today.subtract(18, 'year').toDate();

  return (
    <div className="d-flex flex-column fade-in" style={{ gap: '0.5rem' }}>
      <div className="form-field">
        <div>
          <label id="business-rep" htmlFor="business-representative" className="label label--bold">
            Assign a business representative as cardholder
          </label>
          <p className="business-rep-description">
            The selected representative name will be used as the default cardholder’s name for all your reserved cards.
          </p>
        </div>

        <select
          id="business-representative"
          className="form-control"
          {...formik.getFieldProps?.('cardholderName')}
          aria-errormessage="last-name-error"
          aria-invalid={touched?.cardholderName && errors?.cardholderName ? 'true' : 'false'}
          aria-labelledby="business-rep business-rep-description"
        >
          <option value="">select user</option>
          {representatives.map(rep => (
            <option value={rep.name} key={rep.name}>
              {rep.name}
            </option>
          ))}
        </select>
        <span id="last-name-error" className="field-error" aria-live="assertive">
          {touched?.cardholderName && errors?.cardholderName}
        </span>
      </div>

      <div className="form-field">
        <div>
          <label id="dob" className="label label--bold" htmlFor="dob">
            Date of birth (DOB)
          </label>
          <p className="field-desc" id="dob-description">
            Enter the DOB of the selected representative. We will use their DOB and their BVN to complete your reserved virtual card
            creation.
          </p>
        </div>

        <DatePicker
          maxDate={eighteenYearsAgo}
          placeholder="YYYY-MM-DD"
          selected={(values?.dob as unknown) as Date}
          dateFormat="yyyy-MM-dd"
          className={`${touched?.dob && errors?.dob ? 'invalid-input' : ''}`}
          onChange={date => setFieldValue?.('dob', date)}
          autoComplete="off"
          onBlur={handleBlur}
          name="dob"
          id="dob"
          aria-errormessage="dob-error"
          ariaLabelledBy="dob dob-description"
        />
        <span id="dob-error" className="field-error" aria-live="assertive">
          {touched?.dob && errors?.dob}
        </span>
      </div>

      <div className="flex-grow-1 flex-shrink-0" style={{ flexBasis: 220 }} />
    </div>
  );
};

export default KYCReservedVC;
