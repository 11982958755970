/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import { getOtpDescriptors } from '+containers/Dashboard/Issuing/utils';
import Modal from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler, useOTPAuth, useSearchQuery } from '+hooks';
import { CardIssuanceServices } from '+services/card-issuance-services';
import useStore from '+store';
import {
  cardWithdrawalOtpResponseType,
  CurrencyType,
  IModalProps,
  IssuedCardActionModalStageType,
  IssuedCardActionType,
  OtpResponseType,
  TIssuedCardActionModalFormValues
} from '+types';
import { formatAmount, twoFAInputValidation } from '+utils';

import CardFundingForm from './CardFundingForm';
import CardWithdrawalForm from './CardWithdrawalForm';
import OTPAuthorizationForm from './OTPAuthorizationForm';

const CardActionsModal = ({
  action,
  onCloseModal,
  cardBalance
}: {
  action: IssuedCardActionType;
  onCloseModal: () => void;
  cardBalance: number;
}) => {
  const { ref: cardReference } = useParams<{ ref: string }>();
  const {
    defaultMerchant: { email: merchantEmail },
    walletBalance
  } = useStore(store => store);
  const queryClient = useQueryClient();

  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [stage, setStage] = useState<IssuedCardActionModalStageType>(action === 'fund_card' ? 'fund_card' : 'withdraw_from_card');
  const [hasHitMaxAttempts, setHasHitMaxAttempts] = useState(false);
  const [fundingRef, setFundingRef] = useState('');
  const [withdrawalRef, setWithdrawalRef] = useState('');
  const [isSuccessfulAuth, setIsSuccessfulAuth] = useState(false);
  const searchQuery = useSearchQuery<{ currency: CurrencyType }>();
  const currency = searchQuery.value.currency ?? 'USD';

  const availableWalletBalance = walletBalance?.[currency]?.available_balance ?? 0;
  const { updateCountdownStatus, countdownIsCompleted, authState, resendOTP, updateAuthState } = useOTPAuth();
  const { otpLabel } = getOtpDescriptors(authState.two_factor_type);

  const handleAuthorizationError = (error: any) => {
    let errorMessage = error.response?.data?.message;
    if (errorMessage === 'invalid request data') {
      const errors = error.response?.data?.data;
      const keys = Object.keys(errors);
      if (keys.length) {
        errorMessage = errors[keys[0]]?.message || errorMessage;
      }
    }
    if (error.response?.data?.message?.includes('0 attempt left')) {
      errorMessage = 'You have exceeded the maximum number of attempts. Please restart this transaction.';
      setHasHitMaxAttempts(true);
    } else {
      errorMessage = error.response?.data.message || 'There has been an error';
    }

    feedbackInit({
      componentLevel: true,
      message: errorMessage,
      type: 'danger'
    });

    setTimeout(() => {
      closeFeedback();
    }, 5000);
  };

  const { mutateAsync: mutateCardFunding } = CardIssuanceServices.useInitiateCardFunding({
    cardRef: cardReference,
    errorMessage: 'We were unable to complete your virtual card funding. Please try again',
    onSuccess: (data: OtpResponseType) => {
      if (action === 'fund_card') {
        const { reference, auth } = data?.data || {};
        setFundingRef(reference);
        updateAuthState({
          ...auth
        });
      }
    }
  });

  const { mutateAsync: mutateFundingAuthorization } = CardIssuanceServices.useAuthorizeCardFunding({
    showErrorMessage: false,
    onError: handleAuthorizationError,
    onSuccess: () => setIsSuccessfulAuth(true)
  });

  const { mutateAsync: mutateCardWithdrawal } = CardIssuanceServices.useInitiateCardWithdrawal({
    cardRef: cardReference,
    errorMessage: 'We were unable to complete your virtual card withdrawal. Please try again',
    onSuccess: (data: cardWithdrawalOtpResponseType) => {
      if (action === 'withdraw_from_card') {
        const { reference, auth } = data?.data || {};
        setWithdrawalRef(reference);
        updateAuthState({
          ...auth
        });
      }
    }
  });

  const { mutateAsync: mutateWithdrawalAuthorization } = CardIssuanceServices.useAuthorizeCardWithdrawal({
    showErrorMessage: false,
    onError: handleAuthorizationError,
    onSuccess: () => setIsSuccessfulAuth(true)
  });

  const goToPreviousStage = () => {
    switch (stage) {
      case 'confirm_card_funding':
      case 'confirm_card_withdrawal':
        if (authState.two_factor_type === 'totp_recovery_code') {
          updateAuthState({ two_factor_type: 'totp' });
          return;
        }
        if (stage === 'confirm_card_funding') setStage('fund_card');
        if (stage === 'confirm_card_withdrawal') setStage('withdraw_from_card');
        return;
      default:
        onCloseModal();
    }
  };

  const initiateCardFunding = async () => {
    await mutateCardFunding({
      amount: formik.values.fundingAmount,
      description: 'card funding'
    });
    setStage('confirm_card_funding');
  };

  const authorizeCardFunding = async () => {
    await mutateFundingAuthorization({
      reference: fundingRef,
      auth: {
        ...authState,
        code: formik.values.otp
      }
    });
  };

  const initiateCardWithdrawal = async () => {
    await mutateCardWithdrawal({
      amount: Number(formik.values.withdrawalAmount),
      description: 'card withdrawal'
    });
    setStage('confirm_card_withdrawal');
  };

  const authorizeCardWithdrawal = async () => {
    await mutateWithdrawalAuthorization({
      reference: withdrawalRef,
      auth: {
        ...authState,
        code: formik.values.otp
      }
    });
  };

  const handleResendOtp = async () => {
    updateCountdownStatus(false);
    await resendOTP();
  };

  const formik = useFormik({
    initialValues: {
      fundingAmount: '',
      sourceOfFund: 'issuing_balance',
      recipientOfFund: 'issuing_balance',
      withdrawalAmount: '',
      otp: ''
    } as TIssuedCardActionModalFormValues,
    validate: formValues => {
      const formErrors: Partial<{ [P in keyof TIssuedCardActionModalFormValues]: string }> = {};
      const minimumFundableAmount = 5;
      const minimumWithdrawal = 1;

      if (stage === 'fund_card') {
        if (!formValues.fundingAmount) formErrors.fundingAmount = 'Please enter the amount to be funded';

        if (formValues.fundingAmount && +formValues.fundingAmount < minimumFundableAmount)
          formErrors.fundingAmount = `Amount should be at least ${minimumFundableAmount} USD`;

        if (formValues.sourceOfFund === 'issuing_balance') {
          if (formValues.fundingAmount && +formValues.fundingAmount > +availableWalletBalance)
            formErrors.fundingAmount =
              'You do not have sufficient funds in your issuing balance for this amount. Kindly top up the balance or change the amount.';
        }
        return formErrors;
      }

      if (stage === 'withdraw_from_card') {
        if (!formValues.withdrawalAmount) formErrors.withdrawalAmount = 'Please enter the amount to be withdrawn';

        if (formValues.withdrawalAmount && +formValues.withdrawalAmount < minimumWithdrawal)
          formErrors.withdrawalAmount = `Amount should be at least ${minimumWithdrawal} USD`;

        if (formValues.withdrawalAmount && +formValues.withdrawalAmount > +cardBalance)
          formErrors.withdrawalAmount = 'You do not have sufficient funds in your card balance for this withdrawal.';

        return formErrors;
      }

      if (stage === 'confirm_card_funding' || stage === 'confirm_card_withdrawal') {
        if (!twoFAInputValidation(authState.two_factor_type, formValues.otp))
          formErrors.otp = `Please enter a valid ${otpLabel.toLowerCase()}`;
      }
      return formErrors;
    },
    onSubmit: async () => {
      switch (stage) {
        case 'fund_card':
          await initiateCardFunding();
          break;
        case 'confirm_card_funding':
          await authorizeCardFunding();
          break;
        case 'withdraw_from_card':
          await initiateCardWithdrawal();
          break;
        case 'confirm_card_withdrawal':
        default:
          await authorizeCardWithdrawal();
      }
    }
  });

  const handleCloseModal = () => {
    if (isSuccessfulAuth) {
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(cardReference)
      });
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes('balances'),
        refetchType: 'inactive'
      });
    }
    onCloseModal();
  };

  const modalProps: Record<IssuedCardActionModalStageType | 'sharedProps', Partial<IModalProps>> = {
    sharedProps: {
      close: handleCloseModal,
      completedHeading: 'Done!',
      firstButtonAction: goToPreviousStage,
      secondButtonAction: formik.submitForm,
      secondButtonText: 'Continue',
      completedAction: handleCloseModal
    },
    fund_card: {
      heading: <div className="text-lg">Fund virtual card</div>,
      description: <div className="text-md">Enter the details below to fund virtual card</div>,
      secondButtonActionIsTerminal: false,
      secondButtonText: <span className="text-md">Continue</span>,
      secondButtonDisable: !(formik.dirty && formik.isValid),
      firstButtonText: <span className="text-md">Cancel</span>,
      content: <CardFundingForm currency={currency} formik={formik} balance={availableWalletBalance} />
    },
    confirm_card_funding: {
      size: 'sm',
      secondButtonDisable: !formik.values.otp || hasHitMaxAttempts,
      secondButtonActionIsTerminal: true,
      heading: <div className="text-lg">Confirm card funding</div>,
      secondButtonText: <span className="text-md">Proceed</span>,
      firstButtonText: <span className="text-md">Back</span>,
      description: (
        <span className="text-md">
          Please confirm that you want to transfer{' '}
          <strong>{`${formatAmount(formik.values.fundingAmount)} ${currency.toUpperCase()}`}</strong> from your{' '}
          <strong>issuing balance</strong> to card balance. This action cannot be undone.
        </span>
      ),
      completedHeading: 'Success',
      completedDescription: 'You have successfully funded virtual card from your issuing balance.',
      content: (
        <OTPAuthorizationForm
          twoFactorType={authState.two_factor_type}
          onUpdateAuthState={() => updateAuthState({ two_factor_type: 'totp_recovery_code' })}
          email={merchantEmail || ''}
          isMaxOtpAttempts={hasHitMaxAttempts}
          isCountdownComplete={countdownIsCompleted}
          onOtpResend={handleResendOtp}
          formik={formik}
          onCountdownEnd={() => updateCountdownStatus(true)}
        />
      )
    },
    withdraw_from_card: {
      heading: <div className="text-lg">Withdraw funds from virtual card</div>,
      description: <div className="text-md">Enter the details below to withdraw funds from your virtual card</div>,
      secondButtonActionIsTerminal: false,
      secondButtonText: <span className="text-md">Continue</span>,
      secondButtonDisable: !(formik.dirty && formik.isValid),
      firstButtonText: <span className="text-md">Cancel</span>,
      content: <CardWithdrawalForm formik={formik} cardBalance={cardBalance} walletBalance={availableWalletBalance} currency={currency} />
    },
    confirm_card_withdrawal: {
      size: 'sm',
      secondButtonDisable: !formik.values.otp || hasHitMaxAttempts,
      secondButtonActionIsTerminal: true,
      heading: <div className="text-lg">Confirm card withdrawal</div>,
      secondButtonText: <span className="text-md">Submit</span>,
      firstButtonText: <span className="text-md">Back</span>,
      description: (
        <span className="text-md">
          Confirm withdrawal of <strong>{`${formatAmount(formik.values.withdrawalAmount)} ${currency.toUpperCase()}`}</strong> from your{' '}
          <strong>virtual card</strong> to issuing balance.
        </span>
      ),
      completedHeading: 'Success',
      completedDescription: `You have successfully withdrawn ${formatAmount(
        formik.values.withdrawalAmount
      )} ${currency.toUpperCase()} from your card balance to your issuing balance.`,
      content: (
        <OTPAuthorizationForm
          twoFactorType={authState.two_factor_type}
          onUpdateAuthState={() => updateAuthState({ two_factor_type: 'totp_recovery_code' })}
          email={merchantEmail || ''}
          isMaxOtpAttempts={hasHitMaxAttempts}
          isCountdownComplete={countdownIsCompleted}
          onOtpResend={handleResendOtp}
          formik={formik}
          onCountdownEnd={() => updateCountdownStatus(true)}
        />
      )
    }
  };

  const sharedModalProps = {
    ...modalProps.sharedProps,
    ...(modalProps[stage] || {})
  };

  return <Modal {...(sharedModalProps as IModalProps)} />;
};

export default CardActionsModal;
