import React from 'react';

import CorrectIcon from '+assets/img/dashboard/check-green.svg';
import WarningIcon from '+assets/img/dashboard/warning-info-rounded.svg';

import '../index.scss';

export interface IVirtualBankAccountLimitProps {
  action: () => void;
  count: number;
  currency: string;
  loading: boolean;
  completed: boolean;
}

const VirtualBankAccountLimit: React.FC<IVirtualBankAccountLimitProps> = ({ action, count, currency, loading, completed }) => {
  const onRequestClick = () => {
    action();
  };
  return (
    <div className="vba-limit-request-wrapper" role="status" aria-live="polite" aria-label="Warning banner">
      <div className="vba-limit-banner-container">
        {!completed && !loading && <img src={WarningIcon} alt="warnin-icon" className="vba-img-style" />}
        {completed && <img src={CorrectIcon} alt="warnin-icon" className="ml-10-vba-limit" />}
        <div className="vab-limit-text-label">
          {loading && (
            <>
              <span className="spinner-border spinner-border-sm ml-10-vba-limit" role="status" aria-hidden="true" />
              <span className="vab-limit-text-400 ">
                Your {currency} VBA request is currently being processed. You will be notified when it is done.
              </span>
            </>
          )}
          {!completed && !loading && (
            <>
              <span className="vab-limit-text-400 ">
                You have {count || 0} Virtual Bank Accounts (VBAs) left for usage on {currency} !
              </span>
              <span
                className={`ml-10-vba-limit vab-limit-text-600 vba-limit-color ${completed || loading ? 'hide-text-vba-limit' : ''}`}
                onClick={onRequestClick}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') onRequestClick();
                }}
                role="button"
                tabIndex={0}
              >
                Click here to request new VBAs
              </span>
            </>
          )}
          {completed && (
            <span className="vab-limit-text-400 ">
              Your request for increased {currency} VBA has been sent successfully. You will be notified when it is done
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default VirtualBankAccountLimit;
