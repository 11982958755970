import { useShallow } from 'zustand/react/shallow';

import { AuthServices } from '+services/auth-services';
import { MerchantServices } from '+services/merchant-services';
import { UtilServices } from '+services/util-services';
import useStore from '+store';
import { mapCountryToStatesFrom, reduceToStateOptionsFrom } from '+utils';

const useCardAccessRequest = () => {
  const { addCardAccess, addCurrencyAccess, addStates, addMerchantDetails } = useStore(useShallow(state => state));

  const { data: cardAccess } = AuthServices.useFetchAccessRequestStatus({
    baseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
    onSuccess: ({ data }) => {
      addCardAccess(data.category);
      addCurrencyAccess(data.currency);
    }
  });

  const merchantDetails = MerchantServices.useFetchMerchantDetails({
    onSuccess: ({ data }) => addMerchantDetails(data),
    enabled: [cardAccess?.data?.reserved?.status].includes('inactive'),
    showErrorMessage: false
  });

  UtilServices.useFetchCountries({
    params: { includeStates: true },
    onSuccess: ({ data }) => {
      const countryToStatesMap = mapCountryToStatesFrom(data);
      const stateOptions = reduceToStateOptionsFrom(countryToStatesMap?.[merchantDetails?.data?.data?.country ?? '']);
      addStates(stateOptions);
    },
    enabled: !!merchantDetails.data?.data?.country
  });
};

export default useCardAccessRequest;
