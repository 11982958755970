/* eslint-disable no-unused-vars */
import { useFetch, useSend } from '+hooks';
import { IEmailSubscriptionResponse, ISendResponse } from '+types';

export const EmailConfigurationServices = {
  useGetConfigurations: ({
    onError,
    onSuccess,
    bannerLevel,
    enabled,
    showErrorMessage
  }: {
    onError?: (e: Error) => void;
    onSuccess?: (data: unknown) => void;
    bannerLevel?: boolean;
    reference?: string;
    currency?: string;
    enabled?: boolean;
    showErrorMessage?: boolean;
  }) =>
    useFetch(`/notification-configurations`, {
      onError,
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      enabled,
      showErrorMessage,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useSetMarketingEmailConfig: ({ errorMessage, bannerLevel }: { bannerLevel?: boolean; errorMessage?: string }) =>
    useSend({
      url: '/notification-configurations/marketing',
      method: 'put',
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useSetPayoutEmailConfig: ({ errorMessage, bannerLevel }: { bannerLevel?: boolean; errorMessage?: string }) =>
    useSend({
      url: '/notification-configurations/payout',
      method: 'put',
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useSetPayinEmailConfig: ({ errorMessage, bannerLevel }: { bannerLevel?: boolean; errorMessage?: string }) =>
    useSend({
      url: '/notification-configurations/payin',
      method: 'put',
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useSetDisputeEmailConfig: ({ errorMessage, bannerLevel }: { bannerLevel?: boolean; errorMessage?: string }) =>
    useSend({
      url: '/notification-configurations/dispute',
      method: 'put',
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useSetCardIssuanceEmailConfig: ({
    onSuccess,
    onError,
    errorMessage,
    bannerLevel
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
  }) =>
    useSend({
      url: '/notification-configurations/card-issuance',
      method: 'put',
      onSuccess,
      onError,
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useSetSubscribeEmailConfig: ({
    onSuccess,
    onError,
    errorMessage,
    bannerLevel,
    onSettled
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    onSettled?: () => void;
  }) =>
    useSend({
      url: '/transactions/report/configuration',
      method: 'put',
      onSuccess,
      onError,
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      onSettled,
      reInvalidate: true,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useSetSupportEmailConfig: ({ errorMessage, bannerLevel }: { bannerLevel?: boolean; errorMessage?: string }) =>
    useSend({
      url: '/notification-configurations/support-email',
      method: 'put',
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetSubscribeEmailConfig: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage
  }: {
    onSuccess?: (data: ISendResponse<IEmailSubscriptionResponse>) => void;
    onError?: () => void;
    bannerLevel?: boolean;
    errorMessage?: string;
  }) =>
    useFetch(`/transactions/report/configuration`, {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    })
};
