import React, { useState } from 'react';
import { capitalize } from '+utils';
import { IBulkPayoutHeaderProps } from '+types/bulk-payouts';
import RepeatBulkPayout from '../BulkTransactionDetails/RepeatBulkPayoutModal';
import BusinessAvatar from '+assets/img/dashboard/briefcase.svg';
import SuccessIcon from '+assets/img/dashboard/check.png';
import arrowRight from '+assets/img/dashboard/arrow-right.svg';

const BulkPayoutHeader = ({
  summaryData,
  status,
  description,
  data,
  setExportModalVisible,
  permissions,
  validationCompleted = true,
  startAction,
  discardAction,
  enableStartBtn,
  enableDiscardBtn,
  type,
  currency
}: IBulkPayoutHeaderProps) => {
  const [repeatPayoutModal, setRepeatPayoutModal] = useState(false);
  const actionBtns = () => {
    let action = [];
    if (status === 'draft') {
      action = [
        <button type="button" className="btn btn-secondary btn-sm d-bulk-btn" disabled={!enableDiscardBtn} onClick={discardAction}>
          <span>Discard Bulk Payout</span>
        </button>,
        <button type="button" className="btn btn-primary btn-sm s-bulk-btn" disabled={!enableStartBtn} onClick={startAction}>
          <span>Start Bulk Payout</span>
          <img src={arrowRight} alt="arrow right" />
        </button>
      ];
    }
    if (permissions.payout === 'export') {
      action.push(
        <button
          type="button"
          aria-label="Export Transaction"
          className="btn btn-sm btn-secondary export-btn"
          style={{ background: 'none', border: 'none', color: '#2376F3', fontWeight: 500 }}
          hidden={data?.length === 0}
          onClick={() => setExportModalVisible!(true)}
        >
          <i className="os-icon os-icon-arrow-up-right" />
          <span>Export Transaction</span>
        </button>
      );
    }
    if (status === 'complete') {
      action.push(
        <button type="button" className="btn repeat-bulkpayout" onClick={() => setRepeatPayoutModal(true)}>
          <i className="os-icon os-icon-rotate-ccw" />
          <span>Repeat Bulk Payout</span>
        </button>
      );
    }
    return action;
  };
  return (
    <div className="info-summary-heading">
      {repeatPayoutModal && <RepeatBulkPayout close={() => setRepeatPayoutModal(false)} type={type} currency={currency} />}
      <div className="bulk-payout-header content-details-head content-details-hd">
        <div className="content-details-title biz-info-heading">
          <div className="user-avatar-w">
            <div className="user-avatar">
              <img alt="" src={BusinessAvatar} />
            </div>
          </div>
          <div className="cd-title ml-3 info-subheading">
            <div className="bulk-reference">
              <h3 className="cd-maintitle main-title">{summaryData?.reference?.toUpperCase() || 'SMGC-MAY-23-SALARY'}</h3>
              {status === 'completed' && <img src={SuccessIcon} alt="active" aria-hidden="true" />}
            </div>
            <p className="cd-subtitle  p-lg">{capitalize(description) || 'KPB-BBG82H4FBLWTE75HA1K'}</p>
          </div>
        </div>
        <div className="action-btns">{actionBtns()}</div>
      </div>
    </div>
  );
};

export default BulkPayoutHeader;
