import { useEffect, useRef, useState } from 'react';
import * as Flags from 'country-flag-icons/react/3x2';
import Select, { components, GroupBase, MultiValueProps, OptionProps, StylesConfig, ValueContainerProps } from 'react-select';

import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import { IdentityServices } from '+services/identity-services';
import { queryClient } from '+utils';

import { IEvidenceOfFileUpload } from '../AccessRequest/UploadDueDiligenceForm';
import LimitWarningBanner from '../components/LimitWarningBanner';
import {
  EventType,
  IAvailableCountry,
  IdReqPayloadType,
  IdRequestData,
  ILabeledValue,
  IVerificationType,
  SubValueType
} from '../types/types';

import InfoSymbol from '+assets/img/dashboard/info-symbol.png';

import '../AccessRequest/IdAccessRequestModal/IdAccessRequestModal.scss';

export interface IdAccessRequestModalProps {
  onCancel: () => void;
  onAccessRequested: (status: boolean) => void;
  requestsInfo: IdRequestData;
  evidenceOfFileUpload?: IEvidenceOfFileUpload;
}

const colourStyles: StylesConfig<any, true> = {
  control: styles => ({ ...styles, backgroundColor: '#fff' }),
  option: styles => {
    return {
      ...styles,
      backgroundColor: '#fff',

      ':active': {
        ...styles[':active'],
        backgroundColor: '#fff'
      }
    };
  }
};

const Option = (props: OptionProps<IAvailableCountry, boolean, GroupBase<IAvailableCountry>>) => {
  const Flag = Flags[props.data.value.toUpperCase() as keyof typeof Flags];
  return (
    <div>
      <components.Option {...props}>
        <div className="form-check mb-1" onClick={() => null}>
          <input
            checked={props.isSelected}
            onChange={() => null}
            className="form-check-input"
            type="checkbox"
            value=""
            id="defaultCheck1"
          />
          <Flag width={20} height={15} className="mr-1 mb-0 mt-1" />
          <span onClick={() => null} className="form-check-label mb-0 mt-1">
            {props.label}
          </span>
        </div>
      </components.Option>
    </div>
  );
};

const MultiValue = (props: MultiValueProps<IAvailableCountry, boolean, GroupBase<IAvailableCountry>>) => {
  const Flag = Flags[props.data.value.toUpperCase() as keyof typeof Flags];
  return (
    <components.MultiValue {...props}>
      <Flag width={20} height={15} className="mr-1 mb-1" />
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<unknown, boolean, GroupBase<unknown>>) => {
  const [values, input] = children as any;

  let displayValue = values;

  if (values?.length > 3) {
    displayValue = [values[0], values[1], values[2], `+${values?.length - 3} more`];
  }

  return <components.ValueContainer {...props}>{[displayValue, input]}</components.ValueContainer>;
};

const IdentityAccessRequestForm = ({ onCancel, requestsInfo, onAccessRequested, evidenceOfFileUpload }: IdAccessRequestModalProps) => {
  const [selectedCountries, setSelectedCountries] = useState<IAvailableCountry[]>([]);
  const [isTermsSelected, setIsTermsSelected] = useState<boolean>(false);
  const [isKYCCountrySelected, setIsKYCCountrySelected] = useState<boolean>(false);
  const [isKYBCountrySelected, setIsKYBCountrySelected] = useState<boolean>(false);
  const [isSelectedUseCaseValid, setIsSelectedUseCaseValid] = useState<boolean>(false);
  const [selectVerificationCategory, setSelectVerificationCategory] = useState<Record<keyof typeof EventType, boolean>>({
    kyc: false,
    kyb: false
  });
  const [isVerificationTypeSelected, setIsVerificationTypeSelected] = useState<boolean>(false);

  const [reqPayload, setReqPayload] = useState<IdReqPayloadType>({} as IdReqPayloadType);

  const kybCountries = requestsInfo.available_countries.filter(country => country.verification_types.some(type => type.label === 'KYB'));
  const kycCountries = requestsInfo.available_countries.filter(country => country.verification_types.some(type => type.label === 'KYC'));

  const seletctedUsecases = useRef<Record<keyof typeof EventType, string[]>>({
    kyc: [],
    kyb: []
  });

  const sendRequest = IdentityServices.useRequestIdentityAccess({
    enabled: true,
    errorMessage: 'We were unable to process your request. Please try again',
    hasFeedbackTimeout: true,
    onSuccess: () => {
      onAccessRequested(true);
    }
  });

  const updateVerificationType = () => {
    let updatedRes = reqPayload;
    selectedCountries.forEach((country: IAvailableCountry) => {
      const countryAbbr = country.value;
      country.verification_types.forEach((verificationType: IVerificationType) => {
        const type = verificationType.value as keyof typeof EventType;
        const verificationEnabledValue = {
          [verificationType.value]: {
            enable: selectVerificationCategory[type]
          }
        };

        updatedRes = {
          ...updatedRes,
          [countryAbbr]: {
            ...updatedRes[countryAbbr],
            [verificationType.value]: {
              ...(updatedRes[countryAbbr][type] as object),
              ...verificationEnabledValue[verificationType.value]
            }
          }
        };

        if ((updatedRes[countryAbbr][type] as SubValueType)?.enable === false) {
          delete updatedRes[countryAbbr][type];
        }
      });
    });
    setReqPayload(updatedRes);
  };

  const isSubmitDisabled = () => {
    return selectedCountries.length < 1 || !isTermsSelected || !isVerificationTypeSelected || !isSelectedUseCaseValid;
  };

  useEffect(() => {
    if (selectVerificationCategory.kyb || selectVerificationCategory.kyc) {
      setIsVerificationTypeSelected(true);
    } else {
      setIsVerificationTypeSelected(false);
    }
  }, [selectVerificationCategory]);

  // Always update the kyc/kyb enable value when the selected countries change
  useEffect(() => {
    if (selectedCountries.length > 0) {
      updateVerificationType();
      setIsKYBCountrySelected(kybCountries.some(value => selectedCountries.includes(value)));
      setIsKYCCountrySelected(kycCountries.some(value => selectedCountries.includes(value)));
    } else {
      setIsKYBCountrySelected(false);
      setIsKYCCountrySelected(false);
    }
  }, [selectVerificationCategory, selectedCountries]);

  // Reset the verification category when the selected countries change
  useEffect(() => {
    if (selectedCountries.length < 1) {
      setSelectVerificationCategory({
        kyc: false,
        kyb: false
      });
    }
  }, [selectedCountries, setSelectVerificationCategory]);

  useEffect(() => {
    let isValidCountrySelection = true;
    let isValidUseCases = true;

    selectedCountries.forEach(country => {
      if (!isValidCountrySelection) return;
      let countryUseCases: string[] = [];
      Object.keys(reqPayload[country.value]).forEach(label => {
        if (label === 'enable') {
          return;
        }
        countryUseCases = [...countryUseCases, ...Object.keys(reqPayload[country.value][label])].filter(lb => lb !== 'enable');
      });
      if (countryUseCases.length < 1) {
        isValidCountrySelection = false;
      }
    });

    // Ensure atleast a kyc usecase is selected if kyb options is selected.
    if (selectVerificationCategory.kyc) {
      isValidUseCases = !!seletctedUsecases.current.kyc.length;
    }
    if (selectVerificationCategory.kyb) {
      isValidUseCases = !!seletctedUsecases.current.kyb.length;
    }

    setIsSelectedUseCaseValid(isValidUseCases && isValidCountrySelection);
  }, [reqPayload, selectedCountries]);

  const updateVerificationUseCase = (country: string, type: keyof typeof EventType, useCase: string, value: boolean) => {
    let useCases = seletctedUsecases.current[type];
    if (value) {
      useCases = [...useCases, useCase];
      seletctedUsecases.current[type] = useCases;
    } else {
      useCases = useCases.filter(useCaseItem => useCaseItem !== useCase);
      seletctedUsecases.current[type] = useCases;
    }
    let currentType = reqPayload[country][type];

    if (!value) {
      delete currentType[useCase];
    } else {
      currentType = {
        ...currentType,
        [useCase]: {
          enable: value
        }
      };
    }

    setReqPayload(prev => ({
      ...prev,
      [country]: {
        ...reqPayload[country],
        [type]: {
          ...currentType
        }
      }
    }));
  };

  return (
    <div className="id-access-modal fade-in pt-4">
      {/* Countries of interest */}
      <h4 className="pb-5 title">Request for Access to Identity Service</h4>
      <LimitWarningBanner />

      <div className="mb-4">
        <h6 className="title">Countries of Interest</h6>
        <p className="subtitle mb-4">Select the country or countries where you intend to perform KYC or KYB.</p>

        <Select
          isMulti
          name="countries"
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          closeMenuOnSelect={false}
          placeholder="Select country(s)"
          isClearable={false}
          onChange={(value, action) => {
            const response = reqPayload;
            setSelectedCountries(value as IAvailableCountry[]);

            if (action.action === 'deselect-option' || action.action === 'remove-value') {
              const key = action?.option?.value ?? action?.removedValue?.value;
              if (key !== undefined) {
                delete response[key];
              }
            }
            if (action.action === 'select-option') {
              {
                response[action?.option!.value] = {
                  enable: true
                };
              }
            }

            setReqPayload(response);
          }}
          options={requestsInfo.available_countries}
          components={{ ValueContainer, MultiValue, Option }}
          styles={colourStyles}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>

      {/* Verification Categories */}
      <div className="mt-5">
        <h6 className="title">Verification Category</h6>
        <div className="d-flex">
          {Object.entries(EventType).map(([key, value]) => {
            const disabled = key === 'kyb' ? !isKYBCountrySelected : !isKYCCountrySelected;
            return (
              <div className="pr-4" key={key}>
                <div className="form-check mt-1">
                  <input
                    className={`form-check-input ${!disabled && 'cursor-pointer'}`}
                    type="checkbox"
                    value={key}
                    checked={selectVerificationCategory[key as keyof typeof EventType] === true}
                    id={key}
                    disabled={disabled}
                    onChange={e => {
                      if (selectedCountries.length < 1) return;
                      const isChecked = e.target.checked;
                      const { value } = e.target;
                      setSelectVerificationCategory({
                        ...selectVerificationCategory,
                        [value]: isChecked
                      });
                    }}
                  />
                  <label className={`text-dark font-weight-normal form-check-label mt-1 ${!disabled && 'cursor-pointer'}`} htmlFor={key}>
                    {value}
                  </label>
                </div>
                {value === EventType.kyb && <small className="hint-text">This category is currently available only in Nigeria.</small>}
              </div>
            );
          })}
        </div>
      </div>
      {/* Available use case */}
      <div className="row mt-5 mb-5">
        <div className="col-12">
          <h6 className="title">Available Use Case</h6>
          <p className="use-case-title mb-2">
            <span>ID Verification & Lookup</span>
            <ToolTip
              image={InfoSymbol}
              message={<em>Verify identity documents and/or compare the data submitted with data extracted from the documents</em>}
            />
          </p>
          <p className="subtitle m-0">
            Select available verification type(s) based on your country(s) of interest and verification category for this use case.
          </p>

          {selectedCountries.map((country: IAvailableCountry) => (
            <div className="row mt-4 border-bottom pb-3" key={country.value}>
              <div className="col-4 pr-0">
                <p className="country-text">{country.label}</p>
              </div>{' '}
              <div className="col-8 pl-0">
                <div className="row">
                  {country.verification_types.map((verificationType: IVerificationType) => (
                    <div className="col-12" key={verificationType.value}>
                      <small className="bold">
                        Know Your {verificationType.value === 'kyb' ? 'Business' : 'Customer'} ({verificationType.label})
                      </small>

                      {verificationType.identity_types.map((identityType: ILabeledValue) => {
                        const disabled =
                          selectedCountries.length < 1 || reqPayload[country.value]?.[verificationType.value]?.enable !== true;
                        return (
                          <div className="form-check mb-1" key={identityType.value}>
                            <input
                              className={`form-check-input usecases-input ${!disabled && 'cursor-pointer'}`}
                              type="checkbox"
                              value={identityType.value}
                              checked={reqPayload[country.value]?.[verificationType.value]?.[identityType.value]?.enable === true}
                              id={identityType.value}
                              disabled={disabled}
                              onChange={e => {
                                const isChecked = e.target.checked;
                                const { value } = e.target;
                                updateVerificationUseCase(
                                  country.value,
                                  verificationType.value as keyof typeof EventType,
                                  value,
                                  isChecked
                                );
                              }}
                            />
                            <label
                              className={`text-dark font-weight-normal form-check-label mt-1 ${!disabled && 'cursor-pointer'}`}
                              htmlFor={identityType.value}
                            >
                              {identityType.label}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

          {selectedCountries.length < 1 && (
            <p className="font-italic font-weight-light mt-4 light-text">
              Select a country of interest to see verification types for this use case.
            </p>
          )}
        </div>
      </div>

      {/* Terms and Conditions */}
      <div className="mb-2">
        <h6 className="title">Terms and conditions</h6>
        <div className="row">
          <div className="col-12">
            <div className="form-check align-items-start">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                value="terms"
                id="terms"
                maxLength={50}
                checked={isTermsSelected}
                disabled={selectedCountries.length < 1}
                onChange={e => {
                  setIsTermsSelected(e.target.checked);
                }}
              />
              <label className="form-check-label text-dark font-weight-normal" htmlFor="terms">
                I have read and I accept{' '}
                <a
                  href="https://www.korahq.com/terms-of-use"
                  className="font-weight-bold"
                  target="noopener noreferrer"
                  style={{ marginLeft: '0.3rem' }}
                >
                  Kora&apos;s terms of service
                </a>{' '}
                and{' '}
                <a
                  href="https://www.korahq.com/merchant-service"
                  className="font-weight-bold"
                  target="noopener noreferrer"
                  style={{ marginLeft: '0.3rem' }}
                >
                  Kora&apos;s Identity service agreement.
                </a>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end py-5">
        <button className="btn btn-light text-grey py-2 px-4 mr-2" onClick={onCancel}>
          Back
        </button>
        <button
          disabled={isSubmitDisabled()}
          className="btn btn-primary py-3 px-4"
          onClick={async () => {
            queryClient.invalidateQueries({ queryKey: ['IDENTITY_ACCESS'] });
            await sendRequest.mutate({
              ...reqPayload,
              due_diligence_form: evidenceOfFileUpload?.path
            });
          }}
        >
          <span> Submit</span>{' '}
          {sendRequest.isLoading && (
            <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
          )}{' '}
        </button>
      </div>
    </div>
  );
};

export default IdentityAccessRequestForm;
