import { useState } from 'react';

import CardInfoModal from '+containers/Dashboard/Issuing/components/CardInfoModal';
import { generateCardSummary } from '+containers/Dashboard/Issuing/utils';
import { ICardInfo } from '+types';
import { formatAmount, getTwoDigitYear, isNullish } from '+utils';

import CardThumbnail from '../../components/CardThumbnail';

const CardInformation = ({ cardDetails: data, reference, cardCategory }: ICardInfo) => {
  const [cardInfoModalIsActive, setCardInfoModalIsActive] = useState(false);
  const shortSummaryLength = 5;
  const [toggleShortSummary, setToggleShortSummary] = useState(false);
  const {
    status,
    brand,
    color,
    balance,
    billing,
    currency,
    expiry_month: expiryMonth,
    expiry_year: expiryYear,
    card_holder: cardHolder,
    last_four: lastFour,
    pan,
    cvv,
    label
  } = data || {};

  const formatExpiryDate = () => {
    let formattedYear;
    if (expiryMonth && expiryYear) {
      formattedYear = getTwoDigitYear(expiryYear);
      return `${expiryMonth} / ${formattedYear}`;
    }
    return null;
  };

  const expiryDate = formatExpiryDate();

  const cardSummary = generateCardSummary({ cardDetails: data, cardId: reference });
  return (
    <>
      <div className="card-details__content">
        <div className="content__summary-section">
          <h6 className="content__summary-title">Summary</h6>
          <ul className="content__summary">
            {cardSummary.map((item, index) => {
              const visibleLength = toggleShortSummary ? cardSummary.length : shortSummaryLength;
              if (index < visibleLength)
                return (
                  <li key={item.label} className="content__summary-item fade-in">
                    <span className="key">{item?.label}</span>
                    <span className="value">
                      {item?.before}
                      {item?.value}
                    </span>
                  </li>
                );
              return null;
            })}
          </ul>
          <button type="button" className="btn btn-secondary link-btn pl-0" onClick={() => setToggleShortSummary(prev => !prev)}>
            <span>{toggleShortSummary ? 'Less details' : 'More details'}</span>
            <i className={`os-icon ${toggleShortSummary ? 'os-icon-chevron-up' : 'os-icon-chevron-down'} icon`} />
          </button>
        </div>

        <div className="content__card-section">
          <div className="mb-4 content__card-thumbnail">
            <CardThumbnail
              cardDetails={{
                label,
                status: ['requires_auth', 'require_auth'].includes(status) ? 'Pending (requires authorization)' : status ?? '',
                scheme: brand,
                holderName: !isNullish(cardHolder)
                  ? `${cardHolder?.first_name} ${cardHolder?.first_name !== cardHolder?.last_name ? cardHolder?.last_name : ''}`
                  : 'N/A',
                expiresAt: !isNullish(expiryDate) ? expiryDate : 'N/A',
                lastFourDigits: lastFour
              }}
              color={color}
              applyOverlay={cardCategory === 'reserved_cards'}
              onOverlayClick={() => setCardInfoModalIsActive(true)}
            />
          </div>

          <div className="content__balance-section">
            <div className="content__balance-value">
              <span className="content__balance-digit">{!isNullish(balance) ? formatAmount(balance) : 'N/A'}</span>
              <span className="content__currency"> {currency}</span>
            </div>
            <div className="content__balance-label">Available Balance</div>
          </div>
        </div>
      </div>

      <CardInfoModal
        close={() => setCardInfoModalIsActive(false)}
        open={cardInfoModalIsActive}
        cardInfo={{ holderName: cardHolder, label, cvv, pan, expiryMonth, expiryYear, billing, scheme: brand }}
      />
    </>
  );
};

export default CardInformation;
