import { useLocation } from 'react-router-dom';

import { useSearchQuery } from '+hooks';
import { CardCategoryType, CurrencyType } from '+types';
import { history } from '+utils';

import DetailsSection from './DetailsSection';
import TablesSection from './TablesSection';

import './index.scss';

export default function IssuedCardDetails() {
  const { pathname } = useLocation();
  const cardCategory = pathname?.split('/').slice(-2)[0] as CardCategoryType;
  const { value: searchQueryValue } = useSearchQuery<{ currency: CurrencyType }>();

  const currency = searchQueryValue?.currency ?? 'USD';

  return (
    <div className="card-details-page">
      <button type="button" className="card-details-page__back-btn btn btn-link pl-0" onClick={() => history.goBack()}>
        <i className="os-icon os-icon-arrow-left7" />
        <span>Go Back</span>
      </button>

      <DetailsSection cardCategory={cardCategory} currency={currency} />

      <TablesSection currency={currency} />
    </div>
  );
}
