/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useLayoutEffect, useMemo } from 'react';

import { useSearchQuery } from '+hooks';

import Icon from './Icons';
import ToolTip from './Tooltip';

import './Pagination.scss';

interface ICursorPaginationProps {
  pagingTotalItems: number;
  paging?: { next_cursor?: number; previous_cursor?: number; page_size?: number; count?: number };
}

let previousTab: string;

const CursorPagination = ({ pagingTotalItems, paging }: ICursorPaginationProps) => {
  const searchQuery = useSearchQuery();
  const totalItems = Number(searchQuery.value.totalItems ?? 0);
  const page = Number(searchQuery.value.page ?? 1);
  const currentPageSize = Number(searchQuery.value.limit ?? paging?.page_size);
  const tab = searchQuery.value.tab ?? searchQuery.value.activeTab ?? searchQuery.value.currency ?? '';
  const previousCursor = paging?.previous_cursor;
  const nextCursor = paging?.next_cursor;
  const noNextCursor = !nextCursor;
  const limit = totalItems % currentPageSize;
  const previouslimit = Number(searchQuery.value.previousLimit ?? 0);

  useLayoutEffect(() => {
    if (pagingTotalItems && pagingTotalItems > 0 && pagingTotalItems !== totalItems) {
      searchQuery.setQuery({ totalItems: String(pagingTotalItems) });
    }
  }, [pagingTotalItems, totalItems]);

  useEffect(() => {
    if (previousTab && tab !== previousTab) {
      searchQuery.setQuery({ page: '1', pageAction: '', pageCursorValue: '', totalItems: '' });
    }
    previousTab = tab;
  }, [tab]);

  const totalPages = limit < previouslimit ? Math.ceil(totalItems / previouslimit) : Math.ceil(totalItems / currentPageSize) || 1;
  useEffect(() => {
    if (page && page > totalPages) {
      searchQuery.setQuery({ page: '1', pageAction: 'start', pageCursorValue: '' });
    }
  }, [page]);

  const paginate = (action: 'start' | 'previous' | 'next' | 'end', value?: number) => {
    if (action === 'next' && nextCursor) {
      searchQuery.setQuery({
        pageAction: action,
        pageCursorValue: (value && String(value)) || undefined,
        page: String(page + 1)
      });
    } else if (action === 'previous' && previousCursor && noNextCursor) {
      searchQuery.setQuery({
        pageAction: action,
        pageCursorValue: (value && String(value)) || undefined,
        page: (totalPages - 1).toString(),
        limit: String(previouslimit)
      });
    } else if (action === 'previous' && previousCursor && page > 1) {
      searchQuery.setQuery({
        pageAction: action,
        pageCursorValue: (value && String(value)) || undefined,
        page: String(page - 1)
      });
    } else if (action === 'start') {
      searchQuery.setQuery({
        pageAction: action,
        pageCursorValue: (value && String(value)) || undefined,
        page: '1',
        limit: String(previouslimit)
      });
    } else if (action === 'end') {
      searchQuery.setQuery({
        pageAction: action,
        pageCursorValue: (value && String(value)) || undefined,
        limit: String(limit || currentPageSize),
        page: totalPages.toString(),
        previousLimit: String(previouslimit || currentPageSize)
      });
    }
  };
  const getBeginning = () => {
    if (!previousCursor && !nextCursor) {
      return page;
    }
    if (previousCursor && nextCursor) {
      return currentPageSize * (page - 1) + 1;
    }
    if (noNextCursor) {
      return previouslimit * (totalPages - 1) + 1;
    }
    return 1;
  };
  const getEnding = () => {
    if (!previousCursor && !nextCursor) {
      return paging?.count;
    }
    if (noNextCursor) {
      return totalItems;
    }
    if (nextCursor && previousCursor) {
      const currentEnd = currentPageSize * page;
      return currentEnd > totalItems ? totalItems : currentEnd;
    }
    return currentPageSize;
  };

  const ending = useMemo(() => getEnding(), [paging, page, currentPageSize]);
  const beginning = useMemo(() => getBeginning(), [paging, currentPageSize, page]);

  const displayLimit = () => {
    return (
      <div className="flex pagination-limit">
        <span className="page-text" id="dataTable1_length_custom">
          Show
        </span>
        <select
          name="ddataTable1_length_custom"
          style={{ width: '50px', padding: '3px' }}
          disabled={page !== 1}
          onChange={e =>
            searchQuery.setQuery({
              limit: String(e.target.value),
              pageAction: 'start',
              pageCursorValue: '',
              page: '1',
              previousLimit: String(e.target.value)
            })
          }
          value={previouslimit || '10'}
          aria-controls="dataTable1_length_custom"
          className="form-control form-control-sm"
          data-testid="pagination_limit"
          aria-labelledby="dataTable1_length_custom"
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
        <span> items per page</span>
      </div>
    );
  };

  const scrollToTopSection = () => {
    if (totalItems < 9 || currentPageSize < 9) return null;
    const defaultScroll = () => {
      const contentBox = document.querySelector('.content-box');
      contentBox?.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
      <div className="scroll-to-top">
        <button className="btn btn-sm pagination-back-to-top" type="button" onClick={defaultScroll}>
          <span>Back to top</span>
          <i className="os-icon os-icon-arrow-up6" />
        </button>
      </div>
    );
  };
  return (
    <div className="pagination-section">
      {page === 1 ? (
        displayLimit()
      ) : (
        <ToolTip type="limit" classname="w-auto" message={<p>You can only choose the number of items to display from the first page.</p>}>
          {displayLimit()}
        </ToolTip>
      )}
      {scrollToTopSection()}
      <div className="pagination-cursors">
        <p>
          {beginning} - {ending} <span> of </span>
          {!totalItems ? (
            <span className="ml-1">
              <Icon name="pageLoading" />
            </span>
          ) : (
            totalItems
          )}
        </p>
        <div className="pagination-pointers">
          <button type="button" aria-label="First Page" disabled={!previousCursor || page === 1} onClick={() => paginate('start')}>
            &laquo;
          </button>
          <button
            type="button"
            aria-label="Previous Page"
            disabled={!previousCursor || page === 1}
            onClick={() => paginate('previous', previousCursor)}
          >
            &lsaquo;
          </button>
          <button
            type="button"
            aria-label="Next Page"
            disabled={!nextCursor || page === totalPages}
            onClick={() => paginate('next', nextCursor)}
          >
            &rsaquo;
          </button>
          <button type="button" aria-label="Last Page" disabled={!nextCursor || page === totalPages} onClick={() => paginate('end')}>
            &raquo;
          </button>
        </div>
      </div>
    </div>
  );
};
export default CursorPagination;
