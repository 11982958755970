import { createBrowserHistory, History, LocationState } from 'history';

interface IPreviousHistory {
  path: string;
  type?: string;
  data?: unknown;
  state?: LocationState;
}

interface IHistoryWithPrevious extends History {
  previousHistory: IPreviousHistory | null;
  setPrevious: (path: string, info: { type: string; data: any }) => void;
  getPathInfo: (type: string) => any | null;
  push: (path: string, state?: LocationState, info?: { type: string; data: any }) => void;
}

const _ = createBrowserHistory();

const history: IHistoryWithPrevious = {
  ..._,
  previousHistory: null,

  setPrevious(path: string, info: { type: string; data: any }) {
    history.previousHistory = { path, type: info.type, data: info.data };
  },

  getPathInfo(type: string) {
    if (type !== history.previousHistory?.type) return null;
    return history.previousHistory?.data;
  },

  push(path: string, state?: LocationState, info: { type: string; data: any } | null = null) {
    const { location } = _;
    history.previousHistory = { path: location.pathname, state: location.state, type: info?.type, data: info?.data };
    return _.push(path, state);
  }
};

export default history;
