import { lazy, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import LazyComponent from '+containers/Shared/LazyComponent';
import { actions, history, isAuthenticated } from '+utils';

import SigninComponent from './SignIn';
import SignupComponent from './Signup';

import '+styles/base/parent.scss';
import '+styles/main.scss';
import './index.scss';

const ErrorPage = lazy(() => import('../Shared/ErrorPage'));
const ForgotPasswordComponent = lazy(() => import('./ForgotPassword'));
const MerchantStatus = lazy(() => import('./AuthStatus'));
const EmailSentComponent = lazy(() => import('./Signup/EmailSent'));
const VerifyUser = lazy(() => import('./Signup/VerifyUser'));
const VerifyTeamUser = lazy(() => import('./Signup/VerifyTeamUser'));
const NotSupported = lazy(() => import('./Signup/NotSupported'));
const NotSupportedEnd = lazy(() => import('./Signup/NotSupportedEnd'));
const PasswordEmailSent = lazy(() => import('./ForgotPassword/PasswordEmailSent'));
const NewPassword = lazy(() => import('./ForgotPassword/NewPassword'));
const PasswordReset = lazy(() => import('./ForgotPassword/PasswordReset'));
const KycComponent = lazy(() => import('./Kyc'));
const UnusualAttemptComponent = lazy(() => import('./SignIn/UnusualAttempt'));
const VerifyUserAttempt = lazy(() => import('./SignIn/components/VerifyUserAttempt'));

function AuthComponent() {
  const location = useLocation();

  const validateAuthRoute = () => {
    const authenticated = isAuthenticated().partial;
    const isKycPage = location.pathname === '/auth/kyc';

    if (!authenticated && isKycPage) {
      history.push('/auth/login');
    } else if (authenticated) {
      if (isKycPage) {
        history.push('/auth/kyc');
      } else {
        history.push('/dashboard/home');
      }
    }
  };

  useEffect(() => {
    actions.track('Sign up initiated');
    return validateAuthRoute();
  }, []);

  return (
    <div className="external__main">
      <div className="auth__content">
        <Switch>
          <Route path="/auth/login" component={SigninComponent} />
          <Route path="/auth/forgot-password" component={LazyComponent(ForgotPasswordComponent)} />
          <Route path="/auth/status/:id" component={LazyComponent(MerchantStatus)} />
          <Route path="/auth/signup" component={SignupComponent} />
          <Route path="/auth/email-sent" component={LazyComponent(EmailSentComponent)} />
          <Route path="/auth/verify-user" component={LazyComponent(VerifyUser)} />
          <Route path="/auth/teams/complete-signup" component={LazyComponent(VerifyTeamUser)} />
          <Route path="/auth/not-supported" component={LazyComponent(NotSupported)} />
          <Route path="/auth/not-supported-end" component={LazyComponent(NotSupportedEnd)} />
          <Route path="/auth/password-email-sent" component={LazyComponent(PasswordEmailSent)} />
          <Route path="/auth/reset-password" component={LazyComponent(NewPassword)} />
          <Route path="/auth/password-reset" component={LazyComponent(PasswordReset)} />
          <Route path="/auth/kyc" component={LazyComponent(KycComponent)} />
          <Route path="/auth/new-kyc" component={LazyComponent(KycComponent)} />
          <Route path="/auth/impossible-travel" component={LazyComponent(UnusualAttemptComponent)} />
          <Route path="/auth/signin/verify/:token" component={LazyComponent(VerifyUserAttempt)} />
          <Redirect path="/auth" to="/auth/login" />
          <Route path="*">
            <ErrorPage context="404" clearError={() => {}} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default AuthComponent;
