import { useEffect } from 'react';
import { uploadFiles, useReducerState } from 'src/hooks';

import { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { MerchantServices } from '+services/merchant-services';
import useStore from '+store';

import { initialState, modalContent } from './Data';

export default function useOverpaymentUnderpayment() {
  const utilityUrl = process.env.REACT_APP_UTILITY_API_BASE;
  const Currencies = useStore(state => state.currencies);
  const MERCHANT_ENV = useStore(state => state.merchantEnv);
  const [state, setState] = useReducerState({
    ...initialState
  });

  const isBankTransferAvailableOnCurrency = (currency: string) => {
    const result = Currencies?.reduce((returnValue: boolean, element) => {
      if (currency === element.code) {
        const findBankTransfer = element?.meta_data?.payment_types?.payin.find(item => item.value === 'bank_transfer');
        return !!findBankTransfer;
      }
      return returnValue;
    }, false);
    return result;
  };

  const availableCurrencies = currencies => {
    const totalAvailableCurrencies = currencies?.data?.filter(currency => currency.enabled);
    return totalAvailableCurrencies;
  };

  const enabledCurrency = availableCurrencies(Currencies);

  const { data: REQUESTS } = MerchantServices.useGetMerchantPaymentPreferenceRequest({
    onSuccess: ({ data }) => {
      setState({
        applyStatus: data?.data[0]?.status,
        declinedReason: data?.data[0]?.comments,
        updatedAt: data?.data[0]?.updatedAt
      });
    },
    errorMessage: 'Error fetching payment preference settings'
  });

  const { data: DEFAULT_SETTINGS } = MerchantServices.useGetMerchantPaymentPreference({
    errorMessage: 'Error fetching payment preference settings',
    onSuccess: ({ data }) => {
      setState({
        underpayment: data?.settings?.payment_events?.underpayment?.action,
        overpayment: data?.settings?.payment_events?.overpayment?.action
      });
    }
  });

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setState({ fileUploadLoading: true });
    const file = e.target.files?.[0];

    const fileSize = file?.size;

    if (!file) return;

    const data = new FormData();
    data.append(`files`, file);
    const formatFileSize = Math.floor(fileSize! / 1024 ** 2);
    if (formatFileSize > Number(process.env.REACT_APP_MAX_FILE_SIZE)) {
      return setState({ fileUploadError: `Please upload a file less than ${process.env.REACT_APP_MAX_FILE_SIZE || 20}MB in size` });
    }

    return uploadFiles(data)
      .then(res => {
        setState({ fileUploadName: `${utilityUrl}/files/${res?.data[0]?.path}` || '', fileUpload: true, fileUploadLoading: false });
      })
      .catch(err => {
        setState({ fileUpload: true, fileUploadError: err, fileUploadLoading: false });
      });
  };

  const handleCancelFileUpload = () => {
    setState({ fileUpload: false, fileUploadName: '' });
  };

  const { mutateAsync: updatePaymentPreference } = MerchantServices.useSetMerchantPaymentPreference({
    errorMessage: 'Error updating your payment preference settings',
    successMessage: 'Your Payment Preference request has been sent successfully',
    onSuccess: () => {
      setState({ showModal: false, isEditing: false, fileUpload: false, fileUploadName: '' });
    }
  });

  useEffect(() => {
    if (!state.underpayment || !state.overpayment) {
      return setState({ isEditing: false });
    }

    const preferenceChanged =
      state.underpayment !== DEFAULT_SETTINGS?.settings?.payment_events?.underpayment.action ||
      state.overpayment !== DEFAULT_SETTINGS?.settings?.payment_events?.overpayment.action;

    setState({ isEditing: preferenceChanged });
  }, [state.underpayment, state.overpayment]);

  const handleDropdownDisabled = () => {
    if (state.applyStatus === 'pending') return true;
    if (MERCHANT_ENV !== 'live') return true;
    return false;
  };

  const handleCancel = () => {
    setState({
      underpayment: DEFAULT_SETTINGS?.settings?.payment_events?.underpayment.action || '',
      overpayment: DEFAULT_SETTINGS?.settings?.payment_events?.overpayment.action || ''
    });
  };

  const modalDetails = {
    updatePaymentpreference: {
      ...modalContent.updatePaymentpreference,
      content: modalContent.updatePaymentpreference.content({
        overpayment: state.overpayment,
        underpayment: state.underpayment,
        handleChange: e => handleUploadFile(e),
        fileUploaded: state.fileUpload,
        handleCancel: handleCancelFileUpload,
        fileUploadLoading: state.fileUploadLoading
      }),
      secondButtonActionIsTerminal: true,
      secondButtonDisable: !state.fileUploadName,
      secondButtonAction: () =>
        updatePaymentPreference({
          payment_events: { overpayment: state.overpayment, underpayment: state.underpayment },
          document_reference: state.fileUploadName
        })
    },
    underpayment: {
      ...modalContent.underpayment,
      content: modalContent.underpayment.content()
    },
    overpayment: {
      ...modalContent.overpayment,
      content: modalContent.overpayment.content()
    }
  }[state.modalState] as unknown as IModalProps;

  return {
    ...state,
    setState,
    modalDetails,
    handleCancel,
    enabledCurrency,
    handleDropdownDisabled,
    isBankTransferAvailableOnCurrency,
    handleUploadFile,
    handleCancelFileUpload
  };
}
