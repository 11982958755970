/* eslint-disable react/jsx-props-no-spreading */
import { useLayoutEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { getExistingFiltersFromQuery } from '+containers/Dashboard/Shared/FilterModal';
import Table from '+containers/Dashboard/Shared/Table';
import { useSearchQuery, useSurveyTrigger } from '+hooks';
import useFetchIssuingExport from '+hooks/useFetchIssuingExport';
import APIRequest from '+services/api-services';
import { IdentityServices } from '+services/identity-services';
import useStore from '+store';
import { DefaultMerchantType } from '+types/defaultMerchant';
import { history } from '+utils';

import Filter from '../Issuing/components/Filter/Filter';
import HeaderTabs from '../Shared/HeaderTabs';
import IdentityAccessRequest from './AccessRequest';
import AccessBanner from './components/AccessBanner';
import PageHeader from './components/PageHeader';
import { billingHistoryFilterFields, verificationEventsFilterFields } from './data/identity-data';
import useGetIdentityPermissions from './hooks/useGetIdentityPermissions';
import IdentitySummaries from './IdentitySummaries';
import identityTableDataPairs from './tables/identityTableDataPairs';
import { IdentityServiceAccessType } from './types/types';
import VerificationDetails from './VerificationDetails';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);
const SURVEY_ID = process.env.REACT_APP_ATLAS_QUERY || '';

export function IdentityComponent() {
  const { canViewBillingHistory, canViewVerificationHistory } = useGetIdentityPermissions();
  const searchQuery = useSearchQuery();
  const [tabs, setTabs] = useState<string[]>([]);
  const { canExportBillingData, canExportVerificatinData } = useGetIdentityPermissions();
  const addTab = (tab: 'Billing' | 'Verification Events' | 'Summary') => {
    if (tabs.some(t => t === tab)) return;
    setTabs(prev => [...prev, tab]);
  };

  const [recordIsFiltered, setRecordIsFiltered] = useState(false);
  const { identityServiceAccess } = useStore(
    useShallow((state: unknown) => {
      const typedState = state as { identityServiceAccess: IdentityServiceAccessType; defaultMerchant: DefaultMerchantType };
      return typedState;
    })
  );

  const activeTab = searchQuery.value.tab || tabs[0];
  const limit = searchQuery.value.limit || '10';
  const page = searchQuery.value.page || '1';
  const country = searchQuery.value.country || '';
  const startDate = searchQuery.value.startDate || '';
  const endDate = searchQuery.value.endDate || '';

  const navigateToServiceConfiguration = () => history.push('/dashboard/identity/service-configuration');
  const {
    data: events,
    isFetching: isEventFetching,
    refetch: eventRefetch
  } = IdentityServices.useGetAllVerifications({
    enabled: activeTab === 'Verification Events',
    params: { limit, page, ...getExistingFiltersFromQuery(verificationEventsFilterFields) },
    errorMessage: 'There has been an error getting identity verification events.',
    refetchOnCloseFeedbackError: true
  });
  const { data: verificationCountData } = IdentityServices.useGetAllVerifications({
    enabled: canViewVerificationHistory,
    params: { limit, page, ...{ 'status[]': 'found' } }
  });

  const {
    data: billingHistory,
    isFetching: isBillingFetching,
    refetch: billingRefetch
  } = IdentityServices.useGetVerificationBillingHistory({
    enabled: activeTab === 'Billing',
    params: { limit, page, ...getExistingFiltersFromQuery(billingHistoryFilterFields) },
    errorMessage: 'There has been an error billing history.',
    refetchOnCloseFeedbackError: true
  });

  const { data: eventSummary, isFetching: isFetchingEventSummary } = IdentityServices.useGetIdentityEventSummary({
    enabled: activeTab === 'Summary',
    errorMessage: 'There has been an error summary.',
    params: { country, startDate, endDate },
    refetchOnCloseFeedbackError: true
  });

  const tableDataPairs = identityTableDataPairs({
    verificationData: events?.data?.data,
    verificationRefetch: eventRefetch,
    billingData: billingHistory?.data?.data,
    billingRefetch,
    canExportBillingData,
    canExportVerificatinData,
    verificationExport: useFetchIssuingExport({
      resourceType: `All-${activeTab}`,
      exportFn: ({ fieldsToExport, format }: { format: 'csv' | 'xlsx'; fieldsToExport: any }) =>
        api.getAllVerifications({
          limit,
          page,
          toExport: true,
          exportFormat: format,
          exportFields: fieldsToExport,
          ...getExistingFiltersFromQuery(verificationEventsFilterFields)
        })
    }),
    billingExport: useFetchIssuingExport({
      resourceType: `All-${activeTab}`,
      exportFn: ({ fieldsToExport, format }: { format: 'csv' | 'xlsx'; fieldsToExport: any }) =>
        api.getVerificationBillingHistory({
          limit,
          page,
          toExport: true,
          exportFormat: format,
          exportFields: fieldsToExport,
          ...getExistingFiltersFromQuery(verificationEventsFilterFields)
        })
    })
  });

  let paging;
  let loading;
  let refetch;

  switch (activeTab) {
    case 'Verification Events':
      paging = events?.data?.paging;
      loading = isEventFetching;
      refetch = eventRefetch;
      break;
    case 'Billing':
      paging = billingHistory?.data?.paging;
      loading = isBillingFetching;
      refetch = billingRefetch;
      break;
    default:
  }

  const tableData = tableDataPairs[activeTab] ? { ...tableDataPairs[activeTab], loading, refetch } : {};

  useSurveyTrigger({
    count: verificationCountData?.data?.paging?.total_items,
    surveyId: +SURVEY_ID,
    milestones: [10, 100, 1000],
    id: 'Identity',
    trigger: true
  });

  useLayoutEffect(() => {
    addTab('Summary');
    if (canViewVerificationHistory) {
      addTab('Verification Events');
    }
    if (canViewBillingHistory) {
      addTab('Billing');
    }
  }, []);

  const setSearchQuery = (value: string) => {
    searchQuery.setQuery({ tab: value }, true);
  };
  return (
    <>
      <PageHeader disableConfigButton navigateToServiceConfiguration={navigateToServiceConfiguration} />
      {!identityServiceAccess?.active && <AccessBanner />}
      <div className="content-i">
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex">
            <HeaderTabs tabs={tabs} activeTab={activeTab} onClick={setSearchQuery} />
          </div>
        </section>
        {identityServiceAccess?.has_access && activeTab ? (
          activeTab === 'Summary' ? (
            <>
              <IdentitySummaries summary={eventSummary?.data} isFetching={isFetchingEventSummary} />
            </>
          ) : (
            <>
              <>
                <Filter
                  totalItems={paging?.total_items}
                  title={tableData.tableTitle}
                  actions={tableData.tableActions as Array<'export' | 'filter'>}
                  isFiltered={recordIsFiltered}
                  onChangeIsFiltered={setRecordIsFiltered}
                  {...(tableData.filterProps && tableData.filterProps)}
                  onHandleFilter={() => {
                    tableData?.filterProps?.onHandleFilter?.();
                  }}
                />

                <section className="table-container">
                  <Table
                    tableClassName={`${tableData.tableClassName}`}
                    headings={tableData.headings}
                    hasPagination
                    loading={tableData.loading || false}
                    current={paging?.current}
                    limitAction={value => searchQuery.setQuery({ limit: String(value) })}
                    pageSize={paging?.page_size}
                    actionFn={value => searchQuery.setQuery({ page: String(value) })}
                    totalItems={paging?.total_items || 0}
                    emptyStateHeading={tableData.emptyStateHeading}
                    emptyStateMessage={
                      <>
                        <span>{tableData.emptyStateMessage}</span>
                        {tableData.refetch && (
                          <button
                            type="button"
                            className="refetch-button"
                            onClick={() => tableData && tableData.refetch && tableData.refetch()}
                          >
                            <i className="os-icon os-icon-rotate-ccw" style={{ marginRight: '5px' }} />
                            Refresh
                          </button>
                        )}
                      </>
                    }
                  >
                    {tableDataPairs[activeTab]?.renderChildren?.()}
                  </Table>
                </section>
              </>
            </>
          )
        ) : (
          <p className="font-italic font-weight-light">
            This service has not yet been configured for you. Kindly request access to Identity.
          </p>
        )}
      </div>
    </>
  );
}

export default function Identity() {
  const { identityServiceAccess } = useStore(
    useShallow((state: unknown) => {
      const typedState = state as { identityServiceAccess: IdentityServiceAccessType; defaultMerchant: DefaultMerchantType };
      return typedState;
    })
  );

  const hasAccess: boolean = identityServiceAccess?.has_access;
  return (
    <Switch>
      <Route exact path="/dashboard/identity" component={hasAccess ? IdentityComponent : IdentityAccessRequest} />
      {hasAccess && (
        <Route path="/dashboard/identity/verification/:id">
          <VerificationDetails />
        </Route>
      )}
    </Switch>
  );
}
