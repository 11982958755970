/* eslint-disable no-unused-vars */
import { keepPreviousData } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetch, useSend } from '+hooks';
import { ISendResponse } from '+types';

export const SettlementServices = {
  // use get settlement account here
  useGetSettlementAccount: ({
    onError,
    bannerLevel,
    onSuccess,
    errorMessage,
    showReturnDefaultResponse
  }: {
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    onSuccess?: (data: unknown) => void;
    errorMessage?: string;
    showReturnDefaultResponse?: boolean;
  }) =>
    useFetch(`/merchants/settlement-accounts`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      showReturnDefaultResponse,
      onSuccess,
      errorMessage
    }),

  // use get Single Settlement Transactions here
  useGetSingleSettlementTransactions: ({
    enabled,
    bannerLevel,
    settlementId,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    bannerLevel?: boolean;
    settlementId: string;
    params?: { page: number; limit: number };
    errorMessage?: string;
  }) =>
    useFetch(`/settlements/${settlementId}/transactions`, {
      enabled,
      errorMessageBannerLevel: bannerLevel,
      params,
      errorMessage
    }),

  // use get Single Settlement Transactions here
  useGetSettlement: ({
    enabled,
    bannerLevel,
    settlementId,
    errorMessage
  }: {
    enabled?: boolean;
    bannerLevel?: boolean;
    settlementId: string;
    errorMessage?: string;
  }) =>
    useFetch(`/settlements/${settlementId}`, {
      enabled,
      errorMessageBannerLevel: bannerLevel,
      errorMessage
    }),

  useGetSettlements: ({
    bannerLevel,
    params,
    errorMessage
  }: {
    bannerLevel?: boolean;
    errorMessage?: string;
    params?: { page?: string; limit?: string; status?: string; sortingParams?: Record<string, any>; currency?: string };
  }) =>
    useFetch(`/settlements`, {
      params,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      placeholderData: keepPreviousData
    }),

  useGetSettlementSummary: ({
    bannerLevel,
    params,
    errorMessage
  }: {
    bannerLevel?: boolean;
    errorMessage?: string;
    params?: { currency: string };
  }) =>
    useFetch(`/settlements/summary`, {
      params,
      errorMessageBannerLevel: bannerLevel,
      errorMessage
    }),

  useGetSettlementConfig: ({
    onError,
    bannerLevel,
    select,
    onSuccess,
    errorMessage
  }: {
    select?: (data: unknown) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    onSuccess?: (data: unknown) => void;
    errorMessage?: string;
    reeInvalidate?: boolean;
  }) =>
    useFetch(`/settings/settlement`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      select,
      onSuccess,
      errorMessage,
      useEnvironmentHandler: 'live'
    }),

  useUpdateSettlementAccount: ({
    bannerLevel,
    showErrorMessage,
    onSuccess,
    onError
  }: {
    bannerLevel?: boolean;
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (e: Error) => void;
    showErrorMessage?: boolean;
  }) =>
    useSend({
      url: `/merchants/settlement-accounts`,
      method: 'put',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      showErrorMessage
    }),

  useUpdateSettlementConfig: ({
    bannerLevel,
    showErrorMessage,
    onSuccess,
    onError,
    successMessage,
    errorMessage
  }: {
    bannerLevel?: boolean;
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (e: Error) => void;
    showErrorMessage?: boolean;
    successMessage?: string;
    errorMessage?: string;
  }) =>
    useSend({
      url: `settings/settlement`,
      method: 'put',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      showErrorMessage,
      successMessage,
      errorMessage,
      useEnvironmentHandler: 'live'
    }),

  useExportSettlementTransactions: ({
    enabled,
    bannerLevel,
    settlementId,
    errorMessage,
    format,
    onSuccess,
    onError
  }: {
    enabled?: boolean;
    bannerLevel?: boolean;
    settlementId: string;
    errorMessage?: string;
    format: string;
    onSuccess?: (data: AxiosResponse<Blob>) => void;
    onError?: () => void;
  }) =>
    useFetch(`/settlements/${settlementId}/transactions`, {
      enabled,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      params: { format },
      requestConfig: { responseType: 'blob' },
      useEnvironmentHandler: true,
      onSuccess,
      onError,
      showReturnDefaultResponse: true
    }),

  useExportSettlements: ({
    enabled,
    bannerLevel,
    errorMessage,
    params,
    onSuccess
  }: {
    enabled?: boolean;
    onSuccess?: (data: AxiosResponse<Blob>) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    params: { format: string; status?: string; sortingParams?: Record<string, any>; currency?: string; fieldsToExport: string[] };
  }) => {
    const { format, status, sortingParams, currency, fieldsToExport } = params || {};
    const formattedParams = { status, format, ...sortingParams, currency, 'fieldsToExport[]': fieldsToExport };

    return useFetch(`/settlements`, {
      enabled,
      onSuccess,
      showReturnDefaultResponse: true,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      params: formattedParams,
      requestConfig: { responseType: 'blob' },
      useEnvironmentHandler: true
    });
  },

  useGetCurrencyConversionsSettlementConfig: ({
    onError,
    bannerLevel,
    select,
    onSuccess,
    errorMessage,
    params
  }: {
    select?: (data: unknown) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    onSuccess?: (data: unknown) => void;
    errorMessage?: string;
    reeInvalidate?: boolean;
    params?: { currency: string };
  }) =>
    useFetch(`/settings/settlement/conversions`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      select,
      onSuccess,
      params,
      errorMessage,
      useEnvironmentHandler: 'live'
    }),

  useUpdateConversionsSettlementConfig: ({
    bannerLevel,
    showErrorMessage,
    errorMessage,
    onSuccess,
    onError,
    currency,
    reInvalidate
  }: {
    bannerLevel?: boolean;
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (e: Error) => void;
    errorMessage?: string;
    showErrorMessage?: boolean;
    currency: string;
    reInvalidate?: boolean;
  }) =>
    useSend({
      url: `/settings/settlement/conversions?currency=${currency}`,
      method: 'patch',
      onSuccess,
      onError,
      errorMessage,
      reInvalidate,
      errorMessageBannerLevel: bannerLevel,
      showErrorMessage,
      useEnvironmentHandler: 'live'
    })
};
