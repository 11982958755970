/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';

import { useBreakpoints, useClickOutside } from '+hooks';

import './CurrencyTabs.scss';

interface ICurrencyTab {
  currencies: string[];
  allowedCurrencies?: string[];
  activeCurrency: string;
  onClick: (arg: string) => void;
  disabledOption?: boolean;
}

const queries = {
  isMobile: '(max-width: 650px)'
};
const CurrencyTabs: React.FC<ICurrencyTab> = ({ currencies, activeCurrency, onClick, allowedCurrencies, disabledOption = false }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const breakpoint = useBreakpoints(queries);
  const isMobile = (breakpoint as unknown as { isMobile: boolean })?.isMobile;
  const wrapperRef = useClickOutside<HTMLDivElement>(() => {
    setDropdownVisible(false);
  });

  const tabSwithMaxvisibleCurrencies = isMobile ? 5 : currencies?.length;

  const visibleCurrencies = useMemo(() => {
    if (!currencies || !tabSwithMaxvisibleCurrencies) return [];

    const visible = currencies.slice(0, tabSwithMaxvisibleCurrencies);
    if (!visible.includes(activeCurrency)) {
      visible[tabSwithMaxvisibleCurrencies - 1] = activeCurrency;
    }

    return visible;
  }, [currencies, tabSwithMaxvisibleCurrencies, activeCurrency]);

  const hasDropdown = visibleCurrencies?.length < currencies?.length;

  useEffect(() => {
    if (!isMobile) {
      setDropdownVisible(false);
    }
  }, [activeCurrency]);

  return (
    <>
      <ul className="nav currency-nav nav-tabs">
        {visibleCurrencies?.map((currency: string) => (
          <li className="nav-item currency-nav-item" key={currency}>
            <button
              type="button"
              className={`currency-nav-link nav-link ${activeCurrency === currency ? 'active' : ''} ${!currencies?.includes(currency) ? 'disabled' : ''}`}
              onClick={() => onClick(currency)}
              disabled={disabledOption ? !allowedCurrencies?.includes(currency) : false}
            >
              {currency}
            </button>
          </li>
        ))}
      </ul>
      {hasDropdown && (
        <div className="currency-dropdown" ref={wrapperRef} onClick={() => setDropdownVisible(!dropdownVisible)}>
          <span>
            <i className="os-icon os-icon-chevron-down" />
          </span>
          {dropdownVisible && (
            <ul className="element-box box-style currency-options">
              {currencies.map(currency => {
                let disabled = disabledOption && !allowedCurrencies?.includes(currency);
                return (
                  <li
                    role="presentation"
                    className={`ellipsis__item ${activeCurrency === currency && 'active'} ${disabled ? 'disabled' : ''}`}
                    key={currency}
                    onClick={() => {
                      if (disabled) return;
                      onClick(currency);
                      setDropdownVisible(false);
                    }}
                  >
                    <span>{currency}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default CurrencyTabs;
