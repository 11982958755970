/* eslint-disable no-unused-vars */

import { QueryKey } from '@tanstack/react-query';

import { useFetch, useSend } from '+hooks';
import { AccessResponseType } from '+types';
import { ISendResponse } from '+types/api-services-types';
import { SignInFormType, SignInResponseType, VerifyTokenResponseType } from '+types/auth-services-types';

export const AuthServices = {
  useSignIn: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (value: ISendResponse<SignInResponseType>) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend<SignInFormType, SignInResponseType>({
      url: '/auth/signin',
      method: 'post',
      useAuth: false,
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel
    }),

  /* Add otp methods here */
  useTwoFA: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (response: ISendResponse<SignInResponseType | { message: string }>) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/auth/sign-in/two-factor/complete',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel
    }),

  /* Add otp resend methods here */
  useResendOTP: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
  }) =>
    useSend({
      url: '/auth/sign-in/two-factor/otp/resend',
      method: 'post',
      onSuccess,
      onError,
      showSuccessMessage: false,
      errorMessageBannerLevel: bannerLevel
    }),

  /* add signup here */
  useSignUp: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (value: ISendResponse<SignInResponseType>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
  }) =>
    useSend({
      url: '/auth/signup',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel
    }),

  // use resend here
  useResend: ({ onSuccess, onError, bannerLevel }: { onSuccess?: () => void; onError?: (error: Error) => void; bannerLevel?: boolean }) =>
    useSend({
      url: '/auth/signup/resend-verification-email',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel
    }),

  /* Add verify token here */
  useVerifyToken: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (value: ISendResponse<VerifyTokenResponseType>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
  }) =>
    useSend({
      url: '/auth/signup/verify-user',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel
    }),
  // use verify reset token here
  useVerifyReset: ({
    onSuccess,
    onError,
    bannerLevel,
    data
  }: {
    onSuccess?: (value: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    data: { email: string | null; code: string | null };
  }) =>
    useFetch(`/users/password/reset/verify?address=${data.email}&code=${data.code}`, {
      onSuccess,
      onError,
      customBaseURL: process.env.REACT_APP_AUTH_API_BASE,
      errorMessageBannerLevel: bannerLevel,
      enabled: Boolean(data.email && data.code)
    }),

  // use initiate password reset here
  useInitiatePasswordReset: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
  }) =>
    useSend({
      url: '/users/password/reset/initiate',
      method: 'post',
      customBaseURL: process.env.REACT_APP_AUTH_API_BASE,
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel
    }),

  // use complete reset password here
  useCompletePasswordReset: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
  }) =>
    useSend({
      url: '/users/password/reset/complete',
      method: 'post',
      customBaseURL: process.env.REACT_APP_AUTH_API_BASE,
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel
    }),

  // use resend action OTP here
  useResendOTPAction: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    hasFeedbackTimeout,
    showSuccessMessage,
    showErrorMessage,
    customBaseURL
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    hasFeedbackTimeout?: boolean;
    showSuccessMessage?: boolean;
    showErrorMessage?: boolean;
    customBaseURL?: string;
  }) =>
    useSend({
      url: '/auth/two-factor/otp/resend',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      showSuccessMessage,
      showErrorMessage,
      errorMessage,
      hasFeedbackTimeout,
      customBaseURL,
      useEnvironmentHandler: 'live'
    }),

  // use get 2fa history here
  useGet2FAHistory: ({
    onSuccess,
    bannerLevel,
    showErrorMessage,
    queryKey
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    queryKey?: QueryKey;
  }) =>
    useFetch('/2fa/totp', {
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: false,
      customBaseURL: process.env.REACT_APP_AUTH_API_BASE,
      showErrorMessage,
      queryKey
    }),

  // use get 2fa history here
  useFetchAccessRequestStatus: ({
    onSuccess,
    baseURL,
    bannerLevel,
    cardType = 'virtual'
  }: {
    onSuccess?: (data: { data: AccessResponseType }) => void;
    bannerLevel?: boolean;
    cardType?: string;
    baseURL?: string;
  }) =>
    useFetch(`access/card-issuance/status?card_type=${cardType}`, {
      onSuccess,
      customBaseURL: baseURL,
      errorMessageBannerLevel: bannerLevel
    }),

  // use initiate OTPToken here
  useInitiateOTPToken: ({
    onSuccess,
    onError,
    bannerLevel,
    hasFeedbackTimeout,
    errorMessage
  }: {
    onSuccess?: (data: ISendResponse<{ identifier: string; type: string }>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    hasFeedbackTimeout?: boolean;
    errorMessage?: string;
    showSuccessMessage?: boolean;
  }) =>
    useSend({
      url: '/auth/two-factor/initiate',
      method: 'post',
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE,
      onSuccess,
      onError,
      showSuccessMessage: false,
      errorMessageBannerLevel: bannerLevel,
      hasFeedbackTimeout,
      errorMessage,
      useEnvironmentHandler: 'live'
    }),

  // use initiate TwoFa Registration here
  useInitiateTwoFaRegistration: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    hasFeedbackTimeout
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    hasFeedbackTimeout?: boolean;
  }) =>
    useSend({
      url: '/auth/two-factor/initiate',
      method: 'post',
      onSuccess,
      onError,
      showSuccessMessage: false,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      hasFeedbackTimeout
    }),

  // use get init TwoFA Setup here
  useGetinitTwoFASetup: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    hasFeedbackTimeout
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    hasFeedbackTimeout?: boolean;
  }) =>
    useSend({
      url: '/2fa/totp/register/initiate',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      hasFeedbackTimeout,
      customBaseURL: process.env.REACT_APP_AUTH_API_BASE
    }),

  // use get init TwoFA Setup here
  useActivatetwoFA: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    hasFeedbackTimeout,
    showErrorMessage,
    showSuccessMessage
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    hasFeedbackTimeout?: boolean;
    showErrorMessage?: boolean;
    showSuccessMessage?: boolean;
  }) =>
    useSend({
      url: '/2fa/totp/register/complete',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      hasFeedbackTimeout,
      showErrorMessage,
      showSuccessMessage,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_AUTH_API_BASE
    }),

  // use change Password here
  useChangePassword: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    hasFeedbackTimeout,
    showErrorMessage
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    hasFeedbackTimeout?: boolean;
    showErrorMessage?: boolean;
  }) =>
    useSend({
      url: '/profile/password',
      method: 'put',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      hasFeedbackTimeout,
      showErrorMessage,
      customBaseURL: process.env.REACT_APP_AUTH_API_BASE
    }),

  // deactivate 2fa here
  useDeactivateTwoFA: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    hasFeedbackTimeout,
    showErrorMessage,
    showSuccessMessage
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    hasFeedbackTimeout?: boolean;
    showErrorMessage?: boolean;
    showSuccessMessage?: boolean;
  }) =>
    useSend({
      url: '/2fa/totp/deactivate',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      hasFeedbackTimeout,
      showErrorMessage,
      showSuccessMessage,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_AUTH_API_BASE
    }),

  useUpdateMerchantEnv: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    hasFeedbackTimeout
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    hasFeedbackTimeout?: boolean;
  }) =>
    useSend({
      url: '/merchants/profile/set-environment',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      hasFeedbackTimeout,
      showSuccessMessage: false
    }),

  useGetProductAccess: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (value: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
  }) =>
    useFetch(`/settings/products/access`, {
      onSuccess,
      onError,
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  useVerifyGetStartedTokenTeam: ({
    onSuccess,
    onError,
    bannerLevel,
    token
  }: {
    onSuccess?: (value: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    token: string | null;
  }) =>
    useFetch(`/users/teams/invites/${token}`, {
      onSuccess,
      onError,
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE,
      errorMessageBannerLevel: bannerLevel
    }),

  useCompleteGetStartedTeam: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    hasFeedbackTimeout
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    hasFeedbackTimeout?: boolean;
  }) =>
    useSend({
      url: 'users/teams/onboard',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      hasFeedbackTimeout
    }),

  useGetCurrency: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (value: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
  }) =>
    useFetch(`/merchants/payment-configurations`, {
      onSuccess,
      onError,
      useEnvironmentHandler: true,
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE,
      errorMessageBannerLevel: bannerLevel
    }),

  useBootstrap: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (value: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
  }) =>
    useFetch(`/merchants/get`, {
      onSuccess,
      onError,
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE,
      errorMessageBannerLevel: bannerLevel
    }),

  useAttemptResend: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
  }) =>
    useSend({
      url: '/auth/signin/verify/resend',
      method: 'post',
      onSuccess,
      onError,
      useEnvironmentHandler: 'live',
      errorMessageBannerLevel: bannerLevel
    }),

  useAttemptVerify: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (value: ISendResponse<SignInResponseType>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
  }) =>
    useSend({
      url: '/auth/signin/verify',
      method: 'post',
      onSuccess,
      onError,
      useEnvironmentHandler: 'live',
      errorMessageBannerLevel: bannerLevel
    }),

  useSignout: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (value: ISendResponse<SignInResponseType>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
  }) =>
    useSend({
      url: '/auth/signout',
      method: 'post',
      onSuccess,
      onError,
      useEnvironmentHandler: 'live',
      errorMessageBannerLevel: bannerLevel,
      showErrorMessage: false,
      showSuccessMessage: false,
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE
    })
};
