import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import qs from 'qs';

import { IAPIErrorResponse } from '+types/api-services-types';

import useStore from '../store';
import { history, logError, Logger, logout } from '../utils';
import APIServiceError from './error-services';

const APIBaseURL = process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE || 'http://localhost:3000/api';

export const createApiClient = (auth = true, hasEnvironmentHeader: boolean | string = true) => {
  const subscription = useStore.subscribe(() => {});
  subscription();
  const { authDetails, baseURL, tempToken, merchantEnv } = useStore.getState() as any;

  const setEnvironmentHeader = () => {
    if (!merchantEnv) return authDetails?.account?.merchants[0]?.env;
    return merchantEnv;
  };

  const abortController = new AbortController();
  const config: AxiosRequestConfig = {
    baseURL: baseURL || APIBaseURL,
    timeout: 60000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(hasEnvironmentHeader && {
        'www-environment': typeof hasEnvironmentHeader === 'string' ? hasEnvironmentHeader : setEnvironmentHeader()
      })
    },
    signal: abortController.signal
  };
  const client = axios.create(config);
  const setBaseURL = (url: string) => {
    client.defaults.baseURL = url;
  };

  const setAuthorization = () => {
    const userToken = authDetails?.access_token;
    const tempAccessToken = tempToken;
    if (tempAccessToken && !userToken) return `Bearer ${tempAccessToken}`;
    if (userToken) return `Bearer ${userToken}`;
    return null;
  };

  const refresh = async (url: string | undefined, token: any) => {
    if (token) {
      const response = await client.post(`${url}/auth/refresh-token`, { refresh_token: token });
      const authResponse = response.data.data;
      useStore.setState({ authDetails: authResponse });
    }
    return null;
  };

  const refreshAuthLogic = async (): Promise<void> => {
    const refreshToken = authDetails?.refresh_token;
    const refreshTokenExpiration = authDetails?.refresh_token_expires_in;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (refreshToken && currentTimestamp < refreshTokenExpiration) {
      try {
        await refresh(process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE, refreshToken);
        Logger.info('Access token refreshed, retrying previous request');
        return Promise.resolve();
      } catch (error) {
        logError(error);
      }
    }
    logout('/auth/login');
  };

  createAuthRefreshInterceptor(client, refreshAuthLogic, {
    shouldRefresh: error => {
      const status = error?.status || error?.response?.status;
      const currentPath = window?.location?.pathname || '';
      const fromAuth = ['/auth/signin', '/auth/signup', '/auth/two-factor/complete'].includes(error.response?.config?.url ?? '');
      if (status === 401 && !fromAuth) {
        if (currentPath?.includes('auth/kyc')) {
          history.push('/auth/login');
        }
        return true;
      }
      return false;
    }
  });

  client.interceptors.request.use(
    (requestConfig: { headers?: any; url?: string | undefined; paramsSerializer?: any }) => {
      const configCopy = requestConfig;
      configCopy.paramsSerializer = (params: any) => {
        const result = qs.stringify(params, { arrayFormat: 'brackets' });
        return `${decodeURIComponent(result)}`;
      };

      if (auth) {
        const refreshToken = authDetails?.refresh_token;
        const userTokenExpiration = authDetails?.expires_in;
        configCopy.headers = { ...config.headers, Authorization: setAuthorization() };
        // Handle refresh token for API calls that aren't auth
        if (configCopy?.url?.includes('/auth') || configCopy.url?.includes('/oauth')) return configCopy;

        // Set refresh threshold to two minutes
        const refreshThreshold = Math.floor((new Date().getTime() + 120000) / 1000);

        if (refreshToken && userTokenExpiration < refreshThreshold) {
          refresh(process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE, refreshToken).catch(error => logError(error));
        }
      }

      return configCopy;
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );

  client.interceptors.request.use((requestConfig: AxiosRequestConfig<any>) => {
    const { sessionActive } = useStore.getState() as any;
    const currentPath = window?.location?.pathname || '';
    const onDashboard = currentPath.includes('dashboard') || currentPath.includes('auth/kyc');
    if (sessionActive !== true && onDashboard) {
      if (currentPath?.includes('auth/kyc')) history.push('/auth/login');
      abortController.abort('session inactive');
    }
    return requestConfig;
  }, undefined);

  client.interceptors.response.use(undefined, (error: AxiosError<IAPIErrorResponse>) => {
    if (!error.response) {
      if (process.env.NODE_ENV !== 'production') {
        Logger.error('Response: ', 'Network Error');
      }
      return Promise.reject(
        new APIServiceError({
          status: 500,
          data: {
            message: 'Network Error, try again',
            error: 'server_error',
            data: null
          }
        })
      );
    }

    if (process.env.NODE_ENV !== 'production') {
      Logger.warn('Response: ', error.response);
    }

    return Promise.reject(new APIServiceError(error.response));
  });
  return { client, setBaseURL };
};
