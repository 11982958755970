import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { TransactionServices } from '+services/transaction-services';
import { history, switchTrxnMessage } from '+utils';

const usePayOutDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [modalType, setModalType] = useState('');

  const { data, isLoading } = TransactionServices.useGetSingleTransaction({
    kind: 'payouts',
    onError: () => history.goBack(),
    errorMessage: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
    ref: id
  });

  const trxnStatusObj = switchTrxnMessage[data?.data?.status ?? ('processing' as keyof typeof switchTrxnMessage)];

  return { trxnStatusObj, modalType, setModalType, isLoading, data: data?.data };
};

export default usePayOutDetails;
