import { IPreferencesReservedVC } from '+types';

import { reservedCardPlansDoc, subscriptionPlanOptions } from './accessRequestHelpers';

const PreferencesReservedVC = ({ formik }: IPreferencesReservedVC) => {
  const { values, errors, touched, getFieldProps, setFieldValue, handleBlur, setFieldTouched } = formik;

  return (
    <div className="fade-in">
      <fieldset className="mt-4">
        <div className="form-field mt-2">
          <label className="label label--sm" htmlFor="subscription-plan">
            Subscription plan?{' '}
            <a href={reservedCardPlansDoc} target="_blank" rel="noopener noreferrer" style={{ color: 'hsla(216, 90%, 55%, 1)' }}>
              See plans and pricing
            </a>
          </label>
          <select
            className="form-control"
            id="subscription-plan"
            {...getFieldProps?.('subscriptionPlan')}
            aria-invalid={touched?.subscriptionPlan && errors?.subscriptionPlan ? 'true' : 'false'}
            aria-errormessage="pci-dss-error"
          >
            <option value="">- Select subscription plan -</option>
            {subscriptionPlanOptions.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <span id="monthly-payment-error" className="field-error">
            {touched?.subscriptionPlan && errors?.subscriptionPlan}
          </span>
        </div>
      </fieldset>
    </div>
  );
};

export default PreferencesReservedVC;
