import { Redirect, Route, Switch } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import AccessDenial from '+containers/Dashboard/AccessDenial';
import TransactionDetails from '+containers/Dashboard/TransactionDetails';
import { TabPanel, TabPanels, Tabs } from '+containers/Shared/Tabs';
import { useSearchQuery } from '+hooks';
import { CardIssuanceServices } from '+services/card-issuance-services';
import useStore from '+store';
import { CurrencyType } from '+types';
import { checkProductAccess } from '+utils';

import CurrencyPicker from '../Shared/CurrencyPicker';
import HeaderTabs from '../Shared/HeaderTabs';
import Balance from './Balance';
import Billing from './Billing';
import CardTransactions from './CardTransactions';
import AccessRequestOnboarding from './components/AccessRequestOnboarding';
import BillingHistoryDetails from './components/BillingHistoryDetails';
import CardTransactionDetails from './components/CardTransactionDetails';
import ChargebackTransactionDetails from './components/ChargebackTransactionDetails';
import CustomerCards from './CustomerCards';
import Disputes from './Disputes';
import IssuedCardDetails from './IssuedCardDetails';
import ReservedCards from './ReservedCards';
import { getIssuingPermissions } from './utils';

import './index.scss';

const IssuingIndex = () => {
  const { setQuery, value: searchQueryValue } = useSearchQuery();
  const { cardAccess } = useStore(useShallow(store => ({ cardAccess: store.cardAccess })));
  const defaultMerchant = useStore(state => state.defaultMerchant);
  const currency = searchQueryValue?.currency ?? 'USD';
  const accessGranted = defaultMerchant?.productAccess && checkProductAccess(defaultMerchant.productAccess.card_issuance);
  const showCustomerCard =
    (cardAccess?.reserved?.status !== 'inactive' && cardAccess?.customer?.status === 'inactive') ||
    (cardAccess && cardAccess?.customer?.status === 'inactive') ||
    cardAccess?.customer?.status !== 'inactive';
  const showReservedCard =
    (cardAccess?.customer?.status !== 'inactive' && cardAccess?.reserved?.status === 'inactive') ||
    (cardAccess && cardAccess?.reserved?.status === 'inactive') ||
    cardAccess?.reserved?.status !== 'inactive';

  const tabsList = [
    {
      label: 'Balance',
      key: 'balance',
      element: <Balance />,
      visible: accessGranted
    },
    {
      label: 'Customer Cards',
      key: 'customer_cards',
      element: <CustomerCards />,
      visible: showCustomerCard
    },
    {
      label: 'Reserved Cards',
      key: 'reserved_cards',
      element: <ReservedCards />,
      visible: showReservedCard
    },
    {
      label: 'Card Transactions',
      key: 'card_transactions',
      element: <CardTransactions />,
      visible: accessGranted
    },
    {
      label: 'Disputes',
      key: 'disputes',
      element: <Disputes />,
      visible: accessGranted
    },
    {
      label: 'Billing',
      key: 'billing',
      element: <Billing />,
      visible: accessGranted
    }
  ];

  const tab = searchQueryValue?.tab ?? tabsList.filter(tab => tab.visible)[0].key;

  return (
    <div className="issuing-module__comp">
      <Tabs defaultValue={tab} onChange={tabValue => setQuery({ tab: tabValue }, true)}>
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex settlement-history__tabs">
            <HeaderTabs tabs={tabsList.map(tab => tab.key)} activeTab={tab} onClick={tabValue => setQuery({ tab: tabValue }, true)} />
            <CurrencyPicker
              className="settlement-history__currency-switch"
              aria-label="Currency Picker"
              options={['USD']}
              onChange={value => setQuery({ currency: value })}
              activeCurrency={currency}
            />
          </div>
        </section>

        <TabPanels>
          {tabsList.map(({ element, key }) => (
            <TabPanel value={key} key={key}>
              {element}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

const IssuingModule = () => {
  const { value: searchQueryValue } = useSearchQuery<{ currency: CurrencyType }>();
  const currency = searchQueryValue?.currency ?? 'USD';
  const defaultMerchant = useStore(state => state.defaultMerchant);
  const { cardAccess } = useStore(useShallow(store => ({ cardAccess: store.cardAccess })));
  const { canViewCardList, canViewTrxnDetails, canViewBillingHistory } = getIssuingPermissions();
  const accessGranted = defaultMerchant?.productAccess && checkProductAccess(defaultMerchant.productAccess.card_issuance);

  const { addWalletBalance } = useStore(useShallow(store => store));
  const { canViewWalletBalance } = getIssuingPermissions();

  CardIssuanceServices.useFetchIssuingBalance({
    onSuccess: data => addWalletBalance(data.data),
    refetchOnCloseFeedbackError: true,
    enabled: canViewWalletBalance && accessGranted,
    params: { currency }
  });

  return (
    <div>
      <Switch>
        <Route exact path="/dashboard/issuing">
          {!accessGranted && cardAccess?.reserved?.status === 'inactive' && cardAccess?.customer?.status === 'inactive' ? (
            <AccessRequestOnboarding />
          ) : (
            <IssuingIndex />
          )}
        </Route>

        {cardAccess?.customer?.status !== 'inactive' && (
          <Route exact path="/dashboard/issuing/customer_cards/:ref">
            {canViewCardList ? <IssuedCardDetails /> : <AccessDenial />}
          </Route>
        )}

        {cardAccess?.reserved?.status !== 'inactive' && (
          <Route exact path="/dashboard/issuing/reserved_cards/:ref">
            {canViewCardList ? <IssuedCardDetails /> : <AccessDenial />}
          </Route>
        )}

        {accessGranted ? (
          <>
            <Route path="/dashboard/issuing/card-transactions/:id">
              {canViewTrxnDetails ? <CardTransactionDetails /> : <AccessDenial />}
            </Route>

            <Route path="/dashboard/issuing/issued-card-chargebacks/:id">
              {canViewTrxnDetails ? <ChargebackTransactionDetails /> : <AccessDenial />}
            </Route>

            <Route path="/dashboard/issuing/funding-deposit/:id">
              <TransactionDetails />
            </Route>

            <Route path="/dashboard/issuing/billing-history/:id">
              {canViewBillingHistory ? <BillingHistoryDetails /> : <AccessDenial />}
            </Route>
          </>
        ) : (
          <Route path="/dashboard/issuing/">
            <Redirect to="/dashboard/issuing" />
          </Route>
        )}
      </Switch>
    </div>
  );
};

export default IssuingModule;
