import { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

import useStore from '+store';

import Slider from './Slider';

import CancelIcon from '+assets/img/cancel-icon.svg';
import DismissLink from '+assets/img/dashboard/cancel-kyc.svg';
import CheckIcon from '+assets/img/dashboard/check-circle.svg';
import KycLinkIcon from '+assets/img/dashboard/kyc-link-icon.svg';
import InfoIcon from '+assets/img/external/information-icon.svg';

import './Banner.scss';

const KycBanner = () => {
  const status = useStore(state => state.merchantKYC);
  const { pathname } = useLocation();
  const [item, setItem] = useState('');

  const getKycStatus = () => {
    if (status?.compliance) {
      const complianceStatus = status?.compliance?.status;
      const flaggedCategories = status?.compliance?.flagged_categories;
      switch (complianceStatus) {
        case 'unverified':
          return flaggedCategories?.length > 0 ? 'two' : 'one';
        case 'feedback':
          return 'two';
        case 'kyc':
          return 'one';
        case 'ready':
          return 'three';
        case 'verified':
          return '';
        default:
          return 'one';
      }
    }
    return '';
  };
  const stage = {
    one: {
      Img: InfoIcon,
      Text: 'Complete your KYC process to Go Live!',
      bColor: '#FFB447',
      tColor: '#915200',
      CancelImg: DismissLink
    },
    two: {
      Img: InfoIcon,
      Text: 'You got feedback! Update your KYC information',
      bColor: '#FFB447',
      tColor: '#915200',
      CancelImg: DismissLink
    },
    three: {
      Img: CheckIcon,
      Text: 'KYC Verification in progress...',
      bColor: '#24B314',
      tColor: '#FFFFFF',
      CancelImg: CancelIcon
    }
  };

  useEffect(() => {
    setItem(getKycStatus());
  }, [status]);

  if (pathname.match('/dashboard/settings')) {
    return null;
  }
  return item ? (
    <div className="banner" style={{ background: stage?.[item]?.bColor }}>
      <img src={stage?.[item]?.Img} alt="info" className="banner-sparkle" />
      <div className="slider-wrapper">
        <Slider>
          <p style={{ color: stage?.[item]?.tColor }}>{stage?.[item]?.Text}</p>
          {stage?.[item]?.Img === InfoIcon && (
            <a href="/auth/kyc" rel="noreferrer" className="styled-text">
              <span>Proceed to KYC</span>
              <img className="link-icon" src={KycLinkIcon} alt="proceed" />
            </a>
          )}
        </Slider>
      </div>

      <button data-testid="close-button" type="button" className="banner-btn" onClick={() => setItem('')}>
        <p className="banner-action" style={{ color: stage?.[item]?.tColor }}>
          Dismiss
        </p>
        <img src={stage?.[item]?.CancelImg} alt="cancel" className="banner-btn-icon" />
      </button>
    </div>
  ) : null;
};

export default withRouter(KycBanner);
