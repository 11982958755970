import React from 'react';

import useStore from '+store';
import { getDate } from '+utils';

import WarningIcon from '+assets/img/dashboard/warning-info-rounded.svg';

interface IBvnUpdateProps {
  setBvnModal: () => void;
  userRole: string;
}

const BvnUpdate: React.FC<IBvnUpdateProps> = ({ setBvnModal, userRole }) => {
  const status = useStore(state => state.merchantKYC);
  const checkStatus = Boolean(status?.bvn_required && (userRole === 'Owner' || userRole === 'Administrator'));

  return checkStatus ? (
    <div className="bvn-update">
      <div className="bvn-details">
        <div className="bvn-image mr-3">
          <img src={WarningIcon} alt="bvn-link" />
        </div>
        <div className="bvn-text">
          <p className="heading">Your account may soon be suspended</p>
          <p className="sub-text">
            Kindly update your Bank Verification Number (BVN) before{' '}
            <b>{getDate(process.env.REACT_APP_BVN_COLLECTION_DEADLINE || new Date())}</b> to avoid restrictions to your services on Kora.
          </p>
        </div>
      </div>
      <div>
        <button type="button" className="btn btn-primary bvn-button" onClick={setBvnModal}>
          Update BVN
        </button>
      </div>
    </div>
  ) : null;
};

export default BvnUpdate;
