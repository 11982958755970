import { UtilServices } from '+services/util-services';
import { currencyToCountryCode } from '+utils';

type Options = {
  showError?: boolean;
};

export default function useMobileOperators(currency = '', options: Options = {}) {
  const countryCode = currencyToCountryCode[currency.toUpperCase()];

  const result = UtilServices.useFetchMobileMoneyOperators({
    code: countryCode
  });

  return result;
}
