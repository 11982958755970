import { AuthServices } from '+services/auth-services';
import useStore from '+store';

export default function use2FAInfo({ onSuccess, key = 'GET_TWOFA_HISTORY' }: { onSuccess?: () => void; key?: string } = {}) {
  const profile = useStore(state => state.profile);
  return AuthServices.useGet2FAHistory({
    onSuccess,
    queryKey: [key, profile?.email],
    showErrorMessage: false
  });
}
