import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import { capitalizeRemovedash } from '+utils';

const UnderpaymentContent = () => {
  return (
    <section className="payment-preferences__modal-content">
      <article className="mb-4">
        <p>Underpayment occurs when a customer is expected to pay a fixed amount but pays less than the expected amount.</p>
      </article>
      <article className="my-4">
        <h6>Accept All</h6>
        <p>This means you will keep any underpayment made by a customer.</p>
      </article>
      <article className="my-4">
        <h6>Return All</h6>
        <p>We would return the amount paid by the customer instantly.</p>
      </article>
    </section>
  );
};

const Overpayment = () => {
  return (
    <section className="payment-preferences__modal-content">
      <article className="mb-4">
        <p>Over-payment occurs when a customer is expected to pay a fixed amount but pays more than the expected amount.</p>
      </article>
      <article className="my-4">
        <h6>Accept All</h6>
        <p>This means you will accept any overpayment made by a customer.</p>
      </article>
      <article className="my-4">
        <h6>Return All</h6>
        <p>We would return the amount paid by the customer instantly.</p>
      </article>
      <article className="my-4">
        <h6>Return Excess</h6>
        <p>We would receive the amount expected but return the excess amount</p>
      </article>
    </section>
  );
};

const SubmitPreferenceRequest = ({
  overpayment,
  underpayment,
  handleChange,
  fileUploaded,
  handleCancel,
  fileUploadLoading
}: {
  overpayment: string;
  underpayment: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileUploaded: boolean;
  fileUploadLoading: boolean;
  handleCancel: () => void;
}) => {
  const fileRef = React.useRef<HTMLInputElement>(null);

  return (
    <section className="payment-preferences__summary-changes">
      <div className="summary-of-changes">
        <h1>Summary Of Changes</h1>
        <div>
          <p>Overpayment</p>
          <p>{capitalizeRemovedash(overpayment)}</p>
        </div>
        <div>
          <p>Underpayment</p>
          <p>{capitalizeRemovedash(underpayment)}</p>
        </div>
      </div>
      <hr />
      <div className="download-attestation">
        <Icon name="downloadAttestation" />
        <p>
          Please{' '}
          <a
            href="https://api.koraapi.com/utilities/api/files/files/d27b6cc0-6a65-11ef-9838-c9d5caeaf478.pdf"
            target="_blank"
            referrerPolicy="no-referrer"
            download
            rel="noreferrer"
          >
            {' '}
            download the attestation document here.
          </a>{' '}
          Then, sign and upload the document below.
        </p>
      </div>
      <div className="upload-document" style={{ background: fileUploaded ? '#E4FFF1' : '' }}>
        <p>
          <Icon name={fileUploaded ? 'correct' : 'file'} />{' '}
          <span>{!fileUploaded ? 'Upload signed document here' : 'Attestation Document'}</span>
        </p>
        {fileUploadLoading && <span className="spinner-border spinner-border-sm loader-span" role="status" aria-hidden="true" />}
        {fileUploaded ? (
          <button onClick={handleCancel} type="button">
            <span className="uploaded-text">Uploaded</span>
            <Icon name="cancel" />
          </button>
        ) : (
          <button onClick={() => fileRef?.current?.click()} type="button">
            <input ref={fileRef} onChange={handleChange} multiple={false} type="file" accept=".pdf" hidden />
            <span className="upload-text">Upload</span>
            <Icon name="upload" />
          </button>
        )}
      </div>
    </section>
  );
};

export const modalContent = {
  updatePaymentpreference: {
    heading: 'Submit Preference Request',
    description:
      'For any change to your preference to be effected, you would need to upload an attestation document signed by an authorised signatory from your company.',
    content: SubmitPreferenceRequest,
    showButtons: true,
    secondButtonText: 'Submit',
    secondButtonColor: '#24B314',
    secondButtonDisabled: false,
    secondButtonActionIsTerminal: true
  },

  underpayment: {
    heading: 'Underpayment',
    showButtons: false,
    content: UnderpaymentContent
  },

  overpayment: {
    heading: 'Overpayment',
    showButtons: false,
    content: Overpayment
  }
};

export const paymentPreferenceOptions = {
  underpayment: [
    { name: 'Choose an Option', value: '' },
    { name: 'Return All', value: 'return_all' },
    { name: 'Accept', value: 'accept' }
  ],
  overpayment: [
    { name: 'Choose an Option', value: '' },
    { name: 'Accept', value: 'accept' },
    { name: 'Return Excess', value: 'return_excess' },

    { name: 'Return All', value: 'return_all' }
  ]
};

export const initialState = {
  overpayment: '',
  underpayment: '',
  modalState: '',
  showModal: false,
  isEditing: false,
  applyStatus: '',
  fileUpload: false,
  fileUploadName: '',
  fileUploadLoading: false,
  fileUploadError: '',
  declinedReason: '',
  updatedAt: '',
  showMoreInfo: false,
  showDeclinedReason: false,
  showWhatsNew: true
};
