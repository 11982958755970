import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceholder';
import Modal from '+containers/Dashboard/Shared/Modal';
import { CaretDown, InfoIcon, Save } from '+containers/Shared/Icons';
import { backwardAmountInput } from '+utils';

import useConversions from './useConversions';

import './conversions.scss';

const Conversions = ({ activeCurrency }: { [key in 'activeCurrency']: string }) => {
  const {
    activated,
    activeModal,
    rateMarkup,
    modalDetails,
    enabled,
    settlementCurrency,
    disableSettlementBtn,
    toggleConversions,
    isFetching,
    currencyList,
    setState,
    rateSample
  } = useConversions(activeCurrency);

  return (
    <div className="conversions" data-testid="conversions">
      <section className="settlement-currency">
        <div className="settlement-currency__action">
          <div>
            <h5>Swap {activeCurrency} Settlements</h5>
            <p className="grey-text" data-testid="subtitle">
              A merchant may choose to have payments in one currency settled in another currency. This can be configured here
            </p>
          </div>
          {enabled && (
            <button
              data-testid="update-conversions-btn"
              className="btn btn-green"
              disabled={disableSettlementBtn()}
              type="button"
              onClick={() => setState({ activeModal: 'enableConversion' })}
            >
              <Save title={'save-button'} />
              <span>Save Changes</span>
            </button>
          )}
        </div>
        <div className="toggle">
          <input
            aria-label="toggle-conversions-status"
            disabled={!currencyList.length}
            data-testid="conversions-checkbox"
            checked={enabled}
            type="checkbox"
            onChange={toggleConversions}
          />
          <span>Allow this merchant to settle {activeCurrency} payments in another currency</span>
        </div>{' '}
      </section>
      {!activated && (
        <div className="conversions-markup__disabled-account">
          <InfoIcon title="info" />
          <p>
            This feature has been disabled on this account. Please{' '}
            <a href="mailto:support@korapay.com" target="_blank" rel="noopener noreferrer">
              contact our team
            </a>{' '}
            to request access.
          </p>
        </div>
      )}
      {enabled && (
        <section className="conversions-markup visible">
          {isFetching ? (
            <LoadingPlaceholder type="text" content={2} />
          ) : (
            <>
              <div className="conversions-markup__edit" data-testid="conversions-form">
                <div className="conversions-markup__edit-markup">
                  <strong>Select the settlement currency</strong>
                  <p>All transactions will be settled in the currency you chose for this merchant</p>
                </div>
                <div className="conversions-markup__edit-wrapper">
                  <select
                    data-testid="conversions-currency-select"
                    value={settlementCurrency}
                    onChange={e => setState({ settlementCurrency: e?.target?.value })}
                  >
                    <option selected disabled value="">
                      Select settlement currency
                    </option>
                    {currencyList.map(arg => (
                      <option key={arg} value={arg}>
                        {arg}
                      </option>
                    ))}
                  </select>
                  <CaretDown title={'caret-down'} />
                </div>
              </div>
              <div className="conversions-markup__edit">
                <div className="conversions-markup__edit-markup">
                  <strong>Currency Rate markup</strong>
                  <p>Apply a markup to the exchange rate. This will be added on the rate at the time of the transaction.</p>
                </div>
                <div className="conversions-markup__edit-wrapper">
                  <div className="select-wrapper">
                    <select defaultValue="%" disabled>
                      <option>%</option>
                    </select>
                  </div>
                  <input
                    data-testid="rate-markup-input"
                    value={rateMarkup}
                    onChange={e => {
                      setState({ rateMarkup: String(backwardAmountInput(e.target.value) as string) });
                    }}
                    placeholder="0.00"
                  />
                </div>
              </div>
              <div className="conversions-markup__markup-rate">
                <InfoIcon title="info" fill="#FA9500" />
                <p>
                  Your customers will see the exchange rate at the time of the transaction. For example,{' '}
                  {
                    <span style={{ display: 'inline-flex', gap: 5, alignItems: 'center' }}>
                      <strong>
                        {activeCurrency} {rateSample?.[activeCurrency]}
                      </strong>
                      <i>=</i>
                      <strong>
                        {settlementCurrency} {rateSample?.[settlementCurrency]}
                      </strong>
                    </span>
                  }{' '}
                  (Note: this is not the current rate).
                </p>
              </div>
            </>
          )}
        </section>
      )}

      <Modal
        visible={!!activeModal}
        close={() => setState({ activeModal: '' })}
        heading={modalDetails?.heading}
        description={modalDetails?.description}
        content={modalDetails?.content}
        secondButtonText={modalDetails?.secondButtonText}
        size={modalDetails?.size}
        secondButtonColor={modalDetails?.secondButtonColor}
        secondButtonActionIsTerminal={modalDetails?.secondButtonActionIsTerminal || false}
        secondButtonAction={modalDetails?.secondButtonAction}
        showButtons={modalDetails?.showButtons}
        secondButtonDisable={modalDetails?.secondButtonDisable}
      />
    </div>
  );
};

export default Conversions;
