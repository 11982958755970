import { QueryClient } from '@tanstack/react-query';

import { Storage } from '+services/storage-services';
import useStore from '+store';
import { history } from '+utils';

const store = useStore;

export const logout = (destination: null | string = null, queryClient?: QueryClient) => {
  store.setState({
    authDetails: {},
    tempToken: '',
    profile: {},
    defaultMerchant: {},
    sessionActive: false,
    merchantEnv: '',
    merchantKYC: { bvn_required: false }
  });
  store.destroy(); // destroy the listener
  store.persist.clearStorage(); // clear the store from storage
  Storage.removeItem('merchantID');
  if (queryClient)
    queryClient.setDefaultOptions({
      queries: {
        enabled: false,
        refetchOnWindowFocus: false
      }
    });
  if (destination) history.push(destination);
};
