import { useEffect, useState } from 'react';
import { convertToWords } from 'react-number-to-words';

import { IdentityServices } from '+services/identity-services';
import useStore from '+store';
import { DefaultMerchantType } from '+types/defaultMerchant';

import IdentityAccessRequestForm from '../AccessRequestForm';
import RequestLimitReached from '../components/RequestLimitReached';
import { IdentityServiceAccessType, IdRequestData } from '../types/types';
import IdAccessRequestCompletedModal from './IdAccessRequestModal/IdAccessRequestCompletedModal';
import UploadDueDiligenceForm, { IEvidenceOfFileUpload } from './UploadDueDiligenceForm';

import './index.scss';

const STEPS = {
  START: 'START',
  UPLOAD_DUE_DILIGENCE_FORM: 'UPLOAD_DUE_DILIGENCE_FORM',
  REQUEST_FORM: 'REQUEST_FORM',
  REQUESTED: 'REQUESTED'
} as const;

const IdentityAccessRequest = () => {
  const [showModal, setShowModal] = useState(false);
  const { identityServiceAccess } = useStore.getState() as unknown as {
    identityServiceAccess: IdentityServiceAccessType;
    defaultMerchant: DefaultMerchantType;
  };
  const [hasPendingReq, sethasPendingReq] = useState(identityServiceAccess?.has_pending_request);
  const [canRequest, setCanRequest] = useState(identityServiceAccess?.has_pending_request);

  const [requestAccessLimitReached, setRequestAccessLmitReached] = useState(false);

  useEffect(() => {
    const limitReached = (identityServiceAccess?.attempts ?? 0) >= (identityServiceAccess?.max_attempts ?? 0);
    setRequestAccessLmitReached(limitReached);
    setCanRequest(!limitReached && !identityServiceAccess?.has_pending_request);
    sethasPendingReq(identityServiceAccess?.has_pending_request);
  }, [identityServiceAccess]);

  const [evidenceOfFileUpload, setEvidenceOfFileUpload] = useState<IEvidenceOfFileUpload | undefined>();

  const [canProceed, setCanProceed] = useState(false);
  const [step, setStep] = useState<keyof typeof STEPS>(STEPS.START);

  const attemptsLeft = (identityServiceAccess?.max_attempts ?? 0) - (identityServiceAccess?.attempts ?? 0);

  const {
    data: requestsInfo,
    isLoading,
    isFetching,
    refetch: fetchData
  } = IdentityServices.useGetIdentityAccessConfig({
    enabled: false,
    refetchOnCloseFeedbackError: true,
    onSuccess: () => {
      if (canProceed) {
        setStep(STEPS.UPLOAD_DUE_DILIGENCE_FORM);
      }
    }
  });

  return (
    <div className={`${step === (STEPS.START || STEPS.REQUESTED) && 'h-100'} identity-access-request`}>
      <div className={`${step === (STEPS.START || STEPS.REQUESTED) && 'h-100'} row justify-content-center`}>
        <div
          className={`${
            step === (STEPS.START || STEPS.REQUESTED) && 'h-100'
          } col-12 col-md-12  col-lg-8 col-xl-6 d-flex justify-content-center align-items-center`}
        >
          {(step === STEPS.START || step === STEPS.REQUESTED) && (
            <div className="id-request-card text-center fade-in">
              <span className="access-request-header-icon ">
                <i className="os-icon os-icon-fingerprint" style={{ fontSize: '60px' }} />
              </span>
              <h2 className="id-request-title mt-4">Build trust and safety for your business with Identity</h2>
              <p className="id-request-subtitle mt-4">
                Keep your business protected with Identity - Kora&apos;s robust KYC engine that enables businesses to verify and
                authenticate the identity of their customers.
              </p>

              {hasPendingReq && (
                <h6 className="px-4 access-requested-text mt-5 mb-4">
                  Your request has been received and is being reviewed. We will contact you shortly.
                </h6>
              )}

              {!hasPendingReq && requestAccessLimitReached && <RequestLimitReached />}

              {!hasPendingReq && canRequest && (
                <>
                  <button
                    disabled={isLoading}
                    type="button"
                    className="btn btn-primary p-3 mt-3 mb-3"
                    onClick={async () => {
                      setCanProceed(true);
                      await fetchData();
                    }}
                  >
                    <span> Request Access</span>{' '}
                    {isFetching && (
                      <span
                        className="spinner-border spinner-border-sm"
                        style={{ marginRight: '0.5rem' }}
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                  {(identityServiceAccess.attempts ?? 0) > 0 && (
                    <p className="text-dark mb-3">
                      You have{' '}
                      <span className="font-italic id-text-bold">
                        {' '}
                        {convertToWords(attemptsLeft)} ({attemptsLeft}) attempts Left
                      </span>
                    </p>
                  )}
                </>
              )}

              <div>
                <a
                  href="https://developers.korapay.com/docs/verify-customers-businesses"
                  target="noopener noreferrer"
                  className="text-primary font-weight-bold text-decoration-none"
                >
                  <i className="os-icon os-icon-book-open font-weight-bold mr-2" />
                  See Developer Guide
                </a>
              </div>
            </div>
          )}

          {step === STEPS.REQUEST_FORM && (
            <IdentityAccessRequestForm
              onCancel={() => {
                setStep(STEPS.UPLOAD_DUE_DILIGENCE_FORM);
              }}
              onAccessRequested={status => {
                sethasPendingReq(status);
                setStep(STEPS.REQUESTED);
                setShowModal(true);
              }}
              evidenceOfFileUpload={evidenceOfFileUpload || undefined}
              requestsInfo={requestsInfo.data as IdRequestData}
            />
          )}

          {step === STEPS.UPLOAD_DUE_DILIGENCE_FORM && (
            <UploadDueDiligenceForm
              onProceed={() => {
                setStep(STEPS.REQUEST_FORM);
              }}
              onCancel={() => {
                setStep(STEPS.START);
                setEvidenceOfFileUpload(undefined);
              }}
              updateEvidence={file => {
                setEvidenceOfFileUpload(file);
              }}
              evidenceOfFileUpload={evidenceOfFileUpload || undefined}
            />
          )}
        </div>
      </div>

      {showModal ? (
        <IdAccessRequestCompletedModal
          onClose={() => {
            setShowModal(false);
          }}
        />
      ) : null}
    </div>
  );
};

export default IdentityAccessRequest;
