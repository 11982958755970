/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useFormik } from 'formik';

import ChevronRight from '+assets/icons/ChevronRight';
import Modal from '+dashboard/Shared/Modal';
import { CardIssuanceServices } from '+services/card-issuance-services';
import useStore from '+store';
import { AccessRequestPayloadType, CardAccessRequestStageType, IAccessRequestModal, IModalProps, PCIDSSLevelType } from '+types';

import { initialValues, stages, stageValidationOptions } from './accessRequestHelpers';
import CardOptionsMenu from './CardOptionsMenu';
import KycReservedVC from './KYCReservedVC';
import PreferencesCustomerVC from './PreferencesCustomerVC';
import PreferencesReservedVC from './PreferencesReservedVC';

import calendlyLogo from '+assets/img/calendly-logo.svg';
import completed from '+assets/img/dashboard/warning-info-rounded.svg';

function AccessRequestModal({ onClose, gotoStage = stages.set_card_options }: IAccessRequestModal) {
  const [stage, setStage] = useState<CardAccessRequestStageType>(gotoStage);
  const [option, setOption] = useState<CardAccessRequestStageType | null>(null);
  const { defaultMerchant, merchantDetails } = useStore(useShallow(store => store));
  const [requestIsSuccessful, setRequestIsSuccessful] = useState(false);
  const currencyAccess = useStore(useShallow(store => store.currencyAccess));
  const hasRepresentatives = (merchantDetails?.representatives ?? []).length > 0;
  const hasCurrencyAccess = currencyAccess.USD.status === 'enabled';

  useEffect(() => {
    if (gotoStage === stages.set_reserved_vc_preferences) {
      if (hasRepresentatives) setStage(stages.set_rvc_kyc);
    }
  }, [hasRepresentatives]);

  useEffect(() => {
    if (stage === stages.set_customer_vc_preferences || stage === stages.set_reserved_vc_preferences) handleOptionChange(stage);
  }, [stage]);

  const cleanupForm = () => {
    formik.resetForm({ values: initialValues });
  };

  const goToPrevStageOrClose = () => {
    if (stage === stages.set_card_options) {
      cleanupForm();
      onClose();
    }

    if ([stages.set_rvc_kyc, stages.set_customer_vc_preferences].includes(stage)) {
      cleanupForm();
      setStage(stages.set_card_options);
    }

    if (stage === stages.set_reserved_vc_preferences) {
      if (!hasRepresentatives) setStage(stages.set_card_options);
      else setStage(stages.set_rvc_kyc);
    }
  };

  const handleOptionChange = (value: CardAccessRequestStageType) => {
    setOption(prevValue => (prevValue === value ? null : value));
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      street: merchantDetails.street ?? '',
      country: defaultMerchant.country?.name ?? '',
      ...(!hasRepresentatives ? { cardholderName: defaultMerchant.name ?? '' } : {})
    },
    enableReinitialize: true,
    validate: stageValidationOptions[stage],
    onSubmit: async () => {
      switch (stage) {
        case stages.set_card_options:
          if (!hasCurrencyAccess) {
            setStage(stages.set_no_usd_access);
          } else if (option === stages.set_reserved_vc_preferences && hasRepresentatives) {
            setStage(stages.set_rvc_kyc);
          } else {
            setStage(option!);
          }
          formik.setTouched({});
          break;
        case stages.set_rvc_kyc:
          setStage(stages.set_reserved_vc_preferences);
          formik.setTouched({});
          break;
        case stages.set_reserved_vc_preferences:
          if (!hasCurrencyAccess) {
            setStage(stages.set_no_usd_access);
          } else {
            await requestReservedCards();
          }
          break;
        case stages.set_customer_vc_preferences:
        default:
          if (!hasCurrencyAccess) {
            setStage(stages.set_no_usd_access);
          } else {
            await requestCustomerCards();
          }
          break;
      }
    }
  });

  useEffect(() => {
    formik.validateForm();
  }, [stage === 'set_reserved_vc_preferences']);

  const { mutateAsync: mutateAccessStatus } = CardIssuanceServices.useRequestCardAccess({
    cardType: 'virtual',
    onSuccess: () => {
      cleanupForm();
    },
    errorMessage: 'We were unable to complete your request. Please try again'
  });

  let payload = {} as AccessRequestPayloadType;

  const requestReservedCards = async () => {
    payload = {
      currency: 'USD',
      category: 'reserved',
      subscription_plan: formik.values.subscriptionPlan as AccessRequestPayloadType['subscription_plan']
    };
    await mutateAccessStatus(payload);
  };

  const requestCustomerCards = async () => {
    payload = {
      currency: 'USD',
      category: 'customer',
      monthly_payment_value: formik.values.customerCardMonthlyPaymentValue as AccessRequestPayloadType['monthly_payment_value'],
      subscription_plan: formik.values.subscriptionPlan as AccessRequestPayloadType['subscription_plan'],
      pci_dss_level: formik.values.pciDssLevel as PCIDSSLevelType
    };
    await mutateAccessStatus(payload, { onSuccess: () => setRequestIsSuccessful(true) });
  };

  const modalPropOptions: Record<CardAccessRequestStageType | 'shared_props', Partial<IModalProps>> = {
    shared_props: {
      close: () => {
        cleanupForm();
        if (requestIsSuccessful) window.location.reload();
        onClose();
      },
      firstButtonAction: goToPrevStageOrClose,
      secondButtonAction: formik.submitForm,
      secondButtonText: 'Next',
      secondButtonActionIsTerminal: false,
      secondButtonDisable: !(formik.dirty && formik.isValid),
      completedHeading: 'Request submitted',
      completedDescription: 'Your request has been received and will be reviewed by the team shortly.'
    },
    set_card_options: {
      heading: 'Issue virtual cards',
      description: 'Select your preferred virtual card issuing option below to get started.',
      showButtons: false,
      content: <CardOptionsMenu option={option} onOptionChange={handleOptionChange} submitForm={formik.submitForm} />,
      completedHeading: 'Request submitted',
      completedDescription: 'Your request has been received and will be reviewed by the team shortly.'
    },
    set_no_usd_access: {
      completedImage: completed,
      modalStage: 'complete',
      completedHeading: `${option === stages.set_reserved_vc_preferences ? 'Unable to set up access to Reserved Virtual Cards' : 'Unable to request access to Customer Cards'}`,
      completedDescription: (
        <div>
          <p style={{ color: '#414F5F' }}>
            This action cannot be completed.{' '}
            {option === stages.set_reserved_vc_preferences
              ? 'To set up and activate access to RVCs'
              : 'To Request access to Customer Cards'}
            , you need to have access to USD products on Kora.
          </p>
          <p className="pt-4" style={{ color: '#414F5F' }}>
            Please{' '}
            <a href="http://korahq.com/contact-us" target="_blank" style={{ color: '#414F5F' }} rel="noreferrer">
              <u>
                <b>contact our support team</b>
              </u>
            </a>{' '}
            for assistance in enabling this access.
          </p>
        </div>
      )
    },
    set_customer_vc_preferences: {
      heading: <div className="text-lg m-0">Issue virtual cards to your customers</div>,
      description: (
        <p className="text-md m-0" style={{ color: 'hsla(212, 19%, 31%, 1)' }}>
          Tell us more about your business to help us understand how you would like to use the Card Issuance service.
        </p>
      ),
      content: <PreferencesCustomerVC formik={formik} />,
      firstButtonText: 'Back',
      hideFirstButton: gotoStage === stages.set_customer_vc_preferences,
      secondButtonText: 'Submit',
      secondButtonActionIsTerminal: true,
      completedDescription: (
        <div className="book-demo__copy">
          <p>Your request has been received and will be reviewed by the team shortly.</p>
          <p>In the meantime, book a demo to discover how you can issue cards to your customers seamlessly.</p>
        </div>
      ),
      completedAction: () => {
        window.open(process.env.REACT_APP_PRODUCT_SUPPORT_CALENDLY_LINK || '#', '_blank');
      },
      completedActionText: (
        <span className="d-flex align-items-center book-demo-btn">
          <img src={calendlyLogo} className="flex-shrink-0" aria-hidden alt="" width={35} height={35} />
          <span className="d-flex flex-column text-left book-demo-btn__copy">
            <strong className="book-demo-btn__title">Book a demo</strong>
            <span className="book-demo-btn__description">Schedule a session with our team</span>
          </span>
          <ChevronRight stroke="#3E4B5B" aria-hidden />
        </span>
      )
    },
    set_rvc_kyc: {
      heading: <div className="text-lg m-0">Get reserved virtual cards for your business.</div>,
      description: (
        <p className="text-md m-0" style={{ color: 'hsla(212, 19%, 31%, 1)' }}>
          Tell us more about how you would like to use the Card Issuance service.
        </p>
      ),
      content: <KycReservedVC representatives={merchantDetails.representatives ?? []} formik={formik} />,
      firstButtonText: 'Back',
      hideFirstButton: gotoStage === stages.set_reserved_vc_preferences,
      maxHeight: '720px'
    },
    set_reserved_vc_preferences: {
      heading: <div className="text-lg m-0">Confirm activation of access to Reserved Virtual Cards</div>,
      headingBorder: false,
      description: (
        <p className="text-md m-0" style={{ color: 'hsla(212, 19%, 31%, 1)' }}>
          You’re about to activate your access to issue Reserved Virtual Cards for your business. Select your preferred subscription plan to
          proceed.
        </p>
      ),
      content: <PreferencesReservedVC formik={formik} />,
      firstButtonText: 'Back',
      secondButtonActionIsTerminal: true,
      size: 'sm',
      secondButtonText: 'Confirm',
      maxHeight: '720px',
      completedHeading: 'Access activated',
      completedDescription: 'You can now create, issue and manage Reserved Virtual Cards for your business directly from your dashboard.'
    }
  };

  const modalProps = {
    ...modalPropOptions.shared_props,
    ...modalPropOptions[stage]
  };

  return (
    <div>
      <Modal key={String(stage === stages.set_no_usd_access)} {...(modalProps as IModalProps)} />
    </div>
  );
}

export default AccessRequestModal;
