/* eslint-disable no-unused-vars */
import { keepPreviousData } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { useFetch, useSend } from '+hooks';
import { ISendResponse } from '+types';

export const TransactionServices = {
  useInitiateTransfer: ({
    onSuccess,
    onError,
    bannerLevel,
    hasFeedbackTimeout,
    errorMessage
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    showErrorMessage?: boolean;
    hasFeedbackTimeout?: boolean;
    errorMessage?: string;
  }) =>
    useSend({
      url: '/transfers/initiate',
      method: 'post',
      onSuccess,
      onError,
      showSuccessMessage: false,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      hasFeedbackTimeout,
      errorMessage
    }),

  useProcessTransfer: ({
    onSuccess,
    onError,
    bannerLevel,
    showErrorMessage,
    hasFeedbackTimeout,
    errorMessage
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    showErrorMessage?: boolean;
    hasFeedbackTimeout?: boolean;
    errorMessage?: string;
  }) =>
    useSend({
      url: '/transfers/process',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      hasFeedbackTimeout,
      errorMessage,
      showErrorMessage
    }),

  useConfirmWalletBeneficiary: ({
    onSuccess,
    onError,
    bannerLevel,
    showErrorMessage,
    hasFeedbackTimeout,
    errorMessage,
    showSuccessMessage
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    showErrorMessage?: boolean;
    hasFeedbackTimeout?: boolean;
    errorMessage?: string;
    showSuccessMessage?: boolean;
  }) =>
    useSend({
      url: '/transfers/beneficiary/resolve',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      hasFeedbackTimeout,
      errorMessage,
      showErrorMessage,
      showSuccessMessage
    }),

  useCalculateTransferFees: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (data: ISendResponse<Record<string, { fee: number; vat: number }>>) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/fees/calculate',
      method: 'post',
      onSuccess,
      onError,
      showSuccessMessage: false,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetSingleTransaction: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    kind,
    ref
  }: {
    onSuccess?: (data: { data: Record<string, unknown> }) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    kind?: string;
    ref?: string;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/transactions/${kind}/${ref}`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetRelatedTransactions: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    ref,
    enabled,
    errorMessage
  }: {
    onSuccess?: (data: { data: Record<string, unknown> }) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    ref?: string;
    refetchOnCloseFeedbackError?: boolean;
    enabled?: boolean;
    errorMessage?: string;
  }) =>
    useFetch(`/transactions/payments/${ref}?limit=3`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      enabled,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useTransactionDetails: ({
    stage,
    id,
    onError,
    errorMessage
  }: {
    stage: string;
    id: string;
    onError: (error: Error) => void;
    errorMessage: string;
  }) => {
    const getURL = () => {
      switch (stage) {
        case 'pay-ins':
          return `/transactions/payins/${id}`;
        case 'payouts':
          return `/transactions/payouts/${id}`;
        case 'refunds':
          return `/refunds/${id}`;
        case 'card-transactions':
          return `/transactions/card-issuance/${id}`;
        case 'chargebacks':
          return `/chargebacks/${id}`;
        case 'issued-card-chargebacks':
          return `/chargebacks/card-issuance/${id}`;
        case 'funding-deposit':
          return `/transactions/card-issuance/wallets/deposit-requests?reference=${id}`;
        default:
          return `/conversions/${id}`;
      }
    };
    return useFetch(getURL(), {
      onError,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    });
  },

  useGetBulkPayouts: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params?: { currency: string; page: string; limit: string; status?: string[] };
    errorMessage?: string;
  }) =>
    useFetch(`/transactions/bulk-transfers`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params,
      errorMessage,
      useEnvironmentHandler: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetTransactions: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    kind,
    errorMessage,
    showErrorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    kind: string;
    params?: {
      paginationType: string;
      limit: string;
      sortingParams: { status?: string[] };
      currency: string;
      pageAction: string;
      pageCursorValue?: string;
    };
    errorMessage?: string;
    showErrorMessage?: boolean;
  }) => {
    const { sortingParams, ...otherParams } = params;
    return useFetch(`/transactions/${kind}`, {
      enabled,
      onError,
      refetchOnCloseFeedbackError,
      params: { ...otherParams, ...sortingParams },
      errorMessage,
      showErrorMessage,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    });
  },

  useGetTransactionsCount: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    kind,
    shouldKeepPreviousData
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    kind: string;
    shouldKeepPreviousData?: boolean;
    params?: {
      currency: string;
      sortingParams?: { status: string[] | undefined };
    };
  }) => {
    const { sortingParams, ...otherParams } = params;
    return useFetch(`/transactions/${kind}/count`, {
      enabled,
      onError,
      refetchOnCloseFeedbackError,
      params: { ...otherParams, ...sortingParams },
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...(shouldKeepPreviousData && { placeholderData: keepPreviousData })
    });
  },

  useSubmitBulkPayout: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/transactions/bulk/upload',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetBulkPayoutTransactionList: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    bulkReference,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    bulkReference: string;
    params?: {
      page: number;
      limit: number;
    };
    errorMessage?: string;
  }) =>
    useFetch(`/transactions/bulk/${bulkReference}/payouts`, {
      enabled,
      onError,
      refetchOnCloseFeedbackError,
      params,
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetBulkPayoutSummary: ({
    enabled,
    onError,
    onSuccess,
    bannerLevel,
    refetchOnCloseFeedbackError,
    bulkReference,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    bulkReference: string;
    errorMessage?: string;
  }) =>
    useFetch(`/transactions/bulk/${bulkReference}`, {
      enabled,
      onError,
      onSuccess,
      refetchOnCloseFeedbackError,
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: true,
      refetchInterval: 15000,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useDiscardBulkPayout: ({
    onSuccess,
    onError,
    bannerLevel,
    bulkReference,
    errorMessage
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    bulkReference: string;
    errorMessage?: string;
  }) =>
    useSend({
      url: `/transactions/bulk/${bulkReference}/cancel`,
      method: 'patch',
      onSuccess,
      onError,
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useExportBulkTransfers: ({
    enabled,
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage,
    showErrorMessage
  }: {
    enabled?: boolean;
    onSuccess?: (data: AxiosResponse) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: { format: string; sortingParams: {}; currency: string; fieldsToExport: string; batch_reference: string };
    errorMessage?: string;
    showErrorMessage?: boolean;
  }) => {
    const { format, currency, fieldsToExport, batch_reference, ...sortingParams } = params || {};
    const formattedParams = { format, currency, ...sortingParams, 'fieldsToExport[]': fieldsToExport };
    return useFetch(`/transactions/payouts?batchReference=${batch_reference}`, {
      enabled,
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      showErrorMessage,
      useEnvironmentHandler: true,
      showReturnDefaultResponse: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...{ requestConfig: { responseType: 'blob' } }
    });
  },

  useRepeatBulkPayout: ({
    onSuccess,
    onError,
    bannerLevel,
    id,
    errorMessage
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    id: string;
    errorMessage?: string;
  }) =>
    useSend({
      url: `/transactions/bulk/${id}/repeat`,
      method: 'post',
      onSuccess,
      onError,
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useEditBulkPayout: ({
    onSuccess,
    onError,
    bannerLevel,
    bulkReference,
    errorMessage,
    successMessage
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    bulkReference: string;
    errorMessage?: string;
    successMessage?: string;
  }) =>
    useSend({
      url: `/transactions/bulk/${bulkReference}`,
      method: 'patch',
      onSuccess,
      onError,
      errorMessage,
      successMessage,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useInitiateBulkPayout: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    queryData
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    errorMessage?: string;
    queryData: {
      bulkReference: string;
      data: { two_factor_type: string };
    };
  }) =>
    useSend({
      url: `/transactions/bulk/${queryData?.bulkReference}/initiate`,
      data: queryData?.data,
      method: 'patch',
      onSuccess,
      onError,
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      showSuccessMessage: false
    }),

  useStartBulkPayout: ({
    onSuccess,
    onError,
    bannerLevel,
    bulkReference,
    errorMessage,
    auth_data
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    bulkReference: string;
    errorMessage?: string;
    componentLevel?: boolean;
    auth_data: {
      code: string;
      two_factor_type: string;
      identifier: string;
    };
  }) =>
    useSend({
      url: `/transactions/bulk/${bulkReference}/start`,
      data: auth_data,
      method: 'patch',
      onSuccess,
      onError,
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      showErrorMessage: true
    }),

  useGetAccountHoldersTransactions: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage,
    id
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params?: { page: string; limit: string; sortingParams: { status?: string[] } };
    errorMessage?: string;
    id?: string;
  }) =>
    useFetch(`/virtual-bank-account/account-holders/${id}/transactions`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params,
      errorMessage,
      placeholderData: keepPreviousData,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetFileDownload: ({
    enabled,
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    link
  }: {
    onSuccess?: (data: { data: Record<string, unknown> }) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    link?: string;
    refetchOnCloseFeedbackError?: boolean;
    enabled?: boolean;
  }) =>
    useFetch(`/exports/reports/${link}`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      showReturnDefaultResponse: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useExportTransactions: ({
    enabled,
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage,
    successMessage
  }: {
    enabled?: boolean;
    onSuccess?: (data: AxiosResponse) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: { format?: string; sortingParams: any; currency: string; fieldsToExport: string; kind: string };
    errorMessage?: string;
    successMessage?: string;
  }) => {
    const { format, currency, fieldsToExport, kind, sortingParams } = params || {};
    const formattedParams = { format, ...sortingParams, currency, 'fieldsToExport[]': fieldsToExport };
    return useFetch(`/transactions/${kind}`, {
      enabled,
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      successMessage,
      showSuccessMessage: true,
      useEnvironmentHandler: true,
      showReturnDefaultResponse: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      requestConfig: { responseType: 'blob' }
    });
  }
};
