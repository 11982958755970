import { convertToWords } from 'react-number-to-words';

import useStore from '+store';

import { IdentityServiceAccessType } from '../types/types';

const LimitWarningBanner = () => {
  const { identityServiceAccess } = useStore.getState() as unknown as {
    identityServiceAccess: IdentityServiceAccessType;
  };
  return (
    <>
      {!!identityServiceAccess?.attempts && identityServiceAccess?.attempts > 0 && (
        <div className="bg-warning p-3 rounded mb-5">
          <p className="align-items-start m-0 warning-text">
            <span>
              Access requests are limited to{' '}
              {identityServiceAccess.max_attempts !== undefined ? convertToWords(identityServiceAccess.max_attempts).toLowerCase() : 'N/A'}{' '}
              attempts per account.{' '}
              <span className="id-text-bold">
                You have {identityServiceAccess.attempts} out of {identityServiceAccess.max_attempts} allowed attempts
              </span>{' '}
              remaining for this account.
            </span>
          </p>
        </div>
      )}
    </>
  );
};

export default LimitWarningBanner;
