import { useParams } from 'react-router-dom';

import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { useFeedbackHandler } from '+hooks';
import { RefundServices } from '+services/refund-services';
import { history, switchTrxnMessage } from '+utils';

import { getAccountDetailsFrom, getPayersInfoFrom, getRefundDetailsFrom, getTopLevelSummaryFrom } from './refundDetailsHelpers';

const RefundDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { feedbackInit } = useFeedbackHandler();
  const { data: { data: refundData } = {}, isLoading: refundDataIsLoading } = RefundServices.useGetSingleRefund({
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
        type: 'danger'
      });
      history.goBack();
    },
    ref: id
  });

  const topLevelSummary = getTopLevelSummaryFrom(refundData);
  const refundDetails = getRefundDetailsFrom(refundData);
  const accountDetails = getAccountDetailsFrom(refundData);
  const payersInfo = getPayersInfoFrom(refundData);

  return (
    <TransactionDetails isLoading={refundDataIsLoading}>
      <TransactionDetails.Header
        status={switchTrxnMessage[refundData?.status ?? 'pending']?.name}
        statusBg={switchTrxnMessage[refundData?.status ?? 'pending']?.backgroundColor}
        statusColor={switchTrxnMessage[refundData?.status ?? 'pending']?.color}
        heading={refundData?.merchant_reference ?? refundData?.reference ?? ''}
        summaries={topLevelSummary}
      />

      <TransactionDetails.Section heading="Refund Details" summaries={refundDetails} />
      {refundData?.source?.type === 'bank_transfer' && (
        <TransactionDetails.Section heading="Account Number Generated for Payment" summaries={accountDetails} />
      )}
      <TransactionDetails.Section heading="Payer's Information" summaries={payersInfo} />
    </TransactionDetails>
  );
};

export default RefundDetails;
