import { Link } from 'react-router-dom';

import Copyable from '+dashboard/Shared/Copyable';
import ToolTip from '+dashboard/Shared/Tooltip';
import useStore from '+store';
import { Channel, CurrencyType, DataType, PayOutMappingTypes, StatusType } from '+types';
import {
  capitalize,
  capitalizeFirst,
  capitalizeRemovedash,
  formatAmount,
  getDate,
  getTime,
  logBreadCrumb,
  paymentMethodsMap,
  switchBank,
  switchChannel,
  switchCurrency,
  switchReason,
  switchStatus
} from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import mobileIconPNG from '+assets/img/dashboard/mobile.png';
import infoIcon from '+assets/img/dashboard/information-button.svg';
import mobileIconSVG from '+assets/img/dashboard/mobile.svg';

export const payOutHeaderSummary = (trx?: DataType) => {
  return [
    {
      label: (
        <>
          Net Amount
          <ToolTip
            type="net_amount"
            image={infoIcon}
            message={
              <em>
                Net Amount <br /> This is the amount less fee
              </em>
            }
          />
        </>
      ),
      value: `${formatAmount(trx?.amount_paid)} ${trx?.currency}`
    },

    {
      label: (
        <>
          Fee
          <ToolTip type="fee" image={infoIcon} message={<em>Total charges incurred while processing this transaction</em>} />
        </>
      ),
      value: [!!trx?.fee, !!trx?.vat, !!trx?.currency].includes(false)
        ? 'N/A'
        : `${formatAmount(parseFloat(trx?.fee ?? '') + parseFloat(trx?.vat ?? ''))} ${trx?.currency}`
    },
    { label: 'Date Completed', value: trx?.completed_at ? `${getDate(trx.completed_at)}, ${getTime(trx.completed_at)}` : 'Not Available' },
    {
      label: 'Transaction ID',
      value: <Copyable text={trx?.reference ?? ''} textModifier={text => text?.toUpperCase()} />
    }
  ];
};

export const payOutMoreTrxDetailsSection = (trx?: DataType) => [
  {
    label: 'Status',
    value: (
      <>
        <span
          className={`status-pill smaller more-details-opt ${
            (trx?.status as StatusType) === 'requires_auth' ? 'yellow' : switchStatus(trx?.status ?? '')
          }`}
        />
        <>{capitalizeRemovedash(trx?.status ?? 'Noy Available')}</>
      </>
    )
  },

  ...(['expired', 'failed', 'flagged'].includes(trx?.status ?? '')
    ? [{ label: 'Reason For ' + switchReason(trx?.status ?? ''), value: trx?.message ?? 'Not Available' }]
    : []),
  ...(trx?.trace_id ? [{ label: 'Trace ID', value: trx.trace_id }] : []),
  ...(trx?.payment?.conversion
    ? [
        {
          label: 'Exchange Rate',
          value: `1 ${trx?.payment?.conversion?.settlement_currency} → ${formatAmount(trx?.payment?.conversion?.rate, 3)} ${trx?.currency}`
        },
        { label: 'Settlement Currency', value: trx?.payment?.conversion?.settlement_currency },
        {
          label: 'Settlement Amount',
          value: trx?.payment?.conversion?.settlement_amount
            ? `${formatAmount(trx?.payment?.conversion?.settlement_amount)} ${trx?.payment?.conversion?.settlement_currency}`
            : 'N/A'
        }
      ]
    : []),
  { label: 'Amount Charged', value: `${formatAmount(trx?.amount_charged ?? 0)} ${trx?.currency}` },
  { label: 'Currency Charged', value: switchCurrency[trx?.currency as CurrencyType] },
  { label: 'Fee Bearer', value: `${trx?.merchant_bears_cost ? 'Merchant' : 'Customer'}` },
  { label: 'Channel', value: switchChannel(trx?.channel as Channel) },
  { label: 'Date Created', value: trx?.created_at ? `${getDate(trx.created_at)}, ${getTime(trx.created_at)}` : 'Not Available' },
  { label: 'Date Completed', value: trx?.completed_at ? `${getDate(trx.completed_at)}, ${getTime(trx.completed_at)}` : 'Not Available' },

  ...(trx?.batch_reference
    ? [
        {
          label: 'Bulk ID',
          value: (
            <span>
              <Link to={`/dashboard/bulk-payout/${trx?.batch_reference}`} className="bulk-id">
                {trx?.batch_reference.toUpperCase()}
              </Link>
            </span>
          )
        }
      ]
    : []),
  { label: 'Kora Reference', value: trx?.unique_reference?.toUpperCase() ?? 'Not Available' },
  {
    label: 'Description',
    value: trx?.narration ?? trx?.description ?? 'No description provided'
  }
];

export const payoutRecipientInformationSection = (trx: DataType) => [
  { label: 'Payment Method', value: paymentMethodsMap[trx?.destination?.type as PayOutMappingTypes] ?? 'Not Available' },
  ...(['bank_account', 'wallet'].includes(trx?.destination?.type)
    ? [
        {
          label: 'Bank',
          value: (
            <>
              <img
                id="bank-icon"
                src={switchBank(trx?.destination?.details?.bank_slug)}
                alt="bank icon"
                style={{ marginLeft: trx?.destination?.details?.bank_name ? 0 : '' }}
              />
              <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
                {capitalize(trx?.destination?.details?.bank_name || trx?.destination?.details?.mobile_name || 'Not Available')}
              </span>
            </>
          )
        }
      ]
    : []),
  ...(trx?.destination?.type === 'mobile_money'
    ? [
        {
          label: (
            <span>
              <img id="phone-icon" src={mobileIconSVG} alt="phone icon" srcSet={mobileIconPNG} height={16} style={{ marginRight: 6 }} />
              <span>{trx?.destination?.details?.mobile_name || 'Operator Information Unavailable'}</span>
            </span>
          ),
          value: trx?.destination?.details?.mobile_number || 'Operator Information Unavailable'
        }
      ]
    : []),

  ...(trx?.destination?.details?.account_number
    ? [
        {
          label: 'Account Number',
          value: trx?.destination?.details?.account_number
        }
      ]
    : []),

  {
    label: 'Account Name',
    value: capitalize(trx?.destination?.details?.account_name || trx?.payment?.customer?.name || 'Not Available')
  },
  { label: 'Email', value: trx?.payment?.customer?.email || 'Not provided' },

  ...(trx?.destination?.details.first_name
    ? [{ label: 'First Name', value: capitalize(trx?.destination?.details?.first_name || 'Not Provided') }]
    : []),
  ...(trx?.destination?.details.last_name
    ? [{ label: 'Last Name', value: capitalize(trx?.destination?.details?.last_name || 'Not Provided') }]
    : []),
  ...(trx?.destination?.details.address
    ? [{ label: 'Address', value: capitalize(trx?.destination?.details?.address || 'Not Provided') }]
    : []),
  ...(trx?.destination?.details.city ? [{ label: 'City', value: capitalize(trx?.destination?.details?.city || 'Not Provided') }] : []),
  ...(trx?.destination?.details.country
    ? [{ label: 'Country', value: capitalize(trx?.destination?.details?.country || 'Not Provided') }]
    : [])
];

export const payoutReceiptFn = (trx: DataType) => {
  const defaultMerchant = useStore.getState().defaultMerchant;

  return {
    id: trx.reference,
    txType: 'payouts' as const,
    amount: formatAmount(trx.amount),
    currency: trx.currency,
    customerInfo: `Receipt For ${defaultMerchant.name}`,
    summaryInfo: 'Your payment has been successfully processed.',
    isInternalTransaction: true,
    summaryList: [
      {
        label: 'Amount',
        value: formatAmount(trx.amount)
      },
      {
        label: 'Customer Name',
        value: capitalize(trx.destination?.details?.account_name || trx.payment?.customer?.name || 'Not Available')
      },
      {
        label: 'Date Completed',
        value: trx.completed_at ? `${getDate(trx.completed_at)}, ${getTime(trx.completed_at)}` : 'Not Available'
      },
      {
        label: 'Transaction Type',
        value: paymentMethodsMap[trx.destination?.type as PayOutMappingTypes] || 'Not Available'
      },
      ...(trx.destination?.type === 'mobile_money'
        ? [
            {
              label: 'Mobile Number',
              value: `+${trx.destination?.details?.mobile_number}`
            },
            {
              label: 'Operator',
              value: trx.destination?.details?.mobile_name || 'Operator Information Unavailable'
            }
          ]
        : [
            {
              label: 'Account Number',
              value: trx.destination?.details?.account_number
            },
            {
              label: 'Bank',
              value: trx.destination?.details?.bank_name || 'No Bank Information Available'
            }
          ]),
      ...(trx.trace_id
        ? [
            {
              label: 'Trace ID',
              value: trx.trace_id
            }
          ]
        : []),
      {
        label: 'Payout Reference',
        value: trx.reference?.toUpperCase()
      },
      {
        label: 'Description',
        value: trx.narration || trx.description || 'No description provided'
      }
    ]
  };
};

export const viewPayoutReceiptButton = ({ onClick }: { onClick: () => void }) =>
  ({
    children: 'View Receipt',
    onClick: () => {
      onClick();
      logBreadCrumb({
        event: breadCrumbEvents.receipt.viewReceiptButton
      });
    },
    disabled: false,
    hidden: false,
    variant: 'grey'
  }) as const;

export const payoutRemittanceInformationSection = (trx: DataType) => [
  { label: 'Sender Name', value: trx?.remittance_data.sender_name ?? 'Not Available' },
  { label: 'Sender Phone Number', value: trx?.remittance_data.sender_phone_number ?? 'Not Available' },
  ...(trx?.remittance_data?.sender_nationality
    ? [
        {
          label: 'Sender Nationality',
          value: capitalize(trx?.remittance_data?.sender_nationality)
        }
      ]
    : []),
  ...(trx?.remittance_data?.sender_country_iso
    ? [
        {
          label: 'Sender Country ISO',
          value: trx?.remittance_data?.sender_country_iso
        }
      ]
    : []),
  ...(trx?.remittance_data?.sender_service_provider_name
    ? [
        {
          label: 'Sender Service Provider Name',
          value: trx?.remittance_data?.sender_service_provider_name
        }
      ]
    : []),
  ...(trx?.remittance_data?.destination_phone_number
    ? [
        {
          label: 'Remittance Destination Phone Number',
          value: trx?.remittance_data?.destination_phone_number
        }
      ]
    : []),
  ...(trx?.remittance_data?.sender_id_type
    ? [
        {
          label: 'Sendeer ID Type',
          value: trx?.remittance_data?.sender_id_type
        }
      ]
    : []),
  ...(trx?.remittance_data?.sender_id_number
    ? [
        {
          label: 'Sender ID Number',
          value: trx?.remittance_data?.sender_id_number
        }
      ]
    : []),
  ...(trx?.remittance_data?.source_of_funds
    ? [
        {
          label: 'Source of Funds',
          value: capitalizeFirst(trx?.remittance_data?.source_of_funds)
        }
      ]
    : []),
  ...(trx?.remittance_data?.remittance_purpose
    ? [
        {
          label: 'Remittance Purpose',
          value: capitalizeFirst(trx?.remittance_data?.remittance_purpose)
        }
      ]
    : []),
  ...(trx?.remittance_data?.sender_occupation
    ? [
        {
          label: 'Sender Occupation',
          value: capitalizeFirst(trx?.remittance_data?.sender_occupation)
        }
      ]
    : [])
];
