import { AxiosError } from 'axios';

import { useFeedbackHandler } from '+hooks';
import { SwapWalletConversionService } from '+services/swapwallet-conversion-services';
import { IConversionsQueryData } from '+types';

const useProcessConversion = (
  conversionsQueryData: IConversionsQueryData,
  onConversionSuccess?: (success: boolean) => void,
  onError?: (errorMsg: string) => void
) => {
  const { feedbackInit } = useFeedbackHandler();
  const { mutateAsync: processConversionMutation, isLoading } = SwapWalletConversionService.useProcessConversion({
    onSuccess: () => {
      onConversionSuccess?.(true);
    },
    reInvalidate: true,
    showErrorMessage: false,
    onError: (e: Error | AxiosError) => {
      const err = e as AxiosError<{ message: string; data: Record<string, { message: string }> }>;
      let errMsg = err.response?.data?.message;
      if (errMsg === 'invalid request data') {
        const errors = err.response?.data?.data;
        const keys = Object.keys(errors ?? {});
        if (keys.length) {
          errMsg = errors?.[keys[0]]?.message || errMsg;
        }
      }

      const finalErrorMessage = errMsg || 'Unable to process wallet funding. Please try again';
      if (onError) {
        onError(finalErrorMessage);
      } else {
        feedbackInit({
          message: finalErrorMessage,
          type: 'danger',
          componentLevel: true
        });
      }

      if (onConversionSuccess) {
        onConversionSuccess(false);
      }
    }
  });

  return { processConversionMutation: async () => await processConversionMutation(conversionsQueryData), isLoading };
};

export default useProcessConversion;
