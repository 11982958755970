import { useState } from 'react';
import { useParams } from 'react-router-dom';

import FileDownloadList from '+containers/Dashboard/Shared/FileDownloadList';
import IssuanceChargebackHistory from '+containers/Dashboard/TransactionDetails/components/IssuanceChargebackHistory';
import IssuanceChargebackModal from '+containers/Dashboard/TransactionDetails/components/IssuanceChargebackModal';
import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { ActionButtons } from '+containers/Dashboard/TransactionDetailsNew/types';
import { useSearchQuery } from '+hooks';
import { CardIssuanceServices } from '+services/card-issuance-services';
import useStore from '+store';
import { CurrencyType, IChargebackTransactionDetails, ISODateString } from '+types';
import { history } from '+utils';

import { chargebacksTrxnMessage } from '../data';
import { getIssuingPermissions } from '../utils';
import {
  generateCardholderFrom,
  generateDocumentsFrom,
  generateMoreDetailsFrom,
  generateSummaryFrom
} from './TransactionDetailsUtils/Chargeback';

const ChargebackTransactionDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { walletBalance } = useStore.getState();
  const { canManageChargeback } = getIssuingPermissions();
  const { value: searchQueryValue } = useSearchQuery<{ currency: CurrencyType }>();
  const currency = searchQueryValue?.currency ?? 'USD';
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const cachedIssuingBalance = walletBalance?.[currency]?.available_balance ?? 0;

  const {
    data: responseData,
    isLoading,
    refetch
  } = CardIssuanceServices.useFetchSingleIssuedCardChargeback({
    ref: id,
    errorMessage: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
    onError: () => {
      history.goBack();
    }
  });

  const data = responseData?.data;

  const summaryList = generateSummaryFrom(data as IChargebackTransactionDetails);
  const moreDetailsList = generateMoreDetailsFrom(data as IChargebackTransactionDetails);
  const cardholderInfoList = generateCardholderFrom(data as IChargebackTransactionDetails);
  const documentsList = generateDocumentsFrom((data as IChargebackTransactionDetails)?.status_history);
  const canBeReescalated = ['partially_accepted', 'declined', 'invalid'].includes(data?.status as string);
  const actionButtons: Array<ActionButtons> = [
    {
      children: (
        <>
          Re-escalate... <i className="os-icon os-icon-corner-up-right" />
        </>
      ),
      onClick: () => setModalIsOpen(true),
      disabled: !canBeReescalated,
      variant: 'secondary',
      hidden: ['accepted', 'accepted_pre_arbitration', 'declined_pre_arbitration'].includes(data?.status as string) || !canManageChargeback
    }
  ];

  return (
    <>
      <TransactionDetails isLoading={isLoading}>
        <TransactionDetails.Header
          heading={data?.amount as unknown as number}
          currency={data?.currency}
          status={chargebacksTrxnMessage[(data as IChargebackTransactionDetails)?.status]?.name}
          statusBg={chargebacksTrxnMessage[(data as IChargebackTransactionDetails)?.status]?.backgroundColor}
          statusColor={chargebacksTrxnMessage[(data as IChargebackTransactionDetails)?.status]?.color}
          actionButtons={actionButtons}
          summaries={summaryList}
        />

        <TransactionDetails.Section heading="More Transaction Details" summaries={moreDetailsList} />

        <TransactionDetails.Section heading="Cardholder's Information" summaries={cardholderInfoList} />

        <TransactionDetails.Section heading="Chargeback Timeline">
          <IssuanceChargebackHistory data={(data as IChargebackTransactionDetails)?.status_history} />
        </TransactionDetails.Section>

        <TransactionDetails.Section heading="Chargeback Documents">
          <FileDownloadList data={documentsList} />
        </TransactionDetails.Section>
      </TransactionDetails>

      <IssuanceChargebackModal
        modalIsVisible={modalIsOpen}
        transactionCurrency={data?.currency as string}
        transactionAmount={data?.amount as string}
        transactionDate={data?.transaction_date as ISODateString}
        issuingBalance={cachedIssuingBalance as number}
        actualResolutionDate={data?.actual_resolution_date as ISODateString}
        expectedResolutionDate={data?.expected_resolution_date as ISODateString}
        chargebackStatus={data?.status as IChargebackTransactionDetails['status']}
        chargebackCategory={data?.category as IChargebackTransactionDetails['category']}
        reference={data?.reference as string}
        refundedAmount={data?.accepted_amount as string}
        cardScheme={data?.card_brand as IChargebackTransactionDetails['card_brand']}
        refetchTransactionDetails={refetch}
        closeModal={() => setModalIsOpen(false)}
      />
    </>
  );
};

export default ChargebackTransactionDetails;
