import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import Table from '+containers/Dashboard/Shared/Table';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import { CardIssuanceServices } from '+services/card-issuance-services';
import useStore from '+store';
import { AllCardsResponseType, CommonSearchQueriesType } from '+types';

import AccessRequestPostOnboarding from '../components/AccessRequestPostOnboarding';
import AccountSuspensionNotification from '../components/AccountSuspensionNotification';
import Filter from '../components/Filter/Filter';
import Summary from '../components/Summary';
import { computeTableTitle, customerCardsTableProps, getCustomerCardsFilterProps } from '../constants';
import exportActionOptions from '../constants/exportActionOptions';
import { useFetchIssuanceTables, useFetchIssuingExport } from '../hooks';
import { getIssuingPermissions } from '../utils';
import CustomerCardsRow from './components/CustomerCardsRow';

const CustomerCardsIndex = () => {
  const { merchantEnv, cardAccess } = useStore(useShallow(store => ({ merchantEnv: store.merchantEnv, cardAccess: store.cardAccess })));
  const [recordIsFiltered, setRecordIsFiltered] = useState(false);
  const { value: searchQueryValue, setQuery } = useSearchQuery<
    {
      tab: 'customer_cards';
    } & CommonSearchQueriesType
  >();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const { canViewCardList, canRequestCardAccess } = getIssuingPermissions();
  const limit = searchQueryValue?.limit || '10';
  const page = searchQueryValue?.page || '1';
  const tab = searchQueryValue?.tab || 'customer_cards';
  const exportAction = useFetchIssuingExport({ resourceType: `All-${tab}`, exportFn: exportActionOptions[tab] });
  const filterProps = getCustomerCardsFilterProps();

  const {
    tableData,
    isFetching: isFetchingTableData,
    refetch: refetchTableData
  } = useFetchIssuanceTables({
    page,
    limit,
    tab,
    queryValue: searchQueryValue
  });

  const { data } = CardIssuanceServices.useFetchIssuedCardsStats({
    params: { cardCategory: 'customer' },
    refetchOnCloseFeedbackError: true
  });

  const customerCardStats = data?.data || {};

  const { mutateAsync: requestAccess, isLoading: isRequestingAccess } = CardIssuanceServices.useRequestCardAccess({
    cardType: 'virtual',
    errorMessage: 'We were unable to complete your request. Please try again',
    onSuccess: () => {
      closeFeedback(6500);
    }
  });

  const handleRequestAccess = async () => {
    if (canRequestCardAccess) {
      await requestAccess({
        reactivation: true,
        currency: 'USD',
        category: 'customer'
      });
    } else {
      feedbackInit({
        componentLevel: false,
        message: 'Sorry, you do not have the required permission(s) to perform this action',
        type: 'danger'
      });
      closeFeedback(6500);
    }
  };

  const paging = tableData?.data?.paging;

  return (
    <div>
      <div className="issuing-heading">
        <div className="flex-grow-1 info-summary-container">
          <Summary label="Total Cards Issued" value={customerCardStats?.total_cards} description="All cards issued to customers" />
          <Summary
            label="Active Cards"
            value={customerCardStats?.total_active_cards}
            description="Cards being used to perform transactions"
            cards
          />
        </div>
      </div>

      {cardAccess.customer.status === 'suspended' && (
        <AccountSuspensionNotification
          isRequestingAccess={isRequestingAccess}
          onRequestAccess={handleRequestAccess}
          cardCategory="customer"
        />
      )}

      <Filter
        totalItems={paging?.total_items as number}
        title={computeTableTitle({
          singularTitle: 'card',
          pluralTitle: 'cards',
          filtered: recordIsFiltered,
          activeTab: tab as string,
          pageItemCount: paging?.total_items as number
        })}
        actions={filterProps.actions}
        filterModalHeading={filterProps.filterModalHeading}
        filterModalDescription={filterProps.filterModalDescription}
        filterFields={filterProps.filterFields}
        exportType={filterProps.exportType}
        quickFilterType={tab}
        exportHeading={filterProps.exportHeading}
        exportDescription={filterProps.exportHeading}
        onChangeIsFiltered={setRecordIsFiltered}
        isFiltered={recordIsFiltered}
        exportAction={exportAction}
      />

      <section className="transaction_table_comp table-container">
        <Table
          tableClassName={`--history-table ${customerCardsTableProps.tableClassName}`}
          headings={customerCardsTableProps.headings}
          hasPagination
          borderedTable
          loading={isFetchingTableData}
          current={paging?.current}
          limitAction={value => setQuery({ limit: String(value) })}
          pageSize={paging?.page_size}
          actionFn={value => setQuery({ page: String(value) })}
          totalItems={paging?.total_items || 0}
          emptyStateHeading={customerCardsTableProps.emptyStateHeading}
          emptyStateMessage={
            <>
              <span>{customerCardsTableProps.emptyStateMessage}</span>
              {canViewCardList ? (
                <button type="button" className="refetch-button" onClick={() => refetchTableData()}>
                  <i className="os-icon os-icon-rotate-ccw" />
                  Refresh
                </button>
              ) : null}
            </>
          }
        >
          <CustomerCardsRow rowData={tableData?.data?.data as Array<AllCardsResponseType>} />
        </Table>
      </section>
    </div>
  );
};

const CustomerCards = () => {
  const { cardAccess } = useStore(useShallow(store => ({ cardAccess: store.cardAccess })));

  if (!['active', 'suspended'].includes(cardAccess.customer.status)) {
    return <AccessRequestPostOnboarding activeTab="customer_cards" accessStatus={cardAccess.customer.status} />;
  }
  return <CustomerCardsIndex />;
};
export default CustomerCards;
