import Icon from '+containers/Dashboard/Shared/Icons';
import { IReferralsInfoCards } from '+types';

import { ReferralsInfoCards } from './ReferralsInfoCards';

const ReferralsInfo = ({ setStage }: { setStage: (value: 'info' | 'link') => void }) => {
  const onSave = () => {
    setStage('link');
  };

  const generateLink: Array<IReferralsInfoCards> = [
    {
      title: 'Generate link',
      description: 'Generate your exclusive link and send to your friends telling them about Kora.',
      icon: 'linkHook'
    },
    {
      title: 'Registration',
      description: 'Have your friends sign up and begin making transactions on Kora.',
      icon: 'userProfile'
    },
    {
      title: 'Earn Cool Bonuses!',
      description: 'Start earning! Share your link with everyone who needs a reliable payment partner.',
      icon: 'briefcase'
    }
  ];
  return (
    <section className="referral-info">
      <div className="referral-info-header">
        <span>
          <Icon name="thumbsUp" width={20} height={20} fill={'#2376F3'} />
        </span>
        <h6>Refer and earn!</h6>
        <p>Follow the steps below to start earning awesome bonuses with Kora.</p>
      </div>
      <div className="referral-info-desc">
        {generateLink.map((item, index) => (
          <ReferralsInfoCards key={index} title={item.title} description={item.description} icon={item.icon} />
        ))}
      </div>

      <div className="referral-info-footer">
        <button className="referral-btn small" type="button" onClick={onSave}>
          Generate Link
        </button>
      </div>
    </section>
  );
};

export default ReferralsInfo;
