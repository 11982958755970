/* eslint-disable no-unused-vars */
import { QueryKey } from '@tanstack/react-query';

import { useFetch, useSend } from '+hooks';
import { IReferralsData, IRVCMerchantDetailsResponse, ISendResponse } from '+types';

export const MerchantServices = {
  // use get 2fa history here
  useFetchMerchantDetails: ({
    onSuccess,
    bannerLevel,
    enabled,
    showErrorMessage
  }: {
    onSuccess?: (data: ISendResponse<IRVCMerchantDetailsResponse>) => void;
    bannerLevel?: boolean;
    enabled?: boolean;
    showErrorMessage?: boolean;
  }) =>
    useFetch('merchants/business-details', {
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      enabled,
      showErrorMessage
    }),

  // use set Notification Urls  here
  useSetNotificationUrls: ({
    onSuccess,
    bannerLevel,
    hasFeedbackTimeout
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    hasFeedbackTimeout?: boolean;
  }) =>
    useSend({
      url: '/merchants/profile/notification-urls',
      method: 'post',
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      hasFeedbackTimeout,
      reInvalidate: true
    }),

  // use get Notification Urls here
  useGetNotificationUrls: ({
    onSuccess,
    bannerLevel,
    refetchOnCloseFeedbackError,
    errorMessage,
    refetchOnWindowFocus,
    refetchOnReconnect,
    staleTime
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
    refetchOnWindowFocus?: boolean;
    refetchOnReconnect?: boolean;
    staleTime?: number;
  }) =>
    useFetch('/merchants/profile/notification-urls', {
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      errorMessage,
      refetchOnWindowFocus,
      refetchOnReconnect,
      staleTime
    }),

  // use fetch Merchant Virtual Account here
  useFetchMerchantVirtualAccount: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    errorMessage,
    enabled,
    showErrorMessage
  }: {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
    enabled?: boolean;
    showErrorMessage?: boolean;
  }) =>
    useFetch('/merchants/profile/bank-account', {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      errorMessage,
      enabled,
      showErrorMessage
    }),

  // use set Notification Urls  here
  useUpdateMerchantDetails: ({ onSuccess, bannerLevel }: { onSuccess?: () => void; bannerLevel?: boolean }) =>
    useSend({
      url: '/merchants/business-profile',
      method: 'put',
      onSuccess,
      reInvalidate: true,
      errorMessageBannerLevel: bannerLevel
    }),

  useGetMerchantPaymentPreferenceRequest: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    errorMessage
  }: {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
  }) =>
    useFetch('/settings/bank-transfer-settings-requests', {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      errorMessage,
      useEnvironmentHandler: 'live'
    }),

  // use set Notification Urls  here
  useSetMerchantPaymentPreference: ({
    onSuccess,
    bannerLevel,
    errorMessage,
    successMessage
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    successMessage?: string;
  }) =>
    useSend({
      url: '/settings/bank-transfer-settings-requests',
      method: 'post',
      onSuccess,
      errorMessage,
      successMessage,
      errorMessageBannerLevel: bannerLevel,
      reInvalidate: true,
      useEnvironmentHandler: 'live'
    }),

  useGetMerchantPaymentPreference: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    errorMessage
  }: {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
  }) =>
    useFetch('/settings/bank-transfers', {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      errorMessage,
      useEnvironmentHandler: 'live'
    }),

  useSetMerchantAppearanceSettings: ({
    onSuccess,
    bannerLevel,
    errorMessage,
    successMessage,
    showErrorMessage,
    onError
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    successMessage?: string;
    showErrorMessage?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/settings/checkout/customization',
      method: 'put',
      onSuccess,
      onError,
      errorMessage,
      successMessage,
      showErrorMessage,
      errorMessageBannerLevel: bannerLevel,
      reInvalidate: true,
      useEnvironmentHandler: 'live'
    }),

  useGetMerchantAppearanceSettings: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    errorMessage
  }: {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
  }) =>
    useFetch('/settings/checkout/customization', {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      errorMessage,
      useEnvironmentHandler: 'live'
    }),

  useGetMerchantStatus: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    errorMessage
  }: {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
  }) =>
    useFetch('/merchants/verifications/status', {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      errorMessage
    }),

  useGenerateLink: ({
    onSuccess,
    bannerLevel,
    errorMessage,
    successMessage,
    showErrorMessage,
    onError
  }: {
    onSuccess?: (data: { data: IReferralsData }) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    successMessage?: string;
    showErrorMessage?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/referral/generate-link',
      method: 'post',
      onSuccess,
      onError,
      errorMessage,
      successMessage,
      showErrorMessage,
      errorMessageBannerLevel: bannerLevel
    }),

  useGetReferralStatus: ({
    onSuccess,
    onError,
    bannerLevel,
    queryKey,
    params,
    errorMessage
  }: {
    onSuccess?: (data: IReferralsData) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    queryKey?: QueryKey;
    params?: { email: string };
  }) =>
    useFetch('/referral/link', {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      queryKey,
      params,
      showErrorMessage: false,
      showSuccessMessage: false,
      retry: false
    })
};
