import Copyable from '+containers/Dashboard/Shared/Copyable';
import { SummaryItemType } from '+containers/Dashboard/TransactionDetailsNew/types';
import { IConversionData } from '+types/conversions';
import { capitalize, formatAmount, getDate, getTime, switchChannel, switchStatus } from '+utils';

export function generateSummaryFrom(data: IConversionData['data']): Array<SummaryItemType> {
  return [
    {
      label: 'Amount Converted',
      value: `${formatAmount(data?.source_amount)} ${data?.source_currency}`
    },
    {
      label: 'Converted To',
      value: `${formatAmount(data?.converted_amount)} ${data?.destination_currency}`
    },
    {
      label: 'Date / Time',
      value: data?.transaction_date ? `${getDate(data?.transaction_date)}, ${getTime(data?.transaction_date)}` : 'Not Available'
    },
    {
      label: 'Transaction ID',
      value: data?.reference ? <Copyable text={data?.reference} /> : 'Not available'
    }
  ];
}

export function generateMoreDetailFrom({ data }: IConversionData): SummaryItemType[] {
  const getCurrencyLabel = (currency: string): string => {
    switch (currency) {
      case 'NGN':
        return 'Naira (NGN)';
      case 'USD':
        return 'United States Dollar (USD)';
      default:
        return currency;
    }
  };
  return [
    {
      label: 'Status',
      value: (
        <>
          <span
            className={`status-pill smaller ${data?.status === 'requires_auth' ? 'yellow' : switchStatus(data?.status)}`}
            style={{ margin: '0 7px 5px 0' }}
          />
          {capitalize(data?.status === 'requires_auth' ? 'Pending (Requires Authorization)' : data?.status || 'Not Available')}
        </>
      )
    },
    {
      label: 'Fee Bearer',
      value: 'Customer'
    },
    {
      label: 'Exchange Rate',
      value: `1 USD → ${data?.exchange_rate} ${data?.destination_currency !== 'USD' ? data?.destination_currency : data?.source_currency}`
    },
    {
      label: 'Converted From',
      value: `${getCurrencyLabel(data?.source_currency || '')}`
    },
    {
      label: 'Converted To',
      value: `${getCurrencyLabel(data?.destination_currency || '')}`
    },
    {
      label: 'Channel',
      value: switchChannel(data?.channel)
    },
    {
      label: 'Internal Refrence',
      value: data?.reference ? <Copyable text={data?.reference} /> : 'Not available'
    },
    {
      label: 'Customer name',
      value: data?.customer_name
    }
  ];
}
