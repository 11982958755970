import React from 'react';

import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import useStore from '+store';
import { DefaultBusinessType, DocumentType } from '+types';
import { capitalizeFirst, maskBetweenSetRange } from '+utils';

import UserPNG from '+assets/img/dashboard/user.png';
import UserSvg from '+assets/img/dashboard/user.svg';

const DocumentItem: React.FC<{
  document: DocumentType;
}> = ({ document }) => (
  <a
    href={document.documents?.[0]?.file?.url}
    className="div-table --account-table reps-row --row mb-2"
    key={document?.name}
    download
    target="_blank"
    rel="noopener noreferrer"
  >
    <div>
      <img src={UserSvg} srcSet={UserPNG} alt="document" loading="lazy" />
      <span style={{ textTransform: 'capitalize' }}>{document?.name}</span>
      <span>{document?.role?.label}</span>
    </div>
    <div>
      <span>{maskBetweenSetRange(capitalizeFirst(document?.documents?.[0]?.file?.description || ''), 4, 9)}</span>
    </div>
  </a>
);

const BusinessRepsTable = () => {
  const defaultBusiness = useStore(state => state.merchantKYC);
  const representatives = (defaultBusiness as DefaultBusinessType)?.details?.representatives;
  return (
    <div className="mt-4">
      {representatives?.length ? (
        representatives?.map(document => <DocumentItem document={document} />)
      ) : (
        <EmptyStateComponent heading="No documents uploaded" hideMessage />
      )}
    </div>
  );
};

const BusinessRepresentative = () => {
  return (
    <div className="business-rep__main">
      <h5 className="compliance-heading">Business Representatives</h5>
      <span className="description">Added representatives for your business/organization.</span>
      <BusinessRepsTable />
    </div>
  );
};

export default BusinessRepresentative;
