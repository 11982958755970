/* eslint-disable no-unused-vars */
import { useFetch, useSend } from '+hooks';
import { ISendResponse } from '+types';
import { filterOutEmptyValues } from '+utils';

export const CheckoutServices = {
  // use get balance here
  useGetAllPaymentLinks: ({
    onSuccess,
    bannerLevel,
    enabled,
    params,
    errorMessage,
    refetchOnCloseFeedbackError
  }: {
    onSuccess?: () => void;
    errorMessage?: string;
    bannerLevel?: boolean;
    enabled?: boolean;
    params: { page: string; limit: string; query: any; currency: string };
    refetchOnCloseFeedbackError?: boolean;
  }) => {
    const { page, limit, query, currency } = params;
    return useFetch('/payment-requests', {
      onSuccess,
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      enabled,
      params: {
        page,
        limit,
        ...filterOutEmptyValues({
          'search[status][]': query,
          currency
        })
      },
      refetchOnCloseFeedbackError
    });
  },

  // use get Single Settlement Transactions here
  useGetSinglePaymentLink: ({
    enabled,
    bannerLevel,
    slug,
    errorMessage,
    onError,
    showErrorMessage,
    refetchOnCloseFeedbackError
  }: {
    enabled?: boolean;
    bannerLevel?: boolean;
    slug: string;
    errorMessage?: string;
    onError?: (e: Error) => void;
    showErrorMessage?: boolean;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/payment-requests/${slug}/get`, {
      enabled,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      onError,
      showErrorMessage,
      refetchOnCloseFeedbackError
    }),

  // use get Link Transactions here
  useGetLinkTransactions: ({
    enabled,
    bannerLevel,
    id,
    errorMessage,
    onError,
    showErrorMessage,
    params
  }: {
    enabled?: boolean;
    bannerLevel?: boolean;
    id: string;
    errorMessage?: string;
    onError?: (e: Error) => void;
    showErrorMessage?: boolean;
    params?: { page: string; limit: string };
  }) =>
    useFetch(`/payment-requests/${id}/transactions`, {
      enabled,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      onError,
      showErrorMessage,
      params
    }),

  useSetNotificationUrls: ({
    onSuccess,
    bannerLevel,
    showErrorMessage,
    onError
  }: {
    onSuccess?: (res: ISendResponse<unknown>) => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    onError?: (e: Error) => void;
  }) =>
    useSend({
      url: '/payment-requests',
      method: 'post',
      onSuccess,
      showErrorMessage,
      errorMessageBannerLevel: bannerLevel,
      onError
    }),

  useCheckLink: ({
    onSuccess,
    bannerLevel,
    showErrorMessage,
    showSuccessMessage,
    onError
  }: {
    onSuccess?: (res: ISendResponse<unknown>) => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    showSuccessMessage?: boolean;
    onError?: (e: Error) => void;
  }) =>
    useSend({
      url: '/payment-requests/resolve-link-slug',
      method: 'post',
      onSuccess,
      showErrorMessage,
      showSuccessMessage,
      errorMessageBannerLevel: bannerLevel,
      onError
    }),

  useDeletePaymentLink: ({
    onSuccess,
    bannerLevel,
    showErrorMessage,
    onError,
    ref,
    errorMessage
  }: {
    onSuccess?: (res: ISendResponse<unknown>) => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    onError?: (e: Error) => void;
    enabled?: boolean;
    ref: any;
    errorMessage?: string;
  }) =>
    useSend({
      url: `/payment-requests/${ref}`,
      method: 'delete',
      onSuccess,
      showErrorMessage,
      errorMessageBannerLevel: bannerLevel,
      onError,
      errorMessage
    }),

  useUpdatePaymentLink: ({
    onSuccess,
    bannerLevel,
    showErrorMessage,
    onError,
    ref,
    errorMessage
  }: {
    onSuccess?: (res: ISendResponse<unknown>) => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    onError?: (e: Error) => void;
    enabled?: boolean;
    ref: any;
    errorMessage?: string;
  }) =>
    useSend({
      url: `/payment-requests/${ref}`,
      method: 'put',
      onSuccess,
      showErrorMessage,
      errorMessageBannerLevel: bannerLevel,
      onError,
      errorMessage
    })
};
