import ReceiptModal from '+containers/Dashboard/Shared/ReceiptTemplate';
import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { DataType } from '+types';
import { formatAmount } from '+utils';

import {
  payOutHeaderSummary,
  payOutMoreTrxDetailsSection,
  payoutReceiptFn,
  payoutRecipientInformationSection,
  payoutRemittanceInformationSection,
  viewPayoutReceiptButton
} from './payOutDetailsHelpers';
import usePayOutDetails from './usePayOutDetails';

const PayOutDetails = () => {
  const { trxnStatusObj, isLoading, data, modalType, setModalType } = usePayOutDetails();
  const isRemittanceTransaction = (data as DataType)?.remittance_data;

  return (
    <>
      <TransactionDetails loaderCount={4} isLoading={isLoading || !data}>
        <TransactionDetails.Header
          heading={formatAmount(data?.amount_charged)}
          currency={data?.currency}
          status={trxnStatusObj.name}
          statusBg={trxnStatusObj.backgroundColor}
          statusColor={trxnStatusObj.color}
          actionButtons={[...(data?.status === 'success' ? [viewPayoutReceiptButton({ onClick: () => setModalType('receipt') })] : [])]}
          summaries={payOutHeaderSummary(data as unknown as DataType)}
        />
        <TransactionDetails.Section
          heading="More Transaction Details"
          dataTestId="payout-more-transaction-details"
          summaries={payOutMoreTrxDetailsSection(data as unknown as DataType)}
        />
        {isRemittanceTransaction && (
          <TransactionDetails.Section
            heading="Remittance Information"
            dataTestId="remittance-information-section"
            summaries={payoutRemittanceInformationSection(data as unknown as DataType)}
          />
        )}
        <TransactionDetails.Section
          heading="Recipient Information"
          dataTestId="recipient-information-section"
          summaries={payoutRecipientInformationSection(data as unknown as DataType)}
        />
        <TransactionDetails.Webhook
          canRequestWebhook={data?.can_request_webhook}
          trxRef={data?.unique_reference}
          trxType="payout"
          notificationRef={data?.reference}
        />
      </TransactionDetails>
      {modalType === 'receipt' && <ReceiptModal data={payoutReceiptFn(data as unknown as DataType)} close={() => setModalType('')} />}
    </>
  );
};

export default PayOutDetails;
