import { issuedCardChargebacksFilterField, singleCardTxFilterFields } from '+containers/Dashboard/Issuing/data';
import { getExistingFiltersFromQuery } from '+dashboard/Shared/FilterModal';
import { CardIssuanceServices } from '+services/card-issuance-services';
import { IssuedCardTabKeyType } from '+types';

type UseFetchIssuedCardTableDataProps = {
  tab: IssuedCardTabKeyType;
  limit: string;
  page: string;
  sortingParams: Record<string, string>;
  cardId: string;
  merchantEnv: string;
};

export default function useFetchIssuedCardTableData({ tab, limit, page, cardId }: UseFetchIssuedCardTableDataProps) {
  const servicesMapper = {
    balance_history: {
      service: CardIssuanceServices.useFetchIssuedCardTransactions,
      params: {
        limit,
        page,
        ref: cardId,
        ...getExistingFiltersFromQuery(singleCardTxFilterFields)
      }
    },
    transactions: {
      service: CardIssuanceServices.useFetchIssuedCardTransactions,
      params: {
        limit,
        page,
        ref: cardId,
        ...getExistingFiltersFromQuery(singleCardTxFilterFields)
      }
    },
    events: {
      service: CardIssuanceServices.useFetchCardEvents,
      params: {
        limit,
        page,
        ref: cardId
      }
    },
    chargebacks: {
      service: CardIssuanceServices.useFetchAllChargebacksForIssuedCard,
      params: {
        page,
        limit,
        cardId,
        ...getExistingFiltersFromQuery(issuedCardChargebacksFilterField)
      }
    }
  };

  const {
    data: tableData,
    isFetching,
    refetch
  } = servicesMapper[tab].service({
    params: servicesMapper[tab].params
  });

  return {
    tableData,
    isFetching,
    refetch
  };
}
