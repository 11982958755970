/* eslint-disable no-unused-vars */
import { QueryKey } from '@tanstack/react-query';

import { useFetch, useSend } from '+hooks';
import { IKYCMetaData, IResponse, ISendResponse, SignInResponseType } from '+types';

export const KYCServices = {
  // add for unsupported countries
  useUnsupportedCountries: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/kyc/unsupported-countries',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  // add for get kyc metadata
  useGetKYCMetaData: <T>({
    onSuccess,
    bannerLevel,
    showReturnDefaultResponse,
    metaData
  }: {
    onSuccess?: (value: IResponse<IKYCMetaData>) => void;
    bannerLevel?: boolean;
    showReturnDefaultResponse?: boolean;
    metaData: T;
  }) =>
    useFetch<IKYCMetaData, {}>(`/kyc/meta-data?keys[]=${metaData}`, {
      onSuccess,
      showReturnDefaultResponse,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  // add for use business doc here
  useBusinessDoc: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/kyc/documents',
      method: 'put',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  // add for use business rep here
  useBusinessRep: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (value: ISendResponse<SignInResponseType>) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/kyc/representatives',
      method: 'put',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  // add for use business rep here
  useResetKyc: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (value: ISendResponse<SignInResponseType>) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/kyc/reset',
      method: 'patch',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  // add the business type here
  useBusinessType: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (value: ISendResponse<SignInResponseType>) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/kyc/business-type',
      method: 'put',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  // add for get kyc metadata
  useFetchMerchantKYC: ({
    onSuccess,
    bannerLevel,
    errorMessage,
    queryKey
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    queryKey?: QueryKey;
  }) =>
    useFetch(`/merchants/info`, {
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      queryKey,
      useEnvironmentHandler: 'live'
    }),

  // add for business profile here
  useBusinessProfile: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/kyc/business-profile',
      method: 'put',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  // add for settlement account here
  useSettlementAccount: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/kyc/settlement-accounts',
      method: 'put',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  // add for skip compliance category account here
  useSkipComplianceCategory: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/kyc/skip-category',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  // add for skip compliance category account here
  useDetailsVerification: ({
    onSuccess,
    onError,
    errorMessage,
    bannerLevel
  }: {
    onSuccess?: (res: any) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/kyc/id/verify',
      method: 'post',
      onSuccess,
      onError,
      errorMessage,
      hasFeedbackTimeout: true,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  // add for bvn submission here
  useBvnSubmission: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    reInvalidate,
    hasFeedbackTimeout
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    reInvalidate?: boolean;
    hasFeedbackTimeout?: boolean;
    errorMessage?: string;
  }) =>
    useSend({
      url: '/kyc/bvn',
      method: 'put',
      onSuccess,
      onError,
      errorMessage,
      reInvalidate,
      hasFeedbackTimeout,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  // use get virtual account kyc details here
  useGetVirtualAccountKycDetails: ({
    onSuccess,
    bannerLevel,
    reference
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    reference?: string;
  }) =>
    useFetch(`/virtual-bank-account/account-holders/${reference}/kyc-details`, {
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live'
    }),

  // use getMerchantTieringData here
  useGetMerchantTieringData: ({
    onSuccess,
    onError,
    bannerLevel,
    showErrorMessage
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    showErrorMessage?: boolean;
  }) =>
    useFetch(`/merchants/product-limits`, {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      showErrorMessage,
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE,
      useEnvironmentHandler: 'live'
    })
};
