import { useEffect, useReducer, useRef } from 'react';

import useFeedbackHandler from '+hooks/feedbackHandler';
import { uploadFiles } from '+hooks/utils';
import { MerchantServices } from '+services/merchant-services';
import Modal from '+shared/Modal';
import useStore from '+store';
import { getDate, getTime, getTimeFromSeconds } from '+utils';

import CustomColourPicker from './components/Colorpicker';

import ArrowDownIcon from '+assets/img/arrow-down.svg';
import CancelIcon from '+assets/img/dashboard/cancel-icon-black.svg';
import InfoIcon from '+assets/img/dashboard/exclamation.svg';
import FileIcon from '+assets/img/dashboard/fileicon.svg';
import InfoIconGrey from '+assets/img/dashboard/information-button.svg';
import UploadIcon from '+assets/img/dashboard/upload-icon.svg';

import './index.scss';

const Appearance = () => {
  const maxUploadSize = process.env.REACT_APP_MAX_LOGO_SIZE || 3;
  const utilityUrl = process.env.REACT_APP_UTILITY_API_BASE;
  const { feedbackInit } = useFeedbackHandler();
  const defaultMerchant = useStore((store: { defaultMerchant: any }) => store.defaultMerchant);
  const hiddenFileInput = useRef(null);
  const modalRef = useRef(null);
  const buttonTextRef = useRef(null);
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      showbuttoncolourCustomPicker: false,
      showbuttonTextcolourCustomPicker: false,
      uploadImage: false,
      imageUrl: '',
      imageName: '',
      showModal: false,
      buttonColor: '',
      buttonTextColor: ''
    }
  );

  const { data: appearanceData, refetch: refetchAppearanceSettings } = MerchantServices.useGetMerchantAppearanceSettings({
    errorMessage: 'Error fetching checkout customization settings',
    onSuccess: data => {
      updateState({
        buttonColor: data?.settings?.next_state?.primary_color || data?.settings?.current_state?.primary_color || '#48CEB0',
        buttonTextColor: data?.settings?.next_state?.secondary_color || data?.settings?.current_state?.secondary_color || '#FFFFFF',
        imageUrl: data?.settings?.next_state?.logo || data?.settings?.current_state?.logo || ''
      });
    }
  });

  const updateAppearanceSettings = MerchantServices.useSetMerchantAppearanceSettings({
    errorMessage: 'Error updating checkout customization settings',
    showErrorMessage: true,
    onError: error => {
      throw error;
    },
    onSuccess: () => {
      updateState({ showModal: false });
    }
  });

  const handleSave = async () => {
    const data = {
      logo: state.imageUrl,
      primary_color: state.buttonColor,
      secondary_color: state.buttonTextColor
    };
    try {
      await updateAppearanceSettings.mutateAsync(data);
      refetchAppearanceSettings();
    } catch (err) {
      feedbackInit({
        type: 'danger',
        message: err?.response?.data?.message || 'Error updating checkout customization settings'
      });
      updateState({ showModal: false });
      throw err;
    }
  };

  const handleCancel = () => {
    updateState({
      buttonColor: appearanceData?.settings?.current_state?.primary_color,
      buttonTextColor: appearanceData?.settings?.current_state?.secondary_color
    });
  };

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleOutsideClick = e => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      updateState({ showbuttoncolourCustomPicker: false });
    }
  };

  const handleOutsideClick2 = e => {
    if (buttonTextRef.current && !buttonTextRef.current.contains(e.target)) {
      updateState({ showbuttonTextcolourCustomPicker: false });
    }
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    const fileName = e.target.files[0]?.name;
    const fileSize = e.target.files[0]?.size;
    const data = new FormData();
    data.append(`files`, file);
    const formatFileSize = Math.floor(fileSize / 1024 ** 2);
    if (formatFileSize > Number(maxUploadSize)) {
      feedbackInit({
        message: `Please upload a file less than 3MB in size`,
        type: 'danger'
      });
      return;
    }
    return uploadFiles(data)
      .then(res => {
        updateState({
          imageUrl: `${utilityUrl}/files/${res?.data[0]?.path}`,
          uploadImage: true,
          imageName: fileName
        });
      })
      .catch(err => {
        feedbackInit({
          message: `There's been an error uploading your logo. Please try again`,
          type: 'danger'
        });
      });
  };

  const handleDisableSavedButton = () => {
    if (appearanceData?.settings?.current_state?.logo === '') return true;
    if (!state.imageUrl) return true;
    if (
      appearanceData?.settings?.next_state?.primary_color !== state.buttonColor ||
      appearanceData?.settings?.next_state?.secondary_color !== state.buttonTextColor ||
      appearanceData?.settings?.next_state?.logo !== state.imageUrl
    )
      return false;

    return true;
  };

  useEffect(() => {
    if (modalRef.current) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    if (buttonTextRef.current) {
      document.addEventListener('mousedown', handleOutsideClick2);
    }
    return () => {
      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('mousedown', handleOutsideClick2);
    };
  }, [modalRef.current, buttonTextRef.current]);

  const updateContent = () => {
    return (
      <>
        <p className="modal-text">Please confirm that you want the changes made to your preferences to take effect.</p>
        <p className="modal-text__bold">
          <img style={{ margin: '0px 5px 0px 0px' }} src={InfoIcon} className="modal-text__image" alt="info icon" />
          <span>
            Note that your changes will take effect after {`${getTimeFromSeconds(appearanceData?.display_delay_in_seconds) || '3 hours'}`}
          </span>
        </p>
      </>
    );
  };

  return (
    <div>
      <div className="element-wrapper appearance-settings">
        <div>
          <h5 className="form-header">Checkout Appearance</h5>
          <div className="form-desc">Update and manage your payment preferences here.</div>
          <p className="appearance-warning" style={{ background: state.uploadImage ? '#48CEB0' : '' }}>
            Any change made would be{' '}
            <strong>reflected in {`${getTimeFromSeconds(appearanceData?.display_delay_in_seconds) || '6 months'}`}</strong>, after which you
            can only make an appearance change in{' '}
            <strong>{`${getTimeFromSeconds(appearanceData?.update_delay_in_seconds) || '3 hours'}`}</strong>
            {appearanceData?.available_on && (
              <p className="modal-text__bold">
                <img style={{ margin: '0px 5px 0px 0px' }} src={InfoIcon} className="modal-text__image" alt="info icon" />
                <span>
                  Note that your changes will be effected by{' '}
                  {`${getDate(appearanceData?.available_on)} ${getTime(appearanceData?.available_on)}`}
                </span>
              </p>
            )}
          </p>

          <h5 className="appearance-subheader ">Upload business logo</h5>
          <div>
            <div className="appearance-logo-upload">
              <p className="company-logo-section">
                <img width={18} src={FileIcon} alt="" />
                <span> Company logo</span>
                <img width={15} src={InfoIconGrey} alt="" />
              </p>
              <p className="custom-file-upload">
                <span>{state.uploadImage ? state.imageName : 'Upload'}</span>
                {state.uploadImage ? (
                  <button
                    type="button"
                    onClick={() =>
                      updateState({ imageUrl: appearanceData?.settings?.current_state?.logo, imageName: '', uploadImage: false })
                    }
                  >
                    <img src={CancelIcon} alt="cancel Icon" />
                  </button>
                ) : (
                  <button type="button" onClick={handleClick}>
                    <img src={UploadIcon} alt="upload icon" />
                  </button>
                )}
                <input ref={hiddenFileInput} type="file" accept=".jpg, .png, .jpeg" onChange={e => handleFileChange(e)} alt="" />
              </p>
            </div>
            <div className="appearance-logo-info">
              <p>Max size: {maxUploadSize}MB</p>
              {!appearanceData?.settings?.current_state && <p>Required</p>}
            </div>
          </div>
          <h5 className="appearance-subheader --more-space">Choose your appearance colors</h5>
          <div className="appearance-subdiv">
            <div>
              <h5 className="appearance-subheader">Primary color</h5>
              <p style={{ fontSize: '14px' }}>This would change the button color</p>
            </div>
            <div onClick={() => updateState({ showbuttoncolourCustomPicker: true })} className="colour-picker-dropdown">
              <span style={{ background: state.buttonColor || '#48CEB0' }} className="default-colour" />
              <img src={ArrowDownIcon} width={18} alt="" />
              {state.showbuttoncolourCustomPicker && (
                <div ref={modalRef} className="colour-picker-div">
                  <CustomColourPicker initialColor={state.buttonColor} onColorChange={color => updateState({ buttonColor: color })} />
                </div>
              )}
            </div>
          </div>

          <div className="appearance-subdiv2">
            <div>
              <h5 className="appearance-subheader">Secondary color</h5>
              <p style={{ fontSize: '14px' }}>This would change the button text color</p>
            </div>
            <div onClick={() => updateState({ showbuttonTextcolourCustomPicker: true })} className="colour-picker-dropdown">
              <span style={{ background: state.buttonTextColor || '#FFFFFF' }} className="default-colour" />
              <img src={ArrowDownIcon} width={18} alt="" />
              {state.showbuttonTextcolourCustomPicker && (
                <div ref={buttonTextRef} className="colour-picker-div">
                  <CustomColourPicker
                    initialColor={state.buttonTextColor}
                    onColorChange={color => updateState({ buttonTextColor: color })}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="grey-section__subsection-group" style={{ paddingLeft: '0px' }}>
            <button
              className="btn btn-primary btn--save"
              type="button"
              disabled={handleDisableSavedButton()}
              onClick={() => updateState({ showModal: true })}
            >
              Save
            </button>

            <button className="btn btn--link btn--cancel" type="button" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
        <div className="checkout-content-w appearance-preview">
          <div className="preview-box">
            <p className="test-mode-indicator">THIS IS A PREVIEW</p>
            <div className="modal-mockup">
              {state.imageUrl ? (
                <div className="merchant-logo-appearance">
                  <img width="100%" height="100%" src={state.imageUrl} alt="logo" />
                </div>
              ) : (
                <div className="logo-holder">
                  <span>Your logo would be displayed here</span>
                </div>
              )}
              <div className="checkout-header">
                <h1 className="checkout-name">Payment link name</h1>
                <p className="merchant-name">To {defaultMerchant?.name}</p>
                <p className="description">Payment link description</p>
              </div>

              <div className="kpy-col-paymentLink-container">
                <div className="amount-holder">Pay N1,500.00</div>
                <div className="checkout-form-container">
                  <div className="kpy-col-input-field">
                    <label htmlFor="customer-name" className="kpy-col-label">
                      Full Name
                    </label>
                    <input className="kpy-col-input pay-link" id="customer-name" name="customer-name" placeholder="John Doe" readOnly />
                  </div>

                  <div className="kpy-col-input-field">
                    <label htmlFor="customer-email" className="kpy-col-label">
                      Email
                    </label>
                    <input
                      className="kpy-col-input pay-link"
                      id="customer-email"
                      name="customer-email"
                      placeholder="email@example.com"
                      readOnly
                    />
                  </div>
                  <button style={{ background: state.buttonColor, color: state.buttonTextColor }} className="btn-checkout" type="button">
                    Proceed
                  </button>
                </div>
              </div>
              <div className="secured__banner">
                <img src="https://korablobstorage.blob.core.windows.net/modal-bucket/Safety.svg" alt="" />
                <p style={{ margin: '0px' }}>
                  Secured by <span>Kora</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        heading="Confirm Changes?"
        visible={state.showModal}
        content={updateContent()}
        close={() => updateState({ showModal: false })}
        secondButtonText="Yes, Confirm"
        size="md"
        secondButtonAction={handleSave}
        secondButtonActionIsTerminal={false}
      />
    </div>
  );
};

export default Appearance;
