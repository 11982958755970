import { useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Modal from '+dashboard/Shared/Modal';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import { SwapWalletConversionService } from '+services/swapwallet-conversion-services';
import useStore from '+store';
import { APIDownload, filteredOutObjectProperty, getDate, queriesParams } from '+utils';

import AdvanceExportModal from '../Shared/AdvanceExportModal';
import ExportFilterModal from '../Shared/ExportFilterModal';
import Table from '../Shared/Table';
import { conversionTableData, getConversionsTableProps } from './components/conversionHelpers';
import ConversionsFilter from './components/ConversionsFilter';
import ConvertCurrencies from './components/ConvertCurrencies';
import ConversionDetails from './ConversionDetails';

import imgEmptyState from '+assets/img/dashboard/empty-state.png';

function ConversionsComponent() {
  const amountInputRef = useRef<HTMLInputElement | null>(null);
  const { profile } = useStore();

  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [conversionState, setConversionState] = useState({ format: '', fieldToExport: '', enabled: false });

  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [showLargeExportModal, setLargeExportModal] = useState(false);

  const defaultMerchant = useStore(state => state.defaultMerchant);
  const defaultCurrency = useStore(state => state.defaultCurrency);

  const isConversionAllowed =
    Object.keys(defaultMerchant?.conversion_limits ?? {}).length > 0 && defaultCurrency in (defaultMerchant?.conversion_limits ?? {});

  const limit = searchQuery.value.limit || '10';
  const page = searchQuery.value.page || '1';

  const status = searchQuery.value.status || undefined;
  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value as unknown as Record<string, string[]>, [
      queriesParams.currency,
      queriesParams.page,
      queriesParams.limit,
      queriesParams.sorterType,
      queriesParams.status,
      queriesParams.isFilterVisible
    ])
  };

  const { data: conversions, isFetching } = SwapWalletConversionService.useGetConversions({
    params: { page, limit, ...sortingParams },
    refetchOnCloseFeedbackError: true
  });

  const data = conversions?.data?.data || [];
  const paging = conversions?.data?.paging || {};

  const tableData = conversionTableData(data);

  const { isLoading } = SwapWalletConversionService.useExportConversions({
    enabled: conversionState.enabled,
    successMessage: 'Conversions successfully downloaded',
    errorMessage: 'There has been an error exporting your conversions',
    onError: () => {
      setExportModalVisible(false);
    },
    onSuccess: res => {
      setExportModalVisible(false);
      setConversionState({ format: '', fieldToExport: '', enabled: false });
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = conversionState.format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `Conversions at ${getDate(Date.now())}`, type);
      }
    },
    params: { format: conversionState.format, fieldsToExport: conversionState.fieldToExport, sortingParams }
  });

  const handleConversionSuccess = (isSuccess: boolean) => {
    setIsModalOpen(isSuccess);
  };

  const conversionCompletedModal = () => {
    const content = {
      completedHeading: 'Conversion Successful',
      completedDescription: 'You have successfully completed this  conversion.',
      completedActionText: 'Dismiss',
      completedAction: () => setIsModalOpen(false)
    };

    return {
      visible: isModalOpen,
      hideCloseButton: true,
      size: 'md',
      close: () => () => setIsModalOpen(false),
      modalStage: 'complete',
      ...content
    };
  };

  const exportFile = async (format: string, fieldToExport: string[]) => {
    const parameterizeArray = (key: string, arr: string[]) => {
      arr = arr.map(encodeURIComponent);
      return arr.join(`&${key}[]=`);
    };
    const fields = parameterizeArray('fieldsToExport', fieldToExport);
    setConversionState({ format, fieldToExport: fields, enabled: true });
  };

  const { headings, emptyStateHeading, tableClassName, emptyStateMessage } = getConversionsTableProps({
    isConversionAllowed,
    amountInputRef
  });

  return (
    <div className="">
      <>
        <Modal {...conversionCompletedModal()} />
        <div className="row">
          <div className="col-sm-12">
            <div className="bulk-payouts-desc">
              <div className="heading-box-mmd">
                <h4 role="heading" className="form-header payment-link-header">
                  Quick Currency Conversions
                </h4>
                <p className="form-desc payment-desc">
                  Easy and instant conversions between currencies. Note that exchange rates are dynamic and may change over time.
                </p>
              </div>
            </div>
            {isConversionAllowed ? (
              <ConvertCurrencies
                onConversionSuccess={handleConversionSuccess}
                amountInputRef={amountInputRef}
                onError={(message: string) =>
                  feedbackInit({
                    message,
                    type: 'danger'
                  })
                }
              />
            ) : (
              <div className="empty-state-content empty-state-with-bg" data-testid="empty-state-content">
                <div className="empty-state-image">
                  <img alt="img" src={imgEmptyState} loading="lazy" />
                </div>
                <p className="heading">You Don’t Have Access to Currency Conversions Yet</p>
                <p>To enable this feature, please contact our support team. We&apos;re here to help!</p>
                <a
                  className="refetch-button contact-support-btn"
                  href="mailto:support@korapay.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="os-icon os-icon-phone" />
                  Reach out to support
                </a>
              </div>
            )}
          </div>
        </div>
        {<ExportFilterModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />}
        {
          <ConversionsFilter
            openExportModal={() => setExportModalVisible(true)}
            totalCount={+paging?.total_items || 0}
            disabled={data.length === 0}
          />
        }
        <div className="row">
          <div className="col-md-12">
            <div className="element-wrapper">
              <section className="transaction_table_comp bulk-payout-table">
                <Table
                  headings={headings}
                  emptyStateHeading={emptyStateHeading}
                  tableClassName={tableClassName}
                  emptyStateMessage={emptyStateMessage}
                  hasPagination
                  loading={isFetching}
                  current={paging.current}
                  totalItems={paging?.total_items || 0}
                  pageSize={paging?.page_size || 0}
                  actionFn={value => searchQuery.setQuery({ page: String(value) })}
                  limitAction={value => searchQuery.setQuery({ limit: String(value) })}
                >
                  {tableData}
                </Table>
              </section>
              {exportModalVisible && (
                <AdvanceExportModal
                  openExport={exportModalVisible}
                  setOpenExport={setExportModalVisible}
                  exportAction={exportFile}
                  type="conversions"
                  showSuccessModal={false}
                  isLoading={isLoading}
                />
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
export default function Conversions() {
  return (
    <Switch>
      <Route exact path="/dashboard/conversions" component={ConversionsComponent} />
      <Route path="/dashboard/conversions/:id">
        <ConversionDetails />
      </Route>
    </Switch>
  );
}
