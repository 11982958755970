import Modal from '+containers/Dashboard/Shared/Modal';
import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import { DataType, PaymentAmountDetailsType, StatusType } from '+types';
import { capitalizeRemovedash, formatAmount } from '+utils';

import { payInMoreTrxDetailBanner } from '../payInDetailsHelpers';

import infoIcon from '+assets/img/dashboard/information-button.svg';

const ViewTrxBreakdownModal = ({ trx, close }: { trx: DataType; close: () => void }) => {
  const amountPaidForSuccessStatus = (trx.status as StatusType) === 'success' ? trx.amount_charged : 0;

  const paymentTypesDetails: PaymentAmountDetailsType = {
    currency: trx.currency,

    ...{
      ...(trx.payment_source_type === 'bank_transfer' && { amount_expected: `${formatAmount(trx.amount_charged)} ${trx.currency}` }),
      amount_charged: `${formatAmount(trx.amount_charged)} ${trx.currency}`,
      amount_paid: `${formatAmount(+trx.amount_collected ? +trx.amount_collected : amountPaidForSuccessStatus)}
        ${trx.currency}`
    },
    ...(trx.meta?.payment_event_action?.event === 'overpayment'
      ? { overpayment: `${formatAmount(trx.amount_collected - trx.amount_charged)} ${trx.currency}` }
      : {}),
    ...(trx.meta?.payment_event_action?.event === 'underpayment'
      ? { underpayment: `${formatAmount(trx.amount_collected - trx.amount_charged)} ${trx.currency}` }
      : {}),
    ...(trx.meta?.additional_fees?.stamp_duty && {
      stamp_duty_fee: `${formatAmount(trx.meta?.additional_fees?.stamp_duty)} ${trx.currency}`
    }),
    fees: `${formatAmount(parseFloat(trx.fee || '0') + parseFloat(trx.vat || '0'))} ${trx.currency}`,

    ...(trx.payment?.sentinal_transaction
      ? {
          tax: `${formatAmount(+trx.payment.sentinal_transaction.vat + +trx.payment.sentinal_transaction.processing_fee || 0)} ${trx.currency}`,
          net_amount: `${formatAmount(
            trx.amount_paid - trx.payment.sentinal_transaction.vat - trx.payment.sentinal_transaction.processing_fee
          )} ${trx.currency}`
        }
      : {
          net_amount: `${formatAmount(trx.amount_paid)} ${trx.currency}`
        })
  };

  return (
    <Modal
      close={() => close()}
      heading="Amount Breakdown"
      description={
        <p style={{ color: '#414F5F', fontWeight: 400 }}>
          This is a detailed breakdown of the amount on this transaction, including the individual components that contribute to the final
          value of the transaction.
        </p>
      }
      content={
        <>
          {payInMoreTrxDetailBanner(trx)}
          {Object.keys(paymentTypesDetails).map(item => (
            <p className="overpayment-underpayment-list" key={item}>
              <span className="overpayment-underpayment-list-left-side">
                {capitalizeRemovedash(item)}
                {item === 'amount_expected' && (
                  <ToolTip
                    image={infoIcon}
                    type="amount_expected"
                    message="This is the entire amount that the customer was supposed to pay."
                  />
                )}
                {item === 'amount_paid' && (
                  <ToolTip image={infoIcon} type="amount_paid" message="This is the total amount paid (including fees)." />
                )}
                {item === 'amount_charged' && (
                  <ToolTip
                    image={infoIcon}
                    type="amount_charged"
                    message="This is the total amount to be settled, excluding fees and any reserves."
                  />
                )}

                {item === 'fees' && <ToolTip image={infoIcon} type="fees" message="This is the sum of the transaction fees." />}
                {item === 'tax' && <ToolTip image={infoIcon} type="tax" message="This is the sum of the tax deducted" />}
              </span>
              <span className="overpayment-underpayment-list-right-side">
                {paymentTypesDetails[item as keyof PaymentAmountDetailsType]}
              </span>
            </p>
          ))}
        </>
      }
      firstButtonText="Close"
      hideSecondButton
    />
  );
};

export default ViewTrxBreakdownModal;
