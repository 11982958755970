import { getExistingFiltersFromQuery } from '+containers/Dashboard/Shared/FilterModal';
import { CardIssuanceServices } from '+services/card-issuance-services';
import { Currency } from '+types';
import { filteredOutObjectProperty } from '+utils';
import queryParams from '+utils/queryParams';

import { billingHistoryFields } from '../data';
import { getIssuingPermissions } from '../utils';

interface IUseFetchBilling {
  currency?: Currency;
  tab: 'billing_history' | string;
  limit: string;
  page: string;
  queryValue: any;
}

export default function useFetchBilling({ currency = 'USD', tab, limit, page, queryValue }: IUseFetchBilling) {
  const { canViewBillingHistory } = getIssuingPermissions();

  const sortingParams = {
    ...filteredOutObjectProperty(queryValue, [
      queryParams.currency,
      queryParams.page,
      queryParams.limit,
      queryParams.tab,
      queryParams.subtab,
      queryParams.sorterType
    ])
  };

  const {
    data: tableData,
    isFetching,
    refetch
  } = QueryFns[tab].services({
    params: QueryFns[tab].params({ limit, page, currency }),
    refetchOnCloseFeedbackError: true,
    enabled: canViewBillingHistory
  });

  return {
    tableData,
    isFetching,
    refetch
  };
}

const QueryFns: Record<
  string,
  {
    services: (value: {
      enabled?: boolean;
      onError?: (e: Error) => void;
      bannerLevel?: boolean;
      refetchOnCloseFeedbackError?: boolean;
      params: {
        page: string;
        limit: string;
        currency: string;
        exportFormat?: string;
        exportFields?: string;
        toExport?: boolean;
        format: string;
        fieldsToExport?: string;
      };
      errorMessage?: string;
    }) => { isFetching: boolean; refetch: () => void; data: unknown };
    params: (value: { limit: string; page: string; currency: Currency }) => {
      page: string;
      limit: string;
      currency: string;
      exportFormat?: string;
      exportFields?: string;
      toExport?: boolean;
      format: string;
      fieldsToExport?: string;
    };
  }
> = {
  billing_history: {
    services: CardIssuanceServices.useFetchBillingHistory,
    params: ({ limit, page }: { limit: string; page: string }) => ({
      limit,
      page,
      ...(getExistingFiltersFromQuery(billingHistoryFields) as {
        exportFormat?: string;
        currency: string;
        exportFields?: string;
        toExport?: boolean;
        format: string;
        fieldsToExport?: string;
      })
    })
  }
};

const QueryKeys: { [key: string]: string } = {
  billing_history: 'BILLING_HISTORY'
};
