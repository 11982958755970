import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import { switchCard } from '+containers/Dashboard/TransactionDetails/data';
import { SummaryItemType } from '+containers/Dashboard/TransactionDetailsNew/types';
import Copyable from '+dashboard/Shared/Copyable';
import { IChargebackTransactionDetails } from '+types';
import { capitalizeRemovedash, formatAmount, getDateAndTime, getTwoDigitDate, history, switchCurrency, switchStatus } from '+utils';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

export function generateSummaryFrom(data: IChargebackTransactionDetails): Array<SummaryItemType> {
  return [
    { label: 'Chargeback Amount', value: `${formatAmount(data?.amount)} ${data?.currency}` },
    { label: 'Date / Time', value: getDateAndTime(data?.escalation_date) },
    { label: 'Cardholder', value: data?.card_holder_name },
    {
      label: 'Transaction ID',
      value: (
        <>
          <Copyable text={data?.reference.toUpperCase()} textModifier={(text: string) => `${text.substring(0, 15)}...`} /> <br />
          <button
            type="button"
            className="btn"
            data-redirectto="original-transaction"
            onClick={() => history.push(`/dashboard/issuing/card-transactions/${data.transaction_reference}`)}
          >
            <span>See Original Transaction</span>
            <i className="os-icon os-icon-arrow-up-right" />
          </button>
        </>
      )
    }
  ];
}

export function generateMoreDetailsFrom(data: IChargebackTransactionDetails): Array<SummaryItemType> {
  const requiresAuth = ['requires_auth', 'pending_auth', 'require_auth'].includes(data?.status);

  const isNotPending = !['pending', 'processing', 'pending_pre_arbitration', 'processing_pre_arbitration'].includes(data?.status);

  const hasReescalated = [
    'pending_pre_arbitration',
    'processing_pre_arbitration',
    'accepted_pre_arbitration',
    'partially_accepted_pre_arbitration',
    'invalid_pre_arbitration',
    'declined_pre_arbitration',
    'delayed_pre_arbitration',
    'accepted_arbitration',
    'declined_arbitration'
  ].includes(data?.status);

  return [
    {
      label: 'Status',
      value: (
        <>
          <span className={`status-pill smaller ${switchStatus(data?.status)}`} />{' '}
          {requiresAuth ? 'Pending (Requires Authorization)' : capitalizeRemovedash(data?.status) || 'Not Available'}
        </>
      )
    },
    { label: 'Transaction Amount', value: `${data?.transaction_amount} ${data?.currency}` },
    { label: 'Reescalated Amount', value: `${data?.re_escalated_amount} ${data?.currency}`, hidden: !hasReescalated },
    { label: 'Escalated Amount', value: `${data?.amount} ${data?.currency}`, hidden: hasReescalated },
    { label: 'Currency Charged', value: switchCurrency[data?.currency] },
    { label: 'Purchasing Vendor', value: data?.card_acceptor_name ?? 'Not Available' },
    { label: 'Date, Time of Transaction', value: getDateAndTime(data?.transaction_date) },
    { label: 'Date, Time of Escalation', value: getDateAndTime(data?.escalation_date) },
    {
      label: (
        <>
          <span>Expected Resolution Date</span>{' '}
          <ToolTip image={InfoIcon} message="This chargeback will be resolved on or before this date" />
        </>
      ),
      value: getDateAndTime(data?.expected_resolution_date)
    },
    {
      label: 'Date, Time of Resolution',
      value: getDateAndTime(data?.actual_resolution_date),
      hidden: !isNotPending
    },
    { label: 'Kora Reference', value: <Copyable text={data?.reference} /> },
    { label: 'Description', value: data?.description }
  ];
}

export function generateCardholderFrom(data: IChargebackTransactionDetails): Array<SummaryItemType> {
  return [
    {
      label: "Cardholder's Name",
      value: data?.card_holder_name
    },
    {
      label: 'PAN',
      value: (
        <>
          <img alt={data?.card_brand} src={switchCard[data?.card_brand]} className="card-logo" />
          <Copyable text={`**** ${data?.last_four}`} spanClassName="copyable-bold" />
        </>
      )
    },
    {
      label: 'Card ID',
      value: <Copyable text={data?.card_reference} spanClassName="copyable-blue" />
    },
    {
      label: 'Expiry Date',
      value: getTwoDigitDate(data?.card_expiry_date)
    }
  ];
}

export function generateDocumentsFrom(data: IChargebackTransactionDetails['status_history']) {
  const docsList = data?.reduce((prev: Array<{ fileName: string; fileUrl: string; dateOfUpload: string }>, next) => {
    if (next.evidence) {
      return [
        ...prev,
        {
          fileName: next.description,
          fileUrl: next.evidence,
          dateOfUpload: next.date
        }
      ];
    }
    return prev;
  }, []);

  return docsList;
}
