import { FormikProps } from 'formik';

import Icon from '+containers/Dashboard/Shared/Icons';
import { TCardManagementModalFormValues } from '+types';
import { filterUserInput } from '+utils';

const reasonsForAction = [
  { label: '- Select an option -', value: '' },
  { label: 'Suspected Fraud', value: 'fraud' },
  { label: 'Card Compromise', value: 'compromise' },
  { label: "Cardholder's request", value: 'request' },
  { label: 'Other', value: 'other' }
];

const CardManagementForm = ({
  action,
  formik
}: {
  action: 'terminate_card' | 'suspend_card';
  formik: FormikProps<TCardManagementModalFormValues>;
}) => {
  const { values, errors, setFieldValue, handleBlur, touched } = formik;
  const minimumCharacterLength = 3;
  const maximumCharacterLength = 100;
  const verb = action === 'suspend_card' ? 'suspend' : 'terminate';

  return (
    <div className="card-management">
      {action === 'terminate_card' && (
        <div className="card-management__notice">
          <div className="card-management__notice-icon">
            <Icon name="cautionOutlined" />
          </div>
          <p className="card-management__notice-copy">
            Terminated cards will be permanently blocked and will no longer be able to perform transactions.
          </p>
        </div>
      )}
      <div className="form-group">
        <label htmlFor="reason">
          <span className="dark">Why do you want to {verb} this card?</span>
        </label>
        <select
          className="form-control"
          id="reason"
          name="reason"
          onChange={e => setFieldValue('reason', e.target.value)}
          value={values?.reason}
        >
          {reasonsForAction.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="reasonDescription">
          <span className="dark">Tell us more about your reason to {verb} this card</span>
        </label>
        <textarea
          id="reasonDescription"
          name="reasonDescription"
          className="form-control"
          onChange={e => setFieldValue('reasonDescription', filterUserInput(e.target.value))}
          onBlur={handleBlur}
          value={values.reasonDescription}
          rows={3}
          minLength={minimumCharacterLength}
          maxLength={maximumCharacterLength}
          aria-describedby="word-count"
        />
        <div className="flex width-full reset-margin" data-justify="between">
          {touched.reasonDescription && errors.reasonDescription && (
            <p style={{ color: 'red', fontSize: '.875rem' }}>{errors.reasonDescription}</p>
          )}
          <span
            id="word-count"
            className="ml-auto"
            aria-live="polite"
            style={{ fontSize: '.875rem' }}
          >{`${values.reasonDescription.length}/${maximumCharacterLength}`}</span>
        </div>
      </div>
    </div>
  );
};

export default CardManagementForm;
