/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState } from 'react';
import { Field, Formik } from 'formik';

import ListDropdown from '+dashboard/Shared/ListDropdown';
import { useBanks } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import { useAccountValidation } from '+hooks/useAccountValidation';
import useMobileOperators from '+hooks/useMobileOperators';
import { SettlementServices } from '+services/settlement-services';
import Modal from '+shared/Modal';
import useStore from '+store';
import { IOperators } from '+types/kycform-types';
import { cleanInput, switchCurrency } from '+utils';

interface IBankReplacementModalProps {
  isFetchingBanks: boolean;
  banks?: Array<Record<string, any>>;
  close: () => void;
  showRequestSentModal: () => void;
  secondbuttonDisable: boolean;
  refetchAccount: () => void;
}

const BankReplacementModal = ({ close, showRequestSentModal, refetchAccount }: IBankReplacementModalProps) => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const formRef = useRef(null);
  const [bankListOpen, setBankListOpen] = useState(false);
  const [state, setState] = useState({
    currency: 'NGN',
    bank_code: '',
    account_name: '',
    account_number: '',
    phone_number: '',
    mobileMoneyCode: '',
    modalVisible: false,
    bankEnquiry: {
      visible: false,
      message: 'Verifying bank account...',
      type: 'secondary'
    }
  });

  const {
    validateBankAccount,
    debounceValidateBankAccount,
    resetValidationDetails,
    bankDetails,
    showValidationStatus,
    accountNumMaxLength
  } = useAccountValidation({
    currency: state.currency,
    formRef,
    canEditName: true
  });

  const { data: banks } = useBanks(state.currency);
  const { data: operators } = useMobileOperators('GHS');
  const availableCurrencies = useStore(state => state.availableCurrencies);

  const handleSubmit = async () => {
    const { account_number, bank_code, account_name, phone_number, mobileMoneyCode } = formRef.current.values;
    if (bankDetails?.canEditName && !(account_name?.length > 2)) {
      return;
    }
    const updateSettlement = {
      currency: state.currency,
      ...(state.currency === 'GHS'
        ? {
            details: {
              operator: mobileMoneyCode,
              mobile_number: phone_number,
              account_name: account_name || undefined
            }
          }
        : {
            details: {
              bank_code,
              account_number,
              ...(bankDetails?.canEditName && { account_name })
            }
          }),

      ...(state.currency === 'GHS' && { type: 'mobile_money' })
    };
    await mutateAsync(updateSettlement);
    closeFeedback();
  };

  const { mutateAsync } = SettlementServices.useUpdateSettlementAccount({
    showErrorMessage: true,
    onSuccess: () => {
      refetchAccount();
      close();
      setState({
        ...state,
        account_number: '',
        buttonDisabled: true,
        modalVisible: false
      });
      showRequestSentModal();
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    },
    onError: error => {
      window.scrollTo(0, 0);
      setState({ ...state, buttonDisabled: true, modalVisible: false, account_number: '' });
      if (
        error.response.data?.message?.includes('Account details is already being reviewed') ||
        error.response.data?.message?.includes('Account details was rejected, please use a different account') ||
        error.response.data?.message?.includes('Account number has already been added and approved') ||
        error.response.data?.message?.includes('Mobile money account details is already being reviewed') ||
        error.response.data?.message?.includes('Mobile number has already been added and approved') ||
        error.response.data?.message?.includes('Mobile money account details was rejected')
      ) {
        feedbackInit({
          message: `Duplicate account details submitted, please use a different account`,
          type: 'danger',
          componentLevel: true
        });
      } else {
        feedbackInit({
          message: `There's an issue updating your settlement account details. Try again later`,
          type: 'danger',
          componentLevel: true
        });
      }
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  });

  const selectAccountContent = () => {
    return (
      <div className="bankForm-container">
        <Formik
          initialValues={{
            account_name: '',
            account_number: '',
            bank_code: '',
            bank_name: '',
            phone_number: '',
            mobileMoneyCode: '',
            resolving_bank: false,
            enquiry_message: ''
          }}
          innerRef={formRef}
          validate={values => {
            const errors = {};
            if (bankDetails?.canEditName && values.account_name?.length < 3) {
              errors.account_name = 'Please enter a valid name';
            }
            return errors;
          }}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <>
                <div className="form-group">
                  <Field name="currency">
                    {({ field }) => (
                      <>
                        <label htmlFor="account_number" className="withdraw-label">
                          <span className="dark">Currency</span>
                        </label>

                        <select
                          {...field}
                          name="currency-select"
                          component="select"
                          className="form-control"
                          required
                          value={state.currency}
                          onChange={e => {
                            e.persist();
                            setState({ ...state, currency: e?.target?.value });
                          }}
                        >
                          {availableCurrencies?.map(currency => (
                            <option value={currency} key={currency}>
                              {switchCurrency[currency]}
                            </option>
                          ))}
                          {availableCurrencies?.length === 0 && <option value="">Not Available</option>}
                        </select>
                      </>
                    )}
                  </Field>
                </div>
                {state.currency === 'GHS' ? (
                  <>
                    <div className="form-group">
                      <Field name="bank">
                        {({ field }) => (
                          <>
                            <label htmlFor="mobileMoneyCode" className="withdraw-label">
                              <span className="dark">Operator</span>
                            </label>
                            <select
                              {...field}
                              name="mobileMoneyCode"
                              className="form-control"
                              value={state.mobileMoneyCode}
                              onChange={e => {
                                e.persist();
                                setState({ ...state, mobileMoneyCode: e?.target?.value });
                                setFieldValue('mobileMoneyCode', e?.target?.value);
                              }}
                            >
                              <option value="">Select operator</option>
                              {operators?.data?.map((operator: IOperators) => (
                                <option value={operator.code} key={operator.code}>
                                  {operator.name}
                                </option>
                              ))}
                              {operators?.data?.length === 0 && <option value="">Not Available</option>}
                            </select>
                          </>
                        )}
                      </Field>
                    </div>

                    <div className="form-group">
                      <Field name="phone_number">
                        {({ field }) => (
                          <>
                            <label htmlFor="phone_number" className="withdraw-label">
                              <span className="dark">Mobile money Number</span>
                            </label>
                            <input
                              name="phone_number"
                              {...field}
                              placeholder="E.g. 0123456789"
                              maxLength={12}
                              onChange={e => {
                                setState({ ...state, phone_number: e?.target?.value });
                                setFieldValue('phone_number', e?.target?.value);
                              }}
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="form-group">
                      <Field name="bank">
                        {({ field }) => (
                          <>
                            <label htmlFor="account_number" className="withdraw-label">
                              <span className="dark">Bank</span>
                            </label>
                            <ListDropdown
                              {...field}
                              list={banks?.data}
                              type="bank"
                              className="banks-list settlement-bank-list"
                              value={{ code: values?.bank_code, name: values?.bank_name }}
                              active={bankListOpen}
                              setActive={open => setBankListOpen(open)}
                              onBlur={null}
                              setValue={value => {
                                validateBankAccount({ ...values, bank_code: value.code, bank_name: value.name }, setFieldValue);
                                setFieldValue('bank_code', value.code);
                                setFieldValue('bank_name', value.name);
                              }}
                            />
                          </>
                        )}
                      </Field>
                    </div>

                    <div className="form-group">
                      <Field name="account_number">
                        {({ field }) => (
                          <>
                            <label htmlFor="account_number" className="withdraw-label">
                              <span className="dark">Account Number</span>
                            </label>
                            <input
                              name="account_number"
                              {...field}
                              placeholder="E.g. 0123456789"
                              maxLength={accountNumMaxLength}
                              onChange={e => {
                                resetValidationDetails();
                                field.onChange?.(e);
                                debounceValidateBankAccount({ ...values, account_number: e.target.value }, setFieldValue);
                              }}
                            />
                          </>
                        )}
                      </Field>
                    </div>

                    <div className="form-group">
                      {showValidationStatus()}
                      <Field name="account_name">
                        {({ field }) => (
                          <>
                            <label htmlFor="account_name" className="withdraw-label">
                              <span className="dark">Customer</span>
                            </label>
                            <input
                              {...field}
                              name="account_name"
                              placeholder="E.g. Ciroma Chukwuma"
                              {...(!bankDetails.canEditName && {
                                readOnly: true,
                                className: 'read-only'
                              })}
                              onChange={e => {
                                setFieldValue('account_name', cleanInput(e.target.value));
                              }}
                            />
                            <em className="text-danger">{errors?.account_name}</em>
                          </>
                        )}
                      </Field>
                    </div>
                  </>
                )}
              </>
            );
          }}
        </Formik>
      </div>
    );
  };

  const setDisabled = () => {
    if (
      !state.currency ||
      (state.currency !== 'GHS' && !bankDetails?.isValidated) ||
      (state.currency === 'GHS' && (!state.mobileMoneyCode || state.phone_number.length !== 12))
    )
      return true;
  };

  return (
    <Modal
      close={() => {
        setState({
          ...state,
          bankEnquiry: {
            visible: null,
            message: null,
            type: null
          }
        });
        close();
      }}
      secondButtonDisable={setDisabled()}
      showImage={false}
      heading="Replace Bank Account"
      description="To update your bank account, please fill out the form below. This account should have the same name as the one in your profile."
      content={selectAccountContent()}
      firstButtonText="Cancel"
      secondButtonText="Proceed"
      secondButtonAction={handleSubmit}
      secondButtonActionIsTerminal={false}
    />
  );
};

export default BankReplacementModal;
