import { useEffect } from 'react';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import Icon from '+containers/Dashboard/Shared/Icons';
import { MerchantServices } from '+services/merchant-services';
import useStore from '+store';
import { IReferralsData } from '+types';

const ReferralsLink = ({
  setStage,
  data,
  refetchLink
}: {
  setStage: (value: 'info' | 'link') => void;
  data?: IReferralsData | null;
  refetchLink: () => void;
}) => {
  const profile = useStore(state => state.profile);
  const { mutate, isLoading } = MerchantServices.useGenerateLink({
    onSuccess: () => {
      refetchLink();
    },
    onError: () => {
      setStage('info');
    }
  });

  useEffect(() => {
    if (!data) mutate({ email: profile?.email });
  }, []);

  return isLoading ? (
    <div className="referrals-spinner">
      <Icon name="circleSpinner" width={20} height={20} />
      <p>Generating Link...</p>
    </div>
  ) : (
    <>
      <div className="referrals-heading-content">
        <div>
          <h6>Invite People</h6>
          <div className="form-desc" style={{ fontSize: '0.75rem', border: 'none', marginBottom: '0', maxWidth: '24rem' }}>
            Refer your friends to Kora by sharing the link below. They can to sign up and create a kora account using your unique referral
            link.
          </div>
        </div>
        <div className="referral-link-box">
          <div className="referral-link">
            Share Referral Link
            <p className="referrals-cont-copy">
              <span>{!isLoading && <Copyable text={data?.data?.link ?? ''} buttonClassName="referrals-btn-copy" />}</span>
            </p>
          </div>
        </div>
      </div>
      {(data?.data?.count ?? 0) >= 0 ? (
        <div className="referrals-heading-content">
          <div>
            <h6>Total Referrals</h6>
            <div className="form-desc" style={{ fontSize: '0.75rem', border: 'none', marginBottom: '0', maxWidth: '24rem' }}>
              Below, you&apos;ll find the total number of referrals you&apos;ve made.
            </div>
          </div>
          <div className="referral-link">
            <p className="referrals-cont-count">
              Successful Referrals:
              <span>{data?.data?.count}</span>
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ReferralsLink;
