/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useReducer, useState } from 'react';

import { useSurveyTrigger } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import { RefundServices } from '+services/refund-services';
import { backwardAmountInput, capitalizeFirstText, cleanInput, formatAmount, logBreadCrumb } from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import ListDropdown from './ListDropdown';
import Modal from './Modal';

import './CreateRefundModal.scss';

const reasonOptions = [
  { code: '1', name: 'Unfulfilled Request' },
  { code: '2', name: 'Cancelled Transaction' },
  { code: '3', name: 'Overpayment' },
  { code: '4', name: 'Custom Reason' }
];
interface ICreateRefundsModalProps {
  close: () => void;
  visible: boolean;
  reference: string;
  currency: string;
  refetchRefund: () => void;
}

const CreateRefundsModal = ({ close, visible, reference, currency, refetchRefund }: ICreateRefundsModalProps) => {
  const { feedbackInit } = useFeedbackHandler();
  const [initiateSurvey, setInitiateSurvey] = useState(false);
  const [type, setType] = useState('');
  const [validationRef, setValidationRef] = useState('');

  useSurveyTrigger({
    surveyId: +(process.env.REACT_APP_ATLAS_QUERY_RD || '0'),
    trigger: initiateSurvey,
    id: 'refund',
    saveToLocalStorage: false
  });
  const [event, updateEvent] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      validateReason: '',
      refundReason: '',
      loading: false,
      transactionState: null,
      transactionReference: null,
      refundType: null,
      reasonListOpen: false,
      canRefund: false,
      customField: false,
      reason: undefined,
      amount: null,
      customReason: '',
      maxAmount: null,
      minAmount: null,
      refundAmount: null,
      canRefundFull: false,
      internalReference: null
    }
  );

  const setDisabled = () => {
    if (
      event.amount > event.refundAmount ||
      event.amount < event.minAmount ||
      !event.canRefund ||
      event.loading ||
      (event.customField && event.customReason === '')
    )
      return true;
  };
  useEffect(() => {
    if (reference) {
      updateEvent({
        transactionState: 'verifying'
      });
      setValidationRef(reference);
    }
  }, [reference]);

  RefundServices.useValidateTransaction({
    enabled: Boolean(validationRef),
    ref: validationRef,
    onSuccess: data => {
      setValidationRef('');
      const {
        can_refund,
        reason,
        refund_reason,
        maximum_refundable_amount,
        minimum_refundable_amount,
        available_refundable_amount,
        payment_source_reference
      } = data?.data || {};
      updateEvent({
        canRefund: can_refund,
        transactionState: (can_refund && 'valid') || 'invalid',
        validateReason: reason,
        refundReason: refund_reason,
        internalReference: payment_source_reference,
        maxAmount: maximum_refundable_amount,
        minAmount: minimum_refundable_amount,
        refundAmount: available_refundable_amount,
        canRefundFull: available_refundable_amount < maximum_refundable_amount
      });
    },
    onError: error => {
      updateEvent({
        transactionState: 'invalid'
      });
      feedbackInit({
        message: error.response?.data?.message || 'We are sorry, we could not validate your transaction right now.',
        type: 'danger',
        componentLevel: true,
        action: {
          action: () => setValidationRef(reference || event.transactionReference),
          name: 'Try again'
        }
      });
    }
  });

  // Raising refunds
  const initiateRefund = RefundServices.useInitiateRefund({
    ref: reference || event.transactionReference,
    showErrorMessage: true,
    onSuccess: () => {
      setInitiateSurvey(true);
      refetchRefund();
    },
    onError: error => {
      updateEvent({
        loading: false
      });
      const message = error.response?.data?.message || 'We are sorry, we could not raise a refund request right now.';
      feedbackInit({
        message,
        type: 'danger',
        componentLevel: true
      });
    }
  });

  //   To be called when validating transactionID
  const showStatus = () => {
    switch (event.transactionState) {
      case 'valid':
        return (
          <small>
            <span className="os-icon os-icon-checkmark" role="status" /> Valid
          </small>
        );
      case 'invalid':
        return <small className="text-danger">{capitalizeFirstText(event.validateReason)}</small>;
      case 'verifying':
        return <small>Verifying..</small>;
    }
  };

  const Radio = ({ checked, onChange, label, disabled }) => {
    return (
      <label className="radio-container" onClick={!disabled ? onChange : null}>
        <input checked={checked} type="radio" onChange={onChange} disabled={disabled} data-testid="refund-type" />
        {label}
      </label>
    );
  };

  const defaultRefundContent = () => {
    return (
      <>
        <div className="element-box refund-request-note p-3">
          <div>
            <p>Please note that requested Refunds are typically processed within 5 - 7 business days.</p>
          </div>
        </div>

        <div className="form-group mb-4">
          <label className="withdraw-label">
            <span className="dark">Transaction ID</span>
          </label>

          <input
            className="form-control"
            data-testid="transaction-id"
            type="text"
            defaultValue={reference}
            disabled={reference || false}
            name="transactionReference"
            value={event.transactionReference}
            placeholder="Enter Transaction ID"
            onBlur={() => {
              if (event.transactionReference) {
                updateEvent({ transactionState: 'verifying' });
                setValidationRef(event.transactionReference);
              }
            }}
            onChange={e => {
              const formattedInput = cleanInput(e.target.value);
              updateEvent({ transactionReference: formattedInput });
            }}
          />

          <div className="show-status">{showStatus()}</div>
        </div>

        {event?.internalReference && (
          <div className="form-group mb-4">
            <label className="withdraw-label">
              <span className="dark">Internal Reference</span>
            </label>
            <input className="form-control" value={event?.internalReference} disabled />
          </div>
        )}
        <div className="form-group pt-2">
          <label htmlFor="amount" className="withdraw-label">
            <span className="dark">Select Refund Type</span>
          </label>
          <div className={`my-2 radio-div opacity-50 ${event.canRefundFull && 'full-disabled'}`}>
            <Radio
              checked={event.refundType === 'full'}
              disabled={!event.canRefund || event.canRefundFull}
              onChange={() => {
                updateEvent({ refundType: 'full', amount: event.maxAmount });
              }}
              label="Full Refund"
            />
            <small>Refund the full amount </small>
          </div>

          {event.canRefundFull && (
            <div className="show-reason px-3">
              <small className="text-danger">
                <span className="os-icon os-icon-info icon-danger" role="status" /> {event.refundReason}
              </small>
            </div>
          )}
          <div className="my-2 mt-3 radio-div">
            <Radio
              checked={event.refundType === 'partial'}
              disabled={!event.canRefund}
              onChange={() => {
                updateEvent({ refundType: 'partial', amount: null });
              }}
              label="Partial Refund"
            />
            <small>Refund a partial amount</small>
          </div>
        </div>

        {event.refundType && (
          <div className="form-group my-2 pt-2">
            <label htmlFor="refund-amount" className="withdraw-label">
              <span className="dark">Refund Amount</span>
            </label>
            <input
              rows="2"
              type="number"
              maxLength="150"
              value={event.amount || ''}
              className="form-control"
              name="amount"
              placeholder="Enter amount"
              disabled={event.refundType === 'full'}
              onChange={e => {
                const formattedInput = backwardAmountInput(e.target.value);
                updateEvent({ amount: formattedInput });
              }}
              onWheel={e => e.target.blur()}
              onKeyDown={e => ['e', 'E', '+', '-', 'ArrowDown', 'ArrowUp'].includes(e.key) && e.preventDefault()}
            />

            <label htmlFor="amount" className="withdraw-label mt-2 small">
              <span>
                Minimum Amount:{' '}
                <span className={`dark ${parseFloat(event.amount) < event.minAmount && 'red'}`} id="min">
                  {currency} {formatAmount(event?.minAmount)}
                </span>
              </span>
              <span>
                Maximum Amount:{' '}
                <span className={`dark ${parseFloat(event.amount) > event.refundAmount && 'red'}`}>
                  {currency} {formatAmount(event.refundAmount)}
                </span>
              </span>
            </label>
          </div>
        )}

        <div className="form-group my-2 pt-2 reason-container">
          <label htmlFor="description" className="withdraw-label">
            <span className="dark">
              Reason <span className="optional">(*optional)</span>
            </span>
          </label>
          <ListDropdown
            list={reasonOptions}
            type="reason"
            name="reason"
            className="reason-list"
            searchDropdown={false}
            disabled={!event.canRefund}
            displayText={false}
            value={{ name: event.reason }}
            active={event.reasonListOpen}
            setActive={open => updateEvent({ reasonListOpen: open })}
            setValue={value => {
              if (value.name === 'Custom Reason') {
                updateEvent({ reason: value?.name, customField: true });
              } else {
                updateEvent({ reason: value?.name, customField: false });
              }
            }}
          />
        </div>

        {event.customField && (
          <div className="form-group my-2 pt-2">
            <label htmlFor="refund-amount" className="withdraw-label">
              <span className="dark">Enter Reason</span>
            </label>
            <textarea
              maxLength="50"
              autoComplete="off"
              type="text"
              className="form-control"
              name="amount"
              placeholder="Enter reason"
              value={event.customReason}
              onChange={e => {
                const formattedInput = cleanInput(e.target.value);
                updateEvent({ customReason: formattedInput });
              }}
            />
          </div>
        )}
      </>
    );
  };

  const switchRefundModal = kind => {
    let content;
    switch (kind) {
      case 'confirm':
        content = {
          heading: 'Confirm Refund Request',
          content: `Are you sure you want to refund ${currency} ${formatAmount(event.amount)} for the selected transaction? `,
          justify: 'space-between',
          firstButtonText: 'Go Back',
          size: 'sm',
          headingBorder: false,
          firstButtonAction: () => setType('init'),
          secondButtonText: 'Yes, Refund',
          secondButtonAction: async () => {
            updateEvent({
              loading: true
            });
            logBreadCrumb({
              event: breadCrumbEvents.disputes.attemptRefundButton,
              data: { reference: reference || event.transactionReference, amount: event.amount }
            });
            const refundDetails = {
              amount: event.amount,
              reason: event.customField ? event.customReason : event.reason
            };
            await initiateRefund.mutateAsync(refundDetails);
          },

          completedHeading: 'Refund Request successful',
          completedDescription: 'Your refund request has been created and is being processed.'
        };
        break;
      default:
        content = {
          heading: `Create New Refund Request`,
          description: 'Provide the details required below to make a full or partial refund for this transaction. ',
          content: defaultRefundContent(),
          secondButtonText: 'Confirm Refund',
          secondButtonAction: () => {
            setType('confirm');
          }
        };
        break;
    }

    return {
      size: 'md',
      close: () => {
        setType('init');
        updateEvent({
          canRefund: false,
          customField: false,
          transactionState: null,
          transactionReference: null,
          reason: undefined,
          amount: null,
          maxAmount: null,
          minAmount: null,
          refundType: null,
          refundAmount: null,
          canRefundFull: false,
          internalReference: null
        });
        close();
      },
      secondButtonDisable: setDisabled(),
      secondButtonActionIsTerminal: kind === 'confirm',
      ...content
    };
  };

  return <section>{visible && <Modal {...switchRefundModal(type)} />}</section>;
};

export default CreateRefundsModal;
