import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { datadogRum } from '@datadog/browser-rum';

import { useMerchantAuth } from '+hooks';
import { Storage } from '+services/storage-services';
import useStore from '+store';
import { ISendResponse, SignInResponseType } from '+types';
import { history } from '+utils';

export const useSigninResponse = () => {
  const queryClient = useQueryClient();
  const { setSavedLocation, savedLocation } = useStore();
  const savedRole = queryClient.getQueryData(['SAVED_ROLE']);
  const [state, setState] = useState({
    isLoading: false,
    feedback: {
      message: '',
      visible: false,
      type: 'danger'
    }
  });
  const { bootstrap } = useMerchantAuth();

  const serializeSigninSuccess = (response: ISendResponse<SignInResponseType> | { message: string }) => {
    const { message } = response as { message: string };
    if (message === 'A verification email has been sent to your email address') {
      return 'Unverified';
    }
    const authResponse = (response as ISendResponse<SignInResponseType>).data;

    if (authResponse?.two_factor_auth?.required) {
      useStore.setState({ tempToken: authResponse.access_token });
      return {
        identifier: authResponse.two_factor_auth.identifier,
        type: authResponse.two_factor_auth.type
      };
    }

    // IN THIS CASE, THE USER IS NOT USING 2FA
    useStore.setState({ authDetails: authResponse });
    return null;
  };

  const onSuccess = async (
    res: ISendResponse<SignInResponseType | { message: string }>,
    initiateTwoFA?: (response: { identifier: string; type: string }) => void
  ) => {
    const response = serializeSigninSuccess(res);
    const { authDetails } = useStore.getState();

    if (response === 'Unverified') {
      return setState({
        ...state,
        isLoading: false,
        feedback: { ...state.feedback, message: 'A verification email has been sent to your email address', visible: true }
      });
    }

    if (authDetails?.impossible_travel_detected) {
      return history.push('/auth/impossible-travel');
    }
    Storage.removeItem('USER_EMAIL');

    if (authDetails?.account?.merchants[0]?.meta_data?.show_kyc_page_after_login) {
      await bootstrap();
      return history.push('/auth/kyc');
    }
    if (response === null) {
      if (authDetails && process.env.REACT_APP_DARKHORSE_INIT === 'true') {
        const user = {
          id: authDetails?.account.id?.toString(),
          name: `${authDetails?.account.firstname} ${authDetails?.account.lastname}`,
          email: authDetails?.account.email
        };
        datadogRum.setUser(user);
      }
      setSavedLocation('');
      return history.push(savedLocation && savedLocation !== '/auth/login' ? savedLocation : '/dashboard/home', savedRole ?? '');
    }
    return initiateTwoFA?.(response);
  };

  return {
    serializeSigninSuccess,
    state,
    setState,
    onSuccess
  };
};
