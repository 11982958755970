import { ReactNode, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import HeaderTabs from '+containers/Dashboard/Shared/HeaderTabs';
import Table from '+containers/Dashboard/Shared/Table';
import { TabPanel, TabPanels, Tabs } from '+containers/Shared/Tabs';
import { useSearchQuery } from '+hooks';
import { BalanceServices } from '+services/balance-services';
import { CardIssuanceServices } from '+services/card-issuance-services';
import useStore from '+store';
import { BalanceSubtabKeyType, CommonSearchQueriesType, CurrencyType, IssuingTabKeyType, WalletBalanceType } from '+types';
import { formatAmount } from '+utils';

import Filter from '../components/Filter/Filter';
import FundIssuingWalletModal from '../components/FundIssuingWalletModal';
import Summary from '../components/Summary';
import { balanceSubtabsTableProps, computeTableTitle, exportActionOptions, getBalanceSubtabsFilterProps } from '../constants';
import { useFetchIssuanceTables, useFetchIssuingExport } from '../hooks';
import { getIssuingPermissions } from '../utils';
import FundingDepositsRow from './components/FundingDepositsRow';
import IssuingHistoryRow from './components/IssuingHistoryRow';

const subtabs: Array<{ label: string; key: BalanceSubtabKeyType; renderElement: (arg: any) => ReactNode }> = [
  {
    label: 'Issuing Balance History',
    key: 'issuing_balance_history',
    renderElement: ({ rowData, currency }: { rowData: any; currency: CurrencyType }) => (
      <IssuingHistoryRow rowData={rowData} currency={currency} />
    )
  },
  {
    label: 'Funding Deposits',
    key: 'funding_deposits',
    renderElement: ({ rowData, currency }: { rowData: any; currency: CurrencyType }) => (
      <FundingDepositsRow rowData={rowData} currency={currency} />
    )
  }
];

const IssuingBalance = () => {
  const { value: searchQueryValue, setQuery } = useSearchQuery<
    {
      tab: IssuingTabKeyType;
      subtab: BalanceSubtabKeyType;
    } & CommonSearchQueriesType
  >();
  const limit = searchQueryValue?.limit ?? '10';
  const page = searchQueryValue?.page ?? '1';
  const { walletBalance, addCardFees } = useStore(useShallow(store => store));
  const subtab = searchQueryValue?.subtab ?? subtabs[0].key;
  const currency = searchQueryValue?.currency ?? 'USD';
  const exportAction = useFetchIssuingExport({ resourceType: `All-${subtab}`, exportFn: exportActionOptions[subtab], currency });
  const [recordIsFiltered, setRecordIsFiltered] = useState(false);
  const [showWalletFunding, setShowWalletFunding] = useState(false);
  const { canViewWalletHistory, canFundWallet } = getIssuingPermissions();

  const {
    tableData,
    isFetching: isFetchingTableData,
    refetch: refetchTableData
  } = useFetchIssuanceTables({
    currency,
    page,
    limit,
    tab: subtab,
    queryValue: searchQueryValue
  });

  const { data: balances } = BalanceServices.useGetBalances({
    enabled: canFundWallet,
    showErrorMessage: false,
    refetchOnCloseFeedbackError: true,
    errorMessage: 'There has been an error in getting your balance'
  });

  CardIssuanceServices.useFetchSubscriptionFees({
    onSuccess: data => addCardFees(data?.data),
    refetchOnCloseFeedbackError: true,
    errorMessage: 'There has been an error in getting issuing subscription fees'
  });

  const paging = tableData?.data?.paging;
  const availableBalance = walletBalance?.[currency]?.available_balance;
  const tableProps = balanceSubtabsTableProps[subtab];
  const filterProps = getBalanceSubtabsFilterProps()[subtab];

  return (
    <div>
      <div className="issuing-heading">
        <div className="flex-grow-1 info-summary-container">
          <Summary
            label={`Issuing Balance (${currency})`}
            value={availableBalance ?? 0}
            valueFormatter={formatAmount}
            description="For card issuing and transactions"
          />
        </div>

        <div>
          <div className="page-action-btns">
            <button onClick={() => setShowWalletFunding(true)} className="btn btn-primary p-2" type="button">
              <span className="os-icon os-icon-plus" />
              <span>Add Funds</span>
            </button>
          </div>
        </div>
      </div>
      <Tabs defaultValue={subtab} onChange={subtabValue => setQuery({ tab: searchQueryValue.tab, subtab: subtabValue })}>
        <div className="os-tabs-controls os-tabs-complex settlement-history__tabs">
          <HeaderTabs
            tabs={subtabs.map(tab => tab.key)}
            activeTab={subtab}
            onClick={subtabValue => setQuery({ tab: searchQueryValue.tab, subtab: subtabValue })}
            className="small-text"
          />
        </div>

        <TabPanels>
          {subtabs.map(({ key, renderElement }) => {
            return (
              <TabPanel key={`${key}-panel`} value={key} className="transaction_table_comp table-container">
                <Filter
                  totalItems={Number(paging?.total_items)}
                  title={computeTableTitle({
                    filtered: recordIsFiltered,
                    activeTab: subtab as string,
                    pageItemCount: Number(paging?.total_items)
                  })}
                  actions={filterProps.actions}
                  filterModalHeading={filterProps.filterModalHeading}
                  filterModalDescription={filterProps.filterModalDescription}
                  filterFields={filterProps.filterFields}
                  exportType={filterProps.exportType}
                  exportHeading={filterProps.exportHeading}
                  exportDescription={filterProps.exportHeading}
                  onChangeIsFiltered={setRecordIsFiltered}
                  isFiltered={recordIsFiltered}
                  exportAction={exportAction}
                />

                <Table
                  tableClassName={`--history-table ${tableProps.tableClassName}`}
                  headings={tableProps.headings}
                  hasPagination
                  borderedTable
                  loading={isFetchingTableData}
                  current={paging?.current}
                  limitAction={value => setQuery({ limit: String(value) })}
                  pageSize={paging?.page_size}
                  actionFn={value => setQuery({ page: String(value) })}
                  totalItems={paging?.total_items || 0}
                  emptyStateHeading={tableProps.emptyStateHeading}
                  emptyStateMessage={
                    <>
                      <span>{tableProps.emptyStateMessage}</span>

                      {canViewWalletHistory && (
                        <button type="button" className="refetch-button" onClick={() => refetchTableData()}>
                          <i className="os-icon os-icon-rotate-ccw" style={{ marginRight: '5px' }} />
                          Refresh
                        </button>
                      )}
                    </>
                  }
                >
                  {renderElement({ rowData: tableData?.data?.data, currency })}
                </Table>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>

      {showWalletFunding ? (
        <FundIssuingWalletModal
          balances={balances?.data as WalletBalanceType}
          currency={currency}
          onClose={() => setShowWalletFunding(false)}
        />
      ) : null}
    </div>
  );
};

export default IssuingBalance;
