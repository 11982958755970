import { useState } from 'react';
import { useParams } from 'react-router-dom';

import DeclineChargeBackModal from '+containers/Dashboard/Shared/DeclineChargeBackModal';
import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { ActionButtons } from '+containers/Dashboard/TransactionDetailsNew/types';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { ChargeBackServices } from '+services/chargeback-services';
import useStore from '+store';
import { history, logBreadCrumb, switchTrxnMessage } from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import { getAccountDetailsFrom, getChargebackDetailsFrom, getPayersInfoFrom, getTopLevelSummaryFrom } from './chargebackDetailsHelpers';

const api: APIRequest = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

type ModalStateType = { isOpen: boolean; isApproved?: boolean };

const ChargebackDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { feedbackInit } = useFeedbackHandler();
  const [modalState, setModalState] = useState<ModalStateType>({ isOpen: false, isApproved: false });
  const permissions = useStore((state: any) => state.permissions);
  const {
    data: { data: chargebackData } = {},
    isLoading: chargebackDataIsLoading,
    refetch: refetchChargebackData
  } = ChargeBackServices.useGetSingleChargeBack({
    ref: id,
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
        type: 'danger'
      });
      history.goBack();
    }
  });

  const topLevelSummary = getTopLevelSummaryFrom(chargebackData);
  const chargebackDetails = getChargebackDetailsFrom(chargebackData);
  const accountDetails = getAccountDetailsFrom(chargebackData);
  const payersInfo = getPayersInfoFrom(chargebackData);
  const actionButtonIsHidden = !(permissions?.chargeback && permissions?.chargeback === 'manage' && chargebackData?.status === 'pending');

  const actionButtons: Array<ActionButtons> = [
    {
      onClick: () => {
        setModalState({ isOpen: true, isApproved: true });
        logBreadCrumb({
          event: breadCrumbEvents.disputes.acceptChargeBackButton
        });
      },
      hidden: actionButtonIsHidden,
      children: 'Accept Chargeback',
      variant: 'dark',
      disabled: false
    },
    {
      onClick: () => {
        setModalState({ isOpen: true, isApproved: false });
        logBreadCrumb({
          event: breadCrumbEvents.disputes.declineChargeBackButton
        });
      },
      hidden: actionButtonIsHidden,
      children: 'Decline',
      variant: 'primary',
      disabled: false
    }
  ];

  return (
    <>
      <TransactionDetails isLoading={chargebackDataIsLoading}>
        <TransactionDetails.Header
          status={switchTrxnMessage[chargebackData?.status ?? 'pending']?.name}
          statusBg={switchTrxnMessage[chargebackData?.status ?? 'pending']?.backgroundColor}
          statusColor={switchTrxnMessage[chargebackData?.status ?? 'pending']?.color}
          heading={chargebackData?.reference ?? ''}
          summaries={topLevelSummary}
          actionButtons={actionButtons}
        />

        <TransactionDetails.Section heading="Chargeback Details" summaries={chargebackDetails} />
        {chargebackData?.source?.type === 'bank_transfer' && (
          <TransactionDetails.Section heading="Account Number Generated for Payment" summaries={accountDetails} />
        )}
        <TransactionDetails.Section heading="Payer's Information" summaries={payersInfo} />
      </TransactionDetails>
      <DeclineChargeBackModal
        visible={modalState.isOpen}
        reference={chargebackData?.reference}
        refetchChargeBack={refetchChargebackData}
        currency={chargebackData?.currency}
        approve={modalState.isApproved}
        amount={Number(chargebackData?.amount)}
        close={() => setModalState({ isOpen: false })}
      />
    </>
  );
};

export default ChargebackDetails;
