import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import IssuanceChargebackModal from '+containers/Dashboard/TransactionDetails/components/IssuanceChargebackModal';
import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { ActionButtons } from '+containers/Dashboard/TransactionDetailsNew/types';
import { CardIssuanceServices } from '+services/card-issuance-services';
import useStore from '+store';
import { CurrencyType, ICardTransactionDetails, ISODateString } from '+types';
import { formatAmount, history, isObjectNotEmpty, switchTrxnMessage } from '+utils';

import { getIssuingPermissions } from '../utils';
import {
  generateChildrenGenerators,
  generateHolderInfoFrom,
  generateMoreDetailsFrom,
  generateSummaryFrom,
  generateSummaryGenerators
} from './TransactionDetailsUtils/CardTransaction';

const CardTransactionDetails = () => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const { cardAccess, walletBalance } = useStore.getState();
  const { canManageChargeback } = getIssuingPermissions();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const issuingBalance = walletBalance['USD']?.available_balance;

  const {
    data: responseData,
    isLoading,
    refetch
  } = CardIssuanceServices.useGetSingleIssuedCardTransaction({
    ref: id,
    errorMessage: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
    onSuccess: ({ data }) => {
      queryClient.setQueryData([`TRANSACTION_DETAILS_${id}`], data);
    },
    onError: () => {
      history.goBack();
    }
  });

  const data = responseData?.data;
  const summaryList = generateSummaryFrom(data as ICardTransactionDetails);
  const moreDetailsList = generateMoreDetailsFrom(data as ICardTransactionDetails);
  const holderInfoList = generateHolderInfoFrom(data as ICardTransactionDetails);

  const cardTransactionHasDisputes = () => {
    if ((data && data?.refunds?.length > 0) || isObjectNotEmpty(data?.chargeback)) return true;
    return false;
  };

  const hideEscalation = () => {
    if (data?.type !== 'card_transaction') return true;
    if (data?.card?.reserved && cardAccess.reserved.status !== 'active') return true;
    if (cardAccess.customer.status !== 'active') return true;
    if (!canManageChargeback) return true;
    return false;
  };

  const actionButtons: Array<ActionButtons> = [
    {
      children: (
        <>
          Escalate... <i className="os-icon os-icon-corner-up-right" />
        </>
      ),
      onClick: () => setModalIsOpen(true),
      disabled: (Boolean(data?.status) && data?.status !== 'success') || cardTransactionHasDisputes(),
      hidden: hideEscalation(),
      variant: 'secondary'
    }
  ];

  const generateDisputes = () => ({
    refunds: data?.refunds && data?.refunds.length > 0 ? data?.refunds : [],
    chargebacks: data?.chargeback ? [data?.chargeback] : []
  });

  return (
    <>
      <TransactionDetails isLoading={isLoading}>
        <TransactionDetails.Header
          heading={formatAmount(data?.amount)}
          currency={data?.currency}
          status={switchTrxnMessage[(data as ICardTransactionDetails)?.status]?.name}
          statusBg={switchTrxnMessage[(data as ICardTransactionDetails)?.status]?.backgroundColor}
          statusColor={switchTrxnMessage[(data as ICardTransactionDetails)?.status]?.color}
          actionButtons={actionButtons}
          summaries={summaryList}
        />

        <TransactionDetails.Section heading="More Transaction Details" summaries={moreDetailsList} />

        <TransactionDetails.Section heading="Cardholder's Information" summaries={holderInfoList} />

        <TransactionDetails.Section heading="Disputes">
          <TransactionDetails.Disputes
            tabs={['refunds', 'chargebacks']}
            onGenerateDisputes={cardTransactionHasDisputes() ? generateDisputes : null}
            onGenerateSummaryGenerators={generateSummaryGenerators}
            onGenerateChildrenGenerators={generateChildrenGenerators}
            currency={data?.currency as CurrencyType}
          />
        </TransactionDetails.Section>
      </TransactionDetails>

      <IssuanceChargebackModal
        modalIsVisible={modalIsOpen}
        transactionStatus={data?.status}
        transactionCurrency={data?.currency as string}
        transactionAmount={data?.amount as unknown as string}
        transactionDate={data?.date as ISODateString}
        issuingBalance={issuingBalance as number}
        reference={data?.reference as string}
        cardScheme={data?.card ? data?.card.brand : ('mastercard' as ICardTransactionDetails['card']['brand'])}
        refetchTransactionDetails={refetch}
        closeModal={() => setModalIsOpen(false)}
      />
    </>
  );
};

export default CardTransactionDetails;
