import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Field, FieldProps, Form, Formik } from 'formik';

import useFeedbackHandler from '+hooks/feedbackHandler';
import { AuthServices } from '+services/auth-services';
import { ConfirmPasswordValidation, DefaultRequiredValidation, history, PasswordValidation } from '+utils';

const ChangePasswordComponent = () => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState({
    isLoading: true,
    errors: {}
  });

  const handleSubmit = ({ currentPassword, confirmPassword }: { currentPassword: string; confirmPassword: string }) => {
    const data = {
      old_password: currentPassword,
      new_password: confirmPassword
    };
    setLoading(true);
    mutateChangePassword(data);
  };

  const { mutate: mutateChangePassword } = AuthServices.useChangePassword({
    onSuccess: () => {
      setState({
        ...state,
        isLoading: false,
        errors: {}
      });
      setLoading(false);
      feedbackInit({
        message: `You've updated your password`,
        type: 'success'
      });
      setTimeout(() => {
        closeFeedback();
        history.push('/dashboard/settings/security');
      }, 3000);
    },
    onError: () => {
      setLoading(false);
    }
  });

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="element-box">
          <h5 className="form-header">Change Password</h5>
          <div className="form-desc">
            You can create a secure password using at least 8 characters with a mix of numbers, uppercase and lowercase letters.
          </div>
          <Formik initialValues={{ currentPassword: '', password: '', confirmPassword: '' }} onSubmit={handleSubmit}>
            {({ errors, values, touched }) => {
              return (
                <Form>
                  <div className="col-sm-8">
                    <Field
                      name="currentPassword"
                      validate={() => {
                        return DefaultRequiredValidation(values.currentPassword, 'Current Password');
                      }}
                    >
                      {({ field }: FieldProps) => (
                        <div className="form-group row">
                          <label className="col-form-label col-sm-4" id="1" htmlFor="password">
                            Current Password
                          </label>
                          <div className="col-sm-8">
                            <input className="form-control" placeholder="Enter your current password" type="password" {...field} />
                            {errors.currentPassword ? (
                              <div className="input__errors">
                                <p>{errors.currentPassword}</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="password" validate={PasswordValidation}>
                      {({ field }: FieldProps) => (
                        <div className="form-group row">
                          <label className="col-form-label col-sm-4" id="1" htmlFor="password">
                            New Password
                          </label>
                          <div className="col-sm-8">
                            <input className="form-control" placeholder="Must be atleast 8 characters" type="password" {...field} />
                            {errors.password && touched.password ? (
                              <div className="input__errors">
                                <p>{errors.password}</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="confirmPassword" validate={ConfirmPasswordValidation}>
                      {({ field }: FieldProps) => (
                        <div className="form-group row">
                          <label className="col-form-label col-sm-4" htmlFor="confirmPassword">
                            Repeat New Password
                          </label>
                          <div className="col-sm-8">
                            <input className="form-control" placeholder="" type="password" {...field} />
                            {errors.confirmPassword && touched.confirmPassword ? (
                              <div className="input__errors">
                                <p>{errors.confirmPassword}</p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="form-buttons-w button-row">
                    <button className="btn btn-primary" type="submit" disabled={isLoading} style={{ width: 'fit-content' }}>
                      {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : null}
                      {isLoading ? <span style={{ marginLeft: '0.5rem' }}>Submitting...</span> : `Submit`}
                    </button>
                    <button className="btn btn-white" onClick={() => history.push('/dashboard/settings/security')} type="button">
                      Cancel
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ChangePasswordComponent);
