import { Switch } from 'react-router-dom';

import Scrollable from '+containers/Shared/Scrollable';
import { useRolesSettings } from '+hooks';

import ApiIntegrations from '../ApiIntegrations';
import Limits from '../Limits';
import PaymentPreference from '../PaymentPreferences';
import SettlementsSettings from '../Settlements';
import SettingsNavigation from './SettingsNavigation';

import '../index.scss';

const matchSettingsComponents = {
  settlement_setting: ['settings/settlements', null, SettlementsSettings],
  api_configuration: ['settings/api-integrations', null, ApiIntegrations],
  limits: ['settings/limits', null, Limits],
  bank_transfer_setting: ['settings/payment', null, PaymentPreference]
};

function ProductSettings() {
  const [routes, router] = useRolesSettings(matchSettingsComponents);

  return (
    <div className="os-tabs-w">
      <div className="os-tabs-controls os-tabs-complex settings-tab">
        <ul className="nav nav-tabs">
          <SettingsNavigation />
          <Scrollable>
            {routes.settings.map(route => (route.key.includes('settlements') ? route : ''))}
            {routes.settings.map(route => (route.key.includes('api-integrations') ? route : ''))}
            {routes.settings.map(route => (route.key.includes('limits') ? route : ''))}
            {routes.settings.map(route => (route.key.includes('payment') ? route : ''))}
          </Scrollable>
        </ul>
      </div>
      <div>
        <Switch>{router.map(route => route)}</Switch>
      </div>
    </div>
  );
}

export default ProductSettings;
