/* eslint-disable no-unused-vars */
import { keepPreviousData } from '@tanstack/react-query';

import { useFetch } from '+hooks';

export const AuditLogsService = {
  useFetchUserLogs: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    errorMessage,
    params
  }: {
    onSuccess?: () => void;
    onError?: (error: Error) => void;
    params?: { page: number; limit: number; dateFrom?: any; dateTo?: any };
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
  }) =>
    useFetch('/merchants/profile/audit-logs', {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      errorMessage,
      params: {
        page: params?.page,
        limit: params?.limit,
        ...(params?.dateFrom && params?.dateTo ? { date_from: params.dateFrom, date_to: params?.dateTo } : {})
      },
      useEnvironmentHandler: 'live',
      placeholderData: keepPreviousData
    })
};
