import { AllCardsResponseType } from '+types';
import { capitalizeRemovedash, getDateAndTime, history } from '+utils';

import mcLabel from '+assets/img/dashboard/mastercard-label.svg';
import visaLabel from '+assets/img/dashboard/visa-label.svg';

const ReservedCardsRow = ({ rowData }: { rowData: Array<AllCardsResponseType> }) => {
  const handleClick = (reference: string) => history.push(`/dashboard/issuing/reserved_cards/${reference}`);

  return rowData?.map?.(tx => {
    return (
      <div
        key={`transaction_${tx.reference}`}
        className="div-table --history-table --row cards-transactions-row"
        role="button"
        tabIndex={0}
        onClick={() => handleClick(tx.reference)}
        onKeyUp={e => {
          if (e.key === 'Enter') e.preventDefault();
          handleClick(tx.reference);
        }}
        data-testid="reserved-card-list-item"
      >
        <div className="--txn-status --column d-flex">
          <span className="body-row-header">Status:</span>
          <span className={`status-indicator ${tx.status === 'pre-authorized' ? 'warn' : tx.status}`} />
          <span>{tx.status === 'require_auth' ? 'Pending' : capitalizeRemovedash(tx.status)}</span>
        </div>
        <div className="--column">
          <span className="body-row-header">Unique Card ID:</span>
          <span className="font-weight-500 text-uppercase" style={{ color: '#007bff' }}>
            {tx.reference}
          </span>
        </div>
        <div className="--column">
          <span className="body-row-header">Cardholder:</span>
          <span className="value">{tx.holder_name}</span>
        </div>
        <div className="--column">
          <span className="body-row-header">Card Label:</span>
          <span className="value">{tx.label}</span>
        </div>
        <div className="--column">
          <span className="body-row-header">Card Number:</span>
          <span>
            <img className="card-scheme" alt={tx.brand} src={tx.brand === 'visa' ? visaLabel : mcLabel} />
            <span className="card-digits">**** {tx.last_four}</span>
          </span>
        </div>
        <div className="--column">
          <span className="body-row-header">Date Created:</span>
          <span className="grey-text">{getDateAndTime(tx.date_created)}</span>
        </div>
      </div>
    );
  });
};

export default ReservedCardsRow;
