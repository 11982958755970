/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import Flags from 'country-flag-icons/react/3x2';
import { Field, Form, Formik } from 'formik';

import useFeedbackHandler from '+hooks/feedbackHandler';
import useMerchantAuth from '+hooks/useMerchantAuth';
import { MerchantServices } from '+services/merchant-services';
import CustomInput from '+shared/CustomInput';
import useStore from '+store';
import { cleanInput, DefaultRequiredValidation, EmailValidation, formatPhoneNumber, PhoneValidation, URLValidation } from '+utils';

const BusinessProfile = () => {
  const defaultMerchant = useStore(store => store.defaultMerchant);

  const defaultBusiness = useStore(store => store.merchantKYC);
  const merchantInfo = defaultBusiness?.details?.contact;

  const businessProfile = defaultBusiness?.details?.business_profile;
  const [viewBusinessProfile, setViewBusinessProfile] = useState(true);
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const { bootstrap } = useMerchantAuth();
  const { isLoading, mutateAsync } = MerchantServices.useUpdateMerchantDetails({});

  const Flag = Flags[defaultMerchant?.country?.iso2];

  const renderBusinessProfileUpdate = () => {
    const handleSubmit = async data => {
      const updatedData = {
        business_description: data.description,
        support_email: data.email,
        phone: data.phone_number,
        website: data.website
      };
      try {
        await mutateAsync(updatedData);
        await bootstrap();
        setViewBusinessProfile(true);
        feedbackInit({
          message: 'Successfully updated your business details',
          type: 'success'
        });
        setTimeout(() => {
          closeFeedback();
        }, 3000);
      } catch (error) {
        setViewBusinessProfile(true);
        feedbackInit({
          message: `There's an issue updating your details. Try again later`,
          type: 'danger'
        });
      }
    };
    return (
      <div className="element-box business-profile-form">
        <Formik
          initialValues={{
            description: businessProfile?.business_description || '',
            email: merchantInfo?.support_email || '',
            website: businessProfile?.website || '',
            phone_number: formatPhoneNumber(merchantInfo?.support_phone) || '',
            country: defaultMerchant?.country?.name || '',
            business_name: defaultMerchant?.name || ''
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <div className="business-profile-form__left">
                  <div className="form-group">
                    <Field
                      name="business_name"
                      label="Business Name"
                      placeholder=""
                      type="text"
                      onChange={e => {
                        setFieldValue('business_name', cleanInput(e?.target?.value));
                      }}
                      component={CustomInput}
                      disabled
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="phone_number"
                      label="Phone Number"
                      placeholder=""
                      type="text"
                      validate={() => {
                        if (PhoneValidation(values.phone_number)) {
                          return PhoneValidation(values.phone_number);
                        }
                        return false;
                      }}
                      onChange={e => setFieldValue('phone_number', formatPhoneNumber(e?.target?.value))}
                      component={CustomInput}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="description"
                      validate={() => {
                        return DefaultRequiredValidation(values?.description, 'Business description');
                      }}
                    >
                      {({ field }) => (
                        <>
                          <label htmlFor="id"> Business Description</label>
                          <textarea
                            {...field}
                            className="form-control"
                            rows="3"
                            maxLength="150"
                            value={values.description || ''}
                            onChange={e => setFieldValue('description', cleanInput(e?.target?.value))}
                          />
                          {errors.description ? (
                            <div className="input__errors">
                              <p>{errors.description}</p>
                            </div>
                          ) : null}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="business-profile-form__right">
                  <div className="form-group business-website">
                    <Field
                      name="country"
                      label="Country"
                      placeholder=""
                      className="form-control"
                      component={CustomInput}
                      maxLength="150"
                      value={values.country || ''}
                      onChange={e => setFieldValue('country', cleanInput(e?.target?.value))}
                      disabled
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="email"
                      label="Support Email"
                      placeholder="E.g, support@yourcompany.com"
                      component={CustomInput}
                      validate={event => EmailValidation(event)}
                      maxLength="100"
                      onChange={e => setFieldValue('email', cleanInput(e?.target?.value))}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <Field
                      name="website"
                      label="Website"
                      placeholder="website"
                      component={CustomInput}
                      maxLength="150"
                      value={values?.website || ''}
                      validate={() => {
                        return DefaultRequiredValidation(values?.website, 'website') || URLValidation(values?.website);
                      }}
                      onChange={e => setFieldValue('website', cleanInput(e?.target?.value))}
                      className="form-control"
                    />
                  </div>

                  <div className="form-buttons-w business-profile-form-button">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={isLoading}
                      style={{ marginRight: '1rem', width: 'fit-content' }}
                    >
                      {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : null}
                      {isLoading ? <span style={{ marginLeft: '0.5rem' }}>Saving...</span> : `Save Changes`}
                    </button>
                    <button type="button" className="btn btn-grey" onClick={() => setViewBusinessProfile(true)}>
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  };

  return (
    <div className="business-profile__main">
      {viewBusinessProfile ? (
        <>
          <div className="business-profile__head">
            <span>
              <h5 className="compliance-heading">Business Profile</h5>
              <span className="description">Details about your company/organization as submitted during onboarding.</span>
            </span>

            <button type="button" className="btn btn-primary" onClick={() => setViewBusinessProfile(false)}>
              Update
            </button>
          </div>
          <div className="box mt-3">
            <div className="list-row" style={{ width: '50%' }}>
              <div>
                <span className="label">Business Name </span>
                <p className="value">{defaultMerchant?.name || 'nil'}</p>
              </div>
              <div>
                <span className="label">Phone Number</span>
                <p className="value">{merchantInfo?.support_phone || 'nil'}</p>
              </div>

              <span className="label">Business Description</span>
              <p className="value">{businessProfile?.business_description || 'nil'}</p>
            </div>

            <div className="list-row" style={{ marginBottom: '0.5rem' }}>
              <div>
                <span className="label">Country</span>
                <div className="country">
                  <p className="value">{defaultMerchant?.country?.name || 'nil'}</p>
                  {defaultMerchant?.country?.iso2 && <Flag />}
                </div>
              </div>
              <div>
                <span className="label"> Email</span>
                <p className="value">{defaultMerchant?.email || 'nil'}</p>
              </div>
              {defaultBusiness?.business_type !== 'individual' && (
                <div>
                  <span className="label">{defaultBusiness?.business_type === 'ngo' ? 'NGO Type' : 'Industry'}</span>
                  <p className="value">
                    {defaultBusiness?.business_type === 'ngo'
                      ? defaultBusiness?.details?.business_profile?.incorporated_trustee_category?.label
                      : defaultBusiness?.details?.business_profile?.industry?.label || 'nil'}
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        renderBusinessProfileUpdate()
      )}
    </div>
  );
};

export default BusinessProfile;
