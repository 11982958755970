import Copyable from '+containers/Dashboard/Shared/Copyable';
import { SummaryItemType } from '+containers/Dashboard/TransactionDetailsNew/types';
import { CardBrandType, ChargebackDetailsResponseType } from '+types';
import {
  capitalize,
  capitalizeRemovedash,
  daysfromToday,
  formatAmount,
  formatPAN,
  getDateAndTime,
  history,
  paymentMethodsMap,
  switchBank,
  switchCard,
  switchCurrency,
  switchStatus
} from '+utils';

export const getTopLevelSummaryFrom = (data?: ChargebackDetailsResponseType): SummaryItemType[] => {
  const checkedAmount = data?.amount ?? 0;
  const checkedApprovedAmount = data?.approved_amount ?? 0;
  const rejectedAmount = data?.status === 'pending' ? 0 : +checkedAmount - +checkedApprovedAmount;

  return [
    {
      label: 'Dispute Amount',
      value: `${data?.amount} ${data?.currency}`
    },
    {
      label: 'Accepted Amount',
      value: `${data?.approved_amount} ${data?.currency}`
    },
    {
      label: 'Rejected Amount',
      value: `${formatAmount(rejectedAmount)} ${data?.currency}`
    },
    {
      label: 'Date Contested',
      value: getDateAndTime(data?.created_at ?? new Date())
    },
    {
      label: 'Customer Name',
      value: data?.customer_name
    },
    {
      label: 'Transaction ID',
      value: (
        <>
          <Copyable text={data?.payment_reference ?? ''} />
          <br />
          <button
            type="button"
            className="btn"
            style={{ color: '#2376f3', gap: '8px' }}
            onClick={() => history.push(`/dashboard/pay-ins/${data?.payment_source_reference}`)}
            data-redirectto="original-transaction"
          >
            See original transaction <i className="os-icon os-icon-arrow-up-right" />
          </button>
        </>
      )
    }
  ];
};

export const getChargebackDetailsFrom = (data?: ChargebackDetailsResponseType): SummaryItemType[] => [
  {
    label: 'Status',
    value: (
      <>
        <span className={`status-pill smaller ${switchStatus(data?.status ?? 'pending')}`} />{' '}
        <span>{capitalizeRemovedash(data?.status ?? 'Not Available')}</span>
      </>
    )
  },
  {
    label: 'Currency Charged',
    value: switchCurrency[data?.currency as keyof typeof switchCurrency]
  },
  {
    label: 'Fee Bearer',
    value: 'Customer'
  },
  {
    label: 'Chargeback Due',
    value: daysfromToday(data?.deadline as string)
  },
  {
    label: 'Date Created',
    value: getDateAndTime(data?.created_at as string)
  },
  {
    label: 'Internal Reference',
    value: <Copyable text={data?.payment_source_reference || ''} />
  },
  {
    label: 'Reason for Chargeback',
    value: data?.reason || 'Not provided'
  }
];

export const getAccountDetailsFrom = (data?: ChargebackDetailsResponseType): SummaryItemType[] => [
  {
    label: 'Bank',
    value: (
      <>
        <img
          id="bank-icon"
          src={switchBank(data?.source?.details?.bank_slug || 'default')}
          alt="bank icon"
          style={{ marginLeft: data?.source?.details?.bank_name ? 0 : '' }}
        />
        <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
          {capitalize(data?.source?.virtual_bank_account?.bank_name || 'No Bank Information Available')}
        </span>
      </>
    )
  },
  {
    label: 'Account Number',
    value: data?.source?.virtual_bank_account?.account_number ?? 'Not Available'
  },
  {
    label: 'Account Name',
    value: capitalize(data?.source?.virtual_bank_account?.account_name || 'Not Available')
  },
  {
    label: 'Expired At',
    value: data?.source?.virtual_bank_account ? getDateAndTime(data?.source?.virtual_bank_account?.expiry ?? '') : 'Not Available'
  }
];

export const getPayersInfoFrom = (data?: ChargebackDetailsResponseType): SummaryItemType[] => {
  return [
    {
      label: 'Payment Method',
      value: paymentMethodsMap[data?.source.type as keyof typeof paymentMethodsMap]
    },
    {
      label: 'Bank',
      value: (
        <>
          <img
            id="bank-icon"
            src={switchBank(data?.source?.details?.bank_slug || 'default')}
            alt="bank icon"
            style={{ marginLeft: data?.source?.details?.bank_name ? 0 : '' }}
          />
          <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
            {capitalize(data?.source?.details?.bank_name || 'No Bank Information Available')}
          </span>
        </>
      ),
      hidden: !['bank_transfer', 'pay_with_bank'].includes(data?.source?.type || '')
    },
    {
      label: 'Account Number',
      value: data?.source?.details?.account_number ?? 'Not Available',
      hidden: !['bank_transfer', 'pay_with_bank', 'wallet'].includes(data?.source?.type || '')
    },
    {
      label: 'Account Name',
      value: data?.source?.details?.account_name ?? 'Not Available',
      hidden: data?.source.type !== 'bank_transfer'
    },
    {
      label: (
        <span>
          <img
            src={switchCard[data?.source?.details?.card_type as CardBrandType]}
            className="card-logo"
            alt="card-logo"
            style={{ width: '1.5rem', marginRight: '8px' }}
          />
          <span style={{ display: 'inline' }}>{capitalize(data?.source?.details?.card_type || '')}</span>
        </span>
      ),
      value: formatPAN(data?.source.details.masked_pan || ''),
      hidden: !(['bank_account', 'card', 'wallet', 'mobile_money'].includes(data?.source.type || '') && data?.source.details.masked_pan)
    },
    {
      label: 'Customer Name',
      value: 'Not Available',
      hidden: !['card', 'wallet', 'pay_with_bank', 'mobile_money'].includes(data?.source?.type || '')
    },
    {
      label: 'Email',
      value: 'Not Provided'
    }
  ];
};
