/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-use-before-define */
import { useEffect, useRef } from 'react';

import useStore from '+store';
import { actions } from '+utils';

const cacheTime = process.env.REACT_APP_CACHE_TIME || 15;
const MAX_INACTIVE_DURATION = Number(cacheTime) * 60 * 1000;

export default function useInactivityTracker() {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const didSessionTimeout = useRef(false);

  function logoutUserOut() {
    didSessionTimeout.current = true;
    actions.track('merchant logged out');
    delete window.visitedKorapay;
    useStore.setState({ sessionActive: false });
  }

  function resetActivity() {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      logoutUserOut();
      removeListeners();
    }, MAX_INACTIVE_DURATION);
  }

  function removeListeners() {
    events.forEach(evtName => {
      document.removeEventListener(evtName, resetActivity, true);
    });
  }

  const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];

  function addListeners() {
    events.forEach(evtName => {
      document.addEventListener(evtName, resetActivity, true);
    });
  }

  useEffect(() => {
    addListeners();
    resetActivity(); // initial run

    return () => {
      removeListeners();
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return {
    didSessionTimeout: didSessionTimeout.current
  };
}
