import { useState } from 'react';

import { getIssuingPermissions } from '+containers/Dashboard/Issuing/utils';
import { CardStatusType } from '+types';

import CardActionsModal from './CardActionsModal';

const IssuedCardAction = ({
  category,
  cardBalance,
  status
}: {
  category: 'reserved_cards' | 'customer_cards';
  cardBalance: number;
  status: CardStatusType;
}) => {
  const [cardAction, setCardAction] = useState<'fund_card' | 'withdraw_from_card' | null>(null);
  const { canFundReservedCard } = getIssuingPermissions();
  const canFundCard = canFundReservedCard && category === 'reserved_cards';

  return (
    <>
      <div className="card-item__cta-group">
        {canFundCard && (
          <button type="button" className="btn btn-primary" onClick={() => setCardAction('fund_card')}>
            <i className="os-icon os-icon-plus icon" />
            <span className="m-0">Fund Card</span>
          </button>
        )}

        <button
          type="button"
          disabled={['suspended', 'terminated', 'expired'].includes(status)}
          className="btn btn-grey"
          onClick={() => setCardAction('withdraw_from_card')}
        >
          <i className="os-icon os-icon-minus2 icon" />
          <span className="m-0">Withdraw Funds</span>
        </button>
      </div>

      {cardAction && <CardActionsModal cardBalance={cardBalance} action={cardAction} onCloseModal={() => setCardAction(null)} />}
    </>
  );
};

export default IssuedCardAction;
