import { useState } from 'react';
import clsx from 'clsx';

import Icon from '+containers/Dashboard/Shared/Icons';
import { useClickOutside } from '+hooks';
import { CardManagementType, CardStatusType, CurrencyType } from '+types';

import CardManagementModal from './CardManagementModal';

const IssuedCardManagement = ({ status, currency, reference }: { currency: CurrencyType; status: CardStatusType; reference: string }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [cardAction, setCardAction] = useState<CardManagementType | null>(null);
  const menuRef = useClickOutside<HTMLDivElement>(() => {
    setShowMenu(false);
  });

  const menuOptions: Array<{ label: string; action: CardManagementType; hidden?: boolean }> = [
    { label: 'reactivate card', action: 'reactivate_card', hidden: !['deactivated', 'suspended'].includes(status) },
    { label: 'suspend card', action: 'suspend_card', hidden: ['deactivated', 'suspended'].includes(status) },
    { label: 'terminate card', action: 'terminate_card', hidden: status === 'terminated' }
  ];

  return (
    <>
      <div className="position-relative" ref={menuRef}>
        <button type="button" className="card-item__manage-btn btn btn-secondary link-btn" onClick={() => setShowMenu(prev => !prev)}>
          <Icon name="gear" width={18} height={18} className={clsx(showMenu && 'rotate')} />
          <span>Manage Card</span>
          <Icon name="arrowDownRounded" width={8} height={8} className={clsx(showMenu && 'rotate')} />
        </button>

        {showMenu && (
          <ul className="card-item__manage-menu">
            {menuOptions.map(({ label, action, hidden }) => {
              if (!hidden)
                return (
                  <li key={action}>
                    <button
                      type="button"
                      onClick={() => {
                        setShowMenu(false);
                        setCardAction(action);
                      }}
                    >
                      <span className={clsx('capitalize', action === 'terminate_card' && 'danger-text')}>{label}</span>
                    </button>
                  </li>
                );
              return null;
            })}
          </ul>
        )}
      </div>

      {cardAction ? (
        <CardManagementModal cardId={reference} action={cardAction} onClose={() => setCardAction(null)} currency={currency || 'usd'} />
      ) : null}
    </>
  );
};

export default IssuedCardManagement;
