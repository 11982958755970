import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import dayjs from 'dayjs';

import { useCurrencies, useReducerState, useSearchQuery } from '+hooks';
import { backwardAmountInput, cleanInput, filteredOutObjectProperty } from '+utils';

import './index.scss';

const statusColors = {
  yellow: '#f1e9d3',
  red: '#e6c8ce',
  grey: '#d3d9e0',
  green: '#d5eace',
  lightBlue: '#EBEDFF'
};

const statusOption = {
  'pay-in': [
    { value: 'processing', label: 'Processing', color: '#f1e9d3' },
    { value: 'success', label: 'Success', color: '#d5eace' },
    { value: 'flagged', label: 'Flagged', color: '#d5eace' },
    { value: 'failed', label: 'Failed', color: '#e6c8ce' },
    { value: 'expired', label: 'Expired', color: '#d3d9e0' },
    { value: 'rejected', label: 'Rejected', color: '#d3d9e0' },
    { value: 'pre_authorized', label: 'Pre-Authorized', color: statusColors.lightBlue },
    { value: 'void_authorization', label: 'Voided (Auth)', color: statusColors.grey },
    { value: 'void_capture', label: 'Voided (Capture)', color: statusColors.grey }
  ],
  payout: [
    { value: 'processing', label: 'Processing', color: '#f1e9d3' },
    { value: 'success', label: 'Success', color: '#d5eace' },
    { value: 'failed', label: 'Failed', color: '#e6c8ce' },
    { value: 'abandoned', label: 'Abandoned', color: '#d3d9e0' },
    { value: 'pending', label: 'Pending', color: '#f1e9d3' },
    { value: 'reversed', label: 'Reversed', color: '#b0a6ff' }
  ],
  'bulk-payout': [
    { value: 'processing', label: 'Processing', color: '#f1e9d3' },
    { value: 'complete', label: 'Complete', color: '#d5eace' },
    { value: 'failed', label: 'Failed', color: '#e6c8ce' },
    { value: 'draft', label: 'Draft', color: '#a9afbc' },
    { value: 'pending', label: 'Pending', color: '#f1e9d3' },
    { value: 'cancelled', label: 'Cancelled', color: '#e6c8ce' }
  ]
};

const initialState = {
  keyword: '',
  type: '',
  amount: '',
  amountSubfilter: 'is_equal_to',
  reference: '',
  status: '',
  channel: '',
  payment_method: '',
  dateFrom: undefined,
  dateTo: undefined,
  batch_reference: '',
  isFilterVisible: false,
  sorterType: 'filter'
};

const showClearButtonKeys = Object.keys(initialState);

interface FilterComponentProps {
  handleFilterQuery?: (arg) => void;
  type: string;
  activeCurrency: string;
  isBatchReference?: boolean;
}

const FilterComponent = ({ handleFilterQuery, type, activeCurrency, isBatchReference = false }: FilterComponentProps) => {
  const animatedComponents = makeAnimated();
  const currency = useCurrencies();
  const searchQuery = useSearchQuery();
  const [state, setState] = useReducerState(initialState);
  const isFilterVisible = Boolean(searchQuery.value.isFilterVisible || true);

  useEffect(() => {
    $('.filter-box').slideToggle(200, 'swing');
  }, [state.isFilterVisible]);

  const getPaymentTypes = category => {
    const data = currency?.data?.data;
    const paymentType = data?.find(c => c.code === activeCurrency)?.meta_data?.payment_types?.[category];
    return paymentType?.map(p => (
      <option key={p.value} value={p.value}>
        {p.label}
      </option>
    ));
  };

  useEffect(() => {
    const filterKeys = Object.keys(searchQuery.value);
    const showKeys = showClearButtonKeys.some(key => filterKeys.indexOf(key) !== -1);
    if (filterKeys.length > 0 && showKeys) {
      setState({
        clearFilter: true,
        amount: searchQuery.value.amount || '',
        keyword: searchQuery.value.keyword || '',
        dateFrom: searchQuery.value.dateFrom,
        dateTo: searchQuery.value.dateTo,
        status: typeof searchQuery.value.status === 'string' ? [searchQuery.value.status] : searchQuery.value.status || [],
        type: searchQuery.value.type || '',
        amountSubfilter: searchQuery.value.amountSubfilter || 'is_equal_to',
        reference: searchQuery.value.reference || '',
        channel: searchQuery.value.channel || '',
        payment_method: searchQuery.value.payment_method || '',
        batch_reference: searchQuery.value.batch_reference || '',
        isFilterVisible: Boolean(searchQuery.value.isFilterVisible || false)
      });
    } else {
      setState({ ...initialState, clearFilter: false });
    }
  }, [searchQuery.value]);

  const handleClearFilter = () => {
    setState(initialState);
    searchQuery.clearAll(['tab', 'page', 'currency']);
  };

  const getPreviousKeywordValueAndLabel = item => {
    const previousKeyword = Object.keys(item).find(key => Object.keys(searchQuery.value).includes(key));
    if (previousKeyword && previousKeyword in item) {
      return { ...item, ...searchQuery.value, [previousKeyword]: item[previousKeyword] };
    }
    return { ...searchQuery.value, ...item };
  };

  const filterTransactions = async () => {
    const removeKeys = ['clearFilter', 'tab', 'isFilterVisible'];
    if (!state.amount) {
      removeKeys.push('amountSubfilter');
    }
    const values = filteredOutObjectProperty({ ...state, page: 1 }, removeKeys);
    const result = getPreviousKeywordValueAndLabel(values);

    const queries = {
      ...result,
      ...(isBatchReference && { batch_reference: values.reference })
    };
    return searchQuery.setQuery(queries, true);
  };

  return (
    <div className="filter-section ml-0" hidden={Boolean(!isFilterVisible)}>
      <div className="filter-box">
        <div className="filter-content">
          <form>
            <div className="filter-body">
              <div className="form-group form-group-flex filter-object filter-object-lg">
                <label htmlFor="status"> Status</label>
                <Select
                  id="status"
                  aria-label="status"
                  closeMenuOnSelect={false}
                  placeholder="Select a transaction status"
                  components={animatedComponents}
                  isMulti
                  value={statusOption[type]?.filter(status => state?.status?.includes(status.value))}
                  options={statusOption[type]}
                  styles={{
                    control: (styles, { isFocused }) => ({
                      ...styles,
                      fontSize: '13px',
                      fontWeight: 300,
                      border: isFocused ? null : '2px solid #dde2ec'
                    }),
                    option: (styles, { data, isFocused }) => {
                      return {
                        ...styles,
                        fontSize: '13px',
                        fontWeight: 400,
                        backgroundColor: isFocused ? data.color : null
                      };
                    },
                    multiValue: (styles, { data }) => {
                      return {
                        ...styles,
                        backgroundColor: data.color
                      };
                    },
                    dropdownIndicator: base => ({
                      ...base,
                      display: 'inline-block'
                    }),
                    clearIndicator: styles => ({
                      ...styles,
                      display: 'inline-block'
                    }),

                    indicatorSeparator: () => ({}),
                    container: base => ({
                      ...base,
                      height: '100%'
                    }),
                    valueContainer: base => ({
                      ...base,
                      display: 'flex',
                      height: '100%'
                    }),
                    singleValue: (base: any) => ({
                      ...base,
                      position: 'absolute',
                      top: 'unset',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: 'calc(100% - 40px)'
                    })
                  }}
                  onChange={value => setState({ status: !value || value.length === 0 ? [] : value.map(e => e.value) })}
                />
              </div>

              {type !== 'bulk-payout' && (
                <div className="form-group form-group-flex filter-object filter-object-sm">
                  <label htmlFor="type-fund"> {type === 'pay-in' ? 'Pay-in' : 'Payout'} Type</label>
                  <select
                    id="type-fund"
                    name="type-fund"
                    className="form-control"
                    onChange={e => setState({ type: e.target.value })}
                    value={state.type}
                  >
                    <option value="">All</option>
                    {type === 'pay-in' ? (
                      <>
                        <option value="payin">Pay-in</option>
                        <option value="deposit">Funding</option>
                      </>
                    ) : (
                      <>{getPaymentTypes('payout')}</>
                    )}
                  </select>
                </div>
              )}

              {type === 'pay-in' && (
                <div className="form-group form-group-flex filter-object filter-object-sm">
                  <label htmlFor="payment-method"> Payment Method</label>
                  <select
                    id="payment-method"
                    name="type"
                    className="form-control"
                    onChange={e => setState({ payment_method: e.target.value })}
                    value={state.payment_method}
                  >
                    <option value="">All</option>
                    <option value="bank_transfer">Bank Transfer</option>
                    <option value="card">Card Payment</option>
                    <option value="pay_with_bank">Pay with Bank (Direct debit)</option>
                    <option value="mobile_money">Mobile Money</option>
                  </select>
                </div>
              )}

              {type !== 'bulk-payout' && (
                <div className="form-group form-group-flex filter-object filter-object-md">
                  <label htmlFor="amount">
                    Amount <span className="label-note">({activeCurrency})</span>
                  </label>
                  <div className="input-group mb-2 mr-sm-2 mb-sm-0">
                    <select
                      id="amount"
                      className="form-control date-item md prepend"
                      style={{ background: '#e9ecef', maxWidth: '125px' }}
                      onChange={e => setState({ amountSubfilter: e.target.value })}
                      value={state.amountSubfilter}
                    >
                      <option value="is_equal_to">Exactly</option>
                      <option value="is_greater_than">Greater than</option>
                      <option value="is_less_than">Less than</option>
                    </select>
                    <input
                      className="form-control"
                      type="number"
                      placeholder="0.00"
                      maxLength="50"
                      onChange={e => {
                        setState({ amount: parseFloat(backwardAmountInput(e.target.value)).toFixed(2) });
                      }}
                      value={state.amount || ''}
                    />
                  </div>
                </div>
              )}

              <div className="form-group form-group-flex filter-object filter-object-sm">
                <label htmlFor="from">
                  From <span className="label-note">(Start date)</span>
                </label>
                <DatePicker
                  id="from"
                  selected={state.dateFrom ? new Date(state.dateFrom) : undefined}
                  dateFormat="dd-MM-yyyy"
                  onChange={date => setState({ dateFrom: dayjs(dayjs(date)).format('YYYY-MM-DD') })}
                  maxDate={new Date()}
                  placeholderText="Select a start date"
                  calendarClassName="custom-datepicker"
                />
              </div>
              <div className="form-group form-group-flex filter-object filter-object-sm">
                <label htmlFor="label">
                  To <span className="label-note">(End date)</span>
                </label>
                <DatePicker
                  id="label"
                  selected={state.dateTo ? new Date(state.dateTo) : undefined}
                  dateFormat="dd-MM-yyyy"
                  minDate={state.dateFrom ? new Date(state.dateFrom) : undefined}
                  maxDate={new Date()}
                  onChange={date => setState({ dateTo: dayjs(dayjs(date)).format('YYYY-MM-DD') })}
                  placeholderText="Select an end date"
                  calendarClassName="custom-datepicker"
                />
              </div>

              <div className="form-group form-group-flex filter-object filter-object-md">
                <label htmlFor="transaction-id">Transaction ID</label>
                <input
                  id="transaction-id"
                  name="transaction-id"
                  className="form-control"
                  type="search"
                  placeholder="Eg. KPY-C-8052042.."
                  value={state.reference}
                  onChange={e => setState({ reference: cleanInput(e.target.value) })}
                />
              </div>

              {type !== 'bulk-payout' && (
                <div className="form-group form-group-flex filter-object filter-object-sm">
                  <label htmlFor="type"> Payment Channel</label>
                  <select
                    id="type"
                    name="type"
                    className="form-control"
                    onChange={e => setState({ channel: e.target.value })}
                    value={state.channel}
                  >
                    <option value="">All</option>
                    <option value="web">Dashboard</option>
                    {type === 'pay-in' && <option value="modal">Checkout</option>}
                    <option value="api">API</option>
                  </select>
                </div>
              )}

              <div className="form-group form-group-flex filter-object filter-object-lg">
                <label htmlFor="keyword"> Keyword</label>
                <input
                  id="keyword"
                  type="search"
                  className="form-control"
                  placeholder="Search anything..."
                  value={state.keyword}
                  onChange={e => setState({ keyword: cleanInput(e.target.value) })}
                />
              </div>
              <div className="filter-options">
                <button className="btn btn-secondary" type="button" onClick={() => handleClearFilter()}>
                  Clear
                </button>
                <button className="btn btn-success" type="button" onClick={() => filterTransactions()}>
                  Apply Filter
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
