import { StateCreator } from 'zustand';

import { SignInResponseType } from '+types';

type AuthDetails = SignInResponseType;

type AuthSlice = {
  authDetails: AuthDetails;
  tempToken: string;
  sessionActive: boolean;
};

const createAuthSlice: StateCreator<AuthSlice> = set => ({
  authDetails: {} as AuthDetails,
  tempToken: '',
  sessionActive: false,
  baseURL: '',
  setBaseURL: (url: string) => set(state => ({ ...state, baseURL: url }))
});

export default createAuthSlice;
