/* eslint-disable no-unused-vars */
import { useEffect, useReducer } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import { useSearchQuery } from '+hooks';
import { cleanInput, filteredOutObjectProperty, filterOutEmptyValues, queryParams } from '+utils';

import arrowRight from '+assets/img/dashboard/arrow-right.svg';
import calendar from '+assets/img/dashboard/calendar.svg';
import search from '+assets/img/dashboard/search-thin.svg';

import 'react-datepicker/dist/react-datepicker.css';

const cardStatusOptions = {
  active: 'Active',
  suspended: 'Suspended',
  expired: 'Expired',
  terminated: 'Terminated'
} as const;

const txStatusOptions = {
  success: 'Success',
  processing: 'Processing',
  failed: 'Failed',
  pending: 'Pending',
  declined: 'Declined',
  reversed: 'Reversed'
} as const;

const cardBrandOptions = {
  mastercard: 'Mastercard',
  visa: 'Visa'
} as const;

const chargbackStatusOptions = {
  accepted: 'Accepted',
  declined: 'Declined',
  processing: 'Processing',
  pending: 'Pending',
  invalid: 'Invalid',
  pending_pre_arbitration: 'Pre-Arb Pending',
  processing_pre_arbitration: 'Pre-Arb Processing',
  invalid_pre_arbitration: 'Pre-Arb Invalid',
  accepted_pre_arbitration: 'Pre-Arb Accepted',
  declined_pre_arbitration: 'Pre-Arb Declined'
} as const;

const defaultFilterState = {
  'status[]': '',
  cardBrand: '',
  keyword: '',
  dateCreatedFrom: null,
  dateCreatedTo: null
};

interface FilterState {
  'status[]': string;
  cardBrand: string;
  keyword: string;
  dateCreatedFrom: string | null;
  dateCreatedTo: string | null;
}

const billingStatusOptions: {
  pending: string;
  partially_paid: string;
  fully_paid: string;
} = {
  pending: 'Pending',
  partially_paid: 'Partially Paid',
  fully_paid: 'Fully Paid'
};

type BillingStatusKeys = keyof typeof billingStatusOptions;

export function IssuanceFilter({ type = '', handleFilterQuery }: { type: string; handleFilterQuery: (arg: Record<string, any>) => void }) {
  const searchQuery = useSearchQuery<FilterState>();

  const [filterItems, setFilterItems] = useReducer(
    (prev: FilterState, next: Partial<FilterState>) => ({ ...prev, ...next }),
    defaultFilterState
  );

  const handleFilter = () => {
    const nonEmptyFilters = filterOutEmptyValues(filterItems);
    searchQuery.setQuery({ ...nonEmptyFilters });
    handleFilterQuery(nonEmptyFilters);
  };

  useEffect(() => {
    const params = filteredOutObjectProperty(searchQuery.value, [
      queryParams.currency,
      queryParams.page,
      queryParams.limit,
      queryParams.tab,
      queryParams.subtab,
      queryParams.sorterType
    ]);

    if (!Object.keys(params || {}).length) setFilterItems(defaultFilterState);
  }, [location.search]);

  switch (type) {
    case 'customer_cards':
    case 'reserved_cards':
      return (
        <section className="settlement-filter">
          <div className="settlement-filter__search-w filter-section">
            <div className="element-search-content filter-body w-100">
              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <select
                  name="type"
                  className="form-control"
                  onChange={e => setFilterItems({ 'status[]': e.target.value })}
                  value={filterItems?.['status[]'] ?? searchQuery.value?.['status[]']}
                  style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                >
                  <option value="">Card Status</option>
                  {(Object.keys(cardStatusOptions ?? {}) as Array<keyof typeof cardStatusOptions>).map(value => (
                    <option key={value} value={value}>
                      {cardStatusOptions[value]}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
                <img src={search} alt="search icon" aria-hidden />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by card ID, cardholder name, anything..."
                  value={filterItems.keyword ?? searchQuery.value?.keyword}
                  onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
                  style={{ border: '1.5px solid #EAF2FE' }}
                />
              </div>

              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <select
                  name="type"
                  className="form-control"
                  onChange={e => setFilterItems({ cardBrand: e.target.value })}
                  value={filterItems?.cardBrand ?? searchQuery.value?.cardBrand}
                  style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                >
                  <option value="">Card Brand</option>
                  {(Object.keys(cardBrandOptions ?? {}) as Array<keyof typeof cardBrandOptions>).map(option => (
                    <option key={option} value={option}>
                      {cardBrandOptions[option]}
                    </option>
                  ))}
                </select>
              </div>

              <button
                aria-label="filter transactions"
                type="button"
                className="settlement-filter__filter-button"
                onClick={() => handleFilter()}
                aria-controls="handleFilter"
              >
                <img src={arrowRight} alt="arrow right icon" aria-hidden />
              </button>
            </div>
          </div>
        </section>
      );

    case 'card_transactions':
      return (
        <section className="settlement-filter">
          <div className="settlement-filter__search-w filter-section">
            <div className="element-search-content filter-body w-100">
              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <select
                  name="type"
                  className="form-control"
                  onChange={e => setFilterItems({ 'status[]': e.target.value })}
                  value={filterItems?.['status[]'] ?? searchQuery.value?.['status[]']}
                  style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                >
                  <option value="">Status</option>
                  {(Object.keys(txStatusOptions ?? {}) as Array<keyof typeof txStatusOptions>).map(value => (
                    <option key={value} value={value}>
                      {txStatusOptions[value]}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
                <img src={search} alt="search icon" aria-hidden />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by transaction ID, customer name, anything..."
                  value={filterItems.keyword ?? searchQuery.value?.keyword}
                  onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
                  style={{ border: '1.5px solid #EAF2FE' }}
                />
              </div>

              <div
                className="form-group filter-object d-flex filter-object-sm w-auto"
                style={{
                  ['--calendar-image' as string]: `url("${calendar}")`,
                  minWidth: '300px'
                }}
              >
                <DatePicker
                  selected={filterItems.dateCreatedFrom ? new Date(filterItems.dateCreatedFrom) : undefined}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  className="form-control date-select pl-4 date-picker"
                  onChange={date => setFilterItems({ dateCreatedFrom: date && dayjs(date).format('YYYY-MM-DD') })}
                  maxDate={new Date()}
                  placeholderText="From"
                  calendarClassName="custom-datepicker"
                />
                <DatePicker
                  selected={filterItems.dateCreatedTo ? new Date(filterItems.dateCreatedTo) : undefined}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  className="form-control date-select pl-4 date-picker"
                  minDate={filterItems.dateCreatedFrom ? new Date(filterItems.dateCreatedFrom) : new Date()}
                  maxDate={new Date()}
                  onChange={date => setFilterItems({ dateCreatedTo: date && dayjs(date).format('YYYY-MM-DD') })}
                  placeholderText="To"
                  calendarClassName="custom-datepicker"
                />
              </div>

              <button
                aria-label="filter transactions"
                type="button"
                className="settlement-filter__filter-button"
                onClick={() => handleFilter()}
                aria-controls="handleFilter"
              >
                <img src={arrowRight} alt="arrow right icon" aria-hidden />
              </button>
            </div>
          </div>
        </section>
      );
    case 'chargebacks':
      return (
        <section className="settlement-filter">
          <div className="settlement-filter__search-w filter-section">
            <div className="element-search-content filter-body w-100">
              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <select
                  name="type"
                  className="form-control"
                  onChange={e => setFilterItems({ 'status[]': e.target.value })}
                  value={filterItems?.['status[]'] ?? searchQuery.value?.['status[]']}
                  style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                >
                  <option value="">Status</option>
                  {(Object.keys(chargbackStatusOptions ?? {}) as Array<keyof typeof chargbackStatusOptions>).map(value => (
                    <option key={value} value={value}>
                      {chargbackStatusOptions[value]}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
                <img src={search} alt="search icon" aria-hidden />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by transaction ID, customer name, anything..."
                  value={filterItems.keyword ?? searchQuery.value?.keyword}
                  onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
                  style={{ border: '1.5px solid #EAF2FE' }}
                />
              </div>

              <div
                className="form-group filter-object d-flex filter-object-sm w-auto"
                style={{
                  ['--calendar-image' as string]: `url("${calendar}")`,
                  minWidth: '300px'
                }}
              >
                <DatePicker
                  selected={filterItems.dateCreatedFrom ? new Date(filterItems.dateCreatedFrom) : undefined}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  className="form-control date-select pl-4 date-picker"
                  onChange={date => setFilterItems({ dateCreatedFrom: date && dayjs(date).format('YYYY-MM-DD') })}
                  maxDate={new Date()}
                  placeholderText="From"
                  calendarClassName="custom-datepicker"
                />
                <DatePicker
                  selected={filterItems.dateCreatedTo ? new Date(filterItems.dateCreatedTo) : undefined}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  className="form-control date-select pl-4 date-picker"
                  minDate={filterItems.dateCreatedFrom ? new Date(filterItems.dateCreatedFrom) : new Date()}
                  maxDate={new Date()}
                  onChange={date => setFilterItems({ dateCreatedTo: date && dayjs(date).format('YYYY-MM-DD') })}
                  placeholderText="To"
                  calendarClassName="custom-datepicker"
                />
              </div>

              <button
                aria-label="filter transactions"
                type="button"
                className="settlement-filter__filter-button"
                onClick={() => handleFilter()}
                aria-controls="handleFilter"
              >
                <img src={arrowRight} alt="arrow right icon" aria-hidden />
              </button>
            </div>
          </div>
        </section>
      );
    case 'billing_history':
      return (
        <section className="settlement-filter">
          <div className="settlement-filter__search-w filter-section">
            <div className="element-search-content filter-body w-100">
              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <select
                  name="type"
                  className="form-control"
                  onChange={e => setFilterItems({ 'status[]': e.target.value })}
                  value={filterItems?.['status[]'] ?? searchQuery.value?.['status[]']}
                  style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                >
                  <option value="">Status</option>
                  {Object.keys(billingStatusOptions).map(value => (
                    <option key={value} value={value}>
                      {billingStatusOptions[value as BillingStatusKeys]}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
                <img src={search} alt="search icon" aria-hidden />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by transaction ID, customer name, anything..."
                  value={filterItems.keyword}
                  onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
                  style={{ border: '1.5px solid #EAF2FE' }}
                />
              </div>

              <div
                className="form-group filter-object d-flex filter-object-sm w-auto"
                style={{
                  ['--calendar-image' as string]: `url("${calendar}")`,
                  minWidth: '300px'
                }}
              >
                <DatePicker
                  selected={filterItems.dateCreatedFrom ? new Date(filterItems.dateCreatedFrom) : undefined}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  className="form-control date-select pl-4 date-picker"
                  onChange={date => setFilterItems({ dateCreatedFrom: date && dayjs(date).format('YYYY-MM-DD') })}
                  maxDate={new Date()}
                  placeholderText="From"
                  calendarClassName="custom-datepicker"
                />
                <DatePicker
                  selected={filterItems.dateCreatedTo ? new Date(filterItems.dateCreatedTo) : undefined}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  className="form-control date-select pl-4 date-picker"
                  minDate={filterItems.dateCreatedFrom ? new Date(filterItems.dateCreatedFrom) : new Date()}
                  maxDate={new Date()}
                  onChange={date => setFilterItems({ dateCreatedTo: date && dayjs(date).format('YYYY-MM-DD') })}
                  placeholderText="To"
                  calendarClassName="custom-datepicker"
                />
              </div>

              <button
                aria-label="filter transactions"
                type="button"
                className="settlement-filter__filter-button"
                onClick={() => handleFilter()}
                aria-controls="handleFilter"
              >
                <img src={arrowRight} alt="arrow right icon" aria-hidden />
              </button>
            </div>
          </div>
        </section>
      );
    default:
      return null;
  }
}
