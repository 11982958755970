/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import Table from '+containers/Dashboard/Shared/Table';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import { CardIssuanceServices } from '+services/card-issuance-services';
import useStore from '+store';
import { CommonSearchQueriesType } from '+types';

import AccessRequestPostOnboarding from '../components/AccessRequestPostOnboarding';
import AccountSuspensionNotification from '../components/AccountSuspensionNotification';
import CardCreationModal from '../components/CardCreationModal';
import Filter from '../components/Filter/Filter';
import FundIssuingWalletModal from '../components/FundIssuingWalletModal';
import Summary from '../components/Summary';
import { computeTableTitle, exportActionOptions, getReservedCardsFilterProps, reservedCardsTableProps } from '../constants';
import { useFetchIssuanceTables, useFetchIssuingExport } from '../hooks';
import { getIssuingPermissions } from '../utils';
import ReservedCardsRow from './components/ReservedCardsRow';

const PRE_FUND_AMOUNT = Number(process.env.REACT_APP_MIN_RVC_PREFUND_AMOUNT || '10000');

const ReservedCardsIndex = () => {
  const { value: searchQueryValue, setQuery } = useSearchQuery<
    {
      tab: 'reserved_cards';
    } & CommonSearchQueriesType
  >();
  const limit = searchQueryValue?.limit ?? '10';
  const page = searchQueryValue?.page ?? '1';
  const tab = searchQueryValue?.tab || 'reserved_cards';
  const currency = searchQueryValue?.currency ?? 'USD';
  const { merchantEnv, cardAccess, walletBalance, cardFees } = useStore(useShallow(store => store));
  const [recordIsFiltered, setRecordIsFiltered] = useState(false);
  const { canViewCardList, canRequestCardAccess } = getIssuingPermissions();
  const exportAction = useFetchIssuingExport({ resourceType: tab, exportFn: exportActionOptions[tab] });
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [showCardCreation, setShowCardCreation] = useState(false);
  const [showWalletFunding, setShowWalletFunding] = useState(false);
  const balance = walletBalance?.[currency]?.available_balance;
  const cardCreationFee = cardFees?.reserved?.issuance;
  const balanceIsEnough = Number(balance ?? '0') >= cardCreationFee + PRE_FUND_AMOUNT;
  const filterProps = getReservedCardsFilterProps();

  const {
    tableData,
    isFetching: isFetchingTableData,
    refetch: refetchTableData
  } = useFetchIssuanceTables({
    page,
    limit,
    tab,
    queryValue: searchQueryValue
  });

  const { data } = CardIssuanceServices.useFetchIssuedCardsStats({
    params: { cardCategory: 'reserved' },
    refetchOnCloseFeedbackError: true
  });

  const reservedCardStats = data?.data || {};

  const { data: merchantName } = CardIssuanceServices.useFetchReservedCardholderName({
    refetchOnCloseFeedbackError: true,
    errorMessage: `There has been an error fetching the merchant name`,
    onError: () => {
      setTimeout(() => closeFeedback(), 5000);
    },
    enabled: cardAccess.reserved.status === 'active'
  });

  const { mutateAsync: requestAccess, isLoading: isRequestingAccess } = CardIssuanceServices.useRequestCardAccess({
    cardType: 'virtual',
    onSuccess: () => {
      closeFeedback(6500);
    },
    errorMessage: 'We were unable to complete your request. Please try again'
  });

  const handleRequestAccess = async () => {
    if (canRequestCardAccess) {
      await requestAccess({
        reactivation: true,
        currency: 'USD',
        category: 'reserved'
      });
    } else {
      feedbackInit({
        componentLevel: false,
        message: 'Sorry, you do not have the required permission(s) to perform this action.',
        type: 'danger'
      });
      closeFeedback(6500);
    }
  };

  const paging = tableData?.data?.paging;

  return (
    <div>
      <div className="issuing-heading">
        <div className="flex-grow-1 info-summary-container">
          <Summary label="Total Cards Issued" value={reservedCardStats?.total_cards} description="All cards issued for my business" />
          <Summary
            label="Active Cards"
            value={reservedCardStats?.total_active_cards}
            description="Cards being used to perform transactions"
            cards
          />
        </div>

        {cardAccess?.reserved?.status === 'active' ? (
          <div>
            <div className="page-action-btns">
              <button onClick={() => setShowCardCreation(true)} data-testid="page-action-btn" className="btn btn-primary p-2" type="button">
                <span className="os-icon os-icon-plus" />
                <span>New Virtual Card</span>
              </button>
            </div>
          </div>
        ) : null}
      </div>

      {cardAccess?.reserved?.status === 'suspended' && (
        <AccountSuspensionNotification
          isRequestingAccess={isRequestingAccess}
          onRequestAccess={handleRequestAccess}
          cardCategory="reserved"
        />
      )}

      {cardAccess?.reserved?.status === 'pending' && (
        <div className="fade-in p-3 my-3 text-center account-pending-notif">
          <span>Your reactivation request has been submitted. Our team will reach out to you soon on how to proceed.</span>
        </div>
      )}

      <Filter
        totalItems={paging?.total_items as number}
        title={computeTableTitle({
          singularTitle: 'card',
          pluralTitle: 'cards',
          filtered: recordIsFiltered,
          activeTab: tab as string,
          pageItemCount: paging?.total_items as number
        })}
        actions={filterProps.actions}
        exportType={filterProps.exportType as string}
        quickFilterType={tab}
        exportHeading={filterProps.exportHeading as string}
        exportDescription={filterProps.exportHeading as string}
        onChangeIsFiltered={setRecordIsFiltered}
        isFiltered={recordIsFiltered}
        exportAction={exportAction}
      />

      <section className="transaction_table_comp table-container">
        <Table
          tableClassName={`--history-table ${reservedCardsTableProps.tableClassName}`}
          headings={reservedCardsTableProps.headings}
          hasPagination
          borderedTable
          loading={isFetchingTableData}
          current={paging?.current}
          limitAction={value => setQuery({ limit: String(value) })}
          pageSize={paging?.page_size}
          actionFn={value => setQuery({ page: String(value) })}
          totalItems={paging?.total_items || 0}
          emptyStateHeading={reservedCardsTableProps.emptyStateHeading}
          emptyStateMessage={
            <>
              <span>{reservedCardsTableProps.emptyStateMessage}</span>
              {cardAccess?.reserved?.status === 'active' ? (
                <button type="button" className="new-card-btn" onClick={() => setShowCardCreation(true)}>
                  <i className="os-icon os-icon-plus" style={{ marginRight: '5px' }} />
                  New virtual card
                </button>
              ) : null}
            </>
          }
        >
          <ReservedCardsRow rowData={tableData?.data?.data} />
        </Table>
      </section>

      {showCardCreation ? (
        <CardCreationModal
          onClose={() => setShowCardCreation(false)}
          gotoStage={balanceIsEnough ? 'CREATE' : 'FUND'}
          merchantInfo={merchantName?.data}
          onFundBalance={() => setShowWalletFunding(true)}
        />
      ) : null}

      {showWalletFunding ? (
        <FundIssuingWalletModal balances={walletBalance} currency={currency} onClose={() => setShowWalletFunding(false)} />
      ) : null}
    </div>
  );
};

const ReservedCards = () => {
  const { cardAccess } = useStore(useShallow(store => ({ cardAccess: store.cardAccess })));

  if (!['active', 'pending', 'suspended'].includes(cardAccess.reserved.status)) {
    return <AccessRequestPostOnboarding activeTab="reserved_cards" accessStatus={cardAccess.reserved.status} />;
  }

  return <ReservedCardsIndex />;
};

export default ReservedCards;
