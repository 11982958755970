import { useParams } from 'react-router-dom';

import Table from '+containers/Dashboard/Shared/Table';
import { useSearchQuery } from '+hooks';
import { TransactionServices } from '+services/transaction-services';
import { capitalize, filteredOutObjectProperty, formatAmount, getDate, getTime, history, switchStatus } from '+utils';

import VirtualAccountsFilter from '../Shared/VirtualAccountsFilter';

import Warning from '+assets/img/dashboard/warning.svg';

export default function Transactions() {
  const searchQuery = useSearchQuery();
  const paginationPage = searchQuery.value.page || '1';
  const status = searchQuery.value.status || '';
  const limit = searchQuery.value.limit || '10';
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, ['page', 'tab', 'limit', 'kycinfoTab', 'eventTab', 'accountHolderTab', 'status'])
  };

  const { id } = useParams();
  const { data: vbaTrxns, isFetching } = TransactionServices.useGetAccountHoldersTransactions({
    params: {
      page: paginationPage,
      limit,
      ...sortingParams,
      ...(status && { status: [status] })
    },
    errorMessage: "There has been an error getting this account's transactions"
  });

  const paging = vbaTrxns?.paging || {};
  const transactions = vbaTrxns?.data || [];

  const accountTransactions = () => {
    return (
      <>
        {transactions.map(each => {
          return (
            <div
              key={`settlement_${each.reference}`}
              className="div-table --column-5 --vba-trxns --row"
              onClick={() => history.push(`/dashboard/pay-ins/${each?.reference}`)}
              onKeyDown={() => history.push(`/dashboard/pay-ins/${each?.reference}`)}
              role="button"
              tabIndex={0}
            >
              <div>
                <span className="body-row-header">Status:</span>
                <span className={`status-pill smaller ${switchStatus(each?.status)}`} />
                <span>{capitalize(each?.status)}</span>
                {each?.status === 'flagged' && (
                  <span style={{ marginLeft: '10px' }}>
                    <img width="15px" height="15px" src={Warning} alt="warning" />
                  </span>
                )}
              </div>
              <div>
                <span className="body-row-header">Transaction ID:</span>
                <span style={{ color: '#007bff', fontWeight: '500', textTransform: 'uppercase' }}>{each.payment?.reference}</span>
              </div>

              <div>
                <span className="body-row-header">Transaction Date:</span>
                <span>{getDate(each.created_at)}</span>
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(each.created_at)}
                </span>
              </div>
              <div>
                <span className="body-row-header">Amount:</span>
                <span>
                  <span style={{ fontWeight: '600' }}>{formatAmount(each.amount)} </span>
                  {each?.currency || 'NGN'}
                </span>
              </div>
            </div>
          );
        })}
      </>
    );
  };
  return (
    <div>
      {' '}
      <div className="content-details-body mt-4" style={{ border: '0' }}>
        <VirtualAccountsFilter type="transactions" Tab="Transactions" totalCount={paging?.total_items} />
        <Table
          tableClassName=" --column-5 --vba-trxns"
          headings={[
            {
              value: 'Status'
            },
            {
              value: 'Transaction ID'
            },
            {
              value: 'Transaction Date'
            },
            {
              value: 'Amount'
            }
          ]}
          hasPagination
          loading={isFetching}
          current={paginationPage}
          totalItems={paging?.total_items || 0}
          pageSize={paging?.page_size || 0}
          actionFn={current => searchQuery.setQuery({ page: current })}
          EmptyStateMessage={<p style={{ maxWidth: '200px', marginTop: '-20px' }}>There are no transactions for this account.</p>}
          background="#f5f6f6"
          tableWrapperClassName="vba-container sub"
        >
          {accountTransactions()}
        </Table>
      </div>
    </div>
  );
}
