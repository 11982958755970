import { useParams } from 'react-router-dom';

import Table from '+containers/Dashboard/Shared/Table';
import TabSwitch from '+containers/Dashboard/Shared/TabSwitch';
import useFeedbackHandler from '+hooks/feedbackHandler';
import useSearchQuery from '+hooks/useSearchQuery';
import { VirtualBankServices } from '+services/virtual-bank-services';
import { durationMonth, durationToday, durationWeek, getDate, getTime } from '+utils';

export default function Events() {
  const { id } = useParams<{ id: string }>();
  const searchQuery = useSearchQuery();
  const tabs = ['all_time', 'last_24_hours', 'last_7_days', 'last_30_days'];
  const paginationPage = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '25';
  const activeTab = searchQuery.value.eventTab || 'all_time';
  const { feedbackInit } = useFeedbackHandler();

  const getTimeFromEvent = (eventTab: string): (string | null)[] => {
    switch (eventTab) {
      case 'all_time':
        return [null, null];
      case 'last_24_hours':
        return durationToday();
      case 'last_7_days':
        return durationWeek();
      case 'last_30_days':
        return durationMonth();
      default:
        return [null, null];
    }
  };

  const { data, isFetching } = VirtualBankServices.useGetVirtualBankAccountHolderEvents({
    params: { page: paginationPage, limit, accountReference: id },
    errorMessage: 'There has been an error getting this account holders events log',
    refetchOnCloseFeedbackError: true
  });

  const paging = data?.paging;

  const renderEvents = () => {
    return (
      <>
        <table className="table table-lightborder audit-log-table">
          <tbody>
            {data?.data.map(event => (
              <tr key={event.reference}>
                <td style={{ width: '20%' }}>
                  {getDate(event.created_at)}&emsp;<span style={{ color: '#A9AFBC' }}>{getTime(event.created_at)}</span>
                </td>
                <td style={{ width: '45%' }}>
                  <span style={{ fontWeight: 500 }}>{event.event_type}</span> : {event.event_action}
                </td>
                <td style={{ width: '35%' }} className="text-right color-blue">
                  {event.reference}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {data?.data.map(event => (
          <div key={event.reference} className="div-table --body audit-log-table-mobile">
            <div className="--txn-status">
              <span className="body-row-header">Timestamp:</span>
              <span>{`${getDate(event.createdAt)}, ${getTime(event.createdAt)}`}</span>
            </div>
            <div>
              <span className="body-row-header">Event:</span>
              <span>
                <span className="font-weight-bold">{event.event_type}</span> : {event.event_action}
              </span>
            </div>
            <div>
              <span className="body-row-header">Event ID:</span>
              <span>{event.reference}</span>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="element-box audit__element">
      <div className="os-tabs-w mb-3">
        <div className="os-tabs-controls">
          <ul className="nav">
            <li className="nav-item">
              <h4 className="element-header audit__header d-flex align-items-center">Events</h4>
            </li>
          </ul>
          <ul className="nav nav-pills smaller d-md-flex">
            <TabSwitch
              options={tabs}
              activeTab={activeTab}
              setTab={value => searchQuery.setQuery({ eventTab: value, page: '1' })}
              className="ml-3"
              id="audit__logs"
            />
          </ul>
        </div>
        <div className="tab-content">
          <div className="tab-pane active" id="tab_activity">
            <Table
              hasPagination
              header={false}
              headings={[
                {
                  value: 'Timestamp',
                  className: 'lighter',
                  style: { width: '20%' }
                },
                {
                  value: 'Event',
                  className: 'lighter',
                  style: { width: '60%' }
                },
                {
                  value: 'Event ID',
                  className: 'text-right lighter',
                  style: { width: '20%' }
                }
              ]}
              totalItems={paging?.total_items || 0}
              pageSize={paging?.page_size || 0}
              loading={isFetching}
              current={paginationPage}
              actionFn={value => searchQuery.setQuery({ page: value })}
              annotation="actions"
              EmptyStateHeading="There are no event logs yet"
              EmptyStateMessage="You do not have any event logs at the moment."
              tableWrapperClassName="table-responsive"
            >
              {renderEvents()}
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
