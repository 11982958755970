import React from 'react';

import { cardColorOptions } from './cardCreationModalData';

import mcLogo from '+assets/img/dashboard/mastercard.svg';
import visaLogo from '+assets/img/dashboard/visa.svg';

import './index.scss';

type CardThumbnailProps = {
  cardDetails: {
    status?: string;
    scheme: string;
    holderName: string;
    expiresAt: string;
    lastFourDigits: string | React.JSX;
    label?: string;
  };
  variant?: 'withStatus' | 'withLabel';
  color?: string;
};

const getCardColorProp = color => {
  const targetObj = cardColorOptions.find(({ label }) => label === color) || cardColorOptions[2];
  return targetObj;
};

export default function CardThumbnail({ cardDetails, variant = 'withStatus', color }: CardThumbnailProps) {
  const { status = '', scheme = '', holderName = '', expiresAt = '', lastFourDigits = '', label = '' } = cardDetails || {};

  const schemeLogoOptions: Record<string, string> = {
    mastercard: mcLogo,
    visa: visaLogo
  };

  const targetColorProps = getCardColorProp(color);

  return (
    <div className="card-thumbnail__comp">
      <div className="card-wrapper" style={{ backgroundImage: `linear-gradient(${targetColorProps.value})` }}>
        <div className="top">
          {variant === 'withStatus' && <div className={`status-indicator lg ${status} capitalize status`}>{status}</div>}

          {variant === 'withLabel' && <div className="capitalize text-sm text-sbold">{label}</div>}

          <div className="scheme-logo">
            <img alt={scheme} src={schemeLogoOptions[scheme]} />
          </div>
        </div>
        <div className="middle">
          <span className="hidden-char">••••</span>
          <span className="hidden-char">••••</span>
          <span className="hidden-char">••••</span>
          <span className="digits">{lastFourDigits}</span>
        </div>
        <div className="bottom">
          <div className="card-info-unit">
            <p className="label">expires</p>
            <p className="value">{expiresAt}</p>
          </div>
          <div className="card-info-unit">
            <p className="label">Cardholder</p>
            <p className="value">{holderName}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
