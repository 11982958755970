import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 10,
      refetchOnWindowFocus: false,
      retry: (failedCount: number, error: any): boolean => {
        const maxRetryCount = 2;
        return failedCount < maxRetryCount && !(error?.response?.status >= 400 && error?.response?.status < 500);
      }
    }
  }
});

export default queryClient;
