/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { useReducer, useState } from 'react';

import useFeedbackHandler from '+hooks/feedbackHandler';
import { ChargeBackServices } from '+services/chargeback-services';
import { backwardAmountInput, cleanInput, formatAmount, logBreadCrumb } from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import FileUpload from './FileUpload';
import Modal from './Modal';
import Radio from './RadioButton';
import ToolTip from './Tooltip';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

import './DeclineChargeBack.scss';

interface IDeclineChargeBackProps {
  close: () => void;
  amount: number;
  currency?: string;
  refetchChargeBack: () => void;
  visible?: boolean;
  approve?: any;
  reference?: any;
}

export default function DeclineChargeBack({
  close,
  visible,
  currency,
  amount,
  approve,
  reference,
  refetchChargeBack
}: IDeclineChargeBackProps) {
  const { feedbackInit } = useFeedbackHandler();
  const [type, setType] = useState('');
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      reason: '',
      minAmount: 100,
      approved_amount: null,
      loading: false,
      evidence: null,
      status: 'declined'
    }
  );

  const setDisabled = () => {
    if (approve) {
      if (state.loading) {
        return true;
      }
      return false;
    }
    if (
      state.status &&
      state.reason &&
      state.evidence &&
      (state.status === 'partial' ? +state.approved_amount >= +state.minAmount && +state.approved_amount <= +amount : true) &&
      !state.loading
    ) {
      return false;
    }
    return true;
  };

  // Accept or Decline Chargeback

  const updateChargeback = ChargeBackServices.useUpdateChargeBack({
    ref: reference,
    errorMessage: 'We are sorry, we could not update your chargeback right now.',
    bannerLevel: true,
    onSuccess: () => {
      refetchChargeBack();
    },
    onError: () => {
      updateState({
        loading: false
      });
    }
  });

  const defaultChargeBackContent = () => {
    return (
      <>
        <div className="element-box chargeback-note p-1 mb-0">
          <div>
            <p>
              You can partially refund this chargeback by entering a new chargeback amount or decline the entire amount with supporting
              documents to validate your claim.
            </p>
          </div>
        </div>

        <div className="form-group pt-2">
          <div className="my-2 radio-div pl-1">
            <Radio
              checked={state.status === 'declined'}
              onChange={() => {
                updateState({ status: 'declined' });
              }}
              label="Full Decline"
            />
          </div>

          <div className="my-2 radio-div pl-1">
            <Radio
              checked={state.status === 'partial'}
              onChange={() => {
                updateState({ status: 'partial' });
              }}
              label="Partial Decline"
            />
          </div>
        </div>

        <div className="form-group">
          <label className="withdraw-label">
            <span className="dark">Dispute Amount (NGN)</span>
          </label>
          <input className="form-control" value={amount || 0.0} disabled />
        </div>

        {state.status === 'partial' && (
          <div className="form-group">
            <label htmlFor="refund-amount" className="withdraw-label">
              <span className="dark">
                Accepted Amount (NGN)
                <ToolTip
                  image={InfoIcon}
                  type="accepted_amount"
                  message={<p>Accepted chargeback amount is the amount you are willing to return to the customer.</p>}
                />
              </span>
            </label>
            <input
              rows="2"
              type="number"
              maxLength="150"
              value={state.approved_amount}
              className="form-control"
              name="amount"
              placeholder="Enter proposed amount "
              onChange={e => {
                const formattedInput = backwardAmountInput(e.target.value);
                updateState({ approved_amount: formattedInput });
              }}
              onWheel={e => e.target.blur()}
              onKeyDown={e => ['e', 'E', '+', '-', 'ArrowDown', 'ArrowUp'].includes(e.key) && e.preventDefault()}
            />
            <label htmlFor="amount" className="withdraw-label mt-2 small">
              <span>
                Minimum Amount:{' '}
                <span className={`dark ${parseFloat(state.approved_amount) < state.minAmount && 'red'}`} id="min">
                  {currency} {formatAmount(state?.minAmount)}
                </span>
              </span>
              <span>
                Maximum Amount:{' '}
                <span className={`dark ${parseFloat(state.approved_amount) > amount && 'red'}`}>
                  {currency} {formatAmount(amount)}
                </span>
              </span>
            </label>
          </div>
        )}

        <div className="form-group">
          <label htmlFor="refund-amount" className="withdraw-label">
            <span className="dark">Reason</span>
          </label>
          <textarea
            maxLength="200"
            autoComplete="off"
            type="text"
            className="form-control"
            name="amount"
            value={state.reason}
            placeholder="Add a reason for declining this chargeback"
            onChange={e => {
              const formattedInput = cleanInput(e.target.value);
              updateState({ reason: formattedInput });
            }}
          />
        </div>

        <div className="form-group my-2 pt-3 chargeback-note">
          <label htmlFor="refund-amount" className="withdraw-label">
            <span className="dark">Submit documents to validate your claim</span>
          </label>

          <div className="chargeback-note mb-0">
            <div>
              <p>
                Add all supporting documents in one pdf file. preferably in A4 size for best result. After submitting, you will not be able
                to change your defense.
              </p>
            </div>
          </div>

          <FileUpload
            onUploadComplete={data => {
              updateState({ evidence: data?.data[0]?.path });
            }}
            fileUploaded={state?.evidence}
            onRemoveUploadedFile={() => updateState({ evidence: null })}
            fileUploadMessage={<p>Max file size 20MB</p>}
            fileAllowed=".pdf"
          />
        </div>
      </>
    );
  };

  const switchChargeBackModal = kind => {
    let content;
    switch (kind) {
      case 'confirm':
        content = {
          heading: 'Decline Chargeback',
          description: `Submit your defense for our team to process your claim, after which you would get a response. `,
          justify: 'space-between',
          firstButtonText: 'Go Back',
          size: 'sm',
          firstButtonAction: () => setType('init'),
          secondButtonText: 'Submit',
          secondButtonAction: async () => {
            updateState({
              loading: true
            });
            const chargebackDetails = {
              status: state.status,
              reason: state.reason,
              evidence: state.evidence,
              approved_amount: state.status === 'partial' ? state.approved_amount : undefined
            };
            logBreadCrumb({
              event: breadCrumbEvents.disputes.attemptDeclineChargebackButton,
              data: chargebackDetails
            });
            await updateChargeback.mutateAsync(chargebackDetails);
          },

          completedHeading: 'Defense Submitted',
          completedDescription: 'We have received your chargeback defense. You will get a response soon.'
        };
        break;
      default:
        content = approve
          ? {
              heading: 'Accept Chargeback',
              description: `By clicking Accept, you agree to automatically lose this chargeback and return ${formatAmount(
                amount
              )} ${currency} to the consumer. This action cannot be reversed.`,
              justify: 'space-between',
              firstButtonText: 'Go Back',
              size: 'sm',
              secondButtonText: 'Accept',
              secondButtonAction: async () => {
                updateState({
                  loading: true
                });

                logBreadCrumb({
                  event: breadCrumbEvents.disputes.attemptAcceptChargebackButton,
                  data: { reference }
                });
                const chargebackDetails = {
                  status: 'accepted'
                };
                await updateChargeback.mutateAsync(chargebackDetails);
              },

              completedHeading: 'Chargeback Accepted',
              completedDescription: 'The chargeback has been successfully resolved.'
            }
          : {
              heading: `Decline Chargeback`,
              content: defaultChargeBackContent(),
              secondButtonText: 'Proceed',
              secondButtonAction: () => {
                setType('confirm');
              }
            };
        break;
    }

    return {
      size: 'md',
      close: () => {
        setType('init');
        updateState({
          status: 'declined',
          loading: false,
          reason: '',
          evidence: null,
          approved_amount: ''
        });
        close();
      },
      secondButtonDisable: setDisabled(),
      secondButtonActionIsTerminal: kind === 'confirm' || approve,
      ...content
    };
  };

  return <section>{visible && <Modal {...switchChargeBackModal(type)} />}</section>;
}
