import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { ICreateTipsAndGuideSlice } from '+types/tips-and-guide';

import createTipsAndGuideSlice from './setTipsAndGuide';
import { storageLocal } from './storage';

const useLocalStore = create<ICreateTipsAndGuideSlice>()(
  devtools(
    persist(
      (set, get, api) => {
        return {
          ...createTipsAndGuideSlice(set, get, api)
        };
      },
      {
        name: 'kora-app-data',
        storage: createJSONStorage(() => storageLocal)
      }
    ),
    { enabled: process.env.NODE_ENV !== 'production' }
  )
);

export default useLocalStore;
