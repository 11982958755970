/* eslint-disable no-unused-vars */
import { QueryKey } from '@tanstack/react-query';

import { IdentityServiceAccessType } from '+containers/Dashboard/Identity/types/types';
import { useFetch, useSend } from '+hooks';

export const IdentityServices = {
  useGetIdentityAccessConfig: ({
    enabled,
    onError,
    bannerLevel,
    onSuccess,
    refetchOnCloseFeedbackError
  }: {
    enabled?: boolean;
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/identities/access/requests/info`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useRequestIdentityAccess: ({
    onSuccess,
    bannerLevel,
    showErrorMessage,
    errorMessage,
    onError,
    enabled,
    hasFeedbackTimeout
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    errorMessage?: string;
    onError?: (e: Error) => void;
    enabled?: boolean;
    hasFeedbackTimeout?: boolean;
  }) =>
    useSend({
      url: '/identities/access',
      method: 'post',
      onSuccess,
      showErrorMessage,
      errorMessage,
      hasFeedbackTimeout,
      errorMessageBannerLevel: bannerLevel,
      onError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetIdentityAccess: ({
    enabled,
    onError,
    queryKey,
    bannerLevel,
    onSuccess,
    refetchOnCloseFeedbackError
  }: {
    enabled?: boolean;
    queryKey: QueryKey;
    onSuccess?: (data: { data: IdentityServiceAccessType }) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/identities/access`, {
      queryKey,
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetAllVerifications: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: {
      page: any;
      limit: any;
      toExport?: any;
      exportFields?: any;
      exportFormat?: any;
    };
    errorMessage?: string;
  }) => {
    const { page, limit, toExport, exportFormat, exportFields, ...filterParams } = params;
    const formattedParams = { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams };
    return useFetch(`/identities/verifications`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...(toExport && { requestConfig: { responseType: 'blob' } })
    });
  },

  useGetVerificationBillingHistory: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: {
      page: any;
      limit: any;
      toExport?: any;
      exportFields?: any;
      exportFormat?: any;
    };
    errorMessage?: string;
  }) => {
    const { page, limit, toExport, exportFormat, exportFields, ...filterParams } = params;
    const formattedParams = { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams };
    return useFetch(`/identities/billings`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...(toExport && { requestConfig: { responseType: 'blob' } })
    });
  },

  useGetSingleVerification: ({
    onError,
    bannerLevel,
    onSuccess,
    errorMessage,
    id,
    refetchOnCloseFeedbackError
  }: {
    enabled?: boolean;
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
    id: string;
  }) =>
    useFetch(`/identities/verifications/${id}`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      errorMessage,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetIdentityEventSummary: ({
    onError,
    bannerLevel,
    onSuccess,
    errorMessage,
    refetchOnCloseFeedbackError,
    params
  }: {
    enabled?: boolean;
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: {
      country?: string;
      startDate?: string;
      endDate?: string;
    };
    errorMessage?: string;
  }) => {
    const { country, startDate, endDate } = params;
    const summaryParams = {
      ...(country && { country }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate })
    };
    return useFetch(`/identities/verifications/statistics`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      params: summaryParams,
      errorMessage,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    });
  }
};
