/* eslint-disable camelcase */
import { Link, Route, Switch } from 'react-router-dom';

import { useSearchQuery, useSurveyTrigger } from '+hooks';
import { CheckoutServices } from '+services/checkout-service';
import useStore from '+store';
import { capitalize, getDate, getTime, history, logBreadCrumb } from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import Copyable from '../Shared/Copyable';
import CurrencyTabs from '../Shared/CurrencyTabs';
import Table from '../Shared/Table';
import CreatePaymentLinkComponent from './CreatePaymentLink';
import PaymentLinkDetailsComponent from './PaymentLinkDetails';

import './index.scss';

const PaymentLinkHome = () => {
  const searchQuery = useSearchQuery();
  const tabs: Record<string, string | null> = { All: null, Active: 'active', Inactive: 'inactive', Expired: 'expired' };
  const paginationPage = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '25';
  const activeTab = searchQuery.value.tab || 'All';
  const activeCurrency = searchQuery.value.currency || 'NGN';
  const newPaymentLink = searchQuery.value['new-payment-link'];
  const MERCHANT_ENV = useStore(store => store.merchantEnv);
  const availableCurrencies = useStore(state => state.availableCurrencies);

  const {
    data: paymentLinks,
    isLoading,
    meta
  } = CheckoutServices.useGetAllPaymentLinks({
    params: {
      page: paginationPage,
      limit,
      query: tabs[activeTab],
      currency: activeCurrency
    },
    errorMessage: `There has been an error getting ${activeTab === 'All' ? 'your' : activeTab} ${activeCurrency} your payment links.`,
    refetchOnCloseFeedbackError: true
  });
  const paging = meta?.paging;

  useSurveyTrigger({
    surveyId: +(process.env.REACT_APP_ATLAS_QUERY_PN || '0'),
    id: 'payment_links',
    trigger: newPaymentLink === 'true',
    count: paging?.total_items,
    milestones: [5, 100, 1000]
  });

  const Links = () => {
    return (
      <>
        {paymentLinks?.data?.data.map(each => (
          <div key={`transaction_${each.reference}`} className="div-table --checkout-table --row">
            <div>
              <span className="body-row-header">Status:</span>
              <span className={`status-pill smaller ${each.status === 'active' && 'green'} ${each.status === 'inactive' && 'red'}`} />
              <span>{capitalize(each.status)}</span>
            </div>
            <div
              onClick={() => {
                history.push(`/dashboard/payment-links/${each.slug}`);
                logBreadCrumb({ event: breadCrumbEvents.paymentLinks.paymentLinkClicked(activeCurrency), data: { slug: each.slug } });
              }}
              onKeyDown={() => {
                history.push(`/dashboard/payment-links/${each.slug}`);
                logBreadCrumb({ event: breadCrumbEvents.paymentLinks.paymentLinkClicked(activeCurrency), data: { slug: each.slug } });
              }}
              role="button"
              tabIndex={0}
            >
              <span className="body-row-header">Name:</span>
              <Link to={`/dashboard/payment-links/${each.slug}`} style={{ textDecoration: 'none' }}>
                <span>{capitalize(each.name)}</span>
              </Link>
            </div>
            <div>
              <span className="body-row-header">Created At:</span>
              <span>{getDate(each.createdAt)}</span>
              <span className="annotation" style={{ marginLeft: '5px' }}>
                {getTime(each.createdAt)}
              </span>
            </div>
            <div>
              <span className="body-row-header">Type:</span>
              <button
                type="button"
                className={`badge badge-${each.plan.is_recurring ? 'secondary' : 'warning'}`}
                style={{ border: 'none' }}
              >
                {each.plan.is_recurring ? 'Subscription' : 'One Time'}
              </button>
            </div>
            <div className="link-row">
              <span className="body-row-header">URL:</span>
              <Copyable
                spanClassName="checkout-link"
                text={
                  MERCHANT_ENV === 'test'
                    ? `${process.env.REACT_APP_CHECKOUT_TEST}${each.slug}`
                    : `${process.env.REACT_APP_CHECKOUT_LIVE}${each.slug}`
                }
                showOnHover
              />
            </div>
          </div>
        ))}
      </>
    );
  };

  const setSearchQuery = (value: string) => {
    searchQuery.setQuery({ currency: value }, true);
    logBreadCrumb({ event: breadCrumbEvents.paymentLinks.tabClicked(value) });
  };
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="payment-link-summary">
            <div className="heading-box-mmd">
              <h4 className="form-header payment-link-header">Get paid faster with Payment Links</h4>
              <p className="form-desc payment-desc">
                The secure, Kora-hosted payment links are an amazing way to receive payments from your customers. Payment links support
                multiple payment methods and are accessible to your customers anywhere, on any device.
                <a href="https://developers.korapay.com/docs/payment-links" target="noopener noreferrer" style={{ marginLeft: '0.3rem' }}>
                  Learn more.
                </a>
                .
              </p>
            </div>
            <div className="payment-select-mobile">
              <button
                className="btn btn-primary create-payment-link"
                type="button"
                onClick={() => {
                  history.push(`/dashboard/payment-links/new?currency=${activeCurrency}`);
                  logBreadCrumb({ event: breadCrumbEvents.paymentLinks.createPaymentLinkClicked });
                }}
              >
                <i className="os-icon os-icon-plus" style={{}} />
                Create Payment Link
              </button>
              <div>
                <select
                  component="select"
                  className="form-control pr-5 pl-3 mx-1"
                  name="activeTab"
                  value={activeTab}
                  onChange={e => searchQuery.setQuery({ tab: e.target.value, page: '1' })}
                  style={{ border: '1px solid #dde2ec', borderRadius: '10px' }}
                >
                  {Object.keys(tabs).map(tab => (
                    <option key={tab} value={tab}>
                      {tab}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 mb-5">
          <div className="os-tabs-w">
            <div className="os-tabs-controls os-tabs-complex settlement-tabs" style={{ alignItems: 'baseline' }}>
              <CurrencyTabs currencies={availableCurrencies} activeCurrency={activeCurrency} onClick={setSearchQuery} />
              <div className="payment-select">
                <select
                  component="select"
                  className="form-control pr-5 pl-3 mx-1"
                  name="activeTab"
                  value={activeTab}
                  onChange={e => searchQuery.setQuery({ tab: e.target.value, page: '1' })}
                  style={{ border: '1px solid #dde2ec', borderRadius: '10px' }}
                >
                  {Object.keys(tabs).map(tab => (
                    <option key={tab} value={tab}>
                      {tab}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="nav-content active" id="allSettlements">
              <Table
                hasPagination
                tableClassName="--checkout-table"
                headings={[
                  {
                    value: 'Status'
                  },
                  {
                    value: 'Name'
                  },
                  {
                    value: 'Created'
                  },
                  {
                    value: 'Type',
                    className: 'text-center'
                  },
                  {
                    value: 'URL',
                    className: 'text-center'
                  }
                ]}
                totalItems={paging?.total_items || 0}
                pageSize={paging?.page_size || 0}
                loading={isLoading}
                current={parseInt(paginationPage, 10)}
                limitAction={value => searchQuery.setQuery({ limit: String(value) })}
                actionFn={value => searchQuery.setQuery({ page: String(value) })}
                annotation="links"
                emptyStateHeading="There are no payment links yet"
                emptyStateMessage={
                  <span>
                    <Link to={`/dashboard/payment-links/new?currency=${activeCurrency}`}>Create a payment link</Link> to see them here.
                  </span>
                }
                tableWrapperClassName="element-box-tp"
              >
                <Links />
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function PaymentLinks() {
  return (
    <div className="checkouts-container">
      <Switch>
        <Route exact path="/dashboard/payment-links" component={PaymentLinkHome} />
        <Route path="/dashboard/payment-links/new" component={CreatePaymentLinkComponent} />
        <Route path="/dashboard/payment-links/:id">
          <PaymentLinkDetailsComponent />
        </Route>
      </Switch>
    </div>
  );
}
