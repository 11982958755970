import { AxiosResponse } from 'axios';

import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import { Storage } from '+services/storage-services';
import { history } from '+utils';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

function useExportDownloader() {
  const { feedbackInit } = useFeedbackHandler();

  const getDownload = async () => {
    let exportLink = Storage.getItem('EXPORT_LINK');
    if (exportLink) {
      const params = new URLSearchParams(exportLink);
      exportLink = params.get('exportToken');
      try {
        feedbackInit({
          title: 'Download in progress',
          message: '- Your file is being prepared',
          type: 'warning'
        });
        const res = (await api.getFileDownload(exportLink ?? '')) as AxiosResponse<{ data: { url: string } }>;

        const { url } = res.data.data;

        window.location.href = url;

        Storage.removeItem('EXPORT_LINK');
      } catch {
        Storage.removeItem('EXPORT_LINK');
        history.push('/dashboard/expired-export');
      }
    }
  };

  return { getDownload };
}

export default useExportDownloader;
