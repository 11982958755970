import { CardTransactionsResponseType } from '+types';
import { capitalizeRemovedash, formatAmount, getDate, getTime, history } from '+utils';

const TransactionsRow = ({ rowData }: { rowData: Array<CardTransactionsResponseType> }) => {
  const handleClick = (reference: string) => history.push(`/dashboard/issuing/card-transactions/${reference}`);

  return rowData?.map?.(tx => (
    <div
      key={`transaction_${tx.reference}`}
      className="div-table --history-table --row transactions-row"
      role="button"
      tabIndex={0}
      onClick={() => handleClick(tx.reference)}
      onKeyUp={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          handleClick(tx.reference);
        }
      }}
    >
      <div className="--txn-status --column d-flex">
        <span className="body-row-header">Status:</span>
        <span className={`status-indicator ${tx.status}`} />
        <span>{['requires_auth', 'require_auth'].includes(tx.status) ? 'pending' : capitalizeRemovedash(`${tx.status}`)}</span>
      </div>
      <div className="--column">
        <span className="body-row-header">Transaction ID:</span>
        <span className="font-weight-500 text-uppercase" style={{ color: '#007bff' }}>
          {tx.reference}
        </span>
      </div>
      <div className="--column">
        <span className="body-row-header">Type:</span>
        <span>{capitalizeRemovedash(tx.type)}</span>
      </div>
      <div className="--column">
        <span className="body-row-header">Date/Time:</span>
        <span className="grey-text">
          {getDate(tx.date)} {getTime(tx.date)}
        </span>
      </div>
      <div className="--column">
        <span className="body-row-header">Amount:</span>
        <span className="font-weight-500">
          <span>{formatAmount(tx.amount)}</span>
          <span className="annotation ml-1 d-inline">{tx.currency}</span>
        </span>
      </div>
    </div>
  ));
};

export default TransactionsRow;
