import React from 'react';

import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import useStore from '+store';
import { DefaultBusinessType, DocumentType } from '+types';
import { capitalizeRemovedash, maskBetweenSetRange } from '+utils';

import FilePNG from '+assets/img/dashboard/file.png';
import FileSvg from '+assets/img/dashboard/file.svg';

const DocumentItem: React.FC<{
  document: DocumentType;
}> = ({ document }) => (
  <a
    href={document?.file?.url}
    className="div-table --account-table document-row --row mb-2"
    key={document?.type}
    download
    target="_blank"
    rel="noopener noreferrer"
  >
    <div>
      <img src={FileSvg} srcSet={FilePNG} alt="document" loading="lazy" />
      <span style={{ textTransform: 'capitalize' }}>{document?.type ? capitalizeRemovedash(document?.type) : 'nil'}</span>
    </div>
    <div>
      <span>{document?.file?.description ? maskBetweenSetRange(capitalizeRemovedash(document?.file?.description), 4, 9) : 'nil'}</span>
    </div>
  </a>
);

const MerchantVerificationDocumentTable = () => {
  const defaultBusiness = useStore(state => state.merchantKYC);
  const documents = (defaultBusiness as DefaultBusinessType)?.details?.documents;
  return (
    <div className="mt-4">
      {documents?.length ? (
        documents?.map(document => <DocumentItem document={document} />)
      ) : (
        <EmptyStateComponent heading="No documents uploaded" hideMessage />
      )}
    </div>
  );
};

const VerificationDocument = () => {
  return (
    <div className="verification-doc__main">
      <h5 className="compliance-heading">Verification Documents</h5>
      <span className="description">Uploaded documents provided based on your business type.</span>
      <MerchantVerificationDocumentTable />
    </div>
  );
};

export default VerificationDocument;
