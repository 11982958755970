import { Dispatch, SetStateAction } from 'react';

import { CaretDown, CompleteTick } from '+containers/Shared/Icons';
import { TConversionsError } from '+types/conversions';
import { switchCurrency } from '+utils/formats';

import InfoIcon from '+assets/img/dashboard/exclamation.svg';

const settlementConfirmationUI = ({
  defaultCurrency,
  currency,
  rateMarkup
}: {
  defaultCurrency: string;
  currency: string;
  rateMarkup: string;
}) => (
  <div className="modal-ui">
    <div className="activate-conversions" data-testid="confirm-update-conversions">
      <div className="activate-conversions-active-currency">
        <span>
          <strong>{switchCurrency[defaultCurrency as keyof typeof switchCurrency]}</strong> transactions will be settled in{' '}
          <strong>{switchCurrency[currency as keyof typeof switchCurrency]}</strong>.
        </span>
      </div>
      <ul className="activate-conversions__changes">
        <li className="activate-conversions__changes-item">
          <span>Currency Markup</span>
          <span>{rateMarkup} %</span>
        </li>
      </ul>
    </div>
    <div className="alert">
      <img src={InfoIcon} height={16} alt="feedback icon" />
      <strong>
        Note: Refunds and chargebacks will be converted from the settlement currency to the recipient&apos;s currency using the exchange
        rate at the time of the refund.
      </strong>
    </div>
  </div>
);

const actionFeedback = ({ close, text }: { close: Dispatch<SetStateAction<string>>; text?: string }) => (
  <div className="modal-ui conversion-success" data-testid="conversions-update-feedback">
    <CompleteTick title="success-icon" className="success-icon" width={80} />
    <h4>Done!</h4>
    <p className="conversion-success__text">{text}</p>
    <button data-testid="dismiss-btn" onClick={() => close('')} type="button" className="conversion-success__dismiss">
      Dismiss
    </button>
  </div>
);

export const modalContent = {
  enableConversion: {
    heading: 'Settlement Currency',
    description:
      'Please note that all NGN transactions for this merchant will be settled in the selected currency from this update onwards. The changes you have made are listed below:',
    content: settlementConfirmationUI,
    size: 'md',
    firstButtonColor: '#DDE2EC',
    secondButtonText: 'Yes, Save',
    secondButtonColor: '#24B314'
  },

  enableConversionSuccess: {
    content: actionFeedback,
    showButtons: false,
    size: 'sm'
  },

  disableConversions: {
    heading: 'Disable Swap Settlement for NGN?',
    content: (currency: string) => (
      <span data-testid="confirm-disable-text">
        Please confirm that you want to disable swap settlements for this currency. This will reset the merchant’s {currency} settlements to
        the default currency.
      </span>
    ),
    size: 'md',
    secondButtonColor: '#F32345',
    secondButtonText: 'Yes, Disable'
  },

  disableConversionSuccess: {
    content: actionFeedback,
    showButtons: false,
    size: 'sm'
  },

  settlementDestination: {
    heading: 'Settlement Currency',
    description: 'What currency would you like to be settled in?',
    content: (currency: string, setCurrency: Dispatch<SetStateAction<string>>) => (
      <div className="modal-ui settlement-destination">
        <label htmlFor="select-currency">Currency</label>
        <div className="select-wrapper">
          <select value={currency} onChange={e => setCurrency(e.target.value)} id="select-currency">
            <option value="NGN">NGN</option>
            <option value="USD">USD</option>
          </select>
          <CaretDown title="caret-down" />
        </div>
      </div>
    ),
    size: 'md',
    secondButtonText: 'Continue',
    secondButtonDisable: (arg: boolean) => arg
  }
};

export const successText = ({ fromCurrency, toCurrency }: { fromCurrency: string; toCurrency: string }) =>
  `You have successfully changed the settlement currency to ${toCurrency} for ${fromCurrency} transactions and set the markup limit.`;

export const errorText = (currency: string) => `You have successfully disabled ${currency} Swap Settlement for this merchant.`;

export const formatError = (e: TConversionsError) => {
  return e?.response?.data?.message;
};

export const conversionRateSample = (mergedCurrency: string, rateMarkup: string): Record<string, string> | void => {
  const getMarkup = (rate: number) => rate + rate * (+rateMarkup / 100);

  if (['NGNUSD', 'USDNGN'].includes(mergedCurrency)) {
    return {
      NGN: getMarkup(1500).toFixed(2),
      USD: (1).toFixed(2)
    };
  }
};
