/* eslint-disable no-unused-vars */
import { useFetch, useSend } from '+hooks';

export const WebhookServices = {
  useResendWebhookNotification: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    errorMessage?: string;
  }) =>
    useSend({
      url: `/webhooks/notifications/initiate`,
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetWebhookNotification: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    ref,
    showErrorMessage
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    ref?: string;
    refetchOnCloseFeedbackError?: boolean;
    showErrorMessage?: boolean;
  }) =>
    useFetch(`/webhooks/notifications/${ref}`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      showErrorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    })
};
