import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

import { queryClient, storeExportLink } from '+utils';

import SignInForm from './components';
import Confirm2FAForm from './components/Confirm2FAForm';

import ImgKorapayLogo from '+assets/img/logos/logo-kpy-ent.png';
import ImgKorapayLogoSvg from '+assets/img/logos/logo-kpy-ent.svg';

function SigninComponent() {
  const [twoFARequired, setTwoFARequired] = useState(false);
  const [twoFAParams, setTwoFAParams] = useState({});
  const [email, setEmail] = useState('');
  const location = useLocation();

  const handleTwoFactorAuthRequired = (params: Record<string, string>, emailAddress: string) => {
    setEmail(emailAddress);
    setTwoFARequired(true);
    setTwoFAParams(params);
  };

  useEffect(() => {
    queryClient.setQueryData(['isWebBanner'], true);
  });

  useEffect(() => {
    storeExportLink(location);
  }, []);

  return (
    <>
      <Helmet title="Login" />
      {twoFARequired ? (
        <main className="auth__main">
          <div className="auth__header">
            <div className="logo-container">
              <Link to="/">
                <span className="sr-only">Logo</span>
                <img alt="Korapay Logo" src={ImgKorapayLogo} srcSet={ImgKorapayLogoSvg} />
              </Link>
            </div>
          </div>
          <div className="auth__content">
            <Confirm2FAForm twoFAParams={twoFAParams} email={email} />
          </div>
        </main>
      ) : (
        <main className="auth__main">
          <div className="auth__header">
            <div className="logo-container">
              <Link to="/">
                <img alt="Korapay Logo" src={ImgKorapayLogo} srcSet={ImgKorapayLogoSvg} />
              </Link>
            </div>
          </div>
          <div className="headings">
            <h2>Log in to your account</h2>
          </div>
          <div className="auth__content">
            <SignInForm initIfTwoFactorRequired={handleTwoFactorAuthRequired} />
          </div>
        </main>
      )}
    </>
  );
}

export default SigninComponent;
