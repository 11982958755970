/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import { useBreakpoints, useCurrencies, useReducerState, useSearchQuery } from '+hooks';
import { CurrencyDataType, CurrencyPaymentType, FilterTransactionT } from '+types';
import { backwardAmountInput, cleanInput, filteredOutObjectProperty } from '+utils';

import Modal from './Modal';

import arrowRight from '+assets/img/dashboard/arrow-right.svg';
import calendar from '+assets/img/dashboard/calendar.svg';
import search from '+assets/img/dashboard/search.svg';

import './index.scss';

interface ISearchComponentProps {
  handleSearchQuery: (query: string) => void;
  type: string;
  activeCurrency: string;
  isBatchReference?: boolean;
}

const initialState = {
  keyword: '',
  type: '',
  amount: '',
  amountSubfilter: 'is_equal_to',
  reference: '',
  status: '',
  channel: '',
  payment_method: '',
  dateFrom: '',
  dateTo: '',
  batch_reference: '',
  isFilterVisible: false,
  sorterType: 'filter'
};
const SearchComponent = ({ handleSearchQuery, type, activeCurrency, isBatchReference }: ISearchComponentProps) => {
  const queries = useMemo(
    () => ({
      isMobile: '(max-width: 768px)'
    }),
    []
  );
  const breakpoint = useBreakpoints(queries);
  const currency = useCurrencies();

  const [state, setState] = useReducerState(initialState);
  const searchQuery = useSearchQuery();
  const [modalVisible, setModalVisible] = useState(false);
  const isMobile = (breakpoint as unknown as { isMobile: boolean })?.isMobile;

  const handleSearch = () => {
    return handleSearchQuery(state.keyword);
  };

  const getPaymentTypes = (category: string): { value: string; label: string }[] | undefined => {
    const data: CurrencyDataType[] | undefined = currency?.data?.data;
    const paymentType = data?.find((c: CurrencyDataType) => c.code === activeCurrency)?.meta_data?.payment_types?.[category];
    return paymentType?.map((p: CurrencyPaymentType) => ({ value: p.value, label: p.label }));
  };

  useEffect(() => {
    const filterKeys = Object.keys(searchQuery.value);
    if (filterKeys.length > 0) {
      setState({
        amount: searchQuery.value.amount || '',
        keyword: searchQuery.value.keyword || '',
        dateFrom: searchQuery.value.dateFrom,
        dateTo: searchQuery.value.dateTo,
        status: searchQuery.value.status,
        type: searchQuery.value.type || '',
        amountSubfilter: searchQuery.value.amountSubfilter || 'is_equal_to',
        reference: searchQuery.value.reference || '',
        channel: searchQuery.value.channel || '',
        payment_method: searchQuery.value.payment_method || '',
        batch_reference: searchQuery.value.batch_reference || '',
        isFilterVisible: Boolean(searchQuery.value.isFilterVisible || false)
      });
    }
  }, [searchQuery.value]);

  const statusOption: Record<FilterTransactionT, { value: string; label: string }[]> = {
    'pay-in': [
      { value: 'processing', label: 'Processing' },
      { value: 'success', label: 'Success' },
      { value: 'flagged', label: 'Flagged' },
      { value: 'failed', label: 'Failed' },
      { value: 'expired', label: 'Expired' },
      { value: 'rejected', label: 'Rejected' },
      { value: 'pre_authorized', label: 'Pre-Authorized' },
      { value: 'void_authorization', label: 'Voided (Auth)' },
      { value: 'void_capture', label: 'Voided (Capture)' }
    ],
    payout: [
      { value: 'processing', label: 'Processing' },
      { value: 'success', label: 'Success' },
      { value: 'failed', label: 'Failed' },
      { value: 'abandoned', label: 'Abandoned' },
      { value: 'pending', label: 'Pending' },
      { value: 'reversed', label: 'Reversed' }
    ],
    'bulk-payout': [
      { value: 'processing', label: 'Processing' },
      { value: 'complete', label: 'Complete' },
      { value: 'failed', label: 'Failed' },
      { value: 'draft', label: 'Draft' },
      { value: 'pending', label: 'Pending' },
      { value: 'cancelled', label: 'Cancelled' }
    ]
  };

  const paymentType: Partial<Record<FilterTransactionT, { value: string; label: string }[]>> = {
    'pay-in': [
      { value: 'payin', label: 'Pay-in' },
      { value: 'deposit', label: 'Funding' }
    ],
    payout: getPaymentTypes('payout')
  };

  const paymentMethod = [
    { value: 'bank_transfer', label: 'Bank Transfer' },
    { value: 'card', label: 'Card Payment' },
    { value: 'pay_with_bank', label: 'Pay with Bank' },
    { value: 'mobile_money', label: 'Mobile Money' }
  ];

  const paymentChannel = [
    { value: 'web', label: 'Dashboard' },
    ...(type === 'pay-in' ? [{ value: 'modal', label: 'Checkout' }] : []),
    { value: 'api', label: 'API' }
  ];

  const filterTransactions = async () => {
    const removeKeys = ['clearFilter', 'tab', 'isFilterVisible'];
    if (!state.amount) {
      removeKeys.push('amountSubfilter');
    }
    const values = filteredOutObjectProperty({ ...state, page: 1 }, removeKeys);
    const queries = {
      ...values,
      ...(isBatchReference && { batch_reference: values.reference })
    };
    setModalVisible(false);
    return searchQuery.setQuery(queries, true);
  };

  const selectedStatusOptions = statusOption[type as FilterTransactionT] || [];
  const selectedPaymentType = paymentType[type as FilterTransactionT] || [];

  const defaultSearchContent = () => {
    return (
      <div className="mobile-filter__modal">
        <p>Status</p>
        <div className="mobile-switch__modal" role="menu">
          {selectedStatusOptions?.map((item: { value: string; label: string }, index: number) => {
            const isSelected = state.status === item.value;
            return (
              <button
                type="button"
                role="menuitem"
                key={index}
                className={`mobile-switch__modal__item ${isSelected ? 'active' : ''}`}
                onClick={() => setState({ status: item.value })}
              >
                <input type="radio" checked={isSelected} readOnly />
                <div>
                  <span>{item.label}</span>
                </div>
              </button>
            );
          })}
        </div>
        {type !== 'bulk-payout' && (
          <div>
            <p> {type === 'pay-in' ? 'Pay-in' : 'Payout'} Type</p>

            <div className="mobile-switch__modal" role="menu">
              {selectedPaymentType?.map((item: { value: string; label: string }, index: number) => {
                const isSelected = state.type === item.value;
                return (
                  <button
                    type="button"
                    role="menuitem"
                    key={index}
                    className={`mobile-switch__modal__item ${isSelected ? 'active' : ''}`}
                    onClick={() => setState({ type: item.value })}
                  >
                    <input type="radio" checked={isSelected} readOnly />
                    <div>
                      <span>{item.label}</span>
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        )}
        {type === 'pay-in' && (
          <div>
            <p> Payment Method</p>

            <div className="mobile-switch__modal" role="menu">
              {paymentMethod?.map((item, index) => {
                const isSelected = state.payment_method === item.value;
                return (
                  <button
                    type="button"
                    role="menuitem"
                    key={index}
                    className={`mobile-switch__modal__item ${isSelected ? 'active' : ''}`}
                    onClick={() => setState({ payment_method: item.value })}
                  >
                    <input type="radio" checked={isSelected} readOnly />
                    <div>
                      <span>{item.label}</span>
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        )}

        {type !== 'bulk-payout' && (
          <div className="modal-div">
            <p>
              Amount <span className="label-note">({activeCurrency})</span>
            </p>
            <div className="input-group">
              <select
                id="amount"
                className="form-control modal-select"
                style={{ background: '#e9ecef', maxWidth: '125px' }}
                onChange={e => setState({ amountSubfilter: e.target.value })}
                value={state.amountSubfilter}
              >
                <option value="is_equal_to">Exactly</option>
                <option value="is_greater_than">Greater than</option>
                <option value="is_less_than">Less than</option>
              </select>
              <input
                className="form-control"
                type="number"
                placeholder="0.00"
                maxLength={50}
                onChange={e => {
                  const amountInput = backwardAmountInput(e.target.value) || '0';
                  setState({ amount: parseFloat(amountInput).toFixed(2) });
                }}
                value={state.amount || ''}
              />
            </div>
          </div>
        )}

        <div className="modal-div">
          <p>Timestamp</p>
          <div className="form-group filter-object filter-object-sm w-auto mr-0" style={{ '--calendar-image': `url("${calendar}")` }}>
            <DatePicker
              id="from"
              name="dateFrom"
              selected={state.dateFrom ? new Date(state.dateFrom) : null}
              dateFormat="dd-MM-yyyy"
              onChange={date => setState({ dateFrom: date ? dayjs(dayjs(date)).format('YYYY-MM-DD') : '' })}
              maxDate={new Date()}
              placeholderText="From"
              calendarClassName="custom-datepicker"
              className="form-control date-select pl-4 date-picker"
            />
          </div>
          <div className="form-group filter-object filter-object-sm w-auto mr-0 mt-3" style={{ '--calendar-image': `url("${calendar}")` }}>
            <DatePicker
              id="label"
              name="dateTo"
              selected={state.dateTo ? new Date(state.dateTo) : undefined}
              dateFormat="dd-MM-yyyy"
              minDate={state.dateFrom ? new Date(state.dateFrom) : undefined}
              maxDate={new Date()}
              onChange={date => setState({ dateTo: date ? dayjs(dayjs(date)).format('YYYY-MM-DD') : '' })}
              placeholderText="To"
              calendarClassName="custom-datepicker"
              className="form-control date-select pl-4 date-picker"
            />
          </div>
        </div>

        <div className="modal-div">
          <p>Transaction ID</p>
          <input
            id="transaction-id"
            name="transaction-id"
            className="form-control"
            type="search"
            placeholder="Eg. KPY-C-8052042.."
            value={state.reference}
            onChange={e => setState({ reference: cleanInput(e.target.value) })}
          />
        </div>

        {type !== 'bulk-payout' && (
          <div>
            <p>Payment Channel</p>

            <div className="mobile-switch__modal" role="menu">
              {paymentChannel?.map((item, index) => {
                const isSelected = state.channel === item.value;
                return (
                  <button
                    type="button"
                    role="menuitem"
                    key={index}
                    className={`mobile-switch__modal__item ${isSelected ? 'active' : ''}`}
                    onClick={() => setState({ channel: item.value })}
                  >
                    <input type="radio" checked={isSelected} readOnly />
                    <div>
                      <span>{item.label}</span>
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        )}

        <div className="modal-div">
          <p> Keyword</p>
          <input
            id="keyword"
            type="search"
            className="form-control"
            placeholder="Search anything..."
            value={state.keyword}
            onChange={e => setState({ keyword: cleanInput(e.target.value) })}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="search__container" style={{ margin: '0' }}>
        <img src={search} alt="search icon" className="search__icon" />
        <input
          type="search"
          aria-label="search transactions"
          name="searchHistory"
          id="searchtransactions"
          data-testid="searchtransactions"
          className="form-control form-control-sm"
          placeholder="Search..."
          value={searchQuery.value.keyword}
          style={{ fontFamily: 'Averta PE' }}
          onChange={e => {
            if (e.target.value.trim() === '') {
              handleSearchQuery('');
            } else {
              setState({
                keyword: e.target.value
              });
            }
          }}
        />
        <button
          aria-label="search transactions"
          type="button"
          className="search__button"
          onClick={() => handleSearch()}
          aria-controls="searchtransactions"
          data-testid="searchbutton"
        >
          <img src={arrowRight} alt="arrow right icon" aria-hidden />
        </button>
      </div>
      <div className="mobile-search-container" onClick={() => setModalVisible(!modalVisible)}>
        <i className="os-icon os-icon-sliders" />
      </div>
      {isMobile && modalVisible && (
        <Modal
          heading="Filter"
          size="sm"
          secondButtonText="Apply"
          secondButtonAction={filterTransactions}
          close={() => {
            setModalVisible(false);
          }}
          secondButtonActionIsTerminal={false}
          content={defaultSearchContent()}
        />
      )}
    </>
  );
};

export default SearchComponent;
