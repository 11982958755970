/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react';

import './index.scss';

import CursorPagination from './CursorPagination';
import EmptyState from './EmptyState';
import LoadingPlaceholder from './LoadingPlaceholder';
import PaginationComponent from './Pagination';

export type TableProps = {
  tableTitle?: string;
  hasPagination: boolean;
  header: string | React.ReactNode;
  tableClassName: string;
  hidden: boolean;
  headings: Array<any>;
  totalItems: number | string;
  pageSize: number | string;
  loading: boolean;
  current: number | string;
  limitAction: (val: any) => void;
  actionFn: (val: any) => void;
  annotation: string;
  emptyStateHeading: string | React.ReactNode;
  emptyStateMessage: string | React.ReactNode;
  background: string;
  children: string | React.ReactNode;
  tableWrapperClassName: string;
  borderedTable: boolean;
  doNotShowIf: boolean;
  banner: string | React.ReactNode;
  paginationType?: string;
  paging?: { next_cursor?: number; previous_cursor?: number; page_size?: number };
};
const Table = ({
  hasPagination = false,
  header = '',
  tableClassName = '',
  hidden = false,
  headings = [],
  totalItems = '',
  pageSize = '',
  loading = false,
  current = '',
  limitAction = () => {},
  actionFn = () => {},
  annotation = '',
  emptyStateHeading = '',
  emptyStateMessage = '',
  background = '',
  children = null,
  tableWrapperClassName = '',
  borderedTable = true,
  doNotShowIf = false,
  banner = null,
  paginationType = '',
  paging
}: Partial<TableProps>) => {
  return (
    <section className="table__comp">
      {header && (
        <div className="box-header pt-4 pb-3 ml-3" style={{ fontWeight: 600 }}>
          {header}
        </div>
      )}
      <div className={`${borderedTable ? 'table-wrapper' : ''} ${tableWrapperClassName}  ${hasPagination ? '--has-pagination' : ''}`}>
        {loading ? (
          <LoadingPlaceholder type="table" background={background} />
        ) : totalItems === 0 || doNotShowIf ? (
          <EmptyState heading={emptyStateHeading} message={emptyStateMessage} />
        ) : (
          <>
            <div className="table-responsive">
              <div className="theader">
                <div className={`div-table pb-3 ${tableClassName} --heading`} hidden={hidden}>
                  {headings?.map((heading, index) => (
                    <div className={heading?.className} style={heading?.style} key={index}>
                      {heading?.value}
                    </div>
                  ))}
                </div>
              </div>
              {banner && <div className="table-banner">{banner}</div>}
              <div className="tbody">
                {banner && (
                  <tr className="table-banner-mobile">
                    <td colSpan={100}>{banner}</td>
                  </tr>
                )}
                {children}
              </div>
            </div>

            {hasPagination &&
              (paginationType === 'cursor' ? (
                <CursorPagination pagingTotalItems={Number(totalItems)} paging={paging} />
              ) : (
                <PaginationComponent
                  currentPage={Number(current)}
                  pagingTotalItems={Number(totalItems)}
                  limitAction={limitAction}
                  pageSize={Number(pageSize)}
                  action={actionFn ?? (() => {})}
                  annotation={annotation}
                />
              ))}
          </>
        )}
      </div>
    </section>
  );
};

export default Table;
