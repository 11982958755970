import { useEffect, useReducer } from 'react';

import { useMerchantBalances, useValidateAmount } from '+hooks';
import useStore from '+store';
import { ConversionDataType, CurrencyType, StateType } from '+types';
import {
  createConversionsQueryData,
  createQueryData,
  getAvailableBalance,
  getMaxConversionLimit,
  getMinConversionLimit,
  setDestinationCurrencyArray
} from '+utils';

const initialState: StateType = {
  amount: '',
  apiData: null,
  data: null,
  exchange: {},
  countdownCompleted: false,
  isLoading: true,
  countdown: 40,
  startCount: false,
  loading: false,
  isProcessing: false,
  payloadCurrency: {
    from: '',
    to: ''
  }
};

const useConversionLogics = (currency?: string) => {
  const { balances } = useMerchantBalances();
  const defaultMerchant = useStore(state => state.defaultMerchant);
  const currencyArray = Object.keys(defaultMerchant?.conversion_limits || {});
  const sourceCurrency = Object.keys(defaultMerchant?.conversion_limits || {});
  const destinationCurrency = Object.keys(defaultMerchant?.conversion_limits?.[sourceCurrency[0] as CurrencyType] || {});

  const [state, setState] = useReducer((state: StateType, action: Partial<StateType>) => ({ ...state, ...action }), {
    ...initialState,
    payloadCurrency: {
      from: sourceCurrency[0] || '',
      to: destinationCurrency[0] || ''
    }
  });

  const { amount, data, payloadCurrency } = state;

  const getAvailableDestinationCurrency = setDestinationCurrencyArray(payloadCurrency.from, defaultMerchant);

  useEffect(() => {
    if (currency) {
      setState({ payloadCurrency: { ...payloadCurrency, from: currency } });
    }
  }, [currency]);

  useEffect(() => {
    if (getAvailableDestinationCurrency.length === 1) {
      setState({ payloadCurrency: { ...payloadCurrency, to: getAvailableDestinationCurrency[0] } });
    }
  }, [payloadCurrency.from]);

  const { rate, reference, to_amount: toAmount } = (data as unknown as ConversionDataType) || {};

  const availableBalance = getAvailableBalance(payloadCurrency.from, balances) || 0;
  const minConversionLimit = getMinConversionLimit(payloadCurrency.from, payloadCurrency.to, defaultMerchant.conversion_limits || {});
  const maxConversionLimit = getMaxConversionLimit(payloadCurrency.from, payloadCurrency.to, defaultMerchant.conversion_limits || {});

  const { errorMessage, validateAmount, setErrorMessage } = useValidateAmount({
    payloadCurrency,
    amount,
    minConversionLimit,
    maxConversionLimit: maxConversionLimit || 0,
    availableBalance
  });

  const queryData = createQueryData(amount, payloadCurrency);
  const conversionsQueryData = createConversionsQueryData(queryData, reference);

  return {
    state,
    setState,
    rate,
    reference,
    toAmount,
    minConversionLimit,
    maxConversionLimit,
    errorMessage,
    validateAmount,
    setErrorMessage,
    queryData,
    conversionsQueryData,
    currencyArray,
    availableBalance,
    sourceCurrency,
    destinationCurrency: getAvailableDestinationCurrency
  };
};

export default useConversionLogics;
