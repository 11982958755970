/* eslint-disable prefer-rest-params */
import mixpanel, { Dict } from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_APOLLO_SUN ?? '');

const EnvCheck = process.env.NODE_ENV === 'production';

const target = {
  identify: (id: string) => {
    if (EnvCheck) mixpanel.identify(id);
    localStorage.setItem('MIXPANEL_USER_ID', id);
  },
  alias: (id: string) => {
    if (EnvCheck) mixpanel.alias(id);
  },
  register: (data: Dict) => {
    if (EnvCheck) mixpanel.register(data);
  },
  track: (name: string, props: Dict | undefined) => {
    if (EnvCheck) mixpanel.track(name, props);
  },
  people: {
    set: (props: Dict) => {
      if (EnvCheck) mixpanel.people.set(props);
    }
  }
};

const handler = {
  get(obj: {
    track(arg0: string): unknown; identify: (arg0: string) => void; 
}, prop: string) {
    if (prop !== 'identify') {
      const existingID = localStorage.getItem('MIXPANEL_USER_ID');
      if (existingID) {
        obj.identify(existingID);
      }
      return Reflect.get(...arguments);
    }
    return Reflect.get(...arguments);
  }
};

const actions = new Proxy(target, handler);

export default actions;
