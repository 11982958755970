/* eslint-disable no-unused-vars */
import { useFetch, useSend } from '+hooks';

export const ProfileServices = {
  useFetchTokens: ({
    onSuccess,
    bannerLevel,
    errorMessage,
    refetchOnCloseFeedbackError
  }: {
    onSuccess?: () => void;
    errorMessage?: string;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch('/auth/auth-keys', {
      onSuccess,
      errorMessage,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError
    }),

  useRefreshTokens: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/auth/api-keys/generate',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel
    }),

  useGenerateEncryptionToken: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: '/auth/encryption-key/generate',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel
    })
};
