import React, { useEffect, useRef } from 'react';

const useFocusTrap = (modalRef: React.RefObject<HTMLElement>, modalIsVisible: boolean, closeModal: () => void) => {
  const firstFocusableNode = useRef<HTMLElement | null>(null);
  const lastFocusableNode = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!modalIsVisible || !modalRef.current) return;

    const currentModalRef = modalRef.current;
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        if (!e.shiftKey && document.activeElement === lastFocusableNode.current) {
          e.preventDefault();
          firstFocusableNode.current?.focus();
        } else if (e.shiftKey && document.activeElement === firstFocusableNode.current) {
          e.preventDefault();
          lastFocusableNode.current?.focus();
        }
      } else if (e.key === 'Escape') {
        e.preventDefault();
        closeModal?.();
      }
    };

    const handleFocus = (e: FocusEvent) => {
      if (currentModalRef && !currentModalRef.contains(e.target as HTMLElement)) {
        firstFocusableNode?.current?.focus();
      }
    };

    const focusableNodes: NodeListOf<HTMLElement> = currentModalRef.querySelectorAll(
      'a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex="0"]'
    );

    if (focusableNodes.length > 0) {
      firstFocusableNode.current = focusableNodes[0];
      lastFocusableNode.current = focusableNodes[focusableNodes.length - 1];
      firstFocusableNode.current.focus();
    }

    currentModalRef.addEventListener('keydown', handleKeyDown);
    document.addEventListener('focusin', handleFocus);

    return () => {
      currentModalRef.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('focusin', handleFocus);
    };
  }, [modalIsVisible, modalRef]);
};

export default useFocusTrap;
