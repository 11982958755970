/* eslint-disable @typescript-eslint/only-throw-error */
import { AxiosResponse } from 'axios';

import APIRequest from '+services/api-services';
import APIServiceError from '+services/error-services';

import useLogout from './useLogout';

const merchantMiddlewareBaseURL = process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE || 'http://localhost:3000';
const merchantMiddlewareAPIRequest = new APIRequest(merchantMiddlewareBaseURL);

const useMerchantAuth = () => {
  const { logout } = useLogout();
  const bootstrap = async () => await merchantMiddlewareAPIRequest.bootstrap();

  const updateMerchantEnv = async (data: unknown) => (await merchantMiddlewareAPIRequest.updateMerchantEnv(data)) as unknown;

  const getProductAccess = async () => await merchantMiddlewareAPIRequest.getProductAccess();

  const handleLogout = (destination = '/') => logout(destination);

  const verifyGetStartedTokenTeam = async (data: string | null) => {
    try {
      const response = (await merchantMiddlewareAPIRequest.verifyGetStartedTokenTeam(data)) as AxiosResponse<{ status: number }>;
      return response.data;
    } catch (error) {
      if (error instanceof APIServiceError) {
        throw error;
      }
      throw error;
    }
  };

  const completeGetStartedTeam = async (data: {
    verification_token: string;
    first_name: string;
    last_name: string;
    password: string;
    phone: string;
  }) => {
    try {
      const response = (await merchantMiddlewareAPIRequest.completeGetStartedTeam(data)) as AxiosResponse<{ status: boolean }>;
      return response;
    } catch (error) {
      if (error instanceof APIServiceError) {
        throw error;
      }
      throw error;
    }
  };

  return { bootstrap, handleLogout, updateMerchantEnv, getProductAccess, verifyGetStartedTokenTeam, completeGetStartedTeam };
};

export default useMerchantAuth;
