import { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import dayjs from 'dayjs';

import { useBreakpoints, useReducerState, useSearchQuery } from '+hooks';
import { backwardAmountInput, cleanInput, filteredOutObjectProperty } from '+utils';

import arrowRight from '+assets/img/dashboard/arrow-right.svg';
import calendar from '+assets/img/dashboard/calendar.svg';
import search from '+assets/img/dashboard/search-thin.svg';

import 'react-datepicker/dist/react-datepicker.css';
import '../index.scss';

import Modal from '+containers/Dashboard/Shared/Modal';

const chargebackStatus = [
  { value: 'pending', label: 'Pending', color: '#f1e9d3' },
  { value: 'accepted', label: 'Accepted', color: '#e6c8ce' },
  { value: 'auto_accepted', label: 'Accepted (Auto)', color: '#e6c8ce' },
  { value: 'lost', label: 'Lost', color: '#e6c8ce' },
  { value: 'won', label: 'Won', color: '#d5eace' },
  { value: 'partial', label: 'Partial', color: '#d5eace' },
  { value: 'declined', label: 'Declined', color: '#d3d9e0' }
];

const refundsStatusOption = [
  { value: 'processing', label: 'Processing' },
  { value: 'success', label: 'Success' }
];

const initialState = {
  status: [],
  keyword: '',
  amount: '',
  clearFilter: false,
  dateFrom: '',
  dateTo: ''
};

const showClearButtonKeys = Object.keys(initialState);

interface IDisputeFilterProps {
  handleFilterQuery: () => void;
  totalCount?: number;
  openExportModal?: () => void;
  userAccess?: string;
}

const DisputeFilter = ({ handleFilterQuery, totalCount = 0, openExportModal = () => {}, userAccess = '' }: IDisputeFilterProps) => {
  const animatedComponents = makeAnimated();
  const searchQuery = useSearchQuery();
  const activeTab = searchQuery.value.tab || 'refunds';
  const activeCurrency = searchQuery.value.currency || 'NGN';

  const queries = useMemo(
    () => ({
      isMobile: '(max-width: 768px)'
    }),
    []
  );
  const breakpoint = useBreakpoints(queries);
  const [modalVisible, setModalVisible] = useState(false);
  const isMobile = (breakpoint as unknown as { isMobile: boolean })?.isMobile;

  const [state, setState] = useReducerState(initialState);
  useEffect(() => {
    const filterKeys = Object.keys(searchQuery.value);
    const showKeys = showClearButtonKeys.some(key => filterKeys.indexOf(key) !== -1);
    if (filterKeys.length > 0 && showKeys) {
      setState({
        clearFilter: true,
        amount: searchQuery.value.amount || '',
        keyword: searchQuery.value.keyword || '',
        dateFrom: searchQuery.value.dateFrom,
        dateTo: searchQuery.value.dateTo,
        status: typeof searchQuery.value.status === 'string' ? [searchQuery.value.status] : searchQuery.value.status || []
      });
    } else {
      setState({ ...initialState, clearFilter: false });
    }
  }, [searchQuery.value]);

  const handleClearFilter = () => {
    setState(initialState);
    searchQuery.clearAll(['tab', 'page', 'currency']);
  };
  const getPreviousKeywordValueAndLabel = item => {
    const previousKeyword = Object.keys(item).find(key => Object.keys(searchQuery.value).includes(key));
    if (previousKeyword && previousKeyword in item) {
      return { ...item, ...searchQuery?.value, [previousKeyword]: item[previousKeyword] };
    }
    return { ...searchQuery?.value, ...item };
  };

  const filterTransactions = async () => {
    const values = filteredOutObjectProperty(state, ['clearFilter']);
    const result = getPreviousKeywordValueAndLabel(values);
    setModalVisible(false);
    return searchQuery.setQuery(
      {
        ...result
      },
      true
    );
  };

  const defaultSearchContent = () => {
    return (
      <div className="mobile-filter__modal">
        <p>Status</p>
        <div className="mobile-switch__modal" role="menu">
          {activeTab === 'chargebacks' &&
            chargebackStatus?.map((item: { value: string; label: string }, index: number) => {
              const isSelected = state.status === item.value;
              return (
                <button
                  type="button"
                  role="menuitem"
                  key={index}
                  className={`mobile-switch__modal__item ${isSelected ? 'active' : ''}`}
                  onClick={() => setState({ status: item.value })}
                >
                  <input type="radio" checked={isSelected} readOnly />
                  <div>
                    <span>{item.label}</span>
                  </div>
                </button>
              );
            })}
          {activeTab === 'refunds' &&
            refundsStatusOption?.map((item: { value: string; label: string }, index: number) => {
              const isSelected = state.status === item.value;
              return (
                <button
                  type="button"
                  role="menuitem"
                  key={index}
                  className={`mobile-switch__modal__item ${isSelected ? 'active' : ''}`}
                  onClick={() => setState({ status: item.value })}
                >
                  <input type="radio" checked={isSelected} readOnly />
                  <div>
                    <span>{item.label}</span>
                  </div>
                </button>
              );
            })}
        </div>

        <div className="modal-div">
          <p>
            Amount <span className="label-note">({activeCurrency})</span>
          </p>

          <input
            className="form-control"
            type="number"
            placeholder="Search by Amount..."
            maxLength={50}
            onChange={e => {
              const amountInput = backwardAmountInput(e.target.value) || '0';
              setState({ amount: parseFloat(amountInput).toFixed(2) });
            }}
            value={state.amount || ''}
          />
        </div>

        <div className="modal-div">
          <p> Keyword</p>
          <input
            id="keyword"
            type="search"
            className="form-control"
            placeholder={`${
              activeTab === 'refunds'
                ? 'Search by Refund ID, Transaction ID, or Customer Name...'
                : 'Search by Chargeback ID, customer name, anything ...'
            }`}
            value={state.keyword}
            onChange={e => setState({ keyword: cleanInput(e.target.value) })}
          />
        </div>

        <div className="modal-div">
          <p>Timestamp</p>
          <div className="form-group filter-object filter-object-sm w-auto mr-0" style={{ '--calendar-image': `url("${calendar}")` }}>
            <DatePicker
              id="from"
              name="dateFrom"
              selected={state.dateFrom ? new Date(state.dateFrom) : null}
              dateFormat="dd-MM-yyyy"
              onChange={date => setState({ dateFrom: date ? dayjs(dayjs(date)).format('YYYY-MM-DD') : '' })}
              maxDate={new Date()}
              placeholderText="From"
              calendarClassName="custom-datepicker"
              className="form-control date-select pl-4 date-picker"
            />
          </div>
          <div className="form-group filter-object filter-object-sm w-auto mr-0 mt-3" style={{ '--calendar-image': `url("${calendar}")` }}>
            <DatePicker
              id="label"
              name="dateTo"
              selected={state.dateTo ? new Date(state.dateTo) : undefined}
              dateFormat="dd-MM-yyyy"
              minDate={state.dateFrom ? new Date(state.dateFrom) : undefined}
              maxDate={new Date()}
              onChange={date => setState({ dateTo: date ? dayjs(dayjs(date)).format('YYYY-MM-DD') : '' })}
              placeholderText="To"
              calendarClassName="custom-datepicker"
              className="form-control date-select pl-4 date-picker"
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {isMobile && modalVisible && (
        <Modal
          heading="Filter"
          size="sm"
          secondButtonText="Apply"
          secondButtonAction={filterTransactions}
          close={() => {
            setModalVisible(false);
          }}
          secondButtonActionIsTerminal={false}
          content={defaultSearchContent()}
        />
      )}
      <section className="settlement-filter" data-testid="dispute-filter-wrapper">
        <div className="settlement-filter__top">
          <div>
            <span>
              Showing all {activeCurrency} {activeTab} ({totalCount || 0}) {state.clearFilter && '(filtered results)'}
            </span>
            {state.clearFilter && (
              <>
                <span className="divider-sm" />
                <button type="button" data-testid="clear-btn" onClick={handleClearFilter}>
                  {' '}
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20" style={{ width: '1rem' }}>
                    <path
                      fill="#AABDCE"
                      d="M3.426 2.926c3.902-3.9 10.247-3.9 14.149 0 3.9 3.901 3.9 10.248 0 14.15A9.976 9.976 0 0110.5 20a9.975 9.975 0 01-7.074-2.924c-3.901-3.902-3.901-10.249 0-14.15zM6.374 12.95a.833.833 0 101.179 1.178L10.5 11.18l2.946 2.948a.835.835 0 001.18-1.18l-2.947-2.946 2.947-2.948a.833.833 0 10-1.179-1.179L10.5 8.822 7.553 5.874a.833.833 0 10-1.18 1.18L9.322 10l-2.947 2.948z"
                    />
                  </svg>
                  &nbsp;
                  <span>Clear</span>
                </button>
              </>
            )}
          </div>
          <div>
            {(userAccess === 'manage' || userAccess === 'export') && (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={openExportModal}
                data-testid="export-btn"
                style={{ background: 'none', border: 'none', color: '#2376F3', paddingRight: 0 }}
              >
                <i className="os-icon os-icon-arrow-up-right" />
                <span>Export</span>
              </button>
            )}
          </div>
        </div>
        <div className="settlement-filter__search-w filter-section">
          <div className="element-search-content filter-body w-100">
            {activeTab === 'chargebacks' && (
              <div className="form-group filter-object filter-object-md w-auto --no-max-width mr-0">
                <Select
                  value={chargebackStatus.filter(status => state?.status?.includes(status.value))}
                  id="status"
                  aria-label="status"
                  closeMenuOnSelect={false}
                  placeholder="All Statuses"
                  components={animatedComponents}
                  isMulti
                  options={chargebackStatus}
                  styles={{
                    control: (styles, { isFocused }) => ({
                      ...styles,
                      fontSize: '13px',
                      fontWeight: 300,
                      border: isFocused ? null : '2px solid #dde2ec'
                    }),
                    option: (styles, { data, isFocused }) => {
                      return {
                        ...styles,
                        fontSize: '13px',
                        fontWeight: 400,
                        backgroundColor: isFocused ? data.color : null
                      };
                    },
                    multiValue: (styles, { data }) => {
                      return {
                        ...styles,
                        backgroundColor: data.color
                      };
                    },

                    indicatorSeparator: () => ({}),
                    container: base => ({
                      ...base,
                      height: '100%'
                    }),
                    valueContainer: base => ({
                      ...base,
                      display: 'flex',
                      height: '100%'
                    }),
                    singleValue: (base: any) => ({
                      ...base,
                      position: 'absolute',
                      top: 'unset',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: 'calc(100% - 40px)'
                    })
                  }}
                  onChange={value => setState({ status: !value || value.length === 0 ? [] : value.map(e => e.value) })}
                />
              </div>
            )}

            {activeTab === 'refunds' && (
              <div className="form-group filter-object filter-object-sm w-auto --no-max-width mr-0">
                <select
                  name="status"
                  className={`form-control filter-object-sm mr-0 ${!state.status?.[0] && 'refunds-all-status'}`}
                  value={state.status?.[0]}
                  aria-label="status"
                  style={{ border: '1.5px solid #EAF2FE' }}
                  onChange={e => setState({ status: [e.target.value] })}
                >
                  <option value="">All Statuses</option>
                  {refundsStatusOption.map(item => (
                    <option key={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="form-group position-relative filter-object flex-grow-1 w-auto --no-max-width --search-container mr-0">
              <img src={search} alt="search icon" aria-hidden />
              <input
                type="search"
                className="form-control"
                placeholder={`${
                  activeTab === 'refunds'
                    ? 'Search by Refund ID, Transaction ID, or Customer Name...'
                    : 'Search by Chargeback ID, customer name, anything ...'
                }`}
                value={state.keyword}
                onChange={e => setState({ keyword: cleanInput(e.target.value) })}
                style={{ border: '1.5px solid #EAF2FE' }}
                data-testid="keyword"
              />
            </div>
            {activeTab === 'refunds' && (
              <div className="form-group position-relative filter-object filter-object-ssm flex-grow-1 w-auto --search-container mr-0">
                <img src={search} alt="search icon" aria-hidden />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by Amount..."
                  value={state.amount}
                  onChange={e => setState({ amount: backwardAmountInput(e.target.value) })}
                  style={{ border: '1.5px solid #EAF2FE' }}
                />
              </div>
            )}

            <div
              className="form-group filter-object d-flex filter-object-sm w-auto mr-0"
              style={{ '--calendar-image': `url("${calendar}")`, minWidth: '300px' }}
            >
              <DatePicker
                selected={state.dateFrom ? new Date(state.dateFrom) : undefined}
                dateFormat="dd-MM-yyyy"
                popperPlacement="bottom-end"
                style={{ borderRadius: '0px 4px 4px 0px', border: '0.5px solid red' }}
                className="form-control date-select pl-4 date-picker"
                onChange={date => setState({ dateFrom: dayjs(dayjs(date)).format('YYYY-MM-DD') })}
                maxDate={new Date()}
                placeholderText="From"
                calendarClassName="custom-datepicker"
                data-testid="start-date"
              />
              <DatePicker
                selected={state.dateTo ? new Date(state.dateTo) : undefined}
                dateFormat="dd-MM-yyyy"
                popperPlacement="bottom-end"
                style={{ borderRadius: '0px 4px 4px 0px' }}
                className="form-control date-select pl-4 date-picker"
                minDate={state.dateFrom ? new Date(state.dateFrom) : undefined}
                maxDate={new Date()}
                onChange={date => setState({ dateTo: dayjs(dayjs(date)).format('YYYY-MM-DD') })}
                placeholderText="To"
                calendarClassName="custom-datepicker"
                data-testid="end-date"
              />
            </div>

            <button
              aria-label="filter transactions"
              type="button"
              className="settlement-filter__filter-button"
              onClick={() => filterTransactions()}
            >
              <img src={arrowRight} alt="arrow right icon" aria-hidden />
            </button>
          </div>
        </div>
        <div className="filter-search">
          <div className="filter-search search__container" style={{ margin: '0' }}>
            <img src={search} alt="search icon" className="search__icon" />
            <input
              type="search"
              aria-label="search transactions"
              name="searchHistory"
              id="searchtransactions"
              data-testid="searchtransactions"
              className="form-control form-control-sm"
              placeholder={`${
                activeTab === 'refunds'
                  ? 'Search by Refund ID, Transaction ID, or Customer Name...'
                  : 'Search by Chargeback ID, customer name, anything ...'
              }`}
              value={searchQuery.value.keyword}
              style={{ fontFamily: 'Averta PE' }}
              onChange={e => {
                if (e.target.value.trim() === '') {
                  handleClearFilter();
                } else {
                  setState({
                    keyword: e.target.value
                  });
                }
              }}
            />
            <button
              aria-label="search transactions"
              type="button"
              className="search__button"
              onClick={filterTransactions}
              aria-controls="searchtransactions"
              data-testid="searchbutton"
            >
              <img src={arrowRight} alt="arrow right icon" aria-hidden />
            </button>
          </div>
          <div className="mobile-search-container" onClick={() => setModalVisible(!modalVisible)}>
            <i className="os-icon os-icon-sliders" />
          </div>
        </div>
      </section>
    </>
  );
};

export default DisputeFilter;
