/* eslint-disable no-unused-vars */
import { CardAccessRequestStageType } from '+types';
import { capitalizeRemovedash } from '+utils';

import { requestAccessOptions } from './accessRequestHelpers';

interface ICardOptionsMenu {
  option: string | null;
  onOptionChange: (arg: CardAccessRequestStageType) => void;
  submitForm: () => Promise<void>;
}
const CardOptionsMenu = ({ option, onOptionChange, submitForm }: ICardOptionsMenu) => {
  return (
    <div className="px-2 pt-3">
      <ul className="card-options">
        {requestAccessOptions.map(({ title, value, description, btn }) => {
          const isCurrentlyActive = value === option;
          return (
            <li key={value} className="stack-lg">
              <button
                type="button"
                onClick={() => onOptionChange(value)}
                aria-expanded={isCurrentlyActive}
                aria-controls={`content-${value}`}
                className="block width-full request-access-btn"
                data-active={`${isCurrentlyActive}`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <span>{title}</span>
                  <i className={`os-icon ${isCurrentlyActive ? 'os-icon-chevron-up' : 'os-icon-chevron-down'}`} />
                </div>
                <div
                  id={`content-${value}`}
                  role="region"
                  aria-labelledby={`label-${value}`}
                  hidden={!isCurrentlyActive}
                  className="element-box request-access-content"
                  style={{ margin: 0 }}
                >
                  <p id={`label-${value}-content`} style={{ color: 'hsla(212, 19%, 31%, 1)', fontWeight: 300 }}>
                    {description}
                  </p>
                  <div className="d-flex justify-content-end">
                    <button
                      data-testid={`${btn}-btn`}
                      type="button"
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        submitForm();
                      }}
                      className="btn"
                    >
                      {capitalizeRemovedash(btn)}
                    </button>
                  </div>
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CardOptionsMenu;
