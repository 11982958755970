import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import useStore from '+store';

const useSurveyTrigger = ({
  count,
  surveyId,
  milestones,
  id,
  trigger,
  saveToLocalStorage = true,
}: {
  count?: number;
  surveyId: number;
  milestones?: number[];
  id: string;
  trigger?: boolean;
  saveToLocalStorage?: boolean;
}) => {
  const { startSurvey } = useIntercom();
  const MERCHANT_ENV = useStore(state => (state as { merchantEnv: string }).merchantEnv);
  useEffect(() => {
    if (MERCHANT_ENV !== 'live') return;
    if (process.env.REACT_APP_ATHENA_PLATE_INIT !== 'true') return;
    if (!trigger) return;
    if (count !== undefined) {
      let highestMilestoneReached: number | null = null;

      milestones?.forEach(milestone => {
        if (count >= milestone) {
          highestMilestoneReached = milestone;
        }
      });

      if (highestMilestoneReached !== null) {
        let surveyTriggered = false;

        milestones?.forEach(milestone => {
          if (milestone <= (highestMilestoneReached as number)) {
            const milestoneKey = `${id}SurveyFor${milestone}Triggered`;
            const hasSurveyBeenStartedForMilestone = localStorage.getItem(milestoneKey);

            if (hasSurveyBeenStartedForMilestone !== 'true' && !surveyTriggered) {
              startSurvey(+surveyId);
              surveyTriggered = true;
            }

            localStorage.setItem(milestoneKey, 'true');
          }
        });
      }
    } else {
      const surveyKey = `${id}surveyStarted`;
      const hasSurveyBeenStarted = localStorage.getItem(surveyKey);
      if (!hasSurveyBeenStarted || hasSurveyBeenStarted === 'false') {
        startSurvey(+surveyId);
        if(saveToLocalStorage) localStorage.setItem(surveyKey, 'true');
      }
    }
  }, [count, surveyId, milestones, startSurvey, id, trigger]);
};

export default useSurveyTrigger;
