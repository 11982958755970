/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';

import Icon from '+containers/Dashboard/Shared/Icons';
import CustomCheckbox from '+dashboard/Shared/CustomCheckbox';
import Modal from '+dashboard/Shared/Modal';
import { CardIssuanceServices } from '+services/card-issuance-services';
import useStore from '+store';
import { CardOpsManagementStageType, ICardManagementModal, IModalProps, TCardManagementModalFormValues } from '+types';

import CardManagementForm from './CardManagementForm';

export default function CardManagementModal({
  onClose = () => null,
  cardId,
  action: operation,
  currency,
  suspensionReason
}: ICardManagementModal) {
  const { profile } = useStore(store => store);
  const [stage, setStage] = useState<CardOpsManagementStageType>(operation);
  const [isSuccessfulUpdate, setIsSuccessfulUpdate] = useState(false);
  const [cardActionConfirmed, setCardActionConfirmed] = useState(false);
  const queryClient = useQueryClient();

  const { mutateAsync: mutateCardTermination } = CardIssuanceServices.useTerminateCard({
    errorMessage: 'We were unable to complete your action. Please try again',
    cardRef: cardId,
    onSuccess: () => setIsSuccessfulUpdate(true)
  });

  const { mutateAsync: mutateCardStatusUpdate } = CardIssuanceServices.useUpdateCardStatus({
    errorMessage: 'We were unable to complete your action. Please try again',
    cardRef: cardId,
    onSuccess: () => setIsSuccessfulUpdate(true)
  });

  const formik = useFormik({
    initialValues: {
      reason: '',
      reasonDescription: '',
      confirmCardAction: false,
      limitPeriod: '',
      currency,
      maxSpend: ''
    },
    validate: formValues => {
      const formErrors: Partial<{ [P in keyof TCardManagementModalFormValues]: string }> = {};
      const minimumCharacterLength = 3;

      if (['suspend_card', 'terminate_card'].includes(stage)) {
        if (!formValues.reason) formErrors.reason = 'Reason required';
        if (!formValues.reasonDescription) formErrors.reasonDescription = 'Please provide a description';
        if (formValues.reasonDescription && formValues.reasonDescription.length < minimumCharacterLength)
          formErrors.reasonDescription = 'Description cannot be less than 3 characters';
      }

      if (['confirm_card_suspension', 'confirm_card_termination'].includes(stage)) {
        if (formValues.confirmCardAction !== true) formErrors.confirmCardAction = 'Please confirm this action';
      }

      if (stage === 'manage_limits') {
        if (!formValues.limitPeriod) formErrors.limitPeriod = 'Please select a limit period';
        if (!formValues.maxSpend) formErrors.maxSpend = 'Please enter the maximum spend for this card';
      }

      return formErrors;
    },
    onSubmit: () => {}
  });

  const { setFieldValue, dirty, isValid, values } = formik;

  const handleSubmit = async () => {
    switch (stage) {
      case 'reactivate_card':
        await reactivateCard();
        break;
      case 'suspend_card':
        setStage('confirm_card_suspension');
        break;
      case 'confirm_card_suspension':
        await suspendCard();
        break;
      case 'terminate_card':
        setStage('confirm_card_termination');
        break;
      case 'confirm_card_termination':
        await terminateCard();
        break;
      case 'manage_limits':
        setStage('confirm_card_limits');
        break;
      default:
        break;
    }
  };
  const toggleActionConfirmation = (checked: boolean) => {
    setCardActionConfirmed(checked);
    setFieldValue('confirmCardAction', checked);
  };

  const { reason, reasonDescription } = values;

  const reasonText = reason === 'other' ? reasonDescription : reason;

  const suspendCard = async () => {
    await mutateCardStatusUpdate({
      action: 'suspend',
      description: reasonDescription,
      reason: reasonText,
      initiator: `${profile?.firstName} ${profile?.lastName}`
    });
  };

  const reactivateCard = async () => {
    await mutateCardStatusUpdate({
      action: 'activate',
      reason: 'Reactivate card',
      initiator: `${profile?.firstName} ${profile?.lastName}`
    });
  };

  const terminateCard = async () => {
    await mutateCardTermination({
      reason: reasonText,
      initiator: `${profile?.firstName} ${profile?.lastName}`,
      description: reasonDescription
    });
  };

  const handleCloseModal = () => {
    if (isSuccessfulUpdate) {
      queryClient.invalidateQueries({
        predicate: query => query.queryKey.includes(cardId)
      });
    }
    onClose();
  };

  const goToPreviousStage = () => {
    switch (stage) {
      case 'confirm_card_termination':
        setStage('terminate_card');
        return;
      case 'confirm_card_suspension':
        setStage('suspend_card');
        return;
      default:
        handleCloseModal();
    }
  };

  const renderConfirmCardAction = (
    <div className="confirm-action">
      {operation === 'suspend_card' && (
        <div className="confirm-action__copy">
          <Icon name="infoIcon" />
          <p className="confirm-action__disclaimer">
            <strong>Important:</strong> Once you confirm, card balance will be blocked and you won&apos;t be able to perform any transaction
            on it.
          </p>
        </div>
      )}

      <CustomCheckbox
        text={<p className="confirm-action__checkbox-label">Yes, I understand the implications of this action</p>}
        checked={cardActionConfirmed}
        onChange={toggleActionConfirmation}
        className="confirm-action__checkbox"
      />
    </div>
  );

  const modalPropOptions: Record<CardOpsManagementStageType | 'sharedProps', Partial<IModalProps>> = {
    sharedProps: {
      close: handleCloseModal,
      completedHeading: 'Done!',
      firstButtonAction: goToPreviousStage,
      secondButtonAction: handleSubmit,
      secondButtonText: 'Continue',
      secondButtonDisable: !(dirty && isValid),
      completedAction: handleCloseModal
    },
    suspend_card: {
      heading: <span style={{ fontSize: '1.25rem' }}>Suspend Card</span>,
      description: (
        <span style={{ fontSize: '0.925rem' }}>
          Suspending a card makes its balance blocked. Thus, transactions cannot be carried out on such card.
        </span>
      ),
      content: <CardManagementForm formik={formik} action="suspend_card" />,
      secondButtonActionIsTerminal: false
    },
    confirm_card_suspension: {
      heading: <span style={{ fontSize: '1.25rem' }}>Confirm card suspension</span>,
      description: <span style={{ fontSize: '0.925rem' }}>Please confirm that you want to suspend this virtual card.</span>,
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Confirm',
      secondButtonDisable: !cardActionConfirmed,
      firstButtonText: 'Back',
      completedDescription: 'You have successfully suspended this virtual card.',
      size: 'sm',
      content: renderConfirmCardAction
    },
    reactivate_card: {
      secondButtonActionIsTerminal: true,
      secondButtonDisable: false,
      heading: 'Confirm reactivation',
      size: 'sm',
      description: (
        <span>
          {suspensionReason ? `This card was suspended due to ${suspensionReason}.` : null}
          Please confirm that you want to reactivate this card.
        </span>
      ),
      completedDescription: 'You have successfully reactivated this virtual card.'
    },
    terminate_card: {
      heading: <span style={{ fontSize: '1.25rem' }}>Terminate Card</span>,
      description: <span style={{ fontSize: '0.925rem' }}>Please follow the prompt below to terminate this card.</span>,
      content: <CardManagementForm formik={formik} action="terminate_card" />,
      secondButtonActionIsTerminal: false,
      secondButtonAction: () => setStage('confirm_card_termination'),
      secondButtonExtraStyle: 'btn-red'
    },
    confirm_card_termination: {
      heading: <span style={{ fontSize: '1.25rem' }}>Confirm card termination</span>,
      description: <span style={{ fontSize: '0.925rem' }}>Please confirm that you want to terminate this virtual card.</span>,
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Confirm',
      completedDescription: 'You have successfully terminated this virtual card.',
      secondButtonExtraStyle: 'btn-red',
      secondButtonDisable: !cardActionConfirmed,
      size: 'sm',
      content: renderConfirmCardAction
    },
    manage_limits: {
      heading: <div className="text-lg">Manage virtual card spending limit</div>,
      description: (
        <div className="text-md">This limit defines the amount of money that is accessible on this virtual card per period selected.</div>
      ),
      secondButtonActionIsTerminal: false,
      secondButtonText: <span className="text-md">Continue</span>,
      firstButtonText: <span className="text-md">Cancel</span>
    },
    confirm_card_limits: {
      secondButtonActionIsTerminal: true,
      heading: <div className="text-lg">Confirm change(s) to card limit</div>,
      secondButtonText: <span className="text-md">Yes, Confirm</span>,
      firstButtonText: <span className="text-md">Back</span>,
      description: (
        <span className="text-md">
          Kindly confirm that you want to make changes to card spending limit. New limit will take effect immediately.
        </span>
      ),
      completedHeading: 'Success',
      completedDescription: 'You have successfully updated reserved virtual card limits.',
      size: 'sm'
    }
  };

  const modalProps = {
    ...modalPropOptions.sharedProps,
    ...(modalPropOptions[stage] || {})
  };

  return (
    <div>
      <Modal {...(modalProps as IModalProps)} />
    </div>
  );
}
