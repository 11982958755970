/* eslint-disable camelcase */
import { Props } from 'react-select';

import { CardAccessRequestStageType, RVCStageValidationType } from '+types';

export const minCharLength = 3;
export const maxDescriptionLength = 100;
export const maxAddressCharLength = 50;
export const maxPostalCodeLength = 10;
export const MAX_BVN_LEN = 11;
export const customerCardPlansDoc = process.env.REACT_APP_RVC_CUSTOMER_CARD_PLANS || '';
export const reservedCardPlansDoc = process.env.REACT_APP_RVC_BUSINESS_CARD_PLANS || '';

export const initialValues = {
  country: '',
  city: '',
  state: '',
  postalCode: '',
  street: '',
  useCase: '',
  description: '',
  dob: '',
  cardholderName: '',
  customerCardMonthlyPaymentValue: '',
  pciDssLevel: '',
  subscriptionPlan: ''
};

export const stages: Record<CardAccessRequestStageType, CardAccessRequestStageType> = {
  set_card_options: 'set_card_options',
  set_rvc_kyc: 'set_rvc_kyc',
  set_reserved_vc_preferences: 'set_reserved_vc_preferences',
  set_customer_vc_preferences: 'set_customer_vc_preferences',
  set_no_usd_access: 'set_no_usd_access'
} as const;

export const stageValidationOptions: Partial<RVCStageValidationType<typeof initialValues>> = {
  set_rvc_kyc: formValues => {
    const { dob, cardholderName } = formValues;
    const errors: Partial<typeof initialValues> = {};

    if (!dob) errors.dob = 'Date of birth is required';
    if (!cardholderName) errors.cardholderName = 'A cardholder is required';

    return errors;
  },
  set_reserved_vc_preferences: formValues => {
    const { subscriptionPlan } = formValues;
    const errors: Partial<typeof initialValues> = {};
    if (!subscriptionPlan) errors.subscriptionPlan = 'A subscription plan is required';
    return errors;
  },
  set_customer_vc_preferences: formValues => {
    const { customerCardMonthlyPaymentValue, pciDssLevel, subscriptionPlan } = formValues;
    const errors: Partial<typeof initialValues> = {};

    if (!customerCardMonthlyPaymentValue) errors.customerCardMonthlyPaymentValue = 'A monthly payment value is required';
    if (!pciDssLevel) errors.pciDssLevel = 'A PCI DSS level is required';
    if (!subscriptionPlan) errors.subscriptionPlan = 'A subscription plan is required';

    return errors;
  }
};

export const requestAccessOptions: Array<{ title: string; value: CardAccessRequestStageType; description: string; btn: string }> = [
  {
    title: 'Reserved Cards: Get virtual cards for your business',
    value: 'set_reserved_vc_preferences',
    description:
      'Set up your access to issue virtual cards for your business and team. You would be able easily generate and manage them right from your dashboard.',
    btn: 'set-up'
  },
  {
    title: 'Customer Cards: Issue virtual cards to your customers',
    value: 'set_customer_vc_preferences',
    description:
      'Request access to issue virtual cards to your customers. Number of cards issuable is determined by your PCI DSS level. These cards can be generated via API and managed on the dashboard.',
    btn: 'request'
  }
];

export const useCaseOptions = [
  { label: 'Employee expense management', value: 'Employee expense management' },
  { label: 'Procurement', value: 'Procurement' },
  { label: 'Payroll', value: 'Payroll' },
  { label: 'Personal expense management', value: 'Personal expense management' },
  { label: 'Other', value: 'Other' }
] as const;

export const subscriptionPlanOptions = [
  { label: 'Startup', value: 'Startup' },
  { label: 'Growth', value: 'Growth' },
  { label: 'Enterprise', value: 'Enterprise' }
] as const;

export const customerCardMonthlyPaymentOptions = [
  { value: 'LessThan50K', label: '$50,000 and below' },
  { value: 'From50KTo100K', label: 'Between $50,000 - $100,000' },
  { value: 'From100KTo500K', label: 'Between $100,000 - $500,000' },
  { value: 'From500KTo1M', label: 'Between $500,000 - $1,000,000' },
  { value: 'Above1M', label: 'Above $1,000,000' }
] as const;

export const reservedCardMonthlyPaymentOptions = [
  { value: 'LessThan1K', label: '$1,000 and below' },
  { value: 'From1KTo5K', label: 'Between $1,000 - $5,000' },
  { value: 'From5KTo10K', label: 'Between $5,000 - $1,0000' },
  { value: 'From10KTo50K', label: 'Between $10,000 - $50,000' },
  { value: 'Above50K', label: 'Above $50,000' }
] as const;

export const pciDssLevels = [
  { value: 'level_0', label: '0' },
  { value: 'level_1', label: '1' },
  { value: 'level_2', label: '2' },
  { value: 'level_3', label: '3' },
  { value: 'level_4', label: '4' }
] as const;

export const reactSelectStyles: Props['styles'] = {
  container: base => ({
    ...base,
    ' *': {
      fontSize: '0.85rem'
    },
    ':focus-visible': {
      outlineColor: 'transparent'
    }
  }),
  control: base => ({
    ...base,
    boxShadow: 'none',
    border: '2px solid #dde2ec',
    borderRadius: '4px',
    outlineColor: 'transparent',
    ':has(input[aria-invalid=true])': {
      borderColor: '#ff5661'
    },
    ':focus-within': {
      borderColor: '#047bf8'
    }
  }),
  menuList: base => ({
    ...base,
    maxHeight: '180px'
  }),
  placeholder: base => ({
    ...base,
    color: '#0003'
  }),
  dropdownIndicator: base => ({
    ...base,
    position: 'static'
  }),
  singleValue: base => ({
    ...base,
    color: '#495057'
  })
};
