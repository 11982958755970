/* eslint-disable no-nested-ternary */
import { useEffect, useReducer, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { ScrollToTopSection } from '+containers/Shared/ScrollToTop';
import { TransactionServices } from '+services/transaction-services';
import useStore from '+store';
import { capitalizeRemovedash, cardStatus, formatAmount, formatStatus, history, prefixWithArticle } from '+utils';

import {
  ActiveTabType,
  AdditionalInfoType,
  DataType,
  EventType,
  EventUpdatePayloadType,
  PaymentAmountDetailsType,
  PaymentChannelsType,
  RefundsAndChargebackBreakdownType,
  RelatedTransactionsType,
  TransactionBreakdownType,
  TransactionT
} from '../../../types/transaction-types';
import CreateRefundsModal from '../Shared/CreateRefundsModal';
import DeclineChargeBackModal from '../Shared/DeclineChargeBackModal';
import LoadingPlaceholder from '../Shared/LoadingPlaceholder';
import Modal from '../Shared/Modal';
import ReceiptModal, { IReceiptData } from '../Shared/ReceiptTemplate';
import ToolTip from '../Shared/Tooltip';
import Webhooks from './components/Webhooks/Webhooks';
import { chargebacksTrxnMessage, getTransactionUtilities, switchDetailsHeading, switchTrxnMessage } from './data';

import Flag from '+assets/img/dashboard/greenflag.svg';
import InfoIcon from '+assets/img/dashboard/information-button.svg';
import OverpaymentSVG from '+assets/img/dashboard/overpayment.svg';
import UnderpaymentSVG from '+assets/img/dashboard/underpayment.svg';

import './index.scss';

function TransactionDetails() {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation<{ pathname: string }>();
  const defaultMerchant = useStore((store: any) => store.defaultMerchant);

  const permissions = useStore((state: any) => state.permissions);
  const transactionType: TransactionT = pathname.split('/').slice(-2)[0] as TransactionT;

  const getFormattedStatus = () => {
    if (transactionType === 'funding-deposit') {
      return formatStatus(status);
    }
    return status;
  };

  const initialEventState: EventType = {
    extraPaymentTabs: ['Refunds', 'Reversals', 'Chargebacks'],
    activeTab: 'Refunds',
    refundsModal: false,
    respondChargebackModal: false,
    IssuanceChargebackModalIsVisible: false,
    approve: false,
    reversalsLength: 0,
    refundsLength: 0,
    chargebacksLength: 0,
    showReceiptModal: false,
    txReceiptData: {} as IReceiptData,
    paymentType: false
  };

  const eventStateReducer = (prevState: EventType, newState: EventUpdatePayloadType): EventType => {
    return { ...prevState, ...newState };
  };

  const [event, updateEvent] = useReducer(eventStateReducer, initialEventState);

  const {
    data: response,
    isLoading,
    refetch
  } = TransactionServices.useTransactionDetails({
    id,
    stage: transactionType,
    errorMessage: `There has been an error fetching the details for the transaction: ${id?.toUpperCase()}.`,
    onError: () => {
      history.goBack();
    }
  });
  let data = response?.data;
  data = data?.data?.[0] ?? data?.data ?? data;

  const { data: relatedTrxns } = TransactionServices.useGetRelatedTransactions({
    enabled: transactionType === 'pay-ins' && !!data,
    ref: data?.payment?.reference,
    errorMessage: 'There was an error getting related transactions'
  });

  const relatedTrxnsWithoutCurrentTrxn = relatedTrxns?.data.filter((trx: RelatedTransactionsType) => trx.reference !== data.reference);

  const {
    reference,
    merchant_reference: merchantReference,
    unique_reference: uniqueReference,
    amount,
    amount_charged: amountCharged,
    amount_collected: amountCollected,
    meta,
    payment_reversals: reversals,
    currency,
    payment,
    status,
    can_request_webhook: canRequestWebhook,
    destination_currency: destinationCurrency,
    converted_amount: convertedAmount,
    remittance_data
  } = (data || {}) as DataType;

  useEffect(() => {
    if (reversals) {
      const isActiveTab: Record<ActiveTabType, () => void> = {
        refund: () => updateEvent({ activeTab: 'Refunds' }),
        underpayment: () => updateEvent({ activeTab: 'Reversals' }),
        overpayment: () => updateEvent({ activeTab: 'Reversals' }),
        chargeback: () => updateEvent({ activeTab: 'Chargebacks' })
      };

      const rev: ActiveTabType = reversals[0]?.type;
      isActiveTab[rev]?.();
    }
  }, [reversals]);

  const getTrxnStatusMessage = () => {
    const formattedStatus = getFormattedStatus();
    if (!formattedStatus) return switchTrxnMessage.processing;

    if (
      typeof formattedStatus === 'string' &&
      ['processing', 'failed', 'pending', 'manual'].includes(formattedStatus?.toLowerCase()) &&
      transactionType === 'refunds'
    )
      return switchTrxnMessage.processing;

    if (transactionType === 'issued-card-chargebacks')
      return chargebacksTrxnMessage[formattedStatus as keyof typeof chargebacksTrxnMessage];

    return switchTrxnMessage[formattedStatus];
  };

  const trxnMessage = getTrxnStatusMessage();

  const [
    {
      refundsAndChargebackBreakdown,
      paymentChannels,
      virtualAccount,
      refundsAndReversal,
      renderIssuanceChargebackHistory,
      renderIssuanceChargebackDocs,
      transactionBreakdown,
      morePaymentDetails,
      additionalInfo,
      paymentChannelInfo,
      actionButtons,
      paymentTypesDetails,
      relatedTransactions,
      conversionsRelatedTransactions
    },
    setTransactionUtilities
  ] = useState(
    getTransactionUtilities(
      { ...(data! as DataType), transactionType, id, defaultMerchant, permissions, status: getFormattedStatus() },
      { event, updateEvent }
    )
  );

  useEffect(() => {
    setTransactionUtilities(
      getTransactionUtilities(
        { ...(data! as DataType), transactionType, id, defaultMerchant, permissions, status: getFormattedStatus() },
        { event, updateEvent }
      )
    );
  }, [data, event]);
  return (
    <>
      <div className="transaction-details__comp">
        <div className="header-row">
          <div className="col-sm-12" style={{ padding: '0px' }}>
            <button type="button" className="btn btn-link goback-btn" onClick={() => history.goBack()}>
              <i className="os-icon os-icon-arrow-left7" />
              <span>Go Back</span>
            </button>
            {!isLoading && (
              <section className="invoice-heading">
                <div className="invoice-details">
                  <span className="amount-heading">
                    {['refunds', 'chargebacks'].includes(transactionType) ? (
                      merchantReference?.toUpperCase() || reference?.toUpperCase()
                    ) : (
                      <>
                        {formatAmount(Number(amountCharged || amountCollected || amount || convertedAmount || '0.00'))}
                        <span>{transactionType === 'conversions' ? destinationCurrency : currency}</span>
                      </>
                    )}
                  </span>
                  <div className="invoice-date">
                    <p
                      style={{
                        color: trxnMessage?.color,
                        backgroundColor: trxnMessage?.backgroundColor,
                        margin: '0px',
                        padding: '2px 5px'
                      }}
                    >
                      {status === 'flagged' && <img src={Flag} alt="" />}
                      <span>{trxnMessage?.name}</span>
                    </p>
                  </div>
                </div>

                <div className="action-btns-container">
                  {actionButtons.map(({ label, onClick, leftIcon = null, rightIcon, color, ...btnProps }) => (
                    <button
                      key={label}
                      className={`btn btn-${color || 'secondary'}`}
                      type="button"
                      onClick={onClick}
                      {...btnProps}
                      {...(meta?.charge_operation === 'capture' &&
                        label.toLowerCase().includes('refund') && {
                          style: { display: 'none' }
                        })}
                    >
                      {leftIcon && <span className="mr-2">{leftIcon}</span>}
                      {label}
                      {rightIcon && <span className="ml-2">{rightIcon}</span>}
                    </button>
                  ))}
                </div>
              </section>
            )}
          </div>
        </div>
        <div className="element-box transaction-details-container">
          <section className="trxn-information">
            {isLoading ? (
              <LoadingPlaceholder type="text" content={4} />
            ) : (
              <article>
                <ul className="trxn-breakdown-list">
                  {!['refunds', 'chargebacks'].includes(transactionType)
                    ? Object.keys(transactionBreakdown).map(item => {
                        if (
                          !transactionBreakdown[item as keyof TransactionBreakdownType] ||
                          transactionBreakdown[item as keyof TransactionBreakdownType] === ' '
                        )
                          return null;
                        return (
                          <li key={item}>
                            <p>
                              {item === 'transaction_id' && 'Transaction ID'}
                              {item !== 'transaction_id' && capitalizeRemovedash(item)}
                              {item === 'net_amount' && (
                                <ToolTip type="net_amount" image={InfoIcon} message={<>This is the amount less fees</>} />
                              )}
                              {item === 'fee' && (
                                <ToolTip
                                  image={InfoIcon}
                                  message={
                                    <em>
                                      {' '}
                                      Fees <br /> Total charges incurred while processesing this transaction.
                                    </em>
                                  }
                                />
                              )}
                            </p>
                            <p>{transactionBreakdown[item as keyof TransactionBreakdownType]}</p>
                          </li>
                        );
                      })
                    : Object.keys(refundsAndChargebackBreakdown).map(item => {
                        if (
                          !refundsAndChargebackBreakdown[item as keyof RefundsAndChargebackBreakdownType] ||
                          refundsAndChargebackBreakdown[item as keyof RefundsAndChargebackBreakdownType] === ' '
                        )
                          return null;
                        return (
                          <li key={item}>
                            <p>
                              {item === 'transaction_id' && 'Transaction ID'}
                              {item !== 'transaction_id' && capitalizeRemovedash(item)}
                            </p>
                            <p>{refundsAndChargebackBreakdown[item as keyof RefundsAndChargebackBreakdownType]}</p>
                          </li>
                        );
                      })}
                </ul>
              </article>
            )}
          </section>
        </div>
      </div>
      <div className="transaction-details__comp_2">
        <div className="transaction-details-container-2">
          <section className="customer-information">
            {isLoading ? (
              <LoadingPlaceholder type="text" content={4} />
            ) : (
              <article>
                <ul>
                  <li>
                    <div className="section-heading more-trxn-heading">
                      {switchDetailsHeading[transactionType]} Details
                      {transactionType === 'pay-ins' && !Object.keys(cardStatus).includes(status) && (
                        <span className="section-heading-link" onClick={() => updateEvent({ paymentType: true })}>
                          View Amount Breakdown <i className="os-icon os-icon-arrow-up-right" />
                        </span>
                      )}
                    </div>
                    {meta?.payment_event_action?.event && (
                      <div className="overpayment-underpayment-info">
                        <img src={meta?.payment_event_action?.event === 'overpayment' ? OverpaymentSVG : UnderpaymentSVG} alt="" />
                        <span>
                          There is <strong>{prefixWithArticle(meta?.payment_event_action?.event)}</strong> on this transaction.
                        </span>
                      </div>
                    )}
                    {Object.keys(morePaymentDetails).map(item => (
                      <p key={item}>
                        <span>
                          {item === 'rnn' && (
                            <>
                              RNN <ToolTip image={InfoIcon} type="rnn" message="Reference Retrieval Number" />
                            </>
                          )}
                          {item === 'stan' && (
                            <>
                              STAN <ToolTip image={InfoIcon} type="stan" message="System Trace Audit Number" />
                            </>
                          )}
                          {!['stan', 'rnn'].includes(item) && capitalizeRemovedash(item)}
                          {item === 'approval_code' && <ToolTip image={InfoIcon} message="Also known as ‘Acquirer code" />}
                          {item === 'cross-currency_status' && (
                            <ToolTip
                              image={InfoIcon}
                              message="This defines whether a transaction is performed in the issued virtual card currency or not."
                            />
                          )}
                          {item === 'expected_resolution_date' && (
                            <ToolTip image={InfoIcon} message="This chargeback will be resolved on or before this date" />
                          )}
                        </span>
                        <span>{morePaymentDetails[item]}</span>
                      </p>
                    ))}
                  </li>

                  <li>
                    {paymentChannelInfo?.title ? (
                      <p className="section-heading" id="section-title">
                        {paymentChannelInfo?.title}
                      </p>
                    ) : null}

                    {Object.keys(paymentChannelInfo?.data || {}).map(item => (
                      <p key={item}>
                        {['bank_account', 'card', 'wallet', 'mobile_money'].includes(item) ? (
                          paymentChannels(item as PaymentChannelsType)
                        ) : (
                          <>
                            <span>{capitalizeRemovedash(item)}</span>
                            <span>{paymentChannelInfo?.data?.[item as keyof AdditionalInfoType['data']]}</span>
                          </>
                        )}
                      </p>
                    ))}
                  </li>
                  {remittance_data && (
                    <li className="mb-5">
                      <div>
                        {additionalInfo?.remittanceHeading && (
                          <p className="section-heading" id="section-title">
                            {additionalInfo?.remittanceHeading}
                          </p>
                        )}
                        {Object.keys(additionalInfo?.remittance_data || {}).map(item => (
                          <p key={item}>
                            <span>{capitalizeRemovedash(item)}</span>
                            <span>{additionalInfo?.remittance_data?.[item as keyof AdditionalInfoType]}</span>
                          </p>
                        ))}
                      </div>
                    </li>
                  )}
                  <li>
                    {additionalInfo?.title ? (
                      <p className="section-heading" id="section-title">
                        {additionalInfo?.title}
                      </p>
                    ) : null}
                    {Object.keys(additionalInfo?.data || {}).map(item => (
                      <p key={item}>
                        {['bank_account', 'card', 'wallet', 'mobile_money'].includes(item) ? (
                          paymentChannels(item as PaymentChannelsType)
                        ) : (
                          <>
                            <span>{capitalizeRemovedash(item)}</span>
                            <span>{additionalInfo?.data?.[item as keyof AdditionalInfoType['data']]}</span>
                          </>
                        )}
                      </p>
                    ))}
                  </li>

                  {/* {source?.virtual_bank_account && <li>{virtualAccount()}</li>} */}

                  {!!relatedTrxnsWithoutCurrentTrxn?.length && (
                    <li>{relatedTransactions({ uniqueRef: payment.reference, trxns: relatedTrxnsWithoutCurrentTrxn })}</li>
                  )}
                  {data?.source?.reference && <li>{conversionsRelatedTransactions({ transaction: data?.source })}</li>}
                  {['pay-ins', 'card-transactions'].includes(transactionType) && <li>{refundsAndReversal()}</li>}
                  <li>{transactionType === 'issued-card-chargebacks' && renderIssuanceChargebackHistory()}</li>
                  <li>{transactionType === 'issued-card-chargebacks' && renderIssuanceChargebackDocs()}</li>
                  {['pay-ins', 'payouts'].includes(transactionType) && (
                    <li>
                      <Webhooks
                        paymentReference={payment?.reference}
                        reference={reference}
                        uniqueReference={uniqueReference}
                        transactionType={transactionType}
                        canRequestWebhook={canRequestWebhook}
                      />
                    </li>
                  )}
                </ul>
              </article>
            )}
          </section>
        </div>
      </div>
      {event.refundsModal && (
        <CreateRefundsModal
          visible={event.refundsModal}
          reference={payment?.reference}
          refetchRefund={refetch}
          currency={currency}
          close={() => updateEvent({ refundsModal: false })}
        />
      )}

      {event.paymentType && (
        <Modal
          close={() => updateEvent({ paymentType: false })}
          heading="Amount Breakdown"
          description={
            <p style={{ color: '#414F5F', fontWeight: 400 }}>
              This is a detailed breakdown of the amount on this transaction, including the individual components that contribute to the
              final value of the transaction.
            </p>
          }
          content={
            <>
              {meta?.payment_event_action?.event && (
                <div className="overpayment-underpayment-info">
                  <img
                    src={meta?.payment_event_action?.event === 'overpayment' ? OverpaymentSVG : UnderpaymentSVG}
                    alt={`${meta?.payment_event_action?.event} icon`}
                  />
                  <span>
                    There is <strong>{prefixWithArticle(meta?.payment_event_action?.event)}</strong> on this transaction.
                  </span>
                </div>
              )}
              {Object.keys(paymentTypesDetails).map(item => (
                <p className="overpayment-underpayment-list" key={item}>
                  <span className="overpayment-underpayment-list-left-side">
                    {capitalizeRemovedash(item)}
                    {item === 'amount_expected' && (
                      <ToolTip
                        image={InfoIcon}
                        type="amount_expected"
                        message="This is the entire amount that the customer was supposed to pay."
                      />
                    )}
                    {item === 'amount_paid' && (
                      <ToolTip image={InfoIcon} type="amount_paid" message="This is the total amount paid (including fees)." />
                    )}
                    {item === 'amount_charged' && (
                      <ToolTip
                        image={InfoIcon}
                        type="amount_charged"
                        message="This is the total amount to be settled, excluding fees and any reserves."
                      />
                    )}

                    {item === 'fees' && <ToolTip image={InfoIcon} type="fees" message="This is the sum of the transaction fees." />}
                    {item === 'tax' && <ToolTip image={InfoIcon} type="tax" message="This is the sum of the tax deducted" />}
                  </span>
                  <span className="overpayment-underpayment-list-right-side">
                    {paymentTypesDetails[item as keyof PaymentAmountDetailsType]}
                  </span>
                </p>
              ))}
            </>
          }
          firstButtonText="Close"
          hideSecondButton
        />
      )}
      <DeclineChargeBackModal
        visible={event.respondChargebackModal}
        reference={reference}
        refetchChargeBack={refetch}
        currency={currency}
        approve={event.approve}
        amount={Number(amount)}
        close={() => updateEvent({ respondChargebackModal: false })}
      />

      {transactionType !== 'funding-deposit' ? <section className="back-to-top">{ScrollToTopSection()}</section> : null}

      {event.showReceiptModal && <ReceiptModal data={event.txReceiptData} close={() => updateEvent({ showReceiptModal: false })} />}
    </>
  );
}

export default TransactionDetails;
