/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'react-router-dom';

import ImgEmptyState from '+assets/img/dashboard/empty-state.png';

interface EmptyStateMessageProps {
  message?: string | React.ReactNode;
  hideMessage: boolean;
}
interface EmptyStateComponentProps {
  heading: string | React.ReactNode;
  message?: string | React.ReactNode;
  hideMessage?: boolean;
}

const EmptyStateMessage: React.FC<EmptyStateMessageProps> = ({ message, hideMessage }) => {
  if (hideMessage) return null;

  return (
    <p>
      {message || (
        <>
          It looks like there are no results yet. You can <Link to="/dashboard/home">make a payment</Link> &nbsp;or&nbsp;
          <Link to="/dashboard/payment-links/new">get paid</Link> easily.
        </>
      )}
    </p>
  );
};

const EmptyStateComponent: React.FC<EmptyStateComponentProps> = ({ heading, message, hideMessage = false }) => {
  return (
    <div className="empty-state-content" data-testid="empty-state-content">
      <div className="empty-state-image">
        <img alt="Nothing" src={ImgEmptyState} loading="lazy" />
      </div>
      <p className="heading">{heading}</p>
      <EmptyStateMessage message={message} hideMessage={hideMessage} />
    </div>
  );
};

export default EmptyStateComponent;
