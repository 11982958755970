import { useState } from 'react';

import { AuthServices } from '+services/auth-services';
import { TwoFactorAuthType } from '+types';

type StateType = {
  code: string;
  identifier: string;
  two_factor_type: TwoFactorAuthType;
};

const useOTPAuth = () => {
  const [countdownIsCompleted, setCountdownIsCompleted] = useState(false);
  const [authState, setAuthState] = useState<StateType>({
    code: '',
    identifier: '',
    two_factor_type: 'otp'
  });

  const { mutateAsync: mutateOTPResend } = AuthServices.useResendOTPAction({
    bannerLevel: true,
    errorMessage: 'We are sorry, something went wrong. Please try again.',
    onError: () => {},
    showSuccessMessage: false
  });

  const updateAuthState = (arg: Partial<StateType>) => {
    setAuthState(prev => ({ ...prev, ...arg }));
  };

  const resendOTP = async () => {
    await mutateOTPResend({ identifier: authState.identifier });
  };

  const updateCountdownStatus = (arg: boolean) => {
    setCountdownIsCompleted(arg);
  };

  return {
    authState,
    countdownIsCompleted,
    resendOTP,
    updateAuthState,
    updateCountdownStatus
  };
};

export default useOTPAuth;
