/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import { createContext, ForwardedRef, forwardRef, useCallback, useContext, useMemo } from 'react';
import composeClassNames from 'clsx';

import { ITab, ITabList, ITabPanel, ITabPanels, ITabs, TabsContextType, WithForwardRefType } from '+types';

let id = 0;
const generateId = () => ++id;
const makeId = (...args: Array<string | number>) => args.filter(val => val != null).join('--');

const TabsContext = createContext<TabsContextType<any> | undefined>(undefined);

function useTabsContext() {
  const context = useContext(TabsContext);
  if (context === undefined) throw new Error(`Tab* Component must be rendered as a child of Tabs`);
  return context;
}

export const Tabs: WithForwardRefType = forwardRef<HTMLDivElement, ITabs<string>>(
  <T extends string>({ children, defaultValue, className, onChange, ...props }: ITabs<T>, forwardedRef: ForwardedRef<HTMLDivElement>) => {
    const INTERNAL_CLASS_NAME = '';
    const tabsId = makeId('tab', generateId());
    const isTabActive = useCallback((value: T) => value === defaultValue, [defaultValue]);
    const onSelectTab = useCallback((tabVal: T) => {
      if (onChange) onChange(tabVal);
    }, []);
    const memoizedContext = useMemo(() => ({ tabsId, isTabActive, onSelectTab }), [isTabActive, onSelectTab]);

    return (
      <div className={composeClassNames(INTERNAL_CLASS_NAME, className)} ref={forwardedRef} {...props}>
        <TabsContext.Provider value={memoizedContext}>{children}</TabsContext.Provider>
      </div>
    );
  }
);

Tabs.displayName = 'Tabs';

export const TabList = forwardRef<HTMLUListElement, ITabList>(({ children, className, ...props }, forwardedRef) => {
  const INTERNAL_CLASS_NAME = 'tabs__tablist';
  return (
    <ul role="tablist" className={composeClassNames(INTERNAL_CLASS_NAME, className)} ref={forwardedRef} {...props}>
      {children}
    </ul>
  );
});

TabList.displayName = 'TabList';

export const Tab = forwardRef<HTMLButtonElement, ITab>(({ children, value, className, ...props }, forwardedRef) => {
  const INTERNAL_CLASS_NAME = 'tabs__tab';
  const { tabsId, isTabActive, onSelectTab } = useTabsContext();
  const panelId = makeId(tabsId, 'panel', value);
  const controllerId = makeId(tabsId, 'controller', value);
  const isActive = isTabActive(value);
  const onSelect = () => {
    onSelectTab(value);
  };

  return (
    <li>
      <button
        onClick={onSelect}
        type="button"
        id={controllerId}
        aria-controls={panelId}
        aria-selected={isActive}
        tabIndex={isActive ? 0 : -1}
        role="tab"
        className={composeClassNames(INTERNAL_CLASS_NAME, { active: isActive }, className)}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </button>
    </li>
  );
});

Tab.displayName = 'Tab';

export const TabPanels = forwardRef<HTMLDivElement, ITabPanels>(({ children, className, ...props }, forwardedRef) => {
  const INTERNAL_CLASS_NAME = 'tabs__panels';
  return (
    <div className={composeClassNames(INTERNAL_CLASS_NAME, className)} ref={forwardedRef} {...props}>
      {children}
    </div>
  );
});

TabPanels.displayName = 'TabPanels';

export const TabPanel = forwardRef<HTMLDivElement, ITabPanel>(({ children, value, className, ...props }, forwardedRef) => {
  const INTERNAL_CLASS_NAME = 'tabs__panel';
  const { tabsId, isTabActive } = useTabsContext();
  const isActive = isTabActive(value);
  const panelId = makeId(tabsId, 'panel', value);
  const controllerId = makeId(tabsId, 'controller', value);
  return isActive ? (
    <div
      tabIndex={isActive ? 0 : -1}
      hidden={!isActive ? true : undefined}
      aria-labelledby={controllerId}
      id={panelId}
      role="tab"
      className={composeClassNames(INTERNAL_CLASS_NAME, className)}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </div>
  ) : null;
});

TabPanel.displayName = 'TabPanel';
