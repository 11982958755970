/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import $ from 'jquery';

import {
  use2FAHistory,
  useCardAccessRequest,
  useFeedbackHandler,
  useInactivityTracker,
  useMerchantAuth,
  useMerchantKYC,
  usePageHeader,
  useRoles,
  useTour
} from '+hooks';
import { useSecurityToolTip } from '+hooks/useSecurityToolTip';
import { IdentityServices } from '+services/identity-services';
import { VirtualBankServices } from '+services/virtual-bank-services';
import { SuspenseLoader as KpyLoader } from '+shared/LazyComponent';
import useStore from '+store';
import useLocalStore from '+store/localStore';
import { AccessControlType } from '+types/auth-services-types';
import { actions, checkProductAccess, identifyClarityUser, isAuthenticated, storeExportLink } from '+utils';

import AuditLogs from './AuditLogs';
import Balances from './Balances';
import BulkPayouts from './BulkPayout';
import Conversions from './Conversions';
import Dispute from './Disputes';
import DashboardHome from './Home';
import Identity from './Identity';
import Issuing from './Issuing';
import LimitUpdateTip from './LimitUpdateTip';
import PayIn from './PayIn';
import PaymentLink from './PaymentLinks';
import PayOut from './PayOut';
import Settings from './Settings';
import ApiIntegrations from './Settings/ApiIntegrations';
import EmailConfigurationModal from './Settings/EmailConfiguration/components/EmailConfigurationModal';
import Merchant from './Settings/Merchant';
import Referrals from './Settings/Referrals';
import Security from './Settings/Security';
import SettlementsSettings from './Settings/Settlements';
import Team from './Settings/Teams';
import Settlement from './Settlements';
import BvnCollectionModal from './Shared/BvnCollectionModal';
import CurrencyNotificationBanner from './Shared/CurrencyNotificationBanner';
import DesktopNavigation from './Shared/DesktopNav';
import ExpiredExport from './Shared/ExpiredExport';
import FeedbackHandler from './Shared/FeedbackHandler';
import KycBanner from './Shared/KycBanner';
import MobileNavigation from './Shared/MobileNav';
import SecureAccountModal from './Shared/SecureAccountModal';
import SecurityTipModal from './Shared/SecurityTipModal';
import SessionExpiredModal from './Shared/SessionExpiredModal';
import TabletNavigation from './Shared/TabletNav';
import TwoFactorBanner from './Shared/TwoFactorBanner';
import TipsAndGuides from './TipsAndGuides';
import VirtualAccounts from './VirtualAccounts';

import defaultUserLogo from '+assets/img/dashboard/default-user-avatar.png';
import Loader from '+assets/img/dashboard/kpy-loader.svg';
import DefaultIcon from '+assets/img/dashboard/star.svg';
import TipsGuideIcon from '+assets/img/dashboard/tip-guide-white.svg';
// navbars

import './index.scss';

const Dashboard = () => {
  actions.track('merchant logged into dashboard');
  const authDetails = useStore(state => state.authDetails);
  const profile = useStore(state => state.profile);
  const defaultMerchant = useStore(state => state.defaultMerchant);
  const defaultCurrency = useStore(state => state.defaultCurrency);
  const MERCHANT_ENV = useStore(state => state.merchantEnv);
  const setSavedLocation = useStore(state => state.setSavedLocation);
  const guideListState = useLocalStore(state => state.list);
  const {
    generateSecurityTips,
    unClicked,
    setSecurityTipType,
    getSecurityTipProps,
    isTipVisible,
    setIsTipVisible,
    openSecurityTip,
    setOpenSecurityTip,
    securityTipType,
    permissions,
    isIPQueryComplete
  } = useSecurityToolTip();
  useInactivityTracker();
  const sessionActive = useStore(state => state.sessionActive);
  const queryClient = useQueryClient();
  const [accessControl, setAccessControl] = useState<AccessControlType | null>(null);
  const userToken = authDetails?.access_token;
  const [userRole, setUserRole] = useState('');
  const { feedbackInit } = useFeedbackHandler();
  const { update } = useIntercom();
  const { merchantKYC: status, setMerchantKYC } = useStore();
  IdentityServices.useGetIdentityAccess({
    queryKey: ['IDENTITY_ACCESS'],
    onSuccess: response => {
      const identityServiceAccess = response.data;

      if (identityServiceAccess) {
        useStore.setState({ identityServiceAccess });
      }
    }
  });

  const [bannerOpen, setBannerOpen] = useState<boolean | null>(null);
  const [is2FAQueryActivated, setIs2FAQueryActivated] = useState(false);
  const [bvnModal, setBvnModal] = useState(false);

  const location = useLocation();
  const { bootstrap, getProductAccess, handleLogout, updateMerchantEnv } = useMerchantAuth();

  const {
    data: get2FAHistory,
    isSuccess: is2FASuccess,
    isError: is2FAError
  } = use2FAHistory({
    enabled: !is2FAQueryActivated,
    onSuccess: () => {
      setIs2FAQueryActivated(false);
    },
    onError: err => {
      if (err?.response?.data?.message === 'user totp details not found') setBannerOpen(false);
    }
  });

  const is2FAQueryComplete = is2FASuccess || is2FAError;

  useEffect(() => {
    const activitateModal = is2FAQueryComplete && isIPQueryComplete;
    if (activitateModal) {
      if (bannerOpen === null && get2FAHistory?.data?.is_activated !== undefined) setBannerOpen(Boolean(get2FAHistory?.data?.is_activated));
      generateSecurityTips();
      setOpenSecurityTip(Boolean(get2FAHistory?.data?.is_activated));
    }
  }, [get2FAHistory?.data?.is_activated, is2FAQueryComplete, permissions, isIPQueryComplete]);

  const canAccessIssuing = () => {
    const userCanView =
      process.env.REACT_APP_FEATURE_FLAG_ISSUING_MERCHANT_LIST === '*' ||
      process.env.REACT_APP_FEATURE_FLAG_ISSUING_MERCHANT_LIST?.split(',')?.includes(defaultMerchant?.email);

    if (MERCHANT_ENV === 'live') {
      return userCanView;
    }
    const userHasProductAccess = checkProductAccess(defaultMerchant?.productAccess?.card_issuance);
    return userCanView && userHasProductAccess;
  };

  const isAllowedFVBA = accessControl?.collection?.virtualBankAccount;
  const canAccessFVBA = () => {
    if (MERCHANT_ENV === 'live') {
      if (!isAllowedFVBA) {
        // eslint-disable-next-line camelcase
        return false;
      }
      return true;
    }
    return true;
  };

  const matchDashboardComponents = {
    balance: ['balances', 'os-icon-server', Balances],
    payment_link: ['payment-links', 'os-icon-link-2', PaymentLink],
    payin: ['pay-ins', 'os-icon-arrow-down-left', PayIn],
    payout: ['payouts', 'os-icon-arrow-up-right', PayOut],
    settlement: ['settlements', 'os-icon-zap', Settlement],
    audit_log: ['audit-logs', 'os-icon-clipboard', AuditLogs],
    ...(canAccessFVBA() && {
      virtual_account: ['virtual-accounts', 'os-icon-hash', VirtualAccounts]
    }),
    setting: ['settings', 'os-icon-ui-46', Settings],
    identity: ['identity', 'os-icon-fingerprint', Identity],
    settlement_setting: ['settings/settlements', null, SettlementsSettings],
    api_configuration: ['settings/api-integrations', null, ApiIntegrations],
    security: ['settings/security', null, Security],
    compliance: ['settings/compliance', null, Merchant],
    team: ['settings/teams', null, Team],
    refund: ['disputes', 'os-icon-corner-up-left', Dispute],
    bulk_payouts: ['bulk-payout', 'os-icon-briefcase', BulkPayouts],
    conversions: ['conversions', 'os-icon-repeat', Conversions],
    referrals: ['referrals', 'os-icon-share-2', Referrals],

    // Feature flag dependent routes
    ...(canAccessIssuing() && {
      issuing: ['issuing', 'os-icon-layers', Issuing]
    })
  };

  const { pathname } = useLocation();
  const id = pathname.split('/')[3];
  const TRANSACTION_ID = queryClient.getQueryData([`TRANSACTION_DETAILS_${id}`]);

  const openBvnModal = () => {
    setBvnModal(true);
  };

  const [state, setState] = useState({
    isLoading: true,
    isUpdatingEnv: false,
    isLoadingMessage: 'Preparing your dashboard...',
    stepsEnabled: false,
    initialStep: 0
  });
  const [routes, router] = useRoles(matchDashboardComponents);
  const [initiateTour, StepComponent] = useTour();
  const heading = usePageHeader();

  VirtualBankServices.useGetAccessControl({
    currency: 'NGN',
    enabled: Boolean(userToken),
    errorMessage: 'There has been an error getting your Access status. Please refresh the page',
    onSuccess: data => {
      setAccessControl(data?.data);
    }
  });

  useLayoutEffect(() => {
    const setup = async () => {
      const { full } = isAuthenticated();
      if (full) {
        try {
          await bootstrap();
          await getProductAccess();
        } catch (error) {
          setState({
            ...state,
            isLoading: false,
            isLoadingMessage: ''
          });
        }
      } else {
        queryClient.setDefaultOptions({
          queries: {
            enabled: false,
            refetchOnWindowFocus: false
          }
        });
        if (!sessionActive) {
          setSavedLocation(`${window.location.pathname}${window.location.search}`);
          handleLogout('/auth/login');
        }
      }
    };
    setup().then(() => {
      queryClient.setDefaultOptions({
        queries: {
          enabled: true,
          refetchOnWindowFocus: false
        }
      });
      setState({
        ...state,
        isLoading: false,
        isLoadingMessage: ''
      });
    });

    useStore.setState({ sessionActive: true });
  }, [isAuthenticated().full]);

  useCardAccessRequest();
  useMerchantKYC(data => {
    setMerchantKYC(data?.data);
  });

  useEffect(() => {
    if (userToken) {
      const jwt = JSON.parse(atob(userToken.split('.')[1]));
      setUserRole(jwt?.permissions?.name || '');
    }
    if (profile && defaultMerchant) {
      update({
        name: `${profile?.firstName || 'Signed In User'} ${profile?.lastName || ''}`,
        email: profile?.email || '',
        company: {
          name: defaultMerchant?.name,
          companyId: defaultMerchant?.id
        },
        phone: profile.phone,
        createdAt: profile.created,
        customAttributes: { userRole, dashboardEnv: MERCHANT_ENV, merchantID: defaultMerchant?.id }
      });
      identifyClarityUser(profile?.email);
    }
  }, [userToken, profile, defaultMerchant]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // Initiate menu on small screens
  useEffect(() => {
    $('.mobile-menu-trigger').on('click', () => {
      setIsMenuOpen(!isMenuOpen);
      $('.menu-mobile.--mobile .menu-and-user').slideToggle(200, 'swing');
    });

    $('.menu-mobile.--mobile .main-menu li a:not(.has-sub-menu a)').on('click', () => {
      $('.menu-mobile.--mobile .menu-and-user').slideToggle(500, 'swing');
    });

    $('.sub-menu a').on('click', () => {
      $('.menu-mobile.--mobile .menu-and-user').slideToggle(500, 'swing');
    });

    $('.menu-mobile.--mobile .main-menu .has-sub-menu a').on('click', () => {
      $('.sub-menu').slideToggle(500, 'swing');
    });

    $('.menu-mobile.--tablet .main-menu .has-sub-menu a').on('click', () => {
      $('.sub-menu').slideToggle(500, 'swing');
    });

    return () => {
      $('.mobile-menu-trigger').off();
      $('.menu-mobile.--mobile .main-menu li a:not(.has-sub-menu a)').off();
      $('.sub-menu a').off();
      $('.menu-mobile.--mobile .main-menu .has-sub-menu a').off();
      $('.menu-mobile.--tablet .main-menu .has-sub-menu a').off();
    };
  });

  const updateHamburgerState = () => {
    setIsMenuOpen(false);
    $('.menu-mobile.--mobile .menu-and-user').slideUp(200, 'swing');
  };
  useEffect(() => {
    updateHamburgerState();
  }, [location.pathname]);

  const toggleEnvMode = async () => {
    const complianceStatus = status?.compliance?.status;
    try {
      setState(prevState => ({
        ...prevState,
        isUpdatingEnv: true
      }));
      if (!defaultMerchant?.can_go_live) {
        updateHamburgerState();
        setState(prevState => ({
          ...prevState,
          isUpdatingEnv: false
        }));
        return feedbackInit({
          message:
            complianceStatus === 'ready'
              ? `Verification in Progress - You can not go-live until your account is
          verified.`
              : `Please update your business details to go live`,
          type: 'warning'
        });
      }
      return await allowMerchantGoLive();
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        isUpdatingEnv: false
      }));
      updateHamburgerState();
      return feedbackInit({
        message:
          error?.response?.data?.message ||
          `An error occured, switching you to ${MERCHANT_ENV === 'test' ? 'live' : 'test'} mode. Please contact support`,
        type: 'danger'
      });
    }
  };

  useEffect(() => {
    storeExportLink(location);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (!sessionActive) {
        handleLogout();
      }
    };

    if (!sessionActive) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [sessionActive]);

  const allowMerchantGoLive = async () => {
    try {
      await updateMerchantEnv({
        environment: MERCHANT_ENV === 'test' ? 'live' : 'test'
      });
      await bootstrap();
      await getProductAccess();
      await queryClient.refetchQueries();
      updateHamburgerState();
    } catch (error) {
      feedbackInit({
        message:
          error?.response?.data?.message ||
          `An error occured, switching you to ${MERCHANT_ENV === 'test' ? 'live' : 'test'} mode. Please contact support`,
        type: 'danger'
      });
    } finally {
      setState(prevState => ({
        ...prevState,
        isUpdatingEnv: false
      }));
    }
  };

  const handleImageErr = e => {
    e.target.src = defaultUserLogo;
  };

  const logUserOut = () => {
    handleLogout();
    actions.track('merchant logged out');
    delete window.visitedKorapay;
  };

  return state.isLoading ? (
    <KpyLoader message={state.isLoadingMessage} />
  ) : (
    <>
      <main className="dashbaord_main menu-position-side menu-side-left full-screen with-content-panel">
        <div className="all-wrapper solid-bg-all">
          <div className="layout-w">
            <MobileNavigation
              logUserOut={logUserOut}
              routes={routes}
              toggleEnvMode={toggleEnvMode}
              isUpdatingEnv={state.isUpdatingEnv}
              userRole={userRole}
              isMenuOpen={isMenuOpen}
            />
            <TabletNavigation logUserOut={logUserOut} routes={routes} />
            <DesktopNavigation routes={routes} toggleEnvMode={toggleEnvMode} isUpdatingEnv={state.isUpdatingEnv} userRole={userRole} />
            <div className="content-w" style={{ minHeight: '100vh' }}>
              <div className="top-bar color-scheme-transparent">
                <div className="page-heading">
                  <span className="page-heading-text">{heading}</span>
                  {heading && heading === 'Transaction Details' ? (
                    <p className="transaction_id">
                      {pathname?.includes('/dashboard/payouts') ? id?.toUpperCase() : TRANSACTION_ID?.payment?.reference?.toUpperCase()}
                    </p>
                  ) : null}
                </div>
                <div className="top-menu-controls">
                  <div className="default-currency-div">
                    <img src={DefaultIcon} alt="star" className="default-star" />
                    <p className="default-currency">
                      Your default currency is <span>{defaultCurrency || 'N/A'}</span>
                    </p>
                  </div>
                  <div className="logged-user-w">
                    <div className="logged-user-i">
                      <div className="avatar-w">
                        <img alt="" src={profile?.avatar || defaultUserLogo} onError={handleImageErr} />
                      </div>
                      <div className="logged-user-menu color-style-bright">
                        <div className="logged-user-avatar-info">
                          <div className="avatar-w">
                            <img alt="" src={profile?.avatar || defaultUserLogo} onError={handleImageErr} />
                          </div>
                          <div className="logged-user-info-w">
                            <div className="logged-user-name">
                              {profile?.firstName} {profile?.lastName}
                            </div>
                            <div className="logged-user-role">{userRole || ''}</div>
                          </div>
                        </div>
                        <ul>
                          {guideListState?.[profile?.email]?.length > 0 && (
                            <li>
                              <a
                                className="tip-guide-anchor"
                                role="button"
                                onClick={() => setIsTipVisible(true)}
                                onKeyUp={() => setIsTipVisible(true)}
                                tabIndex={0}
                              >
                                <img src={TipsGuideIcon} alt="" />
                                <div className="tip-guide-text">
                                  <span>
                                    Tips and Guides
                                    {unClicked > 0 && <span className="tip-guide-length"> {unClicked} </span>}
                                  </span>
                                  <i className="os-icon os-icon-arrow-right" />
                                </div>
                              </a>
                            </li>
                          )}
                          <li>
                            <a role="button" onClick={logUserOut} onKeyUp={logUserOut} tabIndex={0}>
                              <i className="os-icon os-icon-signs-11" />
                              <span>Logout</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/**  ------------------- START - SWITCH MODES  ------------------- */}
                <div className="floated-colors-btn second-floated-btn --md">
                  <div
                    className={`os-toggler-w ${MERCHANT_ENV === 'live' ? 'on' : ''}`}
                    onClick={toggleEnvMode}
                    role="button"
                    onKeyDown={toggleEnvMode}
                    tabIndex={0}
                  >
                    {!state.isUpdatingEnv ? (
                      <div className="os-toggler-i">
                        <div className="os-toggler-pill" />
                      </div>
                    ) : (
                      <img src={Loader} alt="Loader" aria-hidden className="live-button--loader" />
                    )}
                  </div>
                  <span>Live Mode</span>
                </div>
                {/**  ------------------- END - SWITCH MODES  ------------------- */}
                {/** ------------------- END - TOP MENU CONTROLS ------------------ */}
              </div>
              {/** ------------------- END - TOP BAR ------------------ */}
              <FeedbackHandler />
              <div className="content-i --top-content-container">
                <div className="content-banner">
                  <KycBanner />
                  {bannerOpen && <TwoFactorBanner twoFaHistory={get2FAHistory?.data?.is_activated} />}
                  <CurrencyNotificationBanner twoFaHistory={get2FAHistory?.data?.is_activated} />
                </div>
                <div className="content-box">
                  <SessionExpiredModal />
                  <Switch>
                    <Route path="/dashboard/home">
                      <DashboardHome initiateTour={initiateTour} setBvnModal={openBvnModal} userRole={userRole} />
                    </Route>
                    <Route path="/dashboard/expired-export">
                      <ExpiredExport />
                    </Route>
                    {router.map(route => route)}
                    {router.length > 0 && <Redirect path="/dashboard" to="/dashboard/home" />}
                  </Switch>
                </div>
              </div>
            </div>
          </div>
          <div className="display-type" />
        </div>
        {StepComponent()}
      </main>
      <EmailConfigurationModal />
      <SecureAccountModal
        closeAction={() => {
          setOpenSecurityTip(true);
        }}
        visible={bannerOpen === false}
        close={() => {
          setBannerOpen(true);
        }}
      />
      <LimitUpdateTip />
      {guideListState?.[profile?.email]?.length > 0 && (
        <TipsAndGuides
          isOpen={isTipVisible}
          toggle={() => setIsTipVisible(false)}
          setStatus={type => {
            setSecurityTipType(type);
            setOpenSecurityTip(true);
            setIsTipVisible(false);
          }}
        />
      )}
      <SecurityTipModal
        visible={openSecurityTip && Boolean(securityTipType)}
        close={() => setOpenSecurityTip(!openSecurityTip)}
        hasCheckBox
        {...getSecurityTipProps()}
      />

      <BvnCollectionModal visible={bvnModal} close={() => setBvnModal(false)} />
    </>
  );
};

export default React.memo(Dashboard);
