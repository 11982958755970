import { Route, Switch } from 'react-router-dom';

import { useRolesNav } from '+hooks';
import { UtilServices } from '+services/util-services';
import useStore from '+store';
import { history } from '+utils';

import AppearanceSettings from './components/AppearanceSettings';
import GeneralPage from './components/GeneralPage';
import ProductSettings from './components/ProductSettings';
import SettingsTitle from './components/SettingsTitle';

import './index.scss';

const settingsRoutes = {
  general: { path: '/dashboard/settings/general', component: GeneralPage },
  compliance: { path: '/dashboard/settings/compliance', component: GeneralPage },
  security: { path: '/dashboard/settings/security', component: GeneralPage },
  team: { path: '/dashboard/settings/teams', component: GeneralPage },
  ip_address_configuration: { path: '/dashboard/settings/email-configuration', component: GeneralPage },
  referrals: { path: '/dashboard/settings/referrals', component: GeneralPage },
  settlements: { path: '/dashboard/settings/settlements', component: ProductSettings },
  limits: { path: '/dashboard/settings/limits', component: ProductSettings },
  api: { path: '/dashboard/settings/api-integrations', component: ProductSettings },
  bank_transfer_setting: { path: '/dashboard/settings/payment', component: ProductSettings },
  checkout_customization: { path: '/dashboard/settings/checkout_customization', component: AppearanceSettings }
};

const displaySettingsRoutes = {
  compliance: {
    title: 'Compliance',
    path: '/dashboard/settings/compliance',
    text: 'Manage regulatory documents and maintain compliance effortlessly.'
  },
  team: {
    title: 'Teams',
    path: '/dashboard/settings/teams',
    text: 'Add and manage team members with custom roles and permissions.'
  },
  security: {
    title: 'Security',
    path: '/dashboard/settings/security',
    text: 'Strengthen account protection with advanced security controls.'
  },
  notification_configuration: {
    title: 'Notifications',
    path: '/dashboard/settings/email-configuration',
    text: 'Manage and customize notifications related to your account and transactions.'
  },
  settlement_setting: {
    title: 'Settlements',
    path: '/dashboard/settings/settlements',
    text: 'Control how and when payments are deposited into your account.'
  },
  limits: {
    title: 'Limits & Product Access',
    path: '/dashboard/settings/limits',
    text: 'Define usage limits and grant access to your products.'
  },
  api_configuration: {
    title: 'API Configuration',
    path: '/dashboard/settings/api-integrations',
    text: 'Set up API keys and integrations to power your business payment.'
  },
  bank_transfer_setting: {
    title: 'Payment Preferences',
    path: '/dashboard/settings/payment',
    text: 'Choose how you accept payments and configure options.'
  },
  checkout_customization: {
    title: 'Checkout',
    path: '/dashboard/settings/checkout_customization',
    text: 'Personalize your checkout flow to match your brand and boost conversions.'
  },
  referrals: {
    title: 'Referrals',
    path: '/dashboard/settings/referrals',
    text: 'Invite friends and family to Kora and earn exciting bonuses.'
  }
};

function SettingsComponent() {
  const [options] = useRolesNav(displaySettingsRoutes);
  const setBanks = useStore(state => state.setBanks);
  UtilServices.useFetchCurrencies({
    onSuccess: data => {
      useStore.setState({ currencies: data?.data });
    }
  });
  UtilServices.useFetchBanks({
    onSuccess: data => {
      setBanks(data?.data?.data as string[]);
    }
  });
  return (
    <div className="settings-section">
      <div>
        <h4 className="settings-title">General Business Settings</h4>
        <div className="settings-grid">
          <SettingsTitle
            title="My Business"
            text="Update essential business details and company information."
            click={() => {
              history.push(`/dashboard/settings/general`, null);
            }}
          />
          {options.map(option => (option?.key?.toLowerCase()?.includes('compliance') ? option : ''))}
          {options.map(option => (option?.key?.toLowerCase()?.includes('teams') ? option : ''))}
          {options.map(option => (option?.key?.toLowerCase()?.includes('security') ? option : ''))}
          {options.map(option => (option?.key?.toLowerCase()?.includes('notifications') ? option : ''))}
          {options.map(option => (option?.key?.toLowerCase()?.includes('referrals') ? option : ''))}
        </div>
      </div>

      <div style={{ marginTop: '50px' }}>
        <h4 className="settings-title">Product & Payment Settings</h4>
        <div className="settings-grid">
          {options.map(option => (option?.key?.toLowerCase()?.includes('settlements') ? option : ''))}
          {options.map(option => (option?.key?.toLowerCase()?.includes('limits') ? option : ''))}
          {options.map(option => (option?.key?.toLowerCase()?.includes('api') ? option : ''))}
          {options.map(option => (option?.key?.toLowerCase()?.includes('payment') ? option : ''))}
        </div>
      </div>

      <div style={{ marginTop: '50px' }}>
        <h4 className="settings-title">Appearance Settings</h4>
        <div className="settings-grid">{options.map(option => (option?.key?.toLowerCase()?.includes('checkout') ? option : ''))}</div>
      </div>
    </div>
  );
}

export default function SettingsPage() {
  return (
    <Switch>
      <Route exact path="/dashboard/settings" component={SettingsComponent} />
      {Object.values(settingsRoutes).map(({ path, component }, index) => (
        <Route key={index} path={path} component={component} />
      ))}
    </Switch>
  );
}
