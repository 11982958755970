import React, { FC } from 'react';
import Select, { OptionsType, ValueType } from 'react-select';

interface ICustomReactSelectProps extends React.ComponentProps<typeof Select> {
  id: string;
  label: string;
  placeholder: string;
  isSearchable?: boolean;
  options: OptionsType<{ label: string; value: string }>;
  onChange: (value: ValueType<{ label: string; value: string }>, actionMeta: any) => void;
  classNamePrefix?: string;
  isBank?: boolean;
}

const CustomReactSelect: FC<ICustomReactSelectProps> = ({
  id,
  label,
  placeholder,
  isSearchable = false,
  options,
  onChange,
  classNamePrefix = '',
  components = {},
  styles = {},
  ...props
}) => {
  const customStyles = {
    singleValue: (base: any) => ({
      ...base,
      position: 'absolute',
      top: 'unset',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 'calc(100% - 40px)'
    }),
    control: (base: any) => ({
      ...base,
      boxShadow: 'none',
      border: '2px solid #eff2f7 !important;',
      marginBottom: '20px',
      borderRadius: '8px',
      height: '59px'
    }),
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: '#3E4B5B',
        position: 'absolute',
        top: 'unset'
      };
    },
    dropdownIndicator: (base: any) => ({
      ...base,
      position: 'absolute',
      top: '20%',
      right: '0%'
    })
  };

  const selectedOption = options?.find((option: { label: string; value: string }) => option.value === props.value);

  return (
    <Select
      components={components}
      id={id}
      aria-label={label}
      placeholder={placeholder}
      isSearchable={isSearchable}
      styles={{
        ...customStyles,
        indicatorSeparator: () => ({}),
        valueContainer: base => ({
          ...base,
          display: 'flex',
          height: '100%'
        }),
        ...styles
      }}
      options={options}
      onChange={onChange}
      classNamePrefix={classNamePrefix}
      {...props}
      value={selectedOption || ''}
    />
  );
};

export default CustomReactSelect;
