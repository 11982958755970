import '../index.scss';

interface ISettingsTitleProps {
  text: string;
  title: string;
  click: () => void;
}
const SettingsTitle = ({ title, text, click }: ISettingsTitleProps) => {
  return (
    <div className="settings-details" onClick={click}>
      <h5>{title}</h5>
      <p>{text}</p>
    </div>
  );
};

export default SettingsTitle;
