import { KYCServices } from '+services/kyc-services';
import useStore from '+store';

export default function useMerchantKYC(onSuccess?: () => void) {
  const profile = useStore(state => state.profile);
  return KYCServices.useFetchMerchantKYC({
    queryKey: ['merchants/info', profile?.email],
    onSuccess,
    errorMessage: 'There has been an error getting your KYC status. Please refresh the page'
  });
}
