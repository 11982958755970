import { FormikProps } from 'formik';

import { CurrencyType, TIssuedCardActionModalFormValues } from '+types';
import { backwardAmountInput, formatAmount, stripNonNumeric } from '+utils';

const CardWithdrawalForm = ({
  formik,
  cardBalance,
  walletBalance,
  currency
}: {
  formik: FormikProps<TIssuedCardActionModalFormValues>;
  cardBalance: number;
  walletBalance: number;
  currency: CurrencyType;
}) => {
  const { setFieldValue, values, getFieldProps, handleBlur, touched, errors } = formik;
  const sourceOfFundOptions = [
    { label: '- Select a source of fund -', value: '' },
    { label: `Issuing Balance (${formatAmount(walletBalance)} ${currency?.toUpperCase()})`, value: 'issuing_balance' }
  ];

  return (
    <div className="card-withdrawal-form">
      <div>
        <label htmlFor="recipient-of-fund">Withdraw funds to</label>
        <select className="form-control" id="recipient-of-fund" {...getFieldProps('sourceOfFund')} tabIndex={-1}>
          {sourceOfFundOptions.map(({ value, label }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
        <div className="d-flex align-items-center">
          <span>Card balance:</span>
          <strong className="ml-1">
            {formatAmount(cardBalance)} {currency.toUpperCase()}
          </strong>
        </div>
        <p className="sr-only">
          You&apos;re now withdrawing from your card balance. You have a current balance of {formatAmount(cardBalance)}{' '}
          {currency.toUpperCase()}
        </p>
      </div>

      <div>
        <label htmlFor="withdrawal-amount">Amount to withdraw ({currency.toUpperCase()})</label>
        <input
          type="text"
          name="withdrawalAmount"
          id="withdrawal-amount"
          value={values.withdrawalAmount}
          onChange={e => setFieldValue('withdrawalAmount', backwardAmountInput(stripNonNumeric(e.target.value)))}
          onBlur={handleBlur}
          className="form-control"
          placeholder="Enter amount"
        />

        <div aria-live="polite" role="alert">
          {touched.withdrawalAmount && errors.withdrawalAmount && (
            <p className="fade-in card-withdrawal-form__error">{errors.withdrawalAmount}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardWithdrawalForm;
