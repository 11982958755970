import { CardTransactionsResponseType, CurrencyType } from '+types';
import { formatAmount, getDate, getTime, history } from '+utils';

const BalanceHistoryRow = ({ rowData, currency }: { rowData: Array<CardTransactionsResponseType>; currency: CurrencyType }) => {
  const handleClick = (reference: string) => history.push(`/dashboard/issuing/card-transactions/${reference}`);

  return rowData?.map?.(tx => {
    const isCredit = ['card_creation', 'card_funding'].includes(tx.type) || tx.status === 'reversed';

    return (
      <div
        key={`transaction_${tx.reference}`}
        className="div-table --history-table --row balance-history-row"
        role="button"
        tabIndex={0}
        onClick={() => handleClick(tx.reference)}
        onKeyUp={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleClick(tx.reference);
          }
        }}
      >
        <div className="--txn-status --column d-flex">
          <span className="body-row-header">Date:</span>
          <span className="grey-text">
            {getDate(tx.date)} {getTime(tx.date)}
          </span>
        </div>
        <div className="--column">
          <span className="body-row-header">Details:</span>
          <span style={{ maxWidth: 'unset' }}>
            {tx.description && <>{tx.description} - </>}
            <span className="font-weight-500 text-uppercase" style={{ color: '#007bff' }}>
              {tx.reference}
            </span>
          </span>
        </div>
        <div className="--column">
          <span className="body-row-header">Amount ({currency ?? 'N/A'}):</span>
          <span className="font-weight-500" style={{ color: isCredit ? '#24B314' : '#F32345' }}>
            <span>
              {isCredit ? '+' : '-'} {formatAmount(tx.amount)}
            </span>
          </span>
        </div>
        <div className="--column">
          <span className="body-row-header">Balance After ({currency ?? 'N/A'}):</span>
          <span className="font-weight-500">
            <span>{tx.balance_after === undefined ? '--' : formatAmount(tx.balance_after)}</span>
          </span>
        </div>
      </div>
    );
  });
};

export default BalanceHistoryRow;
