/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';

import useStore from '+store';

import defaultMerchantLogo from '+assets/img/dashboard/biz-avatar.png';
import kpyLogoWhiteSmall from '+assets/img/logos/kSmall.png';

import './index.scss';

interface ITabletNavProps {
  logUserOut: () => void;
  routes: Record<string, any[]>;
}

const TabletNav = ({ logUserOut, routes }: ITabletNavProps) => {
  const defaultMerchant = useStore(store => store.defaultMerchant);

  return (
    <div className="menu-mobile --tablet menu-activated-on-click color-scheme-dark">
      <div className="mm-logo-buttons-w">
        <a className="mm-logo" href="index.html">
          <img src={kpyLogoWhiteSmall} alt="korapay logo" />
          <span>Dashboard</span>
        </a>
        <div className="mm-buttons">
          <div className="content-panel-open">
            <div className="os-icon os-icon-grid-circles" />
          </div>
          <div className="mobile-menu-trigger">
            <div className="os-icon os-icon-hamburger-menu-1" />
          </div>
        </div>
      </div>
      <div className="menu-and-user">
        <div className="logged-user-w">
          <div className="avatar-w">
            <img alt="" src={defaultMerchantLogo} />
          </div>
          <div className="logged-user-info-w">
            <div className="logged-user-name">{defaultMerchant?.name}</div>
            <div className="logged-user-role">ID KPY{defaultMerchant?.id}</div>
          </div>
        </div>

        {/* <!---------------- START - Tablet Menu List -------------------- */}
        <ul className="main-menu">
          <li className="selected">
            <Link to="/dashboard/home">
              <div className="icon-w">
                <div className="os-icon os-icon-home" />
              </div>
              <span>Home</span>
            </Link>
          </li>

          {routes.base?.map(route => (route.props.children.props.to.pathname.includes('balances') ? route : ''))}

          {routes.base?.map(route =>
            route.props.children.props.to.pathname.includes('payment-links') ||
            route.props.children.props.to.pathname.includes('virtual-accounts') ||
            route.props.children.props.to.pathname.includes('issuing') ||
            route.props.children.props.to.pathname.includes('bulk-payout') ||
            route.props.children.props.to.pathname.includes('identity')
              ? route
              : ''
          )}

          {routes.base?.map(route =>
            route.props.children.props.to.pathname.includes('pay-ins') ||
            route.props.children.props.to.pathname.includes('payouts') ||
            route.props.children.props.to.pathname.includes('settlements') ||
            route.props.children.props.to.pathname.includes('disputes')
              ? route
              : ''
          )}

          {routes.base?.map(route => (route.props.children.props.to.pathname.includes('audit-logs') ? route : ''))}
          {routes.base?.map(route => (route.props.children.props.to.pathname.includes('settings') ? route : ''))}

          <li>
            <a role="button" onClick={logUserOut} onKeyUp={logUserOut} tabIndex={0}>
              <div className="icon-w">
                <div className="os-icon os-icon-signs-11" />
              </div>
              <span>Logout</span>
            </a>
          </li>
        </ul>
        {/* <!---------------- END - Tablet Menu List -------------------- */}
      </div>
    </div>
  );
};

export default TabletNav;
