import { CardEventsResponseType, EventVerbType } from '+types';
import { getDateAndTime } from '+utils';

const verbOptions: Record<string, EventVerbType> = {
  creation: 'created',
  deactivation: 'deactivated',
  termination: 'terminated',
  suspension: 'suspended',
  activation: 'activated'
};

const getExplanation = (event: Pick<CardEventsResponseType, 'event' | 'creator'>) => {
  const verb = verbOptions[event?.event];
  const creator = event.creator?.includes?.('KORA') ? 'Kora' : event.creator;
  if (verb) {
    return `${creator} ${verb} virtual card`;
  }
  return '';
};

const EventsRow = ({ rowData }: { rowData: Array<CardEventsResponseType> }) => {
  return rowData?.map?.(event => {
    return (
      <div
        key={`transaction_${event.reference}`}
        className="div-table --history-table --row balance-history-row events-row"
        role="button"
        tabIndex={0}
      >
        <div className="--txn-status --column d-flex">
          <span className="body-row-header">Date:</span>
          <span className="grey-text ml-1">{getDateAndTime(event.date)}</span>
        </div>
        <div className="--column">
          <span className="body-row-header">Event:</span>
          <span className="font-weight-500 value">
            <span className="capitalize">{event.event}:</span> <span className="font-weight-400">{getExplanation(event)}</span>
          </span>
        </div>
        <div className="--column">
          <span className="body-row-header">Event ID:</span>
          <span className="grey-text">{event.reference}</span>
        </div>
        <div className="--column">
          <span className="body-row-header">Description:</span>
          <span>{event.reason || '-'}</span>
        </div>
      </div>
    );
  });
};

export default EventsRow;
