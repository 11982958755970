import { Switch } from 'react-router-dom';

import Scrollable from '+containers/Shared/Scrollable';
import { useRolesSettings } from '+hooks';

import Appearance from '../Appearance';
import SettingsNavigation from './SettingsNavigation';

import '../index.scss';

const matchSettingsComponents = {
  checkout_customization: ['settings/checkout_customization', null, Appearance]
};

function AppearanceSettings() {
  const [routes, router] = useRolesSettings(matchSettingsComponents);

  return (
    <div className="os-tabs-w">
      <div className="os-tabs-controls os-tabs-complex settings-tab">
        <ul className="nav nav-tabs">
          <SettingsNavigation />
          <Scrollable>{routes.settings.map(route => (route.key.includes('checkout_customization') ? route : ''))}</Scrollable>
        </ul>
      </div>
      <div>
        <Switch>{router.map(route => route)}</Switch>
      </div>
    </div>
  );
}

export default AppearanceSettings;
