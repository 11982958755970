import React, { useRef } from 'react';
import Papa from 'papaparse';

import useFeedbackHandler from '+hooks/feedbackHandler';
import useReducerState from '+hooks/useReducerState';
import { uploadFiles } from '+hooks/utils';
import { logError } from '+utils';

import CancelSvg from '+assets/img/dashboard/cancel.svg';
import CSVSvg from '+assets/img/dashboard/csv.svg';
import ErrorSvg from '+assets/img/dashboard/error-icon.svg';
import UploadSvg from '+assets/img/dashboard/evidenceupload.svg';
import PdfSvg from '+assets/img/dashboard/pdf.svg';

import './FileUpload.scss';

interface IFileUploadProps {
  fileUploaded: string;
  onRemoveUploadedFile: () => void;
  onUploadComplete: (results: any) => void;
  fileUploadMessage: string | React.ReactNode;
  fileAllowed: string;
  fileName?: string;
  maxFileSize?: number;
}

const FileUpload: React.FC<IFileUploadProps> = ({
  fileUploaded,
  onRemoveUploadedFile,
  onUploadComplete,
  fileUploadMessage,
  fileAllowed = '.pdf',
  fileName: fileName,
  maxFileSize = Number(process.env.REACT_APP_MAX_FILE_SIZE) || 5
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const { feedbackInit } = useFeedbackHandler();
  const [state, setState] = useReducerState({
    uploadStatus: false,
    dragging: false,
    error: false,
    errorText: '',
    displayName: fileName ?? ''
  });

  const handleFileChange = e => {
    e.preventDefault();

    const file = state.dragging ? e.dataTransfer.files[0] : e.target.files[0];
    const fileName = file?.name;
    const fileSize = file?.size;
    const fileExtension = '.' + fileName?.toString()?.split('.')[1];

    if (!fileAllowed?.includes(fileExtension)) {
      setState({ error: true, errorText: 'File type not allowed', dragging: false });
      return;
    }

    const formatFileSize = Math.floor(fileSize / 1024 ** 2);
    if (formatFileSize > maxFileSize) {
      setState({ error: true, errorText: 'Your file exceeds the file size limit', dragging: false });
      return;
    }

    setState({ displayName: fileName, uploadStatus: true });
    if (fileExtension === '.csv') {
      Papa.parse(file, {
        complete: function (results) {
          onUploadComplete(results);
          setState({ uploadStatus: false, dragging: false });
        }
      });
    } else {
      const data = new FormData();
      data.append(`files`, file);
      return uploadFiles(data)
        .then(res => {
          onUploadComplete(res);
          setState({ uploadStatus: false, dragging: false });
        })
        .catch(err => {
          setState({ uploadStatus: false, dragging: false });
          feedbackInit({
            message: `There's been an error uploading your file. Please try again`,
            type: 'danger',
            componentLevel: true
          });
          logError(err);
        });
    }
  };

  const formatFileName = (docName: string, type: string) => {
    if (docName?.length > 20) {
      const fileName = docName.substring(0, 20);
      return `${fileName} ...${type}`;
    }
    return `${docName}`;
  };

  return (
    <div className="file-upload-section mt-4">
      {fileUploaded ? (
        <div className="file-uploaded mb-1" data-testid="file-uploaded">
          <div className="d-flex align-items-center">
            <img src={state.displayName?.toString()?.split('.')[1] === 'csv' ? CSVSvg : PdfSvg} alt="pdf" />
            <p className="mb-0">{formatFileName(state.displayName, state.displayName?.toString()?.split('.')[1])}</p>
          </div>
          <img src={CancelSvg} alt="cancel" onClick={() => onRemoveUploadedFile()} data-testid="clear-button" />
        </div>
      ) : (
        <>
          <div
            onDragEnter={() => setState({ dragging: true, error: false })}
            onDragOver={e => e.preventDefault()}
            onDrop={handleFileChange}
            className={`empty-upload-state mb-1 ${state.error && 'error-state'}`}
          >
            <div className="d-flex justify-content-center align-items-center ">
              {state.uploadStatus ? (
                <>
                  <span className="spinner-border spinner-border-sm loader-span" role="status" aria-hidden="true" />
                  <p className="mb-0">Please wait...</p>
                </>
              ) : (
                <button onClick={() => fileRef?.current?.click()} type="button">
                  <input
                    ref={fileRef}
                    onChange={e => {
                      setState({ error: false });
                      handleFileChange(e);
                    }}
                    hidden
                    multiple={false}
                    type="file"
                    accept={fileAllowed}
                    data-testid="file-input"
                  />
                  <img src={UploadSvg} alt="upload" />
                  <span>Drop your file here or click to browse</span>
                </button>
              )}
            </div>
          </div>
          {state.error ? (
            <div className="d-flex mt-2 align-items-center" data-testid="error">
              <img src={ErrorSvg} alt="upload" className="mr-2" style={{ width: '20px', height: '20px' }} />
              <p className="error-state-text">{state.errorText}</p>
            </div>
          ) : (
            fileUploadMessage
          )}
        </>
      )}
    </div>
  );
};

export default FileUpload;
