import React from 'react';

type ButtonGroupProps = {
  buttonType: { value: string; label: string }[];
  type: string;
  switchTransferType: (value: string) => void;
};

const SwitchTransferButtonGroups: React.FC<ButtonGroupProps> = ({ buttonType, type, switchTransferType }) => (
  <div className="withdrawal_info p-2 toggle-transfer">
    {buttonType.map(button => (
      <button
        key={button.value}
        type="button"
        className={type === button.value ? 'btn btn-selected' : 'btn'}
        onClick={() => switchTransferType(button.value)}
      >
        <span>{button.label}</span>
      </button>
    ))}
  </div>
);

export default SwitchTransferButtonGroups;
