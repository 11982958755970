import React from 'react';

import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import Icon from '+containers/Dashboard/Shared/Icons';
import Modal from '+containers/Shared/Modal';
import { getDateAndTime } from '+utils';

import { paymentPreferenceOptions } from './Data';
import useOverpaymentUnderpayment from './useOverpaymentUnderpayment';

import InfoIcon from '+assets/img/dashboard/exclamation.svg';
import question from '+assets/img/dashboard/question-mark-dark.svg';

const OverpaymentUnderpayment: React.FC<{ activeCurrency: string }> = ({ activeCurrency }) => {
  const {
    isBankTransferAvailableOnCurrency,
    setState,
    overpayment,
    underpayment,
    showModal,
    modalDetails,
    handleCancel,
    isEditing,
    handleDropdownDisabled,
    applyStatus,
    declinedReason,
    updatedAt,
    showMoreInfo,
    showDeclinedReason,
    showWhatsNew
  } = useOverpaymentUnderpayment();

  if (!isBankTransferAvailableOnCurrency(activeCurrency))
    return <EmptyStateComponent heading={null} message="You currently cannot set payment preferences for this currency" />;

  return (
    <div className="payment-preferences__banktransfer">
      <div className="payment-preferences__left-header">
        <h5 className="form-header pt-0">Bank Transfer</h5>
        <p className="info-desc no-underline mb-1">
          Set your preferences for customer payments that are made through the Bank Transfer method.
        </p>
      </div>
      <div className="payment-preferences__dest-w grey-section">
        <div className="grey-section__subsection">
          <div className="grey-section__subsection-group">
            {showWhatsNew && (
              <div className="grey-section__payment-preference-new-info">
                <div className="whats-new">
                  <div>
                    <Icon name="chat" height={18} width={16} />
                    <span>Whats New?</span> <Icon name="star" />
                  </div>
                  <button onClick={() => setState({ showWhatsNew: false })}>
                    <Icon name="cancelled" stroke="#3E4B5B" height={16} />
                  </button>
                </div>
                <p>To better protect your business against fraud, we’ve made some changes to how you can update this payment preference.</p>
                {showMoreInfo && (
                  <div className="grey-section__more-info">
                    <h2 className="form-header pt-0">Attestation️ </h2>
                    <p>
                      As an extra layer of security, an authorised signatory from your company will sign and upload an attestation letter
                      before changes are confirmed.
                    </p>
                    <h2 className="form-header pt-0">Authorisation</h2>
                    <p>To mitigate risk and errors, changes made to this payment preference is subject to review and approval by Kora.</p>
                  </div>
                )}
                <button onClick={() => setState({ showMoreInfo: !showMoreInfo })}>
                  See {showMoreInfo ? 'Less' : 'More'} <Icon name={showMoreInfo ? 'upIcon' : 'down'} />
                </button>
                <hr />
              </div>
            )}
            <div className="grey-section__subsection-item">
              <div>
                <h6 className="section-group">
                  Overpayments
                  <button
                    onClick={() => {
                      setState({ modalState: 'overpayment', showModal: true });
                    }}
                    type="button"
                    className="ml-2"
                  >
                    <img src={question} alt="more information" aria-hidden />
                  </button>
                </h6>

                <p>Set your preference for when a customer makes an overpayment.</p>
              </div>
              <div>
                <div className="form-group mb-0">
                  <select
                    name="destination"
                    className="form-control"
                    disabled={handleDropdownDisabled()}
                    onChange={e => {
                      const value = e.target.value;
                      setState({ overpayment: value });
                    }}
                    value={overpayment}
                  >
                    {paymentPreferenceOptions.overpayment?.map(each => {
                      return (
                        <option key={each.value} value={each.value}>
                          {each.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="grey-section__subsection-group">
            <div className="grey-section__subsection-item">
              <div>
                <h6 className="section-group">
                  Underpayments
                  <button
                    onClick={() => {
                      setState({ modalState: 'underpayment', showModal: true });
                    }}
                    type="button"
                    className="ml-2"
                  >
                    <img src={question} alt="more information" aria-hidden />
                  </button>
                </h6>
                <p>Set your preference for when a customer makes an underpayment.</p>
              </div>
              <div>
                <div className="form-group mb-0">
                  <select
                    name="destination"
                    className="form-control"
                    disabled={handleDropdownDisabled()}
                    onChange={e => {
                      const value = e?.target?.value;
                      setState({ underpayment: value });
                    }}
                    value={underpayment}
                  >
                    {paymentPreferenceOptions.underpayment?.map(each => {
                      return (
                        <option key={each.value} value={each.value}>
                          {each.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {isEditing && (
            <div className="grey-section__subsection-group">
              <div className="payment-preferences__controls">
                <button
                  className="btn btn-success "
                  type="button"
                  onClick={() => setState({ modalState: 'updatePaymentpreference', showModal: true })}
                >
                  Continue
                </button>
                <span className="payment-preferences__divider-sm" />
                <button className="btn btn--link" type="button" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          )}
          {applyStatus === 'pending' && (
            <div className="grey-section__subsection-group">
              <div className="payment-preferences__undocontrols">
                <div className="payment-preferences__tooltip pending-info">
                  <img src={InfoIcon} alt="" />
                  <span>
                    The changes you requested are being reviewed. This takes between <strong> 8 </strong>to <strong>24</strong> hours to
                    complete.{' '}
                    <a href="https://developers.korapay.com/docs/handling-underpayments-and-overpayments" target="_blank" rel="noreferrer">
                      Learn More
                    </a>
                  </span>
                </div>
              </div>
            </div>
          )}
          {applyStatus === 'rejected' && (
            <div className="grey-section__subsection-group">
              <div className="grey-section__declined-reason">
                <hr />
                <div className="declined-reason-header">
                  <div>
                    <Icon name="cancelled" height={18} />
                    <span>Request Declined</span>
                  </div>
                </div>
                <p>
                  Your request to change this payment preferences has been declined. Please review the reason and make the necessary
                  adjustments.
                </p>
                {showDeclinedReason && (
                  <div className="reason-for-decline">
                    <h2 className="form-header pt-0">Reason for Decline</h2>
                    <p>
                      Your request was declined due to <strong>{declinedReason}</strong>. Please ensure all required documents are attached
                      and accurately filled out.
                    </p>
                    <span>{getDateAndTime(updatedAt)}</span>
                  </div>
                )}
                <button onClick={() => setState({ showDeclinedReason: !showDeclinedReason })}>
                  See {showDeclinedReason ? 'Less' : 'More'} <Icon name={showDeclinedReason ? 'upIcon' : 'down'} />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        visible={!!showModal}
        close={() => setState({ showModal: false })}
        heading={modalDetails?.heading}
        description={modalDetails?.description}
        content={modalDetails?.content}
        secondButtonText={modalDetails?.secondButtonText}
        size="md"
        secondButtonColor={modalDetails?.secondButtonColor}
        secondButtonActionIsTerminal={!!modalDetails?.secondButtonActionIsTerminal}
        secondButtonAction={modalDetails?.secondButtonAction}
        showButtons={modalDetails?.showButtons}
        secondButtonDisable={modalDetails?.secondButtonDisable}
      />
    </div>
  );
};

export default OverpaymentUnderpayment;
