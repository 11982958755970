/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';

import { usePageHeader } from '+hooks';
import useStore from '+store';

import defaultMerchantLogo from '+assets/img/dashboard/biz-avatar.png';
import kpyLogoWhiteSmall from '+assets/img/logos/kSmall.png';
import Loader from '+assets/img/dashboard/kpy-loader.svg';

import './index.scss';

interface IMobileNavProps {
  logUserOut: () => void;
  routes: Record<string, any[]>;
  isUpdatingEnv: boolean;
  isMenuOpen: boolean;
  toggleEnvMode: () => void;
}

const MobileNav = ({ logUserOut, routes, toggleEnvMode, isUpdatingEnv, isMenuOpen }: IMobileNavProps) => {
  const defaultMerchant = useStore(store => store.defaultMerchant);
  const MERCHANT_ENV = useStore(state => state.merchantEnv);
  const heading = usePageHeader();

  return (
    <div className="menu-mobile --mobile menu-activated-on-click color-scheme-dark">
      <div className="mm-logo-buttons-w">
        <a className="mm-logo" href="#">
          <img src={kpyLogoWhiteSmall} alt="korapay logo" />
          <span>{heading}</span>
        </a>
        <div className="mm-buttons">
          <div className="content-panel-open">
            <div className="os-icon os-icon-grid-circles" />
          </div>
          <div className="mobile-menu-trigger">
            {isMenuOpen ? <div className="os-icon os-icon-close" /> : <div className="os-icon os-icon-hamburger-menu-1" />}
          </div>
        </div>
      </div>
      <div className="menu-and-user">
        <div className="logged-user-w">
          <div className="avatar-w">
            <img alt="" src={defaultMerchantLogo} />
          </div>
          <div className="logged-user-info-w">
            <div className="logged-user-name">{defaultMerchant?.name}</div>
            <div className="logged-user-role">ID KPY{defaultMerchant?.id}</div>
          </div>
        </div>

        {/* <!---------------- START - Mobile Menu List -------------------- */}
        <div className="main-menu">
          <ul>
            <li className="selected">
              <Link to="/dashboard/home">
                <div className="icon-w">
                  <div className="os-icon os-icon-home" />
                </div>
                <span>Home</span>
              </Link>
            </li>

            {routes.base?.map(route => (route.props.children.props.to.pathname.includes('balances') ? route : ''))}
            <li className="sub-header">TOOLS</li>
            {routes.base?.map(route =>
              route.props.children.props.to.pathname.includes('payment-links') ||
              route.props.children.props.to.pathname.includes('virtual-accounts') ||
              route.props.children.props.to.pathname.includes('issuing') ||
              route.props.children.props.to.pathname.includes('bulk-payout') ||
              route.props.children.props.to.pathname.includes('identity') ||
              route.props.children.props.to.pathname.includes('conversions')
                ? route
                : ''
            )}
            <li className="sub-header">TRANSACTIONS</li>
            {routes.base?.map(route =>
              route.props.children.props.to.pathname.includes('pay-ins') ||
              route.props.children.props.to.pathname.includes('payouts') ||
              route.props.children.props.to.pathname.includes('settlements') ||
              route.props.children.props.to.pathname.includes('disputes')
                ? route
                : ''
            )}
            <li className="sub-header">ACCOUNT</li>

            {routes.base?.map(route => (route.props.children.props.to.pathname.includes('audit-logs') ? route : ''))}

            {routes.base?.map(route => (route.props.children.props.to.pathname.includes('referrals') ? route : ''))}
            {routes.base?.map(route => (route.props.children.props.to.pathname.includes('settings') ? route : ''))}

            <li>
              <a role="button" onClick={logUserOut} onKeyUp={logUserOut} tabIndex={0}>
                <div className="icon-w">
                  <div className="os-icon os-icon-signs-11" />
                </div>
                <span>Logout</span>
              </a>
            </li>
          </ul>
          {/* <!---------------- END - Mobile Menu List -------------------- */}

          <div className="floated-colors-btn second-floated-btn --sm">
            <div className="inner-floated-btn">
              <div
                className={`os-toggler-w ${MERCHANT_ENV === 'live' ? 'on' : ''}`}
                onClick={toggleEnvMode}
                role="button"
                onKeyDown={toggleEnvMode}
                tabIndex={0}
                aria-label="Toggle live mode"
              >
                {!isUpdatingEnv ? (
                  <div className="os-toggler-i os-toggler-i-sm">
                    <div className="os-toggler-pill" />
                  </div>
                ) : (
                  <img src={Loader} alt="Loader" aria-hidden className="live-button--loader" />
                )}
              </div>
              <span>Live Mode</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
