/* eslint-disable no-unused-vars */
import { FormikProps } from 'formik';

import { getOtpDescriptors } from '+containers/Dashboard/Issuing/utils';
import CountdownTimer from '+containers/Dashboard/Shared/CountdownTimer';
import { TIssuedCardActionModalFormValues } from '+types';
import { maskEmail, stripNonNumeric } from '+utils';

const OTPAuthorizationForm = ({
  twoFactorType,
  onUpdateAuthState,
  email,
  isMaxOtpAttempts,
  isCountdownComplete,
  onCountdownEnd,
  onOtpResend,
  formik
}: {
  twoFactorType: 'otp' | 'totp' | 'totp_recovery_code';
  onUpdateAuthState: () => void;
  isMaxOtpAttempts: boolean;
  email: string;
  isCountdownComplete: boolean;
  onOtpResend: () => void;
  onCountdownEnd: (arg: boolean) => void;
  formik: FormikProps<TIssuedCardActionModalFormValues>;
}) => {
  const minInputLength = 6;
  const maxInputLength = 11;
  const { otpLabel, otpPlaceholder } = getOtpDescriptors(twoFactorType);
  const { setFieldValue, errors, touched, handleBlur, values } = formik;
  return (
    <div className="otp-form">
      <p className="otp-form__hint">
        To proceed, enter{' '}
        {twoFactorType === 'otp' ? (
          <>
            {' '}
            the OTP (one-time PIN) that was sent to your email (<strong>{maskEmail(email)}</strong>).
          </>
        ) : null}
        {twoFactorType === 'totp' ? 'the authentication code from your authenticator app' : null}
        {twoFactorType === 'totp_recovery_code' ? 'a recovery code' : null}
      </p>

      <div>
        <label htmlFor="otp">{otpLabel}</label>
        <input
          type="text"
          name="otp"
          id="otp"
          value={values.otp}
          onChange={e => setFieldValue('otp', stripNonNumeric(e.target.value))}
          onBlur={handleBlur}
          className="form-control"
          placeholder={otpPlaceholder}
          inputMode="numeric"
          autoComplete="one-time-code"
          minLength={minInputLength}
          maxLength={maxInputLength}
          disabled={isMaxOtpAttempts}
        />

        <div role="alert" aria-live="polite">
          {errors.otp && touched.otp ? <p className="otp-form__error">{errors.otp}</p> : null}

          {twoFactorType === 'totp' && (
            <div className="otp-form__cta--code otp-cta mt-2">
              <span>Can&apos;t access authenticator app?</span>
              <button type="button" className="btn btn-link ml-1" onClick={() => onUpdateAuthState()}>
                Confirm using recovery codes
              </button>
            </div>
          )}

          {twoFactorType === 'otp' && (
            <div className="otp-form__cta--timer mt-2">
              <span>You didn&apos;t receive a code?</span>
              {isCountdownComplete ? (
                <button disabled={isMaxOtpAttempts} type="button" className="btn btn-link" onClick={onOtpResend}>
                  Resend code.
                </button>
              ) : (
                <CountdownTimer targetTime={30} onCountdownEnd={() => onCountdownEnd(true)} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OTPAuthorizationForm;
