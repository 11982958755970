/* eslint-disable no-unused-vars */
import { useFetch, useSend } from '+hooks';
import { ICountriesResponse, ISendResponse } from '+types';

export const UtilServices = {
  useFetchCountries: ({
    onSuccess,
    bannerLevel,
    params,
    enabled = true
  }: {
    bannerLevel?: boolean;
    params?: { forSignup?: boolean; includeStates?: boolean; forCardPayment?: boolean };
    enabled?: boolean;
    onSuccess?: (data: ICountriesResponse) => void;
  }) =>
    useFetch('/misc/countries', {
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      params,
      enabled,
      useEnvironmentHandler: 'live'
    }),

  // add get states here
  useGetStates: ({
    onSuccess,
    bannerLevel,
    countryCode,
    enabled
  }: {
    onSuccess?: (value: unknown) => void;
    bannerLevel?: boolean;
    countryCode?: number;
    enabled?: boolean;
  }) =>
    useFetch(`/misc/countries/${countryCode}/states`, {
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      enabled,
      useEnvironmentHandler: 'live'
    }),

  useBankEnquiry: ({
    onSuccess,
    onError,
    bannerLevel,
    useEnvironmentHandler
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    useEnvironmentHandler?: boolean | string;
  }) =>
    useSend({
      url: '/misc/banks/resolve',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler,
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useMobileNumberEnquiry: ({
    onSuccess,
    onError,
    bannerLevel,
    useEnvironmentHandler
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    useEnvironmentHandler?: boolean | string;
  }) =>
    useSend({
      url: '/misc/mobile-money/resolve',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler,
      customBaseURL: process.env.REACT_APP_UTILITY_API_BASE
    }),

  useFetchBanks: ({
    onSuccess,
    bannerLevel,
    enabled = true,
    code
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    bannerLevel?: boolean;
    enabled?: boolean;
    code?: string;
  }) => {
    const url = code ? `/misc/banks?countryCode=${code}` : '/misc/banks';
    return useFetch(url, {
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      enabled,
      useEnvironmentHandler: 'live'
    });
  },

  useFetchMobileMoneyOperators: ({
    onSuccess,
    bannerLevel,
    enabled = true,
    code
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    enabled?: boolean;
    code?: string;
  }) =>
    useFetch(`/misc/mobile-money?countryCode=${code}`, {
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      enabled,
      useEnvironmentHandler: 'live'
    }),

  useFetchCurrencies: ({
    onSuccess,
    bannerLevel,
    enabled = true
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    bannerLevel?: boolean;
    enabled?: boolean;
  }) =>
    useFetch(`/misc/currencies`, {
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      enabled,
      useEnvironmentHandler: 'live'
    }),

  useUploadFiles: ({
    onSuccess,
    onError,
    bannerLevel
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
  }) =>
    useSend({
      url: `/files?field_name=files`,
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_UTILITY_API_BASE
    }),

  useDownloadFile: ({
    onSuccess,
    onError,
    bannerLevel,
    url,
    enabled = true,
    showSuccessMessage,
    showErrorMessage
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    enabled?: boolean;
    url: string;
    showSuccessMessage?: boolean;
    showErrorMessage?: boolean;
  }) =>
    useFetch(url, {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      enabled,
      showSuccessMessage,
      showErrorMessage,
      showReturnDefaultResponse: true
    })
};
