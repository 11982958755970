import { IPreferencesCustomerVC } from '+types';

import { customerCardMonthlyPaymentOptions, customerCardPlansDoc, pciDssLevels, subscriptionPlanOptions } from './accessRequestHelpers';

const PreferencesCustomerVC = ({ formik }: IPreferencesCustomerVC) => {
  const { touched, errors, getFieldProps } = formik;

  return (
    <div className="fade-in">
      <div className="form-field">
        <label className="label label--sm" htmlFor="customer-card-monthly-payment">
          What is your current monthly payment value?
        </label>
        <select
          className="form-control"
          id="customer-card-monthly-payment"
          {...getFieldProps?.('customerCardMonthlyPaymentValue')}
          aria-invalid={touched?.customerCardMonthlyPaymentValue && errors?.customerCardMonthlyPaymentValue ? 'true' : 'false'}
          aria-errormessage="customer-card-monthly-payment-error"
        >
          <option value="">- Select payment value -</option>
          {customerCardMonthlyPaymentOptions.map(option => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <span id="customer-card-monthly-payment-error" className="field-error" aria-live="assertive">
          {touched?.customerCardMonthlyPaymentValue && errors?.customerCardMonthlyPaymentValue}
        </span>
      </div>

      <div className="form-field">
        <label className="label label--sm" htmlFor="pci-dss">
          What is your current PCI DSS Level?
        </label>
        <select
          className="form-control"
          id="pci-dss"
          {...getFieldProps?.('pciDssLevel')}
          aria-invalid={touched?.pciDssLevel && errors?.pciDssLevel ? 'true' : 'false'}
          aria-errormessage="pci-dss-error"
        >
          <option value="">- Select PCI DSS level -</option>
          {pciDssLevels.map(({ value, label }) => (
            <option key={value} value={value}>
              {label === '0' ? 'No PCI DSS Level' : `Level ${label}`}
            </option>
          ))}
        </select>
        <span id="monthly-payment-error" className="field-error" aria-live="assertive">
          {touched?.pciDssLevel && errors?.pciDssLevel}
        </span>
      </div>

      <div className="form-field">
        <label className="label label--sm" htmlFor="subscription-plan">
          Select a preferred subscription plan.{' '}
          <a href={customerCardPlansDoc} target="_blank" rel="noopener noreferrer" style={{ color: 'hsla(216, 90%, 55%, 1)' }}>
            see plans and pricing
          </a>
        </label>
        <select
          className="form-control"
          id="subscription-plan"
          {...getFieldProps?.('subscriptionPlan')}
          aria-invalid={touched?.subscriptionPlan && errors?.subscriptionPlan ? 'true' : 'false'}
          aria-errormessage="pci-dss-error"
        >
          <option value="">- Select subscription plan -</option>
          {subscriptionPlanOptions.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        <span id="monthly-payment-error" className="field-error">
          {touched?.subscriptionPlan && errors?.subscriptionPlan}
        </span>
      </div>
    </div>
  );
};

export default PreferencesCustomerVC;
