import React, { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useDebouncedCallback } from 'use-debounce';

import CurrencyConversionForm from '+containers/Dashboard/Shared/CurrencyConversionForm';
import { useConversionLogics, useCountdown, useFeedbackHandler, useProcessConversion } from '+hooks';
import { SwapWalletConversionService } from '+services/swapwallet-conversion-services';
import { IConvertCurrencies } from '+types';
import { backwardAmountInput, cleanInput, formatAmount } from '+utils';

import './index.scss';

const ConvertCurrencies: React.FC<IConvertCurrencies> = ({ onConversionSuccess, amountInputRef, onError }) => {
  const {
    state,
    setState,
    rate,
    toAmount,
    availableBalance,
    minConversionLimit,
    maxConversionLimit,
    errorMessage,
    validateAmount,
    queryData,
    conversionsQueryData,
    currencyArray,
    sourceCurrency,
    destinationCurrency
  } = useConversionLogics();

  const { amount, data, payloadCurrency } = state;
  const { closeFeedback } = useFeedbackHandler();

  const { mutateAsync: initiateConversion, isPending: isLoading } = SwapWalletConversionService.useInitiateConversion({
    onSuccess: data => {
      const response = data?.data;
      setState({ exchange: response || {}, data: response || null });
      setState({ countdownCompleted: false, isLoading: false });
    },
    onError: (error: unknown) => {
      const axiosError = error as AxiosError<{ message: string }>;
      onError(axiosError?.response?.data?.message || 'There has been an error getting swap information');
      if (axiosError?.response?.data?.message?.includes('Too many requests') && !checkLimitAndValidate) {
        closeFeedback(9000);
      }
    }
  });

  const { processConversionMutation, isLoading: isProcessConversionLoading } = useProcessConversion(
    conversionsQueryData,
    onConversionSuccess,
    onError
  );

  const handleCountdownCompletion = async () => {
    setState({ countdownCompleted: true, isLoading: true });
    await initiateConversion(queryData);
    setState({ countdownCompleted: false });
  };

  const { currentCount, resetCountdown } = useCountdown({
    countFrom: state.countdown,
    isStartCount: state.startCount,
    isRestartCountdown: true,
    callbackFn: handleCountdownCompletion as () => void
  });

  const debouncedFetchData = useDebouncedCallback(async () => {
    if (payloadCurrency.from === payloadCurrency.to) return;

    const isValid = validateAmount();
    if (isValid && (payloadCurrency.from || payloadCurrency.to)) {
      setState({ apiData: null });
      try {
        const result = await initiateConversion(queryData);
        setState({ apiData: result?.data });
      } catch (error) {
        const axiosError = error as AxiosError<{ message: string }>;
        onError(axiosError?.response?.data?.message || 'Conversion can not be initiated, please try again');
      }
    }
  }, 1000);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/,/g, '');
    const formattedAmount = backwardAmountInput(cleanInput(inputValue));
    if (inputValue === '') {
      setState({ apiData: null, amount: inputValue });
      return;
    }
    setState({ amount: formattedAmount as typeof amount, apiData: null });
    await debouncedFetchData();
    if (inputValue < amount || inputValue === '') {
      setState({ data: null });
    }
  };

  const handleSelectChange = async (e: React.ChangeEvent<HTMLSelectElement>, name: string) => {
    const selectedCurrency = e.target.value;

    if (name === 'currencyFrom') {
      setState({
        payloadCurrency: {
          ...state.payloadCurrency,
          from: selectedCurrency
        }
      });
    } else if (name === 'currencyTo') {
      setState({
        payloadCurrency: {
          ...state.payloadCurrency,
          to: selectedCurrency
        }
      });
    }
    await debouncedFetchData();
  };

  const handleSwitchCurrency = async () => {
    const { from, to } = state.payloadCurrency;
    setState({
      payloadCurrency: {
        from: to,
        to: from
      }
    });

    await debouncedFetchData();
  };

  const validate = () => {
    if (data !== null && amount !== '' && !isLoading && payloadCurrency.from !== '' && payloadCurrency.to !== '') {
      const parsedAmount = parseFloat(amount);
      const maxLimit = maxConversionLimit !== undefined ? Math.min(maxConversionLimit, availableBalance) : availableBalance;

      return parsedAmount >= minConversionLimit && parsedAmount <= maxLimit;
    }

    return false;
  };

  const checkLimitAndValidate = validate();

  useEffect(() => {
    if (checkLimitAndValidate) {
      setState({ startCount: true });
    } else {
      setState({ startCount: false });
      resetCountdown();
    }
  }, [checkLimitAndValidate]);

  const handleProcessConversion = async () => {
    setState({ amount: '' });
    await processConversionMutation();
  };

  return (
    <CurrencyConversionForm
      formType="main"
      payloadCurrency={payloadCurrency}
      currencyArray={currencyArray}
      handleSelectChange={handleSelectChange as (e: React.ChangeEvent<HTMLSelectElement>, name: string) => void}
      handleSwitchCurrency={handleSwitchCurrency as () => void}
      handleProcessConversion={handleProcessConversion as () => void}
      state={state}
      availableBalance={availableBalance}
      errorMessage={errorMessage}
      checkLimitAndValidate={checkLimitAndValidate}
      formatAmount={formatAmount}
      rate={rate}
      toAmount={toAmount}
      isLoading={isLoading}
      amount={amount}
      handleInputChange={handleInputChange as (e: React.ChangeEvent<HTMLInputElement>) => void}
      cleanInput={cleanInput}
      amountInputRef={amountInputRef}
      currentCount={currentCount}
      sourceCurrency={sourceCurrency}
      destinationCurrency={destinationCurrency}
      isProcessConversionLoading={isProcessConversionLoading}
    />
  );
};

export default ConvertCurrencies;
