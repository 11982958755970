import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import Table from '+containers/Dashboard/Shared/Table';
import TabSwitch from '+containers/Dashboard/Shared/TabSwitch';
import { useSearchQuery } from '+hooks';
import { VirtualBankServices } from '+services/virtual-bank-services';
import useStore from '+store';
import { CurrencyType } from '+types';
import {
  capitalize,
  filteredOutObjectProperty,
  getDate,
  getDateAndTime,
  getTime,
  history,
  logBreadCrumb,
  logError,
  switchCountry,
  switchStatus
} from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import CurrencyTabs from '../Shared/CurrencyTabs';
import ToolTip from '../Shared/Tooltip';
import VirtualAccountsFilter from './Shared/VirtualAccountsFilter';
import VirtualBankAccountLimit from './Shared/VirtualBankAccountLimitBanner';
import VirtualAccountDetails from './VirtualAccountDetails';
import VirtualAccountHoldersDetails from './VirtualAccountHoldersDetails';
import UpgradeRequestModal from './VirtualAccountsModal/UpgradeRequestModal';

import InfoIcon from '+assets/img/dashboard/exclamation.svg';

import './index.scss';

const FVBACurrency = ['USD', 'GBP', 'EUR'];
const CurrencyToDisplayVbaLimit = ['NGN'] as CurrencyType[];
type TVbaLimitPayLoad = {
  currency: CurrencyType;
};
const VirtualAccounts = () => {
  const searchQuery = useSearchQuery();
  const paginationPage = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';
  const activeTab = searchQuery.value.tab || 'Account Holder';
  const activeCurrency = searchQuery.value.currency || 'NGN';
  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, ['currency', 'page', 'tab', 'limit'])
  };
  const buffer = [];
  const anyLoading = {};
  const refetchRefund = {};
  const getTabDetails = tab => buffer.find(data => data.type === tab);
  const tabs = ['Account Holder', 'Account Number', 'Upgrade Request'];
  const [openModal, setOpenModal] = useState(false);
  const [reference, setReference] = useState('');
  const availableCurrencies = useStore(storedState => storedState.availableCurrencies);
  const virtualBanksCurrencies = ['NGN', 'USD', 'GBP', 'EUR'];

  const switchAPI = {
    'Account Number': {
      service: VirtualBankServices.useGetVirtualBankAccounts,
      params: { ...sortingParams, currency: activeCurrency, limit, page: paginationPage }
    },
    'Account Holder': {
      service: VirtualBankServices.useGetAllVirtualBankAccountHolders,
      params: { ...sortingParams, limit, page: paginationPage }
    },
    'Upgrade Request': {
      service: VirtualBankServices.useUpgradeVirtualAccountRequests,
      params: { ...sortingParams, limit, page: paginationPage }
    }
  };
  const isCurrencyToDisplayVbaLimit = CurrencyToDisplayVbaLimit.some(item => item === activeCurrency);
  tabs.forEach(tab => {
    const { data, isFetching, refetch } = switchAPI[tab].service({
      errorMessage: `There has been an error getting your ${tab}.`,
      params: switchAPI[tab].params,
      enabled: activeTab === tab
    });
    anyLoading[tab] = isFetching;
    refetchRefund[tab] = refetch;
    buffer.push({ type: tab, ...data?.data });
  });

  const { data: getVbaLimitsData, refetch: refetchVbaLimits } = VirtualBankServices.useGetVirtualBankAccountLimitDetails({
    params: { currency: 'NGN' },
    enabled: isCurrencyToDisplayVbaLimit
  });
  const requestVbaIncrease = VirtualBankServices.useIncreaseVirtualAccountLimitService({
    onSuccess: () => refetchVbaLimits(),
    errorMessage: 'There has been an error processing your request',
    onError: error => logError(error)
  });

  const getClassName = () => {
    if (['Account Holder', 'Account Number'].includes(activeTab)) return '--vba-account-holder';
    return '--vba-upgrade-request';
  };

  const getTypes = () => {
    if (activeTab === 'Account Number') return 'account_number';
    if (activeTab === 'Upgrade Request') return 'upgrade_request';
    return 'account_holder';
  };

  const redirectOnClick = (route, redirectReference) => {
    history.push(`/dashboard/virtual-accounts/${route}/${redirectReference}`);
  };
  const tableContent = () => {
    const activeList = getTabDetails(activeTab);
    return (
      <>
        {activeList?.data?.map(each => {
          return (
            <React.Fragment className={`div-table ${getClassName()} --row`} key={each?.reference}>
              {activeTab === 'Account Holder' && (
                <div
                  onClick={() => {
                    redirectOnClick('holders', each?.reference);
                    logBreadCrumb({
                      event: breadCrumbEvents.virtualAccounts.tableClicked(activeCurrency),
                      data: { tab: activeTab, currency: activeCurrency }
                    });
                  }}
                  onKeyDown={() => {
                    redirectOnClick('holders', each?.reference);
                    logBreadCrumb({
                      event: breadCrumbEvents.virtualAccounts.tableClicked(activeCurrency),
                      data: { tab: activeTab, currency: activeCurrency }
                    });
                  }}
                  role="button"
                  tabIndex={0}
                  className={`div-table ${getClassName()} --row`}
                >
                  <div>
                    <span className="body-row-header">Status:</span>
                    <span className={`status-pill smaller ${switchStatus(each?.status)}`} />
                    <span>{capitalize(each?.status)}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Account Holder:</span>
                    <span>
                      <span style={{ fontWeight: 600, color: '#414F5F' }}>{`${each?.first_name} ${each.last_name}`}</span>
                      {'  '}
                      <span style={{ fontWeight: 500, color: '#A9AFBC' }}>{each.email || 'Not Available'}</span>
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">Type:</span>
                    <span>{capitalize(each?.account_type)}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Country:</span>
                    <span>{switchCountry(each?.nationality)}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Date Created:</span>
                    <span style={{ color: '#414F5F' }}>{`${getDate(each.created_at)}`}</span>,{' '}
                    <span style={{ fontWeight: 400, color: '#A9AFBC' }}>{`${getTime(each.created_at)}`}</span>
                  </div>
                </div>
              )}
              {activeTab === 'Account Number' && (
                <div
                  role="button"
                  tabIndex={0}
                  className={`div-table ${getClassName()} --row`}
                  onClick={() => redirectOnClick('details', each?.korapay_reference)}
                  onKeyDown={() => redirectOnClick('details', each?.korapay_reference)}
                >
                  <div>
                    <span className="body-row-header">Status:</span>
                    <span className={`status-pill smaller ${switchStatus(each?.status)}`} />
                    <span>{capitalize(each?.status === 'kyc_pending' ? 'Active' : each?.status)}</span>
                  </div>
                  <div
                    onClick={() => redirectOnClick('details', each?.korapay_reference)}
                    onKeyDown={() => redirectOnClick('details', each?.korapay_reference)}
                    role="button"
                    tabIndex={0}
                  >
                    <span className="body-row-header">Account Number/Bank:</span>
                    <span>
                      <span style={{ fontWeight: 600, color: '#414F5F' }}>{each?.account_number}</span> -{' '}
                      {each?.bank_name || 'Not Available'}
                    </span>
                  </div>
                  {!FVBACurrency.includes(activeCurrency) && (
                    <div>
                      <span className="body-row-header">Account Name:</span>
                      <span>{each?.account_name || 'Not available'}</span>
                    </div>
                  )}
                  {FVBACurrency.includes(activeCurrency) && (
                    <div>
                      <span className="body-row-header">Tier:</span>
                      <span>{`Tier ${each?.tier}` || 'Not available'}</span>
                      {each.pending_upgrade_request && (
                        <ToolTip
                          classname="upgrade-tier-indication"
                          image={InfoIcon}
                          message="This account has a pending upgrade request"
                          type="upgrade-tire-indicator"
                        />
                      )}
                    </div>
                  )}
                  <div>
                    <span className="body-row-header">Account Reference:</span>
                    <span>
                      <Copyable text={each?.account_reference} showOnHover />
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">Date Created:</span>
                    <span>{`${getDateAndTime(each?.created_at)}`}</span>
                  </div>
                </div>
              )}
              {activeTab === 'Upgrade Request' && (
                <div
                  onClick={() => {
                    setReference(each.reference);
                    setOpenModal(true);
                  }}
                  onKeyDown={() => {
                    setReference(each.reference);
                    setOpenModal(true);
                  }}
                  role="button"
                  tabIndex={0}
                  className={`div-table ${getClassName()} --row`}
                >
                  <div>
                    <span className="body-row-header">Status:</span>
                    <span className={`status-pill smaller ${switchStatus(each?.status)}`} />
                    <span>{capitalize(each?.status)}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Account Number/Bank:</span>
                    <span>
                      <span style={{ fontWeight: 600, color: '#414F5F' }}>{each?.virtual_bank_account?.account_number}</span> -{' '}
                      {each?.virtual_bank_account?.bank_name || 'Not Available'}
                    </span>
                  </div>
                  <div>
                    <span className="body-row-header">Account Name:</span>
                    <span>{`${each?.account_holder?.first_name} ${each?.account_holder?.last_name}`}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Upgrade From:</span>
                    <span>{each?.upgrade_from}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Upgrade To:</span>
                    <span>{each?.upgrade_to}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Date Created:</span>
                    <span>{`${getDateAndTime(each?.created_at)}`}</span>
                  </div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const getTableHeaders = () => {
    if (activeTab === 'Account Number') {
      return [
        {
          value: 'Status'
        },
        {
          value: 'Account Number/Bank'
        },
        ...(!FVBACurrency.includes(activeCurrency)
          ? [
              {
                value: 'Account Name'
              }
            ]
          : []),
        ...(FVBACurrency.includes(activeCurrency) ? [{ value: 'Tier' }] : []),
        {
          value: 'Account Reference'
        },
        {
          value: 'Date Created'
        }
      ];
    }
    if (activeTab === 'Account Holder') {
      return [
        {
          value: 'Status'
        },
        {
          value: 'Account Holder'
        },
        {
          value: 'Type'
        },
        {
          value: 'Country'
        },
        {
          value: 'Date Created'
        }
      ];
    }
    return [
      {
        value: 'Request Status'
      },
      {
        value: 'Account Number/Bank'
      },
      {
        value: 'Account Holder'
      },
      {
        value: 'Upgrade From'
      },
      {
        value: 'Upgrade To'
      },
      {
        value: 'Date Requested'
      }
    ];
  };
  const requestVbaLimitAction = (value: TVbaLimitPayLoad) => requestVbaIncrease.mutate(value);
  const setSearchQuery = (value: string) => {
    searchQuery.setQuery({ currency: value });
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="payment-link-summary">
            <div className="heading-box-mmd">
              <h4 className="form-header payment-link-header">Local Virtual Accounts for Global Businesses</h4>
              <p className="form-desc payment-desc">
                Expand your business globally and receive payments just like a local. Set up virtual accounts to receive payments in
                different currencies from all over the world and simplify your payment processes.
                <a
                  href="https://developers.korapay.com/docs/virtual-bank-accounts"
                  target="_blank"
                  style={{ marginLeft: '0.3rem' }}
                  rel="noreferrer"
                >
                  Learn more.
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      {getVbaLimitsData &&
      Object.keys(getVbaLimitsData).length > 0 &&
      getVbaLimitsData?.data?.data?.low_balance_warning &&
      isCurrencyToDisplayVbaLimit ? (
        <VirtualBankAccountLimit
          action={() => {
            requestVbaLimitAction({ currency: activeCurrency });
          }}
          count={getVbaLimitsData.data?.data?.unused_vba || 0}
          currency={activeCurrency}
          loading={requestVbaIncrease.isLoading}
          completed={requestVbaIncrease.isSuccess || getVbaLimitsData.data?.data?.pending_count_increase_request}
        />
      ) : null}

      <div className="os-tabs-controls virtual-accounts">
        <ul className="nav">
          <li className="nav-item">
            <h4 className="element-header audit__header d-flex align-items-center">Explore By:</h4>
          </li>
        </ul>
        <span className="nav nav-pills smaller d-md-flex">
          <TabSwitch
            options={tabs}
            activeTab={activeTab}
            setTab={value => searchQuery.setQuery({ tab: value }, true)}
            className="ml-3"
            id="virtual_accounts_tab"
          />
        </span>
      </div>
      <div className="content-i">
        <div className="row">
          <div className="col-sm-12">
            <div className="element-wrapper">
              {activeTab === 'Account Number' && (
                <section className="os-tabs-w">
                  <div className="os-tabs-controls os-tabs-complex">
                    <CurrencyTabs
                      currencies={availableCurrencies}
                      activeCurrency={activeCurrency}
                      onClick={setSearchQuery}
                      disabledOption
                      allowedCurrencies={virtualBanksCurrencies}
                    />
                  </div>
                </section>
              )}
              <div
                className="os-tabs-controls os-tabs-complex settlement-tabs"
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '20px',
                  marginRight: '0px'
                }}
              >
                <div style={{ width: '100%' }}>
                  <VirtualAccountsFilter type={getTypes()} totalCount={getTabDetails(activeTab)?.paging?.total_items} />
                </div>
              </div>
              <Table
                tableClassName={getClassName()}
                headings={getTableHeaders()}
                hasPagination
                loading={anyLoading[activeTab]}
                current={getTabDetails(activeTab)?.paging?.current}
                totalItems={getTabDetails(activeTab)?.paging?.total_items}
                limitAction={c => searchQuery.setQuery({ limit: String(c) })}
                pageSize={getTabDetails(activeTab)?.paging?.page_size}
                actionFn={c => searchQuery.setQuery({ page: String(c) })}
                annotation="accounts"
                emptyStateHeading={`No ${activeTab} yet.`}
                emptyStateMessage={`You currently have not created any virtual bank ${activeTab}`}
                tableWrapperClassName="vba-container sub"
              >
                {tableContent()}
              </Table>
            </div>
          </div>
        </div>
      </div>
      {openModal && activeTab === 'Upgrade Request' && <UpgradeRequestModal close={() => setOpenModal(false)} reference={reference} />}
    </>
  );
};

export default function VirtualBankAccounts() {
  return (
    <div className="virtual-accounts__container">
      <Switch>
        <Route exact path="/dashboard/virtual-accounts" component={VirtualAccounts} />
        <Route path="/dashboard/virtual-accounts/details/:id">
          <VirtualAccountDetails />
        </Route>
        <Route path="/dashboard/virtual-accounts/holders/:id">
          <VirtualAccountHoldersDetails />
        </Route>
      </Switch>
    </div>
  );
}
