/* eslint-disable no-unused-vars */
import { keepPreviousData } from '@tanstack/react-query';

import { useFetch, useSend } from '+hooks';

export const ChargeBackServices = {
  useGetSingleChargeBack: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    ref
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    kind?: string;
    ref?: string;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/chargebacks/${ref}`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useUpdateChargeBack: ({
    onSuccess,
    onError,
    bannerLevel,
    ref,
    errorMessage
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    ref: string;
    errorMessage?: string;
  }) =>
    useSend({
      url: `/chargebacks/${ref}`,
      method: 'patch',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetChargeBacks: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    params?: { page: string; limit: string; status: string[]; currency: string };
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/chargebacks`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      params,
      refetchOnCloseFeedbackError,
      placeholderData: keepPreviousData,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetChargeBackSummary: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/chargebacks/summary`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useExportChargeBacks: ({
    enabled,
    onSuccess,
    onError,
    bannerLevel,
    successMessage,
    errorMessage,
    params,
    refetchOnCloseFeedbackError
  }: {
    onSuccess?: () => void;
    onError?: () => void;
    enabled?: boolean;
    successMessage?: string;
    errorMessage?: string;
    bannerLevel?: boolean;
    params: { sortingParams: any; format: any; currency: any; fieldsToExport: any };
    refetchOnCloseFeedbackError?: boolean;
  }) => {
    const { sortingParams, format, currency, fieldsToExport } = params || {};
    const formattedParams = { format, ...sortingParams, currency, 'fieldsToExport[]': fieldsToExport };
    return useFetch(`/chargebacks`, {
      enabled,
      successMessage,
      errorMessage,
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      params: formattedParams,
      refetchOnCloseFeedbackError,
      requestConfig: { responseType: 'blob' },
      showReturnDefaultResponse: true,
      showSuccessMessage: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    });
  }
};
