import { useEffect, useState } from 'react';



import { mobileEnquiry } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import { IPayoutsModalProps, WithdrawalDetails } from '+types';
import { countryMobileCode, currencyToMobileNoLengthMapping, formatAmount, logError, validateWithdrawalMobileNumberLength } from '+utils';





export default function useMobileValidation({ currency, maxPayoutLimit }: IPayoutsModalProps) {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [showMobileName, setShowMobileName] = useState(false);
  const [accountNameDisabled, setAccountNameDisabled] = useState(true);
  const [withdrawalDetails, setWithdrawalDetails] = useState<WithdrawalDetails>({
    amount: null,
    description: null,
    pin: '',
    mobileNo: '',
    operatorCode: '',
    username: '',
    mobileMoneyCode: ''
  });

  useEffect(() => {
    if (!validateWithdrawalMobileNumberLength(withdrawalDetails.mobileNo, currency, currencyToMobileNoLengthMapping)) {
      setWithdrawalDetails(details => ({ ...details, username: '' }));
      setShowMobileName(() => false);
      return;
    }
    handleMobileValidation() as unknown as void;
  }, [withdrawalDetails.mobileNo]);

  useEffect(() => {
    if (maxPayoutLimit && parseFloat(withdrawalDetails?.amount?.toString() as string) > maxPayoutLimit) {
      feedbackInit({
        message: `Your maximum payout limit is ${formatAmount(maxPayoutLimit)} ${currency} per transaction.`,
        type: 'danger',
        componentLevel: true
      });
    } else {
      closeFeedback();
    }
  }, [withdrawalDetails.amount]);

  useEffect(() => {
    let message;
    if (!validateWithdrawalMobileNumberLength(withdrawalDetails.mobileNo, currency, currencyToMobileNoLengthMapping)) {
      message =
        currency === 'XOF'
          ? 'Your mobile number should be 10 digits.'
          : `Your mobile number should be ${
              currencyToMobileNoLengthMapping[currency as keyof typeof currencyToMobileNoLengthMapping]
            } digits.`;

      feedbackInit({
        message,
        type: 'warning',
        componentLevel: true
      });
      return;
    }

    closeFeedback();
  }, [withdrawalDetails.mobileNo, currency]);

  const handleMobileValidation = async () => {
    try {
      if (showMobileName) setShowMobileName(false);
      await validateMobileNumber(withdrawalDetails, setWithdrawalDetails);
      setShowMobileName(true);
    } catch (e) {
      feedbackInit({
        message: (e as { message: string }).message || 'An error occurred while validating mobile number',
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const validateMobileNumber = async (
    withdrawalDetails: WithdrawalDetails,
    setWithdrawalDetails: (value: React.SetStateAction<WithdrawalDetails>) => void
  ) => {
    const phoneNumber = `${countryMobileCode[currency as keyof typeof countryMobileCode]}${withdrawalDetails.mobileNo}`;
    const { mobileMoneyCode } = withdrawalDetails;
    const data = { mobileMoneyCode, phoneNumber, currency };

    if (['GHS'].includes(currency)) {
      try {
        setWithdrawalDetails(details => ({ ...details, bankEnquiry: { visible: true, message: 'Verifying mobile number...' } }));
        const { data: mobileDetails } = (await mobileEnquiry(data)) as { data: { account_name: string } };
        setWithdrawalDetails(details => ({
          ...details,
          isValidated: true,
          username: mobileDetails?.account_name || ''
        }));
        if (!mobileDetails?.account_name) {
          setAccountNameDisabled(false);
        }
      } catch (error) {
        logError(error);
        setAccountNameDisabled(false);
      }
    }
  };

  const resetValidateMobileNumberDetails = () => {
    setWithdrawalDetails(details => ({
      ...details,
      isvalidated: false,
      mobileNo: '',
      username: '',
      operatorCode: ''
    }));
  };

  return {
    validateMobileNumber,
    withdrawalDetails,
    setWithdrawalDetails,
    showMobileName,
    setShowMobileName,
    handleMobileValidation,
    accountNameDisabled,
    resetValidateMobileNumberDetails
  };
}
