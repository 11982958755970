import { MutableRefObject, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { FormikProps } from 'formik';
import { useDebouncedCallback } from 'use-debounce';

import { bankNameEnquiry } from '+hooks';
import { logError } from '+utils';

export function useAccountValidation({
  formRef,
  currency,
  canEditName
}: {
  formRef: MutableRefObject<FormikProps<unknown> | null>;
  currency: string;
  canEditName: boolean;
}) {
  const lastVerificatiedAt = useRef<Date | null>(null);
  const verificationDebounceTime = 700;

  const [bankDetails, setBankDetails] = useState({
    amount: null,
    description: '',
    pin: '',
    bankName: '',
    bankNumber: '',
    username: '',
    canEditName: false,
    bankEnquiry: {
      visible: false,
      message: 'Verifying bank account...',
      type: 'secondary'
    },
    showNameInput: false,
    isValidated: false,
    resolvedBankName: ''
  });

  const resetValidationDetails = () => {
    setBankDetails(prevDetails => ({
      ...prevDetails,
      showNameInput: false,
      isValidated: false,
      bankEnquiry: { ...prevDetails.bankEnquiry, visible: false },
      username: ''
    }));
    formRef?.current?.setFieldValue?.('account_name', '') as unknown as void;
  };

  const isAcctNumLengthValid = (selectedCurrency: string, acctNum = 0) => {
    const acctNumLength = `${acctNum}`?.length;
    switch (selectedCurrency) {
      case 'NGN':
        return acctNumLength > 9 && acctNumLength < 12;
      case 'KES':
        return acctNumLength >= 8;
      case 'ZAR':
        return acctNumLength >= 7 && acctNumLength < 12;
      default:
        return acctNumLength === 10;
    }
  };

  const validateBankAccount = async (
    values: {
      bank_code: string;
      account_number: number;
      account_name: string;
      bank_name: string;
    },
    setFieldValue: (field: string, value: string) => Promise<void>
  ) => {
    let name: string;
    const data = { bank: values?.bank_code, account: `${values?.account_number}`, currency };
    if (values?.bank_code?.length && isAcctNumLengthValid(currency, values?.account_number)) {
      await setFieldValue('account_name', '');
      try {
        setBankDetails(details => ({
          ...details,
          bankEnquiry: { ...details.bankEnquiry, visible: true, message: 'Verifying bank account...' }
        }));
        const { data: bankDetailsData } = (await bankNameEnquiry(data, true)) as { data: { account_name: string } };
        name = bankDetailsData?.account_name;
        if (name) {
          await setFieldValue('account_name', name);
        }
        setBankDetails(details => ({
          ...details,
          isValidated: true,
          showNameInput: !!name || ['ZAR', 'EGP'].includes(currency),
          bankEnquiry: { ...details.bankEnquiry, visible: false },
          username: name || '',
          ...(canEditName && !name && { canEditName: true }),
          bankName: values?.bank_code,
          bankNumber: values?.account_number?.toString(),
          resolvedBankName: values?.bank_name
        }));
      } catch (e) {
        const error = (e as AxiosError<{ message: string }>)?.response?.data;
        logError(error);
        setBankDetails(details => ({
          ...details,
          bankEnquiry: {
            // Hide error from previous validation
            visible: new Date().getTime() - (lastVerificatiedAt.current as Date).getTime() > verificationDebounceTime,
            type: 'danger',
            message: error?.message || 'Please check your bank account details and try again'
          }
        }));
      }
    }
  };

  const debounceValidateBankAccount = useDebouncedCallback(async (...params) => {
    lastVerificatiedAt.current = new Date();
    await validateBankAccount(...(params as Parameters<typeof validateBankAccount>));
  }, verificationDebounceTime);

  const showValidationStatus = () => {
    const { visible: show, message, type: enquiryType } = bankDetails.bankEnquiry;
    return (
      show && (
        <div style={{ margin: '-5px 0 10px' }}>
          {!['danger', 'info'].includes(enquiryType) && (
            <span
              className="spinner-border spinner-border-sm"
              style={{ opacity: '0.4', marginLeft: '10px' }}
              role="status"
              aria-hidden="true"
            />
          )}
          <em className={`text-${enquiryType}`}>{message}</em>
        </div>
      )
    );
  };

  return {
    validateBankAccount,
    debounceValidateBankAccount,
    resetValidationDetails,
    bankDetails,
    setBankDetails,
    showValidationStatus,
    accountNumMaxLength: currency === 'KES' ? '20' : '11'
  };
}

export default useAccountValidation;
