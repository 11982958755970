import React, { useRef } from 'react';

import info from '+assets/img/dashboard/tooltip.svg';

import './index.scss';

function ToolTip({
  type,
  message,
  image = info,
  classname = '',
  children,
  wrapperComponent: Wrapper = 'div'
}: {
  message: string | React.ReactNode;
  image?: React.ReactNode;
  classname?: string;
  type?: string;
  children?: React.ReactNode;
  wrapperComponent?: React.ElementType;
}) {
  const tooltipElement = useRef(null);

  const showToolTip = status => {
    const element = tooltipElement.current;
    if (element) {
      if (status) {
        element.classList.add('visible');
        element.setAttribute('aria-hidden', 'true');
        element.parentNode.classList.add('visible');
        return;
      }
      element.classList.remove('visible');
      element.setAttribute('aria-hidden', 'false');
      element.parentNode.classList.remove('visible');
    }
  };

  return (
    <Wrapper className={`text-tooltip-w ${classname}`}>
      {image && !children && (
        <img
          src={image}
          alt="info icon"
          className="text-tooltip--image"
          aria-hidden="true"
          aria-describedby={`${type}-info`}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
          onMouseOver={() => showToolTip(true)}
          onFocus={() => showToolTip(true)}
          onBlur={() => showToolTip(false)}
          onMouseLeave={() => showToolTip(false)}
        />
      )}

      {children && (
        <div
          tabIndex="0"
          onMouseOver={() => showToolTip(true)}
          onFocus={() => showToolTip(true)}
          onBlur={() => showToolTip(false)}
          onMouseLeave={() => showToolTip(false)}
        >
          {children}
        </div>
      )}
      <div className={`text-tooltip--content ${type}`} id={`${type}-info`} role="tooltip" aria-hidden={false} ref={tooltipElement}>
        {message}
      </div>
    </Wrapper>
  );
}

export default ToolTip;
