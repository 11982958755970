import { StateCreator } from 'zustand';

import { IRVCMerchantDetailsResponse, IssuanceSlice } from '+types';

const createIssuanceSlice: StateCreator<IssuanceSlice, [], [], IssuanceSlice> = set => ({
  cardAccess: {
    reserved: { status: 'inactive' },
    customer: { status: 'inactive' }
  },
  currencyAccess: {
    USD: { status: 'disabled' }
  },
  cardFees: { customer: { chargebacks: 0, issuance: 0 }, reserved: { chargebacks: 0, issuance: 0 } },
  walletBalance: { USD: { available_balance: 0 } },
  states: [],
  merchantDetails: {} as IRVCMerchantDetailsResponse,
  addStates: statesData => set(() => ({ states: statesData })),
  addMerchantDetails: newMerchantDetails => set(state => ({ merchantDetails: { ...state.merchantDetails, ...newMerchantDetails } })),
  addCardAccess: newAccessData => set(state => ({ cardAccess: { ...state.cardAccess, ...newAccessData } })),
  addCurrencyAccess: newCurrencyAccess => set(state => ({ currencyAccess: { ...state.currencyAccess, ...newCurrencyAccess } })),
  addCardFees: newFeesData => set(state => ({ cardFees: { ...state.cardFees, ...newFeesData } })),
  addWalletBalance: newBalanceData => set(state => ({ walletBalance: { ...state.walletBalance, ...newBalanceData } }))
});

export default createIssuanceSlice;
