import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Table from '+containers/Dashboard/Shared/Table';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '+containers/Shared/Tabs';
import { useSearchQuery } from '+hooks';
import useStore from '+store';
import { CommonSearchQueriesType, CurrencyType } from '+types';
import { filteredOutObjectProperty } from '+utils';

import Filter from '../../components/Filter/Filter';
import { useFetchIssuedCardTables, useFetchIssuingExport } from '../../hooks';
import { getIssuingPermissions } from '../../utils';
import { cardDetailsFilterProps, cardDetailsTableProps, computeTableTitle, exportActionOptions } from '../constants';

type TabLabel = 'Transactions' | 'Balance History' | 'Chargebacks' | 'Events';
type TabKey = 'transactions' | 'balance_history' | 'chargebacks' | 'events';
type TabListType = { label: TabLabel; key: TabKey; badge?: string }[];

const tabs: TabListType = [
  {
    label: 'Transactions',
    key: 'transactions'
  },
  {
    label: 'Balance History',
    key: 'balance_history'
  },
  {
    label: 'Chargebacks',
    key: 'chargebacks'
  },
  {
    label: 'Events',
    key: 'events'
  }
];
const TablesSection = ({ currency }: { currency: CurrencyType }) => {
  const { ref: reference } = useParams<{ ref: string }>();
  const merchantEnv = useStore(state => state.merchantEnv);
  const { setQuery, value: searchQueryValue } = useSearchQuery<
    {
      tab: TabKey;
    } & CommonSearchQueriesType
  >();

  const activeTab = searchQueryValue.tab ?? tabs[0].key;
  const limit = searchQueryValue.limit ?? '10';
  const page = searchQueryValue.page ?? '1';

  const { canViewChargebacks, canExportChargebacks, canExportCardTrxns } = getIssuingPermissions();

  const [isFiltered, setIsFiltered] = useState(false);

  const sortingParams = {
    ...filteredOutObjectProperty((searchQueryValue as unknown) as Record<string, string[]>, ['currency', 'page', 'limit', 'sorterType'])
  };

  const exportAction = useFetchIssuingExport({
    cardId: reference,
    resourceType: `single-card-${activeTab}`,
    exportFn: exportActionOptions[activeTab]
  });

  const { tableData, isFetching, refetch } = useFetchIssuedCardTables({
    tab: activeTab,
    cardId: reference,
    limit,
    page,
    merchantEnv,
    sortingParams
  });

  const paging = tableData?.data?.paging;

  const computePermissions = () => {
    if (canViewChargebacks && activeTab === 'chargebacks') return false;
    return true;
  };

  return (
    <Tabs defaultValue={activeTab} onChange={subtabValue => setQuery({ tab: subtabValue }, true)}>
      <div>
        <TabList>
          {tabs.map(({ label, key, badge }) => {
            return (
              <Tab key={key} value={key}>
                {label}
                {badge ? <p className="badge badge-danger">{badge}</p> : null}
              </Tab>
            );
          })}
        </TabList>
        <div className="divide-y" />
      </div>

      <TabPanels>
        {tabs.map(({ key }) => {
          const filterProps = cardDetailsFilterProps[key];
          const tableProps = cardDetailsTableProps({
            canExportCardTrxns: true,
            canExportEvents: canExportCardTrxns,
            canExportChargebacks,
            currency
          })[key];

          return (
            <TabPanel value={key} key={key} className="transaction_table_comp table-container">
              <Filter
                totalItems={paging?.total_items}
                title={computeTableTitle({
                  title: tableProps.tableTitle ?? '',
                  filtered: isFiltered,
                  activeTab,
                  pageItemCount: paging?.total_items
                })}
                actions={tableProps.tableActions}
                isFiltered={isFiltered}
                onChangeIsFiltered={setIsFiltered}
                onHandleFilter={() => refetch()}
                filterModalHeading={filterProps.filterModalHeading ?? ''}
                filterModalDescription={filterProps.filterModalDescription ?? ''}
                filterFields={filterProps.filterFields ?? {}}
                exportType={filterProps.exportType}
                quickFilterType={activeTab}
                exportHeading={filterProps.exportHeading}
                exportDescription={filterProps.exportDescription}
                exportAction={exportAction}
              />

              <Table
                tableClassName={`--history-table ${tableProps.tableClassName}`}
                headings={tableProps.headings}
                hasPagination
                borderedTable
                loading={isFetching || false}
                current={paging?.current}
                limitAction={value => setQuery({ limit: String(value) })}
                pageSize={paging?.page_size}
                actionFn={value => setQuery({ page: String(value) })}
                totalItems={paging?.total_items || 0}
                emptyStateHeading={tableProps.emptyStateHeading}
                emptyStateMessage={
                  <>
                    <span>{tableProps.emptyStateMessage}</span>
                    {computePermissions() && (
                      <button type="button" className="refetch-button" onClick={() => refetch()}>
                        <i className="os-icon os-icon-rotate-ccw mr-1" />
                        Refresh
                      </button>
                    )}
                  </>
                }
              >
                {tableProps.renderChildren(tableData?.data?.data)}
              </Table>
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
};

export default TablesSection;
