import { UtilServices } from '+services/util-services';
import { currencyToCountryCode } from '+utils';

type Options = {
  showError?: boolean;
};

export default function useBanks(currency = '', options: Options = {}) {
  const countryCode = currencyToCountryCode[currency.toUpperCase()];
  const result = UtilServices.useFetchBanks({
    enabled: true,
    code: countryCode
  });

  return result;
}
