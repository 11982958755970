import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import useExportDownloader from 'src/hooks/useExportDownloader';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import { TransactionServices } from '+services/transaction-services';
import useStore from '+store';
import { APIDownload, filteredOutObjectProperty, getDate, queriesParams } from '+utils';

import AdvanceExportModal from '../Shared/AdvanceExportModal';
import CurrencyTabs from '../Shared/CurrencyTabs';
import ExportFilterModal from '../Shared/ExportFilterModal';
import FilterComponent from '../Shared/Filter';
import TransactionTableComponent from '../Shared/HistoryTable';
import SearchComponent from '../Shared/Search';
import PayInDetails from './PayInDetails';

import searchClose from '+assets/img/dashboard/search-close.svg';

import './index.scss';

function PayInComponent() {
  const { profile, permissions } = useStore();

  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();

  const activeCurrency = searchQuery.value.currency || 'NGN';
  const limit = searchQuery.value.limit || '10';
  const paginationType = searchQuery.value.pageType || 'cursor';
  const pageAction = searchQuery.value.pageAction || 'start';
  const pageCursorValue = searchQuery.value.pageCursorValue || undefined;
  const status = searchQuery.value.status || undefined;
  const sorterType = searchQuery.value.sorterType || null;
  const isFilterVisible = !!searchQuery.value.isFilterVisible;

  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value as Record<string, string>, [
      queriesParams.currency,
      queriesParams.page,
      queriesParams.previousLimit,
      queriesParams.pageAction,
      queriesParams.pageCursorValue,
      queriesParams.totalItems,
      queriesParams.limit,
      queriesParams.sorterType,
      queriesParams.status,
      queriesParams.isFilterVisible
    ])
  };

  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [showLargeExportModal, setShowLargeExportModal] = useState(false);

  const availableCurrencies = useStore(store => store.availableCurrencies);
  const [exportParams, setExportParams] = useState<{
    format: string;
    fieldsToExport: string;
    currency: string;
    sortingParams: { status?: string[] };
    kind: string;
  } | null>(null);

  const {
    data: response,
    meta,
    isFetching,
    refetch
  } = TransactionServices.useGetTransactions({
    enabled: true,
    kind: 'payins',
    showErrorMessage: false,
    refetchOnCloseFeedbackError: true,
    params: {
      paginationType,
      limit,
      sortingParams,
      currency: activeCurrency,
      pageAction,
      pageCursorValue
    },
    onError: () => {
      let message = 'There has been an error getting your pay-ins.';
      if (sorterType) message = `There has been an error in finding results for your ${sorterType}. `;
      feedbackInit({
        message,
        type: 'danger'
      });
    }
  });

  const { data: countResponse } = TransactionServices.useGetTransactionsCount({
    enabled: true,
    kind: 'payins',
    params: { currency: activeCurrency, sortingParams }
  });

  const count = countResponse?.data;
  const data = response?.data;
  const transactions = data || [];
  const paging = meta?.paging;
  const totalCount = count?.count;

  const { isLoading } = TransactionServices.useExportTransactions({
    enabled: Boolean(exportParams),
    params: exportParams,
    errorMessage: 'There has been an error exporting your pay-ins',
    successMessage: 'Pay-ins successfully downloaded',
    onSuccess: res => {
      if (res.status === 202) {
        setShowLargeExportModal(true);
      } else {
        const type = exportParams?.format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `Pay-ins at ${getDate(Date.now())}`, type);
      }
      setExportModalVisible(false);
      setExportParams(null);
    },
    onError: () => {
      setExportModalVisible(false);
      setExportParams(null);
    }
  });

  const exportFile = (format: string, fieldToExport: string[]) => {
    const parameterizeArray = (key: string, arr: string[]) => {
      const encodedArray = arr.map(encodeURIComponent);
      return encodedArray.join(`&${key}[]=`);
    };
    const fields = parameterizeArray('fieldsToExport', fieldToExport);

    setExportParams({ format, fieldsToExport: fields, currency: activeCurrency, sortingParams, kind: 'payins' });
  };

  const { getDownload } = useExportDownloader('Payins');

  useEffect(() => {
    getDownload();
  }, []);

  const showClearButton = Object.values(sortingParams || {}).filter(item => !!item).length > 0;

  const setSearchQuery = (value: string) => {
    if (!availableCurrencies?.includes(value)) return;
    searchQuery.setQuery({ currency: value }, true);
  };
  return (
    <>
      <ExportFilterModal close={() => setShowLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />
      <div className="content-i">
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex">
            <CurrencyTabs currencies={availableCurrencies} activeCurrency={activeCurrency} onClick={setSearchQuery} />
          </div>
        </section>
        <div className="payins__top-menu">
          <div className="payins__inner-menu">
            {totalCount > 0 && (
              <span className="--filtered-text">
                All {totalCount > 1 ? 'Transactions' : 'Transaction'} ({totalCount}){showClearButton && ' (Filtered)'}
              </span>
            )}
            {!isFilterVisible && showClearButton && (
              <>
                <span className="divider-sm" />
                <button
                  type="button"
                  className="btn btn-sm btn-secondary clear-btn"
                  style={{ background: 'none', border: 'none', color: '#2376F3', fontWeight: 500 }}
                  onClick={() => searchQuery.clearAll()}
                >
                  <img src={searchClose} alt="close" aria-hidden />
                  <span>Clear</span>
                </button>
              </>
            )}
            {['export', 'manage'].includes(permissions.payin) && (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-secondary export-btn"
                  style={{ background: 'none', border: 'none', color: '#2376F3', fontWeight: 500 }}
                  hidden={transactions.length === 0}
                  onClick={() => setExportModalVisible(true)}
                >
                  <i className="os-icon os-icon-arrow-up-right" />
                  <span>Export</span>
                </button>
              </>
            )}
          </div>
          <div className="element-actions d-sm-block">
            <div className="search-filter-box">
              {['export', 'manage'].includes(permissions.payin) && (
                <>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary export-btn"
                    style={{ background: 'none', border: 'none', color: '#2376F3', fontWeight: 500 }}
                    hidden={transactions.length === 0}
                    onClick={() => setExportModalVisible(true)}
                  >
                    <i className="os-icon os-icon-arrow-up-right" />
                    <span>Export</span>
                  </button>
                  <span className="divider-sm" />
                </>
              )}
              {!isFilterVisible && (
                <SearchComponent
                  handleSearchQuery={param => {
                    if (param)
                      return searchQuery.setQuery({
                        page: '1',
                        pageAction: '',
                        pageCursorValue: '',
                        totalItems: '',
                        sorterType: 'search',
                        keyword: param
                      });
                    return searchQuery.setQuery({ sorterType: null, keyword: '' });
                  }}
                  type="pay-in"
                  activeCurrency={activeCurrency}
                />
              )}
              <button
                className="btn btn-secondary btn-sm filter-btn"
                type="button"
                style={{ background: 'none', border: 'none', color: '#2376F3', fontWeight: 500 }}
                onClick={() => searchQuery.setQuery({ isFilterVisible: isFilterVisible ? undefined : 'true' })}
              >
                <i className="os-icon os-icon-sliders" />
                <span>{isFilterVisible ? 'Hide' : 'Show'} Filter</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <FilterComponent
              handleFilterQuery={param => {
                searchQuery.setQuery({ page: '1', pageAction: '', pageCursorValue: '', totalItems: '', sorterType: 'filter', ...param });
              }}
              type="pay-in"
              activeCurrency={activeCurrency}
            />
            <TransactionTableComponent
              type="pay-in"
              data={transactions}
              isFetching={isFetching}
              paging={paging || {}}
              totalCount={totalCount}
              emptyStateMessage={
                <>
                  <span>It seems there are no transactions here.</span>
                  <button type="button" className="refetch-button" onClick={() => refetch()}>
                    <i className="os-icon os-icon-rotate-ccw" style={{ marginRight: '5px' }} /> Refresh
                  </button>
                </>
              }
              emptyStateHeading="No transactions found"
            />
            {exportModalVisible && (
              <AdvanceExportModal
                openExport={exportModalVisible}
                setOpenExport={setExportModalVisible}
                exportAction={exportFile}
                type="pay-in"
                currency={activeCurrency}
                showSuccessModal={false}
                isLoading={isLoading}
              />
            )}{' '}
          </div>
        </div>
      </div>
    </>
  );
}

export default function PayInPage() {
  return (
    <Switch>
      <Route exact path="/dashboard/pay-ins" component={PayInComponent} />
      <Route path="/dashboard/pay-ins/:id">
        <PayInDetails />
      </Route>
    </Switch>
  );
}
