import { useEffect, useState } from 'react';

import useStore from '+store';
import { IFeedbackProps } from '+types';

/**
 * @description This custom hook manages the status information of an asynchronous event and provides optional actions.
 * It is designed to work in conjunction with the FeedbackHandler.tsx component.
 *
 * @returns {Object} An object containing two functions:
 * - closeFeedback: Terminates FeedbackHandler, removing it from display and resetting its state.
 * - feedbackInit: Initializes FeedbackHandler with optional information to be displayed on the screen.
 *
 * @example
 * const { closeFeedback, feedbackInit } = useFeedbackHandler();
 *
 * // Example of initializing FeedbackHandler:
 * feedbackInit({
 *   status: 200,
 *   isClosable: true,
 *   title: "Transaction received",
 *   action: () => console.log("success!"),
 *   type: "success",
 *   componentLevel: true
 * });
 */

const getInitialState = (): IFeedbackProps => ({
  statusCode: undefined,
  isActive: false,
  isClosable: true,
  title: undefined,
  action: undefined,
  componentLevel: false,
  message: '',
  type: 'success'
});

const reducerFn = (prev: IFeedbackProps, next: Partial<IFeedbackProps>) => {
  return { ...prev, ...next };
};

const listeners: Array<(state: IFeedbackProps) => void> = [];

let stateInMemory = {
  ...getInitialState()
};

const dispatch = (newState: Partial<IFeedbackProps>) => {
  stateInMemory = reducerFn(stateInMemory, newState);
  listeners.forEach(listener => listener(stateInMemory));
};

const feedbackInit = (props: IFeedbackProps) => {
  const { sessionActive } = useStore.getState();
  if (sessionActive === true) {
    dispatch({ ...props, isActive: true });
  }
};

const closeFeedback = (delay?: number) => {
  if (delay) {
    setTimeout(() => {
      dispatch({ ...getInitialState() });
    }, delay);
  } else {
    dispatch({ ...getInitialState() });
  }
};

export default function useFeedbackHandler() {
  const [state, setState] = useState(stateInMemory);

  useEffect(() => {
    listeners.push(setState);
    return () => {
      const listenerIndex = listeners.indexOf(setState);
      if (listenerIndex > -1) listeners.splice(listenerIndex, 1);
    };
  }, []);

  return {
    ...state,
    closeFeedback,
    feedbackInit
  };
}

export { closeFeedback, feedbackInit };
