/* eslint-disable camelcase */
import { useEffect, useState } from 'react';

import { TransactionServices } from '+services/transaction-services';

export default function useGetFee({
  type = 'disburse',
  currency,
  channel = 'bank_account',
  currentAmount
}: {
  type?: string;
  currency: string;
  channel?: string;
  currentAmount: number;
}) {
  const [fee, setFee] = useState('0.00');
  const [fetchingFee, setFetchingFee] = useState(false);
  const getFee = TransactionServices.useCalculateTransferFees({
    onSuccess: data => {
      const value = data?.data?.[channel] as { fee: number; vat: number };
      setFetchingFee(false);
      const amount = (value?.fee || 0) + (value?.vat || 0);
      setFee(Number.isNaN(amount) ? '0.00' : amount.toFixed(2));
    },
    onError: () => {
      setFee('N/A');
    }
  });
  useEffect(() => {
    if (currentAmount > 0) {
      getFee.mutate({
        type,
        currency,
        channels: [channel],
        amount: currentAmount
      });
    }
  }, [currentAmount]);

  return { fee, fetchingFee, setFee };
}
