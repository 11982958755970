import { useReducerState } from '+hooks';
import { KYCServices } from '+services/kyc-services';
import useStore from '+store';
import { IBvnCollectionModalProps, IBVNState } from '+types/bvnCollection-types';
import { stripNonNumeric } from '+utils';

import Modal from './Modal';

import MessageIcon from '+assets/img/dashboard/chat-icon.svg';

import './BvnCollectionModal.scss';

export default function BvnCollectionModal({ visible, close }: IBvnCollectionModalProps) {
  const bvnStatus = useStore(state => state.merchantKYC);
  const bvnFeedBack = bvnStatus?.compliance?.feedback?.bvn?.[0];
  const [state, setState] = useReducerState<IBVNState>({
    bvn: '',
    isVerified: false,
    loading: false,
    bvnVerification: ''
  });

  const setDisabled = () => {
    return !state.bvn || state.bvn.length < 11 || !state.isVerified || state.loading;
  };

  const verifyBvnNumber = KYCServices.useDetailsVerification({
    errorMessage: 'There was an issue while trying to verify your BVN. Please ensure you input the correct BVN and try again.',
    bannerLevel: true,
    onSuccess: res => {
      setState({
        isVerified: res.data.status === 'found',
        bvnVerification: res.data.status
      });
    },
    onError: () => {
      setState({
        isVerified: false,
        bvnVerification: ''
      });
    }
  });

  const onClickSubmit = async () => {
    setState({
      loading: true
    });
    await submitBvn.mutateAsync({ bvn: state.bvn });
  };

  const submitBvn = KYCServices.useBvnSubmission({
    bannerLevel: true,
    reInvalidate: true,
    errorMessage: 'An error occurred while processing your request',
    hasFeedbackTimeout: true,
    onError: () => {
      setState({ loading: false });
    }
  });

  const showStatus = () => {
    switch (state.bvnVerification) {
      case 'found':
        return (
          <small>
            <span className="os-icon os-icon-checkmark" role="status" /> Valid
          </small>
        );
      case 'not_found':
        return <small className="text-danger">Invalid BVN</small>;
      case 'verifying':
        return <small>Verifying..</small>;
      default:
        return null;
    }
  };
  const defaultCollectionContent = () => {
    return (
      <>
        {bvnFeedBack && (
          <div className="bvn-warning">
            <div>
              <img src={MessageIcon} alt="info-icon" />
            </div>
            <p>
              <span>Feedback:</span> <br />
              {bvnFeedBack.note}
            </p>
          </div>
        )}

        <div className="element-box bvn-note p-0">
          <p>
            In compliance with regulations, you are required to submit a Bank Verification Number (BVN) for your business.{' '}
            <span>
              Please note that the BVN provided must belong to the business owner (sole proprietor) or one of your company’s listed director
              (companies limited by shares).
            </span>
          </p>
        </div>

        <div className="form-group mb-4">
          <label className="withdraw-label">
            <span className="dark">Bank Verification Number (BVN)</span>
          </label>

          <input
            className="form-control"
            data-testid="bvn-number"
            type={state.bvn.length === 11 ? 'text' : 'number'}
            name="bvn"
            value={state.bvn}
            maxLength={11}
            placeholder="Enter bank verification number"
            onChange={e => {
              const formattedInput = stripNonNumeric(e.target.value);
              setState({ bvn: formattedInput, isVerified: false, bvnVerification: '' });
              if (formattedInput.length === 11) {
                const verificationDetails = {
                  number: formattedInput,
                  type: 'bvn',
                  country: 'ng'
                };
                setState({ bvnVerification: 'verifying', isVerified: false });
                verifyBvnNumber.mutateAsync(verificationDetails);
              }
            }}
          />

          <div className="show-status">{showStatus()}</div>
        </div>
      </>
    );
  };

  const bvnCollectionContent = () => {
    const content = {
      heading: `Update BVN`,
      content: defaultCollectionContent(),
      secondButtonText: 'Submit',
      firstButtonText: 'Cancel',
      secondButtonAction: () => onClickSubmit(),
      completedHeading: 'BVN Update Successful!',
      completedDescription: 'You have successfully updated your BVN (Bank Verification Number).'
    };

    return {
      size: 'md',
      close: () => {
        setState({
          bvn: '',
          isVerified: false,
          loading: false
        });
        close();
      },
      secondButtonDisable: setDisabled(),
      ...content
    };
  };

  return <section>{visible && <Modal {...bvnCollectionContent()} />}</section>;
}
