import React from 'react';

import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, history, switchStatus } from '+utils';

interface IConversionItem {
  reference: string;
  status: string;
  source_amount: number;
  source_currency: string;
  converted_amount: number;
  destination_currency: string;
  transaction_date: string;
}

interface IConversionsProps {
  amountInputRef?: React.RefObject<HTMLInputElement>;
  isConversionAllowed?: boolean;
}

export const conversionTableData = (data: IConversionItem[]) => {
  const onRowClick = (id: string) => {
    return history.push(`/dashboard/conversions/${id}`);
  };

  return data?.map((item: IConversionItem) => {
    return (
      <div
        tabIndex={0}
        className="div-table --history-table --row"
        key={item.reference}
        role="button"
        onClick={() => onRowClick(item.reference)}
        onKeyDown={() => onRowClick(item.reference)}
      >
        <div className="--txn-status" style={{ display: 'flex', alignItems: 'center' }}>
          <span className="body-row-header">Status:</span>
          <span className={`status-pill smaller ${switchStatus(item?.status)}`} />
          <span>{capitalizeRemovedash(item.status)}</span>
        </div>
        <div>
          <span className="body-row-header">Conversions ID:</span>
          <span>{capitalize(item.reference)}</span>
        </div>
        <div>
          <span className="body-row-header">Converted from:</span>
          <span>{`${formatAmount(item?.source_amount)} ${item?.source_currency}`}</span>
        </div>
        <div>
          <span className="body-row-header">Converted to:</span>
          <span>
            <span className={item?.status === 'success' ? 'destination_amount' : ''}>{`${formatAmount(item?.converted_amount)} `}</span>{' '}
            {item?.destination_currency}
          </span>
        </div>
        <div>
          <span className="body-row-header">Date/Time:</span>
          <span>
            {getDate(item.transaction_date)}
            <span className="annotation trxn-date">{getTime(item.transaction_date)}</span>
          </span>
        </div>
      </div>
    );
  });
};

export const getConversionsTableProps = ({ isConversionAllowed, amountInputRef }: IConversionsProps) => ({
  headings: [
    { value: 'Status' },
    { value: 'Conversion ID' },
    { value: 'Converted from' },
    { value: 'Converted to' },
    { value: 'Date / Time' }
  ],
  emptyStateHeading: 'No conversions yet',
  tableClassName: '--history-table',
  emptyStateMessage: (
    <>
      {isConversionAllowed ? (
        <>
          <span>It looks like you have not done any conversions yet, would you like to carry out one now?</span>
          <button type="button" className="refetch-button" onClick={() => amountInputRef?.current?.focus()}>
            <i className="os-icon os-icon-plus" />
            Convert now
          </button>
        </>
      ) : (
        <>
          <span>It looks like you have not done any conversions yet; your conversions would appear here.</span>
        </>
      )}
    </>
  )
});
