import { useState } from 'react';

import { IPWhiteListServices } from '+services/ipwhitelist-services';
import useStore from '+store';
import { IUseIpWhiteListParams } from '+types/tips-and-guide';

export default function useIpWhiteList({ onError }: IUseIpWhiteListParams = {}) {
  const { authDetails } = useStore.getState() as any;
  const [ipEnabled, setIpEnabled] = useState<boolean | null>(null);

  const result = IPWhiteListServices.useFetchIPs({
    enabled: !!(authDetails?.access_token || authDetails?.refresh_token),
    onSuccess: data => setIpEnabled(data.data?.data?.active || false),
    onError
  });

  return { ...result, ipEnabled };
}
