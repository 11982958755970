import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Field, FieldInputProps, Form, Formik } from 'formik';

import { breadCrumbEvents } from '+/utils/bugsnag-events';
import CustomReCaptcha, { IReCaptchaRef } from '+containers/Shared/CustomReCaptcha';
import { AuthServices } from '+services/auth-services';
import { Storage } from '+services/storage-services';
import Feedback from '+shared/Feedback';
import { ISendResponse } from '+types';
import { SignInFormType, SignInResponseType } from '+types/auth-services-types';
import { EmailValidation, history, logBreadCrumb, logError } from '+utils';

import { useSigninResponse } from '../hook/useSigninResponse';

import ErrorSvg from '+assets/img/auth/error.svg';

import './index.scss';

interface IFieldErrors {
  email?: string;
  password?: string;
}

const SignInForm = ({
  initIfTwoFactorRequired
}: {
  initIfTwoFactorRequired: (res: { identifier: string; type: string }, email: string) => void;
}) => {
  const recaptchaRef = useRef<IReCaptchaRef>(null);
  const formRef = useRef<SignInFormType | null>(null);
  const { state, setState, onSuccess } = useSigninResponse();

  const [isLoginAttempt, setIsLoginAttempt] = useState(false);
  const [attemptNumber, setAttemptNumber] = useState(0);

  const handleSubmit = ({ email, password }: { email: string; password: string }) => {
    logBreadCrumb({ event: breadCrumbEvents.signin.loginButtonClicked, data: { email } });
    if (recaptchaRef.current?.executeRecaptcha) {
      setState({ ...state, isLoading: true });
      formRef.current = { email, password };
      recaptchaRef.current.executeRecaptcha();
    } else {
      setState({
        ...state,
        feedback: {
          message: 'Please wait while recatpcha loads and try again',
          visible: true,
          type: 'warning'
        }
      });
    }
  };

  const SignInMutation = AuthServices.useSignIn({
    onSuccess: (async (res: ISendResponse<SignInResponseType>) => {
      Storage.setItem('USER_EMAIL', (formRef?.current?.email as string) ?? '');
      await onSuccess(res, response => initIfTwoFactorRequired(response, (formRef?.current?.email as string) ?? ''));
    }) as unknown as (value: ISendResponse<SignInResponseType>) => void,
    onError: err => {
      const e = err as AxiosError<{ message: string; data: { attempts_left?: number; account_status: string } }>;
      const error = e.response?.data;
      logError(error);
      const errorMessage = () => {
        if (e?.response?.status?.toString().charAt(0) === '4' && error?.message) {
          if (error?.data?.attempts_left) {
            setAttemptNumber(error.data.attempts_left);
            setIsLoginAttempt(true);
            return error.message;
          }
          if (error?.message.includes('Maximum login attempts')) {
            setAttemptNumber(0);
            setIsLoginAttempt(false);
          }
          if (error?.data?.account_status === 'locked') {
            return 'locked';
          }

          if (error?.data?.account_status) {
            return error.message;
          }
        }
        return error?.message ?? 'There seems to be an issue signing you in. Please contact support@korapay.com for more info.';
      };
      setState({
        ...state,
        isLoading: false,
        feedback: { ...state.feedback, message: `${errorMessage()}`, visible: true }
      });
    }
  });

  const loginAct = () => {
    setState({ ...state, feedback: { ...state.feedback, visible: false, message: '' }, isLoading: true });
    SignInMutation.mutate(formRef.current as SignInFormType);
  };

  const showFeedback = () => {
    const { feedback } = state;
    return feedback.visible ? (
      <Feedback type={feedback.type} message={feedback.message === 'locked' ? <LockedMessage /> : feedback.message} />
    ) : (
      ''
    );
  };

  const configErrorMessage = (errors: IFieldErrors, values: { email: string; password: string }) => {
    let msg = null;

    if (values.email && !values.password) {
      if (errors.email) msg = errors.email;
    }

    if (values.password && !values.email) {
      if (errors.password) msg = errors.password;
    }

    if (values.email && values.password) {
      if (errors.email && !errors.password) msg = errors.email;
      if (!errors.email && errors.password) msg = errors.password;
      if (errors.email && errors.password) msg = `${errors.email} and ${errors.password}`;
    }

    return msg === null ? null : <Feedback type="danger" message={msg} />;
  };

  const recaptchaError = (errorType: string) => {
    setState({
      ...state,
      isLoading: false,
      feedback: {
        message: `Recaptcha verification ${errorType}. Please refresh this page and retry.`,
        visible: true,
        type: 'warning'
      }
    });
  };

  return (
    <>
      {showFeedback()}
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSubmit}
        validate={values => {
          const errors: IFieldErrors = {};
          if (!values.password) errors.password = 'Your password is required';
          return errors;
        }}
      >
        {({ errors, values, isValid, dirty }) => {
          return (
            <Form autoComplete="off" className="login__form">
              {configErrorMessage(errors, values)}
              <Field name="email" validate={EmailValidation}>
                {({ field }: { field: FieldInputProps<string> }) => (
                  <div className="input__wrap">
                    <label htmlFor={field.name} className="screen-reader-only">
                      Email Address
                    </label>
                    <input autoComplete="off" {...field} placeholder="Email" type="text" id="email" required />
                  </div>
                )}
              </Field>
              <Field name="password">
                {({ field }: { field: FieldInputProps<string> }) => (
                  <div className="input__wrap" style={{ position: 'relative' }}>
                    <label htmlFor={field.name} className="screen-reader-only">
                      Password
                    </label>
                    <input
                      autoComplete="off"
                      {...field}
                      placeholder="Password"
                      type="password"
                      maxLength={50}
                      id="password"
                      data-testid="password-input"
                      required
                    />
                  </div>
                )}
              </Field>
              <div
                className="forgot-text"
                aria-hidden="true"
                onClick={() => {
                  logBreadCrumb({ event: breadCrumbEvents.signin.resetButtonClicked, data: { email: values.email } });
                  history.push('/auth/forgot-password');
                }}
              >
                Forgot Password?
              </div>

              {isLoginAttempt && (
                <div className="login-attempts">
                  <img src={ErrorSvg} alt="Error Icon" />
                  <p>
                    You have <span>{attemptNumber} login attempts</span> left, after which your account would be
                    <br />
                    locked for security reasons.
                  </p>
                </div>
              )}

              <div className="btn-wrapper mt-5">
                <button className="btn-kpy --full-blue" type="submit" disabled={!(isValid && dirty) || state.isLoading}>
                  {state.isLoading && (
                    <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
                  )}
                  Log in
                </button>

                <CustomReCaptcha
                  ref={recaptchaRef}
                  onChange={loginAct}
                  onError={recaptchaError}
                  onNotReady={() =>
                    setState({
                      ...state,
                      feedback: {
                        message: 'Please wait while recatpcha loads and try again',
                        visible: true,
                        type: 'warning'
                      }
                    })
                  }
                />
                <div className="grey-text mt-3">
                  If you don’t have an account{' '}
                  <Link className="btn-kpy btn--link" to="/auth/signup">
                    <span className="link-text">Sign Up</span>
                  </Link>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SignInForm;

const LockedMessage = () => {
  return (
    <p className="locked-message">
      Your account has been locked.{' '}
      <Link className="bot_launcher_button" to={`${process.env.REACT_APP_SITE_HOSTNAME}/auth/login/locked-account`}>
        Learn how to unlock your account here
      </Link>
    </p>
  );
};
