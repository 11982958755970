import { useState } from 'react';

import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceholder';
import { MerchantServices } from '+services/merchant-services';
import useStore from '+store';
import { IReferralsData } from '+types';

import ReferralsInfo from './components/ReferralsInfo';
import ReferralsLink from './components/ReferralsLink';

import './index.scss';

const Referrals = () => {
  const [stage, setStage] = useState<'info' | 'link'>('info');
  const profile = useStore(state => state.profile);
  const [data, setData] = useState<IReferralsData | null>(null);
  const handleSetStage = (currentStage: 'info' | 'link') => {
    setStage(currentStage);
  };

  const { refetch, isLoading } = MerchantServices.useGetReferralStatus({
    queryKey: ['referral_link', profile?.email],
    onSuccess: data => {
      setData(data);
      setStage('link');
    },
    onError: () => {
      setStage('info');
    }
  });

  if (isLoading) return <LoadingPlaceholder type="text" content={2} />;

  return (
    <div>
      <div className="referrals-heading-box">
        <div className="">
          <h5 className="form-header">Referrals</h5>

          <div className="form-desc" style={{ border: 'none', marginBottom: '0', maxWidth: '24rem' }}>
            Create your exclusive referral link to invite friends and family! Earn exciting bonuses when they join and make their first
            transactions.
          </div>
        </div>
      </div>
      {stage === 'info' && <ReferralsInfo setStage={handleSetStage} />}
      {stage === 'link' && <ReferralsLink setStage={handleSetStage} data={data} refetchLink={refetch as () => void} />}
    </div>
  );
};

export default Referrals;
