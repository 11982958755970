import React from 'react';

import useStore from '+store';
import useLocalStore from '+store/localStore';
import { formatAmount } from '+utils';

function LimitUpdateTip() {
  const productTierLimit = useStore(state => state.productTierLimit);
  const tierLevel = useStore(state => state.tierLevel);
  const show = useLocalStore(state => state.baseLimitBanner);
  const setShow = useLocalStore(state => state.setBaseLimitBanner);
  const visible = show && tierLevel?.name?.toLowerCase() === 'base tier';

  return (
    <div className={`text-tooltip-w tier-tip-notify ${visible ? 'visible' : ''}`}>
      <div
        className={`text-tooltip--content ${visible ? 'visible' : ''}`}
        role="tooltip"
        aria-hidden={visible}
        data-testid="notify-content"
      >
        <button
          data-testid="close-button"
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            setShow(false);
          }}
        >
          <span className="os-icon os-icon-close" />
        </button>
        <p>
          <strong>Limit updated: </strong> You can now receive up to <strong>NGN {formatAmount(productTierLimit)}</strong> daily on your
          Kora account.
        </p>
      </div>
    </div>
  );
}

export default LimitUpdateTip;
