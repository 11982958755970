import Icon from '+containers/Dashboard/Shared/Icons';
import { IReferralsInfoCards } from '+types';

export const ReferralsInfoCards = ({ icon, title, description }: IReferralsInfoCards) => {
  return (
    <div className="referral-info-card">
      <div>
        <Icon name={icon} width={25} height={25} />
        <h6>{title}</h6>
      </div>
      <p>{description}</p>
    </div>
  );
};
