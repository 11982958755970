import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceholder';
import TabSwitch from '+containers/Dashboard/Shared/TabSwitch';
import { useClickOutside, useSearchQuery } from '+hooks';
import { VirtualBankServices } from '+services/virtual-bank-services';
import { capitalize, history } from '+utils';

import ManageAccountsHolderModal from '../VirtualAccountsModal/ManageAccountsHolderModal';
import AccountNumbers from './AccountNumbers';
import Events from './Events';
import KYCInformation from './KYCInformation';
import Transactions from './Transactions';

import CustomersIcon from '+assets/img/dashboard/customers.svg';
import DropdownIcon from '+assets/img/dashboard/dropdownIcon.svg';
import InfoIcon from '+assets/img/dashboard/exclamation.svg';
import SettilngsIcon from '+assets/img/dashboard/settings.svg';

export default function VirtualAccountHoldersDetails() {
  const { id } = useParams<{ id: string }>();
  const searchQuery = useSearchQuery();
  const activeTab = searchQuery.value.accountHolderTab || 'Account Numbers';
  const tabs = ['Account Numbers', 'Transaction', 'KYC Information', 'Events'];
  const [state, setState] = useState({
    openDropdown: false,
    modalVisibility: false,
    type: '',
    action: ''
  });

  const menuRef = useClickOutside<HTMLDivElement>(() => {
    if (state.openDropdown) {
      setState(prev => {
        return { ...prev, openDropdown: false };
      });
    }
  }, false);

  const {
    data: detailsData,
    isLoading: isFetchingDetails,
    isError
  } = VirtualBankServices.useGetVirtualBankAccountHolder({
    enabled: true,
    params: { accountReference: id },
    errorMessage: "There has been an error this account's information",
    refetchOnCloseFeedbackError: true
  });

  useEffect(() => {
    searchQuery.setQuery({ accountHolderTab: 'Account Numbers' });
  }, []);

  const renderTab = (tab: string) => {
    switch (tab) {
      case 'Account Numbers':
        return (
          <AccountNumbers
            reference={detailsData?.reference}
            name={`${capitalize(detailsData?.first_name)} ${capitalize(detailsData?.last_name)}`}
          />
        );
      case 'Transaction':
        return <Transactions />;
      case 'KYC Information':
        return <KYCInformation reference={detailsData?.reference} firstName={detailsData?.first_name} lastName={detailsData?.last_name} />;
      case 'Events':
        return <Events />;
      default:
        return (
          <AccountNumbers
            reference={detailsData?.reference}
            name={`${capitalize(detailsData?.first_name)} ${capitalize(detailsData?.last_name)}`}
          />
        );
    }
  };

  const manageMenuOptions = [
    ...(detailsData?.status === 'suspended'
      ? [{ text: 'Request Reactivation', type: 'reactivate' }]
      : [
          { text: 'Suspend Account Holder', type: 'suspend' },
          { text: 'Deactivate Account Holder', type: 'deactivate' }
        ])
  ];

  if (isFetchingDetails) return <LoadingPlaceholder type="text" />;
  if (isError) return <EmptyStateComponent message="Account holder not found" heading="Virtual Account Holders" />;

  return (
    <>
      <div className="row">
        <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
          <i className="os-icon os-icon-arrow-left7" />
          <span style={{ fontSize: '13px', fontWeight: '500' }}>Go Back to Account Holders</span>
        </button>
      </div>
      <div className="account-holder-header">
        <div className="holder-details">
          <div>
            <img src={CustomersIcon} alt="customer" />
            <span>Customer</span>
          </div>
          <h2>{`${capitalize(detailsData?.first_name)} ${capitalize(detailsData?.last_name)}`}</h2>
          <p>{capitalize(detailsData?.account_type)} Account Holder</p>
        </div>
        {!['deactivated', 'pending', 'rejected'].includes(detailsData?.status) && !detailsData?.requested_status && (
          <div
            onClick={() =>
              setState(prev => {
                return { ...prev, openDropdown: true };
              })
            }
            className="manage-account-holder"
            ref={menuRef}
          >
            <img src={SettilngsIcon} alt="" />
            <p>Manage Account Holder</p>
            <img className="dropdown-icon" src={DropdownIcon} alt="" />
            {state.openDropdown && (
              <div className="dropdown element-box box-style ellipsis__nav withdrawal-options menu-items account-actions">
                {manageMenuOptions.map(action => (
                  <button
                    type="button" // Add explicit type attribute
                    onClick={() =>
                      setState(prev => {
                        return { ...prev, modalVisibility: true, type: action.type, action: action.type };
                      })
                    }
                    className={action.type}
                    disabled={detailsData?.requested_status}
                  >
                    {action.text}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
        {detailsData?.status === 'suspended' && detailsData?.requested_status && (
          <div className="pending-reactivation">
            <img src={InfoIcon} alt="more info" />
            <p>Reactivation Pending</p>
          </div>
        )}
      </div>
      <div className="vba-banner-wrapper">
        {detailsData?.status === 'suspended' && (
          <section className="vba-banner">
            This account holder has been suspended!{' '}
            <span
              onClick={() =>
                setState(prev => {
                  return { ...prev, modalVisibility: true, type: 'reason', action: 'Suspension' };
                })
              }
            >
              Learn Why
            </span>
          </section>
        )}
        {detailsData?.status === 'deactivated' && (
          <section className="vba-banner deleted">
            This account holder has been deactivated!{' '}
            <span
              onClick={() =>
                setState(prev => {
                  return { ...prev, modalVisibility: true, type: 'reason', action: 'Deactivation' };
                })
              }
            >
              Learn Why
            </span>
          </section>
        )}
      </div>
      <div className="os-tabs-controls">
        <ul className="nav nav-pills smaller d-md-flex">
          <TabSwitch
            options={tabs}
            activeTab={activeTab}
            setTab={value => searchQuery.setQuery({ accountHolderTab: value })}
            id="virtual_accounts_holders_tab"
          />
        </ul>
      </div>
      {renderTab(activeTab)}
      {state.modalVisibility && (
        <ManageAccountsHolderModal
          close={() =>
            setState(prev => {
              return { ...prev, modalVisibility: false };
            })
          }
          type={state.type}
          action={state.action}
          reference={id}
          actionReason={detailsData?.status_reason}
        />
      )}
    </>
  );
}
