import { Link } from 'react-router-dom';

import CreateRefundsModal from '+containers/Dashboard/Shared/CreateRefundsModal';
import ReceiptModal from '+containers/Dashboard/Shared/ReceiptTemplate';
import TransactionDetails from '+containers/Dashboard/TransactionDetailsNew';
import { cardStatus, formatAmount } from '+utils';

import {
  accNumberGeneratedForPaymentSection,
  openRefundModalButton,
  payinDisputeChildrenGenerators,
  payInDisputesFn,
  payInDisputeSummaryGeneratorsFn,
  payInHeaderSummary,
  payInMoreTrxDetailBanner,
  payInMoreTrxDetailsSection,
  payInReceiptFn,
  payInRecipientInformationSection,
  viewPayInReceiptButton
} from './payInDetailsHelpers';
import PayInsRelatedTransactions from './payInDetailsWidget/RelatedTransactions';
import ViewTrxBreakdownModal from './payInDetailsWidget/ViewTrxBreakdownModal';
import usePayInDetails from './usePayInDetails';

import './index.scss';

const PayInDetails = () => {
  const { trxnStatusObj, isLoading, data, modalType, setModalType, refetch, relatedTrxns, moreThanOneRelatedTrx } = usePayInDetails();

  return (
    <section className="payin-details">
      <TransactionDetails loaderCount={6} isLoading={isLoading || !data}>
        <TransactionDetails.Header
          heading={formatAmount(data?.amount_charged)}
          currency={data?.currency}
          status={trxnStatusObj.name}
          statusBg={trxnStatusObj.backgroundColor}
          statusColor={trxnStatusObj.color}
          actionButtons={[
            ...(data?.status === 'success'
              ? [
                  viewPayInReceiptButton({ onClick: () => setModalType('receipt') }),
                  openRefundModalButton({ onClick: () => setModalType('refund'), trx: data })
                ]
              : [])
          ]}
          summaries={payInHeaderSummary(data)}
        />
        <TransactionDetails.Section
          dataTestId="payin-more-transaction-details"
          heading={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              More Transaction Details{' '}
              {!Object.keys(cardStatus).includes(data?.status) && (
                <button className="section-heading-link" onClick={() => setModalType('viewBreakdown')}>
                  View Amount Breakdown <i className="os-icon os-icon-arrow-up-right" />
                </button>
              )}
            </div>
          }
          preSummariesContent={payInMoreTrxDetailBanner(data)}
          summaries={payInMoreTrxDetailsSection(data)}
        />
        {data?.source?.virtual_bank_account && (
          <TransactionDetails.Section
            dataTestId="payin-account-number-generated-detais"
            heading="Account Number Generated for Payment"
            summaries={accNumberGeneratedForPaymentSection(data)}
          />
        )}

        <TransactionDetails.Section
          dataTestId="payer-information-details"
          heading="Payer's Information"
          summaries={payInRecipientInformationSection(data)}
        />

        {!!relatedTrxns?.length && (
          <TransactionDetails.Section
            dataTestId="related-transactions-details"
            heading={
              <div aria-label="related transactions" className="related-trxns-heading">
                Related Transaction{moreThanOneRelatedTrx && 's'}
                <Link
                  to={`/dashboard/pay-ins?page=1&currency=${data?.currency}&sorterType=filter&reference=${data?.payment.reference}`}
                  className="section-heading-link"
                >
                  See {moreThanOneRelatedTrx && 'All'} Related Transaction{moreThanOneRelatedTrx && 's'}{' '}
                  <i className="os-icon os-icon-arrow-up-right" />
                </Link>
              </div>
            }
          >
            <PayInsRelatedTransactions uniqueRef={data.payment.reference} trxns={relatedTrxns} />
          </TransactionDetails.Section>
        )}

        <TransactionDetails.Section dataTestId="disputes-section" heading="Refunds, Reversals & Chargebacks">
          <TransactionDetails.Disputes
            onGenerateDisputes={() => payInDisputesFn(data)}
            currency={data?.currency}
            onGenerateSummaryGenerators={payInDisputeSummaryGeneratorsFn}
            onGenerateChildrenGenerators={payinDisputeChildrenGenerators}
          />
        </TransactionDetails.Section>

        <TransactionDetails.Webhook
          canRequestWebhook={data?.can_request_webhook}
          trxRef={data?.reference}
          trxType="payin"
          notificationRef={data?.payment?.reference}
        />
      </TransactionDetails>

      {modalType === 'receipt' && <ReceiptModal data={payInReceiptFn(data)} close={() => setModalType('')} />}

      {modalType === 'refund' && (
        <CreateRefundsModal
          visible={modalType === 'refund'}
          reference={data?.payment?.reference}
          refetchRefund={refetch}
          currency={data.currency}
          close={() => setModalType('')}
        />
      )}

      {modalType === 'viewBreakdown' && <ViewTrxBreakdownModal trx={data} close={() => setModalType('')} />}
    </section>
  );
};

export default PayInDetails;
