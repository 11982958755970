import { MobileMoneyServices } from '+services/mobile-money-services';
import { currencyToCountryCode } from '+utils';

export default function useMobileMoneyNetwork(currency: string) {
  const countryCode = currencyToCountryCode[currency.toUpperCase()];

  const result = MobileMoneyServices.useFetchMobileMoneyNetwork({
    code: countryCode,
    errorMessage: 'There has been an error getting network providers. Please refresh the page.',
    refetchOnCloseFeedbackError: true,
    enabled: !!currency
  });

  return { ...result, data: result.data?.data };
}
