const RequestLimitReached = () => {
  return (
    <div className="bg-warning p-3 rounded mb-5">
      <p className="align-items-start m-0 warning-text">
        You have reached your access request limit. Please contact{' '}
        <a className="id-text-bold" href="mailto:sales@korapay.com" target="noopener noreferrer" style={{ marginLeft: '0.3rem' }}>
          sales@korapay.com
        </a>{' '}
        for assistance.
      </p>
    </div>
  );
};

export default RequestLimitReached;
