/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';

import CountdownTimer from '+containers/Dashboard/Shared/CountdownTimer';
import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import { useFeedbackHandler, useOTPAuth } from '+hooks';
import { CardIssuanceServices } from '+services/card-issuance-services';
import useStore from '+store';
import { CurrencyType, WalletBalanceType } from '+types';
import { backwardAmountInput, formatAmount, maskEmail, twoFAInputValidation } from '+utils';

import { ErrorType } from '../types';
import { getOtpDescriptors } from '../utils';

type FundsSources = {
  label: string;
  balance: number;
  currency: CurrencyType;
};

interface IFundIssuingModal {
  onClose: () => void;
  balances: WalletBalanceType;
  currency: CurrencyType;
}

type Stage = 'confirm' | 'default';

function FundIssuingWalletModal({ onClose, balances = {}, currency: activeCurrency }: IFundIssuingModal) {
  const store = useStore(state => state.defaultMerchant);
  const [fundingRef, setFundingRef] = useState();
  const { updateCountdownStatus, countdownIsCompleted, authState, resendOTP, updateAuthState } = useOTPAuth();
  const [canContinue, setCanContinue] = useState(false);
  const { otpPlaceholder, otpLabel } = getOtpDescriptors(authState.two_factor_type);
  const OTP_MIN_LENGTH = 6;
  const OTP_MAX_LENGTH = 11;
  const walletLimits = store?.issuing_wallet_limits || {};

  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const fundsSources: FundsSources[] =
    Object.keys(balances).map(currency => ({
      currency,
      label: `${currency} Balance`,
      balance: balances[currency]?.available_balance || 0
    })) || [];

  const [sourceOfFunds, setSourceOfFunds] = useState<FundsSources>(
    fundsSources.find(item => item.currency === activeCurrency) || fundsSources[0] || {}
  );

  const [stage, setStage] = useState<Stage>('default');

  const fundsMutation = CardIssuanceServices.useFundCardIssuingWallet({
    hasFeedbackTimeout: true,
    onSuccess: data => {
      const { reference, auth } = data.data;
      setStage('confirm');
      setFundingRef(reference);
      updateAuthState({ ...auth });
    },
    onError: (e: ErrorType) => {
      let errMsg = e.response?.data?.message;
      if (errMsg === 'invalid request data') {
        const errors = e.response?.data?.data;
        const keys = Object.keys(errors);
        if (keys.length) {
          errMsg = errors[keys[0]]?.message || errMsg;
        }
      }
      feedbackInit({
        componentLevel: true,
        message: errMsg,
        type: 'danger'
      });
    }
  });

  const handleOtpResend = async () => {
    updateCountdownStatus(false);
    await resendOTP();
  };

  const otpMutation = CardIssuanceServices.useAuthorizeCardIssuingFunding({
    reInvalidate: true,
    hasFeedbackTimeout: true,
    onError: (e: Error | AxiosError) => {
      let errMsg = e.response?.data?.message;
      if (errMsg === 'invalid request data') {
        const errors = e.response?.data?.data;
        const keys = Object.keys(errors);
        if (keys.length) {
          errMsg = errors[keys[0]]?.message || errMsg;
        }
      }
      feedbackInit({
        componentLevel: true,
        message: errMsg,
        type: 'danger'
      });
    }
  });

  const { errors, setFieldValue, dirty, isValid, values, submitForm } = useFormik({
    initialValues: {
      amount: '',
      otp: ''
    },
    validate: formValues => {
      const formErrors: Partial<typeof formValues> = {};
      const fundingLimits: Partial<Record<CurrencyType, { min: number }>> = {
        ...walletLimits
      };
      const minAmount = fundingLimits[sourceOfFunds.currency]?.min ?? 100;
      if (stage === 'default') {
        if (!formValues.amount) formErrors.amount = 'Amount is required';
        if (+formValues.amount < minAmount) {
          formErrors.amount = `Amount must be at least ${minAmount} ${sourceOfFunds.currency}`;
        }
        if (+formValues.amount > +sourceOfFunds.balance) {
          formErrors.amount = `You do not have sufficient funds in your ${sourceOfFunds.currency} balance for this amount. Kindly top up the balance or change the amount.`;
        }
      }
      if (stage === 'confirm') {
        if (!twoFAInputValidation(authState.two_factor_type, formValues.otp)) formErrors.otp = `Please enter a valid ${otpLabel}`;
      }
      return formErrors;
    },
    onSubmit: async () => {
      closeFeedback();
      if (stage === 'default') {
        await fundsMutation.mutateAsync({ amount: values.amount, currency: 'USD' });
      }
      if (stage === 'confirm') {
        await otpMutation.mutateAsync({
          reference: fundingRef,
          auth: {
            ...authState,
            code: values.otp
          }
        });
      }
    }
  });

  useEffect(() => {
    updateCountdownStatus(stage !== 'confirm');
  }, [stage]);

  useEffect(() => {
    if (values.otp === '' && stage === 'confirm') {
      setCanContinue(false);
    } else {
      setCanContinue(dirty && isValid);
    }
  }, [values, stage, isValid, dirty]);

  function renderFundingForm() {
    return (
      <>
        <div className="form-group">
          <label htmlFor="sourceOfFunds" className="withdraw-label">
            <span className="dark">Source of funds</span>
          </label>
          <select
            disabled
            className="form-control"
            name="sourceOfFunds"
            onChange={e => setSourceOfFunds(fundsSources.find(item => item.currency === e.target.value))}
            value={sourceOfFunds?.currency}
          >
            {fundsSources.map(option => (
              <option key={option.currency} value={option.currency}>
                {option.label} ({formatAmount(option.balance)} {option.currency})
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="amount" className="withdraw-label">
            <span className="dark">Amount ({sourceOfFunds?.currency ?? 'USD'})</span>
          </label>
          <input
            name="amount"
            type="number"
            className={`form-control ${errors.amount && dirty && ' error'}`}
            placeholder="0.00"
            maxLength={11}
            onChange={e => {
              setFieldValue('amount', backwardAmountInput(e.target.value));
            }}
            value={values.amount}
          />
          {errors.amount && dirty ? (
            <p className="error-msg mt-1" style={{ fontSize: '13px', color: '#F32345' }}>
              {errors.amount}
            </p>
          ) : null}
        </div>
      </>
    );
  }

  function renderOtpForm() {
    return (
      <>
        <p>
          To proceed, enter{' '}
          {authState.two_factor_type === 'otp' ? (
            <>
              the OTP (one-time PIN) that was sent to your email (<strong>{maskEmail(store.email)}</strong>).
            </>
          ) : null}
          {authState.two_factor_type === 'totp' ? 'the authentication code from your authenticator app' : null}
          {authState.two_factor_type === 'totp_recovery_code' ? 'a recovery code' : null}
        </p>

        <div className="form-group">
          <label htmlFor="otp" className="withdraw-label">
            <span className="dark">{otpLabel}</span>
          </label>
          <input
            name="otp"
            type="text"
            inputMode="numeric"
            className={`form-control ${errors.otp && dirty && ' error'}`}
            placeholder={otpPlaceholder}
            minLength={OTP_MIN_LENGTH}
            maxLength={OTP_MAX_LENGTH}
            onChange={e => {
              const numericValue = e.target.value.replace(/\D/g, '');
              setFieldValue('otp', numericValue);
            }}
            value={values.otp}
            pattern="\d*"
          />
          {errors.otp && dirty ? (
            <p className="error-msg mt-1" style={{ fontSize: '13px', color: '#F32345' }}>
              {errors.otp}
            </p>
          ) : null}

          {authState.two_factor_type === 'totp' ? (
            <div className="otp-cta mt-2">
              <span>Can&apos;t access authenticator app?</span>
              <button
                type="button"
                className="semibold btn btn-link ml-2"
                onClick={() => updateAuthState({ two_factor_type: 'totp_recovery_code' })}
              >
                Confirm using recovery codes
              </button>
            </div>
          ) : null}

          {authState.two_factor_type === 'otp' ? (
            <div className="otp-cta with-countdown mt-2">
              <span>You didn&apos;t receive a code?</span>
              {countdownIsCompleted ? (
                <button type="button" className="semibold btn btn-link" onClick={handleOtpResend}>
                  Resend code.
                </button>
              ) : (
                <CountdownTimer targetTime={30} onCountdownEnd={() => updateCountdownStatus(true)} />
              )}
            </div>
          ) : null}
        </div>
      </>
    );
  }

  const modalPropOptions: Record<string, Partial<IModalProps>> = {
    sharedProps: {
      close: onClose,
      secondButtonDisable: !canContinue,
      completedDescription: 'Your request to fund your issuing balance has been received and will be processed within 24 hours.',
      secondButtonAction: submitForm
    },
    default: {
      heading: 'Add funds to Issuing Balance',
      description: 'Funds on your issuing balance may be used for cards issuing and other card expenses.',
      content: renderFundingForm(),
      secondButtonActionIsTerminal: false
    },
    confirm: {
      heading: 'Confirm issuing balance funding',
      firstButtonText: 'Back',
      description: (
        <span>
          Please confirm that you want to transfer{' '}
          <b>
            {values.amount} {sourceOfFunds.currency}
          </b>{' '}
          from your <b>{sourceOfFunds.currency} Balance</b> to issuing balance. Your balance will be funded within 24 hours.
        </span>
      ),
      content: renderOtpForm(),
      firstButtonAction: () => {
        if (authState.two_factor_type === 'totp_recovery_code') {
          updateAuthState({ two_factor_type: 'totp' });
          return;
        }
        setStage('default');
      },
      secondButtonActionIsTerminal: true,
      secondButtonText: 'Yes, Confirm'
    }
  };

  const modalProps = {
    ...modalPropOptions.sharedProps,
    ...(modalPropOptions[stage] || modalPropOptions.default)
  };

  return (
    <div>
      <Modal {...(modalProps as IModalProps)} />
    </div>
  );
}

export default FundIssuingWalletModal;
