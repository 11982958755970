import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart, LinearScale } from 'chart.js';

import { AnalyticsServices } from '+services/analytics-services';
import { capitalize, formatAmount } from '+utils';

import LoadingPlaceholder from '../../Shared/LoadingPlaceholder';

import retry from '+assets/img/dashboard/retry.svg';

Chart.register(BarElement, CategoryScale, LinearScale);

interface IAnalyticsGraphProps {
  currency: string;
}

const AnalyticsGraph = ({ currency }: IAnalyticsGraphProps) => {
  const [state, setState] = useState({
    durationTabs: ['year', 'month', 'week'],
    activeDuration: 'year'
  });

  const { data, isFetching, refetch } = AnalyticsServices.useGetTransactionAnalytics({
    currency: currency.toLowerCase(),
    filter: 'payin'
  });

  const initialData = data ? data[`${state.activeDuration}Analytics`] || {} : {};
  const toolTipOptions = {
    backgroundColor: '#07002C',
    titleFontColor: 'white',
    bodyFontColor: 'white',
    borderColor: '#07002C',
    titleFontFamily: 'Averta PE',
    bodyFontFamily: 'Averta PE',
    borderWidth: 0,
    caretSize: 2,
    displayColors: false,
    callbacks: {
      label: tooltipItem => formatAmount(tooltipItem.yLabel)
    }
  };

  const BarChart = () => {
    const options = {
      legend: { display: false },
      tooltips: toolTipOptions,
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        y: {
          grid: {
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            userCallback: val => {
              const value = val
                .toString()
                .split(/(?=(?:...)*$)/)
                .join(',');
              return value;
            }
          }
        },
        x: {
          grid: {
            display: false
          },
          ticks: {
            fontColor: '#4a4a4a80',
            fontFamily: 'Averta PE',
            fontSize: 11
          }
        }
      }
    };
    const barData = {
      labels: Object.keys(initialData).map(key => key.substring(0, 3)),
      datasets: [
        {
          data: Object.keys(initialData).map(key => initialData[key]),
          backgroundColor: '#047bf8',
          borderColor: '#047bf8',
          maxBarThickness: 10,
          minBarLength: 0
        }
      ]
    };
    return state.activeDuration !== 'all time' ? (
      <Bar data={barData} options={options} height={120} />
    ) : (
      <div className="test-mode-analytics" style={{ padding: '3rem' }}>
        <p>Charts are currently not available for this time period</p>
      </div>
    );
  };

  const totalCalculator = activeDuration => {
    if (!data) return '--.--';
    if (activeDuration === 'all time') return data?.totalAnalytics ? formatAmount(data.totalAnalytics) : '-- . --';
    const durationData = Object.values(data[`${state.activeDuration}Analytics`]);
    if (durationData.length === 0) return '--.--';
    return formatAmount(durationData.reduce((acc, val) => acc + val));
  };

  return (
    <section className="analytics-graph__comp">
      <section className="el-tablo --f">
        <div>
          <div className="value">{totalCalculator(state.activeDuration)}</div>
          <div className="label">Total Payments Received ({currency})</div>
        </div>
        <div className="select-and-retry">
          <div className="element-actions">
            <form className="form-inline justify-content-sm-end">
              <select
                aria-label="duration-selector"
                className="form-control form-control-sm rounded"
                onChange={e => setState({ ...state, activeDuration: e.target.value })}
              >
                {state.durationTabs.map(tab => (
                  <option value={tab} key={tab}>
                    {tab === 'all time' ? 'All time' : `This ${capitalize(tab)}`}
                  </option>
                ))}
              </select>
            </form>
          </div>
          <button type="button" onClick={() => refetch()} aria-label="retry-button">
            <img src={retry} alt="retry" />
          </button>
        </div>
      </section>
      <div className="el-chart-w" style={{ margin: '30px 0 10px' }}>
        {isFetching ? <LoadingPlaceholder type="table" content={1} background="#f5f6f6" /> : BarChart()}
      </div>
    </section>
  );
};

export default AnalyticsGraph;
