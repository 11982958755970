import React from 'react';

import BvnUpdate from './components/BvnUpdate';
import Greetings from './components/Greetings';
import WalletSummary from './components/WalletSummary';

import './components/Onboarding.scss';
import './components/index.scss';

interface DashboardHomeComponentProps {
  setBvnModal: () => void;
  userRole: string;
}

const DashboardHomeComponent: React.FC<DashboardHomeComponentProps> = ({ setBvnModal, userRole }) => {
  return (
    <>
      <Greetings />
      <BvnUpdate setBvnModal={setBvnModal} userRole={userRole} />
      <WalletSummary />
    </>
  );
};

export default DashboardHomeComponent;
