/* eslint-disable no-unused-vars */
import { useFetch, useSend } from '+hooks';
import { ISendResponse } from '+types/api-services-types';

export const IPWhiteListServices = {
  // use initiate OTPToken here
  useFetchIPs: ({
    enabled,
    onSuccess,
    onError,
    bannerLevel,
    hasFeedbackTimeout,
    errorMessage
  }: {
    enabled?: boolean;
    onSuccess?: (value: ISendResponse<{ active: boolean }>) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    hasFeedbackTimeout?: boolean;
    errorMessage?: string;
  }) =>
    useFetch(`/auth/ip-addresses`, {
      enabled,
      onSuccess,
      onError,
      errorMessage,
      hasFeedbackTimeout,
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE,
      errorMessageBannerLevel: bannerLevel
    }),

  // use add IP here
  useAddIP: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    hasFeedbackTimeout,
    showErrorMessage
  }: {
    onSuccess?: (data: any) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    hasFeedbackTimeout?: boolean;
    showErrorMessage?: boolean;
  }) =>
    useSend({
      url: '/auth/ip-addresses',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      hasFeedbackTimeout,
      showErrorMessage,
      showSuccessMessage: false,
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE
    }),

  // use Update IP here
  useUpdateIP: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    hasFeedbackTimeout,
    showErrorMessage
  }: {
    onSuccess?: (data: any) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    hasFeedbackTimeout?: boolean;
    showErrorMessage?: boolean;
  }) =>
    useSend({
      url: '/auth/ip-addresses',
      method: 'patch',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      hasFeedbackTimeout,
      showErrorMessage,
      showSuccessMessage: false,
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE
    }),

  // use add IP here
  useEnableDisableIP: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    hasFeedbackTimeout,
    showErrorMessage
  }: {
    onSuccess?: (data: any) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    hasFeedbackTimeout?: boolean;
    showErrorMessage?: boolean;
  }) =>
    useSend({
      url: '/auth/ip-addresses/status',
      method: 'patch',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      hasFeedbackTimeout,
      showErrorMessage,
      showSuccessMessage: false,
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE
    }),

  // use resend OTP Action here
  useRemoveIP: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    hasFeedbackTimeout,
    showErrorMessage
  }: {
    onSuccess?: (data: any) => void;
    onError?: (error: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    hasFeedbackTimeout?: boolean;
    showErrorMessage?: boolean;
  }) =>
    useSend({
      url: '/auth/ip-addresses/remove',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      hasFeedbackTimeout,
      showErrorMessage,
      showSuccessMessage: false,
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE
    })
};
