import React, { useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import useStore from '+store';

import { getIssuingPermissions } from '../utils';
import AccessRequestModal from './AccessRequestModal';

import CardRequestImage from '+assets/img/dashboard/vcp-access.svg';

import './index.scss';

import Icon from '+containers/Dashboard/Shared/Icons';

const AccessRequestOnboarding: React.FC = () => {
  const cardAccess = useStore(useShallow(store => store.cardAccess));
  const { canRequestCardAccess } = getIssuingPermissions();
  const { customer, reserved } = cardAccess;
  const [showModal, setShowModal] = useState(false);

  const cta = (
    <div className="content-btn-wrapper">
      <button type="button" className="btn btn-primary" onClick={() => setShowModal(true)}>
        <Icon name="send" width={16} height={16} stroke="white" />
        <span className="ml-2">Request Access</span>
      </button>
      <div className="cta-text mt-5">
        <a href="https://developers.korapay.com/" target="noopener noreferrer">
          <i className="os-icon os-icon-book-open font-weight-bold mr-2" />
          <span>See Developer Guide</span>
        </a>
      </div>
    </div>
  );

  const message = (
    <p className="request-received-msg request-received-msg--onboarding">
      Your request has been received. Our sales team will contact you.
    </p>
  );

  const content = [reserved?.status, customer?.status].includes('pending') ? message : cta;

  return (
    <div className="vcp-request-page fade-in">
      <div className="vcp-request-main">
        <img src={CardRequestImage} alt="virtual card icon" />
        <h1>Create, issue and manage USD virtual cards</h1>

        <p className="mb-5">
          Use Kora’s powerful issuing engine to seamlessly create, issue and manage USD virtual cards for you and your customers - for
          online purchases, subscriptions, or other digital transactions.
        </p>

        {canRequestCardAccess ? content : null}
      </div>

      {showModal ? <AccessRequestModal onClose={() => setShowModal(false)} /> : null}
    </div>
  );
};

export default AccessRequestOnboarding;
