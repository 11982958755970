/* eslint-disable react/jsx-props-no-spreading */

import Modal from '+dashboard/Shared/Modal';

import './IdAccessRequestModal.scss';

export interface IdAccessRequestModalProps {
  onClose: () => void;
}
function IdAccessRequestCompletedModal({ onClose }: IdAccessRequestModalProps) {
  const modalPropOptions: Partial<Record<'shared' | 'initial', Record<string, unknown>>> = {
    shared: {
      close: () => {
        onClose();
      },
      firstButtonAction: () => {
        onClose();
      },
      modalStage: 'complete',
      secondButtonActionIsTerminal: true,
      completedHeading: 'Request submitted',
      completedDescription: (
        <>
          <p className="mb-4">Your request for identity service access has been submitted successfully.</p>
          <p>
            Our team will review your request and get back to you shortly. You will receive an email notification once your access has been
            granted.
          </p>
        </>
      )
    }
  };

  const modalProps = {
    ...modalPropOptions.shared
  };

  return (
    <div>
      <Modal {...modalProps} />
    </div>
  );
}

export default IdAccessRequestCompletedModal;
