import { useParams } from 'react-router-dom';

import { CreditCard } from '+containers/Shared/Icons';
import LoadingPlaceholder from '+dashboard/Shared/LoadingPlaceholder';
import { CardIssuanceServices } from '+services/card-issuance-services';
import { CardCategoryType, CurrencyType } from '+types';

import { getIssuingPermissions } from '../../utils';
import CardInformation from './CardInformation';
import IssuedCardAction from './IssuedCardActions';
import IssuedCardManagement from './IssuedCardManagement';
import StatusNotification from './StatusNotification';

const DetailsSection = ({ cardCategory, currency }: { cardCategory: CardCategoryType; currency: CurrencyType }) => {
  const { ref } = useParams<{ ref: string }>();
  const { canViewCardDetails, canManageCardDetails } = getIssuingPermissions();

  const { data, isFetching: fetchingCardDetails } = CardIssuanceServices.useGetSingleIssuedCard({
    ref,
    params: { ...cardQueryConfig[cardCategory].queryFnArg },
    errorMessages: 'There has been an error in getting your balance',
    refetchOnCloseFeedbackError: true,
    enabled: canViewCardDetails
  });

  const cardDetails = data?.data;

  const canManageCard = canManageCardDetails && cardDetails?.status !== 'terminated';

  if (fetchingCardDetails) return <LoadingPlaceholder type="text" />;

  return (
    <>
      <div className="card-details__heading">
        <div className="d-flex card-item">
          <div className="card-item__avatar">
            <CreditCard title="" titleId="" className="image" />
          </div>
          <div className="card-item__content">
            <div className="card-item__details">
              <h3 className="card-item__number">Card ending with **** {cardDetails?.last_four}</h3>
              <p className="card-item__type">
                <span>{cardDetails?.brand || 'Not Available'}</span>
                <span className="card-item__ellipse" />
                <span>{cardDetails?.category} card</span>
              </p>
            </div>

            {canManageCard && <IssuedCardManagement status={cardDetails?.status} currency={currency} reference={ref} />}
          </div>
        </div>
        <IssuedCardAction category={cardCategory} cardBalance={cardDetails?.balance} status={cardDetails?.status} />
      </div>

      <CardInformation cardDetails={cardDetails} cardCategory={cardCategory} reference={ref} />
      <StatusNotification status={cardDetails?.status} event={cardDetails?.event} />
    </>
  );
};

export default DetailsSection;

const cardQueryConfig = {
  customer_cards: {
    queryKey: 'CUSTOMER_CARD_DETAILS',
    queryFnArg: { cardCategory: 'customer' }
  },
  reserved_cards: {
    queryKey: 'RESERVED_CARD_DETAILS',
    queryFnArg: { cardCategory: 'reserved' }
  }
} as const;
