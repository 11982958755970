import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CSVFileReader from '+dashboard/Shared/CSVFileReader';
import ListDropdown from '+dashboard/Shared/ListDropdown';
import Modal from '+dashboard/Shared/Modal';
import ToolTip from '+dashboard/Shared/Tooltip';
import { useFeedbackHandler, useReducerState, useSearchQuery } from '+hooks';
import { TransactionServices } from '+services/transaction-services';
import useStore from '+store';
import { bulkPayoutContentT, BulkpayoutType, CSVParsedDataT } from '+types';
import { blockInvalidChars, cleanInput, formatAmount, switchCurrency } from '+utils';

import LearnMoreModal from '../../Shared/SecurityTipModal';
import { bulkPayoutsSampleFile, getAllowedCurrencyList, getBulkPayoutCurrencyList } from './BulkPayoutHelper';
import useBulkPayoutInfoModal from './useBulkPayoutInfoModal';
import useBulkPayoutHintsModal from './useBulkPayoutTipModal';

import info from '+assets/img/dashboard/bulk-tip-icon.svg';
import UploadFile from '+assets/img/dashboard/upload-file.svg';

import './index.scss';

const BulkPayoutFileUpload = () => {
  const searchQuery = useSearchQuery();
  const { feedbackInit } = useFeedbackHandler();
  const defaultCurrency = searchQuery.get('currency') || useStore(state => state.defaultCurrency);
  const [canSubmit, setCanSubmit] = useState(true);
  const defaultMerchant: BulkpayoutType = useStore((store: { defaultMerchant: BulkpayoutType }) => store.defaultMerchant);

  const bulkPayoutCurrencyList = getBulkPayoutCurrencyList(defaultMerchant);
  const allowedCurrencyList = getAllowedCurrencyList(bulkPayoutCurrencyList);

  const [state, setState] = useReducerState({
    payouts: [],
    currency: { code: '', name: '' },
    payoutType: { code: '', name: '' },
    inputValue: '',
    currencyListOpen: false,
    payoutListOpen: false,
    validating: false,
    type: '',
    message: '',
    isProcessing: false,
    retrievedData: {},
    isLoading: true
  });

  const { bulk_payout_limits: bulkLimit, payout_limits: payoutLimits } = defaultMerchant || {};

  const { payoutType, currency, inputValue, payouts } = state;

  const limits = {
    maxPayout: bulkLimit?.max_allowed_bulk_payouts,
    minPayout: bulkLimit?.min_allowed_bulk_payouts,
    bankAccountLimit: payoutLimits?.bank_account?.[currency?.code]?.max,
    bulkBankaccountLimit: payoutLimits?.bulk_bank_account?.[currency?.code]?.max
  };

  const isBankTrx = ['bank_account'].includes(state.payoutType?.code);

  const { getBulkPayoutInfoProps, showLearnMoreModal, setShowLearnMoreModal } = useBulkPayoutInfoModal(isBankTrx);
  const { getBulkPayoutHintsProps, showHintsModal, setShowHintsModal } = useBulkPayoutHintsModal();

  const parseData = (value: string | null) => (Object.is(value, null) ? value : cleanInput(value));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const isBlocking = useRef(true);
  const history = useHistory();
  const payoutsRef = useRef(payouts);

  const formatData = (data: CSVParsedDataT) => {
    if (!data || data?.length === 0) {
      return;
    }
    const header = data[0];
    const keysToCheck = isBankTrx
      ? ['amount', 'narration', 'bank_tag', 'account_number', 'account_name']
      : ['amount', 'narration', 'mmo_slug', 'mobile_number', 'account_name'];

    const allKeysExist = keysToCheck.every(key => header?.includes(key));

    const dataLength = data.slice(1)?.length;
    let message = '';

    if (!allKeysExist) {
      message = 'Invalid file uploaded, Please ensure the sheet contains the correct column header and row information.';
    } else if (dataLength > limits.maxPayout) {
      message = `Payouts must contain less than or equal to ${limits.maxPayout} items`;
    } else if (dataLength < limits.minPayout) {
      message = `Payouts must contain at least ${limits.minPayout} items`;
    }

    if (message) {
      feedbackInit({
        componentLevel: false,
        message,
        type: 'danger'
      });
      return;
    }

    const result = data.slice(1).map((item: string[]) => {
      return {
        amount: parseData(item[0]),
        narration: parseData(item[1]),
        ...(isBankTrx
          ? { bank_slug: parseData(item[2]), account_number: parseData(item[3]), customer_name: parseData(item[4]) }
          : { mmo_slug: parseData(item[2]), mobile_number: parseData(item[3]), customer_name: parseData(item[4]) })
      };
    });
    return result as unknown as bulkPayoutContentT[];
  };

  const processFormData = {
    type: payoutType?.code,
    currency: currency?.code,
    description: inputValue,
    payouts
  };

  const submitBulkPayout = TransactionServices.useSubmitBulkPayout({
    onSuccess: async data => {
      const response = data?.data;
      feedbackInit({
        componentLevel: false,
        message: 'Bulk payout information submitted successfully.',
        type: 'success'
      });
      setCanSubmit(true);
      setState({ retrievedData: response || {}, data: response || null });
      setTimeout(() => {
        history.push(`/dashboard/bulk-payout/${response?.reference}?currency=${defaultCurrency}`);
      }, 1500);
    },
    onError: error => {
      const errorMessage = error.response?.data?.message || 'There has been an error submitting bulk payout information';
      let messageElement;
      if (errorMessage.includes('contact support')) {
        messageElement = (
          <span>
            You do not have access to this service, please{' '}
            <a className="contact_support" href="mailto:support@korapay.com" target="_blank" rel="noopener noreferrer">
              Contact Support
            </a>{' '}
            to request access. Thank you.
          </span>
        );
      } else {
        messageElement = <span>{errorMessage}</span>;
      }
      feedbackInit({
        componentLevel: false,
        message: messageElement,
        type: 'danger'
      });
      setCanSubmit(true);
    }
  });

  const handleDropdownOpen = (type: string, open: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'currency') {
      setState({ currencyListOpen: open });
    } else if (type === 'payout') {
      setState({ payoutListOpen: open });
    }
  };

  const handleSelection = (type: string, value: { name: string; code: string }) => {
    if (type === 'currency') {
      setState({ currency: value });
    } else if (type === 'payout') {
      setState({ payoutType: value });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = cleanInput(e.target.value);
    setState({ inputValue, type: '', message: '' });
    if (inputValue.trim() === '') {
      setState({ type: 'onError', message: 'Please enter the bulk payout description.' });
      setCanSubmit(true);
    } else if (inputValue.length < 5) {
      setState({ type: 'onError', message: 'Bulk payout description must be more than 5 letters.' });
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  };

  const showValidation = () => {
    const { validating, type, message } = state;
    if (message) {
      let validationClass = '';
      if (type === 'onError') {
        validationClass = 'error-text';
      }
      if (type === 'onSuccess') {
        validationClass = 'success-text';
      }
      return (
        <div style={{ padding: '0.735rem 0' }}>
          {validating && (
            <span
              className="spinner-border spinner-border-sm"
              style={{ opacity: '0.6', marginRight: '0.5rem' }}
              role="status"
              aria-hidden="true"
            />
          )}
          <span className={`validation-text ${validationClass}`}>{message}</span>
        </div>
      );
    }
    return null;
  };

  const handleSubmit = async () => {
    setState({ loading: true, isProcessing: true });
    setCanSubmit(false);

    try {
      await submitBulkPayout.mutateAsync(processFormData);
      setState({ payouts: [] });
    } finally {
      setState({ isProcessing: false });
    }
  };

  useEffect(() => {
    payoutsRef.current = payouts;
  }, [payouts]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHintsModal(true);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const navigateToNextLocation = () => {
      if (!isBlocking.current && nextLocation?.pathname) {
        history.push(nextLocation.pathname);
      }
    };

    const blockNavigation = (location, action) => {
      if (isBlocking.current && payoutsRef.current?.length > 0) {
        setIsModalOpen(true);
        setNextLocation(location);
        return false;
      }
      return true;
    };

    navigateToNextLocation();
    const unblock = history.block(blockNavigation);

    return () => {
      unblock();
    };
  }, [isBlocking.current, payoutsRef.current, nextLocation, history]);

  const paymentOptions: { name: string; code: string }[] = [];
  if (payoutLimits?.bank_account?.[currency?.code || defaultCurrency]) paymentOptions.push({ code: 'bank_account', name: 'Bank Transfer' });
  if (payoutLimits?.mobile_money?.[currency?.code || defaultCurrency]) paymentOptions.push({ code: 'mobile_money', name: 'Mobile Money' });

  useEffect(() => {
    setState({
      payoutType: paymentOptions[0],
      ...(!currency?.code && { currency: { code: defaultCurrency, name: switchCurrency[defaultCurrency as keyof typeof switchCurrency] } })
    });
  }, [currency?.code]);

  const handleCloseConfirmNavigation = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="element-box">
      <Modal
        size="md"
        close={handleCloseConfirmNavigation}
        heading="Leaving the bulk payout upload page?"
        description={
          <p style={{ color: '#414F5F', fontWeight: 400, display: 'block' }}>
            Are you sure you want to leave this page? This would mean that you would lose your uploaded file as the session will not be
            saved. This action cannot be undone.
          </p>
        }
        justify="space-between"
        firstButtonText="No, Continue Upload"
        firstButtonAction={handleCloseConfirmNavigation}
        secondButtonText="Yes, Leave"
        secondButtonAction={async () => {
          setIsModalOpen(false);
          isBlocking.current = false;
        }}
        secondButtonColor="#F32345"
        visible={isModalOpen}
      />
      <div className="row">
        <div className="col-sm-12">
          <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7" />
            <span>Back to Bulk Payouts</span>
          </button>
        </div>
      </div>
      <div className="upload_wrapper">
        <div className="col-lg-6">
          <div className="upload-header-wrapper">
            <span>
              <img src={UploadFile} alt="file-upload" />
            </span>
            <h5>Upload Bulk Payout file</h5>
            <p>
              Fill the form below and upload your bulk payout file.
              <span>
                <a role="button" className="learn-more" onClick={() => setShowLearnMoreModal(true)} data-testid="learnMore">
                  {' '}
                  Learn More.
                </a>
              </span>
            </p>
          </div>
          <div className="upload-form-wrapper">
            <div className="form-group reason-container">
              <label htmlFor="payout currency" className="withdraw-lable">
                <span className="dark form_label">Payout Currency</span>
              </label>
              <ListDropdown
                placeholder="Select the currency for this bulk payout"
                list={allowedCurrencyList}
                className="form-control list-dropdown"
                value={{ name: state.currency?.name }}
                active={state.currencyListOpen}
                setActive={(open: React.ChangeEvent<HTMLInputElement>) => handleDropdownOpen('currency', open)}
                setValue={(value: { name: string; code: string }) => handleSelection('currency', value)}
                searchDropdown={false}
                displayText={false}
                defaultValue={defaultCurrency}
              />
            </div>
            <div className="form-group reason-container">
              <label htmlFor="payout type" className="withdraw-lable">
                <span className="dark form_label">Payout Type</span>
              </label>
              <ListDropdown
                list={paymentOptions}
                type="payout type"
                className="form-control list-dropdown"
                searchDropdown={false}
                displayText={false}
                active={state.payoutListOpen}
                setActive={(open: React.ChangeEvent<HTMLInputElement>) => handleDropdownOpen('payout', open)}
                value={{ name: state.payoutType?.name }}
                setValue={(value: { name: string; code: string }) => handleSelection('payout', value)}
              />
            </div>
            <div className="form-group custom_reference">
              <label htmlFor="custom reference" className="withdraw-label custom-reference">
                <span className="dark">Unique Bulk Payouts Tag</span>
                <ToolTip
                  type="reserve balance info"
                  image={info}
                  message={
                    <section className="--reserve-tooltip">
                      <p>
                        <strong>Unique Bulk Payouts Tag:</strong>
                      </p>
                      <p>This is a unique title that you can use to identify your bulk payout.</p>
                      <p>
                        Note: Your bulk payout title should not include special characters and spaces; only alphabets (A-Z), numbers (0-9)
                        and/or hyphens (-). Consider using hyphens instead of space.{' '}
                      </p>
                    </section>
                  }
                />
              </label>
              <input
                type="text"
                className="form-control"
                name="custom-reference"
                value={state.inputValue}
                placeholder="Enter a unique ID for this bulk payout"
                onChange={handleInputChange}
                maxLength={50}
                onKeyDown={blockInvalidChars}
              />
              {showValidation()}
            </div>
            <div className="form-group max-payout-info">
              <CSVFileReader
                onChange={(value: CSVParsedDataT) => {
                  setState({ payouts: formatData(value) });
                }}
              />
              <p>
                Please note that only a minimum of <strong> {`${limits.minPayout} payouts`} </strong> and maximum of{' '}
                <strong> {`${limits.maxPayout} payouts`} </strong> are allowed in a single upload. Maximum amount allowed for a single
                payout is
                <strong>
                  {' '}
                  {` ${currency?.code} ${
                    formatAmount(payoutLimits?.[payoutType?.code as keyof typeof payoutLimits]?.[currency?.code]?.max) || '--'
                  }`}{' '}
                </strong>{' '}
                Total payout amount should not exceed{' '}
                <strong>
                  {' '}
                  {` ${currency?.code} ${
                    payoutLimits?.bulk_bank_account?.[currency?.code]
                      ? formatAmount(payoutLimits?.bulk_bank_account?.[currency?.code]?.max)
                      : '--'
                  }`}{' '}
                  .
                </strong>{' '}
                The uploaded file must be in <strong>CSV</strong> or <strong>Excel,</strong> and <strong>less than 10MB</strong> in size.
              </p>
              <p className="sample-bulkpayout">{bulkPayoutsSampleFile(isBankTrx, currency.code)}</p>
            </div>
          </div>
          <div className="button_wrapper">
            <button
              className="btn btn-primary w-100"
              type="button"
              onClick={handleSubmit}
              disabled={!state.isProcessing && !(canSubmit === false && payouts?.length > 0)}
            >
              {state.isProcessing && (
                <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
              )}
              Continue
            </button>
          </div>
        </div>
      </div>
      <LearnMoreModal visible={showLearnMoreModal} close={() => setShowLearnMoreModal(false)} {...getBulkPayoutInfoProps()} />
      <LearnMoreModal visible={showHintsModal} close={() => setShowHintsModal(false)} {...getBulkPayoutHintsProps()} />
    </div>
  );
};

export default BulkPayoutFileUpload;
