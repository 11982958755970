import { useState } from 'react';

import { useSearchQuery } from '+hooks';
import { ForeignBankProps } from '+types';

import ForeignWithdrawalRequestform from './components/ForeignWithdrawalRequestform';
import { buttonType } from './components/ForeignWithdrawalRequestformData';
import ButtonGroup from './components/SwitchTransferButtonGroups';

import './index.scss';

export default function ForeignCurrencies({
  countriesList,
  statesList,
  requestFormProps,
  selectedButtonType,
  setSelectedButtonType
}: ForeignBankProps & { selectedButtonType: string; setSelectedButtonType: (value: string) => void }) {
  const [type, setType] = useState('local');
  const searchQuery = useSearchQuery();
  const activeCurrency = searchQuery.value.currency || 'NGN';

  const switchTransferType = (value: string) => {
    setType(value);
    setSelectedButtonType(value);
  };

  return (
    <>
      <ButtonGroup buttonType={buttonType} type={type} switchTransferType={switchTransferType} />
      <ForeignWithdrawalRequestform
        buttonType={selectedButtonType || ''}
        requestFormProps={requestFormProps}
        countriesList={countriesList}
        statesList={statesList}
        currency={activeCurrency}
      />
    </>
  );
}
