/* eslint-disable no-unused-vars */
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface IReCaptchaComponentProps {
  onChange: (token: string | null) => void;
  onError: (errorType: string) => void;
  onNotReady?: () => void;
}

export interface IReCaptchaRef {
  executeRecaptcha: () => void;
}

const CustomReCaptcha = forwardRef(({ onChange, onError, onNotReady }: IReCaptchaComponentProps, ref) => {
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const recaptchaError = (errorType: 'error' | 'expired') => {
    recaptchaRef.current?.reset();
    onError?.(errorType);
  };
  const executeRecaptcha = () => {
    if (process.env.REACT_APP_POSEIDON_BOLT_INIT === 'true') {
      if (recaptchaRef.current?.execute) {
        recaptchaRef.current?.execute();
      } else {
        onNotReady?.();
      }
    } else {
      onChange(null);
    }
  };
  useImperativeHandle(ref, () => ({
    executeRecaptcha
  }));
  return (
    process.env.REACT_APP_POSEIDON_BOLT_INIT === 'true' && (
      <ReCAPTCHA
        ref={recaptchaRef as React.MutableRefObject<ReCAPTCHA>}
        size="invisible"
        sitekey={process.env.REACT_APP_POSEIDON_BOLT || ''}
        onChange={onChange}
        onError={() => recaptchaError('error')}
        onExpired={() => recaptchaError('expired')}
      />
    )
  );
});

export default CustomReCaptcha;
