type queriesParamType =
  | 'current'
  | 'limit'
  | 'page'
  | 'tab'
  | 'subTab'
  | 'sorterType'
  | 'currency'
  | 'status'
  | 'isFilterVisible'
  | 'pageAction'
  | 'pageCursorValue'
  | 'totalItems'
  | 'previousLimit';

// eslint-disable-next-line no-unused-vars
export const queriesParams: { [k in queriesParamType]: queriesParamType } = {
  current: 'current',
  currency: 'currency',
  page: 'page',
  limit: 'limit',
  tab: 'tab',
  subTab: 'subTab',
  sorterType: 'sorterType',
  status: 'status',
  isFilterVisible: 'isFilterVisible',
  pageAction: 'pageAction',
  previousLimit: 'previousLimit',
  pageCursorValue: 'pageCursorValue',
  totalItems: 'totalItems'
};

export const cardStatus = {
  pre_authorized: 'Pre-Authorized',
  void_authorization: 'Voided (Auth)',
  void_capture: 'Voided (Capture)'
};
export const currencyOrder: string[] = ['NGN', 'USD', 'GBP', 'EUR', 'GHS', 'KES', 'ZAR', 'XAF', 'XOF', 'EGP'];
