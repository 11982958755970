/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { CurrencyType } from '+types';
import { formatAmount } from '+utils';

import './index.scss';

type SummaryItem = { label: React.ReactNode; value: React.ReactNode; hidden?: boolean };

const ChargebacksTab = <T extends Record<string, any>>({
  data,
  currency,
  summaryGenerator,
  childrenGenerator
}: {
  data: Array<T>;
  currency: CurrencyType;
  summaryGenerator: (...args: Array<any>) => Array<SummaryItem>;
  childrenGenerator: (...args: Array<any>) => React.ReactNode;
}) => {
  if (!data || data.length === 0) return <p className="cluster empty-state"> No chargebacks for this transaction</p>;

  const [activeIndex, setActiveIndex] = useState(0);
  const chargebacksCount = data?.length;
  const summaries = summaryGenerator(data[activeIndex], currency);
  const children = childrenGenerator?.(data[activeIndex]);

  const chargebacks = (
    <ul>
      {data.map((chargeback, index: number) => (
        <li key={chargeback?.reference}>
          <button
            onClick={() => setActiveIndex(index)}
            type="button"
            className={`dispute-amount ${activeIndex === index ? 'active' : ''}`}
            aria-selected={activeIndex === index}
            id={`${index}-controller`}
            role="tab"
            aria-controls={`${index}-panel`}
          >
            Chargeback of <em>{`${formatAmount(chargeback?.amount)} ${currency}`} </em>
          </button>
        </li>
      ))}
    </ul>
  );

  return (
    <section className="with-sidebar">
      <div className="sidebar dispute-sidebar">
        <p className="counter">{`${chargebacksCount} ${chargebacksCount > 1 ? 'Chargebacks' : 'Chargeback'} found`}</p> {chargebacks}
      </div>

      <div
        className="not-sidebar dispute-not-sidebar"
        aria-labelledby={`${activeIndex}-controller`}
        id={`${activeIndex}-panel`}
        role="tabpanel"
      >
        <ul className="dispute-summaries">
          {summaries.map((summary, index) => {
            return summary.hidden ? null : (
              <li className="dispute-summary-item cluster nowrap" key={summary.label as string}>
                <div className="dispute-summary-label" id={`${index}`}>
                  {summary.label}
                </div>
                <div className="dispute-summary-value" aria-labelledby={`${index}`}>
                  {summary.value}
                </div>
              </li>
            );
          })}
        </ul>

        <div className="dispute-children-container">{children}</div>
      </div>
    </section>
  );
};

export default ChargebacksTab;
