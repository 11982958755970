import React from 'react';

import Accordion from '+containers/Dashboard/Shared/Accordion';
import useStore from '+store';
import { Fee, FundByBankModalProps, FundByModal } from '+types/common';
import { formatAmount, history } from '+utils';

import Modal from '../../Shared/Modal';
import Copyable from './Copyable';

import arrow from '+assets/img/dashboard/arrow-down-light.svg';
import RecommendedSvg from '+assets/img/dashboard/recommended.svg';

import './index.scss';

const FundByBankModal: React.FC<FundByBankModalProps & FundByModal> = ({ visible, close, virtualAccount }) => {
  const CAN_GO_LIVE = useStore(state => state.canGoLive);
  const MERCHANT_ENV = useStore((store: { merchantEnv: any }) => store.merchantEnv);

  const handleFees = () => {
    const determineIfPercent = (fee: Fee): string => {
      if (fee?.type === 'percent') {
        return `${fee.total_fee}%`;
      }
      return `NGN ${formatAmount(fee.total_fee)}`;
    };

    const formatFeeArray = (fees: any[]) => {
      const min = Math.min(...fees.map((fee: { total_fee: number }) => fee.total_fee));
      const max = Math.max(...fees.map((fee: { total_fee: number }) => fee.total_fee));
      const minMaxFeeDetails = fees
        .filter((fee: { total_fee: number }) => [min, max].includes(fee.total_fee))
        .sort((min, max) => min.total_fee - max.total_fee);
      const feeArray = [...new Set(minMaxFeeDetails.map((fee: Fee) => determineIfPercent(fee)))];
      if (feeArray.length === 1) return feeArray[0];
      return feeArray.join(' - ');
    };

    const feeRange = virtualAccount?.data?.reserved_bank_accounts?.[0]?.fee_definition || [];
    if (feeRange.length < 1) return `0.00`;
    if (feeRange.length === 1) {
      return determineIfPercent(feeRange[0]);
    }
    return formatFeeArray(feeRange);
  };

  const sortedRBAs = virtualAccount?.data?.reserved_bank_accounts?.sort(a => {
    if (a?.preferred) return -1;
    return 1;
  });

  const hasPreference = virtualAccount?.data?.reserved_bank_accounts?.filter((acc: { preferred: number }) => acc?.preferred)?.length > 0;

  const modalContent = () => (
    <>
      {MERCHANT_ENV === 'live' ? (
        <>
          <div className="top-up-container">
            <section className="top-up-heading">
              <p className="mb-1">Reserved Bank Accounts For</p>
              <h4>{virtualAccount?.data?.merchant_name}</h4>
            </section>
            {sortedRBAs?.map(account => (
              <Accordion
                className={' '}
                key={account?.account_number}
                title={
                  <div key={account?.account_number} className={`top-up-row ${!account?.preferred && hasPreference && 'has-pref'}`}>
                    <div className="d-flex flex-row align-items-center">
                      <p className="label text-capitalize mr-2 mb-0 mt-1">{account?.bank_name?.toLowerCase()}</p>
                      {account?.preferred && <img src={RecommendedSvg} alt="active" aria-hidden />}
                    </div>
                  </div>
                }
                defaultOpen={account?.preferred}
                showToggle
                customToggle={<img className="toggler-arrow" src={arrow} alt="" />}
                content={
                  <>
                    <div className="top-up-row">
                      <p className="label">Account Number</p>
                      <p className="value">
                        <Copyable text={account.account_number} buttonClassName="copy-section" copyColor="white" showCopyText />
                      </p>
                    </div>
                    <div className="top-up-row">
                      <p className="label">Account Name</p>
                      <p className="value">{account.account_name}</p>
                    </div>
                  </>
                }
              />
            ))}
          </div>
          <div className="fee-desc pt-3 px-1">
            <p>Fees applied to funding</p>
            <p>{handleFees()}</p>
          </div>
        </>
      ) : (
        <div className="top-up-container">
          <p style={{ textAlign: 'center' }}>
            This feature is not available in test mode.
            <button
              type="button"
              onClick={() => {
                if (CAN_GO_LIVE) {
                  const toggle = document.getElementById('env-toggler');
                  toggle.click();
                } else {
                  history.push('/dashboard/settings/compliance');
                }
              }}
            >
              Switch to live mode
            </button>
            to top up.
          </p>
        </div>
      )}
      <section className="dismiss-section px-4 pb-3">
        <div className="modal-footer">
          <button className="btn border-0 btn-full" data-dismiss="modal" type="button" onClick={close}>
            Dismiss
          </button>
        </div>
      </section>
    </>
  );

  return (
    <section className="add-funds-modal__comp">
      {visible && (
        <Modal
          close={close}
          size="md"
          heading="Add funds to your balance"
          description="You can add funds to your balance by making a transfer of any amount from your bank into your Reserved Bank Account."
          content={modalContent()}
          showButtons={false}
          secondButtonAction={null}
        />
      )}
    </section>
  );
};

export default FundByBankModal;
