import draft from '+assets/img/dashboard/draft-Icon.png';

const AccessBanner = () => {
  return (
    <div className="row justify-content-between bg-warning p-3 rounded mb-5">
      <div className="col-sm-12 col-lg-4 p-0">
        <h6 className="d-flex align-items-center">
          <img className="mr-2" width={14} src={draft} alt="draft_icon" />
          <span> This service has been disabled. Please contact Support.</span>
        </h6>
      </div>
      <div className="col-lg-2 text-right p-0">
        <a href="mailto:support@korapay.com" target="noopener noreferrer" style={{ marginLeft: '0.3rem' }}>
          Contact Support
        </a>
      </div>
    </div>
  );
};

export default AccessBanner;
