import { useState } from 'react';

import { IProductLimit, ProductLimitValue } from '+types/productTiers';
import { capitalizeRemovedash, formatAmount } from '+utils';

import Icon from './Icons';

import './ProductFeatureDropdown.scss';

const ProductFeatureDropdown = ({ details, currency }: { details: IProductLimit; currency: string }) => {
  const [productDisplay, setProductDisplay] = useState('');
  const renderValue = (value: ProductLimitValue) => {
    if (typeof value === 'number' || typeof value === 'string') return Number.isNaN(Number(value)) ? value : formatAmount(value);
    return (
      <span className={value.custom ? 'is-custom' : ''}>{Number.isNaN(Number(value.value)) ? value.value : formatAmount(value.value)}</span>
    );
  };

  const onRowClick = (key: string) => {
    let value = key;
    if (productDisplay === key) value = '';
    setProductDisplay(value);
  };

  return (
    <div className="tier-wrapper">
      <div className="tier-product-list-wrapper">
        <div className="tier-col tier-col1">
          <h6>Product Features</h6>
        </div>
        <div className="tier-col tier-col2">
          <h6>Transaction Limit ({currency})</h6>
        </div>
        <div className="tier-col tier-col3">
          <h6>Daily Limit ({currency})</h6>
        </div>
      </div>
      {Object.keys(details?.[currency] || {}).map(key => (
        <div key={key} className="tier-products-wrapper">
          <div className="tier-product-list-wrapper tier-product-list-content tier-product-list-content-header">
            <div
              className=" tier-col tier-col1 tier-title-col"
              onClick={() => Object.keys(details?.[currency]?.[key]?.methods).length > 0 && onRowClick(key)}
              tabIndex={0}
              role="button"
              id="tier-button"
              onKeyDown={() => null}
            >
              <div className="tier-title">
                {Object.keys(details?.[currency]?.[key]?.methods).length > 0 && (
                  <span className={`icon-wrapper ${productDisplay === key ? 'isOpen' : ''}`}>
                    <Icon name="arrowDown" width={16} height={16} />
                  </span>
                )}
                <h6 className={Object.keys(details?.[currency]?.[key]?.methods).length === 0 ? 'inactive' : ''}>
                  {capitalizeRemovedash(key)}
                </h6>
              </div>
            </div>
            <div className="tier-col tier-col2">
              <p>{renderValue(details?.[currency]?.[key]?.transaction_limit)}</p>
            </div>
            <div className="tier-col tier-col3">
              <p>{renderValue(details?.[currency]?.[key]?.daily_limit)}</p>
            </div>
          </div>
          {productDisplay === key && (
            <div>
              {Object.keys(details?.[currency]?.[key]?.methods || {}).map(subKey => (
                <div key={subKey} className="tier-product-list-wrapper tier-product-list-content">
                  <div className="tier-col tier-col1">
                    <span className="status-pill smaller dark-grey" />
                    <p>{capitalizeRemovedash(subKey)}</p>
                  </div>
                  <div className="tier-col tier-col2">
                    <p>{renderValue(details?.[currency]?.[key]?.methods?.[subKey]?.transaction_limit)}</p>
                  </div>
                  <div className="tier-col tier-col3">
                    <p>{renderValue(details?.[currency]?.[key]?.methods?.[subKey]?.daily_limit)}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductFeatureDropdown;
