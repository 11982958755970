/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { Route, Switch } from 'react-router-dom';

import { usePermissions, useSearchQuery } from '+hooks';
import { ChargeBackServices } from '+services/chargeback-services';
import { RefundServices } from '+services/refund-services';
import useStore from '+store';
import {
  APIDownload,
  capitalizeFirst,
  capitalizeRemovedash,
  daysfromToday,
  filteredOutObjectProperty,
  formatAmount,
  getDate,
  getTime,
  history,
  logBreadCrumb,
  queriesParams,
  switchStatus
} from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import AdvanceExportModal from '../Shared/AdvanceExportModal';
import CreateRefundsModal from '../Shared/CreateRefundsModal';
import CurrencyPicker from '../Shared/CurrencyPicker';
import ExportFilterModal from '../Shared/ExportFilterModal';
import HeaderTabs from '../Shared/HeaderTabs';
import Table from '../Shared/Table';
import ChargebackDetails from './ChargebackDetails';
import DisputeFilter from './components/DisputeFilter';
import RefundDetails from './RefundDetails';

import './index.scss';

function DisputeComponent() {
  const searchQuery = useSearchQuery();
  const permissions = useStore(state => state.permissions);
  const { profile } = useStore();

  const tabs = ['refunds', 'chargebacks'];

  const activeCurrency = searchQuery.value.currency || 'NGN';
  const currentLimit = searchQuery.value.limit || '10';
  const paginationPage = searchQuery.value.page || '1';
  const selectedStatus = searchQuery.value.status || [];
  const activeTab = searchQuery.value.tab || 'refunds';
  const userAccess = usePermissions(activeTab === 'refunds' ? 'refund' : 'chargeback') || '';
  const sortingParams = {
    status: typeof selectedStatus === 'string' ? [selectedStatus] : selectedStatus,
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.currency,
      queriesParams.page,
      queriesParams.limit,
      queriesParams.status,
      queriesParams.tab
    ])
  };

  const [showLargeExportModal, setLargeExportModal] = useState(false);

  const buffer = [];
  const anyLoading = {};
  const refetchRefund = {};
  const getTabDetails = tab => buffer.find(data => data.type === tab);

  const [modalStates, setModalStates] = useState({
    refundsModal: false,
    exportModal: false
  });

  const [exportParams, setExportParams] = useState(null);
  const [exportFormat, setExportFormat] = useState('');

  const MERCHANT_ENV = useStore(store => store.merchantEnv);
  const defaultMerchant = useStore(store => store.defaultMerchant);
  const availableCurrencies = useStore(store => store.availableCurrencies);

  const switchAPI = {
    refunds: {
      service: RefundServices.useGetRefunds,
      params: { page: paginationPage, limit: currentLimit, ...sortingParams, currency: activeCurrency }
    },
    chargebacks: {
      service: ChargeBackServices.useGetChargeBacks,
      params: { page: paginationPage, limit: currentLimit, ...sortingParams, currency: activeCurrency }
    }
  };

  const switchExportAPI = {
    refunds: {
      service: RefundServices.useExportRefunds
    },
    chargebacks: {
      service: ChargeBackServices.useExportChargeBacks
    }
  };

  const onExportSettled = () => {
    setExportFormat('');
    setExportParams(null);
    setModalStates({ ...modalStates, exportModal: false });
  };

  const { isLoading } = switchExportAPI[activeTab].service({
    enabled: Boolean(exportParams),
    params: exportParams,
    errorMessage: `There has been an error exporting your ${activeTab}`,
    successMessage: `${capitalizeFirst(activeTab)} successfully downloaded`,
    onSuccess: res => {
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = exportFormat === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `${capitalizeFirst(activeTab)} at ${getDate(Date.now())}`, type);
      }
      onExportSettled();
    },
    onError: () => {
      onExportSettled();
    }
  });

  const getStatus = status =>
    ['processing', 'failed', 'pending', 'manual'].includes(status?.toLowerCase()) ? 'Processing' : capitalizeRemovedash(status);

  tabs.forEach(tab => {
    const {
      data: response,
      isFetching,
      refetch
    } = switchAPI[tab].service({
      enabled: activeTab === tab,
      errorMessage: `There has been an error getting your ${tab}`,
      params: switchAPI[tab].params
    });
    const data = response?.data;
    anyLoading[tab] = isFetching;
    refetchRefund[tab] = refetch;
    buffer.push({ type: tab, ...data });
  });

  // Fetch Chargeback summary
  const { data: summaryData } = ChargeBackServices.useGetChargeBackSummary({
    errorMessage: 'There has been an error getting chargeback summary'
  });

  const summary = summaryData?.data || {};

  const exportDisputes = async (format, fieldToExport) => {
    const parameterizeArray = (key, arr) => {
      arr = arr.map(encodeURIComponent);
      return arr.join(`&${key}[]=`);
    };
    const fields = parameterizeArray('fieldsToExport', fieldToExport);
    setExportFormat(format);
    setExportParams({ sortingParams, format, currency: activeCurrency, fieldsToExport: fields });
  };

  const tableOfDisputes = () => {
    const activeList = getTabDetails(activeTab);
    return activeList?.data?.map(each => {
      return (
        <div
          key={`refunds_${each?.reference}`}
          className={`div-table --row ${(activeTab === 'refunds' && '--refunds-table ') || '--chargebacks-table'}`}
          onClick={() => {
            history.push(`/dashboard/disputes/${activeTab}/${each?.reference}`);
            logBreadCrumb({
              event: breadCrumbEvents.disputes.tableClicked(activeTab),
              data: { tab: activeTab, reference: each?.reference }
            });
          }}
          onKeyDown={() => {
            history.push(`/dashboard/disputes/${activeTab}/${each?.reference}`);
            logBreadCrumb({
              event: breadCrumbEvents.disputes.tableClicked(activeTab),
              data: { tab: activeTab, reference: each?.reference }
            });
          }}
          role="button"
          tabIndex={0}
        >
          {activeTab === 'refunds' ? (
            <>
              <div>
                <span className="body-row-header">Status:</span>
                <span className={`status-pill smaller ${switchStatus(getStatus(each?.status || '').toLowerCase())}`} />
                <span>{getStatus(each?.status || 'Not available')}</span>
              </div>
              <div>
                <span className="body-row-header">Refund ID</span>
                <span className="trxn-id" style={{ fontWeight: '600', textTransform: 'uppercase' }}>
                  {each.merchant_reference || each.reference.toUpperCase()}
                </span>
              </div>

              <div>
                <span className="body-row-header">Channel:</span>
                {each.channel ? (
                  capitalizeRemovedash(each.channel)
                ) : (
                  <span style={{ color: 'rgba(90, 99, 126, 0.49)' }}>Not available</span>
                )}
              </div>

              <div>
                <span className="body-row-header">Customer Name:</span>
                <span>{each.payment?.customer?.name}</span>
              </div>

              <div>
                <span className="body-row-header">Date Created:</span>
                <span>{getDate(each.reversal_date)}</span>
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(each.reversal_date)}
                </span>
              </div>

              <div>
                <span className="body-row-header">Refund Amount:</span>
                <span style={{ fontWeight: '500' }}>
                  <strong>{formatAmount(each.refund_amount)}</strong>
                </span>
              </div>
            </>
          ) : (
            <>
              <div>
                <span className="body-row-header">Status:</span>
                <span className={`status-pill smaller ${switchStatus(each.status)}`} />
                <span>{capitalizeRemovedash(each.status === 'auto_accepted' ? 'Accepted (Auto)' : each.status)}</span>
              </div>

              <div>
                <span className="body-row-header">Chargeback ID:</span>
                <span className="trxn-id" style={{ fontWeight: '600', textTransform: 'uppercase' }}>
                  {each.reference.toUpperCase()}
                </span>
              </div>

              <div>
                <span className="body-row-header">Customer Name:</span>
                <span>{each.customer_name}</span>
              </div>

              <div>
                <span className="body-row-header">Date Contested:</span>
                <span>{getDate(each.created_at)}</span>
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(each.created_at)}
                </span>
              </div>

              <div>
                <span className="body-row-header">Due In:</span>
                <span>{each.deadline ? capitalizeFirst(daysfromToday(each.deadline)) : 'N/A'}</span>
              </div>

              <div>
                <span className="body-row-header">Chargeback Amount:</span>
                <span style={{ fontWeight: '500' }}>
                  <strong>{formatAmount(each.amount)}</strong>
                </span>
              </div>
            </>
          )}
        </div>
      );
    });
  };

  const getTableHeaders = () => {
    if (activeTab === 'refunds') {
      return [
        {
          value: 'Status'
        },
        {
          value: 'Refund ID'
        },
        {
          value: 'Channel'
        },
        {
          value: 'Customer Name'
        },
        {
          value: 'Date Created'
        },
        {
          value: `Refund Amount (${activeCurrency})`
        }
      ];
    }
    return [
      {
        value: 'Status'
      },
      {
        value: 'Chargeback ID'
      },
      {
        value: 'Customer Name'
      },
      {
        value: 'Date Contested'
      },
      {
        value: 'Due In'
      },
      {
        value: `Chargeback Amount (${activeCurrency})`
      }
    ];
  };

  const setSearchQuery = (value: string) => {
    searchQuery.setQuery({ tab: value, currency: activeCurrency }, true);
    logBreadCrumb({ event: breadCrumbEvents.disputes.tabClicked(value) });
  };

  return (
    <>
      <ExportFilterModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />
      <section className="os-tabs-w">
        <div className="os-tabs-controls os-tabs-complex settlement-history__tabs">
          <HeaderTabs tabs={tabs} activeTab={activeTab} onClick={setSearchQuery} />
          <CurrencyPicker
            options={availableCurrencies || ['NGN']}
            onChange={value => {
              searchQuery.setQuery({ currency: value, page: '1' });
            }}
            className="settlement-history__currency-switch"
            activeCurrency={activeCurrency}
            id="settlements-history__currency-switch"
          />
        </div>
      </section>

      <section className="history_summary_details">
        <div className="row">
          <div className="col-lg-9 col-md-8 dispute-summary">
            {activeTab === 'refunds' && (
              <div className="history_summary_heading">
                <div className="refund-tab-details">
                  <p>Refund history</p>
                  <p>
                    Monitor and keep track of all the refund requests that are made on your transactions where you have received payment
                    from customers. It’s also easy to process refunds on your pay-ins; click the ‘Refund’ button to get started.
                  </p>
                </div>
              </div>
            )}
            {activeTab === 'chargebacks' && (
              <div className="history_summary_heading">
                <div className="chargeback-tab-details">
                  <p>Action Required</p>
                  <p>{summary?.pending_chargebacks || 0}</p>
                  <p>Chargebacks awaiting response</p>
                </div>
              </div>
            )}
          </div>
          {activeTab === 'refunds' && (
            <div className="col-lg-3 col-md-4 dispute_summary_heading">
              <div className=" __buttons dispute-button">
                {permissions?.refund === 'manage' && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setModalStates({ ...modalStates, refundsModal: true });
                      logBreadCrumb({ event: breadCrumbEvents.disputes.createRefundButton });
                    }}
                  >
                    <i className="os-icon os-icon-plus" />
                    <span>Create Refund</span>
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>

      <DisputeFilter
        openExportModal={() => setModalStates({ ...modalStates, exportModal: true })}
        userAccess={userAccess}
        totalCount={getTabDetails(activeTab)?.paging?.total_items}
      />

      <section className="element-box-tp mt-5">
        <div className="nav-content active">
          <Table
            tableClassName={`${(activeTab === 'refunds' && '--refunds-table ') || '--chargebacks-table'}`}
            headings={getTableHeaders()}
            hasPagination={!anyLoading[activeTab]}
            loading={anyLoading[activeTab]}
            current={parseInt(paginationPage, 10)}
            totalItems={getTabDetails(activeTab)?.paging?.total_items || 0}
            pageSize={getTabDetails(activeTab)?.paging?.page_size || 0}
            actionFn={current => searchQuery.setQuery({ page: String(current) })}
            limitAction={limit => searchQuery.setQuery({ limit: String(limit) })}
            annotation={activeTab === 'refunds' ? 'refunds' : 'chargebacks'}
            emptyStateHeading={`No ${activeTab} yet.`}
            emptyStateMessage={
              <span
                role="button"
                tabIndex={0}
                onClick={() => setModalStates({ ...modalStates, refundsModal: true })}
                onKeyDown={() => setModalStates({ ...modalStates, refundsModal: true })}
              >
                Requested refunds will show up here.
              </span>
            }
          >
            {tableOfDisputes()}
          </Table>
        </div>
        <CreateRefundsModal
          visible={modalStates.refundsModal}
          refetchRefund={refetchRefund[activeTab]}
          currency={activeCurrency}
          close={() => setModalStates({ ...modalStates, refundsModal: false })}
        />
        {modalStates.exportModal && (
          <AdvanceExportModal
            openExport={modalStates.exportModal}
            setOpenExport={() => setModalStates({ ...modalStates, exportModal: false })}
            exportAction={exportDisputes}
            type={activeTab}
            showSuccessModal={false}
            isLoading={isLoading}
          />
        )}
      </section>
    </>
  );
}

export default function DisputeHistory() {
  return (
    <Switch>
      <Route exact path="/dashboard/disputes" component={DisputeComponent} />
      <Route path="/dashboard/disputes/refunds/:id">
        <RefundDetails />
      </Route>
      <Route path="/dashboard/disputes/chargebacks/:id">
        <ChargebackDetails />
      </Route>
    </Switch>
  );
}
