/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';

import { createApiClient } from '+services/api-setup';
import { ISendResponse } from '+types/api-services-types';
import { logError } from '+utils';

import useFeedbackHandler from './feedbackHandler';

const keyContainer: Record<string, string[]> = JSON.parse(localStorage.getItem('keyContainers') ?? '{}');

const manageKey = {
  set: (value: string[]) => {
    const key = value[0];
    if (!keyContainer[key]) {
      keyContainer[key] = value;
      localStorage.setItem('keyContainers', JSON.stringify(keyContainer));
    }
  },
  get: (key: keyof typeof keyContainer): string[] => {
    if (!key) return [];
    if (!keyContainer[key]) {
      const keys = Object.keys(keyContainer)
        .filter(k => k.includes(key))
        .map(k => keyContainer[k]);
      return keys[0];
    }

    return keyContainer[key];
  }
};

interface IUseSend<R> {
  /** This is the API path without the baseURL. It is required. */
  url: string;
  /** The intended API method. It is required. */
  method: Extract<Method, 'post' | 'put' | 'delete' | 'get' | 'patch'>;
  /** The flag to indicate the endpoint needs a token. It is optional. */
  useAuth?: boolean;
  /** The flag to indicate if the success message should be shown. It is optional. */
  showSuccessMessage?: boolean;
  /** The flag to indicate if the error message should be shown. It is optional. */
  showErrorMessage?: boolean;
  /** The success message to be shown. It is optional */
  successMessage?: string;
  /** The error message to be shown. It is optional. */
  errorMessage?: string;
  /** The callback function to be executed when the request is successful. It is optional. */
  onSuccess?: (data: ISendResponse<R>) => void;
  /** The callback function to be executed when the request is unsuccessful. It is optional. */
  onError?: (error: Error) => void;
  /** The success message banner placement, it defaults to `viewport`. It is optional. */
  successMessageBannerLevel?: boolean;
  /** The error message banner placement, it defaults to `viewport`. It is optional. */
  errorMessageBannerLevel?: boolean;
  /** It reinvalidates query related to the endpoint path, it defaults to false. It is optional. */
  reInvalidate?: boolean;
  /** The custom base URL for the API. It is optional. */
  customBaseURL?: string;
  /** The flag to indicate if the environment handler should be used. It is optional. */
  useEnvironmentHandler?: boolean | string;
  /** The flag to indicate if the feedback timeout should be handled. It is optional. */
  hasFeedbackTimeout?: boolean;
  /** The query key for the request. It is optional. */
  reInvalidationKey?: QueryKey;
}

/**
 * @description Custom hook for making POST, PATCH, DELETE, GET request.
 * @param  url - API path without the baseURL.
 * @param  method - API method.
 * @param  useAuth - The flag to indicate the endpoint needs a token. It is optional.
 * @param  showSuccessMessage - The flag to indicate if the success message should be shown. It is optional.
 * @param  showErrorMessage - The flag to indicate if the error message should be shown. It is optional.
 * @param  successMessage - The success message to be shown. It is optional.
 * @param  errorMessage - The error message to be shown. It is optional.
 * @param  onSuccess - The callback function to be executed when the request is successful. It is optional.
 * @param  onError - The callback function to be executed when the request is unsuccessful. It is optional.
 * @param  successMessageBannerLevel - The success message banner placement, it defaults to `viewport`. It is optional.
 * @param  errorMessageBannerLevel - The error message banner placement, it defaults to `viewport`. It is optional.
 * @param  reInvalidate - It reinvalidates query related to the endpoint path, it defaults to false. It is optional.
 * @param  customBaseURL - The custom base URL for the API. It is optional.
 * @returns void
 * @example const result = useSend({url, method, useAuth, showSuccessMessage, showErrorMessage, successMessage, errorMessage, onSuccess, onError, bannerLevel});
 * the result of the request: result.data;
 * the status of the request: result.status;
 * the message of the request: result.message;
 * the paging of the request: result.paging;
 * the result also expose all the properties of the useQuery hook.
 */

export const useSend = <T, R>({
  url,
  method,
  useAuth,
  showSuccessMessage = true,
  showErrorMessage = true,
  successMessage,
  errorMessage,
  onSuccess,
  onError,
  successMessageBannerLevel,
  errorMessageBannerLevel,
  reInvalidate = false,
  customBaseURL,
  useEnvironmentHandler,
  hasFeedbackTimeout,
  reInvalidationKey
}: IUseSend<R>) => {
  const queryClient = useQueryClient();
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const formattedData = <TData>(data: AxiosResponse<ISendResponse<TData>>): ISendResponse<TData> => {
    return data?.data;
  };
  const key = [...url.split('/')];
  const queryKey = useMemo(() => manageKey.get(key[0]), [key]);
  const { client, setBaseURL } = createApiClient(useAuth, useEnvironmentHandler);
  if (customBaseURL) setBaseURL(customBaseURL);
  const mutation = useMutation({
    mutationFn: async (data: T) => {
      const result = await client[method]<R>(url, data as T & AxiosRequestConfig<R>);
      return formattedData<R>(result as AxiosResponse<ISendResponse<R>>);
    },
    onSuccess: data => {
      if (showSuccessMessage) {
        feedbackInit({
          type: 'success',
          message: successMessage ?? data.message,
          componentLevel: successMessageBannerLevel
        });
      }
      if (onSuccess) onSuccess(data);
      if (reInvalidate) queryClient.invalidateQueries({ queryKey: reInvalidationKey ?? queryKey });

      return data;
    },
    onError: err => {
      const error = err as AxiosError<{ message: string }>;
      logError(error?.response?.data?.message);
      if (onError) onError(err);
      if (!showErrorMessage) return;
      feedbackInit({
        type: 'danger',
        message: error?.response?.data?.message ?? errorMessage,
        componentLevel: errorMessageBannerLevel
      });
    },
    onSettled: () => {
      if (hasFeedbackTimeout && (showSuccessMessage || showErrorMessage)) {
        setTimeout(() => {
          closeFeedback();
        }, 5000);
      }
    }
  });

  return { ...mutation, isLoading: mutation.isPending };
};
