import { useState } from 'react';

import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import Icon from '+containers/Dashboard/Shared/Icons';
import { AccessStatusType, CardAccessRequestStageType, CardCategoryType } from '+types';

import { getIssuingPermissions } from '../utils';
import AccessRequestModal from './AccessRequestModal';

interface ISingleCardAccessRequest {
  activeTab: CardCategoryType;
  accessStatus: AccessStatusType;
}

const AccessRequestPostOnboarding = ({ accessStatus, activeTab }: ISingleCardAccessRequest) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [stage, setStage] =
    useState<Extract<CardAccessRequestStageType, 'set_customer_vc_preferences' | 'set_reserved_vc_preferences'>>(
      'set_reserved_vc_preferences'
    );
  const { canRequestCardAccess } = getIssuingPermissions();

  const handleClick = () => {
    if (activeTab === 'reserved_cards') setStage('set_reserved_vc_preferences');
    if (activeTab === 'customer_cards') setStage('set_customer_vc_preferences');
    setModalIsOpen(true);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center flex-column reset-margin fade-in" style={{ height: '60dvh' }}>
        <div>
          <EmptyStateComponent
            heading={`No ${activeTab === 'customer_cards' ? 'customer' : 'reserved'} cards yet`}
            message={`${activeTab === 'customer_cards' ? 'Create, issue, and manage personalized cards for your customers, offering them a seamless online payment experience.' : 'Set up your access to issue virtual cards for your business and team. Easily create and manage them directly from your dashboard.'}`}
          />
          {accessStatus === 'inactive' && canRequestCardAccess && (
            <button type="button" className="bt btn-primary flex mx-auto mt-4 request-post-btn" onClick={handleClick}>
              <Icon name="send" width={16} height={16} stroke="white" />
              <span className="ml-2">{activeTab === 'customer_cards' ? 'Request Access' : 'Set up access'}</span>
            </button>
          )}

          {accessStatus === 'pending' && (
            <div className="d-flex flex-column align-items-center">
              <div className="mt-4 text-center request-post-onboarding">
                <span className="mt-1">Your request is being reviewed. We will contact you shortly.</span>
              </div>
            </div>
          )}
        </div>
      </div>

      {modalIsOpen && <AccessRequestModal onClose={() => setModalIsOpen(false)} gotoStage={stage} />}
    </>
  );
};

export default AccessRequestPostOnboarding;
