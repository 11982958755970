import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Modal from '+dashboard/Shared/Modal';
import { useFeedbackHandler } from '+hooks';
import { BalanceServices } from '+services/balance-services';
import { TransactionServices } from '+services/transaction-services';
import useStore from '+store';
import { IBulkPayoutStartModalProps, PayOutMappingTypes } from '+types';
import { cleanInput, formatAmount } from '+utils';

import { payoutMethodMapping } from '../../TransactionDetails/data';

const validationSchema = Yup.object({
  otp: Yup.string().required('Required').min(6, 'OTP must be at least 6 characters').max(8, 'OTP must be at most 8 characters')
});

const BulkPayoutStartModal = ({
  closeModal,
  actionBtn,
  bulkPayoutSummary,
  currency = 'NGN',
  payoutCount,
  totpIdentifier,
  twoFactorType,
  reference
}: IBulkPayoutStartModalProps) => {
  const defaultMerchant = useStore((store: any) => store.defaultMerchant);
  const MERCHANT_ENV = useStore(state => state.merchantEnv);
  const [balances, setBalances] = useState({});
  const { feedbackInit } = useFeedbackHandler();
  const [identifier, setIdentifier] = useState('');

  const { refetch: refetchBalance } = BalanceServices.useGetBalances({
    onSuccess: data => setBalances(data?.data || {}),
    showErrorMessage: true,
    onError: e => {
      const error = e.response?.data;
      const notLive = error?.message?.includes('not been approved');
      const message = notLive ? 'Your account has not been approved yet' : 'There has been an error in getting your balances';
      feedbackInit({
        message,
        type: 'danger',
        action: {
          action: () => (notLive ? history.push('/dashboard/settings/business') : refetchBalance()),
          name: notLive ? 'Complete account setup' : 'Try again'
        }
      });
    }
  });

  const initiateBulkPayout = TransactionServices.useInitiateBulkPayout({
    queryData: {
      bulkReference: reference,
      data: { two_factor_type: twoFactorType }
    },
    onSuccess: data => {
      const {
        authData: { identifier }
      } = data?.data ?? {};

      if (identifier) {
        setIdentifier(identifier);
      }
    }
  });

  useEffect(() => {
    if (twoFactorType) {
      initiateBulkPayout.mutate({ two_factor_type: twoFactorType });
    }
  }, [twoFactorType]);

  const startBulkPayout = TransactionServices.useStartBulkPayout({
    bannerLevel: true,
    bulkReference: reference,
    auth_data: {
      code: twoFactorType,
      two_factor_type: twoFactorType,
      identifier: identifier
    },
    onSuccess: () => {
      actionBtn();
    }
  });

  const close = () => {
    closeModal(false);
  };

  const formik = useFormik({
    initialValues: {
      otp: ''
    },
    validationSchema,
    onSubmit: () => {}
  });

  const { setFieldValue, isValid, values, dirty } = formik;
  const canContinue = dirty && isValid;

  const modalContent = () => {
    let email = defaultMerchant?.userEmail;
    if (email?.includes('@')) {
      email = email.split('@');
      email = `${email[0].slice(0, 3)}*******@${email[1]}`;
    }
    const payoutMethod: PayOutMappingTypes = (bulkPayoutSummary?.type as PayOutMappingTypes) || 'bank_account';
    return (
      <div className="bulk-payout-otp-form" data-testid="bulk-payout-start-modal">
        <div className="bulk-payout-otp-form-summary">
          <h3>Some important details of your bulk payout:</h3>
          <div className="form-summary-item">
            <span>Total Amount</span>
            <span>
              {' '}
              {formatAmount(bulkPayoutSummary?.total_bulk_amount)} {currency}
            </span>
          </div>
          <div className="form-summary-item">
            <span>Payout Count</span>
            <span> {payoutCount}</span>
          </div>
          <div className="form-summary-item">
            <span>Type</span>
            <span> {payoutMethodMapping?.[payoutMethod]}</span>
          </div>
          <div className="form-summary-item">
            <span>Current Balance</span>
            <span>
              {' '}
              {formatAmount(balances[currency]?.available_balance)} {currency}
            </span>
          </div>
        </div>
        <p>
          To proceed, enter the authorization code or OTP (one-time PIN) that was sent to your email <b>({email})</b> or from your
          authenticator app.
        </p>
        <div className="otp-form-input-wrapper">
          <label htmlFor="otp">Authorization Code / OTP</label>
          <input
            data-testid="otp-input"
            type="text"
            name="otp"
            id="otp"
            className="form-control"
            placeholder="Enter Authorization Code / OTP"
            value={values.otp}
            onChange={e => setFieldValue('otp', cleanInput(e.target.value))}
            maxLength={8}
          />
        </div>
      </div>
    );
  };
  return (
    <Modal
      size="md"
      close={close}
      heading="Confirm bulk payout"
      description={
        <p style={{ color: '#414F5F', fontWeight: 400, display: 'block' }}>
          Please confirm that you want to proceed with this bulk payout. Once started, the bulk payout would not be stopped until all the
          transactions have been processed.
        </p>
      }
      content={modalContent()}
      justify="space-between"
      firstButtonText="Back"
      firstButtonAction={close}
      secondButtonText="Start"
      secondButtonAction={async () => {
        const { otp } = values;
        const data = {
          auth_data: { code: otp, two_factor_type: twoFactorType, identifier: twoFactorType === 'totp' ? totpIdentifier : identifier }
        };
        await startBulkPayout.mutateAsync(data);
        actionBtn();
      }}
      completedHeading="Success"
      completedDescription="Bulk Payout Started Successfullly"
      secondButtonColor="#24B314"
      secondButtonDisable={!canContinue}
    />
  );
};

export default BulkPayoutStartModal;
