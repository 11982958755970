/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useLayoutEffect } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { datadogRum } from '@datadog/browser-rum';

import AuthComponent from '+auth';
import ScrollToTop from '+containers/Shared/ScrollToTop';
import DashboardComponent from '+dashboard';
import useStore from '+store';
import { helmetApp, history, isRobot, actions as mixPanelAction, queryClient } from '+utils';

import ErrorPage from './containers/Shared/ErrorPage';

function initializeReactGA() {
  const trackingCode = process.env.REACT_APP_PEGASUS_WINGS;

  if (trackingCode) {
    ReactGA.initialize(trackingCode);
    ReactGA.pageview('/MainPage');
    ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
  }
}
initializeReactGA();

history.listen((location: { pathname: any }) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
});

const App = () => {
  const { authDetails } = useStore(state => state) as unknown as {
    authDetails: { account: { id: string; firstname: string; lastname: string; email: string } };
  };

  const actions = mixPanelAction as unknown as {
    track: (arg0: string) => void;
  };
  useLayoutEffect(() => {
    const bypassURLs = process.env.REACT_APP_BYPASS_ROBOT?.split(',');
    if (bypassURLs?.includes(window.location.host)) return;
    if (isRobot()) {
      window.document.write('');
      window.location.replace('about:blank');
    }
  }, []);

  useEffect(() => {
    initializeReactGA();
    if ((window as Record<string, any>).visitedKorapay === 'visited') {
      delete (window as Record<string, any>).visitedKorapay;
      actions.track('Session ended');
    }

    const location = window.location.pathname.split('/')[1];
    actions.track('Session started');
    actions.track(`User entered from ${location}`);
    (window as Record<string, any>).visitedKorapay = 'visited';
  }, []);

  const bootProps = {
    alignment: 'right',
    languageOverride: 'en'
  };

  useEffect(() => {
    if (authDetails?.account && process.env.REACT_APP_DARKHORSE_INIT === 'true') {
      const user = {
        id: authDetails?.account?.id,
        name: `${authDetails?.account?.firstname} ${authDetails?.account?.lastname}`,
        email: authDetails?.account?.email
      };

      datadogRum.setUser(user);
    }
  }, [authDetails?.account]);

  return (
    <QueryClientProvider client={queryClient}>
      <IntercomProvider appId={process.env.REACT_APP_ATHENA_PLATE ?? ''} autoBoot autoBootProps={bootProps}>
        <Router history={history}>
          <Helmet {...helmetApp.head} />
          <ScrollToTop />
          <Switch>
            <Route path="/auth" component={AuthComponent} />
            <Route path="/dashboard" component={DashboardComponent} />
            <Redirect exact path="/" to="/auth" />
            <Route path="*">
              <ErrorPage context="404" clearError={() => {}} />
            </Route>
          </Switch>
        </Router>
      </IntercomProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default React.memo(App);
