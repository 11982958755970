import React, { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useDebouncedCallback } from 'use-debounce';

import CurrencyConversionForm from '+containers/Dashboard/Shared/CurrencyConversionForm';
import Modal from '+containers/Dashboard/Shared/Modal';
import { ClockWhiteFill } from '+containers/Shared/Icons';
import { useConversionLogics, useFeedbackHandler, useProcessConversion } from '+hooks';
import { SwapWalletConversionService } from '+services/swapwallet-conversion-services';
import { IFundByWalletModalProps, ListDropdownProps, WalletModal } from '+types';
import { backwardAmountInput, cleanInput, formatAmount } from '+utils';

import { useCountdown } from './Countdown';

import './index.scss';

const ConversionsModal: React.FC<IFundByWalletModalProps & WalletModal & ListDropdownProps> = ({ close, currency }) => {
  const { closeFeedback } = useFeedbackHandler();
  const {
    state,
    setState,
    rate,
    toAmount,
    availableBalance,
    minConversionLimit,
    maxConversionLimit,
    errorMessage,
    validateAmount,
    queryData,
    conversionsQueryData,
    currencyArray,
    sourceCurrency,
    destinationCurrency
  } = useConversionLogics();

  const { amount, data, payloadCurrency } = state;

  useEffect(() => {
    if (currency) {
      setState({ payloadCurrency: { ...state.payloadCurrency, from: currency, to: currency === 'USD' ? currencyArray[0] : 'USD' } });
    }
  }, [currency]);

  const handleCountdownCompletion = async () => {
    setState({ countdownCompleted: true, isLoading: true });
    await initiateConversion.mutateAsync(queryData);
    setState({ countdownCompleted: false });
  };

  const initiateConversion = SwapWalletConversionService.useInitiateConversion({
    errorMessage: 'There has been an error getting swap information',
    showSuccessMessage: false,
    bannerLevel: true,
    onSuccess: data => {
      const response = data?.data;
      setState({ exchange: response || {}, data: response || null });
      setState({ countdownCompleted: false, isLoading: false });
    },
    onError: (error: unknown) => {
      const axiosError = error as AxiosError<{ message: string }>;
      if (axiosError?.response?.data?.message?.includes('Too many requests') && !checkLimitAndValidate) {
        closeFeedback(9000);
      }
    }
  });

  const { processConversionMutation } = useProcessConversion(conversionsQueryData);

  const validate = () => {
    if (data !== null && amount !== '' && !initiateConversion.isLoading) {
      const parsedAmount = parseFloat(amount);
      const maxLimit = maxConversionLimit !== undefined ? Math.min(maxConversionLimit, availableBalance) : availableBalance;

      return parsedAmount >= minConversionLimit && parsedAmount <= maxLimit;
    }

    return false;
  };

  const checkLimitAndValidate = validate();

  const { currentCount, resetCountdown } = useCountdown({
    countFrom: state.countdown,
    isStartCount: state.startCount,
    isRestartCountdown: true,
    callbackFn: handleCountdownCompletion as () => void
  });

  useEffect(() => {
    if (checkLimitAndValidate) {
      setState({ startCount: true });
    } else {
      setState({ startCount: false });
      resetCountdown();
    }
  }, [checkLimitAndValidate]);

  const debouncedFetchData = useDebouncedCallback(async () => {
    if (validateAmount()) {
      const result = await initiateConversion.mutateAsync(queryData);
      setState({ apiData: result?.data });
    }
  }, 1000);

  const handleSelectChange = async (e: React.ChangeEvent<HTMLSelectElement>, name: string) => {
    const selectedCurrency = e.target.value;
    await updateCurrency(name, selectedCurrency);
  };

  const updateCurrency = async (name: string, selectedCurrency: string) => {
    if (name === 'currencyFrom') {
      setState({
        payloadCurrency: {
          ...state.payloadCurrency,
          from: selectedCurrency
        }
      });
    } else if (name === 'currencyTo') {
      setState({
        payloadCurrency: {
          ...state.payloadCurrency,
          to: selectedCurrency
        }
      });
    }
    await debouncedFetchData();
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/,/g, '');
    const formattedAmount = backwardAmountInput(cleanInput(inputValue));
    if (inputValue === '') {
      setState({ apiData: null, amount: inputValue });
      return;
    }
    setState({ amount: formattedAmount as typeof amount });
    await debouncedFetchData();
    if (inputValue < amount || inputValue === '') {
      setState({ data: null });
    }
  };

  const handleSwitchCurrency = async () => {
    const { from, to } = state.payloadCurrency;
    setState({
      payloadCurrency: {
        from: to,
        to: from
      }
    });

    await debouncedFetchData();
  };

  const switchWalletModal = () => {
    const content = {
      modalSmClassName: '.modal-content-sm',
      heading: 'Quick Currency Conversion',
      description: 'Easy and instant conversions between currencies. Note that exchange rates are dynamic and may change over time.',
      content: (
        <CurrencyConversionForm
          formType="modal"
          payloadCurrency={payloadCurrency}
          currencyArray={currencyArray}
          handleSelectChange={handleSelectChange as (e: React.ChangeEvent<HTMLSelectElement>, name: string) => void}
          updateCurrency={updateCurrency as (name: string, selectedCurrency: string) => void}
          handleSwitchCurrency={handleSwitchCurrency as () => void}
          state={state}
          availableBalance={availableBalance}
          errorMessage={errorMessage}
          checkLimitAndValidate={checkLimitAndValidate}
          formatAmount={formatAmount}
          rate={rate}
          toAmount={toAmount}
          isLoading={initiateConversion.isLoading}
          amount={amount}
          handleInputChange={handleInputChange as (e: React.ChangeEvent<HTMLInputElement>) => void}
          cleanInput={cleanInput}
          currentCount={currentCount}
          initiateConversion={initiateConversion}
          sourceCurrency={sourceCurrency}
          destinationCurrency={destinationCurrency}
        />
      ),
      firstButtonText: 'Cancel',
      secondButtonText: (
        <>
          Confirm Conversion <ClockWhiteFill className="clock ml-1 mr-1" width={15} />
          {currentCount}s
        </>
      ),
      secondButtonActionIsTerminal: true,
      secondButtonDisable: !checkLimitAndValidate,
      firstButtonAction: close,
      secondButtonAction: async () => {
        setState({ amount: '' });
        await processConversionMutation();
      },
      completedHeading: 'Conversion Successful',
      completedDescription: 'You have successfully completed this  conversion.',
      completedActionText: 'Dismiss',
      completedAction: close
    };

    return {
      size: 'md',
      close,
      ...content
    };
  };

  return <Modal {...switchWalletModal()} footerButtonClassName="conversion-modal-footer" />;
};

export default ConversionsModal;
