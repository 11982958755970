import { useState } from 'react';

import Table from '+containers/Dashboard/Shared/Table';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '+containers/Shared/Tabs';
import { useSearchQuery } from '+hooks';
import { AllChargebacksResponseType, CommonSearchQueriesType, DisputesSubtabKeyType, TableResponseType } from '+types';

import Filter from '../components/Filter/Filter';
import Summary from '../components/Summary';
import { chargebacksTableProps, computeTableTitle, exportActionOptions, getChargebacksFilterProps } from '../constants';
import { useFetchIssuanceTables, useFetchIssuingExport } from '../hooks';
import { getIssuingPermissions } from '../utils';
import ChargebacksRow from './components/ChargebacksRow';

const Disputes = () => {
  const [recordIsFiltered, setRecordIsFiltered] = useState(false);
  const { value: searchQueryValue, setQuery } = useSearchQuery<
    {
      subtab: DisputesSubtabKeyType;
    } & CommonSearchQueriesType
  >();
  const { canViewChargebacks } = getIssuingPermissions();
  const limit = searchQueryValue?.limit || '10';
  const page = searchQueryValue?.limit || '1';
  const subtab = searchQueryValue?.subtab ?? 'chargebacks';
  const exportAction = useFetchIssuingExport({ resourceType: `All-${subtab}`, exportFn: exportActionOptions[subtab] });
  const filterProps = getChargebacksFilterProps();

  const {
    tableData,
    isFetching: isFetchingTableData,
    refetch: refetchTableData
  } = useFetchIssuanceTables({
    page,
    limit,
    tab: subtab,
    queryValue: searchQueryValue
  });

  const getPendingChargebacks = () => {
    const pendingStatuses = ['pending', 'pending_pre_arbitration'];
    const isChargebackPending = (item: { status: (typeof pendingStatuses)[number] }) => pendingStatuses.includes(item.status);
    return (tableData as TableResponseType<AllChargebacksResponseType>)?.data?.data.filter(isChargebackPending).length;
  };

  const paging = tableData?.data?.paging;

  return (
    <div>
      <div className="issuing-heading">
        <div className="flex-grow-1 info-summary-container">
          <Summary
            label="Escalated Chargeback"
            value={getPendingChargebacks() as number}
            valueCustomStyles="alert-text"
            description="Chargebacks that require attention"
          />
        </div>
      </div>

      <Tabs defaultValue="chargebacks" onChange={subtabValue => setQuery({ subtab: subtabValue })}>
        <div>
          <TabList className="mt-4">
            <Tab value="chargebacks">Chargebacks</Tab>
          </TabList>
          <div className="divide-y" />
        </div>

        <TabPanels>
          <TabPanel value="chargebacks" className="transaction_table_comp table-container">
            <Filter
              totalItems={paging?.total_items as number}
              title={computeTableTitle({
                filtered: recordIsFiltered,
                activeTab: subtab as string,
                pageItemCount: paging?.total_items as number
              })}
              actions={filterProps.actions}
              filterModalHeading={filterProps.filterModalHeading}
              filterModalDescription={filterProps.filterModalDescription}
              filterFields={filterProps.filterFields}
              exportType={filterProps.exportType}
              quickFilterType={subtab}
              exportHeading={filterProps.exportHeading}
              exportDescription={filterProps.exportHeading}
              onChangeIsFiltered={setRecordIsFiltered}
              isFiltered={recordIsFiltered}
              exportAction={exportAction}
            />

            <Table
              tableClassName={`--history-table ${chargebacksTableProps.tableClassName}`}
              headings={chargebacksTableProps.headings}
              hasPagination
              borderedTable
              loading={isFetchingTableData}
              current={paging?.current}
              limitAction={value => setQuery({ limit: String(value) })}
              pageSize={paging?.page_size}
              actionFn={value => setQuery({ page: String(value) })}
              totalItems={paging?.total_items || 0}
              emptyStateHeading={chargebacksTableProps.emptyStateHeading}
              emptyStateMessage={
                <>
                  <span>{chargebacksTableProps.emptyStateMessage}</span>
                  {canViewChargebacks ? (
                    <button type="button" className="refetch-button" onClick={() => refetchTableData()}>
                      <i className="os-icon os-icon-rotate-ccw mr-1" />
                      Refresh
                    </button>
                  ) : null}
                </>
              }
            >
              <ChargebacksRow rowData={tableData?.data?.data as Array<AllChargebacksResponseType>} />
            </Table>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};
export default Disputes;
