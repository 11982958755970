import { ReactNode, useEffect, useState } from 'react';

import useStore from '+store';
import { history } from '+utils';

import SettingsTitle from '../containers/Dashboard/Settings/components/SettingsTitle';

type ComponentConfig = { title: string; path: string; text: string };
type PermissionLevel = 'view' | 'manage' | 'export' | undefined;

interface MatchRoutesToComponents {
  [key: string]: ComponentConfig;
}

interface PermissionsAccess {
  [key: string]: PermissionLevel;
}
export default function useRolesNav(matchRoutesToComponents: MatchRoutesToComponents) {
  const { authDetails } = useStore();
  const userToken = authDetails?.access_token;

  const [permissions, setPermissions] = useState<string[]>([]);
  const [permissionsAccess, setPermissionsAccess] = useState<PermissionsAccess>({});

  useEffect(() => {
    if (userToken) {
      const jwt = JSON.parse(atob(userToken.split('.')[1]));
      const updatedPermissions = { ...(jwt?.permissions?.permissions || {}), limits: 'manage', referrals: 'view' };

      setPermissionsAccess(updatedPermissions);
      setPermissions(Object.keys(updatedPermissions));
    }
  }, [userToken]);

  const createRoutes = () => {
    const options: ReactNode[] = [];
    permissions.forEach(permission => {
      if (matchRoutesToComponents[permission] !== undefined) {
        if (
          permissionsAccess[permission] === 'view' ||
          permissionsAccess[permission] === 'manage' ||
          permissionsAccess[permission] === 'export'
        ) {
          options.push(
            <div key={matchRoutesToComponents[permission]?.title}>
              <SettingsTitle
                title={matchRoutesToComponents[permission]?.title}
                text={matchRoutesToComponents[permission]?.text}
                click={() => {
                  history.push(matchRoutesToComponents[permission]?.path, null);
                }}
              />
            </div>
          );
        }
      }
    });
    return [options];
  };

  return createRoutes();
}
