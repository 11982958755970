import React, { useEffect, useMemo, useState } from 'react';

import ListDropdown from '+dashboard/Shared/ListDropdown';
import { IListItem } from '+types';
import { ForeignBankProps } from '+types/balances-types';
import { cleanInput } from '+utils';

import { bankListMapping, data, defaultForeignCountriesMap } from './ForeignWithdrawalRequestformData';

import '../index.scss';

const ForeignWithdrawalRequestform: React.FC<ForeignBankProps> = ({
  requestFormProps,
  buttonType,
  countriesList,
  statesList,
  currency
}) => {
  const { values, errors, touched, getFieldProps = () => {}, setFieldValue = () => {}, handleBlur } = requestFormProps;

  const [isBankListOpen, setIsBankListOpen] = useState(false);

  const bankList = useMemo(() => {
    return bankListMapping[currency]?.map(bank => ({ code: bank, name: bank, status: 'active' }));
  }, [currency]);

  const getBankListValue = (value?: string) => {
    if (!value) return { code: '', name: '', status: 'active' };
    return bankList?.find(bank => bank.name === value);
  };

  useEffect(() => {
    setFieldValue('country', defaultForeignCountriesMap[currency] || '');
  }, [currency, setFieldValue]);

  useEffect(() => {
    setFieldValue('payment_method', '');
    setFieldValue('account_number', '');
  }, []);

  return (
    <div className="form-wrapper">
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="recipientName">
          <span className="dark">Recipient&apos;s Name</span>
        </label>
        <div className="recipient-name">
          <input
            id="firstName"
            className="form-control"
            data-testid="firstName"
            type="text"
            name="first_name"
            placeholder="John"
            value={values?.first_name}
            onChange={e => setFieldValue('first_name', cleanInput(e.target.value))}
            onBlur={handleBlur}
          />
          <input
            id="lastName"
            className="form-control"
            data-testid="lastName"
            type="text"
            name="last_name"
            placeholder="Doe"
            value={values?.last_name}
            onChange={e => setFieldValue('last_name', cleanInput(e.target.value))}
            onBlur={handleBlur}
          />
        </div>
        <div className="recipient-name">
          <div className="input__errors" id="firstName-error" data-feedback="invalid">
            <p> {touched?.first_name && errors?.first_name}</p>
          </div>
          <div className="input__errors" id="lastName-error" data-feedback="invalid">
            <p>{touched?.last_name && errors?.last_name}</p>
          </div>
        </div>
        <div className="form-group mb-4">
          <label className="withdraw-label" htmlFor="accountNumber">
            <span className="dark">Account Number</span>
          </label>
          <input
            id="accountNumber"
            className="form-control"
            data-testid="accountNumber"
            type="text"
            name="account_number"
            placeholder="e.g 000123423429 or 09e12r345fa6edf"
            value={values?.account_number}
            onChange={e => setFieldValue('account_number', cleanInput(e.target.value))}
            onBlur={handleBlur}
            onKeyDown={e => values?.account_number}
          />
          <div className="input__errors" id="accountNumber-error" data-feedback="invalid">
            <p>{touched?.account_number && errors?.account_number}</p>
          </div>
        </div>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="paymentType">
          <span className="dark">Payment Type</span>
        </label>
        <select
          id="paymentMethod"
          className="form-control"
          aria-describedby="withdraw-form"
          {...(getFieldProps('payment_method') as object)}
        >
          <option value="">- Select a payment type -</option>
          {data
            ?.find(item => item.currency === currency)
            ?.paymentMethods.map(({ type, label }) => (
              <option key={type} value={label}>
                {label}
              </option>
            ))}
        </select>
        <div className="input__errors" id="paymentMethod-error" data-feedback="invalid">
          <p>{touched?.payment_method && errors?.payment_method}</p>
        </div>
      </div>
      {buttonType === 'international' ? (
        <div className="form-group mb-4">
          <label className="withdraw-label" htmlFor="swiftCode">
            <span className="dark">SWIFT Code</span>
          </label>
          <input
            id="swiftCode"
            className="form-control"
            data-testid="swiftCode"
            type="text"
            name="swift_code"
            placeholder="bofaus3n4r05"
            value={values?.swift_code}
            onChange={e => setFieldValue('swift_code', cleanInput(e.target.value))}
            onBlur={handleBlur}
            onKeyDown={e => values?.swift_code}
          />
          <div className="input__errors" id="swiftCode-error" data-feedback="invalid">
            <p>{touched?.swift_code && errors?.swift_code}</p>
          </div>
        </div>
      ) : (
        <div className="form-group mb-4">
          <label className="withdraw-label" htmlFor="routingNumber">
            <span className="dark">Routing number</span>
          </label>
          <input
            id="routingNumber"
            className="form-control"
            data-testid="routingNumber"
            type="text"
            name="routing_number"
            placeholder="00956"
            value={values?.routing_number}
            onChange={e => setFieldValue('routing_number', cleanInput(e.target.value))}
            onBlur={handleBlur}
            onKeyDown={e => values?.routing_number}
          />
          <div className="input__errors" id="routingNumber-error" data-feedback="invalid">
            <p>{touched?.routing_number && errors?.routing_number}</p>
          </div>
        </div>
      )}
      <div className="form-group reason-container">
        <label htmlFor="payout currency" className="withdraw-lable">
          <span className="dark form_label bank_name_label">Bank Name</span>
        </label>
        <ListDropdown
          list={bankList || []}
          type="bank"
          className="banks-list form-control"
          notFoundCTAText="Enter bank name"
          notFoundText="Bank not found?"
          allowCustomInput
          customInputShowCTAText="Or select from our list"
          customInputHiddenCTAText="Can't find your bank on the list?"
          customInputPlaceholder="Bank name"
          value={getBankListValue(values?.bank_name)}
          active={isBankListOpen}
          setActive={(open: boolean) => setIsBankListOpen(open)}
          setValue={(value: IListItem | string) => {
            const val = value as IListItem;
            setFieldValue('bank_name', val.name);
          }}
        />
        <div className="input__errors" id="bankName-error" data-feedback="invalid">
          <p>{touched?.bank_name && errors?.bank_name}</p>
        </div>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="country">
          <span className="dark">Country</span>
        </label>
        <select
          id="country"
          className="form-control"
          aria-describedby="country"
          {...getFieldProps('country')}
          value={getFieldProps('country').value}
          onChange={getFieldProps('country').onChange}
        >
          {countriesList?.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="state">
          <span className="dark">State</span>
        </label>
        <select id="state" className="form-control" aria-describedby="state" {...getFieldProps('state')}>
          <option value="">- Select a state -</option>
          {statesList?.[values?.country || '']?.map(({ name }) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="city">
          <span className="dark"> City </span>
        </label>
        <input
          id="city"
          className="form-control"
          data-testid="city"
          type="text"
          name="city"
          placeholder="City"
          value={values?.city}
          onChange={e => setFieldValue('city', cleanInput(e.target.value))}
          onBlur={handleBlur}
        />
        <div className="input__errors" id="country-error" data-feedback="invalid">
          <p>{touched?.city && errors?.city}</p>
        </div>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="recipientAddress">
          <span className="dark">Recipient&apos;s Address</span>
        </label>
        <input
          id="recipientAddress"
          className="form-control"
          data-testid="recipientAddress"
          type="text"
          name="recipient_address"
          placeholder="123 Street Ave"
          value={values?.recipient_address}
          onChange={e => setFieldValue('recipient_address', cleanInput(e.target.value))}
          onBlur={handleBlur}
        />
        <div className="input__errors" id="recipientAdress-error" data-feedback="invalid">
          <p>{touched?.recipient_address && errors?.recipient_address}</p>
        </div>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="zipCode">
          <span className="dark">{currency === 'USD' ? 'Zip Code' : 'Postal Code'}</span>
        </label>
        <input
          id="zipCode"
          className="form-control"
          data-testid="zipcode"
          type="text"
          name="zip_code"
          placeholder={currency === 'USD' ? '012346085' : 'AB152CD'}
          value={values?.zip_code}
          onChange={e => setFieldValue('zip_code', cleanInput(e.target.value))}
          onKeyDown={e => values?.zip_code}
          onBlur={handleBlur}
        />
        <div className="input__errors" id="zipcode-error" data-feedback="invalid">
          <p>{touched?.zip_code && errors?.zip_code}</p>
        </div>
      </div>
      <div className="form-group mb-4">
        <label className="withdraw-label" htmlFor="senderEmail">
          <span className="dark">Sender&apos;s Email Address</span>
        </label>
        <input
          id="emailAddress"
          className="form-control"
          data-testid="senderEmail"
          type="email"
          name="sender_email"
          placeholder="name@example.com"
          value={values?.sender_email}
          onChange={e => setFieldValue('sender_email', cleanInput(e.target.value))}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};

export default ForeignWithdrawalRequestform;
