import React, { ElementRef, useRef, useState } from 'react';

import { BaseFundsProps } from '+types/common';

import ConversionsModal from './ConversionsModal';

const ConvertFunds: React.FC<BaseFundsProps> = ({ currency, convertFundLimits, defaultCurrency }) => {
  const wrapperRef = useRef<ElementRef<'div'>>(null);
  const [convertFunds, setConvertFunds] = useState(false);

  const handleConvertFunds = (e: React.MouseEvent) => {
    setConvertFunds(true);
  };

  const isConversionEnabled = Object.keys(convertFundLimits || {}).length > 0 && defaultCurrency in (convertFundLimits || {});

  return (
    <div className="history_summary_option">
      <section ref={wrapperRef} style={{ position: 'relative' }}>
        <button className="btn btn-convert-funds" type="button" disabled={!isConversionEnabled} onClick={handleConvertFunds}>
          <span className="os-icon os-icon-repeat" />
          <span>Convert Funds</span>
        </button>
      </section>
      {convertFunds && <ConversionsModal visible={true} close={() => setConvertFunds(false)} currency={currency} />}
    </div>
  );
};

export default ConvertFunds;
