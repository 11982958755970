import { useState } from 'react';

import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceholder';
import { useClickOutside } from '+hooks';

import './index.scss';

type EventT = {
  title: string;
  value: string | number;
  description: string;
  cta?: {
    label: string;
    link: string;
  };
  haveSub?: boolean;
  sub?: Pick<EventT, 'title' | 'value'>[];
};

interface IEventsSummaryProps {
  summaries: EventT[];
  onSummarySubClicked: ({ summaryIndex, subIndex }: { summaryIndex: number; subIndex: number }) => void;
  isFetching?: boolean;
}

export default function EventsSummary({ summaries, isFetching, onSummarySubClicked }: IEventsSummaryProps) {
  const [showMenu, setShowMenu] = useState(false);

  const menuRef = useClickOutside<HTMLDivElement>(() => {
    setShowMenu(false);
  });

  const summaryRateTextClass = (value: number) => {
    if (value < 50) {
      return 'text-danger';
    }
    if (value >= 50 && value < 70) {
      return 'text-warning';
    }
    return 'text-success';
  };

  return (
    <section className="ies">
      {summaries?.map((summary, index) => (
        <div key={index} className="ies__event">
          {isFetching ? (
            <LoadingPlaceholder type="text" content={2} />
          ) : (
            <>
              <div className="ies__event-title">{summary.title}</div>
              <div
                className={`ies__event-value ${summary.title === 'Success Rate' && summaryRateTextClass(parseFloat(summary?.value?.toString()))}`}
              >{`${summary?.value}${summary?.title === 'Success Rate' ? '%' : ''}`}</div>
              <div className="ies__event-description  d-flex">
                {summary.description}{' '}
                {summary.haveSub && (
                  <div className="dropdown ction-btns" ref={menuRef}>
                    <button type="button" className="btn link-btn text-primary border-0 p-0 ml-2" onClick={() => setShowMenu(true)}>
                      <span>{summary.cta?.label}</span>
                      <i className="os-icon os-icon-chevron-down icon" />
                    </button>

                    {showMenu && (
                      <ul className="element-box box-style ellipsis__nav withdrawal-options menu-items">
                        {summary?.sub?.map((menu, subIndex) => (
                          <li
                            key={menu.title}
                            role="presentation"
                            className="ellipsis__item"
                            onClick={() => {
                              onSummarySubClicked({ summaryIndex: index, subIndex });
                              setShowMenu(false);
                            }}
                          >
                            <span>{menu.title}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      ))}
    </section>
  );
}
