import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { TransactionServices } from '+services/transaction-services';
import { RelatedTransactionsType } from '+types';
import { history, switchTrxnMessage } from '+utils';

const usePayInDetails = () => {
  const { id } = useParams<{ id: string }>();

  const [modalType, setModalType] = useState('');

  const {
    data: response,
    isLoading,
    refetch
  } = TransactionServices.useTransactionDetails({
    id,
    stage: 'pay-ins',
    errorMessage: `There has been an error fetching the details for the transaction: ${id?.toUpperCase()}.`,
    onError: () => {
      history.goBack();
    }
  });
  const data = response?.data;

  const { data: relatedTransactionsResponse } = TransactionServices.useGetRelatedTransactions({
    enabled: !!data,
    ref: data?.payment?.reference,
    errorMessage: 'There was an error getting related transactions'
  });

  const relatedTransactions = relatedTransactionsResponse?.data;

  const relatedTrxnsWithoutCurrentTrxn = relatedTransactions?.data.filter(
    (trx: RelatedTransactionsType) => trx.reference !== data.reference
  );

  const moreThanOneRelatedTrx = relatedTrxnsWithoutCurrentTrxn?.length > 1;

  const trxnStatusObj = switchTrxnMessage[data?.status ?? ('processing' as keyof typeof switchTrxnMessage)];

  return {
    trxnStatusObj,
    modalType,
    setModalType,
    isLoading,
    data,
    refetch,
    relatedTrxns: relatedTrxnsWithoutCurrentTrxn,
    moreThanOneRelatedTrx
  };
};

export default usePayInDetails;
