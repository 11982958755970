/* eslint-disable no-param-reassign */

import Icon from '+containers/Dashboard/Shared/Icons';
import { useSearchQuery } from '+hooks';

import CurrencyPicker from '../../Shared/CurrencyPicker';
import OverpaymentUnderpayment from './components/OverpaymentUnderpayment';

import './index.scss';

const PaymentPreference = () => {
  const searchQuery = useSearchQuery();
  const activeCurrency = searchQuery.value.currency || 'NGN';

  return (
    <section className="payment-preference-wrapper">
      <div className="element-box payment-preferences">
        <h4 className="form-header">Payment Preference</h4>
        <div className="form-desc">
          <p> Update and manage your payment preferences here.</p>
          <p>
            <Icon name="lightbulb" />{' '}
            <a
              href="https://developers.korapay.com/docs/handling-underpayments-and-overpayments#preferences-for-handling-overpayments-and-underpayments"
              target="_blank"
              referrerPolicy="no-referrer"
              rel="noreferrer"
            >
              Learn More about Payment Preferences.
            </a>
          </p>
        </div>
        <div>
          <CurrencyPicker
            options={['NGN', 'USD', 'GBP', 'EUR']}
            onChange={value => {
              searchQuery.setQuery({ currency: value });
            }}
            activeCurrency={activeCurrency}
            label={<span>Preferences for:</span>}
            id="balances__currency-switch"
          />
        </div>
        <legend>
          <span />
        </legend>
        <OverpaymentUnderpayment activeCurrency={activeCurrency} />
      </div>
    </section>
  );
};

export default PaymentPreference;
