import React from 'react';
import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import AvertaBold from '+assets/fonts/Averta/AvertaPE-Extrabold.otf';
import AvertaNormal from '+assets/fonts/Averta/AvertaPE-Regular.otf';
import AvertaSemiBold from '+assets/fonts/Averta/AvertaPE-Semibold.otf';
import { IBillingHistoryDetails } from '+types';
import { capitalizeRemovedash, getDate, getTime } from '+utils';

import KpyLogo from '+assets/img/logos/logo-kpy-ent.png';

Font.register({
  family: 'Averta PE',
  fonts: [{ src: AvertaNormal }, { src: AvertaBold, fontWeight: 700 }, { src: AvertaSemiBold, fontWeight: 500 }]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Averta PE',
    backgroundColor: '#F1F6FA',
    paddingHorizontal: 40,
    paddingVertical: 20,
    position: 'relative'
  },
  logoImage: {
    width: 65,
    height: 34,
    marginBottom: 15,
    objectFit: 'scale-down'
  },
  container: {
    borderRadius: '25px',
    marginBottom: 20,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden'
  },
  receiptSeal: {
    position: 'absolute',
    top: 53,
    right: 69,
    zIndex: 3,
    width: 50,
    height: 50
  },
  containerHeader: {
    backgroundColor: '#F9FBFD',
    padding: 25,
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '25px',
    textAlign: 'center',
    borderBottom: '1px solid #DDE2EC'
  },
  containerHeaderText: {
    fontWeight: 500,
    fontSize: 11,
    color: '#66717F'
  },
  label: {
    fontWeight: 500,
    fontSize: 11,
    color: '#6A94BA'
  },
  amount: {
    fontWeight: 700,
    fontSize: 30,
    textAlign: 'center',
    marginTop: 4,
    marginBottom: 4,
    color: '#2376F3',
    letterSpacing: '-0.4px'
  },
  containerContent: {
    backgroundColor: '#FFFFFF',
    padding: 20,
    borderBottomLeftRadius: '25px',
    borderBottomRightRadius: '25px'
  },
  contentTitle: {
    fontWeight: 500,
    fontSize: 9,
    color: '#414F60',
    marginBottom: 10,
    borderBottom: '1px solid #F1F6FA',
    paddingBottom: 10
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15
  },
  value: {
    fontWeight: 500,
    fontSize: 11,
    color: '#292B2C'
  },
  statusValue: {
    fontWeight: 500,
    fontSize: 12,
    color: '#24B314',
    backgroundColor: '#E4FFF1',
    paddingVertical: 4,
    paddingHorizontal: 14,
    borderRadius: 50
  },
  ctaContainer: {
    fontSize: 9,
    color: '#94A7B7',
    fontWeight: 500
  },
  cta: {
    color: '#2376F3',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: 10
  }
});

const ReceiptTemplate = ({ receiptData }: { receiptData: IBillingHistoryDetails }) => {
  return (
    <Document>
      <Page size="A5" style={styles.page}>
        <Image src={KpyLogo} style={styles.logoImage} />
        <View style={styles.container}>
          <View style={styles.containerHeader}>
            <Text style={styles.containerHeaderText}>Total payment of</Text>
            <Text style={styles.amount}>
              {receiptData?.currency}
              {receiptData.amount}
            </Text>
            <Text style={styles.containerHeaderText}>has been received</Text>
          </View>
          <View style={styles.containerContent}>
            <Text style={styles.contentTitle}>ADDITIONAL DETAILS</Text>
            <View style={styles.row}>
              <Text style={styles.label}>Merchant</Text>
              <Text style={styles.value}>{receiptData?.merchant_name}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Amount</Text>
              <Text style={styles.value}>
                {receiptData?.currency}
                {receiptData?.amount}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Invoice Reference</Text>
              <Text style={styles.value}>{receiptData?.reference}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Status</Text>
              <Text style={styles.statusValue}>{capitalizeRemovedash(receiptData?.status)}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Fee type</Text>
              <Text style={styles.value}>{capitalizeRemovedash(receiptData?.type)}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Subscription Type</Text>
              <Text style={styles.value}>{capitalizeRemovedash(receiptData.card_category)} Virtual Card</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Invoice Creation Timestamp</Text>
              <Text style={styles.value}>
                {getDate(receiptData?.created_at)}, {getTime(receiptData?.created_at)}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Invoice Charged Timestamp</Text>
              <Text style={styles.value}>
                {getDate(receiptData?.last_charge_date)}, {getTime(receiptData?.last_charge_date)}
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text style={styles.ctaContainer}>
            This receipt serves as proof of payment. If you require any assistance or have questions regarding this transaction, please
            <Link href="mailto:support@korapay.com">
              <Text style={styles.cta}>&nbsp;contact our support team</Text>
            </Link>
            .
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default ReceiptTemplate;
