import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceholder';
import { useSearchQuery } from '+hooks';
import { KYCServices } from '+services/kyc-services';

import AccountHolderSumary from './KYCComponents/AccountHolderSummary';
import KYCDetails from './KYCComponents/KYCDetails';
import RelatedDocuments from './KYCComponents/RelatedDocuments';

interface IKYCinformationProps {
  reference: string;
  firstName: string;
  lastName: string;
}
export default function KYCInformation({ reference, firstName, lastName }: IKYCinformationProps) {
  const searchQuery = useSearchQuery();
  const tabs = ['Account Holder Summary', 'KYC Details', 'Related Documents'];
  const activeTab = searchQuery.value.kycinfoTab || 'Account Holder Summary';

  const { data, isFetching, isError } = KYCServices.useGetVirtualAccountKycDetails({
    bannerLevel: true
  });

  const renderTabs = (tab: string) => {
    switch (tab) {
      case 'Account Holder Summary':
        return <AccountHolderSumary data={data?.account_summary} firstName={firstName} lastName={lastName} reference={reference} />;
      case 'KYC Details':
        return <KYCDetails data={data?.account_summary} />;
      case 'Related Documents':
        return <RelatedDocuments data={data?.documents} />;
      default:
        return <AccountHolderSumary data={data?.account_summary} firstName={firstName} lastName={lastName} reference={reference} />;
    }
  };

  if (isFetching) return <LoadingPlaceholder type="text" content={3} />;
  if (isError) return <EmptyStateComponent message="No KYC Details found" heading="KYC Detail" />;
  return (
    <div>
      <div className="os-tabs-controls os-tabs-complex settlement-tabs kyc-tabs">
        <ul className="nav nav-tabs" style={{ borderBottom: 'none' }}>
          {tabs.map(tab => {
            return (
              <li className="nav-item" key={tab}>
                <button
                  type="button"
                  data-toggle="tab"
                  onClick={() => searchQuery.setQuery({ kycinfoTab: tab })}
                  className={`nav-link  ${activeTab === tab && 'active'}`}
                >
                  <span className="tab-label">{tab}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      <div style={{ width: '98%', margin: 'auto' }}>{renderTabs(activeTab)}</div>
    </div>
  );
}
