/* eslint-disable no-unused-vars */
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * @description Custom hook for managing and persisting query parameters in the URL.
 * @param {Function | undefined} effectFn - An optional callback function to be executed when the query parameters change.
 * @returns {{
 *   value: Partial<T>;
 *   get: (name: string) => string;
 *   getArr: (name: string) => string[];
 *   setQuery: (queries: Partial<T>, clearAll?: boolean, push?: boolean) => void;
 *   remove: (key: string) => void;
 *   clearAll: () => void;
 * }}
 * @example const searchQuery = useSearchQuery<{ name: string; age: string }>();
 * update query parameters in the URL: searchQuery.setQuery({ name: 'John', age: '20' });
 * get the value of a specific query parameter: searchQuery.get('name'); // John
 * get an array of values for a specific query parameter: searchQuery.getArr('name'); // ['John']
 * clear all query parameters in the URL: searchQuery.clearAll();
 * get current query parameters: searchQuery.value; // { name: null, age: null }
 */

const useSearchQuery = <T extends Record<string, any>>(effectFn?: (data: T) => void) => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => {
    const url = new URLSearchParams(location.search);
    const obj = Array.from(url).reduce<T>(
      (acc, [key, value]) => ({
        ...acc,
        [key]: acc[key] ? [...new Set((Array.isArray(acc[key]) ? acc[key] : [acc[key]])?.concat(value))] : value
      }),
      {} as T
    );
    return obj;
  }, [location]);
  const queryState = useMemo(() => new URLSearchParams(location.search), [location.search]);
  useEffect(() => {
    effectFn?.(query);
  }, [location, query]);

  return {
    value: query as Partial<T>,
    get: (name: string): string => {
      const value = queryState.get(name);
      return value ?? '';
    },
    getArr: (name: string): string[] => {
      const value = queryState.getAll(name);
      return value;
    },
    setQuery: (queries: Partial<T>, clearAll = false, push = false) => {
      const url = new URLSearchParams(clearAll ? '' : location.search);
      Object.entries(queries).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
          value.forEach(item => {
            if (url.has(key, item)) return;
            url.append(key, item);
          });
          return acc;
        }
        if (value === undefined || value === '' || value === null) {
          if (acc.has(key)) acc.delete(key);
          return acc;
        }
        acc.set(key, value);
        return acc;
      }, url);
      history[push ? 'push' : 'replace'](`${location.pathname}?${url.toString()}`);
    },
    clearAll: (keepQuery?: string[]) => {
      let store = {};
      if (keepQuery && keepQuery.length > 0) {
        store = Object.keys(query)
          .map(key => {
            if (keepQuery.includes(key)) return key;
          })
          .filter(item => !!item)
          .reduce(
            (acc, cur) => ({
              ...acc,
              [String(cur)]: query[String(cur)]
            }),
            {}
          );
      }
      const url = new URLSearchParams('');
      if (Object.entries(store).length > 0) {
        Object.entries(store).reduce((acc, [key, value]) => {
          acc.set(key, String(value));
          return acc;
        }, url);
      }
      history.push(`${location.pathname}?${url.toString()}`);
    },
    remove: (key: string) => {
      const url = new URLSearchParams(location.search);
      if (url.has(key)) {
        url.delete(key);
        history.push(`${location.pathname}?${url.toString()}`);
      }
    }
  };
};

export default useSearchQuery;
