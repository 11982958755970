import React, { useState } from 'react';

import bulkPayoutInfo2 from '+assets/img/dashboard/bulkpayoutHints/bulk_pyt_acc_number.svg';
import bulkPayoutInfo3 from '+assets/img/dashboard/bulkpayoutHints/bulk_pyt_cellformat.svg';
import bulkPayoutInfo1 from '+assets/img/dashboard/bulkpayoutHints/bulk_pyt_template.svg';

const useBulkPayoutHintsModal = () => {
  const [showHintsModal, setShowHintsModal] = useState<boolean>(false);
  const getBulkPayoutHintsProps = () => {
    return {
      onSubmit: () => setShowHintsModal(false),
      secondButtonFinalText: 'Done',
      removeIconOnFinalStep: true,
      hasFirstButton: true,
      data: [
        {
          title: (
            <>
              <span className="text-accent">Important Tip</span> for creating Bulk Payout Sheet.
            </>
          ),
          description:
            'Account numbers starting with zeros would be erased in columns due to cell formatting.(e.g. “0217070232” would be interpreted as “217070232”). Here are two ways to fix!',
          image: bulkPayoutInfo1
        },
        {
          title: (
            <>
              You can begin account numbers with an <span className="text-success">apostrophe (&apos;)</span>
            </>
          ),
          description:
            'Modify the entry in each cell that has a number starting with (0) by adding an (‘) before the leading zero (0) to solve this. E.g ‘0213426647',
          image: bulkPayoutInfo2
        },
        {
          title: (
            <>
              You can change the cell format to <span className="text-success">“Plain Text”</span>
            </>
          ),
          description: 'You would retain the leading zero (0) in the cells if you change the cell formatting to “Plain text” or “Number”',
          image: bulkPayoutInfo3
        }
      ]
    };
  };

  return {
    showHintsModal,
    setShowHintsModal,
    getBulkPayoutHintsProps
  };
};
export default useBulkPayoutHintsModal;
