import { useHistory } from 'react-router-dom';

import useFeedbackHandler from '+hooks/feedbackHandler';
import { BalanceServices } from '+services/balance-services';

export const useMerchantBalances = () => {
  const { feedbackInit } = useFeedbackHandler();
  const history = useHistory();

  const {
    data,
    isFetching: fetchingBalances,
    refetch: refetchBalance
  } = BalanceServices.useGetBalances({
    showErrorMessage: false,
    onError: (e: any) => {
      const error = e.response?.data;
      const notLive = error?.message?.includes('not been approved');
      const message = notLive ? 'Your account has not been approved yet' : 'There has been an error in getting your balances';
      feedbackInit({
        message,
        type: 'danger',
        action: {
          action: () => (notLive ? history.push('/dashboard/settings/business') : refetchBalance()),
          name: notLive ? 'Complete account setup' : 'Try again'
        }
      });
    }
  });

  return { balances: data?.data ?? {}, fetchingBalances, refetchBalance };
};
