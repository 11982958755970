/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { useClickOutside } from '+hooks';
import { capitalizeRemovedash } from '+utils/formats';

import './HeaderTabs.scss';

interface IHeaderTab {
  tabs: string[];
  activeTab: string;
  onClick: (arg: string) => void;
  className?: string;
  suffix?: string;
  filterTabs?: (tab: string) => boolean;
}
const HeaderTabs: React.FC<IHeaderTab> = ({ tabs, activeTab, onClick, className, suffix, filterTabs }) => {
  const [viewNav, setViewNav] = useState(false);
  const wrapperRef = useClickOutside<HTMLDivElement>(() => {
    setViewNav(false);
  });

  return (
    tabs &&
    tabs?.length > 0 && (
      <>
        <ul className="nav nav-tabs header-nav" role="tablist">
          {tabs.map((tab: string) => {
            if (filterTabs && !filterTabs(tab)) return null;
            return (
              <li className="nav-item" key={tab} role="presentation" onClick={() => onClick(tab)}>
                <button type="button" role="tab" className={`nav-link ${activeTab === tab ? 'active' : ''}`}>
                  {capitalizeRemovedash(tab)}
                  {suffix && ` ${suffix}`}
                </button>
              </li>
            );
          })}
        </ul>

        <div className="header-dropdown" ref={wrapperRef}>
          <div onClick={() => setViewNav(!viewNav)} className="header-item">
            <p className={`${className ? className : ''}`}>
              {capitalizeRemovedash(activeTab)} {suffix && ` ${suffix}`}
            </p>
            <i className="os-icon os-icon-chevron-down" />
          </div>
          {viewNav && (
            <ul className="element-box box-style header-options">
              {tabs.map(tab => {
                if (filterTabs && !filterTabs(tab)) return null;

                return (
                  <li
                    role="presentation"
                    className={`ellipsis__item ${activeTab === tab && 'active'}`}
                    key={tab}
                    onClick={() => {
                      onClick(tab);
                      setViewNav(false);
                    }}
                  >
                    <span>
                      {capitalizeRemovedash(tab)} {suffix && ` ${suffix}`}
                    </span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </>
    )
  );
};

export default HeaderTabs;
