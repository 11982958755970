import React from 'react';

import './index.scss';

interface CategoriesProps {
  menuOptions: string[];
  currentRoute: string;
  setRoute: (route: { route: string }) => void;
}

const CategoryMenu: React.FC<CategoriesProps> = ({ menuOptions, currentRoute, setRoute }) => {
  return (
    <ul className="category-items">
      {menuOptions?.map((menu, i) => {
        return (
          <li className={`${currentRoute === menu && 'active'}`} onClick={() => setRoute({ route: menu })} key={i}>
            {menu}
          </li>
        );
      })}
    </ul>
  );
};

export default CategoryMenu;
