import FileUpload from '+containers/Dashboard/Shared/FileUpload';
import Icon from '+containers/Dashboard/Shared/Icons';

import LimitWarningBanner from '../../components/LimitWarningBanner';

export interface IEvidenceOfFileUpload {
  id: number;
  identifier: string;
  category: string;
  original_name: string;
  encoding: string;
  path: string;
  mime: string;
  updated_at: string;
  created_at: string;
}

type UploadDueDiligenceFormPropType = {
  updateEvidence: (filePath?: IEvidenceOfFileUpload) => void;
  onProceed: () => void;
  onCancel: () => void;
  evidenceOfFileUpload?: IEvidenceOfFileUpload;
};

const UploadDueDiligenceForm = ({ updateEvidence, evidenceOfFileUpload, onCancel, onProceed }: UploadDueDiligenceFormPropType) => {
  const maxFileSize = 20;
  const utilityUrl = process.env.REACT_APP_DDF_LINK;

  return (
    <div className="id-access-modal fade-in pt-4">
      <h4 className="pb-5 title">Request for Access to Identity Service</h4>
      <LimitWarningBanner />
      <div className="p-3 rounded mb-5">
        <p className="d-flex align-items-start m-0 text-dark">
          <Icon name="message" fill="#FA9500" height={26} width={50} />
          <span className="ml-2">
            Please{' '}
            <a href={utilityUrl} target="_blank" rel="noreferrer">
              Download Blank Due Diligence Form.
            </a>{' '}
            Fill out all required fields in the form, save it on your device and upload the completed version below to proceed with your
            access request.
          </span>
        </p>
      </div>

      <h6>Upload completed Due Diligence Form</h6>
      <FileUpload
        onUploadComplete={data => {
          updateEvidence(data?.data[0]);
        }}
        fileName={evidenceOfFileUpload?.original_name}
        fileUploaded={evidenceOfFileUpload?.path ?? ''}
        onRemoveUploadedFile={() => {
          updateEvidence();
        }}
        fileUploadMessage={<p>Max file size {maxFileSize}MB</p>}
        fileAllowed=".pdf"
        maxFileSize={maxFileSize}
      />

      <div className="d-flex justify-content-end py-5">
        <button className="btn btn-light text-grey py-2 px-4 mr-2" onClick={onCancel}>
          Cancel
        </button>
        <button disabled={!evidenceOfFileUpload} className="btn btn-primary py-3 px-4" onClick={onProceed}>
          Proceed with your access request
        </button>
      </div>
    </div>
  );
};

export default UploadDueDiligenceForm;
