import { AnalyticsServices } from '+services/analytics-services';
import { durationToday, formatAmount } from '+utils';

interface IAnalyticsOverviewProps {
  currency: string;
}

const AnalyticsOverview = ({ currency }: IAnalyticsOverviewProps) => {
  const [startDate, endDate] = durationToday();

  const { data } = AnalyticsServices.useGetPerformanceAnalytics({
    currency: currency.toLowerCase(),
    params: { startDate, endDate }
  });
  const overview = data?.data || {};
  return (
    <section className="element-box element-analytics">
      <div className="analytics-overview__comp">
        <div className="el-tablo">
          <div className="value">{formatAmount(overview?.payinsTotal || 0)}</div>
          <div className="label">Received Today ({currency})</div>
        </div>
        <div className="el-tablo">
          <div className="value">{formatAmount(overview?.payoutsTotal || 0)}</div>
          <div className="label">Sent Today ({currency})</div>
        </div>
        <div className="el-tablo">
          <div className="value">{overview?.allTransactionsCount || '--'}</div>
          <div className="label">Total Transactions</div>
        </div>
      </div>
    </section>
  );
};

export default AnalyticsOverview;
