import React, { ElementRef, useRef, useState } from 'react';

import { AddFundsProps } from '+types/common';

import FundByBankModal from './FundByBankModal';

const AddFunds: React.FC<AddFundsProps> = ({ virtualAccount, disabled }) => {
  const wrapperRef = useRef<ElementRef<'div'>>(null);
  const [fundByBank, setFundByBank] = useState(false);

  const triggerFundByBank = () => {
    if (disabled) return;
    setFundByBank(true);
  };

  return (
    <div className="history_summary_option mb-3">
      <section ref={wrapperRef} style={{ position: 'relative' }}>
        <button
          className="btn btn-success"
          type="button"
          disabled={disabled}
          onClick={triggerFundByBank}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              triggerFundByBank();
            }
          }}
        >
          <span>Add Funds</span>
          <span className="os-icon os-icon-plus" />
        </button>
      </section>
      {fundByBank && <FundByBankModal visible={true} close={() => setFundByBank(false)} virtualAccount={virtualAccount} />}
    </div>
  );
};

export default AddFunds;
