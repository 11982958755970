/* eslint-disable import/extensions */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AdvanceExportModal from '+dashboard/Shared/AdvanceExportModal';
import ExportFilterModal from '+dashboard/Shared/ExportFilterModal';
import { use2FAHistory, useReducerState, useSearchQuery } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import { TransactionServices } from '+services/transaction-services';
import useStore from '+store';
import { BulkDataT, defaultPage, Params } from '+types/bulk-payouts';
import { CurrencyType as CurrencyT } from '+types/common';
import { APIDownload, getDate, history } from '+utils';

import BulkPayoutConfirmModal from '../components/BulkPayoutConfirmModal';
import BulkPayoutHeader from '../components/BulkPayoutHeader';
import BulkPayoutInitTable from '../components/BulkPayoutInitTable';
import BulkPayoutStartModal from '../components/BulkPayoutStartModal';
import BulkPayoutSummary from '../components/BulkPayoutSummary';
import BulkPayoutTable from '../components/BulkPayoutTable';

import DeletedIcon from '+assets/img/deleted-icon.svg';

import './index.scss';

export default function BulkTransactionDetails() {
  const { id } = useParams<Params>();
  const searchQuery = useSearchQuery();
  const { feedbackInit } = useFeedbackHandler();
  const { profile, permissions } = useStore();
  const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const [twoFactorType, setTwoFactorType] = useState<'otp' | 'totp'>('otp');
  const [totpIdentifier, setTotpIdentifier] = useState('');
  const [refetchSummaryData, setRefetchSummaryData] = useState(true);
  const [hasRecoveryCode, setHasRecoveryCode] = useState(false);
  const [exportTransportParams, setExportTransportParams] = useState(null);
  const { data: get2FAHistory } = use2FAHistory({ key: 'GET_2FA_DETAILS' });
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const [state, setState] = useReducerState({
    activeCurrency: searchQuery.get('currency') as CurrencyT,
    showModal: false,
    pagination: defaultPage,
    exportModalVisible: false
  });

  const { activeCurrency, pagination, exportModalVisible } = state;

  const {
    data: bulkTransactions,
    meta,
    isFetching,
    refetch
  } = TransactionServices.useGetBulkPayoutTransactionList({
    bulkReference: id,
    params: {
      page: pagination.page,
      limit: pagination.limit,
      ...pagination.sortingParams
    },
    errorMessage: 'There has been an error getting the bulk payout information'
  });

  const { data: summaryDataResponse, refetch: refetchBulkPayout } = TransactionServices.useGetBulkPayoutSummary({
    bulkReference: id,
    onSuccess: ({ data }) => {
      if (data.status === 'complete') {
        refetch();
        setRefetchSummaryData(false);
      }
    },
    errorMessage: 'There has been an error getting the bulk payout information',
    enabled: refetchSummaryData && !!id
  });

  const summaryData = summaryDataResponse?.data;

  const discardBulkPayout = TransactionServices.useDiscardBulkPayout({
    bulkReference: id,
    onSuccess: () => {
      refetchBulkPayout();
    },
    errorMessage: 'Something went wrong while discarding the bulk payout'
  });

  useEffect(() => {
    const twoFactorHistory = get2FAHistory?.data;
    if (twoFactorHistory?.type === 'totp' && twoFactorHistory?.is_activated) {
      setTwoFactorType(twoFactorHistory?.type);
      setTotpIdentifier(twoFactorHistory?.identifier);
      if (twoFactorHistory?.has_recovery_codes) {
        setHasRecoveryCode(true);
      }
    }
  }, [get2FAHistory?.data]);

  const data = bulkTransactions?.data?.data || [];
  const paging = bulkTransactions?.data?.paging;

  const {
    status,
    reference,
    description,
    payout_count: payCount,
    failed_transactions: failed,
    pending_transactions: pending,
    date_completed: dateCompleted,
    total_bulk_amount: totalBulkAmount,
    successful_transactions: successful,
    payouts: payoutsDraft,
    validation_complete: validationCompleted,
    type
  } = summaryData || {};

  const { isLoading } = TransactionServices.useExportBulkTransfers({
    enabled: Boolean(exportTransportParams),
    params: exportTransportParams,
    errorMessage: 'There has been an error exporting your payouts',
    onSuccess: res => {
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const fileType = exportTransportParams?.format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `Payouts at ${getDate(Date.now())}`, fileType);
        feedbackInit({
          message: `Payouts successfully downloaded`,
          type: 'success'
        });
      }
      setExportTransportParams(null);
      setState({ ...state, exportModalVisible: false });
    },
    onError: () => {
      setState({ ...state, exportModalVisible: false });
      setExportTransportParams(null);
    }
  });

  const exportFile = async (format: string, fieldToExport: string[]) => {
    const parameterizeArray = (key: string, arr: string[]) => {
      const encodedArr = arr.map(encodeURIComponent);
      return encodedArr.join(`&${key}[]=`);
    };
    const fields = parameterizeArray('fieldsToExport', fieldToExport);
    setExportTransportParams({
      format,
      currency: activeCurrency,
      fieldsToExport: fields,
      batch_reference: reference,
      sortingParams: pagination.sortingParams
    });
  };

  const checkIfNoErrorInDraft = () => {
    const errors = payoutsDraft?.filter((item: BulkDataT) => item?.errors?.length);
    return !errors.length;
  };

  const setExportModalVisible = (visible: boolean) => {
    setState({ ...state, exportModalVisible: visible });
  };

  const setPagination = (value: typeof defaultPage) => {
    setState({ ...state, pagination: value });
  };

  const repeat = {
    all: 'Create bulk payout from all transactions',
    successful: 'Create bulk payout from only successful transactions',
    failed: 'Create bulk payout from only failed transactions'
  };

  return (
    <>
      <ExportFilterModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />
      {isStartModalOpen ? (
        <BulkPayoutStartModal
          closeModal={() => {
            setIsStartModalOpen(false);
          }}
          actionBtn={() => {
            refetchBulkPayout();
            refetch();
          }}
          bulkPayoutSummary={summaryData}
          currency={activeCurrency}
          isOpen={isStartModalOpen}
          payoutCount={payoutsDraft?.length}
          twoFactorType={twoFactorType}
          reference={id}
          totpIdentifier={totpIdentifier}
        />
      ) : null}
      {isDiscardModalOpen ? (
        <BulkPayoutConfirmModal
          heading="Discard bulk payout?"
          description="Are you sure you want to discard this bulk payout? Any change or progress you have made so far will be cancelled. This action cannot be undone."
          firstButtonText="No, Continue Editing"
          secondButtonText="Yes, Discard"
          completedHeading="Bulk payout discarded"
          completedDescription="This bulk payout has been discarded and will no longer be processed."
          secondButtonAction={() => {
            discardBulkPayout.mutate(undefined);
          }}
          close={() => setIsDiscardModalOpen(false)}
          confirmModal={isDiscardModalOpen}
          completedImage={DeletedIcon}
        />
      ) : null}
      <div className="row">
        {!isFetching && (
          <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7 back-to-payouts" />
            <span>Back to Bulk Payouts</span>
          </button>
        )}
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div>
              <div className="content-details-box business-info">
                <BulkPayoutHeader
                  summaryData={summaryData}
                  status={status}
                  description={description}
                  data={data}
                  setExportModalVisible={setExportModalVisible}
                  permissions={permissions}
                  startAction={() => {
                    setIsStartModalOpen(true);
                  }}
                  discardAction={() => {
                    setIsDiscardModalOpen(true);
                  }}
                  validationCompleted={validationCompleted}
                  enableStartBtn={status === 'draft' && validationCompleted && checkIfNoErrorInDraft()}
                  enableDiscardBtn={status === 'draft' && validationCompleted}
                  type={type}
                  currency={activeCurrency}
                />

                <BulkPayoutSummary
                  successful={successful}
                  activeCurrency={activeCurrency}
                  dateCompleted={dateCompleted}
                  summaryData={summaryData}
                  failed={failed}
                  status={status}
                  payCount={payCount}
                  isFetching={isFetching}
                  validationComplete={validationCompleted}
                  draftTotalEntries={payoutsDraft?.length}
                />
                <div className="row">
                  <div className="col-md-12">
                    <div className="element-wrapper">
                      {['cancelled', 'draft'].includes(status) ? (
                        <BulkPayoutInitTable
                          data={payoutsDraft}
                          isFetching={isFetching}
                          refetch={refetchBulkPayout}
                          permissions={permissions}
                          reference={reference}
                          status={status}
                          payoutValidationStatus={validationCompleted}
                          payoutType={summaryData?.type}
                          currency={activeCurrency}
                        />
                      ) : (
                        <BulkPayoutTable
                          data={data}
                          isFetching={isFetching}
                          paging={paging}
                          setPagination={setPagination}
                          pagination={pagination}
                          refetch={refetch}
                          setExportModalVisible={setExportModalVisible}
                          permissions={permissions}
                          status={status}
                        />
                      )}

                      {exportModalVisible && (
                        <AdvanceExportModal
                          type="bulk-payouts"
                          openExport={exportModalVisible}
                          setOpenExport={(visible: boolean) => setState({ ...state, exportModalVisible: visible })}
                          exportAction={exportFile}
                          showSuccessModal={false}
                          isLoading={isLoading}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
