/* eslint-disable no-unused-vars */
import { keepPreviousData } from '@tanstack/react-query';

import { useFetch, useSend } from '+hooks';

export const TeamsRoleServices = {
  useFetchTeamMembers: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage
  }: {
    onSuccess?: (data: unknown) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
  }) =>
    useFetch(`/users/teams`, {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      placeholderData: keepPreviousData,
      showSuccessMessage: false,
      useEnvironmentHandler: 'live'
    }),

  useFetchPermissions: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage
  }: {
    onSuccess?: (data: unknown) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    reInvalidate?: boolean;
  }) =>
    useFetch(`/permissions`, {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      placeholderData: keepPreviousData,
      useEnvironmentHandler: 'live'
    }),

  useFetchRoles: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    params
  }: {
    onSuccess?: (data: unknown) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    reInvalidate?: boolean;
    params: { page: number; limit: number; sortingParams?: {}; showAll?: boolean };
  }) =>
    useFetch(`/roles`, {
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      params,
      placeholderData: keepPreviousData,
      useEnvironmentHandler: 'live'
    }),

  useChangeMemberRole: ({
    onSuccess,
    bannerLevel,
    showErrorMessage,
    onError,
    errorMessage,
    reInvalidate
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    onError?: (e: Error) => void;
    errorMessage?: string;
    reInvalidate?: boolean;
  }) =>
    useSend({
      url: '/users/teams/roles',
      method: 'patch',
      onSuccess,
      errorMessage,
      showErrorMessage,
      reInvalidate,
      errorMessageBannerLevel: bannerLevel,
      onError,
      showSuccessMessage: false,
      useEnvironmentHandler: 'live'
    }),

  useCreateCustomRole: ({
    onSuccess,
    bannerLevel,
    showErrorMessage,
    onError,
    errorMessage,
    reInvalidate
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    onError?: (e: Error) => void;
    errorMessage?: string;
    reInvalidate?: boolean;
  }) =>
    useSend({
      url: '/roles',
      method: 'post',
      onSuccess,
      errorMessage,
      showErrorMessage,
      reInvalidate,
      errorMessageBannerLevel: bannerLevel,
      onError,
      showSuccessMessage: false,
      useEnvironmentHandler: 'live'
    }),

  useUpdateCustomRole: ({
    onSuccess,
    bannerLevel,
    showErrorMessage,
    onError,
    errorMessage,
    reInvalidate,
    id
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    onError?: (e: Error) => void;
    errorMessage?: string;
    reInvalidate?: boolean;
    id: number;
  }) =>
    useSend({
      url: `/roles/${id}`,
      method: 'patch',
      onSuccess,
      errorMessage,
      showErrorMessage,
      reInvalidate,
      errorMessageBannerLevel: bannerLevel,
      onError,
      useEnvironmentHandler: 'live'
    }),

  useInviteTeamMember: ({
    onSuccess,
    bannerLevel,
    showErrorMessage,
    onError,
    errorMessage,
    reInvalidate,
    params
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    onError?: (e: Error) => void;
    errorMessage?: string;
    reInvalidate?: boolean;
    params: {
      email: string;
      role_slug: number;
      authorization: { two_factor_type: string; code: string; identifier: string };
    };
  }) =>
    useSend({
      url: '/users/teams/invites',
      method: 'post',
      onSuccess,
      errorMessage,
      showErrorMessage,
      params,
      reInvalidate,
      errorMessageBannerLevel: bannerLevel,
      onError,
      showSuccessMessage: false,
      useEnvironmentHandler: 'live'
    }),

  useRemoveMember: ({
    onSuccess,
    bannerLevel,
    showErrorMessage,
    onError,
    errorMessage,
    reInvalidate,
    params
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    onError?: (e: Error) => void;
    errorMessage?: string;
    reInvalidate?: boolean;
    params: { email: any; authorization: { two_factor_type: string; code: string; identifier: string } };
  }) =>
    useSend({
      url: '/users/teams/invites/remove',
      method: 'post',
      onSuccess,
      errorMessage,
      showErrorMessage,
      params,
      reInvalidate,
      errorMessageBannerLevel: bannerLevel,
      onError,
      showSuccessMessage: false,
      useEnvironmentHandler: 'live'
    }),

  useRemoveRole: ({
    onSuccess,
    bannerLevel,
    showErrorMessage,
    onError,
    errorMessage,
    reInvalidate,
    roleId
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    onError?: (e: Error) => void;
    errorMessage?: string;
    reInvalidate?: boolean;
    roleId: number;
  }) =>
    useSend({
      url: `/roles/${roleId}`,
      method: 'delete',
      onSuccess,
      errorMessage,
      showErrorMessage,
      reInvalidate,
      errorMessageBannerLevel: bannerLevel,
      onError,
      showSuccessMessage: false,
      useEnvironmentHandler: 'live'
    })
};
