/* eslint-disable import/prefer-default-export */
import APIRequest from '+services/api-services';
import { IBankNameEnquiry } from '+types';

const merchantMiddlewareBaseURL = process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE || 'http://localhost:3000';
const merchantMiddlewareAPIRequest = new APIRequest(merchantMiddlewareBaseURL);

const utilityBaseURL = process.env.REACT_APP_UTILITY_API_BASE || 'http://localhost:3000';
const utilityAPIRequest = new APIRequest(utilityBaseURL);

export const bankNameEnquiry = async (data: IBankNameEnquiry, useCurrentEnvironment: boolean) => {
  const response = await merchantMiddlewareAPIRequest.bankEnquiry(data, useCurrentEnvironment);
  return { data: response.data };
};

export const uploadFiles = async file => {
  const response = await utilityAPIRequest.uploadFiles(file);
  return { data: response.data };
};

export const mobileEnquiry = async data => {
  const response = await merchantMiddlewareAPIRequest.mobileNumberEnquiry(data);
  return { data: response?.data };
};
