import { RelatedTransactionsType } from '+types';
import { capitalizeRemovedash, cardStatus, formatAmount, getDate, getTime, switchStatus } from '+utils';

export const PayInsRelatedTransactions = ({ uniqueRef, trxns }: { uniqueRef: string; trxns: RelatedTransactionsType[] }) => {
  const extraCardStatus = (extraStatus: string) => cardStatus[extraStatus as keyof typeof cardStatus];

  return (
    <ul className="related-trxns__list">
      {trxns.map(trx => (
        <li key={trx.reference} className="related-trxns__list-item">
          <div>
            {getDate(trx.transaction_date)} {getTime(trx.transaction_date)}
          </div>
          <div>
            <span>{uniqueRef}</span>
            <span>{trx.reference}</span>
          </div>
          <div>
            <span>
              <i className={`status-pill smaller ${switchStatus(trx.status)}`} />
              {extraCardStatus(trx.status) || capitalizeRemovedash(trx.status)}
            </span>
            {trx.meta?.charge_operation && (
              <span>{trx.meta.charge_operation === 'pre_authorization' ? 'Auth' : trx.meta.charge_operation.split('_')[0]}</span>
            )}
          </div>
          <div>
            {formatAmount(trx.amount)} {trx.currency}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default PayInsRelatedTransactions;
