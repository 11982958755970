import { useEffect, useState } from 'react';

import useFeedbackHandler from '+hooks/feedbackHandler';
import { SettlementServices } from '+services/settlement-services';
import { logError, switchCurrency } from '+utils';

import Modal from '../../../Shared/Modal';

interface IUpdateSettlementDestinationProps {
  closeModal: () => void;
  visible: boolean;
  updateSettings: () => void;
  settlementConfig?: Record<string, string> | null;
}

const UpdateSettlementDestination = ({
  closeModal,
  visible,
  updateSettings,
  settlementConfig = null
}: IUpdateSettlementDestinationProps) => {
  const { feedbackInit } = useFeedbackHandler();

  const [activeCurrency, setActiveCurrency] = useState<string>('');
  const [destination, setDestination] = useState<string>(settlementConfig?.[activeCurrency]?.destination || 'disbursement_wallet');
  const destinationOptions = {
    koraBal: { name: 'Korapay Balance', value: 'disbursement_wallet' },
    settlementAccount: { name: 'Settlement Bank Account', value: 'settlement_account' }
  };
  const destinationOptionsPerCurrency = {
    NGN: [destinationOptions.koraBal, destinationOptions.settlementAccount],
    KES: [destinationOptions.koraBal, destinationOptions.settlementAccount],
    USD: [destinationOptions.koraBal]
  };

  const defaultDestination = destinationOptions.koraBal;

  useEffect(() => {
    const currentDestination = settlementConfig?.[activeCurrency]?.destination || defaultDestination.value;
    setDestination(currentDestination);
  }, [activeCurrency]);

  const settlementsConfigMutation = SettlementServices.useUpdateSettlementConfig({
    successMessage: 'Successfully updated your settlement destination',
    onSuccess: () => {
      updateSettings();
      closeModal();
    }
  });

  const updateSettlementDestination = async () => {
    const payload = {
      currency: activeCurrency,
      settings: {
        destination
      }
    };

    try {
      await settlementsConfigMutation.mutateAsync(payload);
    } catch (error) {
      logError(error);
      feedbackInit({
        componentLevel: true,
        message: '',
        type: 'danger'
      });
      throw error;
    }
  };

  const formContent = () => {
    return (
      <section className="settlements__settings--update-form">
        <div className="form-group mb-4">
          <label htmlFor="todo">Currency</label>
          <select className="form-control" onChange={e => setActiveCurrency(e.target.value)} value={activeCurrency}>
            <option value="">Select Currency</option>
            {Object.keys(settlementConfig || {})?.map(currency => {
              return (
                <option key={currency} value={currency}>
                  {switchCurrency[currency]}
                </option>
              );
            })}
          </select>
        </div>

        <div className="form-group mb-4">
          <label htmlFor="todo">Select Settlement Destination</label>
          <select className="form-control" onChange={e => setDestination(e.target.value)} value={destination}>
            {!activeCurrency ? (
              <option value={defaultDestination.value}>Select Destination</option>
            ) : destinationOptionsPerCurrency?.[activeCurrency] === undefined ? (
              <option value={defaultDestination.value}>{defaultDestination.name}</option>
            ) : (
              destinationOptionsPerCurrency?.[activeCurrency]?.map(each => {
                return (
                  <option key={each.value} value={each.value}>
                    {each.name}
                  </option>
                );
              })
            )}
          </select>
        </div>

        <div>
          <p>You will be charged a fee for every settlement made into your Settlement Bank Account.</p>
        </div>
      </section>
    );
  };

  return (
    <Modal
      visible={visible}
      close={closeModal}
      heading="Settlement Destination"
      description="Where would you like to be settled?"
      content={formContent()}
      secondButtonText="Save"
      secondButtonAction={updateSettlementDestination}
      secondButtonDisable={!activeCurrency || !destination}
      completedHeading="Done"
      completedDescription="The settlement destination for this currency has been updated successfully"
    />
  );
};

export default UpdateSettlementDestination;
