/* eslint-disable no-unused-vars */
import { keepPreviousData } from '@tanstack/react-query';

import { useFetch, useSend } from '+hooks';
import { AccessControlType, ISendResponse } from '+types';

export const VirtualBankServices = {
  useGetAccessControl: ({
    onError,
    bannerLevel,
    currency,
    enabled,
    errorMessage,
    onSuccess
  }: {
    onError?: () => void;
    bannerLevel?: boolean;
    reference?: string;
    currency: string;
    enabled?: boolean;
    errorMessage?: string;
    onSuccess?: (data: ISendResponse<AccessControlType>) => void;
  }) =>
    useFetch(`/settings/products/visibility?currency=${currency}`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      enabled,
      errorMessage,
      onSuccess,
      useEnvironmentHandler: 'live'
    }),

  useGetVirtualBankAccounts: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params?: { page: number; limit: number };
    errorMessage?: string;
  }) =>
    useFetch(`/virtual-bank-account`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params,
      errorMessage,
      placeholderData: keepPreviousData,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetVirtualBankAccount: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    errorMessage,
    id
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
    id: any;
  }) =>
    useFetch(`/virtual-bank-account/${id}`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      errorMessage,
      useEnvironmentHandler: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetAllVirtualBankAccountHolders: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params?: { page: number; limit: number };
    errorMessage?: string;
  }) =>
    useFetch(`/virtual-bank-account/account-holders`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params,
      errorMessage,
      placeholderData: keepPreviousData,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useUpgradeVirtualAccountRequests: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params?: { page: number; limit: number };
    errorMessage?: string;
  }) =>
    useFetch(`/virtual-bank-account/upgrade-requests`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params,
      errorMessage,
      placeholderData: keepPreviousData,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetVirtualBankAccountTransactions: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage,
    id
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params?: { page: string; limit: string; status?: string[] };
    errorMessage?: string;
    id: any;
  }) =>
    useFetch(`/virtual-bank-account/${id}/transactions`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params,
      errorMessage,
      placeholderData: keepPreviousData,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useSingleVirtualAccountUpgradeRequest: ({
    onError,
    bannerLevel,
    errorMessage,
    reference
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params?: { page: string; limit: string; status?: string[] };
    errorMessage?: string;
    reference: any;
  }) =>
    useFetch(`/virtual-bank-account/upgrade-requests/${reference}`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      placeholderData: keepPreviousData,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useManageAccountHolder: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    reInvalidate
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    errorMessage?: string;
    reInvalidate?: boolean;
  }) =>
    useSend({
      url: `/virtual-bank-account/account-holders/manage`,
      method: 'post',
      onSuccess,
      onError,
      errorMessage,
      reInvalidate,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useManageAccountNumber: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    errorMessage?: string;
  }) =>
    useSend({
      url: `/virtual-bank-account/manage`,
      method: 'post',
      onSuccess,
      onError,
      errorMessage,
      reInvalidate: true,
      useEnvironmentHandler: true,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useUpgradeVirtualAccount: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    errorMessage?: string;
  }) =>
    useSend({
      url: `/virtual-bank-account/upgrade`,
      method: 'post',
      onSuccess,
      onError,
      errorMessage,
      reInvalidate: true,
      useEnvironmentHandler: true,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetVirtualBankAccountHolderEvents: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: { format?: string; page: string; limit: string; dateFrom?: string; dateTo?: string; accountReference: string };
    errorMessage?: string;
  }) => {
    const { page, limit, dateFrom, dateTo, accountReference } = params;
    let formattedParams = {};
    if (dateFrom && dateTo) {
      formattedParams = { page, limit, date_from: dateFrom, date_to: dateTo };
    } else {
      formattedParams = { page, limit };
    }
    return useFetch(`/virtual-bank-account/account-holders/${accountReference}/events`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...{ requestConfig: { responseType: 'blob' } }
    });
  },

  useGetVirtualBankAccountHolder: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: { format?: string; page: string; limit: string; accountReference: any };
    errorMessage?: string;
  }) => {
    const { page, limit, accountReference } = params;
    const formattedParams = { page, limit };
    return useFetch(`/virtual-bank-account/account-holders/${accountReference}`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...{ requestConfig: { responseType: 'blob' } }
    });
  },

  useIncreaseVirtualAccountLimitService: ({
    onSuccess,
    onError,
    errorMessage
  }: {
    onSuccess: () => void;
    onError: (error: Error) => void;
    errorMessage?: string;
  }) =>
    useSend({
      url: `/settings/virtual-bank-accounts/counts/request-increase`,
      method: 'post',
      onSuccess,
      onError,
      errorMessage,
      useEnvironmentHandler: 'live'
    }),
  useGetVirtualBankAccountLimitDetails: ({ params, enabled }: { enabled?: boolean; params: { currency: string } }) =>
    useFetch(`/settings/virtual-bank-accounts/counts/`, {
      enabled,
      placeholderData: keepPreviousData,
      params: params,
      useEnvironmentHandler: 'live'
    })
};
