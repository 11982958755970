import { AllChargebacksResponseType, ChargebackStatusType } from '+types';
import { formatAmount, getDate, getTime, history } from '+utils';

import { chargebackTableStatusConfig as statusConfig } from '../../data';

const ChargebacksRow = ({ rowData }: { rowData: Array<AllChargebacksResponseType> }) => {
  const handleClick = (reference: string) => history.push(`/dashboard/issuing/issued-card-chargebacks/${reference}`);

  const isChargebackPending = (status: ChargebackStatusType) =>
    ['pending', 'pending_pre_arbitration', 'processing', 'processing_pre_arbitration'].includes(status);

  return rowData?.map(tx => (
    <div
      key={`transaction_${tx.reference}`}
      data-status-pending={isChargebackPending(tx.status)}
      className="div-table --history-table --row issuance-chgbk-row"
      role="button"
      tabIndex={0}
      onClick={() => handleClick(tx.reference)}
      onKeyUp={e => {
        if (e.key === 'Enter') e.preventDefault();
        handleClick(tx.reference);
      }}
    >
      <div className="--txn-status --column d-flex">
        <span className="body-row-header">Status:</span>
        <span className={`status-indicator ${statusConfig[tx.status].className}`} />
        <span>{statusConfig[tx.status].title}</span>
      </div>
      <div className="--column">
        <span className="body-row-header">Chargeback ID:</span>
        <span className="font-weight-500 text-uppercase" style={{ color: '#007bff' }}>
          {tx.reference}
        </span>
      </div>
      <div className="--column">
        <span className="body-row-header">Vendor:</span>
        <span>{tx.card_acceptor_name || 'N/A'}</span>
      </div>
      <div className="--column">
        <span className="body-row-header">Cardholder:</span>
        <span className="value">{tx.card_holder_name}</span>
      </div>
      <div className="--column">
        <span className="body-row-header">Date Escalated:</span>
        <span className="grey-text">
          {getDate(tx.escalation_date)} {getTime(tx.escalation_date)}
        </span>
      </div>
      <div className="--column">
        <span className="body-row-header">Chargeback Amount:</span>
        <span>
          <span className="font-weight-500">{formatAmount(tx.amount)}</span>
          <span className="ml-1 d-inline">{tx.currency}</span>
        </span>
      </div>
    </div>
  ));
};

export default ChargebacksRow;
