import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useMobileMoneyNetwork, useMobileValidation } from '+hooks';
import APIRequest from '+services/api-services';
import Modal from '+shared/Modal';
import { BulkDataT, IBulkPayoutEditModalProps, WithdrawalDetails } from '+types';
import { backwardAmountInput, cleanInput, countryMobileCode, formatAmount, stripNonNumeric } from '+utils';

import './index.scss';

const validationSchema = Yup.object({
  reference: Yup.string().required('Reference is required'),
  operator: Yup.string().required('Operator is required'),
  mobile_number: Yup.string().required('Mobile number is required'),
  username: Yup.string(),
  amount: Yup.string()
    .required('Amount is required')
    .matches(/^\d{1,3}(,\d{3})*(.\d{1,2})?$/, 'Amount is not valid'),
  narration: Yup.string().required('Description is required')
});

const api = new APIRequest();
const MobileMoneyEditModal = ({ confirmModal, closeModal, actionBtn, editData, currency }: IBulkPayoutEditModalProps) => {
  const { data: mobileMoneyOperatorData } = useMobileMoneyNetwork(currency);
  const { withdrawalDetails, setWithdrawalDetails, showMobileName, accountNameDisabled } = useMobileValidation({ currency });

  const formik = useFormik({
    initialValues: {
      operator: editData?.mobile_money?.operator_slug || '',
      mobile_number: editData?.mobile_money?.mobile_number || '',
      account_name: editData?.mobile_money?.account_name,
      amount: editData?.amount,
      narration: editData?.narration,
      reference: editData?.reference,
      email: editData?.customer?.email
    },
    validationSchema,
    onSubmit: () => {}
  });

  const { setFieldValue, isValid, values, dirty } = formik;
  const canContinue = dirty && isValid;

  const modalContent = () => {
    return (
      <div className="bulk-payout-edit-form" data-testid="bulk-payout-edit-form">
        {editData?.errors?.length ? (
          <div className="bulk-payout-error">
            <p>{'*Please correct the error(s) in this transaction'}</p>
            {editData?.errors?.map(item => (
              <div key={item?.title}>
                <p>
                  {item.messages?.map(message => (
                    <span className="slug-error" key={message}>
                      {message}
                    </span>
                  ))}
                </p>
              </div>
            ))}
          </div>
        ) : null}
        <div className="bulk-input-wrapper">
          <label htmlFor="reference">Reference</label>
          <input
            id="reference"
            type="text"
            name="reference"
            placeholder="Enter reference"
            className="form-field form-control"
            disabled
            value={values?.reference}
          />
        </div>
        <div className="bulk-input-wrapper">
          <label htmlFor="email">Email address (optional)</label>
          <input
            id="email"
            type="text"
            name="email"
            placeholder=""
            className="form-field  form-control"
            value={values?.email}
            onChange={e => setFieldValue('email', e.target.value)}
          />
        </div>
        <div className="bulk-input-wrapper">
          <select
            aria-label="select"
            className="form-control"
            name="operatorCode"
            onChange={e => {
              const selectedOperator = mobileMoneyOperatorData?.data?.find((operator: any) => operator.slug === cleanInput(e.target.value));
              if (selectedOperator) {
                const { slug, code } = selectedOperator;
                setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, operatorCode: slug, mobileMoneyCode: code }));
                setFieldValue('operator', slug);
              }
            }}
          >
            <>
              <option value="">Select a Mobile Network</option>
              {mobileMoneyOperatorData?.data?.map((operator: any) => (
                <option value={operator.slug} key={operator.id}>
                  {operator.name}
                </option>
              ))}
            </>
          </select>
        </div>
        <div className="bulk-input-wrapper">
          <label htmlFor="mobileNo" className="withdraw-label">
            <span className="dark">Mobile Number</span>
          </label>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">{`+${countryMobileCode[currency]}`}</div>
            </div>
            <input
              name="mobileNo"
              component="input"
              className="form-control"
              value={withdrawalDetails.mobileNo}
              type="text"
              onChange={e => {
                const formattedInput = stripNonNumeric(e.target.value);
                setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, mobileNo: formattedInput }));
                setFieldValue('mobile_number', formattedInput);
              }}
              placeholder="e.g 0123456789"
            />
          </div>
        </div>
        {['GHS'].includes(currency) ? (
          <>
            {showMobileName && (
              <div className="form-group">
                <label htmlFor="username" className="withdraw-label">
                  <span className="dark">
                    Account Name <span className="customer_name">(Customer's name is required)</span>
                  </span>
                </label>
                <input
                  rows="2"
                  maxLength="150"
                  value={withdrawalDetails.username || ''}
                  className="form-control"
                  name="username"
                  disabled={accountNameDisabled}
                  onChange={e => {
                    const accountName = e.target.value;
                    setWithdrawalDetails((details: WithdrawalDetails) => ({ ...details, username: accountName }));
                  }}
                  placeholder="Customer's name"
                />
              </div>
            )}
          </>
        ) : (
          <div className="form-group">
            <label htmlFor="username" className="withdraw-label">
              <span className="dark">
                Account Name <span style={{ opacity: 0.7 }}>(optional)</span>
              </span>
            </label>
            <input
              rows="2"
              maxLength="150"
              value={withdrawalDetails.username}
              className="form-control"
              name="username"
              onChange={e => {
                const formattedInput = cleanInput(e.target.value);
                setWithdrawalDetails((details: WithdrawalDetails) => ({
                  ...details,
                  username: formattedInput
                }));
              }}
              placeholder="Customer's name"
            />
          </div>
        )}
        <div className="bulk-input-wrapper">
          <label htmlFor="amount">{`Amount ${currency || 'NGN'}`}</label>
          <input
            id="amount"
            type="text"
            name="amount"
            placeholder="eg 1,000.00"
            maxLength={11}
            onChange={e => {
              const formattedAmount = formatAmount(backwardAmountInput(e.target.value));
              setFieldValue('amount', formattedAmount !== 'NaN' ? formattedAmount : '0');
            }}
            value={`${formatAmount(values?.amount)}`}
            className="form-field form-control"
          />
        </div>
        <div className="bulk-input-wrapper">
          <label htmlFor="description">Description</label>
          <input
            id="description"
            type="text"
            name="description"
            placeholder="Enter description"
            className="form-field form-control"
            value={values?.narration}
            onChange={e => {
              setFieldValue('narration', e.target.value);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      size="md"
      close={() => closeModal(false)}
      heading="Edit entry"
      description={
        <p style={{ color: '#414F5F', fontWeight: 400, display: 'block' }}>
          You can edit the details of an entry before you start the bulk payout. Click the ‘Update’ button when you are done to save your
          changes.
        </p>
      }
      content={modalContent()}
      justify="space-between"
      firstButtonText="Cancel"
      firstButtonAction={() => closeModal(false)}
      secondButtonText="Update"
      secondButtonAction={() => {
        let { amount } = values;
        if (typeof amount === 'string') {
          amount = +parseFloat((amount as string).replace(/,/g, '')).toFixed(2);
        }
        const newData: BulkDataT = {
          ...editData,
          reference: values.reference,
          mobile_money: {
            account_name: values?.account_name,
            mobile_number: `${countryMobileCode[currency]}${values?.mobile_number}`,
            operator_slug: values?.operator
          },
          amount,
          narration: values.narration,
          customer: {
            email: values.email,
            name: withdrawalDetails.username
          }
        };
        actionBtn(newData);
      }}
      completedHeading="Entry updated"
      completedDescription="The entry/item you selected has been updated."
      secondButtonDisable={!canContinue}
      visible={confirmModal}
    />
  );
};

export default MobileMoneyEditModal;
