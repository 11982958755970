/* eslint-disable no-unused-vars */
import { useFetch, useSend } from '+hooks';
import { ISendResponse } from '+types';

export const MobileMoneyServices = {
  useNetworkOperatorAvailability: ({
    onSuccess,
    onError,
    bannerLevel,
    showErrorMessage
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    showErrorMessage?: boolean;
  }) =>
    useSend({
      url: '/transactions/payouts/availability',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      showErrorMessage
    }),

  useFetchMobileMoneyNetwork: ({
    onSuccess,
    bannerLevel,
    enabled = true,
    code,
    refetchOnCloseFeedbackError,
    errorMessage
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    enabled?: boolean;
    code?: string;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
  }) => {
    const url = code ? `/misc//mobile-money?countryCode=${code}` : `/misc//mobile-money`;
    return useFetch(url, {
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      enabled,
      refetchOnCloseFeedbackError,
      errorMessage,
      useEnvironmentHandler: 'live',
      customBaseURL: process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE
    });
  }
};
