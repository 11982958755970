import CurrencyPicker from '+containers/Dashboard/Shared/CurrencyPicker';
import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceholder';
import ProductFeatureDropdown from '+containers/Dashboard/Shared/ProductFeatureDropdown';
import { useSearchQuery } from '+hooks';
import { KYCServices } from '+services/kyc-services';
import useStore from '+store';

import './index.scss';

const Limits = () => {
  const searchQuery = useSearchQuery();
  const activeCurrency = searchQuery.value.currency ?? 'NGN';
  const setActiveCurrency = (currency: string) => searchQuery.setQuery({ currency });

  const availableCurrencies = useStore(state => state.availableCurrencies);

  const EnabledCurrency = availableCurrencies;

  const { data: merchantLimit, isLoading } = KYCServices.useGetMerchantTieringData({
    bannerLevel: true
  });

  return (
    <div className="limit-tab-wrapper">
      <div className="tier-heading-box">
        <div className="fees-heading-content">
          <h5 className="form-header">Limit & Product Access</h5>

          <div className="form-desc" style={{ border: 'none', marginBottom: '0', maxWidth: '24rem' }}>
            See maximum and minimum limits for available product features and how they apply to your organization.{' '}
          </div>
        </div>
        <div className="tier-currencies">
          <CurrencyPicker
            onChange={value => {
              setActiveCurrency(value);
            }}
            options={EnabledCurrency}
            activeCurrency={activeCurrency}
            id="merchant-fees__currency-switch"
            allowedOptions={['NGN']}
            showDropDown={false}
            className="reverse-margin"
          />
          <p>Toggle the switch above to view limits for other currencies.</p>
        </div>
      </div>
      <div className="os-tabs-controls os-tabs-complex mx-0">
        <ul className="nav nav-tabs px-0 mb-2" />
      </div>
      <section>
        <div className="product-access mt-3 ">
          {/* <div className="col-md-12"> */}
          {isLoading && <LoadingPlaceholder type="table" content={3} />}
          {!isLoading && !merchantLimit?.data && <EmptyStateComponent message="Product Limit Not Available" heading={undefined} />}
          {!isLoading && merchantLimit?.data && <ProductFeatureDropdown details={merchantLimit?.data} currency={activeCurrency} />}
          {/* </div> */}
        </div>
      </section>
    </div>
  );
};

export default Limits;
