import pendingCircles from '+assets/img/dashboard/pending-circles.gif';
import { BanksType, CardBrandType, Channel } from '+types';

import discoverLogo from '+assets/img/dashboard/discover.png';
import bankIcon from '+assets/img/dashboard/bank-icon.svg';
import FidelityBank from '+assets/img/dashboard/fidelityLogo.svg';
import GTBank from '+assets/img/dashboard/gtbank.svg';
import Kudabank from '+assets/img/dashboard/kudabank.svg';
import maestroLogo from '+assets/img/dashboard/maestro.svg';
import mastercardLogo from '+assets/img/dashboard/mastercard.svg';
import verveLogo from '+assets/img/dashboard/verve.svg';
import visaLogo from '+assets/img/dashboard/visa.svg';
import Wema from '+assets/img/dashboard/wema.svg';
import Zenith from '+assets/img/dashboard/zenith.svg';

export const switchCard: Record<CardBrandType, string> = {
  mastercard: mastercardLogo,
  verve: verveLogo,
  visa: visaLogo,
  maestro: maestroLogo,
  discover: discoverLogo
};

export const switchBank = (bank: BanksType) => {
  switch (bank) {
    case 'kuda-mfb':
      return Kudabank;
    case 'zenith':
      return Zenith;
    case 'wema':
      return Wema;
    case 'gtbank':
      return GTBank;
    case 'Fidelity Bank':
    case 'fidelity':
      return FidelityBank;
    default:
      return bankIcon;
  }
};

export const switchChannel = (channel: Channel): string => {
  switch (channel) {
    case 'api':
      return 'API';
    case 'modal':
      return 'Kora Checkout';
    case 'web':
      return 'Kora Dashboard';
    case 'bank_transfer':
      return 'Bank Transfer';
    case 'virtual_bank_account':
      return 'Virtual Bank Account';
    case 'card':
      return 'Card';
    case 'reserved_bank_account':
      return 'Reserved Bank Account';
    case 'disbursement_wallet':
      return 'Disbursement Wallet';
    case 'pay_with_bank':
      return 'Pay with Bank';
    case 'mobile_money':
      return 'Mobile Money';
    default:
      return 'Not Available';
  }
};

export const switchReason = (status: string) => {
  switch (status) {
    case 'failed':
      return 'Failure';
    case 'expired':
      return 'Expiry';
    default:
      return status;
  }
};

export const switchTrxnMessage: Record<string, any> = {
  success: { name: `Transaction successful`, color: '#24B314', backgroundColor: '#e4fff1' },
  processing: { name: 'Transaction in progress...', color: '#FA9500', backgroundColor: '#FFF8E1' },
  requires_auth: { name: 'Transaction requires authorization', color: 'rgba(0, 0, 0, 0.5)', backgroundColor: '' },
  failed: { name: 'Transaction failed', color: '#F32345', backgroundColor: '#FFD2DA' },
  expired: { name: 'Transaction expired', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  rejected: { name: 'Transaction rejected', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  abandoned: { name: 'Transaction abandoned', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  pending: { name: 'Action Required', color: '#FA9500', backgroundColor: '#FFF8E1' },
  won: { name: 'Chargeback Won', color: '#24B314', backgroundColor: '#e4fff1' },
  accepted: { name: 'Accepted', color: '#F32345', backgroundColor: '#FFD2DA' },
  auto_accepted: { name: 'Auto Accept', color: '#F32345', backgroundColor: '#FFD2DA' },
  declined: { name: 'Declined', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  partial: { name: 'Partial', color: '#24B314', backgroundColor: '#e4fff1' },
  lost: { name: 'Lost', color: '#F32345', backgroundColor: '#FFD2DA' },
  reversed: { name: 'Transaction reversed', color: '#94A7B7', backgroundColor: '#DDE2EC' },
  flagged: { name: 'Flagged', color: '#24B314', backgroundColor: '#e4fff1' },
  pre_authorized: { name: 'Pre-Authorized', color: '#6474FF', backgroundColor: '#EBEDFF' },
  void_authorization: { name: 'Voided (Auth)', color: '#3E4B5B', backgroundColor: '#F1F6FA' },
  void_capture: { name: 'Voided (Capture)', color: '#3E4B5B', backgroundColor: '#F1F6FA' }
};

export const switchDisputeStatus = (status: string) => {
  switch (status) {
    case 'success':
      return { name: 'Successful', color: '#24B314', icon: '✓' };
    case 'manual':
      return { name: 'Manual', color: '#24B314', icon: '✓' };
    case 'pending':
    case 'processing':
      return {
        name: status === 'pending' ? 'Pending' : 'Processing...',
        color: 'rgba(16,38,73,.4)',
        icon: <img src={pendingCircles} alt="" id="pending-circles" />
      };
    case 'failed':
      return { name: 'Failed', color: '#F32345', icon: '✗' };
    case 'partially_paid':
      return { name: 'Partially Paid', color: '#24B314' };
    case 'fully_paid':
      return { name: 'Paid', color: '#24B314' };
    case 'accepted':
      return { name: 'Accepted', color: '#24b314' };
    case 'partially_accepted':
      return { name: 'Accepted (Partially)', color: '#48ceb0' };
    case 'invalid':
      return { name: 'Invalid', color: '#94a7b7' };
    case 'declined':
      return { name: 'Declined', color: '#f32345' };
    case 'pending_pre_arbitration':
      return { name: 'Pre-arbitration Pending', color: '#ffb447' };
    case 'processing_pre_arbitration':
      return { name: 'Pre-arbitration Processing ', color: '#fa9500' };
    case 'accepted_pre_arbitration':
      return { name: 'Pre-arbitration Accepted', color: '#24b314' };
    case 'partially_accepted_pre_arbitration':
      return { name: 'Pre-arbitration Accepted (Partially)', color: '#24b314' };
    case 'delayed_pre_arbitration':
      return { name: 'Pre-arbitration Delayed', color: '#f32345' };
    case 'invalid_pre_arbitration':
      return { name: 'Pre-arbitration Invalid', color: '#94a7b7' };

    case 'declined_pre_arbitration':
      return { name: 'Pre-arbitration Declined', color: '#f32345' };
    case 'accepted_arbitration':
      return { name: 'Arbitration Accepted', color: '#f32345' };
    case 'declined_arbitration':
      return { name: 'Arbitration Declined', color: '#f32345' };
    default:
      return { name: 'Not Available', color: '#94a7b7' };
  }
};

export const switchWebhookResponseCode = (code: number) => {
  if (code === 200) {
    return {
      color: '#24B314',
      backgroundColor: '#24B31426'
    };
  }

  return {
    color: '#F32345',
    backgroundColor: '#FFD2DA'
  };
};

export const switchWebhookResponseMessage = (status: string) => {
  switch (status) {
    case 'delivered':
      return 'Webhook notification successfully sent to;';
    case 'processing':
      return 'Sending webhook notification to';
    case 'pending':
      return 'Sending webhook notification to';
    default:
      return 'Webhook notification failed';
  }
};
