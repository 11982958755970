;
/* eslint-disable no-unused-vars */
import { AxiosResponse } from 'axios';



import { useFetch, useSend } from '+hooks';
import { ConversionResultType, ISendResponse } from '+types';





export const SwapWalletConversionService = {
  useInitiateConversion: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    showSuccessMessage
  }: {
    onSuccess?: (data: ISendResponse<ConversionResultType['data']>) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    errorMessage?: string;
    showSuccessMessage?: boolean;
  }) =>
    useSend<unknown, ConversionResultType['data']>({
      url: '/conversions/rates',
      method: 'post',
      onSuccess,
      onError,
      showSuccessMessage,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      errorMessage
    }),
  useProcessConversion: ({
    onSuccess,
    onError,
    bannerLevel,
    showErrorMessage,
    reInvalidate
  }: {
    onSuccess?: (data: ISendResponse<unknown>) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    showErrorMessage?: boolean;
    reInvalidate?: boolean;
  }) =>
    useSend({
      url: '/conversions',
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      reInvalidate,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      showErrorMessage
    }),

  useGetConversions: ({
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params
  }: {
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params?: { page: string; limit: string; status?: string[] };
  }) =>
    useFetch(`/conversions`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useExportConversions: ({
    onError,
    onSuccess,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    enabled,
    successMessage,
    errorMessage
  }: {
    onError?: (e: Error) => void;
    onSuccess?: (res: AxiosResponse<unknown>) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: { format: string; fieldsToExport: string; sortingParams?: { status: string[] } };
    enabled?: boolean;
    successMessage?: string;
    errorMessage?: string;
  }) => {
    const { format, fieldsToExport, sortingParams } = params;
    return useFetch(`/conversions`, {
      onError,
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: { format, ...sortingParams, 'fieldsToExport[]': fieldsToExport },
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      enabled,
      successMessage,
      errorMessage,
      showSuccessMessage: true,
      showReturnDefaultResponse: true
    });
  },

  useGetSingleConversions: ({
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    id
  }: {
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    id: string;
  }) =>
    useFetch(`/conversions/${id}`, {
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError
    })
};
