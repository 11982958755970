import { capitalizeRemovedash, formatAmount, getDate, getTime, history } from '+utils';

import { AllTransactionsResponseType } from '../../types';

const BillingHistoryRow = ({ rowData }: { rowData: Array<AllTransactionsResponseType> }) => {
  const handleClick = (reference: string) => history.push(`/dashboard/issuing/billing-history/${reference}`);

  return rowData?.map?.(tx => {
    return (
      <div
        key={`transaction_${tx.reference}`}
        className="div-table --history-table --row cards-transactions-row"
        role="button"
        tabIndex={0}
        onClick={() => handleClick(tx.reference)}
        onKeyUp={e => {
          if (e.key === 'Enter') e.preventDefault();
          handleClick(tx.reference);
        }}
      >
        <div className="--txn-status --column d-flex">
          <span className="body-row-header">Status:</span>
          <span className={`status-indicator ${tx.status === 'pre-authorized' ? 'warn' : tx.status}`} />
          <span>{capitalizeRemovedash(tx.status)}</span>
        </div>
        <div className="--column">
          <span className="body-row-header">Invoice reference:</span>
          <span className="text-uppercase font-weight-500" style={{ color: '#007bff' }}>
            {tx.reference}
          </span>
        </div>
        <div className="--column">
          <span className="body-row-header">Fee Type:</span>
          <span>{capitalizeRemovedash(tx.type)}</span>
        </div>
        <div className="--column">
          <span className="body-row-header">Date and Time:</span>
          <span>
            <strong>{getDate(tx.created_at)}</strong> {getTime(tx.created_at)}
          </span>
        </div>
        <div className="--column">
          <span className="body-row-header">Amount:</span>
          <span>
            <span className="font-weight-500">{formatAmount(tx.amount)}</span>
            <span className="ml-1 d-inline">{tx.currency}</span>
          </span>
        </div>
      </div>
    );
  });
};

export default BillingHistoryRow;
