import { useParams } from 'react-router-dom';

import { SwapWalletConversionService } from '+services/swapwallet-conversion-services';

import { generateMoreDetailFrom, generateSummaryFrom } from '../ConversionDetails/conversionDetailsHelpers';

const useConversionDetails = () => {
  const { id } = useParams<{ id: string }>();

  const { data: response, isLoading } = SwapWalletConversionService.useGetSingleConversions({
    id
  });

  const data = response?.data?.data;

  const recipientInfo = generateSummaryFrom(data);
  const moreTransactionDetails = generateMoreDetailFrom({ data });

  return {
    isLoading,
    data,
    recipientInfo,
    moreTransactionDetails
  };
};

export default useConversionDetails;
