/* eslint-disable no-unused-vars */
import { useReducer, useState } from 'react';
import { AxiosError } from 'axios';

import CategoryMenu from '+containers/Dashboard/Shared/CategoryMenu';
import { useFeedbackHandler, useReducerState } from '+hooks';
import { EmailConfigurationServices } from '+services/email-config-services';
import { TeamsRoleServices } from '+services/teams-roles-services';
import useStore from '+store';
import {
  ICreateEmailConfigurationSlice,
  IEmailCategory,
  IEmailConfigState,
  IEmailSubscriptionState,
  ISubscriptionVariable,
  TeamMemberType
} from '+types';

import ToolTip from '../../Shared/Tooltip';
import ConfigurationForm from './components/ConfigurationForm';
import ConfigurationFormLoader from './components/ConfigurationFormLoader';
import SubscribeConfiguration from './components/SubscribeConfiguration';

// eslint-disable-next-line import/order
import InfoIcon from '+assets/img/dashboard/information-button.svg';

import './index.scss';

interface IStore {
  profile: any;
  defaultMerchant: any;
  merchantEnv: string;
  issuanceAccessRequested: boolean;
}

function EmailConfiguration() {
  const { feedbackInit } = useFeedbackHandler();
  const profile = useStore(store => (store as IStore).profile);
  const defaultMerchant = useStore(store => (store as IStore).defaultMerchant);

  const { setSaveFn, setModalStage, setIsCompleteAfterSave, setAdditionalContentModified, setShowEmailConfigModal } = useStore(
    store => store as ICreateEmailConfigurationSlice
  );

  const [teamMembers, setTeamMembers] = useState<Array<TeamMemberType>>([]);
  const presentUser = teamMembers?.find(member => profile?.email === member?.email);

  const canPerformEmailConfig =
    presentUser?.userRole?.category === 'system' && ['admin', 'owner'].includes(presentUser?.userRole?.slug?.toLowerCase());

  const reducer = (prev: IEmailConfigState, next: Partial<IEmailConfigState>): IEmailConfigState => ({ ...prev, ...next });

  const initialState: IEmailConfigState = {
    route: 'Marketing & Product Updates',
    isLoading: true,
    inputs: {
      payouts_withdrawals: '',
      payout_api: '',
      pay_ins: {
        all_payins: '',
        other_payin_emails: ''
      },
      support: {
        payout: '',
        payin: ''
      },
      refunds_reversals: '',
      chargebacks: '',
      card_issuance: '',
      marketing: ''
    },
    options: {
      payout_api: {
        api_bulk_payout_initiation: false,
        api_bulk_payout_completion: false,
        api_single_payout_completion: false,
        api_bulk_payout_summary: false,
        api_payouts: false
      },
      pay_ins: {
        all_payins: false
      },
      support: {
        payout: false,
        payin: false
      },
      refunds_reversals: {
        requested_refund: false
      },
      chargebacks: {
        chargeback_escalation: false
      },
      card_issuance: {
        auto_funding: false,
        balance_funding: false,
        transaction_limits: false,
        virtual_card_termination: false
      },
      marketing: {
        marketing_product_updates: false
      },
      payouts_withdrawals: {
        payout_withdrawal: false
      }
    },
    feedback: {
      message: '',
      visible: false,
      type: 'danger'
    }
  };

  const [state, updateState] = useReducer(reducer, initialState);
  const [subscriptionState, updateSubscriptionState] = useReducerState<IEmailSubscriptionState>({
    email_recipients: [],
    interval: 'weekly',
    enabled: false,
    intervalSelected: false,
    emailModified: false,
    continueSubscription: false
  });

  const configureState = (result: any) => {
    updateState({
      inputs: {
        ...state.inputs,
        payouts_withdrawals:
          result?.configuration?.payout?.dashboard_single_payout_completion?.email_recipients?.join(', ') || defaultMerchant?.email || '',
        payout_api: result?.configuration?.payout?.api_bulk_payout_initiation?.email_recipients?.join(', ') || defaultMerchant?.email || '',
        pay_ins: {
          all_payins: result?.configuration?.payin?.all_payins?.email_recipients?.join(', ') || defaultMerchant?.email || '',
          other_payin_emails: result?.configuration?.payin?.other_payin_emails?.email_recipients?.join(', ') || defaultMerchant?.email || ''
        },
        support: {
          payout: result?.configuration?.payout?.support_emails?.join(', ') || defaultMerchant?.email || '',
          payin: result?.configuration?.payin?.support_emails?.join(', ') || defaultMerchant?.email || ''
        },
        refunds_reversals: result?.configuration?.dispute?.refund?.email_recipients?.join(', ') || defaultMerchant?.email || '',
        chargebacks: result?.configuration?.dispute?.chargeback?.email_recipients?.join(', ') || defaultMerchant?.email || '',
        card_issuance:
          result?.configuration?.card_issuance?.issuing_balance_funding?.email_recipients?.join(', ') || defaultMerchant?.email || '',
        marketing: result?.configuration?.product_and_marketing?.email_recipients?.join(', ') || defaultMerchant?.email || ''
      },
      options: {
        ...state.options,
        payout_api: {
          api_bulk_payout_initiation: result?.configuration?.payout?.api_bulk_payout_initiation?.enabled || false,
          api_single_payout_completion: result?.configuration?.payout?.api_single_payout_completion?.enabled || false,
          api_bulk_payout_completion: result?.configuration?.payout?.api_bulk_payout_completion?.enabled || false,
          api_bulk_payout_summary: result?.configuration?.payout?.dashboard_single_payout_completion?.enabled || false,
          api_payouts: result?.configuration?.payout?.api_payouts?.enabled || false
        },
        pay_ins: {
          all_payins: result?.configuration?.payin?.all_payins?.enabled || false
        },
        support: {
          payout: result?.configuration?.payout?.support_emails?.enabled || false,
          payin: result?.configuration?.payin?.support_emails?.enabled || false
        },
        refunds_reversals: {
          requested_refund: result?.configuration?.dispute?.refund?.enabled || false
        },
        chargebacks: {
          chargeback_escalation: result?.configuration?.dispute?.chargeback?.enabled || false
        },
        card_issuance: {
          auto_funding: result?.configuration?.card_issuance?.issuing_balance_funding?.enabled || false,
          balance_funding: result?.configuration?.card_issuance?.issuing_balance_funding?.enabled || false,
          transaction_limits: result?.configuration?.card_issuance?.transaction_limits?.enabled || false,
          virtual_card_termination: result?.configuration?.card_issuance?.virtual_card_termination?.enabled || false
        },
        marketing: {
          marketing_product_updates: result?.configuration?.product_and_marketing?.enabled || false
        },
        payouts_withdrawals: {
          payout_withdrawal: result?.configuration?.payout?.dashboard_single_payout_completion?.enabled || false
        }
      }
    });
  };

  const {
    refetch: refetchConfig,
    data,
    isFetching
  } = EmailConfigurationServices.useGetConfigurations({
    showErrorMessage: true,
    onSuccess: result => {
      configureState(result?.data);
      updateState({ isLoading: false });
    },
    onError: err => {
      const e = err as AxiosError<{ message?: string }>;
      configureState({});
      updateState({ isLoading: false });
      const error = e?.response?.data as { message: string };
      if (e?.response?.status === 404) return;
      feedbackInit({
        message: 'There has been an error fetching your Email configuration',
        type: 'danger',
        action: {
          action: () => refetchConfig(),
          name: 'Try again'
        }
      } as any);
    }
  });
  const { isFetching: loadingTeamMembers } = TeamsRoleServices.useFetchTeamMembers({
    onSuccess: allMembers => {
      const owners = allMembers?.data?.filter((member: TeamMemberType) => member?.userRole?.name === 'Owner');
      const others = allMembers?.data?.filter((member: TeamMemberType) => member?.userRole?.name !== 'Owner');
      setTeamMembers([...owners, ...others]);
    },
    errorMessage: 'There has been an error getting team member(s) details'
  });

  const emailMenu: string[] = [
    'Marketing & Product Updates',
    'Payout Withdrawals',
    'Payouts API',
    'Pay-ins',
    'Support',
    'Refunds & Reversals',
    'Chargebacks',
    'Card Issuance'
  ];

  const onUnmountEmailSubscription = () => {
    setSaveFn(async (interval: string) => {
      await updateSubscribeEmailConfig({
        interval,
        email_recipients: subscriptionState.email_recipients,
        enabled: true
      });
    });
    setIsCompleteAfterSave(true);
    setModalStage('email-subscription-interval');
    setShowEmailConfigModal(true);
  };

  const { isLoading: marketingLoading, mutateAsync: updateMarketing } = EmailConfigurationServices.useSetMarketingEmailConfig({
    errorMessage: 'Something went wrong, please try again'
  });

  const { isLoading: payoutLoading, mutateAsync: updatePayout } = EmailConfigurationServices.useSetPayoutEmailConfig({});

  const { isLoading: payinLoading, mutateAsync: updatePayin } = EmailConfigurationServices.useSetPayinEmailConfig({
    errorMessage: 'Something went wrong, please try again'
  });
  const { isLoading: disputeLoading, mutateAsync: updateDispute } = EmailConfigurationServices.useSetDisputeEmailConfig({
    errorMessage: 'Something went wrong, please try again'
  });

  const { isLoading: cardIssuanceLoading, mutateAsync: updateCardIssuance } = EmailConfigurationServices.useSetCardIssuanceEmailConfig({
    onSuccess: () => {
      updateState({
        feedback: {
          type: 'success',
          message: 'You have successfully changed your email',
          visible: true
        }
      });
    },
    errorMessage: 'Something went wrong, please try again'
  });

  const { isLoading: subscribingEmailConfigLoading, mutateAsync: updateSubscribeEmailConfigAsync } =
    EmailConfigurationServices.useSetSubscribeEmailConfig({
      onSuccess: () => {
        updateSubscriptionState({ intervalSelected: false, emailModified: false, continueSubscription: false });
      },
      errorMessage: 'Something went wrong, please try again',
      onSettled: () => {
        setAdditionalContentModified(false);
      }
    });
  const updateSubscribeEmailConfig = async (variable: ISubscriptionVariable) =>
    await updateSubscribeEmailConfigAsync({ ...variable, email_recipients: variable.email_recipients.map(email => email.trim()) });

  const { isLoading: supportLoading, mutateAsync: updateSupport } = EmailConfigurationServices.useSetSupportEmailConfig({});

  const disableMarketingCategoriesButton =
    data?.configuration?.product_and_marketing?.email_recipients?.join(', ') === state.inputs.marketing ||
    (data?.configuration?.product_and_marketing?.email_recipients?.join(', ')
      ? data?.configuration?.product_and_marketing?.email_recipients?.join(', ') === state.inputs.marketing
      : defaultMerchant?.email === state.inputs.marketing);

  const EmailCategories: { [key: string]: IEmailCategory } = {
    marketing: {
      category: 'Marketing & Product Updates',
      title: 'Marketing & Product Updates',
      description: 'News about product and feature updates',
      text: 'Feature releases, tips, announcements and beta programs',
      additionalContent: (
        <SubscribeConfiguration
          isAllowed={canPerformEmailConfig}
          subscribingEmailConfigLoading={subscribingEmailConfigLoading}
          updateSubscriptionState={updateSubscriptionState}
          subscriptionState={subscriptionState}
          updateSubscribeEmailConfig={(variables: ISubscriptionVariable) => updateSubscribeEmailConfig(variables)}
        />
      ),
      isLoading: marketingLoading,
      disableButton: !subscriptionState.intervalSelected && disableMarketingCategoriesButton && !subscriptionState.emailModified,
      options: null,
      hideSuccessAfterSave: subscriptionState.continueSubscription,
      onSave: async () => {
        const { intervalSelected, emailModified, continueSubscription, ...subscriptionVariables } = subscriptionState;
        if (!disableMarketingCategoriesButton)
          await updateMarketing({
            email_recipients: state.inputs.marketing.split(', ')
          } as any);
        if (!canPerformEmailConfig) return;
        if (!continueSubscription && (intervalSelected || emailModified)) {
          updateSubscriptionState({ intervalSelected: false, emailModified: false });
          await updateSubscribeEmailConfig(subscriptionVariables);
        }
        if (continueSubscription) onUnmountEmailSubscription();
      },
      onTextChange: (value: string) => {
        updateState({ inputs: { ...state.inputs, marketing: value } });
      },
      forms: [
        {
          tooltip_note:
            'Marketing emails will be sent to the email address(es) below.  You can add up to Four (4) email addresses, add multiple emails by separating them with a comma.',
          formHeading: 'Where should Kora send marketing emails?',
          value: state.inputs.marketing,
          emailLimit: 4
        }
      ]
    },
    payouts_withdrawals: {
      category: 'Payout Withdrawals',
      title: 'Payout Withdrawal Notifications',
      description: 'Notifications for payout withdrawals ',
      text: 'Payouts and Withdrawals via dashboard',
      isLoading: payoutLoading,
      disableButton:
        data?.configuration?.payout?.dashboard_single_payout_completion?.email_recipients?.join(', ') ===
          state.inputs.payouts_withdrawals ||
        (data?.configuration?.payout?.dashboard_single_payout_completion?.email_recipients?.join(', ')
          ? data?.configuration?.payout?.dashboard_single_payout_completion?.email_recipients?.join(', ') ===
            state.inputs.payouts_withdrawals
          : defaultMerchant?.email === state.inputs.payouts_withdrawals),
      options: null,
      onSave: async () => {
        await updatePayout({
          type: 'payout_via_dashboard',
          category: {
            dashboard_single_payout_completion: {
              email_recipients: state.inputs.payouts_withdrawals.split(', ')
            }
          }
        } as any);
      },
      onTextChange: (value: string) => {
        updateState({ inputs: { ...state.inputs, payouts_withdrawals: value } });
      },
      forms: [
        {
          tooltip_note:
            'Payout withdrawal emails will be sent to the email address(es) below. You can add up to Two (2) email addresses, add multiple emails by separating them with a comma.',
          formHeading: 'Where should Kora send payout withdrawal emails?',
          value: state.inputs.payouts_withdrawals,
          emailLimit: 2
        }
      ]
    },
    payout_api: {
      category: 'Payouts API',
      title: 'Payouts API Notifications',
      description: 'Notifications around payouts and bulk payouts via API',
      text: null,
      isLoading: payoutLoading,
      disableButton:
        (data?.configuration?.payout?.api_bulk_payout_initiation?.email_recipients?.join(', ')
          ? data?.configuration?.payout?.api_bulk_payout_initiation?.email_recipients?.join(', ') === state.inputs.payout_api
          : state.inputs.payout_api === defaultMerchant?.email) &&
        state.options.payout_api.api_bulk_payout_initiation === !!data?.configuration?.payout?.api_bulk_payout_initiation?.enabled &&
        state.options.payout_api.api_bulk_payout_completion === !!data?.configuration?.payout?.api_bulk_payout_completion?.enabled &&
        state.options.payout_api.api_single_payout_completion === !!data?.configuration?.payout?.api_single_payout_completion?.enabled,
      onSave: async () => {
        await updatePayout({
          type: 'payout_via_api',
          category: {
            api_bulk_payout_initiation: {
              enabled: state.options.payout_api.api_bulk_payout_initiation,
              email_recipients: state.inputs.payout_api.split(', ')
            },
            api_single_payout_completion: {
              enabled: state.options.payout_api.api_single_payout_completion,
              email_recipients: state.inputs.payout_api.split(', ')
            },
            api_bulk_payout_completion: {
              enabled: state.options.payout_api.api_bulk_payout_completion,
              email_recipients: state.inputs.payout_api.split(', ')
            }
          }
        } as any);
      },
      onTextChange: (value: string) => {
        updateState({ inputs: { ...state.inputs, payout_api: value } });
      },
      onCheckBoxChange: (value: boolean, name: string) => {
        updateState({ options: { ...state.options, payout_api: { ...state.options.payout_api, [name]: value } } });
      },
      options: [
        {
          value: state.options.payout_api.api_bulk_payout_initiation,
          option: 'Bulk payout initiation',
          name: 'api_bulk_payout_initiation'
        },
        {
          value: state.options.payout_api.api_bulk_payout_completion,
          option: 'Bulk payout completion',
          name: 'api_bulk_payout_completion'
        },
        {
          value: state.options.payout_api.api_single_payout_completion,
          option: 'Single payout completion',
          name: 'api_single_payout_completion'
        }
      ],
      forms: [
        {
          tooltip_note: 'Payout API notifications will be sent to the email address below.',
          formHeading: 'Where should Kora send emails about payouts via API?',
          value: state.inputs.payout_api,
          emailLimit: 1
        }
      ]
    },
    pay_ins: {
      category: 'Pay-ins',
      title: 'Pay-in Notifications',
      description: 'Notifications for pay-in transactions on the following:',
      text: 'Balance top-up and funding receipts',
      isLoading: payinLoading,
      options: null,
      disableButton:
        (data?.configuration?.payin?.all_payins?.email_recipients?.join(', ')
          ? data?.configuration?.payin?.all_payins?.email_recipients?.join(', ') === state.inputs.pay_ins.all_payins
          : state.inputs.pay_ins.all_payins === defaultMerchant?.email) &&
        (data?.configuration?.payin?.other_payin_emails?.email_recipients?.join(', ')
          ? data?.configuration?.payin?.other_payin_emails?.email_recipients?.join(', ') === state.inputs.pay_ins.other_payin_emails
          : state.inputs.pay_ins.other_payin_emails === defaultMerchant?.email) &&
        state.options.pay_ins.all_payins === !!data?.configuration?.payin?.all_payins?.enabled,
      onSave: async () => {
        await updatePayin({
          category: {
            other_payin_emails: {
              enabled: state.options.pay_ins.all_payins,
              email_recipients: state.inputs.pay_ins.other_payin_emails.split(', ')
            },
            all_payins: {
              email_recipients: state.inputs.pay_ins.all_payins.split(', ')
            }
          }
        } as any);
      },
      onTextChange: (value: string, name) => {
        updateState({ inputs: { ...state.inputs, pay_ins: { ...state.inputs.pay_ins, [name || '']: value } } });
      },
      onCheckBoxChange: (value: boolean, name: string) => {
        updateState({ options: { ...state.options, pay_ins: { ...state.options.pay_ins, [name]: value } } });
      },
      forms: [
        {
          tooltip_note:
            'Balance top-up and funding emails will be sent to the email address(es) below. You can add up to Two (2) email addresses, add multiple emails by separating them with a comma.',
          formHeading: 'Where should Kora send balance top-up and funding emails?',
          value: state.inputs.pay_ins.all_payins,
          name: 'all_payins',
          emailLimit: 2
        },
        {
          tooltip_note: 'Pay-in emails will be sent to the email address below. ',
          formHeading: 'Where should Kora send other pay-in emails?',
          value: state.inputs.pay_ins.other_payin_emails,
          name: 'other_payin_emails',
          emailLimit: 1
        }
      ]
    },
    support: {
      category: 'Support',
      title: 'Support Email',
      description: 'Add your preferred support email address(es) to be included in receipts sent to your customers',
      text: null,
      isLoading: supportLoading,
      disableButton:
        (!data?.configuration?.payin?.support_emails?.join(', ') &&
          state.inputs.support.payin === defaultMerchant?.email &&
          !data?.configuration?.payout?.support_emails?.join(', ') &&
          state.inputs.support.payout === defaultMerchant?.email) ||
        (data?.configuration?.payin?.support_emails?.join(', ') === state.inputs.support.payin &&
          data?.configuration?.payout?.support_emails?.join(', ') === state.inputs.support.payout),
      onSave: async () => {
        await updateSupport({
          category: {
            payin: {
              support_emails: state.inputs.support.payin.split(', ')
            },
            payout: {
              support_emails: state.inputs.support.payout.split(', ')
            }
          }
        } as any);
      },
      onTextChange: (value: string, name) => {
        updateState({ inputs: { ...state.inputs, support: { ...state.inputs.support, [name || '']: value } } });
      },
      options: null,
      forms: [
        {
          formHeading: 'Pay-in support email address',
          tooltip_note:
            'Pay-in emails sent to your customers will display the email address provided below as support email. You can add up to two (2) email addresses, add multiple emails by separating them with a comma.',
          value: state.inputs.support.payin,
          name: 'payin',
          emailLimit: 2
        },
        {
          formHeading: 'Payout support email address',
          tooltip_note:
            'Payouts emails sent to your customers will display the email address provided below as support email. You can add up to two (2) email addresses, add multiple emails by separating them with a comma.',
          value: state.inputs.support.payout,
          name: 'payout',
          emailLimit: 2
        }
      ]
    },
    refunds_reversals: {
      category: 'Refunds & Reversals',
      title: 'Refund & Reversal Notifications',
      description: 'Notifications for dispute notification around refunds and reversals',
      text: null,
      isLoading: disputeLoading,
      disableButton:
        (data?.configuration?.dispute?.refund?.email_recipients?.join(', ')
          ? data?.configuration?.dispute?.refund?.email_recipients?.join(', ') === state.inputs.refunds_reversals
          : state.inputs.refunds_reversals === defaultMerchant?.email) &&
        state.options.refunds_reversals.requested_refund === !!data?.configuration?.dispute?.refund?.enabled,

      onSave: async () => {
        await updateDispute({
          type: 'refund',
          email_recipients: state.inputs.refunds_reversals.split(', '),
          enabled: state.options.refunds_reversals.requested_refund
        } as any);
      },
      onTextChange: (value: string) => {
        updateState({ inputs: { ...state.inputs, refunds_reversals: value } });
      },
      onCheckBoxChange: (value: boolean, name: string) => {
        updateState({ options: { ...state.options, refunds_reversals: { ...state.options.refunds_reversals, [name]: value } } });
      },
      options: [
        { value: state.options.refunds_reversals.requested_refund, option: 'Requested refund and updates ', name: 'requested_refund' }
      ],
      forms: [
        {
          tooltip_note:
            'Refund & Reversal notifications will be sent to the email address(es) below. You can add up to two (2) email addresses, add multiple emails by separating them with a comma.',
          formHeading: 'Where should Kora send refunds and reversal emails?',
          value: state.inputs.refunds_reversals,
          name: 'refunds_reversals',
          emailLimit: 2
        }
      ]
    },
    chargebacks: {
      category: 'Chargebacks',
      title: 'Chargeback Notifications',
      description: 'Notifications around reconciliation and chargebacks for transactions on this account',
      text: 'Chargeback escalation and updates',
      isLoading: disputeLoading,
      disableButton:
        data?.configuration?.dispute?.chargeback?.email_recipients?.join(', ') === state.inputs.chargebacks ||
        (data?.configuration?.dispute?.chargeback?.email_recipients?.join(', ')
          ? data?.configuration?.dispute?.chargeback?.email_recipients?.join(', ') === state.inputs.chargebacks
          : defaultMerchant?.email === state.inputs.chargebacks),
      onSave: async () => {
        await updateDispute({
          type: 'chargeback',
          email_recipients: state.inputs.chargebacks.split(', ')
        } as any);
      },
      onTextChange: (value: string) => {
        updateState({ inputs: { ...state.inputs, chargebacks: value } });
      },
      options: null,
      forms: [
        {
          tooltip_note:
            'Chargeback emails will be sent to the email address(es) below. You can add up to four (4) email addresses, add multiple emails by separating them with a comma.',
          formHeading: 'Where should Kora send chargeback emails?',
          value: state.inputs.chargebacks,
          name: 'chargebacks',
          emailLimit: 4
        }
      ]
    },
    card_issuance: {
      category: 'Card Issuance',
      title: 'Card Issuance',
      description: 'Notifications for card issuance on the following:',
      text: null,
      isLoading: cardIssuanceLoading,
      disableButton:
        (data?.configuration?.card_issuance?.issuing_balance_funding?.email_recipients?.join(', ')
          ? data?.configuration?.card_issuance?.issuing_balance_funding?.email_recipients?.join(', ') === state.inputs.card_issuance
          : state.inputs.card_issuance === defaultMerchant?.email) &&
        state.options.card_issuance.auto_funding === !!data?.configuration?.card_issuance?.issuing_balance_funding?.enabled &&
        state.options.card_issuance.balance_funding === !!data?.configuration?.card_issuance?.issuing_balance_funding?.enabled &&
        state.options.card_issuance.transaction_limits === !!data?.configuration?.card_issuance?.transaction_limits?.enabled &&
        state.options.card_issuance.virtual_card_termination === !!data?.configuration?.card_issuance?.virtual_card_termination?.enabled,
      onSave: async () => {
        await updateCardIssuance({
          category: {
            issuing_balance_funding: {
              enabled: state.options.card_issuance.balance_funding,
              email_recipients: state.inputs.card_issuance.split(', ')
            },
            transaction_limits: {
              enabled: state.options.card_issuance.transaction_limits,
              email_recipients: state.inputs.card_issuance.split(', ')
            },
            virtual_card_termination: {
              enabled: state.options.card_issuance.virtual_card_termination,
              email_recipients: state.inputs.card_issuance.split(', ')
            }
          }
        } as any);
      },
      onTextChange: (value: string) => {
        updateState({ inputs: { ...state.inputs, card_issuance: value } });
      },
      onCheckBoxChange: (value: boolean, name: string) => {
        updateState({ options: { ...state.options, card_issuance: { ...state.options.card_issuance, [name]: value } } });
      },
      options: [
        { value: state.options.card_issuance.auto_funding, option: 'Notification on auto-funding of issuing wallet', name: 'auto_funding' },
        { value: state.options.card_issuance.balance_funding, option: 'Issuing balance funding', name: 'balance_funding' },
        { value: state.options.card_issuance.transaction_limits, option: 'Notification on transaction limits', name: 'transaction_limits' },
        {
          value: state.options.card_issuance.virtual_card_termination,
          option: 'Virtual card termination',
          name: 'virtual_card_termination'
        }
      ],
      forms: [
        {
          tooltip_note:
            'Card issuance emails will be sent to the email address(es) below. You can add up to two (2) email addresses, add multiple emails by separating them with a comma.',
          formHeading: 'Where should Kora send card issuance emails?',
          value: state.inputs.card_issuance,
          name: 'card_issuance',
          emailLimit: 2
        }
      ]
    }
  };

  const switchConfiguration: { [key: string]: IEmailCategory } = {
    'Marketing & Product Updates': EmailCategories.marketing,
    'Payout Withdrawals': EmailCategories.payouts_withdrawals,
    'Payouts API': EmailCategories.payout_api,
    'Pay-ins': EmailCategories.pay_ins,
    Support: EmailCategories.support,
    'Refunds & Reversals': EmailCategories.refunds_reversals,
    Chargebacks: EmailCategories.chargebacks,
    'Card Issuance': EmailCategories.card_issuance
  };

  return (
    <>
      <div className="mt-4" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1 )' }}>
        <div className="row">
          <div className="col-sm-12">
            <div className="element-wrapper">
              <div className="element-box p-0">
                <h5 className="form-header">Email Notifications</h5>
                <div className="form-desc p-0">
                  Manage when and how you receive email notifications. Kora may still send <br /> important notifications to your account
                  outside your notification settings.
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 mb-2">
            <div className="form-group">
              <label htmlFor="refund-amount" className="withdraw-label">
                <span className="dark">
                  Default Email Address for Notifications
                  <ToolTip
                    image={InfoIcon}
                    type="accepted_amount"
                    message={
                      <p>
                        Your Default Email Address is the one you used to create your account. By default, all notices will be sent here.
                        You can assign other notifications to different email addresses below.
                      </p>
                    }
                  />
                </span>
              </label>
              <input value={defaultMerchant?.email} className="form-control" disabled />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4">
        <div className="category-heading">Email categories</div>
        <div className="row mt-3">
          <div className="col-md-3">
            <CategoryMenu menuOptions={emailMenu} currentRoute={state.route} setRoute={updateState} />
          </div>
          <div className="col-md-9">
            {isFetching && state.isLoading && loadingTeamMembers ? (
              <ConfigurationFormLoader />
            ) : (
              <ConfigurationForm configData={switchConfiguration[state.route]} refetch={refetchConfig} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailConfiguration;
