import { useState } from 'react';

import Copyable from '+dashboard/Shared/Copyable';
import LoadingPlaceholder from '+dashboard/Shared/LoadingPlaceholder';
import Modal from '+dashboard/Shared/Modal';
import ToolTip from '+dashboard/Shared/Tooltip';
import { WebhookServices } from '+services/webhook-services';
import { capitalizeRemovedash, getDate, getTime, switchStatus, switchWebhookResponseCode, switchWebhookResponseMessage } from '+utils';
import renderStyledJson from '+utils/render-json';

import { IWebhooksProps } from '../../types';

import copyIcon from '+assets/img/copy-new.svg';
import InfoIcon from '+assets/img/dashboard/information-button.svg';

import './index.scss';

const Webhook = ({ canRequestWebhook, trxRef, trxType, notificationRef, dataTestId = 'webhook-section' }: IWebhooksProps) => {
  const [confirmModal, setConfirmModal] = useState(false);

  const {
    data,
    refetch,
    isLoading: fetchingWebhookData,
    isError
  } = WebhookServices.useGetWebhookNotification({ ref: notificationRef, showErrorMessage: false });

  const { isLoading, mutateAsync } = WebhookServices.useResendWebhookNotification({
    onSuccess: () => void refetch()
  });

  const webhookData = data?.data;

  const copyTextFn = () => (
    <div className="copy-wrapper">
      <img src={copyIcon} alt="" /> Copy Code
    </div>
  );

  return (
    <>
      <section data-testid={dataTestId}>
        <h6 className="webhook-title section-title">
          <span>Webhook / Metadata</span>
          {!fetchingWebhookData && canRequestWebhook && (
            <div>
              <ToolTip
                image={InfoIcon}
                type="webhooks-info"
                message={
                  <em>
                    <strong>Note</strong>: Resending this webhook notification may result in double charges if not handled properly in your
                    codebase.
                  </em>
                }
              />
              <button
                disabled={fetchingWebhookData}
                aria-label="resend-webhook"
                className="btn btn-webhook"
                onClick={() => setConfirmModal(true)}
              >
                Resend Webhook <i className="os-icon os-icon-arrow-up-right ml-2" />
              </button>
            </div>
          )}
        </h6>
        {fetchingWebhookData && !isError && <LoadingPlaceholder type="text" content={4} />}
        {!fetchingWebhookData && webhookData && (
          <div className="webhooks-section">
            <div className="webhook-details">
              <p className="webhook-attempt" aria-label="last-attempt">
                Last Attempt
              </p>
              <div>
                {webhookData?.status === 'pending' ? null : (
                  <span
                    className="response-span"
                    style={{
                      color: switchWebhookResponseCode(webhookData?.response_code)?.color,
                      backgroundColor: switchWebhookResponseCode(webhookData?.response_code)?.backgroundColor
                    }}
                  >
                    {webhookData?.response_code}
                  </span>
                )}

                <span className={`status-pill status-margin smaller ${switchStatus(webhookData?.status)}`} />
                <span className="status-text">{capitalizeRemovedash(webhookData?.status || 'Not Available')}</span>
                <span className="status-pill status-margin status-smaller" />
                <span className="notification">{switchWebhookResponseMessage(webhookData?.status)}</span>
              </div>
              {['delivered', 'processing', 'pending'].includes(webhookData?.status) && (
                <div className="webhook-url" aria-label="webhook-url">
                  {' '}
                  {webhookData?.webhook_url || 'N/A'}{' '}
                </div>
              )}
              <p className="webhook-date">
                {webhookData?.webhook_date && `${getDate(webhookData?.webhook_date)}, ${getTime(webhookData?.webhook_date)}`}
              </p>

              <div data-testid="webhook-summary" className="webhook-metadata">
                <p>Summary</p>
                <p>
                  <span>Triggered At</span>
                  <span>
                    {webhookData?.webhook_date ? `${getDate(webhookData?.webhook_date)} | ${getTime(webhookData?.webhook_date)}` : 'N/A'}
                  </span>
                </p>

                <p>
                  <span>Response Code</span>
                  <span>{webhookData?.status === 'pending' ? '---' : webhookData?.response_code}</span>
                </p>
                <p>
                  <span>Retries Attempted</span>
                  <span>{webhookData?.attempts}</span>
                </p>
                <p>
                  <span>Last Attempt</span>
                  <span>
                    {webhookData?.last_attempt ? `${getDate(webhookData?.last_attempt)} | ${getTime(webhookData?.last_attempt)}` : 'N/A'}
                  </span>
                </p>
                <p>
                  <span>Type</span>
                  <span>{capitalizeRemovedash(webhookData?.type || 'N/A')}</span>
                </p>
              </div>
            </div>
            <div className="webhook-payload">
              <p className="webhook-attempt" aria-label="payload">
                Payload
              </p>
              <div className="payload-codeblock">
                <div className="payload-codeblock-heading">
                  <div className=" payload-codeblock-header justify-content-between">
                    <p className="m-0">JSON</p>
                    <div className="payload-copy-section align-items-center">
                      <Copyable
                        hideIcon
                        textModifier={copyTextFn}
                        text={JSON.stringify(webhookData?.request_payload)}
                        spanClassName="copy-wrapper"
                      />
                    </div>
                  </div>
                </div>
                <div className="code-block">
                  {webhookData?.request_payload && renderStyledJson(webhookData?.request_payload, webhookData?.request_protocol)}
                </div>
              </div>
            </div>
          </div>
        )}
        {!webhookData && !fetchingWebhookData && (
          <p className="no-refund no-webhook">
            <i>No Webhook generated for this transaction</i>
          </p>
        )}
      </section>
      {confirmModal && (
        <Modal
          size="md"
          close={() => setConfirmModal(false)}
          heading="Confirm Request"
          description={
            <p style={{ color: '#414F5F', fontWeight: 400, display: 'block' }}>
              Are you sure you want to resend webhook for <strong>{trxRef.toUpperCase()}</strong>?
            </p>
          }
          content={undefined}
          firstButtonText="Cancel"
          firstButtonAction={() => setConfirmModal(false)}
          secondButtonText="Confirm"
          secondButtonAction={async () =>
            await mutateAsync({
              notification_type: trxType,
              transaction_reference: trxRef,
              notification_reference: notificationRef
            })
          }
          secondButtonDisable={isLoading}
          completedHeading="Success!"
          completedDescription="We've received your ‘webhook resend’ request and it's currently being processed.`"
        />
      )}
    </>
  );
};

export default Webhook;
