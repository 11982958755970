import { useEffect, useState } from 'react';

import { useSearchQuery } from '+hooks';
import { capitalize, durationMonth, durationWeek, durationYear } from '+utils';

import EventsSummary from './Summary';

interface IEventSummary {
  totalVerifications: number;
  successCount: number;
  failedCount: number;
  pendingCount: number;
  kycCount: number;
  kybCount: number;
  successRate: number;
}

function IdentitySummaries({ summary, isFetching }: { summary: IEventSummary; isFetching: boolean }) {
  const [summaries, setSummaries] = useState<
    {
      title: string;
      value: number | string;
      description: string;
      cta?: { label: string; link: string };
      haveSub: boolean;
      sub?: { title: string; value: number }[];
    }[]
  >([]);
  const searchQuery = useSearchQuery();

  const duration = [
    { label: 'all time', value: '' },
    { label: 'Last 7 Days', value: durationWeek() },
    { label: 'Last 30 Days', value: durationMonth(1) },
    { label: 'Last Year ', value: durationYear() }
  ];

  const region = [
    { label: 'All Region', value: '' },
    { label: 'Nigeria', value: 'ng' },
    { label: 'Ghana', value: 'gh' },
    { label: 'Kenya', value: 'ke' },
    { label: 'South Africa', value: 'za' }
  ];

  useEffect(() => {
    setSummaries([
      {
        title: 'Total Verification',
        value: summary?.totalVerifications ?? 0,
        description: 'Total number of',
        cta: { label: 'All Checks', link: '' },
        haveSub: true,
        sub: [
          { title: 'All Checks', value: summary?.totalVerifications ?? 0 },
          { title: 'Successful Checks', value: summary?.successCount ?? 0 },
          { title: 'Failed Checks', value: summary?.failedCount ?? 0 },
          { title: 'Pending Checks', value: summary?.pendingCount ?? 0 }
        ]
      },
      {
        title: 'Total KYC Verifications',
        value: summary?.kycCount ?? 0,
        description: 'Total number of KYC checks',
        haveSub: false
      },
      {
        title: 'Total KYB Verifications',
        value: summary?.kybCount ?? 0,
        description: 'Total number of KYB checks ',
        haveSub: false
      },
      {
        title: 'Success Rate',
        value: summary?.successRate.toFixed(2) ?? 0,
        description: 'Percentage of all successful checks',
        haveSub: false
      }
    ]);
  }, [summary]);

  const handleSummarySubClicked = ({ summaryIndex, subIndex }: { summaryIndex: number; subIndex: number }) => {
    let newSummaries = [...summaries];
    if (newSummaries[summaryIndex].sub) {
      newSummaries[summaryIndex].value = newSummaries[summaryIndex].sub[subIndex].value;
      if (newSummaries[summaryIndex].cta) {
        newSummaries[summaryIndex].cta.label = newSummaries[summaryIndex].sub[subIndex].title;
      }
    }
    setSummaries(newSummaries);
  };

  return (
    <div>
      <div className="d-sm-flex justify-content-between mb-5">
        <h5>Usage Summary</h5>
        <div className="d-flex">
          <div className="element-actions mr-3">
            <form className="form-inline justify-content-sm-end">
              <select
                aria-label="duration-selector"
                className="form-control form-control-sm rounded"
                onChange={e => searchQuery.setQuery({ country: e.target.value }, false)}
                value={searchQuery.value.country || ''}
              >
                {region.map(tab => (
                  <option value={tab.value} key={tab.label}>
                    {capitalize(tab.label)}
                  </option>
                ))}
              </select>
            </form>
          </div>
          <div className="element-actions">
            <form className="form-inline justify-content-sm-end">
              <select
                aria-label="duration-selector"
                className="form-control form-control-sm rounded"
                onChange={e => {
                  if (e.target.value !== '') {
                    const dateRange = e.target.value.split(',');
                    searchQuery.setQuery({ startDate: dateRange[0], endDate: dateRange[1] }, false);
                  } else {
                    searchQuery.clearAll(['country']);
                  }
                }}
              >
                {duration.map(tab => (
                  <option value={tab.value} key={tab.label}>
                    {capitalize(tab.label)}
                  </option>
                ))}
              </select>
            </form>
          </div>
        </div>
      </div>
      {!summary && !isFetching && (
        <p className="font-italic font-weight-light">
          No usage summary available. Please try again later or contact support if the issue persists.
        </p>
      )}
      <EventsSummary summaries={summaries} onSummarySubClicked={handleSummarySubClicked} isFetching={isFetching} />
    </div>
  );
}

export default IdentitySummaries;
