/* eslint-disable no-unused-vars */

import { useEffect, useMemo, useReducer, useState } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import Icon from '+containers/Dashboard/Shared/Icons';
import Modal from '+containers/Dashboard/Shared/Modal';
import { useBreakpoints, useSearchQuery } from '+hooks';
import { cleanInput, filteredOutObjectProperty, filterOutEmptyValues, queryParams } from '+utils';

import arrowRight from '+assets/img/dashboard/arrow-right.svg';
import calendar from '+assets/img/dashboard/calendar.svg';
import search from '+assets/img/dashboard/search-thin.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

const eventsStatusOptions = {
  found: 'Found',
  not_found: 'Not found',
  pending: 'Pending',
  failed: 'Failed'
};

const billingTypeOptions = {
  kyb: 'KYB',
  kyc: 'KYC'
};

const defaultFilterState = {
  'status[]': '',
  'verificationType[]': '',
  keyword: '',
  startDate: '',
  endDate: ''
};

interface IFilterState {
  'status[]': string;
  'verificationType[]': string;
  keyword: string;
  startDate: string | null;
  endDate: string | null;
}

export function IdentityFilter({
  type = '',
  handleFilterQuery,
  handleClearFilter
}: {
  type: string;
  handleFilterQuery: (arg: Record<string, any>) => void;
  handleClearFilter: () => void;
}) {
  const searchQuery = useSearchQuery<IFilterState>();

  const queries = useMemo(
    () => ({
      isMobile: '(max-width: 768px)'
    }),
    []
  );
  const breakpoint = useBreakpoints(queries);
  const [modalVisible, setModalVisible] = useState(false);
  const isMobile = (breakpoint as unknown as { isMobile: boolean })?.isMobile;
  const [filterItems, setFilterItems] = useReducer(
    (prev: IFilterState, next: Partial<IFilterState>) => ({ ...prev, ...next }),
    defaultFilterState
  );

  const handleFilter = () => {
    setModalVisible(false);
    const nonEmptyFilters = filterOutEmptyValues(filterItems);
    searchQuery.setQuery({ ...nonEmptyFilters });
    handleFilterQuery(nonEmptyFilters);
  };

  useEffect(() => {
    const params = filteredOutObjectProperty(searchQuery.value, [
      queryParams.currency,
      queryParams.page,
      queryParams.limit,
      queryParams.tab,
      queryParams.subtab,
      queryParams.sorterType
    ]);

    if (!Object.keys(params || {}).length) setFilterItems(defaultFilterState);
  }, [location.search]);

  const renderFilter = () => {
    switch (type) {
      case 'verification-events':
        return (
          <section className="settlement-filter">
            <div className="settlement-filter__search-w filter-section">
              <div className="element-search-content filter-body w-100">
                <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                  <select
                    name="type"
                    className="form-control"
                    onChange={e => setFilterItems({ 'status[]': e.target.value })}
                    value={filterItems?.['status[]'] ?? searchQuery.value?.['status[]']}
                    style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                  >
                    <option value="">Result</option>
                    {Object.keys(eventsStatusOptions || {}).map(value => (
                      <option key={value} value={value}>
                        {eventsStatusOptions[value as keyof typeof eventsStatusOptions]}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
                  <Icon name="search" width={16} height={16} />
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Enter keyword(s)..."
                    value={filterItems.keyword}
                    onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
                    style={{ border: '1.5px solid #EAF2FE' }}
                  />
                </div>

                <div
                  className="form-group filter-object d-flex filter-object-sm w-auto"
                  style={{
                    ['--calendar-image' as string]: `url("${calendar}")`,
                    minWidth: '300px'
                  }}
                >
                  <DatePicker
                    selected={filterItems.startDate ? new Date(filterItems.startDate) : undefined}
                    dateFormat="dd-MM-yyyy"
                    popperPlacement="bottom-end"
                    className="form-control date-select pl-4 date-picker"
                    onChange={date => setFilterItems({ startDate: dayjs(date).format('YYYY-MM-DD') })}
                    placeholderText="From"
                    calendarClassName="custom-datepicker"
                  />
                  <DatePicker
                    selected={filterItems.endDate ? new Date(filterItems.endDate) : undefined}
                    dateFormat="dd-MM-yyyy"
                    popperPlacement="bottom-end"
                    className="form-control date-select pl-4 date-picker"
                    maxDate={new Date()}
                    onChange={date => setFilterItems({ endDate: dayjs(date).format('YYYY-MM-DD') })}
                    placeholderText="To"
                    calendarClassName="custom-datepicker"
                  />
                </div>

                <button
                  aria-label="filter transactions"
                  type="button"
                  className="settlement-filter__filter-button"
                  onClick={handleFilter}
                  aria-controls="handleFilter"
                >
                  <Icon name="arrowRight" width={16} height={16} />
                </button>
              </div>
            </div>
          </section>
        );
      case 'billing-history':
        return (
          <section className="settlement-filter">
            <div className="settlement-filter__search-w filter-section">
              <div className="element-search-content filter-body w-100">
                <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                  <select
                    name="type"
                    className="form-control"
                    onChange={e => setFilterItems({ 'verificationType[]': e.target.value })}
                    value={filterItems?.['verificationType[]'] ?? searchQuery.value?.['verificationType[]']}
                    style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                  >
                    <option value="">Category</option>
                    {Object.keys(billingTypeOptions || {}).map(value => (
                      <option key={value} value={value}>
                        {billingTypeOptions[value as keyof typeof billingTypeOptions]}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
                  <Icon name="search" width={16} height={16} />
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Enter keyword(s)..."
                    value={filterItems.keyword}
                    onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
                    style={{ border: '1.5px solid #EAF2FE' }}
                  />
                </div>

                <div
                  className="form-group filter-object d-flex filter-object-sm w-auto"
                  style={{
                    ['--calendar-image' as string]: `url("${calendar}")`,
                    minWidth: '300px'
                  }}
                >
                  <DatePicker
                    selected={filterItems.startDate ? new Date(filterItems.startDate) : undefined}
                    dateFormat="dd-MM-yyyy"
                    popperPlacement="bottom-end"
                    className="form-control date-select pl-4 date-picker"
                    onChange={date => setFilterItems({ startDate: dayjs(date).format('YYYY-MM-DD') })}
                    placeholderText="From"
                    calendarClassName="custom-datepicker"
                  />
                  <DatePicker
                    selected={filterItems.endDate ? new Date(filterItems.endDate) : undefined}
                    dateFormat="dd-MM-yyyy"
                    popperPlacement="bottom-end"
                    className="form-control date-select pl-4 date-picker"
                    minDate={new Date()}
                    onChange={date => setFilterItems({ endDate: dayjs(date).format('YYYY-MM-DD') })}
                    placeholderText="To"
                    calendarClassName="custom-datepicker"
                  />
                </div>

                <button
                  aria-label="filter transactions"
                  type="button"
                  className="settlement-filter__filter-button"
                  onClick={handleFilter}
                  aria-controls="handleFilter"
                >
                  <Icon name="arrowRight" width={16} height={16} />
                </button>
              </div>
            </div>
          </section>
        );
      default:
        return null;
    }
  };

  const defaultSearchContent = () => {
    return (
      <div className="mobile-filter__modal">
        <p>Status</p>
        <div className="mobile-switch__modal" role="menu">
          {type === 'verification-events' &&
            Object.keys(eventsStatusOptions || {})?.map((item: string, index: number) => {
              const isSelected = filterItems?.['status[]'] === item;
              return (
                <button
                  type="button"
                  role="menuitem"
                  key={index}
                  className={`mobile-switch__modal__item ${isSelected ? 'active' : ''}`}
                  onClick={() => setFilterItems({ 'status[]': item })}
                >
                  <input type="radio" checked={isSelected} readOnly />
                  <div>
                    <span> {eventsStatusOptions[item as keyof typeof eventsStatusOptions]}</span>
                  </div>
                </button>
              );
            })}
          {type === 'billing-history' &&
            Object.keys(billingTypeOptions || {})?.map((item: string, index: number) => {
              const isSelected = filterItems?.['verificationType[]'] === item;
              return (
                <button
                  type="button"
                  role="menuitem"
                  key={index}
                  className={`mobile-switch__modal__item ${isSelected ? 'active' : ''}`}
                  onClick={() => setFilterItems({ 'verificationType[]': item })}
                >
                  <input type="radio" checked={isSelected} readOnly />
                  <div>
                    <span> {billingTypeOptions[item as keyof typeof billingTypeOptions]}</span>
                  </div>
                </button>
              );
            })}
        </div>

        <div className="modal-div">
          <p> Keyword</p>
          <input
            id="keyword"
            type="search"
            className="form-control"
            placeholder="Enter keyword(s)..."
            value={filterItems.keyword}
            onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
          />
        </div>

        <div className="modal-div">
          <p>Timestamp</p>
          <div className="form-group filter-object filter-object-sm w-auto mr-0" style={{ '--calendar-image': `url("${calendar}")` }}>
            <DatePicker
              id="from"
              name="dateFrom"
              selected={filterItems.startDate ? new Date(filterItems.startDate) : undefined}
              dateFormat="dd-MM-yyyy"
              onChange={date => setFilterItems({ startDate: dayjs(date).format('YYYY-MM-DD') })}
              maxDate={new Date()}
              placeholderText="From"
              calendarClassName="custom-datepicker"
              className="form-control date-select pl-4 date-picker"
            />
          </div>
          <div className="form-group filter-object filter-object-sm w-auto mr-0 mt-3" style={{ '--calendar-image': `url("${calendar}")` }}>
            <DatePicker
              id="label"
              name="dateTo"
              selected={filterItems.endDate ? new Date(filterItems.endDate) : undefined}
              dateFormat="dd-MM-yyyy"
              minDate={filterItems.startDate ? new Date(filterItems.startDate) : undefined}
              maxDate={new Date()}
              onChange={date => setFilterItems({ endDate: dayjs(date).format('YYYY-MM-DD') })}
              placeholderText="To"
              calendarClassName="custom-datepicker"
              className="form-control date-select pl-4 date-picker"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderFilter()}
      <div className="filter-search">
        <div className="filter-search search__container" style={{ margin: '0' }}>
          <img src={search} alt="search icon" className="search__icon" />
          <input
            type="search"
            aria-label="search transactions"
            name="searchHistory"
            id="searchtransactions"
            data-testid="searchtransactions"
            className="form-control form-control-sm"
            placeholder="Enter keyword(s)..."
            value={filterItems.keyword}
            style={{ fontFamily: 'Averta PE' }}
            onChange={e => {
              if (e.target.value.trim() === '') {
                handleClearFilter();
              } else {
                setFilterItems({ keyword: cleanInput(e.target.value) });
              }
            }}
          />
          <button
            aria-label="search transactions"
            type="button"
            className="search__button"
            onClick={handleFilter}
            aria-controls="searchtransactions"
            data-testid="searchbutton"
          >
            <img src={arrowRight} alt="arrow right icon" aria-hidden />
          </button>
        </div>
        <div className="mobile-search-container" onClick={() => setModalVisible(!modalVisible)}>
          <i className="os-icon os-icon-sliders" />
        </div>
      </div>
      {isMobile && modalVisible && (
        <Modal
          heading="Filter"
          size="sm"
          secondButtonText="Apply"
          secondButtonAction={handleFilter}
          close={() => {
            setModalVisible(false);
          }}
          secondButtonActionIsTerminal={false}
          content={defaultSearchContent()}
        />
      )}
    </>
  );
}
