import { TRenderSvg } from './types';

const InfoIcon: TRenderSvg = ({
  width = '20',
  height = '20',
  viewBox = '0 0 20 20',
  titleId,
  title,
  fill = "#94A7B7",
  descriptionId = '',
  description = '',
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg">
    <title id={titleId}>{title}</title>
    <desc id={descriptionId}>{description}</desc>
    <g clip-path="url(#clip0_28025_67411)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM8.40909 9.09091C8.40909 8.58882 8.81609 8.18182 9.31818 8.18182H10.6818C11.1839 8.18182 11.5909 8.58882 11.5909 9.09091V15.4545C11.5909 15.9566 11.1839 16.3636 10.6818 16.3636H9.31818C8.81609 16.3636 8.40909 15.9566 8.40909 15.4545V9.09091ZM11.8182 5.45455C11.8182 4.45039 11.0042 3.63636 10 3.63636C8.99582 3.63636 8.18182 4.45039 8.18182 5.45455C8.18182 6.4587 8.99582 7.27273 10 7.27273C11.0042 7.27273 11.8182 6.4587 11.8182 5.45455Z" fill={fill}/>
    </g>
    <defs>
    <clipPath id="clip0_28025_67411">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export { InfoIcon };
