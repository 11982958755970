import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useClickOutside } from '+hooks';
import { history } from '+utils';

import '../index.scss';

const SettingsNavigation = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const lastPath = pathName.substring(pathName.lastIndexOf('/') + 1);
  const [viewNav, setViewNav] = useState(false);
  const wrapperRef = useClickOutside<HTMLDivElement>(() => {
    setViewNav(false);
  });

  const getSettingsName = () => {
    if (['general', 'compliance', 'teams', 'security', 'referrals'].includes(lastPath)) {
      return 'General Business Settings';
    }
    if (['settlements', 'limits', 'api-integrations', 'payment'].includes(lastPath)) {
      return 'Product & Payment Settings';
    }
    if (['checkout_customization'].includes(lastPath)) {
      return 'Appearance Settings';
    }
    return 'General Business Settings';
  };

  const activeMenu = getSettingsName();

  return (
    <div className="settings-navigation" ref={wrapperRef}>
      <button type="button" className="btn btn-link goback-btn p-0" onClick={() => history.push(`/dashboard/settings`, null)}>
        <i className="os-icon os-icon-arrow-left7" />
        <span>Back to Settings</span>
      </button>
      <span className="divider-sm" />
      <div className="navigation-item" onClick={() => setViewNav(!viewNav)}>
        <p>{activeMenu}</p>
        <i className="os-icon os-icon-chevron-down" />
      </div>
      {viewNav && (
        <ul className="element-box box-style ellipsis__nav settings-options">
          <li
            role="presentation"
            className="ellipsis__item settings-item"
            onClick={() => history.push(`/dashboard/settings/general`, null)}
          >
            <span>General Business Settings</span>
            {activeMenu === 'General Business Settings' && <span className="os-icon os-icon-check" role="status" />}
          </li>
          <li
            role="presentation"
            className="ellipsis__item settings-item"
            onClick={() => history.push(`/dashboard/settings/settlements`, null)}
          >
            <span>Product & Payment Settings</span>
            {activeMenu === 'Product & Payment Settings' && <span className="os-icon os-icon-check" role="status" />}
          </li>
          <li
            role="presentation"
            className="ellipsis__item settings-item"
            onClick={() => history.push(`/dashboard/settings/checkout_customization`, null)}
          >
            <span>Appearance Settings</span>
            {activeMenu === 'Appearance Settings' && <span className="os-icon os-icon-check" role="status" />}
          </li>
        </ul>
      )}
    </div>
  );
};

export default SettingsNavigation;
