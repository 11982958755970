import { useState } from 'react';

import Table from '+dashboard/Shared/Table';
import { IBulkPayoutTableDataProps, IBulkPayoutTableProps } from '+types/bulk-payouts';

import BulkPayoutFilter from './BulkPayoutFilter';
import BulkPayoutTableHelpers, { TableBanner } from './BulkPayoutTableHelpers';

function BulkPayoutTable<T extends IBulkPayoutTableDataProps>({
  isFetching,
  paging,
  setPagination,
  refetch,
  pagination,
  data,
  setExportModalVisible,
  permissions,
  status
}: IBulkPayoutTableProps<T>) {
  const [showBanner, setShowBanner] = useState(true);

  const { bulkPayoutTableData, getbulkPayoutTableProps, dropdownOptions } = BulkPayoutTableHelpers;
  const tableData = bulkPayoutTableData(data);
  const { headings, emptyStateHeading, tableClassName, emptyStateMessage } = getbulkPayoutTableProps(refetch);

  return (
    <section className="transaction_table_comp">
      <BulkPayoutFilter
        handleFilterQuery={param => {
          setPagination({ ...pagination, page: 1, sorterType: 'filter', sortingParams: param });
        }}
        openExportModal={setExportModalVisible}
        totalCount={data?.length}
        status={status}
        activeCurrency="NGN"
        userAccess={permissions}
        previousFilter={false}
        setPreviousFilter={() => {}}
        dropdownOptions={dropdownOptions}
      />
      <Table
        headings={headings}
        emptyStateHeading={emptyStateHeading}
        tableClassName={tableClassName}
        emptyStateMessage={emptyStateMessage}
        banner={showBanner && <TableBanner close={() => setShowBanner(false)} status={status} />}
        hasPagination
        loading={isFetching}
        current={paging?.current}
        totalItems={paging?.total_items || 0}
        pageSize={paging?.page_size}
        actionFn={page => setPagination({ ...pagination, page })}
        limitAction={limit => setPagination({ ...pagination, limit, page: 1 })}
      >
        {tableData}
      </Table>
    </section>
  );
}

export default BulkPayoutTable;
