import React, { useState } from 'react';
import { AxiosError } from 'axios';

import { useSurveyTrigger } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import useClickOutside from '+hooks/useClickOutside';
import { AuthServices } from '+services/auth-services';
import { MobileMoneyServices } from '+services/mobile-money-services';
import { TransactionServices } from '+services/transaction-services';
import { MobileNetworkDataType } from '+types';
import { WithdrawProps } from '+types/balances-types';
import { capitalizeFirst, logBreadCrumb, smartTrack } from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import BankWithdrawalModal from './BankPayouts';
import MobilePayoutsModal from './MobilePayouts';
import WalletWithdrawalModal from './WalletPayout';

const Withdraw: React.FC<WithdrawProps> = ({
  banks = [],
  currency,
  balances,
  refetchBalance,
  isFetchingBanks,
  bankPayoutLimit,
  walletPayoutLimit,
  minBankPayoutLimit,
  minWalletPayoutLimit,
  minMobileMoneyLimit,
  mobileMoneyLimit,
  disabled = false,
  payoutLimitDetails,
  settlementAccounts,
  bankWithdrawalLimit,
  balanceCount
}) => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [navModal, setNavModal] = useState(false);
  const [bankPayout, setBankPayout] = useState(false);
  const [walletPayout, setWalletPayout] = useState(false);
  const [mobilePayout, setMobilePayout] = useState(false);
  const [initiateSurvey, setInitiateSurvey] = useState(false);
  const wrapperRef = useClickOutside(() => {
    setNavModal(false);
  });
  const [withdrawalDetails, setWithdrawalDetails] = useState<{
    payment_reference: string;
    identifier: string;
    type: 'otp' | 'totp';
  }>({
    payment_reference: '',
    identifier: '',
    type: 'otp'
  });
  const [twofactorType, setTwofactorType] = useState('otp');

  const [type, setType] = useState('');
  const [networkOperator, setNetworkOperator] = useState<MobileNetworkDataType[]>([]);

  const networkAvailability = MobileMoneyServices.useNetworkOperatorAvailability({
    showErrorMessage: false,
    onSuccess: async (data: { data: MobileNetworkDataType[] }) => {
      const response = data?.data;
      setNetworkOperator(response);
    },
    onError: e => {
      const error = e as AxiosError<{ message: string }>;
      let errorMessage = 'An error occurred while processing your request. Please try again later.';
      let feedbackComponentLevel = true;

      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;

        if (errorMessage.includes('invalid request data')) {
          errorMessage = 'There has been an error getting network availability status. Please refresh the page.';
          feedbackComponentLevel = true;
        }
      }

      feedbackInit({
        componentLevel: feedbackComponentLevel,
        message: errorMessage,
        type: 'danger'
      });
    }
  });

  const initiateTransfer = TransactionServices.useInitiateTransfer({
    hasFeedbackTimeout: true,
    errorMessage: 'We are sorry, your transfer can not be processed right now. Please try again.',
    onSuccess: ({ data }) => {
      const { payment_reference, authData } = data || {};
      setWithdrawalDetails(details => ({ ...details, payment_reference, identifier: authData?.identifier, type: authData?.type }));
    },
    bannerLevel: true
  });

  const processTransfer = TransactionServices.useProcessTransfer({
    showErrorMessage: false,
    onSuccess: data => {
      smartTrack('Payouts', data?.reference || '');
      setWithdrawalDetails(prevDetails => ({ ...prevDetails, payment_reference: data?.data?.reference || '' }));
      refetchBalance();
      setInitiateSurvey(true);
    },
    onError: err => {
      const error = err as AxiosError<{ message: string }>;
      let message = capitalizeFirst(error?.response?.data?.message) || 'We are sorry, your transaction could not be processed right now.';
      if (error.response?.data?.message?.includes('invalid code')) {
        switch (twofactorType) {
          case 'totp':
            message = 'Invalid Authentication code. Please try again.';
            break;
          case 'totp_recovery_code':
            message = 'Invalid Recovery code. Please try again.';
            break;
          default:
            break;
        }
      }

      if (error.response?.data?.message?.includes('too many attempts'))
        message = 'You have exceeded the maximum number of attempts. Please restart this transaction.';
      feedbackInit({
        message,
        type: 'danger',
        componentLevel: true
      });
      setTimeout(
        () => {
          closeFeedback();
          if (error.response?.data?.message?.includes('too many attempts')) {
            setBankPayout(false);
            setWalletPayout(false);
            setMobilePayout(false);
          }
        },
        error.response?.data?.message?.includes('too many attempts') ? 2000 : 5000
      );
    }
  });

  const resendOTP = AuthServices.useResendOTPAction({
    bannerLevel: true,
    onSuccess: data => {
      const { authData } = data;
      setWithdrawalDetails(details => ({ ...details, identifier: authData?.identifier || withdrawalDetails.identifier }));
    },
    errorMessage: 'We are sorry, there has been a problem getting you a new OTP.',
    showSuccessMessage: false
  });

  const handleWithdrawalType = async (withdrawalType: React.SetStateAction<string>) => {
    let payoutType;
    let eventType;
    let eventData;

    if (withdrawalType === 'mobile_money') {
      payoutType = 'MobilePayout';
      eventType = breadCrumbEvents.balances.withdrawButtonOption('Mobile Money');
    } else if (withdrawalType === 'bank_account') {
      payoutType = 'BankPayout';
      eventType = breadCrumbEvents.balances.withdrawButtonOption('Bank account');
    } else {
      return;
    }

    setNavModal(false);
    eventData = null;

    logBreadCrumb({ event: eventType, data: eventData });
    setType(withdrawalType);

    const callNetworkAvailabilityApi = withdrawalType === 'bank_account' && ['ZAR'].includes(currency);

    if (callNetworkAvailabilityApi) {
      await networkAvailability.mutateAsync({ currency, type: withdrawalType });
    }

    if (payoutType === 'MobilePayout') {
      setMobilePayout(true);
    } else {
      setBankPayout(true);
    }
  };

  useSurveyTrigger({
    surveyId: +(process.env.REACT_APP_ATLAS_QUERY_PT || '0'),
    id: 'withdrawal',
    trigger: initiateSurvey,
    count: balanceCount,
    milestones: [5, 100, 1000]
  });

  const disabledButton =
    !payoutLimitDetails?.bank_account[currency] &&
    !payoutLimitDetails?.disbursement_wallet[currency] &&
    !payoutLimitDetails?.mobile_money[currency];

  return (
    <div className="history_summary_option">
      <article ref={wrapperRef} style={{ position: 'relative' }}>
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => {
            setNavModal(value => !value);
            logBreadCrumb({ event: breadCrumbEvents.balances.withdrawButtonClicked });
          }}
          disabled={disabled || disabledButton}
        >
          <span>Withdraw</span>
          <span className="os-icon os-icon-minus2" />
        </button>
        {navModal && (
          <ul className="element-box box-style ellipsis__nav withdrawal-options balance-options">
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => handleWithdrawalType('bank_account')}
              hidden={!payoutLimitDetails.bank_account[currency]}
            >
              <span>To bank account</span>
            </li>
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => {
                setWalletPayout(true);
                setNavModal(false);
                logBreadCrumb({ event: breadCrumbEvents.balances.withdrawButtonOption('Korapay wallet') });
              }}
              hidden={!payoutLimitDetails.disbursement_wallet[currency]}
            >
              <span>To Korapay wallet</span>
            </li>
            <li
              role="presentation"
              className="ellipsis__item"
              onClick={() => handleWithdrawalType('mobile_money')}
              hidden={!payoutLimitDetails.mobile_money[currency]}
            >
              <span>To Mobile Money</span>
            </li>
          </ul>
        )}
      </article>
      {bankPayout && (
        <BankWithdrawalModal
          banks={banks}
          isFetchingBanks={isFetchingBanks}
          close={() => setBankPayout(false)}
          currency={currency}
          balance={balances[currency]?.available_balance}
          refetchBalance={refetchBalance}
          initiateTransfer={initiateTransfer}
          processTransfer={processTransfer}
          resendOTP={resendOTP}
          paymentRef={withdrawalDetails.payment_reference}
          identifier={withdrawalDetails.identifier}
          type={withdrawalDetails.type}
          minPayoutLimit={minBankPayoutLimit}
          maxPayoutLimit={bankPayoutLimit}
          settlementAccounts={settlementAccounts}
          withdrawalLimit={bankWithdrawalLimit}
          get2FAType={setTwofactorType}
          networkOperator={networkOperator}
        />
      )}
      {walletPayout && (
        <WalletWithdrawalModal
          close={() => setWalletPayout(false)}
          currency={currency}
          balance={balances[currency]?.available_balance}
          refetchBalance={refetchBalance}
          initiateTransfer={initiateTransfer}
          processTransfer={processTransfer}
          resendOTP={resendOTP}
          paymentRef={withdrawalDetails.payment_reference}
          identifier={withdrawalDetails.identifier}
          type={withdrawalDetails.type}
          minPayoutLimit={minWalletPayoutLimit}
          maxPayoutLimit={walletPayoutLimit}
          get2FAType={setTwofactorType}
        />
      )}
      {mobilePayout && (
        <MobilePayoutsModal
          banks={banks}
          isFetchingBanks={isFetchingBanks}
          close={() => setMobilePayout(false)}
          currency={currency}
          balance={balances[currency]?.available_balance}
          refetchBalance={refetchBalance}
          initiateTransfer={initiateTransfer}
          processTransfer={processTransfer}
          resendOTP={resendOTP}
          paymentRef={withdrawalDetails.payment_reference}
          identifier={withdrawalDetails.identifier}
          type={withdrawalDetails.type}
          minPayoutLimit={minMobileMoneyLimit}
          maxPayoutLimit={mobileMoneyLimit}
          get2FAType={setTwofactorType}
        />
      )}
    </div>
  );
};

export default Withdraw;
