import React, { useEffect, useState } from 'react';

import check from '+assets/img/dashboard/check-white.svg';

import './index.scss';

interface ICustomCheckbox {
  className?: string;
  checked: boolean;
  text: React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  onChange: (arg: boolean) => void;
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  style?: React.CSSProperties;
}

const CustomCheckbox = ({ className, checked, text, onChange, disabled, style }: ICustomCheckbox) => {
  const [checkboxInput, setCheckboxInput] = useState(checked);

  useEffect(() => {
    if (checked !== checkboxInput) {
      onChange(checkboxInput);
    }
  }, [checkboxInput]);

  useEffect(() => {
    if (checked !== checkboxInput) {
      setCheckboxInput(checked);
    }
  }, [checked]);

  return (
    <label
      className={`checkbox__custom ${className} ${disabled ? 'disabled' : ''}`}
      style={{ '--check-image': `url("${check}")`, ...style }}
    >
      <input
        type="checkbox"
        className={`${checkboxInput ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
        defaultChecked={checkboxInput}
        onClick={() => {
          if (disabled) return;
          setCheckboxInput(prevCheckboxInput => !prevCheckboxInput);
        }}
      />
      <button
        onClick={() => {
          if (disabled) return;
          setCheckboxInput(prevCheckboxInput => !prevCheckboxInput);
        }}
        type="button"
      >
        {text}
      </button>
    </label>
  );
};

export default CustomCheckbox;
