import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useStore from '+store';

import Slider from './Slider';

import Info from '+assets/img/auth/info-brown.svg';
import CancelIcon from '+assets/img/dashboard/cancel-kyc.svg';

import './TwoFactorBanner.scss';

const CurrencyNotificationBanner = ({ twoFaHistory }) => {
  const { pathname } = useLocation();
  const MERCHANT_ENV = useStore(state => state.merchantEnv);
  const status = useStore(state => state.merchantKYC);
  const complianceStatus = status?.compliance?.status;
  const [bannerOpen, setBannerOpen] = useState(MERCHANT_ENV === 'test');

  useEffect(() => {
    setBannerOpen(MERCHANT_ENV === 'test');
  }, [MERCHANT_ENV]);

  const handleClose = () => {
    setBannerOpen(false);
  };

  if (pathname.match('/dashboard/settings')) {
    return null;
  }

  return ['ready', 'kyc', 'feedback', 'unverified'].includes(complianceStatus) || !twoFaHistory
    ? null
    : bannerOpen && (
        <div className="twofactor-banner banner">
          <img className="banner-sparkle" src={Info} alt="" />
          <div className="slider-wrapper">
            <Slider>
              <p>You are currently on Test Mode! Balances shown can not be withdrawn to represent actual value.</p>{' '}
            </Slider>
          </div>
          <button type="button" className="banner-btn" onClick={() => handleClose()}>
            <p className="banner-action">Dismiss</p>
            <img className="banner-btn-icon" src={CancelIcon} alt="cancel" />
          </button>
        </div>
      );
};

export default CurrencyNotificationBanner;
