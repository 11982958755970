/* eslint-disable no-unused-vars */
import { keepPreviousData } from '@tanstack/react-query';

import { useFetch, useSend } from '+hooks';
import { ISendResponse } from '+types/api-services-types';

export const RefundServices = {
  useGetSingleRefund: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    ref
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    ref?: string;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/refunds/${ref}`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetRefunds: ({
    onSuccess,
    onError,
    bannerLevel,
    params,
    refetchOnCloseFeedbackError
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    params: { page: string; limit: string; status: string[]; currency: string };
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/refunds`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      params,
      refetchOnCloseFeedbackError,
      placeholderData: keepPreviousData,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useExportRefunds: ({
    enabled,
    onSuccess,
    onError,
    bannerLevel,
    successMessage,
    errorMessage,
    params,
    refetchOnCloseFeedbackError
  }: {
    onSuccess?: () => void;
    onError?: () => void;
    enabled?: boolean;
    successMessage?: string;
    errorMessage?: string;
    bannerLevel?: boolean;
    params: { sortingParams: any; format: any; currency: any; fieldsToExport: any };
    refetchOnCloseFeedbackError?: boolean;
  }) => {
    const { sortingParams, format, currency, fieldsToExport } = params || {};
    const formattedParams = { format, ...sortingParams, currency, 'fieldsToExport[]': fieldsToExport };
    return useFetch(`/refunds`, {
      enabled,
      successMessage,
      errorMessage,
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      params: formattedParams,
      refetchOnCloseFeedbackError,
      showSuccessMessage: true,
      requestConfig: { responseType: 'blob' },
      showReturnDefaultResponse: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    });
  },

  useValidateTransaction: ({
    enabled,
    onSuccess,
    onError,
    bannerLevel,
    ref,
    refetchOnCloseFeedbackError
  }: {
    enabled: boolean;
    onSuccess?: (data: unknown) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    ref: string;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/refunds/${ref}/validate`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useGetRefundSummary: ({
    onSuccess,
    onError,
    bannerLevel,
    params,
    refetchOnCloseFeedbackError
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    params: { sortingParams: any; format: any; currency: any; fieldsToExport: any };
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/refunds/reports/summary`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      params,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useInitiateRefund: ({
    bannerLevel,
    ref,
    showErrorMessage,
    onSuccess,
    onError
  }: {
    bannerLevel?: boolean;
    ref: any;
    onSuccess?: (data: ISendResponse<unknown>) => void;
    onError?: (e: Error) => void;
    showErrorMessage?: boolean;
  }) =>
    useSend({
      url: `/refunds/${ref}/initiate`,
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      showErrorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    })
};
