import { useFetch } from '+hooks';

export const AnalyticsServices = {
  // use get transaction analytics here
  useGetTransactionAnalytics: ({
    onSuccess,
    bannerLevel,
    currency,
    filter
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    filter?: string | null;
    currency?: any;
  }) =>
    useFetch(`/merchants/analytics/transactions/graph/${currency}/${filter}`, {
      onSuccess,
      errorMessageBannerLevel: bannerLevel
    }),

  // use get transaction analytics here
  useGetPerformanceAnalytics: ({
    onSuccess,
    bannerLevel,
    currency,
    params
  }: {
    onSuccess?: () => void;
    bannerLevel?: boolean;
    currency?: any;
    params?: { startDate: string; endDate: any };
  }) =>
    useFetch(`/merchants/analytics/transactions/${currency}/count`, {
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      params
    })
};
