import { FormikProps } from 'formik';

import { CurrencyType, TIssuedCardActionModalFormValues } from '+types';
import { backwardAmountInput, formatAmount, stripNonNumeric } from '+utils';

const CardFundingForm = ({
  formik,
  balance,
  currency
}: {
  formik: FormikProps<TIssuedCardActionModalFormValues>;
  balance: number;
  currency: CurrencyType;
}) => {
  const { setFieldValue, values, getFieldProps, handleBlur, touched, errors } = formik;
  const sourceOfFundOptions = [
    { label: '- Select a source of fund -', value: '' },
    { label: `Issuing Balance (${formatAmount(balance)} ${currency?.toUpperCase()})`, value: 'issuing_balance' }
  ];

  return (
    <div className="card-funding-form">
      <div>
        <label htmlFor="source-of-fund">Source of funds</label>
        <select className="form-control" id="source-of-fund" {...getFieldProps('sourceOfFund')} tabIndex={-1}>
          {sourceOfFundOptions.map(({ value, label }) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
        <p className="sr-only">
          You&apos;re now transferring from your issuing balance. You have a current balance of {formatAmount(balance)}{' '}
          {currency.toUpperCase()}
        </p>
      </div>

      <div>
        <label htmlFor="funding-amount">Amount ({currency.toUpperCase()})</label>
        <input
          type="text"
          name="fundingAmount"
          id="funding-amount"
          value={values.fundingAmount}
          onChange={e => setFieldValue('fundingAmount', backwardAmountInput(stripNonNumeric(e.target.value)))}
          onBlur={handleBlur}
          className="form-control"
          placeholder="Enter amount"
        />

        <div aria-live="polite" role="alert">
          {touched.fundingAmount && errors.fundingAmount && <p className="fade-in card-funding-form__error">{errors.fundingAmount}</p>}
        </div>
      </div>
    </div>
  );
};

export default CardFundingForm;
