import { TRenderSvg } from './types';

const ArrowsLeftRightHorizontal: TRenderSvg = ({ width = '32', height = '32', viewBox = '0 0 32 32', fill = 'none', className = '' }) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg" role="img" className={className}>
    <path
      d="M22.7071 5.29269L26.7071 9.29269C26.8947 9.48033 27.0001 9.73483 27.0001 10.0002C27.0001 10.2656 26.8947 10.5201 26.7071 10.7077C26.5194 10.8953 26.2649 11.0007 25.9996 11.0007C25.7342 11.0007 25.4797 10.8953 25.2921 10.7077L22.9996 8.41394V26.0002C22.9996 26.2654 22.8942 26.5198 22.7067 26.7073C22.5192 26.8948 22.2648 27.0002 21.9996 27.0002C21.7344 27.0002 21.48 26.8948 21.2925 26.7073C21.1049 26.5198 20.9996 26.2654 20.9996 26.0002V8.41394L18.7071 10.7077C18.5194 10.8953 18.2649 11.0007 17.9996 11.0007C17.7342 11.0007 17.4797 10.8953 17.2921 10.7077C17.1044 10.5201 16.999 10.2656 16.999 10.0002C16.999 9.73483 17.1044 9.48033 17.2921 9.29269L21.2921 5.29269C21.385 5.19971 21.4952 5.12595 21.6166 5.07563C21.738 5.02531 21.8682 4.9994 21.9996 4.9994C22.131 4.9994 22.2611 5.02531 22.3825 5.07563C22.5039 5.12595 22.6142 5.19971 22.7071 5.29269ZM14.7071 22.7077C14.8947 22.52 15.0001 22.2656 15.0001 22.0002C15.0001 21.7348 14.8947 21.4803 14.7071 21.2927C14.5194 21.105 14.2649 20.9996 13.9996 20.9996C13.7342 20.9996 13.4797 21.105 13.2921 21.2927L10.9996 23.5864V6.00019C10.9996 5.73497 10.8942 5.48062 10.7067 5.29308C10.5191 5.10555 10.2648 5.00019 9.99958 5.00019C9.73436 5.00019 9.48001 5.10555 9.29247 5.29308C9.10494 5.48062 8.99958 5.73497 8.99958 6.00019V23.5864L6.70708 21.2927C6.51944 21.105 6.26494 20.9996 5.99958 20.9996C5.73422 20.9996 5.47972 21.105 5.29208 21.2927C5.10444 21.4803 4.99902 21.7348 4.99902 22.0002C4.99902 22.2656 5.10444 22.52 5.29208 22.7077L9.29208 26.7077C9.38495 26.8007 9.49524 26.8744 9.61664 26.9247C9.73804 26.9751 9.86816 27.001 9.99958 27.001C10.131 27.001 10.2611 26.9751 10.3825 26.9247C10.5039 26.8744 10.6142 26.8007 10.7071 26.7077L14.7071 22.7077Z"
      fill="#414F5F"
    />
  </svg>
);

export { ArrowsLeftRightHorizontal };
