import { ReactNode, useEffect, useState } from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import { useClickOutside } from '+hooks';

import './index.scss';

const BasicSelect = ({
  placeholder = 'Select',
  options,
  dataTestId,
  setValue,
  resetOnSelect,
  disabled,
  className = '',
  onOpenChange
}: {
  placeholder?: ReactNode;
  dataTestId?: string;
  options: { value: string; label: ReactNode }[];
  setValue: (arg: string) => void;
  disabled?: boolean;
  className?: string;
  resetOnSelect?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<ReactNode>(placeholder);
  const dropdownRef = useClickOutside<HTMLButtonElement>(() => setOpen(false));

  useEffect(() => {
    if (onOpenChange) {
      onOpenChange(open);
    }
  }, [open, onOpenChange]);

  return (
    <button
      disabled={disabled}
      className={`basic-select ${className}`}
      ref={dropdownRef}
      data-testid={dataTestId}
      onClick={() => setOpen(!open)}
    >
      <span>{selected}</span> <Icon className="arrow-down" name="caretDown" width={12} strokeWidth={1.5} />
      {open && (
        <ul className="dropdown" role="listbox">
          {options.map((option, i) => (
            <li
              style={{ padding: typeof option.label === 'string' ? '0.5rem 1rem' : '0' }}
              key={i}
              role="option"
              aria-selected={selected === option.label}
              onClick={() => {
                setValue(option.value);
                setSelected(resetOnSelect ? placeholder : option.label);
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </button>
  );
};

export default BasicSelect;
