/* eslint-disable no-unused-vars */

import { AxiosResponse } from 'axios';

import { useFetch } from '+hooks';
import useStore from '+store';

export const BalanceServices = {
  // use get balance here
  useGetBalances: ({
    onSuccess,
    onError,
    showErrorMessage,
    bannerLevel,
    enabled,
    refetchOnCloseFeedbackError,
    errorMessage
  }: {
    onSuccess?: (data: { data: Record<string, unknown> }) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    enabled?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
  }) =>
    useFetch('merchants/profile/balances', {
      onError,
      errorMessageBannerLevel: bannerLevel,
      showErrorMessage,
      enabled,
      refetchOnCloseFeedbackError,
      errorMessage,
      onSuccess(data) {
        useStore.setState({
          merchantBalanceDetails: data?.data
        });
        onSuccess?.(data);
      }
    }),

  // use get Rolling Reserve History here
  useGetRollingReserveHistory: ({
    onSuccess,
    onError,
    showErrorMessage,
    bannerLevel,
    enabled,
    params,
    currency,
    refetchOnCloseFeedbackError
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    showErrorMessage?: boolean;
    enabled?: boolean;
    currency?: string;
    params?: { page?: string | number; limit?: string };
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/transactions/rolling-reserves/${currency}/transactions`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      showErrorMessage,
      enabled,
      onSuccess,
      params,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  // use get balance History here
  useGetBalanceHistory: ({
    onSuccess,
    onError,
    bannerLevel,
    params,
    currency,
    refetchOnCloseFeedbackError
  }: {
    onSuccess?: (data: { data: Record<string, unknown> }) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    currency: string;
    params?: { page?: string | number; limit?: string };
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/transactions/balances/${currency}/transactions`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      params,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useExportBalanceHistory: ({
    enabled,
    bannerLevel,
    errorMessage,
    params,
    onSuccess,
    onError
  }: {
    enabled?: boolean;
    onSuccess?: (data: AxiosResponse<unknown>) => void;
    onError?: () => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    params: { currency: string; format: string; dateFrom: string; dateTo: string; sortingParams: string[] };
  }) => {
    const { format, dateFrom, dateTo, sortingParams, currency } = params || {};
    const formattedParams = { format, date_from: dateFrom, date_to: dateTo, currency };

    return useFetch(`/transactions/balances/${currency}/transactions?fieldsToExport[]=${sortingParams}`, {
      enabled,
      onError,
      onSuccess,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      params: formattedParams,
      requestConfig: { responseType: 'blob' },
      useEnvironmentHandler: true,
      showReturnDefaultResponse: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    });
  },

  useExportRollingReserveHistory: ({
    enabled,
    bannerLevel,
    errorMessage,
    params,
    onSuccess,
    onError
  }: {
    enabled?: boolean;
    onSuccess?: (data: AxiosResponse<unknown>) => void;
    onError?: () => void;
    bannerLevel?: boolean;
    errorMessage?: string;
    sortingParams: string;
    params: { currency: string; format: any; dateFrom: any; dateTo: any; sortingParams: any };
  }) => {
    const { currency, format, dateFrom, dateTo } = params || {};
    const formattedParams = { currency, format, dateFrom, dateTo };

    return useFetch(`/transactions/rolling-reserves/${currency}/transactions`, {
      enabled,
      onSuccess,
      onError,
      errorMessageBannerLevel: bannerLevel,
      errorMessage,
      params: formattedParams,
      requestConfig: { responseType: 'blob' },
      useEnvironmentHandler: true,
      showReturnDefaultResponse: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    });
  }
};
