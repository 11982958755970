/* eslint-disable no-unused-vars */
import { useFetch, useSend } from '+hooks';
import {
  CardFundingPayloadType,
  CardStatusUpdatePayloadType,
  CardTerminationPayloadType,
  IChargebackRequestPayload,
  IInitiateCardWithdrawalPayload,
  OtpResponseType,
  WalletBalanceType,
  WithoutCardRef
} from '+types';
import { IResponse, ISendResponse } from '+types/api-services-types';

export const CardIssuanceServices = {
  useGetSingleIssuedCardTransaction: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    ref,
    errorMessage
  }: {
    onSuccess?: (data: IResponse<unknown>) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    kind?: string;
    ref?: string;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
  }) =>
    useFetch(`/transactions/card-issuance/${ref}`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useFetchIssuanceTxStatistics: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/transactions/card-issuance/statistics`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useFetchIssuedCardsStats: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: { cardCategory: string };
  }) =>
    useFetch(`/cards/statistics`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      params,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useFetchSingleIssuedCardChargeback: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    ref,
    errorMessage
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    kind?: string;
    ref?: string;
    errorMessage?: string;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/chargebacks/card-issuance/${ref}`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useFetchFundingDeposits: ({
    onError,
    onSuccess,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    enabled
  }: {
    onError?: (e: Error) => void;
    onSuccess?: () => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: { format: string; fieldsToExport: string; toExport: any; id: string | number; page: string; limit: string };
    enabled?: boolean;
  }) => {
    const { format, fieldsToExport, page, limit, toExport, id, ...otherParams } = params;

    const formattedParams = { ...(toExport ? { format, fieldsToExport } : { page, limit, reference: id }), ...otherParams };
    return useFetch(`/transactions/card-issuance/wallets/deposit-requests`, {
      onError,
      onSuccess,
      enabled,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,

      ...(toExport && { requestConfig: { responseType: 'blob' } })
    });
  },

  useFetchIssuingBalance: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    enabled
  }: {
    onSuccess?: (data: IResponse<WalletBalanceType>['data']) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    kind?: string;
    params: { currency: string };
    enabled?: boolean;
    refetchOnCloseFeedbackError?: boolean;
  }) =>
    useFetch(`/wallets/card-issuance/balances`, {
      onError,
      params,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      enabled,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useFetchSubscriptionFees: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    errorMessage
  }: {
    onSuccess?: (data: any) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    kind?: string;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
  }) =>
    useFetch(`merchants/card-issuance/subscription/fees`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      errorMessage
    }),

  useFetchIssuingBalanceHistory: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: {
      currency: any;
      page?: string;
      limit?: string;
      toExport?: any;
      format?: any;
      fieldsToExport?: any;
    };
    errorMessage?: string;
  }) => {
    const { page, limit, toExport, exportFormat, exportFields, ...filterParams } = params;
    const formattedParams = { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams };
    return useFetch(`/transactions/card-issuance/wallets/transactions`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...(toExport && { requestConfig: { responseType: 'blob' } })
    });
  },

  useFetchIssuedCards: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: {
      cardCategory: 'reserved' | 'customer';
      page?: any;
      limit?: any;
      toExport?: any;
      exportFormat?: any;
      exportFields?: any;
    };
    errorMessage?: string;
  }) => {
    const { page, limit, toExport, exportFormat, exportFields, ...filterParams } = params;
    const formattedParams = { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams };
    return useFetch(`/cards`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...(toExport && { requestConfig: { responseType: 'blob' } })
    });
  },

  useFetchAllIssuanceTransactions: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: {
      page?: any;
      limit?: any;
      toExport?: any;
      exportFields?: any;
      exportFormat?: any;
    };
    errorMessage?: string;
  }) => {
    const { page, limit, toExport, exportFormat, exportFields, ...filterParams } = params;
    const formattedParams = { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams };
    return useFetch(`/transactions/card-issuance`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...(toExport && { requestConfig: { responseType: 'blob' } })
    });
  },

  useFetchAllIssuedCardChargebacks: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: {
      page?: any;
      limit?: any;
      toExport?: any;
      exportFormat?: any;
      exportFields?: any;
    };
    errorMessage?: string;
  }) => {
    const { page, limit, toExport, exportFormat, exportFields, ...filterParams } = params;
    const formattedParams = { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams };
    return useFetch(`/chargebacks/card-issuance`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...(toExport && { requestConfig: { responseType: 'blob' } })
    });
  },

  useFetchAllChargebacksForIssuedCard: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: {
      cardId: string;
      page: string;
      limit: string;
      exportFormat?: string;
      exportFields?: string;
      [x: string]: string;
    };
    errorMessage?: string;
  }) => {
    const { cardId, page, limit, toExport, exportFormat, exportFields, ...filterParams } = params;
    const formattedParams = { cardId, ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams };
    return useFetch(`/chargebacks/card-issuance`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...(toExport && { requestConfig: { responseType: 'blob' } })
    });
  },

  useFetchReservedCardholderName: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    enabled,
    errorMessage
  }: {
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    errorMessage?: string;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    enabled?: boolean;
  }) =>
    useFetch(`/cardholders/reserved`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      errorMessage,
      refetchOnCloseFeedbackError,
      enabled,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useAuthorizeCardFunding: ({
    showErrorMessage,
    onError,
    onSuccess
  }: {
    onError?: (e: Error) => void;
    showErrorMessage?: boolean;
    onSuccess: () => void;
  }) =>
    useSend({
      url: `cards/funding/authorize`,
      method: 'post',
      onError,
      onSuccess,
      errorMessageBannerLevel: true,
      successMessageBannerLevel: false,
      showErrorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      hasFeedbackTimeout: true
    }),

  useFetchIssuedCardTransactions: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: {
      page: string;
      limit: string;
      exportFormat?: string;
      exportFields?: string;
      toExport?: boolean;
      ref: string;
    };
    errorMessage?: string;
  }) => {
    const { page, limit, toExport, exportFormat, exportFields, ref, ...filterParams } = params;
    const formattedParams = { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams };
    return useFetch(`/cards/${ref}/transactions`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      showReturnDefaultResponse: toExport,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...(toExport && { requestConfig: { responseType: 'blob' } })
    });
  },

  useFetchCardEvents: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: {
      cardId: string;
      page: string;
      limit: string;
      exportFormat?: string;
      exportFields?: string;
      toExport?: boolean;
      ref: string;
    };
    errorMessage?: string;
  }) => {
    const { page, limit, toExport, exportFormat, exportFields, ref, ...filterParams } = params;
    const formattedParams = { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams };
    return useFetch(`/cards/${ref}/events`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      showReturnDefaultResponse: toExport,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...(toExport && { requestConfig: { responseType: 'blob' } })
    });
  },

  useGetSingleIssuedCard: ({
    enabled,
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    ref,
    params,
    errorMessages
  }: {
    enabled?: boolean;
    onSuccess?: () => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    kind?: string;
    ref?: string;
    errorMessages?: string;
    refetchOnCloseFeedbackError?: boolean;
    params?: { cardCategory: 'reserved' | 'customer' };
  }) =>
    useFetch(`/cards/${ref}`, {
      enabled,
      onError,
      errorMessage: errorMessages,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      params,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useInitiateReservedCardCreation: ({
    onSuccess,
    onError
  }: {
    onSuccess?: (data: OtpResponseType) => void;
    onError?: (e: Error) => void;
  }) =>
    useSend({
      url: `/cards/reserved/initiate`,
      method: 'post',
      onSuccess,
      onError,
      errorMessageBannerLevel: true,
      successMessageBannerLevel: true,
      showErrorMessage: false,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useAuthorizeReservedCardCreation: ({ onSuccess, onError }: { onSuccess?: (data: any) => void; onError?: (error: Error) => void }) =>
    useSend({
      url: `/cards/reserved/authorize`,
      method: 'post',
      onSuccess,
      onError,
      reInvalidate: true,
      errorMessageBannerLevel: true,
      successMessageBannerLevel: false,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useFundCardIssuingWallet: ({
    onSuccess,
    onError,
    hasFeedbackTimeout
  }: {
    onSuccess?: (data: any) => void;
    onError?: (error: Error) => void;
    hasFeedbackTimeout?: boolean;
  }) =>
    useSend({
      url: `/wallets/card-issuance/funding/initiate`,
      method: 'post',
      onSuccess,
      onError,
      showErrorMessage: false,
      hasFeedbackTimeout,
      successMessageBannerLevel: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useAuthorizeCardIssuingFunding: ({
    onSuccess,
    onError,
    hasFeedbackTimeout,
    reInvalidate
  }: {
    onSuccess?: (data: any) => void;
    onError?: (error: Error) => void;
    hasFeedbackTimeout?: boolean;
    reInvalidate?: boolean;
  }) =>
    useSend({
      url: `wallets/card-issuance/funding/authorize`,
      method: 'post',
      onSuccess,
      onError,
      showErrorMessage: false,
      hasFeedbackTimeout,
      reInvalidate,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useEscalateIssuedCardChargeback: ({
    onSuccess,
    onError,
    bannerLevel,
    errorMessage,
    showErrorMessage,
    hasFeedbackTimeout
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    errorMessage?: string;
    showErrorMessage?: boolean;
    hasFeedbackTimeout?: boolean;
  }) =>
    useSend({
      url: `/chargebacks/card-issuance`,
      method: 'post',
      onSuccess,
      onError,
      errorMessage,
      showErrorMessage,
      hasFeedbackTimeout,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useReescalateIssuedCardChargeback: ({
    onSuccess,
    onError,
    bannerLevel,
    showErrorMessage,
    errorMessage,
    ref,
    hasFeedbackTimeout
  }: {
    onSuccess?: (data: any) => void;
    bannerLevel?: boolean;
    onError?: (error: Error) => void;
    errorMessage?: string;
    showErrorMessage?: boolean;
    ref: string;
    hasFeedbackTimeout?: boolean;
  }) =>
    useSend<Required<IChargebackRequestPayload>, unknown>({
      url: `/chargebacks/card-issuance/${ref}/re-escalate`,
      method: 'patch',
      onSuccess,
      onError,
      errorMessage,
      showErrorMessage,
      hasFeedbackTimeout,
      errorMessageBannerLevel: bannerLevel,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useRequestCardAccess: ({
    onSuccess,
    errorMessage,
    cardType
  }: {
    onSuccess?: (data: any) => void;
    errorMessage?: string;
    cardType: string;
  }) =>
    useSend({
      url: `/access/card-issuance/cards/${cardType}`,
      method: 'post',
      onSuccess,
      errorMessage,
      showErrorMessage: true,
      errorMessageBannerLevel: false,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useTerminateCard: ({ errorMessage, cardRef, onSuccess }: { errorMessage?: string; cardRef: string; onSuccess: () => void }) =>
    useSend<WithoutCardRef<CardTerminationPayloadType>, unknown>({
      url: `cards/${cardRef}/terminate`,
      method: 'patch',
      errorMessage,
      onSuccess,
      showErrorMessage: true,
      errorMessageBannerLevel: true,
      useEnvironmentHandler: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useUpdateCardStatus: ({ errorMessage, cardRef, onSuccess }: { errorMessage?: string; cardRef: string; onSuccess: () => void }) =>
    useSend<WithoutCardRef<CardStatusUpdatePayloadType>, unknown>({
      url: `cards/${cardRef}/status`,
      method: 'patch',
      errorMessage,
      onSuccess,
      showErrorMessage: true,
      errorMessageBannerLevel: true,
      useEnvironmentHandler: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useInitiateCardFunding: ({
    onSuccess,
    onError,
    errorMessage,
    cardRef
  }: {
    onSuccess?: (data: any) => void;
    onError?: (error: Error) => void;
    errorMessage?: string;
    cardRef: string;
  }) =>
    useSend<WithoutCardRef<CardFundingPayloadType>, unknown>({
      url: `cards/${cardRef}/funding/initiate`,
      method: 'post',
      onSuccess,
      onError,
      errorMessage,
      errorMessageBannerLevel: true,
      successMessageBannerLevel: true,
      useEnvironmentHandler: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      hasFeedbackTimeout: true
    }),

  useFetchBillingStats: ({
    enabled,
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    errorMessage,
    params
  }: {
    enabled?: boolean;
    onSuccess?: (data: any) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    kind?: string;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
    params: { currency: string; cardType: string };
  }) =>
    useFetch(`/billings/statistics`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      params,
      refetchOnCloseFeedbackError,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),

  useFetchBillingHistory: ({
    enabled,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    params,
    errorMessage
  }: {
    enabled?: boolean;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    refetchOnCloseFeedbackError?: boolean;
    params: {
      page: string;
      limit: string;
      currency: string;
      exportFormat?: string;
      exportFields?: string;
      toExport?: boolean;
      format: string;
      fieldsToExport?: string;
    };
    errorMessage?: string;
  }) => {
    const { page, limit, toExport, exportFormat, exportFields, format, ...filterParams } = params;
    const formattedParams = { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...filterParams };
    return useFetch(`/billings`, {
      enabled,
      onError,
      errorMessageBannerLevel: bannerLevel,
      refetchOnCloseFeedbackError,
      params: formattedParams,
      errorMessage,
      showReturnDefaultResponse: toExport,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      ...(toExport && { requestConfig: { responseType: 'blob' } })
    });
  },

  useFetchBillingDetails: ({
    onSuccess,
    onError,
    bannerLevel,
    refetchOnCloseFeedbackError,
    id,
    errorMessage
  }: {
    onSuccess?: (data: IResponse<unknown>) => void;
    onError?: (e: Error) => void;
    bannerLevel?: boolean;
    id?: string;
    refetchOnCloseFeedbackError?: boolean;
    errorMessage?: string;
  }) =>
    useFetch(`/billings/${id}`, {
      onError,
      errorMessageBannerLevel: bannerLevel,
      onSuccess,
      refetchOnCloseFeedbackError,
      errorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE
    }),
  useInitiateCardWithdrawal: ({
    onSuccess,
    onError,
    errorMessage,
    cardRef
  }: {
    onSuccess?: (data: any) => void;
    onError?: (error: Error) => void;
    errorMessage?: string;
    cardRef: string;
  }) =>
    useSend<IInitiateCardWithdrawalPayload, unknown>({
      url: `cards/${cardRef}/withdrawal/initiate`,
      method: 'post',
      onSuccess,
      onError,
      errorMessage,
      errorMessageBannerLevel: true,
      successMessageBannerLevel: true,
      useEnvironmentHandler: true,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      hasFeedbackTimeout: true
    }),
  useAuthorizeCardWithdrawal: ({
    showErrorMessage,
    onSuccess,
    onError
  }: {
    onSuccess?: (data?: ISendResponse<unknown>) => void;
    onError?: (e: Error) => void;
    showErrorMessage?: boolean;
  }) =>
    useSend({
      url: `cards/withdrawal/authorize`,
      method: 'post',
      onSuccess,
      onError,
      successMessageBannerLevel: false,
      errorMessageBannerLevel: false,
      showErrorMessage,
      customBaseURL: process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE,
      hasFeedbackTimeout: true
    })
};
